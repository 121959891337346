import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TablePagination,
  Paper,
  Toolbar,
  Table,
  Typography,
  LinearProgress
} from '@mui/material'

import { getAllTags } from '../../../io/graphql/tag/tags'
import { getUser } from '../../../utils/util'
import { useEffectWR } from '../../../containers/labeler/components/types/hooks/useEffectWR'

const fields = [
  {
    id: 'key',
    type: 'text',
    label: 'Image Name',
    format: (value) => {
      const names = value.split('/')
      return names[names.length - 1]
    }
  },
  {
    id: 'count',
    type: 'number',
    label: 'Labels Count',
    format: (value) => value
  },
  {
    id: 'done',
    type: 'boolean',
    label: 'Done',
    format: (value) => (value ? 'Done' : 'Pending')
  },
  {
    id: 'skipped',
    type: 'boolean',
    label: 'Skipped',
    format: (value) => (value ? 'Skipped' : '-')
  },
  {
    id: 'states',
    type: 'text',
    label: 'State',
    format: (value) => {
      if (!value.items || value.items?.length === 0) return 'none'

      return value.items[value.items.length - 1].action
    }
  }
]

const TagsTable = ({ project, projectId, role, statusCondition }) => {
  const [tags, setTags] = useState([])
  const [loading, setLoading] = useState(false)
  const [nextToken, setNextToken] = useState(null)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  useEffect(() => {
    if (project.id && projectId) {
      getTags()
    }
  }, [project.id, rowsPerPage])

  useEffectWR(
    [statusCondition],
    [
      () => {
        setNextToken(null)
        setTags([])
        getTags(0)
      }
    ]
  )

  const getTags = (page) => {
    setLoading(true)

    const and = []

    if (role === 'labeler') {
      and.push({ tagLabelerId: { eq: getUser().id } })
      if (statusCondition !== 'all') {
        and.push({ done: { eq: statusCondition } })
      }
    } else if (role === 'reviewer') {
      and.push({ tagReviewerId: { eq: getUser().id } })
      if (statusCondition !== 'all') {
        and.push({ accepted: { eq: statusCondition } })
      }
    }

    getAllTags(project.id, rowsPerPage, 'asc', nextToken, {
      and,
      or: []
    })
      .then((r) => {
        if (r.data && r.data.items) {
          setTags((prevTags) => [...prevTags, ...r.data.items])

          if (r.data.items.length < rowsPerPage) {
            setNextToken(null)
          } else {
            setNextToken(r.data.nextToken)
          }

          if (Number.isInteger(page)) setPage(page)
        } else {
          setNextToken(null)
        }
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        throw new Error(error)
      })
  }

  const totalTags = () => {
    let totalImg = 0
    if (!nextToken) {
      totalImg = tags.length
    } else {
      project.datasets.forEach((d) => (totalImg += d.dataset ? d.dataset.count : -1))
      totalImg = -1
    }
    return totalImg
  }

  const handleChangePage = (event, newPage) => {
    const index = newPage * rowsPerPage
    if (tags.length > index) {
      setPage(newPage)
    } else {
      getTags(newPage)
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setNextToken(null)
    setTags([])
    setPage(0)

    setTimeout(() => {
      setRowsPerPage(event.target.value)
    }, 200)
  }

  return (
    <Paper sx={{ width: '100%', mb: 2 }}>
      {loading && <LinearProgress color="secondary" />}
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 }
        }}
      >
        <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
          Labels Count
        </Typography>
      </Toolbar>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
          <TableHead>
            <TableRow>
              {fields.map((field) => {
                return <TableCell key={field.id}>{field.label}</TableCell>
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tags &&
              tags.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                return (
                  <TableRow hover tabIndex={-1} role="checkbox" key={i}>
                    {fields.map((column) => {
                      const value = row[column.id]
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id === 'key' && !row.done && !row.skiped ? (
                            <Link to={{ pathname: '/labeler', search: `?id=${row.id}` }}>
                              {column.format(value)}
                            </Link>
                          ) : (
                            column.format(value)
                          )}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalTags()}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        backIconButtonProps={{
          'aria-label': 'previous page'
        }}
        nextIconButtonProps={{
          'aria-label': 'next page'
        }}
      />
    </Paper>
  )
}

export default TagsTable
