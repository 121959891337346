import { createMachine, assign } from 'xstate'

const actions = {
  setLoadingTrue: assign({
    loading: true
  }),
  setLoadingFalse: assign({
    loading: false
  })
}

export const labelerMachine = createMachine(
  {
    id: 'Labeler',
    initial: 'prepareLabelArea',
    context: {
      loading: true,
      hasError: false
    },
    states: {
      prepareLabelArea: {
        on: {
          RESOLVE: 'prepareLayer',
          REJECT: 'failure'
        }
      },
      prepareLayer: {
        on: {
          RESOLVE: 'clearImage',
          REJECT: 'failure'
        }
      },

      clearImage: {
        on: {
          RESOLVE: 'clearTags',
          REJECT: 'failure'
        }
      },
      clearTags: {
        on: {
          RESOLVE: 'getImage',
          REJECT: 'failure'
        }
      },
      getImage: {
        on: {
          RESOLVE: 'drawImage',
          REJECT: 'failure'
        }
      },

      drawImage: {
        on: {
          RESOLVE: 'getTags',
          REJECT: 'failure'
        }
      },
      getTags: {
        on: {
          RESOLVE: 'drawTags',
          REJECT: 'failure'
        }
      },
      drawTags: {
        on: {
          RESOLVE: 'readyForLabeling',
          REJECT: 'failure'
        }
      },
      readyForLabeling: {
        entry: 'setLoadingFalse'
      },
      failure: {
        entry: [
          assign({
            hasError: true,
            loading: true
          })
        ]
      }
    },
    on: {
      RESET: {
        target: 'clearImage',
        actions: 'setLoadingTrue'
      }
    }
  },
  { actions }
)
