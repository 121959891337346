import { API, graphqlOperation } from 'aws-amplify'
import * as mutations from './mutations'

const createTagStateInDB = async (tagState) =>
  API.graphql(graphqlOperation(mutations.createTagState, { input: tagState }))

export const createTagState = async (stage, action, userId, tagId, role) => {
  try {
    const { data, errors } = await createTagStateInDB({
      stage,
      action,
      tagSateTagId: tagId,
      toBlameId: userId,
      role
    })
      .then((r) => ({ data: r.data?.createTagState, errors: r.errors }))
      .catch((e) => ({ errors: [{ message: e }] }))
    return { data, errors }
  } catch (e) {
    return { data: null, errors: [{ message: e }] }
  }
}
