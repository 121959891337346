import { Auth } from 'aws-amplify'
import { sandUser } from '../io/analytics'
import { store } from '../index'

export const debug = true

export function saveUser(user) {
  window.localStorage.setItem('user', JSON.stringify(user))
  sandUser(user)
}

export function deleteUser() {
  deleteTime()
  window.localStorage.setItem('user', null)
}

export function getUser() {
  const user = window.localStorage.getItem('user')

  if (!user || user === 'undefined') {
    return null
  }
  return JSON.parse(user)
}

export async function getUserToken() {
  // Auth
  const token = await Auth.currentSession()
  return token.idToken.jwtToken
}

export async function getUserCredentials() {
  const credentials = await Auth.currentCredentials()

  return credentials
}

export function getFileSize(size) {
  if (size / 1000000000 > 1) {
    return (size / 1000000000).toFixed(2) + 'GB'
  }
  if (size / 1000000 > 1) {
    return (size / 1000000).toFixed(2) + 'MB'
  }
  if (size / 1000 > 1) {
    return (size / 1000).toFixed(2) + 'KB'
  }

  return size.toFixed(2) + 'B'
}

export const getDatasetwithId = (datasetId) => {
  const datasets = store.getState().user.datasets

  for (let i = 0; i < datasets.length; i++) {
    if (datasets[i].datasetId === datasetId) {
      return datasets[i]
    }
  }

  return null
}

export function saveTime(date, time, count, image) {
  const t = JSON.parse(window.localStorage.getItem('time')) || {
    date,
    time: 0,
    count: 0,
    images: []
  }
  window.localStorage.setItem(
    'time',
    JSON.stringify({
      date,
      count: t.date !== date ? count : t.count + count,
      time: t.date !== date ? time : t.time + time,
      images: t.images ? (t.images.includes(image) ? t.images : [...t.images, image]) : [image]
    })
  )
}

export function deleteTime() {
  window.localStorage.setItem('time', null)
}

export function getTime(date) {
  const time = JSON.parse(window.localStorage.getItem('time'))
  if (date !== time.date) return null
  return time
}

export function getNowString() {
  const d = new Date()
  return `${d.getMonth() + 1}/${d.getDate()}/${1900 + d.getYear()}`
}

export function getTimeFromMilis(timestamp) {
  const t = timestamp / 1000
  const h = Math.round(t / 3600)
  const m = Math.round((t % 3600) / 60)
  const s = Math.round((t % 3600) % 60)

  return `${h}:${m}:${s}`
}

export function getRole(team) {
  if (!team || team === 'default') return 'admin'
  const user = getUser()
  for (let i = 0; i < team.membersData.length; i++) {
    if (user.userId === team.membersData[i].id) {
      return team.membersData[i].role
    }
  }
  return 'admin'
}

export function getMemberRole(team) {
  if (!team || team === 'default') {
    return 'admin'
  }

  const user = getUser()

  const member = team.members?.items?.find((item) => item.id.includes(user.id))

  if (member) {
    return member.role
  } else {
    return 'admin'
  }
}

export function getUserInTeam(team, userId) {
  if (!team || team === 'default') return 'unknow'
  for (let i = 0; i < team.membersData.length; i++) {
    if (userId === team.membersData[i].id) {
      return team.membersData[i].name
    }
  }
  return 'unknow'
}

export function getUserInTeam2(team, userId) {
  if (!team || team === 'default') return 'unknow'
  for (let i = 0; i < team.members.items.length; i++) {
    if (userId === team.members.items[i].user.id) {
      return team.members.items[i].user.email
    }
  }
  return userId
}

export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function getTimeNow() {
  return new Date().toISOString()
}

export const dateToString = (date) => new Date(date).toDateString()

export const dateToTimeString = (date) => new Date(date).toLocaleString()

// export function diffFree (user) {
//   const date = new Date(user.createdAt)
//   var diffTime = Math.abs(new Date() - date)
//   diffTime = 31 - Math.ceil(diffTime / (1000 * 60 * 60 * 24))
//   diffTime = diffTime <= 0 ? 'Free Plan' : diffTime

//   return diffTime
// }

export const formatS3Key = (key) => decodeURIComponent(key.split('+').join(' '))

export const formatNumber = (number) => {
  return new Intl.NumberFormat('de-DE').format(number)
}

export function getLastWeek(data = []) {
  const arrayDates = []

  if (!data.length) {
    return arrayDates
  }

  const lastDay = new Date(data[data.length - 1].key_as_string)
  arrayDates.push({
    key_as_string: lastDay,
    doc_count: data[data.length - 1].doc_count
  })
  for (let index = 1; index < 7; index++) {
    const myPastDate = new Date(lastDay)
    myPastDate.setDate(myPastDate.getDate() - index)
    arrayDates.push({ key_as_string: myPastDate, doc_count: 0 })
  }
  for (let index = 1; index < arrayDates.length; index++) {
    const item = arrayDates[index].key_as_string
    for (let i = 0; i < data.length; i++) {
      if (item.getTime() === new Date(data[i].key_as_string).getTime()) {
        arrayDates[index] = {
          ...arrayDates[index],
          doc_count: data[i].doc_count
        }
      }
    }
  }

  return arrayDates.reverse()
}

export function getLastMonth(data = []) {
  const arrayDates = []
  if (data.length > 0) {
    const lastMonth = new Date(data[data.length - 1].key_as_string)
    const firstDay = new Date(lastMonth.getFullYear(), lastMonth.getMonth(), 1)
    const lastDay = new Date(lastMonth.getFullYear(), lastMonth.getMonth() + 1, 0)
    for (let index = 0; index < lastDay.getDate(); index++) {
      const myPastDate = new Date(firstDay)
      myPastDate.setDate(myPastDate.getDate() + index)
      arrayDates.push({ key_as_string: myPastDate, doc_count: 0 })
    }
    for (let index = 0; index < arrayDates.length; index++) {
      const item = arrayDates[index].key_as_string
      for (let i = 0; i < data.length; i++) {
        if (item.getTime() === new Date(data[i].key_as_string).getTime()) {
          arrayDates[index] = {
            ...arrayDates[index],
            doc_count: data[i].doc_count
          }
        }
      }
    }
  }
  return arrayDates
}
// This fuction generates a ramdom hex color code and receive a variable that sets the birghtness
export const randomColor = (brightness) => {
  const randomChannel = (brightness) => {
    const r = 255 - brightness
    const n = 0 | (Math.random() * r + brightness)
    const s = n.toString(16)
    return s.length === 1 ? '0' + s : s
  }
  return '#' + randomChannel(brightness) + randomChannel(brightness) + randomChannel(brightness)
}

export const getAllowFiles = (filesList) => {
  const allowedFiles = []
  const rejectedFiles = []

  for (let index = 0; index < filesList.length; index++) {
    const element = filesList[index]
    switch (element.type) {
      case 'image/png':
      case 'image/jpeg':
      case 'image/jpg':
      case 'image/gif':
      case 'image/tiff':
      case 'image/bmp':
      case 'application/json':
        allowedFiles.push(element)
        break
      default:
        rejectedFiles.push(element)
        break
    }
  }

  return { allowedFiles, rejectedFiles }
}

export function stringToColor(string) {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

export function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: 30,
      height: 30,
      fontSize: 'small'
    },
    children:
      name.split(' ').length >= 2
        ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
        : `${name.split(' ')[0][0]}`
  }
}
