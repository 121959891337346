import React, { useContext } from 'react'
import {
  AccordionDetails,
  AccordionSummary,
  FormLabel,
  ListItem,
  Radio,
  RadioGroup,
  Typography,
  FormControl,
  FormControlLabel,
  Accordion
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import styled from '@emotion/styled'
import { FiltersContext } from '../../../../../../context/FiltersContext'

const ContainerFormControl = styled('div')(() => ({
  width: '100%'
}))

const StatusFilter = () => {
  const { state, dispatch } = useContext(FiltersContext)

  const radioOptions = [
    { value: true, label: 'True' },
    { value: false, label: 'False' },
    { value: 'all', label: 'All' }
  ]

  const handleStatusChange = (event) => {
    const { name, value } = event.target

    const boolValue = value === 'true' ? true : value === 'false' ? false : value
    dispatch({
      type: `set${name.charAt(0).toUpperCase()}${name.slice(1)}`,
      payload: { value: boolValue }
    })
  }

  const createRadio = (radioOption) => (
    <FormControlLabel
      sx={{ fontSize: '1rem', marginRight: '9px' }}
      key={radioOption.value}
      value={radioOption.value}
      control={<Radio size="small" />}
      label={radioOption.label}
    />
  )

  const createRadioGroup = (item) => (
    <FormControl sx={{ width: '100%', margin: '0.5rem 0rem' }} key={item.name} component="fieldset">
      <FormLabel>{item.label.charAt(0).toUpperCase() + item.label.slice(1)}</FormLabel>
      <RadioGroup
        name={item.name}
        value={state[item.name].value}
        onChange={handleStatusChange}
        sx={{ display: 'block' }}
        id={`${item.name}-radio-group`}
      >
        {radioOptions.map(createRadio)}
      </RadioGroup>
    </FormControl>
  )

  return (
    <ListItem>
      <Accordion sx={{ width: '100%' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="status-content"
          id="status"
        >
          <Typography>Status</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ContainerFormControl>
            {[
              { name: 'done', label: 'done' },
              { name: 'accepted', label: 'reviewed' },
              { name: 'skiped', label: 'skipped' }
            ].map(createRadioGroup)}
          </ContainerFormControl>
        </AccordionDetails>
      </Accordion>
    </ListItem>
  )
}

export default StatusFilter
