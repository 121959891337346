import React, { useRef, useContext, useState } from 'react'
import useZoom from '../hooks/useZoom'
import useResizeCategoryCanvas from '../hooks/useResizeCategoryCanvas'
import { LabelerContext } from '../../../context/LabelerContext'
import { DispatchContext } from '../../../context/DispatchContext'
import { v4 as uuidv4 } from 'uuid'
import { useCategoryEventListener } from '../hooks/useCategoryEventListener'
import { useEffectWR } from '../hooks/useEffectWR'
import { useClearCanvas } from '../hooks/useClearCanvas'
import { useDrawTags } from '../hooks/useDrawTags'

export default function LineCanvas({ labeler, panZoom, mouse }) {
  const { state } = useContext(LabelerContext)
  const { dispatch } = useContext(DispatchContext)
  const { lineState, labelerState } = state

  const [line, setLine] = useState(null)
  const [editLine, setEditLine] = useState(null)
  const [editIndex, setEditIndex] = useState(null)

  const canvasIncompleteFigures = useRef(document.createElement('canvas', null, null))
  const canvasCompleteFigures = useRef(document.createElement('canvas', null, null))
  const canvasLineGuide = useRef(document.createElement('canvas', null, null))

  useEffectWR([labelerState.selectedCategory, labelerState.mode], [() => confirmLine()])
  useResizeCategoryCanvas([canvasIncompleteFigures, canvasCompleteFigures, canvasLineGuide], 'line')
  useDrawTags(() => {
    drawAllLines()
    drawLine()
  }, 'line')
  useZoom(
    panZoom,
    mouse.wheel,
    [canvasIncompleteFigures, canvasCompleteFigures, canvasLineGuide],
    () => {
      drawAllLines()
      drawLine()
    }
  )

  useCategoryEventListener('mousedown', mouseDown, labeler.current, 'line')
  useCategoryEventListener('mouseup', mouseUp, labeler.current, 'line')
  useCategoryEventListener('mousemove', mouseMove, labeler.current, 'line')
  useCategoryEventListener('wheel', mouseWheel, labeler.current, 'line')
  useEffectWR([lineState.redraw, editLine], [() => drawAllLines()])
  useEffectWR([editLine], [drawEdit])

  useClearCanvas([canvasIncompleteFigures, canvasCompleteFigures, canvasLineGuide], 'line')

  useEffectWR([line], [() => drawLine()])

  function mouseDown(e) {
    if (e.button === 0) {
      if (labelerState.mode === 'draw') {
        if (line === null) {
          const lineType = labelerState.selectedCategory
          setLine({
            id: uuidv4(),
            childs: [],
            name: lineType.name,
            color: lineType.color,
            type: 'line',
            pos: [
              { x: (mouse.x - panZoom.x) / panZoom.scale, y: (mouse.y - panZoom.y) / panZoom.scale }
            ]
          })
          return
        }
        setLine({
          ...line,
          pos: [
            ...line.pos,
            { x: (mouse.x - panZoom.x) / panZoom.scale, y: (mouse.y - panZoom.y) / panZoom.scale }
          ]
        })
      }
      if (labelerState.mode === 'edit') {
        const l = handleSelectCircle(
          (mouse.x - panZoom.x) / panZoom.scale,
          (mouse.y - panZoom.y) / panZoom.scale
        )
        if (l !== null) {
          const l1 = lineState.tags[l.lineIndex]
          setEditIndex(l)
          setEditLine(l1)
        } else {
          setEditIndex(null)
          setEditLine(null)
        }
      }
    }
  }
  function mouseUp(e) {
    if (labelerState.mode === 'edit') {
      if (e.button === 0) {
        confirmEdit()
      }
    }
  }
  function mouseMove() {
    if (labelerState.mode === 'edit') {
      if (mouse.button) {
        edit((mouse.x - panZoom.x) / panZoom.scale, (mouse.y - panZoom.y) / panZoom.scale)
      }
    }
  }

  const confirmEdit = () => {
    if (editLine !== null) {
      dispatch({ type: 'setLine', payload: { line: editLine, index: editIndex.lineIndex } })
      setEditLine(null)
    }

    dispatch({ type: 'setSaveTags' })
  }

  const edit = (x, y) => {
    if (editLine !== null && editIndex !== null) {
      setEditLine({
        ...editLine,
        pos: editLine.pos.map((point, index) => {
          if (index === editIndex.pointIndex) {
            return { x, y }
          }
          return point
        })
      })
    }
  }

  function drawEdit() {
    const canvas = canvasIncompleteFigures.current
    const ctx = canvas.getContext('2d')
    ctx.lineWidth = 2 / panZoom.scale
    ctx.setTransform(1, 0, 0, 1, 0, 0)
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    ctx.beginPath()
    ctx.setTransform(panZoom.scale, 0, 0, panZoom.scale, panZoom.x, panZoom.y)

    if (editLine !== null) {
      ctx.fillStyle = '#ffffff80'
      ctx.strokeStyle = '#ffffff80'

      editLine.pos.forEach((point) => {
        ctx.lineTo(point.x, point.y)
      })
      ctx.stroke()

      editLine.pos.forEach((point) => {
        ctx.beginPath()
        ctx.fillRect(
          point.x - 3 / panZoom.scale,
          point.y - 3 / panZoom.scale,
          6 / panZoom.scale,
          6 / panZoom.scale
        )
      })
    }
  }

  function mouseWheel(e) {
    if (labelerState.mode === 'template') {
      e.stopPropagation()
      let ms = 0
      ms = ms + -e.deltaY
      let scale = 1
      if (ms !== 0) {
        // eslint-disable-next-line no-unused-vars
        scale = ms < 0 ? 1 / 1.01 : 1.01
        ms = ms * 0.8
        if (Math.abs(ms) < 1) {
          ms = 0
        }
      }
    }
  }

  const handleSelectCircle = (x, y) => {
    let ind = null
    lineState.tags.forEach((line, index) => {
      line.pos.forEach((point, index1) => {
        if (x >= point.x - 3 && x <= point.x + 6 && y >= point.y - 3 && y <= point.y + 6) {
          ind = { lineIndex: index, pointIndex: index1 }
        }
      })
    })
    return ind
  }
  const confirmLine = () => {
    if (line !== null) {
      dispatch({ type: 'addLine', payload: line })
      dispatch({ type: 'setSaveTags' })
      setLine(null)
    }
  }
  const drawLine = () => {
    const canvas = canvasIncompleteFigures.current
    const ctx = canvas.getContext('2d')
    ctx.lineWidth = 2 / panZoom.scale
    ctx.setTransform(1, 0, 0, 1, 0, 0)
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    ctx.beginPath()
    ctx.setTransform(panZoom.scale, 0, 0, panZoom.scale, panZoom.x, panZoom.y)
    if (line !== null) {
      const lineType = labelerState.selectedCategory

      ctx.fillStyle = '#fff'
      ctx.strokeStyle = lineType.color
      line.pos.forEach((point) => {
        ctx.lineTo(point.x, point.y)
      })
      ctx.stroke()
      line.pos.forEach((point) => {
        ctx.beginPath()
        ctx.fillRect(
          point.x - 3 / panZoom.scale,
          point.y - 3 / panZoom.scale,
          6 / panZoom.scale,
          6 / panZoom.scale
        )
      })
    }
  }

  const drawAllLines = (tags = lineState.tags) => {
    const canvas = canvasCompleteFigures.current
    const ctx = canvas.getContext('2d')
    ctx.lineWidth = 2 / panZoom.scale
    ctx.setTransform(1, 0, 0, 1, 0, 0)
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    ctx.beginPath()
    ctx.setTransform(panZoom.scale, 0, 0, panZoom.scale, panZoom.x, panZoom.y)
    tags.forEach((line) => {
      if (line.show !== false) {
        ctx.fillStyle = '#fff'
        ctx.strokeStyle = line.hover ? '#ffffff4D' : line.color
        line.pos.forEach((point) => {
          ctx.lineTo(point.x, point.y)
        })
        ctx.stroke()
        line.pos.forEach((point) => {
          ctx.beginPath()
          ctx.fillRect(
            point.x - 3 / panZoom.scale,
            point.y - 3 / panZoom.scale,
            6 / panZoom.scale,
            6 / panZoom.scale
          )
        })
      }
    })
  }

  return (
    <>
      <canvas className="layout" ref={canvasCompleteFigures} />
      <canvas className="layout" ref={canvasIncompleteFigures} />
      <canvas className="layout" ref={canvasLineGuide} style={{ cursor: labelerState.cursor }} />
    </>
  )
}
