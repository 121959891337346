import React from 'react'
import { styled } from '@mui/system'

const StyledImage = styled('img')({
  width: 200,
  height: 200,
  margin: 5
})

const PlaceholderImage = ({ key }) => {
  return <StyledImage key={key} alt="img_placeholder" src="/assets/placeholder.svg" />
}

export default PlaceholderImage
