import React from 'react'
import { Link } from 'react-router-dom'
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  Typography,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem
} from '@mui/material'
import CopyIcon from '@mui/icons-material/FileCopy'
import LabelIcon from '@mui/icons-material/Label'
import ReviewIcon from '@mui/icons-material/Beenhere'
import ViewIcon from '@mui/icons-material/Visibility'
import MoreIcon from '@mui/icons-material/MoreVert'
import { styled } from '@mui/material/styles'

import { dateToString } from '../../../../../utils/util'

const LoadMoreButton = styled('em')(({ theme }) => ({
  color: theme.palette.primary.main
}))

const ProgressText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main
}))

export default function ProjectsList(props) {
  const { projects, labelIt, nextToken } = props

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [selectedProject, setSelectedProject] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleOpenMenuClick = (event, proj) => {
    setSelectedProject(proj)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <List>
        {projects
          ? projects.map((project) => {
              if (project.datasets.items.length > 0) {
                const dataset = project.datasets.items[0].dataset
                return (
                  <span key={project.id}>
                    <ListItem
                      style={{ textDecoration: 'none' }}
                      component="a"
                      href={`/project?id=${project.id}`}
                    >
                      <ListItemText
                        primary={
                          <React.Fragment>
                            {`${project.name} `}(
                            <ProgressText
                              sx={{ display: 'inline', color: 'text.primary' }}
                              component="span"
                              variant="body2"
                            >
                              {dataset != null ? (
                                <b>{`${
                                  Math.round((project.imagesSavedCount / dataset.count) * 10000) /
                                  100
                                }%`}</b>
                              ) : (
                                <b>{`NaN%`}</b>
                              )}
                            </ProgressText>
                            )
                          </React.Fragment>
                        }
                        primaryTypographyProps={{ color: 'primary' }}
                        secondary={
                          <React.Fragment>
                            <Typography sx={{ display: 'inline' }} component="span" variant="body2">
                              {` ${dateToString(project.createdAt)} `}
                              {project.team ? (
                                <>
                                  <b>Team:</b>
                                  {` ${project.team.name} `}
                                </>
                              ) : (
                                <></>
                              )}
                              <b>Dataset:</b>
                              {dataset != null
                                ? ` ${dataset.name} (${dataset.count}) `
                                : ` N/A (0)`}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <ListItemSecondaryAction>
                        <Button
                          id="startlabeling"
                          style={{ margin: 3 }}
                          size="small"
                          color="secondary"
                          variant="contained"
                          onClick={() => labelIt(project, 'labeler')}
                        >
                          Start Labeling
                        </Button>
                        <IconButton
                          onClick={(e) => handleOpenMenuClick(e, project)}
                          style={{ marginLeft: 4 }}
                          edge="end"
                          aria-label="more"
                          size="large"
                        >
                          <MoreIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider component="li" />
                  </span>
                )
              } else {
                return null
              }
            })
          : ''}
        {(projects.length <= 0 || nextToken) && (
          <ListItem button key="more" onClick={props.loadMore}>
            <Typography>
              <LoadMoreButton>Load more...</LoadMoreButton>
            </Typography>
          </ListItem>
        )}
      </List>
      <Menu style={{ boxShadow: 'none' }} anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Link style={{ textDecoration: 'none' }} to={`/createproject?id=${selectedProject?.id}`}>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <CopyIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Duplicate</ListItemText>
          </MenuItem>
        </Link>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={() => labelIt(selectedProject, 'viewer')}>
          <ListItemIcon>
            <ViewIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>View Images</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => labelIt(selectedProject, 'labeler')}>
          <ListItemIcon>
            <LabelIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Label Images</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => labelIt(selectedProject, 'reviewer')}>
          <ListItemIcon>
            <ReviewIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Review Images</ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}
