import { styled } from '@mui/material/styles'
import { Dialog, IconButton } from '@mui/material'

export const DialogMainContainer = styled(Dialog)(() => ({
  padding: '1rem'
}))

export const DialogCloseIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: 15,
  top: 15,
  color: theme.palette.grey[500]
}))

export const MainContentContainer = styled('div')(() => ({
  width: '100%',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column'
}))

export const GridContainer = styled('div')(() => ({
  width: '100%',
  height: 'auto',
  margin: '1rem 0rem',
  padding: '0rem 1rem',
  display: 'grid',
  gridTemplateRows: '1fr',
  gridTemplateColumns: '60% 40%',
  overflow: 'hidden'
}))

export const MainImageContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center'
}))

export const InfoContainer = styled('div')(() => ({
  overflow: 'hidden'
}))

export const ActionsContainer = styled('div')(() => ({
  width: '100%',
  display: 'block',
  margin: '0rem 2rem'
}))

export const FormContainer = styled('div')(() => ({
  width: '100%',
  display: 'grid',
  gridTemplateRows: '1fr',
  gridTemplateColumns: '70% 15% 15%',
  gap: '0.5rem',
  padding: '1rem 0rem'
}))
