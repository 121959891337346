import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import { Card, Chip, List, ListItem, ListItemText, Button } from '@mui/material'
import { styled } from '@mui/material/styles'

import LoadingDropdown from '../../../../components/LoadingDropdown'
import { sandTeamCreationStarted } from '../../../../io/analytics'
import { user as u } from '../../../../io/user/actions'
import CreateTeam from '../../../../containers/CreateTeam'
import useFreeTrial from '../../../project/hooks/useFreeTrial'
import { getUser } from '../../../../utils/util'

const LoadMore = styled('em')(({ theme }) => ({
  color: theme.palette.primary.main
}))

function Teams(props) {
  const { permissionsFreeTrial } = useFreeTrial()
  const [creating, setCreating] = useState(false)

  useEffect(() => {
    props.getTeams()
    props.getMemberTeams()
  }, [])

  const loadMore = () => {
    props.getTeams(props.teams.nextToken)
    props.getMemberTeams(props.memberTeams.nextToken)
  }

  const { teams, memberTeams, handleSelectTeam } = props
  const teamsAdmin = teams.items
  const teamsMember = memberTeams.items

  return (
    <>
      <div style={{ padding: '1em' }}>
        {creating ? (
          <CreateTeam teamCreated={props.teamCreated} />
        ) : (
          <Grid container className="create-grid">
            <Grid item xs={8} className="project-item">
              <Typography variant="h4">Your Teams</Typography>
              {teams.loading ? (
                <LoadingDropdown />
              ) : !teams.loading ? (
                <Card>
                  <List>
                    {teamsAdmin.map((team, i) => {
                      return handleSelectTeam && i === 0 ? (
                        <ListItem
                          style={{ textDecoration: 'none' }}
                          key={i}
                          component="a"
                          onClick={() => handleSelectTeam(team)}
                        >
                          <ListItemText
                            primary={team.name}
                            secondary={`members:${
                              team.members.nextToken ? '+9' : team.members.items.length
                            }`}
                          />
                          <ListItemSecondaryAction>
                            <Chip label={'Admin'} color={'primary'} />
                          </ListItemSecondaryAction>
                        </ListItem>
                      ) : (
                        <ListItem
                          style={{ textDecoration: 'none' }}
                          key={i}
                          component="a"
                          href={`/team?id=${team.id}`}
                        >
                          <ListItemText
                            primary={team.name}
                            secondary={`members:${
                              team.members.nextToken ? '+9' : team.members.items.length
                            }`}
                          />
                          <ListItemSecondaryAction>
                            <Chip label={'Admin'} color={'primary'} />
                          </ListItemSecondaryAction>
                        </ListItem>
                      )
                    })}
                    {teamsMember.map((team) => (
                      <ListItem key={team.id}>
                        <ListItemText
                          primary={team.name}
                          secondary={`members:${
                            team.members.nextToken ? '+9' : team.members.items.length
                          }`}
                        />
                        <ListItemSecondaryAction>
                          <Chip label={'Member'} color={'secondary'} />
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                    {(teams.items.length <= 0 || teams.nextToken) && (
                      <ListItem button key="more" onClick={loadMore} disabled={teams.loading}>
                        <Typography color="primary">
                          <LoadMore>Load more...</LoadMore>
                        </Typography>
                      </ListItem>
                    )}
                  </List>
                </Card>
              ) : (
                <Card>
                  <List>
                    <ListItem key={0}>
                      <ListItemText
                        primary={'You do not have any Teams'}
                        secondary={`Click the Create New Team Button to begin`}
                      />
                    </ListItem>
                  </List>
                </Card>
              )}
            </Grid>
          </Grid>
        )}
      </div>
      <Button
        color="primary"
        variant="contained"
        disabled={!getUser()?.paid && !permissionsFreeTrial}
        onClick={() => {
          if (!creating) sandTeamCreationStarted()
          setCreating(!creating)
        }}
      >
        {creating ? 'View your teams' : 'Create New Team'}
      </Button>
    </>
  )
}

const mapStateToProps = (state) => ({
  teams: state.user.teams,
  memberTeams: state.user.memberTeams
})

const mapDispatchToProps = {
  getTeams: u.getMyTeams,
  getMemberTeams: u.getMemberTeams
}

export default connect(mapStateToProps, mapDispatchToProps)(Teams)
