import React from 'react'
import BarChart from '../../../../../../../components/charts/Bar'
import LineChart from '../../../../../../../components/charts/Line'

const ImagesMetricsChart = ({ imgFilter, barFilter, project, theme }) => {
  const { imagesStats } = project

  return barFilter !== 'all' ? (
    <BarChart
      datasets={[
        {
          data: imgFilter === 'saved' ? imagesStats.barSaved : imagesStats.barReviewed,
          label: 'Images',
          color: theme.palette.secondary.main
        },
        ...(imgFilter === 'saved'
          ? [
              {
                data: imagesStats.barSkipped,
                label: 'Skipped Images',
                color: '#c5c5c5'
              }
            ]
          : [])
      ]}
      textColor={theme.palette.mode === 'dark' ? theme.palette.text.primary : null}
      gridColor={theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : null}
      filter={barFilter}
    />
  ) : (
    <LineChart
      datasets={[
        {
          data: imgFilter === 'saved' ? imagesStats.barSaved : imagesStats.barReviewed,
          label: 'Images',
          color: theme.palette.secondary.main
        },
        ...(imgFilter === 'saved'
          ? [
              {
                data: imagesStats.barSkipped,
                label: 'Skipped Images',
                color: '#c5c5c5'
              }
            ]
          : [])
      ]}
      color={theme.palette.secondary.main}
      textColor={theme.palette.mode === 'dark' ? theme.palette.text.primary : null}
      gridColor={theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : null}
      filter={barFilter}
    />
  )
}

export default ImagesMetricsChart
