import React, { useRef, useContext, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import Replay from '@mui/icons-material/Replay'
import Create from '@mui/icons-material/Create'
import Check from '@mui/icons-material/Check'
import Cancel from '@mui/icons-material/Cancel'
import { Button, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'

import useZoom from '../hooks/useZoom'
import SamContainer from './sam/SamContainer'
import { useEffectWR } from '../hooks/useEffectWR'
import { useClearCanvas } from '../hooks/useClearCanvas'
import useResizeCategoryCanvas from '../hooks/useResizeCategoryCanvas'
import { LabelerContext } from '../../../context/LabelerContext'
import { DispatchContext } from '../../../context/DispatchContext'
import { useCategoryEventListener } from '../hooks/useCategoryEventListener'
import { isInsidePoly } from '../../../../../utils/labeler'
import { getMaskFromBox, getMaskCorrections } from '../../../../../io/api/ml'
import { useDrawTags } from '../hooks/useDrawTags'
import { useSnackbar } from '../../../../../components/snackbar/context/SnackbarContext'

const ButtonComponent = styled(Button)(() => ({
  minWidth: 45,
  width: 45
}))

export default function SegmentationCanvas({
  labeler,
  panZoom,
  mouse,
  canvasImageRef,
  image,
  tensor
}) {
  const { state } = useContext(LabelerContext)
  const { dispatch } = useContext(DispatchContext)
  const { segmentationState, labelerState } = state

  const [isStartingPoint, setIsStartingPoint] = useState(false)
  const [blockManualMode, setBLockManualMode] = useState(false)
  const [region, setRegion] = useState({
    down: false,
    x: 0,
    y: 0,
    w: 0,
    h: 0,
    edit: false,
    toolsPosX: 0,
    toolsPosY: 100,
    background: { isActive: false, down: false },
    foreground: { isActive: false, down: false }
  })
  const editRegion = useRef({ edit: false, x: 0, y: 0, w: 0, h: 0, position: '' })
  const [backgroundPoints, setBackgroundPoints] = useState([])
  const [foregroundPoints, setForegroundPoints] = useState([])
  const [id, setId] = useState(null)
  const [mymask, setMask] = useState(null)
  const { showSnackbar } = useSnackbar()

  const virtualCanvasRef = useRef(document.createElement('canvas', null, null))
  const auxiliarVirtualCanvasRef = useRef(document.createElement('canvas', null, null))

  const canvasIncompleteFigures = useRef(document.createElement('canvas', null, null))
  const canvasCompleteFigures = useRef(document.createElement('canvas', null, null))
  const canvasLineGuide = useRef(document.createElement('canvas', null, null))
  const canvasResult = useRef(document.createElement('canvas', null, null))
  const canvasAutoSegmentation = useRef(document.createElement('canvas', null, null))
  const canvasAutoSegmentationRegion = useRef(document.createElement('canvas', null, null))

  const GLOBAL_ALPHA = labelerState.canvasSettings[4].value

  useEffectWR(
    [labelerState.mode, labelerState.selectedCategory],
    [deleteIncompleteSegmentation, cancelMask]
  )
  useEffectWR([segmentationState.redraw], [drawPoints, drawCompleteShapes])
  useResizeCategoryCanvas(
    [
      canvasIncompleteFigures,
      canvasCompleteFigures,
      canvasLineGuide,
      virtualCanvasRef,
      auxiliarVirtualCanvasRef,
      canvasResult,
      canvasAutoSegmentation,
      canvasAutoSegmentationRegion
    ],
    'segmentation'
  )

  useDrawTags(() => {
    drawPoints()
    drawCompleteShapes()
    redrawRegion()
    drawMask()
  }, 'segmentation')

  useZoom(
    panZoom,
    mouse.wheel,
    [
      canvasIncompleteFigures,
      canvasCompleteFigures,
      canvasLineGuide,
      virtualCanvasRef,
      auxiliarVirtualCanvasRef,
      canvasResult,
      canvasAutoSegmentation,
      canvasAutoSegmentationRegion
    ],
    () => {
      drawPoints()
      drawCompleteShapes()
      redrawRegion()
      drawMask()
    }
  )

  useCategoryEventListener('mousedown', mouseDown, labeler.current, 'segmentation')
  useCategoryEventListener('mouseup', mouseUp, labeler.current, 'segmentation')
  useCategoryEventListener('mousemove', mouseMove, labeler.current, 'segmentation')
  useEffectWR([segmentationState.redraw], [drawCompleteShapes])
  useEffectWR([GLOBAL_ALPHA], [drawCompleteShapes])
  useClearCanvas(
    [
      canvasIncompleteFigures,
      canvasCompleteFigures,
      canvasLineGuide,
      virtualCanvasRef,
      auxiliarVirtualCanvasRef,
      canvasResult,
      canvasAutoSegmentation,
      canvasAutoSegmentationRegion
    ],
    'segmentation'
  )

  // TODO: Check if this function is in use
  const getMask = async (bx = region.x, by = region.y, bw = region.w, bh = region.h) => {
    try {
      dispatch({ type: 'setLoadingAI', payload: true })
      const id = uuidv4()
      const path = decodeURIComponent(labelerState.image.images[labelerState.image.index]?.key)

      let x = bx
      let y = by
      let w = bw
      let h = bh

      if (region.w < 0) {
        x = x + w
        w = region.x - x
      }
      if (region.h < 0) {
        y = y + h
        h = region.y - y
      }

      const mask = await getMaskFromBox(path, x, y, w, h, id, labelerState)

      setId(id)
      dispatch({ type: 'setLoadingAI', payload: false })

      const replace = mask.data.replace(/'/g, '"')
      const obj = JSON.parse(replace)

      drawMask(obj)

      setMask(obj)
    } catch (error) {
      dispatch({ type: 'setLoadingAI', payload: false })
      cancelMagic()
    }
  }

  const fixMask = async () => {
    try {
      dispatch({ type: 'setLoadingAI', payload: true })
      const path = decodeURIComponent(labelerState.image.images[labelerState.image.index]?.key)
      let x = region.x
      let y = region.y
      let w = region.w
      let h = region.h

      if (region.w < 0) {
        x = x + w
        w = region.x - x
      }
      if (region.h < 0) {
        y = y + h
        h = region.y - y
      }

      const mask = await getMaskCorrections(
        path,
        x,
        y,
        w,
        h,
        id,
        labelerState,
        backgroundPoints,
        foregroundPoints
      )

      const canvas = canvasAutoSegmentationRegion.current
      const ctx = canvas.getContext('2d')

      ctx.beginPath()
      ctx.setTransform(1, 0, 0, 1, 0, 0)
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      redrawRegion()

      dispatch({ type: 'setLoadingAI', payload: false })

      const replace = mask.data.replace(/'/g, '"')
      const obj = JSON.parse(replace)
      setBackgroundPoints([])
      setForegroundPoints([])

      // setRegion({ ...region, background: { ...region.background, down: false, points: [], isActive: false } })
      drawMask(obj)

      setMask(obj)
    } catch (error) {
      cancelMagic()
      dispatch({ type: 'setLoadingAI', payload: false })
    }
  }

  function cancelMask() {
    cancelMagic()
  }

  function addPointInBg(x, y) {
    const points = [...backgroundPoints, { x: Math.round(x), y: Math.round(y) }]
    const canvas = canvasAutoSegmentationRegion.current
    const ctx = canvas.getContext('2d')

    redrawRegion()

    ctx.beginPath()
    ctx.setLineDash([])
    points.forEach((point) => {
      ctx.rect(point.x, point.y, 1, 1)
    })
    ctx.stroke()

    setBackgroundPoints([...points])
  }
  function addPointInForeground(x, y) {
    const points = [...foregroundPoints, { x: Math.round(x), y: Math.round(y) }]
    const canvas = canvasAutoSegmentationRegion.current
    const ctx = canvas.getContext('2d')

    redrawRegion()

    ctx.beginPath()
    ctx.setLineDash([])
    ctx.strokeStyle = '#000'
    points.forEach((point) => {
      ctx.rect(point.x, point.y, 1, 1)
    })
    ctx.stroke()

    setForegroundPoints([...points])
  }

  async function mouseDown(e) {
    if (e.button === 0) {
      if (labelerState.mode === 'draw' || labelerState.mode === 'erase') {
        addSimplePoint((mouse.x - panZoom.x) / panZoom.scale, (mouse.y - panZoom.y) / panZoom.scale)
        return
      }
      if (labelerState.mode === 'paint') {
        clickOnElement((mouse.x - panZoom.x) / panZoom.scale, (mouse.y - panZoom.y) / panZoom.scale)
        return
      }
      if (labelerState.mode === 'magic') {
        if (region.down) {
          setRegion({ ...region, down: false, edit: true })
          dispatch({ type: 'setLoadingAI', payload: true })
          const area = labelerState.image.props.w * labelerState.image.props.h
          const area2 = region.w * region.h
          const areaPercentage = (area2 * 100) / area

          if (areaPercentage >= 0.25) {
            getMask()
            editRegion.current = {
              ...editRegion.current,
              x: region.x,
              y: region.y,
              w: region.w,
              h: region.h
            }
          } else {
            showSnackbar({
              message: 'The drawn area is too small',
              type: 'warning'
            })
            cancelMagic()
            dispatch({ type: 'setLoadingAI', payload: false })
          }
        } else {
          if (!region.edit) {
            setRegion({
              ...region,
              down: true,
              x: (mouse.x - panZoom.x) / panZoom.scale,
              y: (mouse.y - panZoom.y) / panZoom.scale,
              toolsPosX: canvasImageRef.current.width / 2 - 122,
              toolsPosY: 100
            })
          }
        }

        if (region.edit) {
          if (
            Math.sqrt(
              Math.pow(region.x - (mouse.x - panZoom.x) / panZoom.scale, 2) +
                Math.pow(region.y - (mouse.y - panZoom.y) / panZoom.scale, 2)
            ) <
            7 / panZoom.scale
          ) {
            editRegion.current = { ...editRegion.current, edit: true, position: 'left-top' }
            return
          }
          if (
            Math.sqrt(
              Math.pow(region.x + region.w - (mouse.x - panZoom.x) / panZoom.scale, 2) +
                Math.pow(region.y - (mouse.y - panZoom.y) / panZoom.scale, 2)
            ) <
            7 / panZoom.scale
          ) {
            editRegion.current = { ...editRegion.current, edit: true, position: 'right-top' }
            return
          }
          if (
            Math.sqrt(
              Math.pow(region.x + region.w - (mouse.x - panZoom.x) / panZoom.scale, 2) +
                Math.pow(region.y + region.h - (mouse.y - panZoom.y) / panZoom.scale, 2)
            ) <
            7 / panZoom.scale
          ) {
            editRegion.current = { ...editRegion.current, edit: true, position: 'right-down' }
            return
          }
          if (
            Math.sqrt(
              Math.pow(region.x - (mouse.x - panZoom.x) / panZoom.scale, 2) +
                Math.pow(region.y + region.h - (mouse.y - panZoom.y) / panZoom.scale, 2)
            ) <
            7 / panZoom.scale
          ) {
            editRegion.current = { ...editRegion.current, edit: true, position: 'left-down' }
            return
          }
        }
        if (region.background.isActive) {
          setRegion({ ...region, background: { ...region.background, down: true } })
          return
        }
        if (region.foreground.isActive) {
          setRegion({ ...region, foreground: { ...region.foreground, down: true } })
        }
      }
    }
  }

  function mouseUp() {
    setBLockManualMode(false)
    if (!labelerState.loadingAI) {
      if (region.background.down && backgroundPoints.length !== 0) {
        setRegion({ ...region, background: { ...region.background, down: false } })
        fixMask()
      }
      if (region.foreground.down && foregroundPoints.length !== 0) {
        setRegion({ ...region, foreground: { ...region.foreground, down: false } })
        fixMask()
      }
      if (editRegion.current.edit) {
        editRegion.current = { ...editRegion.current, edit: false }
        setRegion({
          ...region,
          x: editRegion.current.x,
          y: editRegion.current.y,
          w: editRegion.current.w,
          h: editRegion.current.h
        })
        getMask(
          editRegion.current.x,
          editRegion.current.y,
          editRegion.current.w,
          editRegion.current.h
        )
      }
    }
  }

  useEffectWR([region.x, region.y, region.w, region.h], [redrawRegion])

  function mouseMove() {
    if (region.down) {
      setRegion({
        ...region,
        w: (mouse.x - panZoom.x) / panZoom.scale,
        h: (mouse.y - panZoom.y) / panZoom.scale
      })
      drawRegion((mouse.x - panZoom.x) / panZoom.scale, (mouse.y - panZoom.y) / panZoom.scale)
      drawMagicLineGuide(
        (mouse.x - panZoom.x) / panZoom.scale,
        (mouse.y - panZoom.y) / panZoom.scale
      )
    } else {
      if (labelerState.mode === 'magic') {
        drawMagicLineGuide(
          (mouse.x - panZoom.x) / panZoom.scale,
          (mouse.y - panZoom.y) / panZoom.scale
        )
      } else {
        drawLineGuide((mouse.x - panZoom.x) / panZoom.scale, (mouse.y - panZoom.y) / panZoom.scale)
      }
    }
    if (labelerState.mode === 'draw' || labelerState.mode === 'erase') {
      if (mouse.button && !blockManualMode) {
        addSimplePoint((mouse.x - panZoom.x) / panZoom.scale, (mouse.y - panZoom.y) / panZoom.scale)
      }
    }
    if (region.background.down) {
      addPointInBg((mouse.x - panZoom.x) / panZoom.scale, (mouse.y - panZoom.y) / panZoom.scale)
    }
    if (region.foreground.down) {
      addPointInForeground(
        (mouse.x - panZoom.x) / panZoom.scale,
        (mouse.y - panZoom.y) / panZoom.scale
      )
    }
    if (editRegion.current.edit) {
      resizeBox((mouse.x - panZoom.x) / panZoom.scale, (mouse.y - panZoom.y) / panZoom.scale)
    }
  }
  function resizeBox(x, y) {
    switch (editRegion.current.position) {
      case 'left-top':
        editRegion.current = {
          ...editRegion.current,
          x,
          y,
          w: region.w + region.x - x,
          h: region.h + region.y - y
        }
        break
      case 'right-top':
        editRegion.current = {
          ...editRegion.current,
          w: x - region.x,
          y,
          h: region.h + region.y - y
        }
        break
      case 'right-down':
        editRegion.current = { ...editRegion.current, w: x - region.x, h: y - region.y }
        break
      case 'left-down':
        editRegion.current = {
          ...editRegion.current,
          h: y - region.y,
          x,
          w: region.x + region.w - x
        }
        break
      default:
        break
    }
    redrawRegion()
  }
  function drawMask(mask = mymask) {
    if (mask) {
      const canvas = canvasAutoSegmentation.current
      const ctx = canvas.getContext('2d')
      const color = segmentationState.selectedSegmentation?.color

      ctx.beginPath()
      ctx.setTransform(1, 0, 0, 1, 0, 0)
      ctx.clearRect(0, 0, canvas.width, canvas.height)

      ctx.fillStyle = color
      ctx.globalAlpha = 0.5

      ctx.setTransform(panZoom.scale, 0, 0, panZoom.scale, panZoom.x, panZoom.y)

      mask.pos.forEach((p) => {
        ctx.lineTo(p.x, p.y)
      })

      ctx.globalCompositeOperation = 'source-over'
      ctx.fill()
    }
  }

  function redrawRegion() {
    if (region.edit) {
      const canvas = canvasAutoSegmentationRegion.current
      const ctx = canvas.getContext('2d')

      ctx.beginPath()
      ctx.setTransform(1, 0, 0, 1, 0, 0)
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.lineWidth = 1 / panZoom.scale
      ctx.fillStyle = '#000'
      ctx.globalAlpha = 0.5
      ctx.fillRect(0, 0, canvas.width, canvas.height)

      ctx.setTransform(panZoom.scale, 0, 0, panZoom.scale, panZoom.x, panZoom.y)

      ctx.strokeStyle = '#fff'
      ctx.setLineDash([5, 15])
      ctx.globalAlpha = 1
      ctx.strokeRect(region.x, region.y, region.w, region.h)
      ctx.clearRect(region.x, region.y, region.w, region.h)

      ctx.strokeRect(
        editRegion.current.x,
        editRegion.current.y,
        editRegion.current.w,
        editRegion.current.h
      )

      ctx.arc(editRegion.current.x, editRegion.current.y, 7 / panZoom.scale, 0, 2 * Math.PI)
      ctx.fill()
      ctx.beginPath()
      ctx.arc(
        editRegion.current.x + editRegion.current.w,
        editRegion.current.y,
        7 / panZoom.scale,
        0,
        2 * Math.PI
      )
      ctx.fill()
      ctx.beginPath()
      ctx.arc(
        editRegion.current.x + editRegion.current.w,
        editRegion.current.y + editRegion.current.h,
        7 / panZoom.scale,
        0,
        2 * Math.PI
      )
      ctx.fill()
      ctx.beginPath()
      ctx.arc(
        editRegion.current.x,
        editRegion.current.y + editRegion.current.h,
        7 / panZoom.scale,
        0,
        2 * Math.PI
      )
      ctx.fill()
    }
  }
  function drawRegion(w, h) {
    const canvas = canvasAutoSegmentationRegion.current
    const ctx = canvas.getContext('2d')

    ctx.beginPath()
    ctx.setTransform(1, 0, 0, 1, 0, 0)
    ctx.clearRect(0, 0, canvas.width, canvas.height)

    ctx.fillStyle = '#000'
    ctx.globalAlpha = 0.5
    ctx.fillRect(0, 0, canvas.width, canvas.height)

    ctx.setTransform(panZoom.scale, 0, 0, panZoom.scale, panZoom.x, panZoom.y)

    ctx.strokeStyle = '#fff'
    ctx.setLineDash([5, 15])
    ctx.globalAlpha = 1

    ctx.strokeRect(region.x, region.y, w - region.x, h - region.y)
    ctx.clearRect(region.x, region.y, w - region.x, h - region.y)

    ctx.arc(region.x, region.y, 7 / panZoom.scale, 0, 2 * Math.PI)
    ctx.fill()
    ctx.beginPath()
    ctx.arc(region.x + region.w, region.y, 7 / panZoom.scale, 0, 2 * Math.PI)
    ctx.fill()
    ctx.beginPath()
    ctx.arc(region.x + region.w, region.y + region.h, 7 / panZoom.scale, 0, 2 * Math.PI)
    ctx.fill()
    ctx.beginPath()
    ctx.arc(region.x, region.y + region.h, 7 / panZoom.scale, 0, 2 * Math.PI)
    ctx.fill()

    setRegion({ ...region, w: w - region.x, h: h - region.y })
  }

  function addSimplePoint(x, y) {
    let index = segmentationState.selectedSegmentationIndex
    const segmentationStack = segmentationState.tags.stack
    const isDeleteMode = labelerState.mode === 'erase'
    index = isDeleteMode ? '_delete' : index
    if (
      segmentationStack.length === 0 ||
      segmentationStack[segmentationStack.length - 1].completed
    ) {
      const id = uuidv4()
      const s = {
        points: [{ x: Math.round(x), y: Math.round(y) }],
        completed: false,
        deleted: isDeleteMode,
        type: index,
        id,
        hide: false
      }
      dispatch({ type: 'addSegmentationStack', payload: s })
      drawPoints(s)
    } else {
      if (verifyCompletedShape({ x: Math.round(x), y: Math.round(y) })) {
        const newPixelsFound = [
          ...bresenhamPoints(
            segmentationStack[segmentationStack.length - 1].points.at(-1).x,
            segmentationStack[segmentationStack.length - 1].points.at(-1).y,
            segmentationStack[segmentationStack.length - 1].points[0].x,
            segmentationStack[segmentationStack.length - 1].points[0].y
          )
        ]
        const newPointsFound = []

        newPixelsFound.forEach((pixel, index) => {
          const nextPoint = newPixelsFound[index + 1]
          if (nextPoint && (pixel.x !== nextPoint.x || pixel.y !== nextPoint.y)) {
            newPointsFound.push({ x: pixel.x, y: nextPoint.y })
            newPointsFound.push({ x: nextPoint.x, y: nextPoint.y })
          } else {
            newPointsFound.push(pixel)
          }
        })
        const newSeg = {
          ...segmentationStack[segmentationStack.length - 1],
          completed: true,
          points: [...segmentationStack[segmentationStack.length - 1].points, ...newPointsFound]
        }
        dispatch({ type: 'addPoints', payload: { points: newPointsFound } })
        dispatch({ type: 'addCompleteShape' })
        dispatch({
          type: 'addShape',
          payload: { index, id: segmentationStack[segmentationStack.length - 1].id }
        })
        dispatch({ type: 'setSaveTags' })
        dispatch({ type: 'redraw' })
        drawPoints(newSeg)
        setBLockManualMode(true)
        setIsStartingPoint(false)
      } else {
        const oldY =
          segmentationStack[segmentationStack.length - 1].points[
            segmentationStack[segmentationStack.length - 1].points.length - 1
          ].y
        const oldX =
          segmentationStack[segmentationStack.length - 1].points[
            segmentationStack[segmentationStack.length - 1].points.length - 1
          ].x
        const newX = Math.round(x)
        const newY = Math.round(y)

        const newPixelsFound = [...bresenhamPoints(oldX, oldY, newX, newY)]
        const newPointsFound = []

        newPixelsFound.forEach((pixel, index) => {
          const nextPoint = newPixelsFound[index + 1]
          if (nextPoint && (pixel.x !== nextPoint.x || pixel.y !== nextPoint.y)) {
            newPointsFound.push({ x: pixel.x, y: nextPoint.y })
            newPointsFound.push({ x: nextPoint.x, y: nextPoint.y })
          } else {
            newPointsFound.push(pixel)
          }
        })
        const newSeg = {
          ...segmentationStack[segmentationStack.length - 1],
          points: [...segmentationStack[segmentationStack.length - 1].points, ...newPointsFound]
        }
        dispatch({ type: 'addPoints', payload: { points: newPointsFound } })
        drawPoints(newSeg)
      }
    }
  }
  function bresenhamPoints(x0, y0, x1, y1) {
    const dx = Math.abs(x1 - x0)
    const sx = x0 < x1 ? 1 : -1
    const dy = -Math.abs(y1 - y0)
    const sy = y0 < y1 ? 1 : -1
    let err = dx + dy
    let e2
    const array = []
    for (;;) {
      array.push({ x: x0, y: y0 })
      if (x0 === x1 && y0 === y1) {
        return array
      }
      e2 = 2 * err
      if (e2 >= dy) {
        err += dy
        x0 += sx
      }
      if (e2 <= dx) {
        err += dx
        y0 += sy
      }
    }
  }
  function drawPoints(stack = segmentationState.tags.stack.at(-1)) {
    const canvas = canvasIncompleteFigures.current
    const ctx = canvas.getContext('2d')
    if (stack) {
      const color = segmentationState.selectedSegmentation?.color
      ctx.setTransform(1, 0, 0, 1, 0, 0)
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.setTransform(panZoom.scale, 0, 0, panZoom.scale, panZoom.x, panZoom.y)
      const lastShape = stack
      const firstPoint = stack.points[0]
      ctx.beginPath()
      if (!lastShape.completed) {
        ctx.lineWidth = 1 / panZoom.scale
        ctx.setLineDash([])
        ctx.globalCompositeOperation = 'source-over'
        ctx.fillStyle = '#fff'
        ctx.arc(firstPoint.x, firstPoint.y, 7 / panZoom.scale, 0, 2 * Math.PI)
        ctx.fill()
        ctx.strokeStyle = '#000'
        ctx.arc(firstPoint.x, firstPoint.y, 2 / panZoom.scale, 0, 2 * Math.PI)
        ctx.stroke()
        lastShape.points.forEach((point) => {
          ctx.lineTo(point.x, point.y)
        })
        if (!lastShape.deleted) {
          ctx.strokeStyle = color
        } else {
          ctx.setLineDash([2, 5])
          ctx.strokeStyle = '#fff'
        }
        ctx.stroke()
      } else {
        ctx.setTransform(1, 0, 0, 1, 0, 0)
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        ctx.setTransform(panZoom.scale, 0, 0, panZoom.scale, panZoom.x, panZoom.y)
      }
    } else {
      ctx.setTransform(1, 0, 0, 1, 0, 0)
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.setTransform(panZoom.scale, 0, 0, panZoom.scale, panZoom.x, panZoom.y)
    }
  }
  function drawCompleteShapes(stack = segmentationState.tags.stack) {
    const canvas = canvasCompleteFigures.current
    const ctx = canvas.getContext('2d')

    const canvas2 = virtualCanvasRef.current
    const ctx2 = canvas2.getContext('2d')

    const canvas3 = auxiliarVirtualCanvasRef.current
    const ctx3 = canvas3.getContext('2d')

    const segmentations = stack

    const actSegmentations = segmentations.map((seg) => {
      return { ...seg, color: segmentationState.tags.all[seg.type].color }
    })

    ctx.setTransform(1, 0, 0, 1, 0, 0) // reset transform
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    ctx.imageSmoothingEnabled = false
    ctx2.setTransform(1, 0, 0, 1, 0, 0)
    ctx2.clearRect(0, 0, canvas.width, canvas.height)
    ctx2.setTransform(panZoom.scale, 0, 0, panZoom.scale, panZoom.x, panZoom.y)
    ctx2.imageSmoothingEnabled = false
    ctx3.setTransform(1, 0, 0, 1, 0, 0)
    ctx3.clearRect(0, 0, canvas.width, canvas.height)
    ctx3.imageSmoothingEnabled = false
    ctx3.globalCompositeOperation = 'source-over'
    ctx3.imageSmoothingEnabled = false

    ctx2.lineWidth = 1
    actSegmentations.forEach((segmentation) => {
      segmentation.points.forEach((point) => {
        ctx2.lineTo(point.x, point.y)
      })
      if (segmentation.completed) {
        ctx2.fillStyle = segmentation.hover ? '#fff' : segmentation.color
        if (segmentation.deleted || segmentation.hide) {
          ctx2.globalCompositeOperation = 'destination-out'
          ctx2.fill()
        } else {
          ctx2.globalCompositeOperation = 'source-over'
          ctx2.fill()
        }
      }
      ctx2.beginPath()
    })

    ctx3.fillRect(
      panZoom.x,
      panZoom.y,
      labelerState.image.props.w * panZoom.scale,
      labelerState.image.props.h * panZoom.scale
    )
    ctx3.globalCompositeOperation = 'source-in'
    ctx3.drawImage(canvas2, 0, 0)
    ctx.globalAlpha = GLOBAL_ALPHA
    ctx.drawImage(canvas3, 0, 0)
  }
  const clickOnElement = (x, y) => {
    for (let index = segmentationState.tags.stack.length - 1; index >= 0; index--) {
      if (!segmentationState.tags.stack[index].deleted) {
        if (isInsidePoly(segmentationState.tags.stack[index].points, { x, y })) {
          dispatch({
            type: 'setSegmentationType',
            payload: {
              index,
              type: segmentationState.tags.stack[index].type,
              obj: segmentationState.tags.stack[index]
            }
          })
          break
        }
      }
    }
  }
  function drawLineGuide(x, y) {
    const canvas = canvasLineGuide.current
    const ctx = canvas.getContext('2d')
    ctx.setTransform(1, 0, 0, 1, 0, 0)
    ctx.globalAlpha = 1
    ctx.setTransform(1, 0, 0, 1, 0, 0)
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    ctx.beginPath()
    ctx.lineWidth = 1 / panZoom.scale
    ctx.setTransform(panZoom.scale, 0, 0, panZoom.scale, panZoom.x, panZoom.y)
    const lastShape = segmentationState.tags.stack[segmentationState.tags.stack.length - 1]
    if (lastShape && !lastShape.completed) {
      const oldX = lastShape.points[lastShape.points.length - 1].x
      const oldY = lastShape.points[lastShape.points.length - 1].y
      const newX = Math.round(x)
      const newY = Math.round(y)

      const newPixelsFound = bresenhamPoints(oldX, oldY, newX, newY)
      const newPointsFound = []

      newPixelsFound.forEach((pixel, index) => {
        const nextPoint = newPixelsFound[index + 1]
        if (nextPoint && (pixel.x !== nextPoint.x || pixel.y !== nextPoint.y)) {
          newPointsFound.push({ x: pixel.x, y: nextPoint.y })
          newPointsFound.push({ x: nextPoint.x, y: nextPoint.y })
        } else {
          newPointsFound.push(pixel)
        }
      })

      ctx.setLineDash([])
      ctx.strokeStyle = '#fff'
      newPointsFound.forEach((point) => {
        ctx.lineTo(point.x, point.y)
      })
      ctx.stroke()

      ctx.setLineDash([1, 1])
      ctx.beginPath()
      ctx.strokeStyle = '#000'
      newPointsFound.forEach((point) => {
        ctx.lineTo(point.x, point.y)
      })
      ctx.stroke()
    }
  }

  function drawMagicLineGuide(x, y) {
    if (x > 0 && x < labelerState.image.props.w && y > 0 && y < labelerState.image.props.h) {
      const canvas = canvasLineGuide.current
      const ctx = canvas.getContext('2d')
      ctx.setTransform(1, 0, 0, 1, 0, 0) // reset transform
      ctx.globalAlpha = 1 //
      ctx.setTransform(1, 0, 0, 1, 0, 0)
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.setTransform(panZoom.scale, 0, 0, panZoom.scale, panZoom.x, panZoom.y)

      ctx.beginPath()
      ctx.setLineDash([])
      ctx.moveTo(x, 0)
      ctx.lineTo(x, labelerState.image.props.h)
      ctx.lineWidth = 1 / panZoom.scale
      ctx.strokeStyle = '#000000'
      ctx.stroke()
      ctx.beginPath()

      ctx.setLineDash([3 / panZoom.scale, 3 / panZoom.scale])
      ctx.moveTo(x, 0)
      ctx.lineTo(x, labelerState.image.props.h)
      ctx.lineWidth = 1 / panZoom.scale
      ctx.strokeStyle = '#ffffff'
      ctx.stroke()
      ctx.beginPath()

      ctx.setLineDash([])
      ctx.moveTo(0, y)
      ctx.lineTo(labelerState.image.props.w, y)
      ctx.lineWidth = 1 / panZoom.scale
      ctx.strokeStyle = '#000000'
      ctx.stroke()
      ctx.beginPath()

      ctx.setLineDash([3 / panZoom.scale, 3 / panZoom.scale])
      ctx.moveTo(0, y)
      ctx.lineTo(labelerState.image.props.w, y)
      ctx.lineWidth = 1 / panZoom.scale
      ctx.strokeStyle = '#ffffff'
      ctx.stroke()
    }
  }
  function deleteIncompleteSegmentation() {
    if (segmentationState.tags.stack.length) {
      dispatch({ type: 'deleteIncompleteSegmentation' })
      const canvas = canvasIncompleteFigures.current
      const ctx = canvas.getContext('2d')
      ctx.setTransform(1, 0, 0, 1, 0, 0)
      ctx.clearRect(0, 0, canvas.width, canvas.height)

      const canvas1 = canvasLineGuide.current
      const ctx1 = canvas1.getContext('2d')
      ctx1.setTransform(1, 0, 0, 1, 0, 0)
      ctx1.clearRect(0, 0, canvas1.width, canvas1.height)
    }
  }

  const verifyCompletedShape = (point) => {
    const firstPoint = getFirstPoint()
    if (isStartingPoint) {
      if (
        Math.sqrt(Math.pow(firstPoint.x - point.x, 2) + Math.pow(firstPoint.y - point.y, 2)) <
        7 / panZoom.scale
      ) {
        return true
      }
    } else {
      if (
        Math.sqrt(Math.pow(firstPoint.x - point.x, 2) + Math.pow(firstPoint.y - point.y, 2)) >
        7 / panZoom.scale
      ) {
        setIsStartingPoint(true)
      }
    }
    return false
  }
  const getFirstPoint = (stack = segmentationState.tags.stack) => {
    if (stack.length) {
      const lastShape = stack[stack.length - 1]
      if (!lastShape.completed) return lastShape.points[0]
    }
    return null
  }

  const cancelMagic = () => {
    const canvas = canvasAutoSegmentationRegion.current
    const ctx = canvas.getContext('2d')

    const canvas1 = canvasAutoSegmentation.current
    const ctx1 = canvas1.getContext('2d')

    ctx1.beginPath()
    ctx1.setTransform(1, 0, 0, 1, 0, 0)
    ctx1.clearRect(0, 0, canvas.width, canvas.height)

    ctx.beginPath()
    ctx.setTransform(1, 0, 0, 1, 0, 0)
    ctx.clearRect(0, 0, canvas.width, canvas.height)

    setRegion({
      down: false,
      x: 0,
      y: 0,
      w: 0,
      h: 0,
      edit: false,
      toolsPosX: 0,
      toolsPosY: 0,
      background: { isActive: false, down: false },
      foreground: { isActive: false, down: false }
    })
    setBackgroundPoints([])
    setMask(null)
    setId(null)
  }

  const fixBackground = () => {
    setRegion({
      ...region,
      foreground: { isActive: false, down: false },
      background: { isActive: true, down: false }
    })
  }

  const confirmMask = () => {
    let index = segmentationState.selectedSegmentationIndex
    const isDeleteMode = labelerState.mode === 'erase'
    index = isDeleteMode ? '_delete' : index

    const id = uuidv4()
    const s = {
      points: mymask.pos,
      completed: true,
      deleted: false,
      type: index,
      id,
      hide: false
    }
    dispatch({ type: 'addSegmentationStack', payload: s })
    dispatch({ type: 'addShape', payload: { index, id } })
    drawPoints(s)
    dispatch({ type: 'setSaveTags' })
    cancelMagic()
    dispatch({ type: 'redraw' })
  }

  return (
    <>
      <canvas className="layout" ref={canvasCompleteFigures} />
      <canvas className="layout" ref={canvasIncompleteFigures} />
      <canvas className="layout" ref={canvasResult} />
      <canvas className="layout" ref={canvasAutoSegmentation} />
      <canvas className="layout" ref={canvasAutoSegmentationRegion} />
      <canvas className="layout" ref={canvasLineGuide} />
      <SamContainer
        labeler={labeler}
        mouse={mouse}
        panZoom={panZoom}
        image={image}
        tensor={tensor}
      />
      {region.edit ? (
        <>
          <Tooltip title="Regenerate Mask">
            <ButtonComponent
              variant="contained"
              color="primary"
              onClick={() => getMask()}
              style={{ position: 'absolute', top: region.toolsPosY - 40, left: region.toolsPosX }}
            >
              <Replay />
            </ButtonComponent>
          </Tooltip>
          <Tooltip title="Remove Background ">
            <ButtonComponent
              disabled={region.background.isActive}
              variant="contained"
              color="primary"
              onClick={fixBackground}
              style={{
                position: 'absolute',
                top: region.toolsPosY - 40,
                left: region.toolsPosX + 50
              }}
            >
              <Create />
            </ButtonComponent>
          </Tooltip>
          <Tooltip title="Confirm Mask">
            <ButtonComponent
              variant="contained"
              color="primary"
              onClick={confirmMask}
              style={{
                position: 'absolute',
                top: region.toolsPosY - 40,
                left: region.toolsPosX + 140
              }}
            >
              <Check />
            </ButtonComponent>
          </Tooltip>
          <Tooltip title="Cancel Mask">
            <ButtonComponent
              variant="contained"
              color="primary"
              onClick={cancelMagic}
              style={{
                position: 'absolute',
                top: region.toolsPosY - 40,
                left: region.toolsPosX + 190
              }}
            >
              <Cancel />
            </ButtonComponent>
          </Tooltip>
        </>
      ) : null}
    </>
  )
}
