import { useEffect, useRef } from 'react'

export function useEventListener(eventName, handler, element = window, capture = false) {
  const savedHandler = useRef()
  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    const isSupported = element && element.addEventListener
    if (!isSupported) return
    const eventListener = (event) => savedHandler.current(event)
    element.addEventListener(eventName, eventListener, capture)
    return () => {
      element.removeEventListener(eventName, eventListener, capture)
    }
  }, [eventName, element])
}
