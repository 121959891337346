import React from 'react'
import { styled } from '@mui/material/styles'
import Searcher from './components/Searcher'
import Sorter from './components/Sorter'
import Resizer from './components/Resizer'

const TopFilterContainer = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  padding: '.2rem',

  [theme.breakpoints.down('sm')]: {
    display: 'block'
  }
}))

const ResizeSortContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 10,
  padding: 0,
  margin: 0,
  [theme.breakpoints.down('s')]: {
    display: 'block'
  }
}))

const TopFilterItems = () => {
  return (
    <TopFilterContainer>
      <Searcher />
      <ResizeSortContainer>
        <Sorter />
        <Resizer />
      </ResizeSortContainer>
    </TopFilterContainer>
  )
}

export default TopFilterItems
