import React, { useContext } from 'react'
import {
  Badge,
  Checkbox,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Avatar
} from '@mui/material'
import ReviewedIcon from '@mui/icons-material/ThumbUp'
import SkipIcon from '@mui/icons-material/DoNotDisturbOn'
import DoneIcon from '@mui/icons-material/Done'
import { styled } from '@mui/material/styles'
import { FiltersContext } from '../../../../context/FiltersContext'
import CardImage from './components/cardImage/CardImage'
import { useSelector } from 'react-redux'

const ContainerIcons = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  position: 'absolute',
  top: -12,
  right: 1,
  gap: 3
}))

const iconStyles = { color: '#2c0f6d', width: 15, height: 15 }

const VisualizerCard = ({ image, imageSelected, clickFunction, doubleClickFunction }) => {
  const { dispatch } = useContext(FiltersContext)
  const project = useSelector((state) => state.project)

  const imageSelectedStyles = () => {
    if (imageSelected.id === image.id)
      return {
        border: '3px solid #2c0f6d',
        borderRadius: '5px'
      }
    else {
      return {
        border: '0pX',
        borderRadius: '0px'
      }
    }
  }

  const handleClick = () => {
    clickFunction(image)
    dispatch({ type: 'setSelectedImage', payload: image })
  }

  const handleDoubleClick = () => {
    doubleClickFunction(image.id)
  }

  const getImageAvatar = (icon, displayCondition) => {
    return (
      <Avatar
        sx={{
          bgcolor: '#ffffff',
          border: '1px solid #2c0f6d',
          width: 20,
          height: 20,
          padding: 1.5,
          display: displayCondition ? 'auto' : 'none',
          color: '#2c0f6d'
        }}
      >
        {icon}
      </Avatar>
    )
  }

  return (
    <Badge
      badgeContent={
        <Checkbox
          sx={{
            left: -20,
            top: 20,
            display: imageSelected.id === image.id ? 'block' : 'none',
            color: '#2c0f6d',
            backgroundColor: '#ffffff',
            borderRadius: '25%',
            padding: 0.1,
            border: '1px solid #2c0f6d'
          }}
          checked={true}
          color="primary"
        />
      }
    >
      <Card sx={{ margin: 1, ...imageSelectedStyles() }}>
        <CardActionArea onClick={handleClick} onDoubleClick={handleDoubleClick}>
          <CardImage image={image} project={project} />
          <CardContent sx={{ height: 50, position: 'relative', overflow: 'visible' }}>
            <ContainerIcons>
              {getImageAvatar(<ReviewedIcon sx={iconStyles} />, image.accepted)}
              {getImageAvatar(<SkipIcon sx={iconStyles} />, image.skiped)}
              {getImageAvatar(<DoneIcon sx={iconStyles} />, image.done)}
            </ContainerIcons>
            <Typography variant="body2" color="text.secondary" noWrap align="left">
              {image.name}
            </Typography>
          </CardContent>
        </CardActionArea>
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{
            position: 'absolute',
            right: 15,
            bottom: 10
          }}
        >
          {image.index}
        </Typography>
      </Card>
    </Badge>
  )
}

export default VisualizerCard
