import React, { useRef, useState } from 'react'
import {
  Grow,
  MenuList,
  Popper,
  Paper,
  Button,
  ButtonGroup,
  ClickAwayListener,
  MenuItem
} from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { useNavigate } from 'react-router-dom'
import { sandLabelerViewed } from '../io/analytics'

const options = [
  { name: 'Start Labeling', role: 'labeler' },
  { name: 'Start Reviewing', role: 'reviewer' },
  { name: 'Start Viewing', role: 'viewer' }
]

export default function ProjectHeader(props) {
  const navigate = useNavigate()
  const anchorRef = useRef(null)
  const [selectedRole, setSelectedRole] = useState(0)
  const [roleOpen, setRoleOpen] = useState(false)

  const handleRoleItemClick = (event, index) => {
    setSelectedRole(index)
    setRoleOpen(false)
  }

  const handleRolesToggle = () => {
    setRoleOpen((prevOpen) => !prevOpen)
  }
  const handleRoleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setRoleOpen(false)
  }

  return (
    <div style={{ display: 'flex', flex: 1, justifyContent: 'end', alignItems: 'center' }}>
      <ButtonGroup
        style={{ maxHeight: 30 }}
        variant="contained"
        color="secondary"
        ref={anchorRef}
        aria-label="split button"
        disabled={!props.id}
      >
        <Button
          onClick={() => {
            sandLabelerViewed({ id: props.id })
            navigate(`/labeler?role=${options[selectedRole].role}`)
          }}
        >
          {options[selectedRole].name}
        </Button>
        <Button
          size="small"
          style={{ minWidth: 5, width: 5 }}
          aria-controls={roleOpen ? 'split-button-menu' : undefined}
          aria-expanded={roleOpen ? 'true' : undefined}
          aria-label="select role"
          aria-haspopup="menu"
          onClick={handleRolesToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={roleOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleRoleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.role}
                      selected={index === selectedRole}
                      onClick={(event) => handleRoleItemClick(event, index)}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}
