import React from 'react'
import { List, Divider } from '@mui/material'
import StatusFilter from './components/statusFilter/StatusFilter'
import CategoriesFilter from './components/categoriesFilter/CategoriesFilter'
import LabelerFilter from './components/labelerFilter/LabelerFilter'
import ReviewerFilter from './components//reviewerFilter/ReviewerFilter'
import LabelsFilter from './components/labelsCountFilter/LabelsCountFilter'
import ClassificationsFilter from './components/classificationsFilter/ClassificationsFilter'

const LateralFilterItems = () => {
  return (
    <List sx={{ height: '100%' }}>
      <StatusFilter />
      <Divider />
      <CategoriesFilter />
      <Divider />
      <ClassificationsFilter />
      <Divider />
      <LabelerFilter />
      <Divider />
      <ReviewerFilter />
      <Divider />
      <LabelsFilter />
      <Divider />
    </List>
  )
}

export default LateralFilterItems
