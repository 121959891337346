import { handleActions } from 'redux-actions'

const defaultState = {
  image: null,
  inedx: 0,
  role: 'admin',
  loading: true
}

const reducer = handleActions(
  {
    LABELER: {
      // eslint-disable-next-line no-unused-vars
      SET_IMAGE: (state, { payload: { next } }) => ({ ...state })
    }
  },
  defaultState
)

export default reducer
