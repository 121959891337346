import React from 'react'
import * as _ from 'lodash'
import Draggable from 'react-draggable'
import { Paper, List, ListItem, ListItemIcon, ListItemText, ListSubheader } from '@mui/material'

function Hotkeys(props) {
  let elements = [
    {
      divider: true,
      name: 'Tools'
    },
    {
      shortcut: 'Space',
      desc: 'Change between modes (Draw & Edit)'
    },
    {
      shortcut: 'I',
      desc: 'Auto labeling'
    },
    {
      shortcut: 'R',
      desc: 'Restore zoom'
    },
    {
      shortcut: 'M',
      desc: 'Zoom in'
    },
    {
      shortcut: 'N',
      desc: 'Zoom out'
    },
    {
      shortcut: 'Delete',
      desc: 'Delete selecte label'
    },
    {
      divider: true,
      name: 'Filters'
    },
    {
      shortcut: 'Z',
      desc: 'Restore filters'
    },
    {
      shortcut: 'X',
      desc: 'Saturation filter'
    },
    {
      shortcut: 'C',
      desc: 'Brightness filter'
    },
    {
      shortcut: 'V',
      desc: 'Contrast filter'
    },
    {
      shortcut: 'B',
      desc: 'Gray scale filter'
    },
    {
      divider: true,
      name: 'General'
    },
    {
      shortcut: 'O',
      desc: 'Download'
    }
  ]

  if (props.categories && props.categories.length) {
    const categories = _.reverse(props.categories.slice(0, 10))
    categories.forEach((cat, i) => {
      elements = [
        {
          shortcut: (categories.length - i) % 10,
          desc: cat.name
        },
        ...elements
      ]
    })
    elements = [
      {
        divider: true,
        name: 'Categories'
      },
      ...elements
    ]
  }

  return (
    <Draggable handle=".handle" axis="y" defaultPosition={{ x: 60, y: -650 }}>
      <Paper
        className="handle"
        style={{ width: 250, paddingBottom: 0, visibility: props.open ? 'visible' : 'hidden' }}
      >
        <List dense>
          {elements.map((e, i) => {
            return e.divider ? (
              <ListSubheader key={i} style={{ textAlign: 'left', lineHeight: '24px' }}>
                {e.name}
              </ListSubheader>
            ) : (
              <ListItem key={i}>
                <ListItemIcon>
                  <div
                    style={{
                      background: '#ddd',
                      color: '#777',
                      fontSize: 12,
                      padding: '2px 4px',
                      border: '1px solid #ccc',
                      borderRadius: 5,
                      minWidth: 30,
                      textAlign: 'center'
                    }}
                  >
                    <b>{e.shortcut}</b>
                  </div>
                </ListItemIcon>
                <ListItemText primary={e.desc} />
              </ListItem>
            )
          })}
        </List>
      </Paper>
    </Draggable>
  )
}

export default Hotkeys
