import React, { useRef, useState, useEffect } from 'react'
import Chart from 'chart.js/auto'

const Line = ({ className, textColor, filter, gridColor, datasets }) => {
  const chartRef = useRef(null)
  const TEXT_COLOR = '#666'
  const GRID_COLOR = 'rgba(0, 0, 0, 0.1)'

  const [chartDatasets, setChartDatasets] = useState([])

  useEffect(() => {
    const dts = datasets?.map((mData) => ({
      label: mData?.label,
      data: mData?.data?.all?.map((item) => item.doc_count),
      fill: 'none',
      backgroundColor: mData?.color,
      pointRadius: 2,
      borderColor: mData?.color,
      borderWidth: 1,
      lineTension: 0
    }))
    setChartDatasets(dts)
  }, [datasets])

  useEffect(() => {
    const _textColor = textColor || TEXT_COLOR
    const _gridColor = gridColor || GRID_COLOR

    chartRef.current = new Chart(chartRef.current, {
      type: 'line',
      data: {
        labels: datasets[0]?.data?.all?.map((item) =>
          new Date(item.key_as_string).toLocaleDateString()
        ),
        datasets: chartDatasets
      },
      options: {
        scales: {
          y: {
            ticks: {
              color: _textColor,
              beginAtZero: true,
              precision: 0
            },
            gridLines: {
              color: _gridColor
            }
          },
          x: {
            ticks: {
              color: _textColor
            },
            gridLines: {
              color: _gridColor
            }
          }
        }
      }
    })
  }, [])

  useEffect(() => {
    const filterDatasets = [...chartDatasets]

    chartRef.current.data.labels = datasets[0]?.data?.[filter]?.map((item) =>
      new Date(item.key_as_string).toLocaleDateString()
    )

    filterDatasets.forEach((_, i) => {
      filterDatasets[i].data = datasets[i].data?.[filter]?.map((item) => item.doc_count)
    })

    chartRef.current.data.datasets = filterDatasets
    chartRef.current.update()
  }, [chartDatasets, filter])

  useEffect(() => {
    const _textColor = textColor || TEXT_COLOR
    const _gridColor = gridColor || GRID_COLOR

    chartRef.current.options.plugins.legend.labels.color = _textColor
    chartRef.current.options.scales.y.ticks.color = _textColor
    chartRef.current.options.scales.y.grid.color = _gridColor
    chartRef.current.options.scales.x.ticks.color = _textColor
    chartRef.current.options.scales.x.grid.color = _gridColor
    chartRef.current.update()
  }, [textColor])

  return <canvas className={className} ref={chartRef} />
}

export default Line
