import React, { useContext } from 'react'
import { IconButton, Icon, FormControlLabel, Checkbox, List, Divider } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import ColoredDot from '../../../../../../../../../../../components/generics/ColoredDot'
import CategoryClassificationItem from './CategoryClassificationItem'
import styled from '@emotion/styled'
import { FiltersContext } from '../../../../../../../context/FiltersContext'

const CategoryContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center'
}))

const CategoryItem = ({
  category,
  handleCategory,
  existCategory,
  handleChange,
  store,
  hiddenCategories
}) => {
  const { state } = useContext(FiltersContext)
  return (
    <React.Fragment key={category.name}>
      {/* Category: Eye, Checkbox, Color and Name */}
      <CategoryContainer>
        {category.type !== 'segmentation' && (
          <IconButton onClick={() => handleCategory(category)}>
            <Icon>
              {existCategory(hiddenCategories, category) ? <VisibilityOff /> : <Visibility />}
            </Icon>
          </IconButton>
        )}
        <FormControlLabel
          label={category.name}
          sx={{ margin: 0 }}
          control={
            <CategoryContainer>
              <Checkbox
                checked={state.categories.includes(category.name)}
                onClick={handleChange}
                name={'categories'}
                value={category.name}
              />
              <ColoredDot color={category.color} />
            </CategoryContainer>
          }
        />
      </CategoryContainer>
      {/* Category Classifications */}
      <List sx={{ maxWidth: 180, paddingBottom: 1 }}>
        {store.project.categories
          .filter(
            (subcategory) =>
              subcategory.type === 'classification' && subcategory.parent === category.name
          )
          .map((classification) => (
            <CategoryClassificationItem
              classification={classification}
              handleCategory={handleCategory}
              existCategory={existCategory}
              handleChange={handleChange}
              key={classification.name}
              hiddenCategories={hiddenCategories}
            />
          ))}
      </List>
      <Divider sx={{ marginBottom: 1 }} />
    </React.Fragment>
  )
}

export default CategoryItem
