export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!, $condition: ModelUserConditionInput) {
    createUser(input: $input, condition: $condition) {
      id
      name
      email
      company
      termsAccepted
      status
      owner
      paid
      createdAt
      updatedAt
    }
  }
`
