import React, { useContext, useState, useEffect } from 'react'
import { Button, Card, Slider, CardContent, Typography, ClickAwayListener } from '@mui/material'
import Restore from '@mui/icons-material/Restore'
import Tonality from '@mui/icons-material/Tonality'
import ColorLens from '@mui/icons-material/ColorLens'
import BrightnessLow from '@mui/icons-material/BrightnessLow'
import FormatColorReset from '@mui/icons-material/FormatColorReset'
import { styled } from '@mui/material/styles'
import OpacityIcon from '@mui/icons-material/Opacity'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import { LabelerContext } from '../../../containers/labeler/context/LabelerContext'
import { DispatchContext } from '../../../containers/labeler/context/DispatchContext'
import BarTooltip from '../BarTooltip'

const IconButton = styled(Button)(() => ({
  minWidth: 48,
  width: 48
}))

export default function DefaultButtons(props) {
  const { state } = useContext(LabelerContext)
  const { dispatch } = useContext(DispatchContext)
  const [settings, setSettings] = useState([
    { name: 'Saturation', value: 100 },
    { name: 'Brightness', value: 100 },
    { name: 'Contrast', value: 100 },
    { name: 'GrayScale', value: 0 },
    { name: 'GlobalAlpha', value: 0.5 }
  ])
  const [card, setCard] = useState({ open: false, index: 0 })
  const [openGlobalAlpha, setOpenGlobalAlpha] = useState(false)

  useEffect(() => {
    if (props.tools.openCard) {
      setCard({ open: true, index: props.tools.index })
    }
  }, [props.tools])

  useEffect(() => {
    restoreValues()
  }, [state.labelerState.image.image])

  const restoreValues = () => {
    const settings = [
      { name: 'Saturation', value: 100 },
      { name: 'Brightness', value: 100 },
      { name: 'Contrast', value: 100 },
      { name: 'GrayScale', value: 0 },
      { name: 'GlobalAlpha', value: 0.5 }
    ]
    dispatch({ type: 'setCanvasSettings', payload: settings })
    setSettings([...settings])
  }

  const changeValueSetting = (event) => {
    const actualSettings = settings
    actualSettings[card.index] = event.target
    dispatch({ type: 'setCanvasSettings', payload: actualSettings })
    setSettings([...actualSettings])
  }

  const handleClick = (index) => () => {
    setCard({ open: true, index })
  }

  const handleClickAway = () => {
    setOpenGlobalAlpha(false)
    setCard({ open: false, index: 0 })
    dispatch({ type: 'setOpenCard', payload: { open: false, index: 0 } })
  }

  const handlerGlobalAlpha = () => {
    const value = settings[4].value === 0.5 ? 1 : 0.5

    const actualSettings = settings
    actualSettings[4] = { ...actualSettings[4], value }
    dispatch({ type: 'setCanvasSettings', payload: actualSettings })
    setSettings([...actualSettings])
  }
  const handlerValueGlobalAlpha = (e, value) => {
    const actualSettings = settings
    actualSettings[4] = { ...actualSettings[4], value }
    dispatch({ type: 'setCanvasSettings', payload: actualSettings })
    setSettings([...actualSettings])
  }
  return (
    <>
      {openGlobalAlpha && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Card style={{ position: 'fixed', top: 4 * 30 + 130, width: 150, left: '54px' }}>
            <CardContent>
              <Typography id="label">{'Global Alpha (' + settings[4].value + ')'}</Typography>
              <Slider
                value={settings[4].value}
                aria-labelledby="label"
                onChange={handlerValueGlobalAlpha}
                min={0}
                step={0.1}
                max={1}
              />
            </CardContent>
          </Card>
        </ClickAwayListener>
      )}
      {card.open && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Card style={{ top: card.index * 30 + 130, position: 'fixed', width: 150, left: '54px' }}>
            <CardContent>
              <Typography id="label">
                {settings[card.index].name}({settings[card.index].value})
              </Typography>
              <Slider
                id={settings[card.index].name}
                name={settings[card.index].name}
                value={settings[card.index].value}
                aria-labelledby="label"
                onChange={(e) => changeValueSetting(e)}
                min={0}
                step={1}
                max={500}
              />
            </CardContent>
          </Card>
        </ClickAwayListener>
      )}
      <BarTooltip title="[Z] Restore Values">
        <IconButton size="small" onClick={restoreValues}>
          <Restore fontSize="small" />
        </IconButton>
      </BarTooltip>
      <BarTooltip title="[X] Saturation">
        <IconButton size="small" onClick={handleClick(0)}>
          <ColorLens fontSize="small" />
        </IconButton>
      </BarTooltip>
      <BarTooltip title="[C] Brightness">
        <IconButton size="small" onClick={handleClick(1)}>
          <BrightnessLow fontSize="small" />
        </IconButton>
      </BarTooltip>
      <BarTooltip title="[V] Contrast">
        <IconButton size="small" onClick={handleClick(2)}>
          <Tonality fontSize="small" />
        </IconButton>
      </BarTooltip>
      <BarTooltip title="[B] Gray Scale">
        <IconButton size="small" onClick={handleClick(3)}>
          <FormatColorReset fontSize="small" />
        </IconButton>
      </BarTooltip>
      <BarTooltip onClick={handlerGlobalAlpha} title="Add/Remove Transparency">
        <div style={{ position: 'relative' }}>
          <OpacityIcon fontSize="small" />
          <Button
            style={{ position: 'absolute' }}
            onClick={(e) => {
              setOpenGlobalAlpha(true)
              e.stopPropagation()
            }}
            sx={{
              minWidth: 1,
              width: 1,
              height: 5
            }}
          >
            <ArrowDropDown style={{ position: 'absolute', left: 0, top: 0 }} />
          </Button>
        </div>
      </BarTooltip>
    </>
  )
}
