import { findIndex as _findIndex } from 'lodash'
import { API, graphqlOperation } from 'aws-amplify'

import { s3UploadJson } from '../../../services/aws'
import { getTimeNow } from '../../../utils/util'
import { createTagState } from '../tagState/tagState'
import * as mutations from './mutations'
import * as queries from './queries'

/**
 * Remove properties from object using the provided keys.
 *
 * @param {Object} object
 * @param  {...string} keys
 * @returns The object without the properties with the keys send
 */
const removeProperties = (object, ...keys) => {
  return Object.entries(object).reduce(
    (prev, [key, value]) => ({
      ...prev,
      ...(!keys.includes(key) && {
        [key]: value
      })
    }),
    {}
  )
}

const formatAnnotations = ({ segmentations, points, rects, polygons, lines, classification }) => {
  // Clean Annotations
  let cleanPoint = []
  let cleanRects = []
  let cleanPolys = []
  let cleanLines = []

  cleanPoint = points.map((item) => {
    return removeProperties(item, 'show', 'hover', 'parent')
  })
  cleanRects = rects.map((item) => {
    return removeProperties(item, 'show', 'hover')
  })
  cleanPolys = polygons.map((item) => {
    return removeProperties(item, 'show', 'hover')
  })

  cleanLines = lines.map((item) => {
    return removeProperties(item, 'show', 'hover')
  })

  // Format Segmentation
  const segmentationObj = {
    all: Object.assign({}, segmentations.all),
    stack: segmentations.stack
  }

  const segmentationAll = Object.fromEntries(
    Object.entries(segmentationObj.all).map(([key, value]) => {
      return [
        key,
        Object.fromEntries(
          Object.entries(value)
            .filter(([prop]) => prop !== 'hover')
            .filter(([prop]) => prop !== 'hide')
        )
      ]
    })
  )

  // Format Classifications
  const cls = []
  for (const key in classification) {
    cls.push({
      name: classification[key].name,
      selected: classification[key].selected,
      values: classification[key].values,
      options: classification[key].options,
      multiSelect: classification[key].multiSelect
    })
  }
  segmentationObj.all = segmentationAll

  if (segmentationObj.stack[segmentationObj.stack.length - 1]) {
    if (!segmentationObj.stack[segmentationObj.stack.length - 1].completed) {
      segmentationObj.stack.pop()
    }
  }

  return {
    segmentations: segmentationObj,
    points: cleanPoint,
    rects: cleanRects,
    polygons: cleanPolys,
    lines: cleanLines,
    classification: cls
  }
}

const getCategoryCount = (rects, polys, points, lines, circles, segmentations = {}) => {
  const categories = []

  for (let i = 0; i < rects.length; i++) {
    const tag = rects[i]
    const index = _findIndex(categories, ['name', tag.name])
    if (index >= 0) {
      categories.splice(index, 1, {
        name: tag.name,
        count: categories[index].count + 1
      })
    } else {
      categories.push({
        name: tag.name,
        count: 1
      })
    }
  }
  for (let i = 0; i < polys.length; i++) {
    const tag = polys[i]
    const index = _findIndex(categories, ['name', tag.name])
    if (index >= 0) {
      categories.splice(index, 1, {
        name: tag.name,
        count: categories[index].count + 1
      })
    } else {
      categories.push({
        name: tag.name,
        count: 1
      })
    }
  }
  for (let i = 0; i < points.length; i++) {
    const tag = points[i]
    const index = _findIndex(categories, ['name', tag.name])
    if (index >= 0) {
      categories.splice(index, 1, {
        name: tag.name,
        count: categories[index].count + 1
      })
    } else {
      categories.push({
        name: tag.name,
        count: 1
      })
    }
  }
  for (let i = 0; i < lines.length; i++) {
    const tag = lines[i]
    const index = _findIndex(categories, ['name', tag.name])
    if (index >= 0) {
      categories.splice(index, 1, {
        name: tag.name,
        count: categories[index].count + 1
      })
    } else {
      categories.push({
        name: tag.name,
        count: 1
      })
    }
  }
  // TODO: Uncomment when add circles
  // for (let i = 0; i < circles.length; i++) {
  //   const tag = circles[i]
  //   const index = _findIndex(categories, ['name', tag.name])
  //   if (index >= 0) {
  //     categories.splice(index, 1, {
  //       name: tag.name,
  //       count: categories[index].count + 1
  //     })
  //   } else {
  //     categories.push({
  //       name: tag.name,
  //       count: 1
  //     })
  //   }
  // }

  for (const property in segmentations.all) {
    if (segmentations.all[property].shapes.length > 0) {
      categories.push({ name: property, count: 1 })
    }
  }

  return categories
}

let savePromise = null

const updateTagInDB = async (tag, userId, role) => {
  try {
    savePromise = API.graphql(graphqlOperation(mutations.updateTag, { input: tag }))

    const result = await savePromise

    if (
      (!result.errors || result.errors?.length <= 0) &&
      ('done' in tag || 'accepted' in tag || 'skiped' in tag)
    ) {
      // TODO: Move this to the Backend
      let stage = 'init'
      let action = 'create'
      if ('skiped' in tag && !tag.skiped) {
        stage = 'inManualLabeling'
        action = 'unskipped'
      }
      if ('done' in tag && !tag.done) {
        stage = 'inManualLabeling'
        action = 'undone'
      }
      if ('accepted' in tag && !tag.accepted) {
        stage = 'inManualReview'
        action = 'rejected'
      }
      if ('skiped' in tag && tag.skiped) {
        stage = 'inManualLabeling'
        action = 'skipped'
      }
      if ('done' in tag && tag.done) {
        stage = 'inManualLabeling'
        action = 'done'
      }
      if ('accepted' in tag && tag.accepted) {
        stage = 'inManualReview'
        action = 'reviewed'
      }

      await createTagState(stage, action, userId, tag.id, role)
    }

    return result
  } catch (error) {
    return error
  }
}

const cancelUpdatedTagInDB = () => {
  if (savePromise) {
    API.cancel(savePromise, 'Save tags canceled')
  }
}

/**
 * Updated Tag in DB
 *
 * @param {*} imageId
 * @param {*} loadingTags
 * @param {*} projectId
 * @param {*} annotations { segmentations, points, rects, polygons, lines, classification }
 * @param {*} labelertime
 * @param {*} reviewertime
 * @param {*} currentAttemp
 * @param {*} extras
 * @returns
 */
export const updateTag = async (
  tagId,
  userId,
  role,
  loadingTags,
  projectId,
  annotations,
  labelertime,
  reviewertime,
  currentAttemp,
  imageKey = null,
  extras = {}
) => {
  if (loadingTags) return { data: { currentAttemp }, errors: [{ message: 'Tags Loading.' }] }

  try {
    const cleanAnnotations = formatAnnotations(annotations)
    if (tagId) {
      const catCount = getCategoryCount(
        cleanAnnotations.rects,
        cleanAnnotations.polygons,
        cleanAnnotations.points,
        cleanAnnotations.lines,
        cleanAnnotations.circles,
        cleanAnnotations.segmentations
      )
      const numSegmentations = Object.values(cleanAnnotations.segmentations.all).filter(
        (segmentation) => segmentation.shapes.length > 0
      )
      // Save Data in DB
      cancelUpdatedTagInDB()
      savePromise = updateTagInDB(
        {
          id: tagId,
          updatedAt: getTimeNow(),
          annotations: {
            rects: cleanAnnotations.rects,
            polys: cleanAnnotations.polygons,
            points: cleanAnnotations.points,
            lines: cleanAnnotations.lines,
            classification: cleanAnnotations.classification,
            circles: [] // getRealCircles(circles, ratio) TODO: Uncomment when add circles
          },
          categoriesCount: catCount,
          labelerTime: Math.trunc(
            (labelertime.hour * 60 * 60 + labelertime.minute * 60 + labelertime.second) * 1000
          ),
          reviewerTime: Math.trunc(
            (reviewertime.hour * 60 * 60 + reviewertime.minute * 60 + reviewertime.second) * 1000
          ),
          count:
            cleanAnnotations.rects.length +
            cleanAnnotations.polygons.length +
            cleanAnnotations.points.length +
            cleanAnnotations.lines.length +
            // cleanAnnotations.circles.length + TODO: Uncomment when add circlesr
            numSegmentations.length,
          ...extras
        },
        userId,
        role
      )

      const { data, errors } = await savePromise
        .then((r) => ({ data: r.data?.updateTag, errors: r.errors }))
        .catch((e) => ({ errors: [{ message: e }] }))

      let uploadSegmentation = true
      if (cleanAnnotations.segmentations) {
        uploadSegmentation = await s3UploadJson(
          JSON.stringify(cleanAnnotations.segmentations),
          tagId,
          projectId,
          imageKey
        )
      }

      return {
        data: { ...data, currentAttemp: !uploadSegmentation ? currentAttemp + 1 : 0 },
        errors: [
          ...(errors || []),
          ...(!uploadSegmentation ? [{ message: 'Error Uploading Segmentation.' }] : [])
        ]
      }
    }

    return { data: { currentAttemp }, errors: [{ message: 'No tag id.' }] }
  } catch (e) {
    return {
      data: { currentAttemp: currentAttemp + 1 },
      errors: [{ message: e }]
    }
  }
}

export const updateTagStatus = async (tagId, userId, role, extras = {}) => {
  try {
    if (tagId) {
      // Save Data in DB
      const { data, errors } = await updateTagInDB(
        {
          id: tagId,
          updatedAt: getTimeNow(),
          ...extras
        },
        userId,
        role
      )
        .then((r) => ({ data: r.data?.updateTag, errors: r.errors }))
        .catch((e) => ({ errors: [{ message: e }] }))

      return { data, errors }
    }

    return { data: null, errors: [{ message: 'No tag id.' }] }
  } catch (e) {
    return { data: null, errors: [{ message: e }] }
  }
}

export const listTagsIds = async (projectId, labelerId, order, nextToken) => {
  try {
    if (projectId) {
      // Get Tags from DB
      const { data, errors } = await getTagsIdsFromDB(projectId, labelerId, order, nextToken)
        .then((r) => ({ data: r.data?.searchTags, errors: r.errors }))
        .catch((e) => ({ errors: [{ message: e }] }))

      return { data, errors }
    }

    return { data: null, errors: [{ message: 'No tag id.' }] }
  } catch (e) {
    return { data: null, errors: [{ message: e }] }
  }
}

export const listReviewerTagsIds = async (projectId, reviewerId, order, nextToken) => {
  try {
    if (projectId) {
      // Get Tags from DB
      const { data, errors } = await getReviewerTagsIdsFromDB(
        projectId,
        reviewerId,
        order,
        nextToken
      )
        .then((r) => ({ data: r.data?.searchTags, errors: r.errors }))
        .catch((e) => ({ errors: [{ message: e }] }))

      return { data, errors }
    }

    return { data: null, errors: [{ message: 'No tag id.' }] }
  } catch (e) {
    return { data: null, errors: [{ message: e }] }
  }
}

export const listViewerTagsIds = async (projectId, limit, sortDirection, nextToken) => {
  try {
    if (projectId) {
      // Get Tags from DB
      const { data, errors } = await getViewerTagsIds(projectId, limit, sortDirection, nextToken)
        .then((r) => ({ data: r.data?.searchTags, errors: r.errors }))
        .catch((e) => ({ errors: [{ message: e }] }))

      return { data, errors }
    }

    return { data: null, errors: [{ message: 'No tag id.' }] }
  } catch (e) {
    return { data: null, errors: [{ message: e }] }
  }
}

const getTagsIdsFromDB = async (projectId, labelerId, sortDirection, nextToken) =>
  API.graphql(
    graphqlOperation(queries.listTagsIds, {
      filter: {
        tagProjectId: { eq: projectId },
        done: { eq: false },
        tagLabelerId: { eq: labelerId }
      },
      limit: 20,
      sort: { field: 'assignedAt', direction: sortDirection },
      nextToken
    })
  )

const getReviewerTagsIdsFromDB = async (projectId, reviewerId, sortDirection, nextToken) =>
  API.graphql(
    graphqlOperation(queries.listTagsIds, {
      filter: {
        tagProjectId: { eq: projectId },
        tagReviewerId: { eq: reviewerId }
      },
      limit: 5,
      sort: { field: 'assignedAt', direction: sortDirection },
      nextToken
    })
  )

const getViewerTagsIds = async (
  projectId,
  limit,
  sortDirection, // asc desc
  nextToken,
  filters = { and: [], or: [] }
) =>
  await API.graphql(
    graphqlOperation(queries.listTagsIds, {
      filter:
        filters.and.length === 0 && filters.or.length === 0
          ? {
              tagProjectId: { eq: projectId }
            }
          : filters.and.length === 0
          ? {
              tagProjectId: { eq: projectId },
              or: filters.or
            }
          : filters.or.length === 0
          ? {
              tagProjectId: { eq: projectId },
              and: filters.and
            }
          : {
              tagProjectId: { eq: projectId },
              and: filters.and,
              or: filters.or
            },
      limit,
      sort: { field: 'createdAt', direction: sortDirection },
      nextToken
    })
  )

const getTagsFromDB = async (projectId, limit, sortDirection, nextToken, filters) =>
  API.graphql(
    graphqlOperation(queries.searchTags, {
      filter:
        filters.and.length === 0 && filters.or.length === 0
          ? {
              tagProjectId: { eq: projectId }
            }
          : filters.and.length === 0
          ? {
              tagProjectId: { eq: projectId },
              or: filters.or
            }
          : filters.or.length === 0
          ? {
              tagProjectId: { eq: projectId },
              and: filters.and
            }
          : {
              tagProjectId: { eq: projectId },
              and: filters.and,
              or: filters.or
            },
      limit,
      sort: { field: 'createdAt', direction: sortDirection },
      nextToken
    })
  )

export const getAllTags = async (
  projectId,
  limit,
  sortDirection,
  nextToken,
  filters = { and: [], or: [] }
) => {
  try {
    if (projectId) {
      // Get Tags from DB
      const { data, errors } = await getTagsFromDB(
        projectId,
        limit,
        sortDirection,
        nextToken,
        filters
      )
        .then((r) => ({ data: r.data?.searchTags, errors: r.errors }))
        .catch((e) => ({ errors: [{ message: e }] }))

      return { data, errors }
    }

    return { data: null, errors: [{ message: 'No tag id.' }] }
  } catch (e) {
    return { data: null, errors: [{ message: e }] }
  }
}
