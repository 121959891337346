import { handleActions } from 'redux-actions'

const initialState = {
  isOpen: false,
  title: '',
  text: '',
  categories: [],
  index: 0,
  okText: null,
  isOpenLabelerMetricsDialog: false,
  isOpenReviwerMetricsDialog: false,
  labelerEmail: '',
  labelerId: '',
  reviwerEmail: '',
  reviwerId: '',
  openSnack: false
}

const reducer = handleActions(
  {
    DIALOG: {
      SET_STATUS: (state, { payload: { value } }) => ({ ...state, isOpen: value }),
      SET_TITLE: (state, { payload: { title } }) => ({ ...state, title }),
      SET_TEXT: (state, { payload: { text } }) => ({ ...state, text }),
      SET_CATEGORIES: (state, { payload: { categories } }) => ({
        ...state,
        categories
      }),
      SET_ALL_PROPERTIES: (
        state,
        { payload: { isOpen, index, title, text, okText, categories } }
      ) => ({
        ...state,
        isOpen,
        index,
        title,
        text,
        okText,
        categories
      }),
      SET_STATUS_LABELER_METRICS_DIALOG: (state, { payload: { value } }) => ({
        ...state,
        isOpenLabelerMetricsDialog: value
      }),
      SET_STATUS_REVIWER_METRICS_DIALOG: (state, { payload: { value } }) => ({
        ...state,
        isOpenReviwerMetricsDialog: value
      }),
      SET_LABELER_EMAIL: (state, { payload: { email, id } }) => ({
        ...state,
        labelerEmail: email,
        labelerId: id
      }),
      SET_REVIWER_EMAIL: (state, { payload: { email, id } }) => ({
        ...state,
        reviwerEmail: email,
        reviwerId: id
      }),
      SET_OPEN_SNACK: (state, { payload: { open } }) => ({ ...state, openSnack: open })
    }
  },
  initialState
)

export default reducer
