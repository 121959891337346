import React, { useContext } from 'react'
import '../../../styles/Timer.css'
import { TimerController } from '../controller/TimerController'
import { LabelerContext } from '../context/LabelerContext'
import { useLabelerMachine } from '../context/MachineContext'
export default function Timer() {
  const { state } = useContext(LabelerContext)
  const { state: MachineState } = useLabelerMachine()
  const { labelerState } = state

  const time = TimerController(state.timerState, labelerState.role, MachineState)
  return (
    <div className="timer-container">
      {labelerState.role !== 'viewer' && (
        <div className="timer notranslate">
          <p>{time[labelerState.role].h} </p>:<p>{time[labelerState.role].m} </p>:
          <p>{time[labelerState.role].s} </p>
        </div>
      )}
    </div>
  )
}
