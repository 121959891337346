import { useEffect, useState, useRef } from 'react'
import { getTags } from '../../../services/graphql'
import { s3GetJson } from '../../../io/aws/s3'

export default function useGetTags(categories, image, projectId, dispatch, machineState, send) {
  const [landmarks, setLandmarks] = useState([])
  const [segmentations, setSegmentations] = useState({ all: [], stack: [] })
  const [rects, setRects] = useState([])
  const [polygons, setPolygons] = useState([])
  const [lines, setLines] = useState([])
  const [classifications, setClassifications] = useState([])
  const [projectCategories, setProjectCategories] = useState([])
  const [defaultCategory, setDefaultCategory] = useState({ type: 'none' })
  const [time, setTime] = useState({ labeler: null, reviewer: null })
  const [containedChildrens, setContainedChildrens] = useState(false)
  const imageRef = useRef({ id: null })
  const [dispatchOrder, setDispatchOrder] = useState(null)

  const getAllTags = async () => {
    try {
      const segmentation = await s3GetJson(`${projectId}/${image.id}.json`)

      const tags = await getTags(image.id)
      const classificationCategories = categories.filter(
        (cat) => cat.type === 'classification' && !cat.parent
      )
      const classificationsTags = []
      const classAnnotations =
        tags.data.getTag.annotations.classification !== null
          ? tags.data.getTag.annotations.classification
          : []
      classificationCategories.forEach((classification) => {
        const category = classAnnotations.find((item) => item.name === classification.name)
        classificationsTags.push({
          ...classification,
          values: category?.values ? category.values : [],
          selected: category?.selected ? category.selected : '',
          options:
            classification.classType === 'boolean' ? ['True', 'False'] : classification.options
        })
      })

      const all = {}
      categories.forEach((category) => {
        if (category.type === 'segmentation') {
          Object.defineProperty(all, category.name, {
            value: {
              color: category.color,
              shapes: [],
              type: category.type,
              hide: false,
              hover: false
            },
            enumerable: true,
            configurable: true
          })
        }
      })

      // eslint-disable-next-line no-prototype-builtins
      if (segmentation !== null && segmentation.hasOwnProperty('all')) {
        if (typeof segmentation.all === 'object') {
          for (const property in segmentation.all) {
            if (all[property] !== undefined) {
              all[property].shapes = segmentation.all[property].shapes
            } else {
              Object.defineProperty(all, property, {
                value: {
                  color: segmentation.all[property].color,
                  shapes: segmentation.all[property].shapes,
                  type: segmentation.all[property].type,
                  hide: false,
                  hover: false
                },
                enumerable: true,
                configurable: true
              })
            }
          }
        } else {
          segmentation.all.forEach((s) => {
            if (all[s.name] !== undefined) {
              all[s.name].shapes = s.shapes
            } else {
              Object.defineProperty(all, s.name, {
                value: {
                  color: s.color,
                  shapes: s.shapes,
                  type: s.type,
                  hide: false,
                  hover: false
                },
                enumerable: true,
                configurable: true
              })
            }
          })
        }
      }

      // eslint-disable-next-line no-prototype-builtins
      if (!all.hasOwnProperty('_delete')) {
        Object.defineProperty(all, '_delete', {
          value: { color: '#ffffff', shapes: [], type: 'segmentation', hide: false, hover: false },
          enumerable: true,
          configurable: true
        })
      }
      setLandmarks(tags.data.getTag.annotations.points.map((point) => ({ ...point, show: true })))
      setSegmentations({ all, stack: segmentation !== null ? segmentation.stack : [] })
      setRects(tags.data.getTag.annotations.rects.map((rect) => ({ ...rect, show: true })))
      setPolygons(tags.data.getTag.annotations.polys.map((poly) => ({ ...poly, show: true })))
      setLines(tags.data.getTag.annotations.lines.map((line) => ({ ...line, show: true })))
      setClassifications(classificationsTags)
      setTime({
        labeler: tags.data.getTag.labelerTime !== null ? tags.data.getTag.labelerTime : 0,
        reviewer: tags.data.getTag.reviewerTime !== null ? tags.data.getTag.reviewerTime : 0
      })
      setDispatchOrder(0)
    } catch (error) {
      send({ type: 'REJECT', source: 'useGetTags' })
    }
  }

  useEffect(() => {
    const all = []
    const allCategories = {
      segmentation: 0,
      point: 0,
      bounding_box: 0,
      polygons: 0,
      ellipse: 0,
      line: 0,
      classification: 0,
      other: 0
    }
    const projectCat = []

    for (let index = 0; index < categories.length; index++) {
      const category = categories[index]
      switch (category.type) {
        case 'segmentation':
          allCategories.segmentation = 1
          all.push({
            name: category.name,
            color: category.color,
            shapes: [],
            type: category.type,
            hide: false,
            hover: false
          })
          break
        case 'point':
          allCategories.point = 1
          break
        case 'bounding_box':
          allCategories.bounding_box = 1
          if (!containedChildrens && category.parent !== null) {
            setContainedChildrens(true)
          }
          break
        case 'classification':
          allCategories.classification = 1
          break
        case 'polygon':
          allCategories.polygons = 1
          break
        case 'ellipse':
          allCategories.ellipse = 1
          break
        case 'line':
          allCategories.line = 1
          break
        default:
          allCategories.other = 1
          break
      }
    }

    const defaultCategory = categories.find((category) => category.type !== 'classification')
    if (defaultCategory) setDefaultCategory({ ...defaultCategory, AI: 'none' })

    setSegmentations({ all, stack: [] })
    for (const property in allCategories) {
      if (allCategories[property] !== 0) {
        projectCat.push(property)
      }
    }
    setProjectCategories(projectCat)
  }, [])

  useEffect(() => {
    if (containedChildrens) dispatch({ type: 'setContainedChildrens', payload: containedChildrens })
  }, [containedChildrens])

  useEffect(() => {
    dispatch({ type: 'setCategories', payload: categories })
    dispatch({ type: 'setIsHiddenCat', payload: { categories } })
    dispatch({ type: 'setProjectSegmentations', payload: segmentations })
    dispatch({ type: 'setProjectCategories', payload: projectCategories })
    dispatch({
      type: 'setCanvas',
      payload: { defaultSelectedCategory: defaultCategory }
    })
    dispatch({ type: 'defaultSegmentation', payload: defaultCategory })
  }, [projectCategories])

  useEffect(() => {
    if (machineState.value === 'getTags') {
      if (image) {
        setTime({ labeler: null, reviewer: null })
        getAllTags()
        imageRef.current = image.id
      }
    }
  }, [image?.id, projectId, machineState.value])

  useEffect(() => {
    if (dispatchOrder === 0) {
      dispatch({ type: 'setLandmarks', payload: landmarks })
      setDispatchOrder(1)
    }
  }, [dispatch, landmarks])

  useEffect(() => {
    if (dispatchOrder === 1) {
      dispatch({ type: 'setSegmentation', payload: segmentations })
      setDispatchOrder(2)
    }
  }, [dispatch, segmentations, dispatchOrder])

  useEffect(() => {
    if (dispatchOrder === 2) {
      dispatch({ type: 'setBoundingboxes', payload: rects })
      setDispatchOrder(3)
    }
  }, [dispatch, rects, dispatchOrder])

  useEffect(() => {
    if (dispatchOrder === 3) {
      dispatch({ type: 'setPolygons', payload: polygons })
      setDispatchOrder(4)
    }
  }, [dispatch, polygons, dispatchOrder])

  useEffect(() => {
    if (dispatchOrder === 4) {
      dispatch({ type: 'setLines', payload: lines })
      setDispatchOrder(5)
    }
  }, [dispatch, lines, dispatchOrder])

  useEffect(() => {
    if (dispatchOrder === 5) {
      dispatch({ type: 'setClassifications', payload: classifications })
      setDispatchOrder(6)
    }
  }, [dispatch, classifications, dispatchOrder])

  useEffect(() => {
    if (dispatchOrder === 6) {
      dispatch({
        type: 'setInitialTime',
        payload: { labelerTimer: time.labeler, reviewerTimer: time.reviewer }
      })
      setDispatchOrder(7)
    }
  }, [dispatch, time, dispatchOrder])

  useEffect(() => {
    if (dispatchOrder === 7) {
      if (machineState.value === 'getTags') {
        send({ type: 'RESOLVE', source: 'useGetTags' })
        setDispatchOrder(-1)
      }
    }
  }, [dispatch, dispatchOrder])

  return null
}
