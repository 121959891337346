export function getBoundingBox(posx, posy, tags = [], panZoom) {
  for (let index = 0; index < tags.length; index++) {
    const rect = tags[index]
    if (
      tags[index].show &&
      rect.pos[0] - 3 / panZoom.scale < posx &&
      posx < rect.pos[0] + Math.abs(rect.pos[2]) + 3 / panZoom.scale &&
      rect.pos[1] - 3 / panZoom.scale < posy &&
      posy < rect.pos[1] + Math.abs(rect.pos[3]) + 3 / panZoom.scale
    )
      return { index, rect, type: 'bounding_box' }
  }
  return null
}
