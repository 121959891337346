import React, { useContext } from 'react'
import { connect } from 'react-redux'
import { useTheme, styled } from '@mui/material/styles'
import { Button, CircularProgress, Icon, Typography } from '@mui/material'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import HubIcon from '@mui/icons-material/HubOutlined'
import { LabelerContext } from '../../containers/labeler/context/LabelerContext'
import { formatS3Key } from '../../utils/util'
import { useLabelerMachine } from '../../containers/labeler/context/MachineContext'
const RootComponent = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.paper,
  position: 'relative',
  zIndex: '1'
}))

const ArrowButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.arrows
}))

function ImageManager(props) {
  const theme = useTheme()
  const { state: MachineState } = useLabelerMachine()

  const {
    state: {
      labelerState: {
        image: { index, images, currentImage }
      }
    }
  } = useContext(LabelerContext)

  return (
    <RootComponent>
      <div
        style={{
          flex: 1,
          display: 'flex',
          justifyContent: 'left',
          marginLeft: 16,
          alignItems: 'center'
        }}
      >
        {props.imageInfo?.generateEmbeddings ? (
          props.imageInfo.generateEmbeddings.samH &&
          props.imageInfo.embeddings?.samH &&
          props.embeddingExist !== undefined ? (
            props.embeddingExist ? (
              <Icon
                sx={{ color: 'rgba(100, 100, 255, 0.84)' }}
                size="small"
                aria-label="has mebeding"
              >
                <HubIcon />
              </Icon>
            ) : (
              <CircularProgress size={20} />
            )
          ) : (
            <Icon sx={{ color: '#999' }} size="small" aria-label="has mebeding">
              <HubIcon />
            </Icon>
          )
        ) : null}
      </div>
      <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
        <ArrowButton
          size="medium"
          onClick={props.handleBack}
          disabled={
            !props.project || images.length === 0 || MachineState.value !== 'readyForLabeling'
          }
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </ArrowButton>
        <Typography style={{ marginTop: 5, maxWidth: '50%' }} variant="subtitle1" noWrap>
          {images.length > index && index >= 0 ? formatS3Key(currentImage.name) : 'Demo image'}
        </Typography>
        <ArrowButton
          size="medium"
          onClick={props.handleNext}
          disabled={
            !props.project || images.length === 0 || MachineState.value !== 'readyForLabeling'
          }
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </ArrowButton>
      </div>
      <div style={{ flex: 1 }}></div>
    </RootComponent>
  )
}

const mapStateToProps = (state) => ({
  project: state.project
})

export default connect(mapStateToProps, {})(ImageManager)
