import { useReducer } from 'react'
import produce from 'immer'

import { sandLabelHidden, sandLabelDeleted } from '../../../../../io/analytics'

const boundingBoxState = {
  tags: [],
  selectedTag: -1,
  selectedParent: -1,
  lastTag: -1,
  editPoints: [],
  redraw: 0,
  containedChildrens: false,
  AICounters: { score: 0.05, overlap: 0.7 },
  loadingAI: false
}

export const reducer = produce((state, action) => {
  switch (action.type) {
    case 'setLoadingTags':
      if (action.payload) {
        state.tags = []
        state.selectedTag = -1
        state.selectedParent = -1
        state.lastTag = -1
        state.editPoints = []
        state.redraw = 0
        state.containedChildrens = false
      }
      break
    case 'setBoundingboxes':
      state.tags = action.payload
      state.redraw = state.redraw + 1
      state.selectedTag = -1
      break
    case 'setBoundingbox':
      state.tags[action.payload.index] = action.payload.boundingbox
      state.editPoints = []
      state.editPoints.push({
        x: action.payload.boundingbox.pos[0],
        y: action.payload.boundingbox.pos[1]
      })
      state.editPoints.push({
        x: action.payload.boundingbox.pos[0],
        y: action.payload.boundingbox.pos[3] + action.payload.boundingbox.pos[1]
      })
      state.editPoints.push({
        x: action.payload.boundingbox.pos[0] + action.payload.boundingbox.pos[2],
        y: action.payload.boundingbox.pos[1]
      })
      state.editPoints.push({
        x: action.payload.boundingbox.pos[0] + action.payload.boundingbox.pos[2],
        y: action.payload.boundingbox.pos[1] + action.payload.boundingbox.pos[3]
      })
      state.editPoints.push({
        x: action.payload.boundingbox.pos[0],
        y: action.payload.boundingbox.pos[1] + action.payload.boundingbox.pos[3] / 2
      })
      state.editPoints.push({
        x: action.payload.boundingbox.pos[0] + action.payload.boundingbox.pos[2],
        y: action.payload.boundingbox.pos[1] + action.payload.boundingbox.pos[3] / 2
      })
      state.editPoints.push({
        x: action.payload.boundingbox.pos[0] + action.payload.boundingbox.pos[2] / 2,
        y: action.payload.boundingbox.pos[1]
      })
      state.editPoints.push({
        x: action.payload.boundingbox.pos[0] + action.payload.boundingbox.pos[2] / 2,
        y: action.payload.boundingbox.pos[1] + action.payload.boundingbox.pos[3]
      })
      state.redraw = state.redraw + 1
      break
    case 'setSelectedBounding':
      state.tags[action.payload] = { ...state.tags[action.payload], hover: true }
      state.selectedTag = action.payload
      state.editPoints.push({
        x: state.tags[action.payload].pos[0],
        y: state.tags[action.payload].pos[1]
      })
      state.editPoints.push({
        x: state.tags[action.payload].pos[0],
        y: state.tags[action.payload].pos[3] + state.tags[action.payload].pos[1]
      })
      state.editPoints.push({
        x: state.tags[action.payload].pos[0] + state.tags[action.payload].pos[2],
        y: state.tags[action.payload].pos[1]
      })
      state.editPoints.push({
        x: state.tags[action.payload].pos[0] + state.tags[action.payload].pos[2],
        y: state.tags[action.payload].pos[1] + state.tags[action.payload].pos[3]
      })
      state.editPoints.push({
        x: state.tags[action.payload].pos[0],
        y: state.tags[action.payload].pos[1] + state.tags[action.payload].pos[3] / 2
      })
      state.editPoints.push({
        x: state.tags[action.payload].pos[0] + state.tags[action.payload].pos[2],
        y: state.tags[action.payload].pos[1] + state.tags[action.payload].pos[3] / 2
      })
      state.editPoints.push({
        x: state.tags[action.payload].pos[0] + state.tags[action.payload].pos[2] / 2,
        y: state.tags[action.payload].pos[1]
      })
      state.editPoints.push({
        x: state.tags[action.payload].pos[0] + state.tags[action.payload].pos[2] / 2,
        y: state.tags[action.payload].pos[1] + state.tags[action.payload].pos[3]
      })
      state.redraw = state.redraw + 1
      break
    case 'setSelectedParent':
      state.selectedParent = action.payload
      break
    case 'unselectParent':
      state.selectedParent = -1
      break
    case 'unselectBounding':
      state.tags[state.selectedTag] = { ...state.tags[state.selectedTag], hover: false }
      state.selectedTag = -1
      state.editPoints = []
      state.redraw = state.redraw + 1
      break
    case 'selectLastTag':
      state.lastTag = action.payload
      break
    case 'addBoundingbox':
      state.tags.push(action.payload)
      if (action.payload.parent === null) {
        state.selectedParent = state.tags.length - 1
        state.lastTag = state.tags.length - 1
      }
      break
    case 'hoverBounding':
      state.tags[action.payload.index] = {
        ...state.tags[action.payload.index],
        hover: action.payload.hover
      }
      state.selectedTag = action.payload.index === state.selectedTag ? -1 : state.selectedTag
      state.redraw = state.redraw + 1
      break
    case 'hideBounding':
      sandLabelHidden({ type: 'BBox' })
      if (action.payload) {
        state.tags[action.payload.index] = {
          ...state.tags[action.payload.index],
          show: action.payload.show
        }
        state.selectedTag = state.tags[action.payload.index].show ? state.selectedTag : -1
      } else {
        state.tags[state.selectedTag] = { ...state.tags[state.selectedTag], show: false }
        state.selectedTag = state.tags[state.selectedTag].show ? state.selectedTag : -1
      }
      state.editPoints = []
      state.redraw = state.redraw + 1
      break
    case 'hideBoundingCategory':
      sandLabelHidden({ type: 'BBox' })
      state.tags = state.tags.map((bounding) => {
        if (bounding.name === action.payload.name) {
          return { ...bounding, show: !bounding.show }
        }
        return bounding
      })
      state.redraw = state.redraw + 1
      break
    case 'hideAll':
      state.tags = state.tags.map((bounding) => ({ ...bounding, show: action.payload }))
      state.redraw = state.redraw + 1
      break
    case 'deleteboundingBox':
      sandLabelDeleted({ type: 'BBox' })
      // eslint-disable-next-line no-case-declarations
      const i = action.payload !== undefined ? action.payload.index : state.selectedTag
      state.lastTag = -1
      state.tags = state.tags.filter((_, index) => index !== i)
      state.selectedTag = -1
      state.redraw = state.redraw + 1
      state.editPoints = []
      break
    case 'setBoundingText':
      state.tags[state.selectedTag] = { ...state.tags[state.selectedTag], text: action.payload }
      break
    case 'classChange':
      // eslint-disable-next-line no-case-declarations
      const { e, className, key, multiSelect } = action.payload
      // eslint-disable-next-line no-case-declarations
      const s = multiSelect ? '' : e.target.value
      // eslint-disable-next-line no-case-declarations
      const v = multiSelect ? e.target.value : []
      state.tags[key] = {
        ...state.tags[key],
        classes: state.tags[key].classes.map((c) =>
          c.name === className ? { ...c, selected: s, values: v } : c
        )
      }
      break
    case 'setContainedChildrens':
      state.containedChildrens = action.payload
      break
    case 'addAiTags':
      state.tags = [...state.tags, ...action.payload]
      state.redraw = state.redraw + 1
      break
    case 'setAICounters':
      state.loadingAI = true
      state.AICounters = { overlap: action.payload.overlap, score: action.payload.score }
      break
    case 'setBoundingBoxAiLoading':
      state.loadingAI = action.payload
      break
    case 'addMultipleBoxes':
      state.tags.push(...action.payload)
      state.redraw = state.redraw + 1
      break
    case 'changeBoundingbox':
      state.selectedTag = -1
      state.tags[action.payload.index].name = action.payload.name
      state.tags[action.payload.index].color = action.payload.color
      state.tags[action.payload.index].hover = false
      state.redraw = state.redraw + 1
      break
    default:
      break
  }
}, boundingBoxState)

export function BoundingBoxReducer() {
  const [state, dispatch] = useReducer(reducer, boundingBoxState)
  return { boundingBoxState: state, boundingBoxDispatch: dispatch }
}
