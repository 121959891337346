import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import SnackbarContent from '@mui/material/SnackbarContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/system'
import { useSnackbar } from './context/SnackbarContext'
import { Button } from '@mui/material'

const StyledSnackbar = styled(Snackbar)(({ theme, type }) => ({
  '& .MuiSnackbarContent-root': {
    backgroundColor: theme.palette[type].main
  },
  '& .message': {
    display: 'flex',
    alignItems: 'center'
  }
}))

const ReusableSnackbar = () => {
  const { snackbarState, hideSnackbar } = useSnackbar()
  const { open, message, type, isButton, buttonBlocked, buttonFunction, buttonText, duration } =
    snackbarState

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    hideSnackbar()
  }

  return (
    <StyledSnackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
      type={type}
    >
      <SnackbarContent
        message={<span className="message">{message}</span>}
        action={
          isButton === true
            ? [
                <Button key="button" variant="contained" color="primary" onClick={buttonFunction}>
                  {buttonText}
                </Button>,
                <IconButton
                  key="closeButton"
                  aria-label="Close"
                  color="inherit"
                  onClick={handleClose}
                  disabled={buttonBlocked}
                >
                  <CloseIcon />
                </IconButton>
              ]
            : [
                <IconButton key="close" aria-label="Close" color="inherit" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              ]
        }
      />
    </StyledSnackbar>
  )
}

export default ReusableSnackbar
