import { useContext, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import { Chip, Paper } from '@mui/material'
import { uniqBy as _uniq } from 'lodash'
import { FiltersContext } from '../../context/FiltersContext'
import { initialState } from '../../context/filtersReducer'
import { getUser } from '../../../../../../utils/util'
import {
  flattenObjectValues,
  getLabelerEmails,
  getLabelsCount,
  getReviewerEmails,
  isValidData
} from './utils/filterHelpers'

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5)
}))

const FilterChips = () => {
  const { state, dispatch } = useContext(FiltersContext)
  const [chipData, setChipData] = useState([])
  const store = useSelector((state) => state)

  const user = getUser()
  const teamMembers = _uniq(
    [{ user: { id: user.id, email: user.email } }, ...(store.project.team?.members?.items || [])],
    'user.id'
  )

  const {
    labelers,
    reviewers,
    categories,
    names,
    accepted,
    done,
    skiped,
    count,
    classifications,
    labelClassifications
  } = state

  const hasFiltersChanged = JSON.stringify(state) !== JSON.stringify(initialState)

  const labelerEmails = getLabelerEmails(labelers, teamMembers)

  const reviewerEmails = getReviewerEmails(reviewers, teamMembers)

  const labelsCount = getLabelsCount(count)

  const flattenedClassifications = flattenObjectValues(classifications)

  useEffect(() => {
    if (!hasFiltersChanged) {
      return
    }

    const filterData = [
      { key: 'names', label: 'Name', value: names[0]?.name },
      { key: 'done', label: 'Done', value: done.value === 'all' ? '' : done.value },
      { key: 'accepted', label: 'Reviewed', value: accepted.value === 'all' ? '' : accepted.value },
      { key: 'skiped', label: 'Skipped', value: skiped.value === 'all' ? '' : skiped.value },
      { key: 'categories', label: 'Categories', value: categories },
      ...flattenedClassifications,
      // TODO: Updated labelClassifications
      { key: 'labelClassifications', label: 'Label Classifications', value: labelClassifications },
      { key: 'labelers', label: 'Labelers', value: labelerEmails },
      { key: 'reviewers', label: 'Reviewers', value: reviewerEmails },
      { key: 'count', label: 'Labels Count', value: labelsCount }
    ]

    const newChipData = filterData.filter((data) => isValidData(data.value))

    setChipData(newChipData)
  }, [
    labelers,
    reviewers,
    categories,
    names,
    accepted,
    done,
    skiped,
    count,
    classifications,
    labelClassifications,
    hasFiltersChanged
  ])

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key))
    dispatch({
      type: 'reset',
      payload: { key: chipToDelete.key, masterKey: chipToDelete.masterKey }
    })
  }

  if (!hasFiltersChanged) {
    return null
  }

  return (
    <Paper
      sx={{
        display: chipData.length > 0 ? 'flex' : 'none',
        justifyContent: 'left',
        flexWrap: 'wrap',
        listStyle: 'none',
        p: 0.5,
        m: 1
      }}
      component="ul"
    >
      {chipData.map((data) => (
        <ListItem key={data.key}>
          <Chip label={`${data.label}: ${data.value}`} onDelete={handleDelete(data)} />
        </ListItem>
      ))}
    </Paper>
  )
}

export default FilterChips
