export const searchTags = /* GraphQL */ `
  query SearchTags(
    $filter: SearchableTagFilterInput
    $sort: [SearchableTagSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableTagAggregationInput]
  ) {
    searchTags(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        height
        width
        key
        done
        skiped
        accepted
        categoriesCount {
          name
          count
        }
        count
        status
        createdAt
        assignedAt
        savedAt
        reviewedAt
        labeler {
          id
          name
          email
        }
        reviewer {
          id
          name
          email
        }
        states {
          items {
            createdAt
            stage
            action
            toBlame {
              name
              email
            }
            role
          }
          nextToken
        }
      }
      nextToken
    }
  }
`

export const listTagsIds = /* GraphQL */ `
  query SearchTags(
    $filter: SearchableTagFilterInput
    $sort: [SearchableTagSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableTagAggregationInput]
  ) {
    searchTags(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
      }
      nextToken
      total
    }
  }
`
