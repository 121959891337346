import React, { useState, useEffect, useContext, forwardRef, useImperativeHandle } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { createShape } from '../../../utils/objects'
import { getUser, saveTime, getNowString } from '../../../utils/util'
import { TYPES, EMPTY } from '../../../utils/constants'
import {
  generateColor,
  getRealRectangles,
  getRealPolys,
  getRealPoints,
  getRealLines,
  getRealCircles
} from '../../../utils/labeler'
import { sandEvent } from '../../../io/analytics'
import { getImageTags } from '../../../services/ai'
//  ACTIONS
import { project as pActions } from '../../../io/project/actions'
import { dialog as dActions } from '../../../io/dialogs/actions'
import '../../../styles/Labeler.css'
import { styled } from '@mui/material/styles'
import Dialog from '../../../components/generics/dialogs/defaultDialog/Dialog'
import RightArea from './rightArea/ContainerRightArea'
import Hotkeys from '../../../components/Labeler/Hotkeys'
import ImageManager from '../../../components/Labeler/ImageManager'
import DownloadAsModal from '../../../components/DownloadAsModal'
import UploadDemoImageDialog from '../../../components/generics/dialogs/uploadDemoImageDialog/UploadDemoImageDialog'
import { DispatchContext } from '../context/DispatchContext'
import { useLabelerMachine } from '../context/MachineContext'
import {
  Menu,
  MenuItem,
  Grid,
  LinearProgress,
  Divider,
  Paper,
  CircularProgress,
  TextField
} from '@mui/material'
import MainCanvas from './MainCanvas'
import { useSnackbar } from '../../../components/snackbar/context/SnackbarContext'

const LabelerComponent = styled(Grid)(({ theme }) => {
  return {
    backgroundColor: theme.palette.background.labeler
  }
})

const Labeler = forwardRef((props, ref) => {
  const { state: MachineState } = useLabelerMachine()
  const { dispatch } = useContext(DispatchContext)
  const navigate = useNavigate()
  const [imageInfo] = useState(null)
  const [timers, setTimers] = useState({
    // Timer
    timerState: 'init',
    lastTime: 0,
    lastEventTime: 0,
    timeCount: 0
  })
  const [tagsCount, setTagsCount] = useState(0)
  const [state, setState] = useState({
    isLoading: true, // TODO: move this to context
    currentPolygon: [],
    currentPoint: null,
    currentLine: [], // TODO: Check if this is in use
    openDownloadModal: false,
    // Image
    imageHeight: null,
    imageWidth: null,
    selectedImg: process.env.REACT_APP_DEMO_IMAGE,
    ratio: 1,
    scale: 1,
    zoomTime: 0,
    nextImages: null,
    // Mouse
    startX: null,
    startY: null,
    scaleCenter: ['50%', '50%'],
    mouseType: 'crosshair',
    circleCenter: {},
    mouseMenuX: null,
    mouseMenuY: null,
    panX: 0,
    panY: 0,
    // Tags
    addedRects: [],
    addedPolygons: [],
    addedPoints: [],
    addedLines: [],
    addedCircles: [],
    addedClassification: {},
    selectedTag: {
      type: null,
      index: -1
    },
    // Categories
    categories: [createShape('Car', 'blue', TYPES.RECT)],
    currentCategory: 0,
    deletingIndex: null,
    currentCategoryTabCreate: false,
    // Messages
    openDialog: false,
    dialogTitle: '',
    dialogText: '',
    dialogIndex: 0, // 1. Delete, 2. AI, 3. Select categories
    dialogOkText: null,
    // AI
    aiCategories: [],
    aiTags: [],
    categoriesSelected: [],
    loadingAI: false,
    // Edit
    edit: EMPTY.labeler.edit,
    // experiments
    uploadImgDialog: false,
    // Review
    reviews: 0,
    nextProjects: null
  })

  const { showSnackbar } = useSnackbar()

  // const canvasRef = React.createRef()
  // const backCanvasRef = React.createRef()
  // const auxCanvasRef = React.createRef()

  // const img = findDOMNode(imgRef)
  // const canvas = canvasRef.current
  // const ctx = canvas.getContext('2d')
  // const auxCanvas = auxCanvasRef.current
  // const auxCtx = auxCanvas.getContext('2d')
  // const backCanvas = backCanvasRef.current
  // const backCtx = backCanvas.getContext('2d')

  useEffect(() => {
    // TODO: Remove this
    const user = getUser()
    if (user) {
      const { project } = props
      if (project && project.id) {
        if (project.role) {
          selectDataset(project)
        } else {
          props.getRole(user.id, project.team.id)
        }
      }
    } else {
      setState((prevState) => ({ ...prevState, uploadImgDialog: true }))
    }
    window.addEventListener('focus', onFocus.bind(this))
    window.addEventListener('blur', onBlur.bind(this))
    changeTime(Date.now(), 'run')

    return () => {
      window.removeEventListener('focus', onFocus.bind(this))
      window.removeEventListener('blur', onBlur.bind(this))
    }
  }, [])

  useEffect(() => {
    handleClickModeChange()
  }, [props.clickMode])

  const onFocus = () => {
    changeTime(Date.now(), 'run')
  }

  const onBlur = () => {
    changeTime(Date.now(), 'stop')
  }

  const changeLoading = (isLoading) => {
    setState((prevState) => ({ ...prevState, isLoading }))
  }

  // TODO: Check if this can't be call when loading
  const changeTime = (time, _state) => {
    let count = 0
    if (timers.timerState !== 'init') {
      if (timers.timerState === 'run' && _state === 'stop') {
        if (Date.now() - timers.lastEventTime < 60000) {
          count = time - timers.lastTime
        } else {
          count = 10000
        }
      }
    }

    saveTime(getNowString(), count, tagsCount, props.image ? props.image.id : 'unknow')

    setTagsCount(0)
    setTimers({
      ...timers,
      lastTime: time,
      timerState: _state,
      timeCount: timers.timeCount + count
    })
  }

  const addEvent = (time) => {
    setTimers({ ...timers, lastEventTime: time })
  }

  const showAiDialog = () => {
    addEvent(Date.now(), 'show_ai_dialog')
    sandEvent('AI labels')
    if (!props.project.id && state.selectedImg !== process.env.REACT_APP_DEMO_IMAGE) {
      props.setDialogAllProperties(
        true,
        5,
        'Signup!',
        'To use Pre-label image tool, you need to signup first.',
        'Signup'
      )
      return
    }
    props.setDialogAllProperties(
      true,
      2,
      'Get labels',
      'Do you want to get suggested categories and labels from our AI?',
      null
    )
  }

  const getAiTags = (key) => {
    setState((prevState) => ({ ...prevState, loadingAI: true }))
    const isDemo = !props.project.id

    getImageTags(key, cleanAiTags, isDemo).catch((error) => {
      console.log(error)
      setState((prevState) => ({ ...prevState, loadingAI: false }))
      showSnackbar({
        message: 'Error getting tags, please try again.',
        type: 'error'
      })
    })
  }

  const cleanAiTags = (error, data) => {
    if (error) {
      setState((prevState) => ({ ...prevState, loadingAI: false }))
      console.log(error)
      return
    }
    const tgs = []
    const categories = []

    data.Labels.forEach((cats) => {
      if (cats.Instances && cats.Instances.length > 0) {
        const color = generateColor()
        cats.Instances.forEach((tag) => {
          tgs.push({
            name: cats.Name,
            color,
            x: tag.BoundingBox.Left,
            y: tag.BoundingBox.Top,
            w: tag.BoundingBox.Width,
            h: tag.BoundingBox.Height
          })
          if (categories.filter((cat) => cat.name === cats.Name).length <= 0) {
            categories.push(createShape(cats.Name, color, TYPES.RECT))
          }
        })
      }
    })
    if (categories.length > 0) {
      props.setDialogAllProperties(
        true,
        3,
        'Select desired categories',
        'These are the categories found, select the ones you want to add:',
        null,
        categories
      )
      setState((prevState) => ({ ...prevState, aiTags: tgs }))
    } else {
      props.setDialogAllProperties(
        true,
        4,
        'We could not recognize any object.',
        "Our smart monkeys weren't smart enough",
        null,
        categories
      )
      setState((prevState) => ({ ...prevState, aiTags: tgs }))
    }
  }

  const setAiTags = (tags) => {
    //  TODOO
    const tgs = []
    tags.forEach((t) => {
      tgs.push({
        id: uuidv4(),
        name: t.name,
        color: t.color,
        type: TYPES.RECT,
        pos: [
          t.x * props.image.width,
          t.y * props.image.height,
          t.w * props.image.width,
          t.h * props.image.height
        ],
        // startX, startY, widthX, heightY
        show: true,
        parent: null
      })
    })

    dispatch({ type: 'addAiTags', payload: tgs })
    setState((prevState) => ({ ...prevState, addedRects: [...prevState.addedRects, ...tgs] }))
    setState((prevState) => ({ ...prevState, loadingAI: false }))
  }

  const closeUploadImgDialog = () => {
    sandEvent('experiment/UploadSingleImage', { selected: 'demoHighway' })
    setState((prevState) => ({ ...prevState, uploadImgDialog: false }))
  }

  const setUploadImg = (input) => {
    setState((prevState) => ({ ...prevState, selectedImg: input, uploadImgDialog: false }))
    sandEvent('experiment/UploadSingleImage', { selected: input })
  }
  /*
8b    d8  dP'Yb  88   88 .dP'Y8 888888
88b  d88 dP   Yb 88   88 `Ybo.' 88__
88YbdP88 Yb   dP Y8   8P o.`Y8b 88''
88 YY 88  YbodP  `YbodP' 8bodP' 888888
  */

  const mouseEnterOnList = (rect, poly, point, line, circle) => {
    if (rect) {
      let selectedEl = {}
      let selectedIndex = null
      for (let index = 0; index < state.addedRects.length; index++) {
        const element = state.addedRects[index]
        if (element === rect) {
          selectedIndex = index
          selectedEl = element
        }
      }
      setState((prevState) => ({
        ...prevState,
        edit: { element: selectedEl, index: selectedIndex }
      }))
    }
    if (poly) {
      let selectedEl = {}
      let selectedIndex = null
      for (let index = 0; index < state.addedPolygons.length; index++) {
        const element = state.addedPolygons[index]
        if (element === poly) {
          selectedIndex = index
          selectedEl = element
        }
      }
      setState((prevState) => ({
        ...prevState,
        edit: { element: selectedEl, index: selectedIndex }
      }))
    }
    if (point) {
      let selectedEl = {}
      let selectedIndex = null
      for (let index = 0; index < state.addedPoints.length; index++) {
        const element = state.addedPoints[index]
        if (element === point) {
          selectedIndex = index
          selectedEl = element
        }
      }
      setState((prevState) => ({
        ...prevState,
        edit: { element: selectedEl, index: selectedIndex }
      }))
    }
    if (line) {
      let selectedEl = {}
      let selectedIndex = null
      for (let index = 0; index < state.addedLines.length; index++) {
        const element = state.addedLines[index]
        if (element === line) {
          selectedIndex = index
          selectedEl = element
        }
      }
      setState((prevState) => ({
        ...prevState,
        edit: { element: selectedEl, index: selectedIndex }
      }))
    }
    if (circle) {
      let selectedEl = {}
      let selectedIndex = null
      for (let index = 0; index < state.addedCircles.length; index++) {
        const element = state.addedCircles[index]
        if (element === circle) {
          selectedIndex = index
          selectedEl = element
        }
      }
      setState((prevState) => ({
        ...prevState,
        edit: { element: selectedEl, index: selectedIndex }
      }))
    }
  }

  const mouseLeaveOnList = (rect, poly, point, line, circle) => {
    if (rect) {
      setState((prevState) => ({ ...prevState, edit: EMPTY.labeler.edit }))
    }
    if (poly) {
      setState((prevState) => ({ ...prevState, edit: EMPTY.labeler.edit }))
    }
    if (point) {
      setState((prevState) => ({ ...prevState, edit: EMPTY.labeler.edit }))
    }
    if (line) {
      setState((prevState) => ({ ...prevState, edit: EMPTY.labeler.edit }))
    }
    if (circle) {
      setState((prevState) => ({ ...prevState, edit: EMPTY.labeler.edit }))
    }
  }

  /*
8888b.  88''Yb    db    Yb        dP
 8I  Yb 88__dP   dPYb    Yb  db  dP
 8I  dY 88'Yb   dP__Yb    YbdPYbdP
8888Y'  88  Yb dP''''Yb    YP  YP
 */

  const handleChildClassChange = (e, className, key, element, multiSelect, classType) => {
    const s = multiSelect || classType === 'multiple' ? '' : e.target.value
    const v = multiSelect || classType === 'multiple' ? e.target.value : []

    setState((prevState) => ({
      ...prevState,
      addedRects:
        element.type !== TYPES.RECT
          ? prevState.addedRects
          : prevState.addedRects.map((r, index) => {
              if (index === key) {
                return {
                  ...r,
                  classes: r.classes.map((c) =>
                    c.name === className ? { ...c, selected: s, values: v } : c
                  )
                }
              }
              return r
            }),
      addedPolygons:
        element.type !== TYPES.POLY
          ? prevState.addedPolygons
          : prevState.addedPolygons.map((r, index) => {
              if (index === key) {
                return {
                  ...r,
                  classes: r.classes.map((c) =>
                    c.name === className ? { ...c, selected: s, values: v } : c
                  )
                }
              }
              return r
            }),
      addedLines:
        element.type !== TYPES.LINE
          ? prevState.addedLines
          : prevState.addedLines.map((r, index) => {
              if (index === key) {
                return {
                  ...r,
                  classes: r.classes.map((c) =>
                    c.name === className ? { ...c, selected: s, values: v } : c
                  )
                }
              }
              return r
            }),
      edit: {
        ...prevState.edit,
        clickedCase: null,
        grabPoint: null
      }
    }))
  }

  /*
88  88 888888 88     88''Yb 888888 88''Yb .dP'Y8
88  88 88__   88     88__dP 88__   88__dP `Ybo.'
888888 88''   88  .o 88'''  88''   88'Yb  o.`Y8b
88  88 888888 88ood8 88     888888 88  Yb 8bodP'
 */
  const changeCurrentCategoryTab = (index) => {
    setState((prevState) => ({ ...prevState, currentCategoryTabCreate: !(index === 0) }))
  }

  const getAIPolygon = (checked) => {
    if (checked) {
      props.setDialogAllProperties(
        true,
        6,
        'Get automatic polygon',
        'Draw 4 points in the corners of the object you want to segment.',
        null
      )
    } else {
      const category = state.categories[state.currentCategory]
      dispatch({ type: 'setLabelerMode', payload: { category: category.type, mode: 'draw' } })
      setState((prevState) => ({
        ...prevState,
        edit: EMPTY.labeler.edit,
        currentPolygon: [],
        currentLine: [],
        mouseType: 'crosshair'
      }))
    }
  }

  const selectItem = (index, type) => {
    setState((prevState) => ({
      ...prevState,
      selectedTag: {
        index,
        type
      }
    }))
  }

  const deleteFigure = () => {
    if (state.edit !== EMPTY.labeler.edit) {
      switch (state.edit.element.type) {
        case TYPES.POLY:
          deletePoly(state.addedPolygons[state.edit.index])
          break
        case TYPES.RECT:
          deleteRect(state.addedRects[state.edit.index])
          break
        case TYPES.POINT:
          deletePoint(state.addedPoints[state.edit.index])
          break
        case TYPES.LINE:
          deleteLine(state.addedLines[state.edit.index])
          break
        case TYPES.CIRCLE:
          deleteCircle(state.addedCircles[state.edit.index])
          break
        default:
          break
      }
    }
  }

  const handleCloseMenu = (e) => {
    e.preventDefault()

    let list = null
    switch (state.edit.element.type) {
      case TYPES.POLY:
        list = 'addedPolygons'
        break
      case TYPES.RECT:
        list = 'addedRects'
        break
      case TYPES.POINT:
        list = 'addedPoints'
        break
      case TYPES.LINE:
        list = 'addedLines'
        break
      case TYPES.CIRCLE:
        list = 'addedCircles'
        break
      default:
        break
    }

    setState((prevState) => ({
      ...prevState,
      mouseMenuX: null,
      mouseMenuY: null,
      [list]: prevState[list].map((r, index) => {
        if (index === prevState.edit.index) {
          return prevState.edit.element
        }
        return r
      })
    }))

    if (props.clickMode === 'draw') {
      setState((prevState) => ({ ...prevState, edit: EMPTY.labeler.edit }))
    }
  }

  const handleAnnotationChange = (e) => {
    const value = e.target.value
    setState((prevState) => ({
      ...prevState,
      edit: {
        ...prevState.edit,
        element: {
          ...prevState.edit.element,
          text: value
        }
      }
    }))
  }

  const changeClassification = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      addedClassification: { ...prevState.addedClassification, [name]: value }
    }))
  }

  /*
  eeeee eeeee e   e  e eeeee e     eeeee eeeee eeeee
  8   8 8  88 8   8  8 8   8 8     8  88 8   8 8   8
  8   8 8   8 8   8  8 8   8 8     8   8 8eee8 8   8
  8   8 8   8 8   8  8 8   8 8     8   8 8   8 8   8
  88ee8 8eee8 88ee8ee8 8   8 88eee 8eee8 8   8 88ee8
 */

  const handleDownloadModalClose = () => {
    setState((prevState) => ({ ...prevState, openDownloadModal: false }))
  }

  // TODO:Move this to new Toolbar
  const downloadObjectAs = (exportName, exportType) => {
    const rects = getRealRectangles(state.addedRects, state.ratio)
    const newRects = []

    for (let i = 0; i < rects.length; i++) {
      const r = rects[i]
      newRects.push({
        ...r,
        pos: {
          x: r.pos[0],
          y: r.pos[1],
          w: r.pos[2],
          h: r.pos[3]
        }
      })
    }

    const tags = newRects
      .concat(getRealPolys(state.addedPolygons, state.ratio))
      .concat(getRealPoints(state.addedPoints, state.ratio))
      .concat(getRealLines(state.addedLines, state.ratio))
      .concat(getRealCircles(state.addedCircles, state.ratio))

    let dataStr = ''
    const downloadAnchorNode = document.createElement('a')
    let extension = ''
    switch (exportType) {
      case 'CSV':
        if (state.addedRects.length > 0) {
          extension = '.csv'
          const flattenedRects = newRects.map((tag) => {
            tag.x = tag.pos.x.toString()
            tag.y = tag.pos.y.toString()
            tag.w = tag.pos.w.toString()
            tag.h = tag.pos.h.toString()
            delete tag.pos
            return tag
          })
          dataStr =
            'data:text/json;charset=utf-8,' + encodeURIComponent(convertToCSV(flattenedRects))
          sandEvent('experiment/DownloadAsCSV', { selected: 'csv' })
        } else {
          sandEvent('experiment/DownloadAsCSV', {
            selected: 'csv',
            failed: true
          })
          return
        }
        break
      case 'JSON':
        sandEvent('experiment/DownloadAsCSV', { selected: 'json' })
        extension = '.json'
        dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(tags))
        break
      default:
        break
    }

    downloadAnchorNode.setAttribute('href', dataStr)
    downloadAnchorNode.setAttribute(
      'download',
      `${props.image ? props.image.name.split('.')[0] : 'tags'}${extension}`
    )
    document.body.appendChild(downloadAnchorNode)
    downloadAnchorNode.click()
    downloadAnchorNode.remove()
  }

  const convertToCSV = (json) => {
    const items = json
    const replacer = (key, value) => (value === null ? '' : value) // specify how you want to handle null values here
    const header = Object.keys(items[0])
    let csv = items.map((row) =>
      header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(',')
    )
    csv.unshift(header.join(','))
    csv = csv.join('\r\n')

    return csv
  }
  /*
eeeee  eeee eeee eeeee eeeee
8   8  8    8  8   8   8   '
8eee8e 8eee 8      8   8eeee
8    8 8    8      8       8
8    8 88ee 88e8   8   8eee8
 */

  const deleteRect = (rect) => {
    addEvent(Date.now(), 'delete_bbox')
    sandEvent('Delete bounding box', state.edit.element)

    setTagsCount(tagsCount - 1)
    setState((prevState) => ({
      ...prevState,
      addedRects: prevState.addedRects.filter((r) => r !== rect),
      edit: EMPTY.labeler.edit,
      selectedTag: {
        type: null,
        index: -1
      }
    }))
    showSnackbar({
      message: 'Label deleted.',
      type: 'success'
    })
  }

  const deletePoly = (poly) => {
    addEvent(Date.now(), 'delete_poly')
    setTagsCount(tagsCount - 1)
    setState((prevState) => ({
      ...prevState,
      addedPolygons: prevState.addedPolygons.filter((p) => p !== poly),
      edit: EMPTY.labeler.edit,
      selectedTag: {
        type: null,
        index: -1
      }
    }))
    showSnackbar({
      message: 'Label deleted.',
      type: 'success'
    })
  }

  const deletePoint = (point) => {
    addEvent(Date.now(), 'delete_point')
    setTagsCount(tagsCount - 1)
    setState((prevState) => ({
      ...prevState,
      addedPoints: prevState.addedPoints.filter((p) => p !== point),
      edit: EMPTY.labeler.edit,
      selectedTag: {
        type: null,
        index: -1
      }
    }))
    showSnackbar({
      message: 'Label deleted.',
      type: 'success'
    })
  }

  const deleteLine = (line) => {
    addEvent(Date.now(), 'delete_line')
    setTagsCount(tagsCount - 1)
    setState((prevState) => ({
      ...prevState,
      addedLines: prevState.addedLines.filter((l) => l !== line),
      edit: EMPTY.labeler.edit,
      selectedTag: {
        type: null,
        index: -1
      }
    }))
    showSnackbar({
      message: 'Label deleted.',
      type: 'success'
    })
  }

  const deleteCircle = (circle) => {
    addEvent(Date.now(), 'delete_circle')
    setTagsCount(tagsCount - 1)
    setState((prevState) => ({
      ...prevState,
      addedCircles: prevState.addedCircles.filter((c) => c !== circle),
      edit: EMPTY.labeler.edit,
      selectedTag: {
        type: null,
        index: -1
      }
    }))
    showSnackbar({
      message: 'Label deleted.',
      type: 'success'
    })
  }

  const hideItem = (item, type) => {
    addEvent(Date.now(), 'hide element')

    switch (type) {
      case TYPES.RECT:
        setState((prevState) => ({
          ...prevState,
          addedRects: prevState.addedRects.map((c) =>
            c === item || (c.id && c.id === item.id) ? { ...c, show: !c.show } : c
          ),
          edit: prevState.edit.element === item ? EMPTY.labeler.edit : prevState.edit
        }))
        break
      case TYPES.POLY:
        setState((prevState) => ({
          ...prevState,
          addedPolygons: prevState.addedPolygons.map((c) =>
            c === item ? { ...c, show: !c.show } : c
          ),
          edit: prevState.edit.element === item ? EMPTY.labeler.edit : prevState.edit
        }))
        break
      case TYPES.CIRCLE:
        setState((prevState) => ({
          ...prevState,
          addedPoints: prevState.addedPoints.map((c) => (c === item ? { ...c, show: !c.show } : c)),
          edit: prevState.edit.element === item ? EMPTY.labeler.edit : prevState.edit
        }))
        break
      case TYPES.LINE:
        setState((prevState) => ({
          ...prevState,
          addedLines: prevState.addedLines.map((c) => (c === item ? { ...c, show: !c.show } : c)),
          edit: prevState.edit.element === item ? EMPTY.labeler.edit : prevState.edit
        }))
        break
      case TYPES.POINT:
        setState((prevState) => ({
          ...prevState,
          addedCircles: prevState.addedCircles.map((c) =>
            c === item ? { ...c, show: !c.show } : c
          ),
          edit: prevState.edit.element === item ? EMPTY.labeler.edit : prevState.edit
        }))
        break
      default:
        break
    }
  }
  /*
eeeee eeeee e
8   8 8   8 8
8eee8 8eee8 8
8   8 8     8
8   8 8     8
 */

  const selectDataset = () => {
    if (state.currentCategoryTabCreate) {
      changeCurrentCategoryTab(0)
    }

    setState((prevState) => ({
      ...prevState,
      categories: props.project.categories,
      currentCategory: 0,
      isLoading: true
    }))
    setTagsCount(0)
    setTimers({ ...timers, timeCount: 0 })
  }

  // TODO: Move this to a method in react query
  const addImage = (item) => {
    if (item) {
      const names = item.key.split('/')
      const img = {
        id: item.id,
        image: item.image,
        userId: item.labeler.id,
        reviewer: item.tagReviewerId,
        name: names[names.length - 1],
        date: item.createdAt,
        key: item.key,
        width: item.width,
        height: item.height,
        downloaded: false,
        done: item.done,
        accepted: item.accepted
      }
      if (img.name) {
        dispatch({ type: 'setImages', payload: [...props.images, img] })
      }
    } else {
      setState((prevState) => ({ ...prevState, isLoading: false }))
      showSnackbar({
        message: 'There are no more images.',
        type: 'info'
      })
    }
  }

  /**
  8''''8 eeeee eeeee eeee eeeee eeeee eeeee  e  eeee eeeee
  8    8 8   8   8   8    8   8 8  88 8   8  8  8    8   '
  8      8eee8   8   8eee 8e    8   8 8eee8e 8  8eee 8eeee
  8    e 8   8   8   8    8  '8 8   8 8    8 8  8        8
  8eeee8 8   8   8   8eee 8eee8 8eee8 8    8 8  8eee 8eee8
  **/

  //  TODO: Add the functionality
  const mouseEnterOnCategory = () => {}
  const mouseLeaveOnCategory = () => {}

  const addCategory = (name, color, shape) => {
    const newShape = createShape(name, color, shape)
    for (let index = 0; index < state.categories.length; index++) {
      const element = state.categories[index]
      if (name === element.name) {
        showSnackbar({
          message: `There is already a category named ${name}.`,
          type: 'info'
        })
        return
      }
      if (color === element.color) {
        showSnackbar({
          message: `There is already a category with that color.`,
          type: 'info'
        })
        return
      }
    }
    changeCurrentCategoryTab(0)
    setState((prevState) => ({ ...prevState, categories: [...prevState.categories, newShape] }))
    showSnackbar({
      message: `Category added.`,
      type: 'success'
    })
  }

  const deleteCategory = (index) => {
    if (state.categories.length <= 1) {
      return
    }
    sandEvent('Delete Category', { name: state.categories[index] })
    props.setDialogAllProperties(
      true,
      1,
      'Delete Category',
      'This will delete all the associated tags.',
      null
    )
    setState((prevState) => ({ ...prevState, deletingIndex: index }))
  }

  const deleteAllTagsFromCategory = (index) => {
    if (index === state.currentCategory) {
      setState((prevState) => ({ ...prevState, currentCategory: 0 }))
    }
    let newTags = []
    let newCategories = []
    switch (state.categories[index].type) {
      case TYPES.RECT:
        newTags = state.addedRects.filter((element) => {
          return element.name !== state.categories[index].name
        })
        newCategories = state.categories.filter((stays, i) => {
          return i !== index
        })
        setState((prevState) => ({ ...prevState, addedRects: newTags }))
        break
      case TYPES.POLY:
        newTags = state.addedPolygons.filter((element) => {
          return element.name !== state.categories[index].name
        })
        newCategories = state.categories.filter((stays, i) => {
          return i !== index
        })
        setState((prevState) => ({ ...prevState, addedPolygons: newTags }))
        break
      case TYPES.POINT:
        newTags = state.addedPoints.filter((element) => {
          return element.name !== state.categories[index].name
        })
        newCategories = state.categories.filter((stays, i) => {
          return i !== index
        })
        setState((prevState) => ({ ...prevState, addedPoints: newTags }))
        break
      case TYPES.LINE:
        newTags = state.addedLines.filter((element) => {
          return element.name !== state.categories[index].name
        })
        newCategories = state.categories.filter((stays, i) => {
          return i !== index
        })
        setState((prevState) => ({ ...prevState, addedLines: newTags }))
        break
      case TYPES.CIRCLE:
        newTags = state.addedCircles.filter((element) => {
          return element.name !== state.categories[index].name
        })
        newCategories = state.categories.filter((stays, i) => {
          return i !== index
        })
        setState((prevState) => ({ ...prevState, addedCircles: newTags }))
        break
      default:
        break
    }

    setState((prevState) => ({ ...prevState, categories: newCategories }))
    showSnackbar({
      message: `Category deleted.`,
      type: 'success'
    })
  }

  const editCategory = (index, newName) => {
    if (newName.length > 0) {
      sandEvent('Edit Category', {
        oldName: state.categories[index],
        newName
      })
      let newTags = []
      for (let index = 0; index < state.categories.length; index++) {
        const element = state.categories[index]
        if (newName === element.name) {
          showSnackbar({
            message: `Two categories may not have the same name: ${newName}`,
            type: 'error'
          })
          return
        }
      }
      switch (state.categories[index].type) {
        case TYPES.POINT:
          newTags = state.addedPoints.map((element) => {
            if (element.name === state.categories[index].name) {
              element.name = newName
              element.color = state.categories[index].color
            }
            return element
          })
          setState((prevState) => ({ ...prevState, addedPoints: newTags }))
          break
        case TYPES.LINE:
          newTags = state.addedLines.map((element) => {
            if (element.name === state.categories[index].name) {
              element.name = newName
              element.color = state.categories[index].color
            }
            return element
          })
          setState((prevState) => ({ ...prevState, addedLines: newTags }))
          break
        case TYPES.RECT:
          newTags = state.addedRects.map((element) => {
            if (element.name === state.categories[index].name) {
              element.name = newName
              element.color = state.categories[index].color
            }
            return element
          })
          setState((prevState) => ({ ...prevState, addedRects: newTags }))
          break
        case TYPES.POLY:
          newTags = state.addedPolygons.map((element) => {
            if (element.name === state.categories[index].name) {
              element.name = newName
              element.color = state.categories[index].color
            }
            return element
          })
          setState((prevState) => ({ ...prevState, addedPolygons: newTags }))
          break
        case TYPES.CIRCLE:
          newTags = state.addedCircles.map((element) => {
            if (element.name === state.categories[index].name) {
              element.name = newName
              element.color = state.categories[index].color
            }
            return element
          })
          setState((prevState) => ({ ...prevState, addedCircles: newTags }))
          break
        default:
          break
      }
      const newCategories = state.categories.map((element, i) => {
        if (i === index) {
          element.name = newName
        }
        return element
      })
      setState((prevState) => ({ ...prevState, categories: newCategories }))
      showSnackbar({
        message: `Category edited`,
        type: 'success'
      })
    } else {
      showSnackbar({
        message: `Categories must have a name.`,
        type: 'error'
      })
    }
  }

  const changeCategory = (index) => {
    if (state.categories.length > index) {
      sandEvent('Change Category', { category: state.categories[index] })
      addEvent(Date.now(), 'change_category')
      setState((prevState) => ({
        ...prevState,
        currentCategory: index,
        currentPolygon: [],
        startY: null,
        startX: null
      }))
    }
  }

  const handleClickModeChange = () => {
    const nextState = props.clickMode
    let mouseType = 'crosshair'
    if (nextState === 'edit') {
      mouseType = 'default'
      setState((prevState) => ({
        ...prevState,
        addedRects: prevState.addedRects.map((r) => {
          return { ...r, selected: false }
        }),
        edit: EMPTY.labeler.edit,
        currentPolygon: [],
        currentLine: [],
        mouseType
      }))
    } else {
      setState((prevState) => ({
        ...prevState,
        addedRects: prevState.addedRects.map((r) => {
          return { ...r, selected: false }
        }),
        edit: EMPTY.labeler.edit,
        currentPolygon: [],
        mouseType
      }))
    }
  }

  /*
  88''Yb 888888 88b 88 8888b.  888888 88''Yb
  88__dP 88__   88Yb88  8I  Yb 88__   88__dP
  88'Yb  88''   88 Y88  8I  dY 88''   88'Yb
  88  Yb 888888 88  Y8 8888Y'  888888 88  Yb
  */

  const cancelDialog = () => {
    props.setDialogStatus(false)
    switch (props.dialogs.index) {
      case 1: // Delete
        setState((prevState) => ({ ...prevState, deletingIndex: null }))
        break
      case 2: // AI
        setState((prevState) => ({
          ...prevState,
          aiTags: [],
          categoriesSelected: [],
          loadingAI: false
        }))
        props.setDialogCategories([])
        break
      case 3: // AI Categories
        setState((prevState) => ({
          ...prevState,
          aiTags: [],
          categoriesSelected: [],
          loadingAI: false
        }))
        props.setDialogCategories([])
        break
      case 4: // No AI Categories found
        setState((prevState) => ({
          ...prevState,
          aiTags: [],
          categoriesSelected: [],
          loadingAI: false
        }))
        props.setDialogCategories([])
        break
      default:
        break
    }
  }

  const okDialog = () => {
    props.setDialogStatus(false)
    switch (props.dialogs.index) {
      case 1: //  Delete
        deleteAllTagsFromCategory(state.deletingIndex)
        setState((prevState) => ({ ...prevState, deletingIndex: null }))
        break
      case 2: // AI
        getAiTags(props.image ? props.image.key : null)
        break
      case 3: {
        // AI Categories
        const tags = state.aiTags.filter((tag) => {
          const { categoriesSelected } = state
          for (let i = 0; i < categoriesSelected.length; i++) {
            if (categoriesSelected[i].name === tag.name) {
              return true
            }
          }
          return false
        })
        state.categoriesSelected.forEach((cat) => {
          const { categories } = state
          for (let i = 0; i < categories.length; i++) {
            if (categories[i].name === cat.name) {
              return
            }
          }
          setState((prevState) => ({ ...prevState, categories: [...prevState.categories, cat] }))
        })

        setAiTags(tags)
        setState((prevState) => ({
          ...prevState,
          aiCategories: [],
          aiTags: [],
          categoriesSelected: [],
          loadingAI: false
        }))
        break
      }

      case 4:
        break
      case 5:
        navigate('/signup')
        break
      case 6: {
        const category = state.categories[state.currentCategory]
        if (category.type !== TYPES.POLY) {
          showSnackbar({
            message: `Please select a polygon category`,
            type: 'info'
          })
          return
        }
        const mouseType = 'pointer'

        dispatch({ type: 'setLabelerMode', payload: { category: category.type, mode: 'autoPoly' } })
        setState((prevState) => ({
          ...prevState,
          edit: EMPTY.labeler.edit,
          currentPolygon: [],
          currentLine: [],
          mouseType
        }))
        break
      }
      default:
        break
    }
  }

  const handleSelectCSV = () => {
    setState((prevState) => ({ ...prevState, openDownloadModal: false }))
    downloadObjectAs('tags', 'CSV')
  }

  const handleSelectJSON = () => {
    setState((prevState) => ({ ...prevState, openDownloadModal: false }))
    downloadObjectAs('tags', 'JSON')
  }

  const handleCategory = (event, category) => {
    const cats = state.categoriesSelected.filter((c) => c === category)

    if (cats.length <= 0) {
      setState((prevState) => ({
        ...prevState,
        categoriesSelected: [...prevState.categoriesSelected, category]
      }))
    } else {
      setState((prevState) => ({
        ...prevState,
        categoriesSelected: prevState.categoriesSelected.filter((c) => c !== category)
      }))
    }
  }

  const renderRightArea = () => {
    return (
      <RightArea
        selectImage={addImage}
        // AI Tools
        showAiDialog={showAiDialog}
        loadingAI={state.loadingAI}
        drawPoints={getAIPolygon}
        // Categories
        addCategory={addCategory}
        editCategory={editCategory}
        categories={state.categories}
        changeCategory={changeCategory}
        deleteCategory={deleteCategory}
        currentCategory={state.currentCategory}
        mouseEnterOnCategory={mouseEnterOnCategory}
        mouseLeaveOnCategory={mouseLeaveOnCategory}
        changeCurrentCategoryTab={changeCurrentCategoryTab}
        currentCategoryTabCreate={state.currentCategoryTabCreate}
        // Labels
        saveTags={props.saveTags}
        ratio={state.ratio}
        lines={state.addedLines}
        circles={state.addedCircles}
        deleteLine={deleteLine}
        deleteCircle={deleteCircle}
        hideLine={(e) => hideItem(e, TYPES.LINE)}
        hideCircle={(e) => hideItem(e, TYPES.CIRCLE)}
        mouseEnterOnList={mouseEnterOnList}
        mouseLeaveOnList={mouseLeaveOnList}
        selectItem={selectItem}
        selectedTag={state.selectedTag}
        handleClassChange={handleChildClassChange}
        changeLoading={changeLoading}
        classification={state.addedClassification}
        classificationChange={changeClassification.bind(this)}
      />
    )
  }

  useImperativeHandle(ref, () => {
    return {
      state,
      showAiDialog,
      changeCategory
    }
  })

  return (
    <div
      className="notranslate"
      style={{ height: '100vh', overflowY: 'hidden', marginTop: props.isAuthenticated ? 0 : 59 }}
    >
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          left: 2,
          zIndex: 1000,
          padding: '2px 5px',
          fontSize: 8
        }}
      >
        {props.version || ''}
      </div>
      <Grid container spacing={0} style={{ height: '100vh', margin: 0 }}>
        <Grid item xs={1} style={{ height: '100vh', width: 48, maxWidth: 48, flexBasis: 48 }}>
          {/* Space for the Toolbar */}
        </Grid>
        <LabelerComponent
          item
          xs={10}
          ms={11}
          md={9}
          lg={9}
          style={{
            overflow: 'hidden',
            animation: props.clickMode !== 'autoPoly' ? 'none' : 'glow 2s infinite'
          }}
        >
          <div style={{ width: '100%', height: 40 }}>
            <ImageManager
              embeddingExist={props.tensor === undefined ? undefined : !!props.tensor}
              handleNext={props.handleNext}
              handleBack={props.handleBack}
              changeLoading={changeLoading}
              imageInfo={imageInfo}
            />
            <Divider />
          </div>
          {MachineState.context.loading || props.loadingAutoBoundingboxes || props.loadingSam ? (
            <LinearProgress />
          ) : null}
          <div
            style={{
              transform: `scale(${state.scale})`,
              transformOrigin: `${state.scaleCenter[0]} ${state.scaleCenter[1]}`,
              height: '100%',
              minHeight: window.height
            }}
          >
            <MainCanvas onSegmentation={props.getEmbeddings} tensor={props.tensor} />
          </div>
        </LabelerComponent>
        <Grid item xs style={{ minWidth: 50 }}>
          {/* zeroMinWidth */}
          {renderRightArea()}
        </Grid>
      </Grid>
      <Dialog handleCategory={handleCategory} clickCancel={cancelDialog} okDialog={okDialog} />
      <DownloadAsModal
        open={state.openDownloadModal}
        handleDownloadModalClose={handleDownloadModalClose}
        disabledCSV={state.addedRects.length === 0}
        handleSelectJSON={handleSelectJSON}
        handleSelectCSV={handleSelectCSV}
      />
      {state.uploadImgDialog ? (
        <UploadDemoImageDialog
          closeUploadImgDialog={closeUploadImgDialog}
          setUploadImg={setUploadImg}
        />
      ) : null}
      <Hotkeys categories={state.categories} open={props.isHotkeysOpen} />
      <Paper
        style={{
          visibility: state.loadingAI ? 'visible' : 'hidden',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 3,
          width: '100vw',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#333333cc'
        }}
      >
        <CircularProgress size={60} />
      </Paper>
      <Menu
        keepMounted
        disableAutoFocusItem
        open={state.mouseMenuY !== null}
        onClose={handleCloseMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          state.mouseMenuY !== null && state.mouseMenuX !== null
            ? { top: state.mouseMenuY, left: state.mouseMenuX }
            : undefined
        }
      >
        <MenuItem
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleCloseMenu(e)
            }
          }}
        >
          <TextField
            variant="standard"
            id="annotation"
            label="- Text"
            onChange={handleAnnotationChange}
            value={state.edit.element && state.edit.element.text ? state.edit.element.text : ''}
          />
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleCloseMenu(e)
            hideItem(state.edit.element, state.edit.element.type)
          }}
        >
          - Hide
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleCloseMenu(e)
            deleteFigure()
          }}
        >
          - Delete
        </MenuItem>
      </Menu>
    </div>
  )
})

const mapStateToProps = (state) => ({
  state: state.labeler,
  project: state.project,
  uiMode: state.ui.mode,
  dialogs: state.dialogs
})

const mapDispatchToProps = {
  getRole: pActions.getRole,
  setImage: pActions.setImage,
  setDialogTitle: dActions.setTitle,
  setDialogText: dActions.setTitle,
  setDialogStatus: dActions.setStatus,
  setDialogCategories: dActions.setCategories,
  setDialogAllProperties: dActions.setAllProperties
}

Labeler.displayName = 'Labeler'
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Labeler)
