import React, { useState, useEffect } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import { filter as _filter } from 'lodash'
import PropTypes from 'prop-types'
import {
  Box,
  Chip,
  Paper,
  Divider,
  MenuItem,
  TextField,
  Typography,
  IconButton,
  styled
} from '@mui/material'

const RootSearch = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center'
}))

const RootFilters = styled('div')(() => ({
  alignItems: 'left',
  display: 'flex',
  justifyContent: 'left',
  marginTop: '12px'
}))

const SearchDivider = styled(Divider)(() => ({
  height: '28px',
  margin: '4px'
}))

const SelectedTextField = styled(TextField)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  width: '100px'
}))

const InputTextField = styled(TextField)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  flex: 3
}))

function SearchBar(props) {
  const { fields, singleSearch, justify } = props
  const [filters, setFilters] = useState([])
  const [logicConnector, setLogicConnector] = useState('and')
  const [search, setSearch] = useState({
    column: fields[0].id,
    query: fields[0],
    text: '',
    logicConnector: 'and'
  })

  const constOperators = [
    { label: '=', value: 'eq' },
    { label: '<', value: 'lt' },
    { label: '>', value: 'gt' }
  ]

  useEffect(() => {
    if (props.filtersChange) {
      props.filtersChange(mapFilters(filters))
    }
  }, [filters])

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleChangeFilter()
    }
  }

  const handleChangeFilter = () => {
    setTimeout(() => {
      setFilters(singleSearch ? [search] : [...filters, search])
    }, 200)
  }

  const handleChangeSearchColumn = (e) => {
    setSearch({
      column: e.target.value,
      query: fields.find((element) => element.id === e.target.value),
      text: e.target.value === 'done' || e.target.value === 'accepted' ? false : '', // TODO: Fix for any
      logicConnector
    })
    props.handleChangeSearchColumn && props.handleChangeSearchColumn(e)
  }

  const mapFilters = () => {
    const fltrsAnd = []
    const fltrsOr = []
    for (let i = 0; i < (filters || []).length; i++) {
      if (filters[i].logicConnector === 'and') {
        fltrsAnd.push({
          [filters[i].query.id === 'labeler'
            ? 'tagLabelerId'
            : filters[i].query.id === 'reviewer'
            ? 'tagReviewerId'
            : filters[i].query.id]:
            filters[i].query.id === 'nameDataset'
              ? {
                  [filters[i].query.id]:
                    filters[i].query.searchable === 'wildcard'
                      ? `*${filters[i].text}*`
                      : `${filters[i].text}`
                }
              : {
                  [filters[i].query.searchable]:
                    filters[i].query.searchable === 'wildcard'
                      ? `*${filters[i].text}*`
                      : `${filters[i].text}`
                }
        })
      } else {
        fltrsOr.push({
          [filters[i].query.id === 'labeler'
            ? 'tagLabelerId'
            : filters[i].query.id === 'reviewer'
            ? 'tagReviewerId'
            : filters[i].query.id]: {
            [filters[i].query.searchable]:
              filters[i].query.searchable === 'wildcard'
                ? `*${filters[i].text}*`
                : `${filters[i].text}`
          }
        })
      }
    }
    return { and: fltrsAnd, or: fltrsOr }
  }

  const deleteFilter = (index) => {
    const newFilters = filters.filter((_, i) => i !== index)

    setTimeout(() => {
      setFilters(newFilters)
    }, 200)
  }

  const onTextChange = (e) => {
    setSearch({ ...search, text: e.target.value })
    if (singleSearch && e.target.value.length === 0) {
      setFilters([])
    }
  }

  const onKeyDown = (e) => keyPress(e)

  const renderInput = () => {
    let opt1 = 'False'
    let opt2 = 'True'

    switch (search.query.type) {
      case 'select':
        return (
          <InputTextField
            variant="standard"
            autoFocus
            select
            sx={{ textAlign: 'left' }}
            InputProps={{ disableUnderline: true }}
            value={search.text}
            placeholder={`Search in ${search.query.label}`}
            onChange={onTextChange}
            onKeyDown={onKeyDown}
          >
            {search.query.options.map((option) => (
              <MenuItem key={option.id} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </InputTextField>
        )
      case 'boolean':
        if (search.query.options) {
          opt1 = search.query.options[0] || opt1
          opt2 = search.query.options[1] || opt2
        }

        return (
          <InputTextField
            variant="standard"
            autoFocus
            select
            sx={{ textAlign: 'left' }}
            InputProps={{ disableUnderline: true }}
            value={search.text}
            placeholder={`Search in ${search.query.label}`}
            onChange={onTextChange}
            onKeyDown={onKeyDown}
          >
            <MenuItem key="false" value={false}>
              {opt1}
            </MenuItem>
            <MenuItem key="true" value={true}>
              {opt2}
            </MenuItem>
          </InputTextField>
        )
      case 'number':
        return (
          <Box sx={{ display: 'flex', width: '100%' }}>
            <SelectedTextField
              variant="standard"
              autoFocus
              select
              sx={{ textAlign: 'center' }}
              InputProps={{ disableUnderline: true }}
              value={search.query.searchable}
              placeholder={`Search in ${search.query.label}`}
              onChange={(e) => {
                setSearch({ ...search, query: { ...search.query, searchable: e.target.value } })
              }}
              onKeyDown={onKeyDown}
            >
              {constOperators.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </SelectedTextField>
            <InputTextField
              variant="standard"
              autoFocus
              InputProps={{ disableUnderline: true }}
              value={search.text}
              placeholder={`Search in ${search.query.label}${
                search.column === 'savedAt' ? ' (YYYY-MM-DD)' : ''
              }`}
              onChange={onTextChange}
              onKeyDown={onKeyDown}
            />
          </Box>
        )
      case 'date':
        return (
          <InputTextField
            variant="standard"
            autoFocus
            InputProps={{ disableUnderline: true }}
            value={search.text}
            placeholder={`Search in ${search.query.label}${' (YYYY-MM-DD)'}`}
            onChange={onTextChange}
            onKeyDown={onKeyDown}
          />
        )
      default:
        return (
          <InputTextField
            variant="standard"
            autoFocus
            InputProps={{ disableUnderline: true }}
            value={search.text}
            placeholder={`Search in ${search.query.label}`}
            onChange={onTextChange}
            onKeyDown={onKeyDown}
          />
        )
    }
  }

  return (
    <>
      <RootSearch
        sx={{ ...props.style, justifyContent: !justify ? 'center' : justify, ...props.sx }}
      >
        <Paper
          sx={{
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: '70%'
          }}
        >
          {!singleSearch && filters.length > 0 && (
            <>
              <TextField
                variant="standard"
                select
                value={logicConnector}
                id="select-column"
                sx={{ width: '80px' }}
                InputProps={{ disableUnderline: true }}
                onChange={(e) => {
                  setLogicConnector(e.target.value)
                  setSearch({ ...search, logicConnector: e.target.value })
                }}
              >
                <MenuItem key={'and'} value={'and'}>
                  And
                </MenuItem>
                <MenuItem key={'or'} value={'or'}>
                  Or
                </MenuItem>
              </TextField>
              <SearchDivider orientation="vertical" />
            </>
          )}
          {fields && fields.length > 1 && (
            <>
              <SelectedTextField
                variant="standard"
                id="select-column"
                InputProps={{ disableUnderline: true }}
                select
                value={search.column}
                onChange={handleChangeSearchColumn}
              >
                {_filter(fields, (o) => !o.hide).map((column) => (
                  <MenuItem key={column.id} value={column.id}>
                    {column.labelSearch || column.label}
                  </MenuItem>
                ))}
              </SelectedTextField>
              <SearchDivider orientation="vertical" />
            </>
          )}
          {renderInput()}
          <SearchDivider orientation="vertical" />
          <IconButton
            type="submit"
            sx={{ padding: '10px' }}
            aria-label="search"
            onClick={handleChangeFilter}
            size="large"
          >
            <SearchIcon />
          </IconButton>
        </Paper>
      </RootSearch>
      {!singleSearch && (
        <RootFilters>
          {filters.map((filter, i) => {
            return (
              <>
                {i > 0 && (
                  <Typography style={{ marginTop: 12 }} variant="subtitle2">
                    {filter.logicConnector}
                  </Typography>
                )}
                <Chip
                  style={{ margin: 5 }}
                  key={filter.column}
                  label={`${filter.query.labelSearch || filter.query.label}:${
                    filter.query.id === 'count'
                      ? constOperators.find((item) => item.value === filter.query.searchable).label
                      : ''
                  } ${filter.text}`}
                  onDelete={() => deleteFilter(i)}
                  variant="outlined"
                />
              </>
            )
          })}
        </RootFilters>
      )}
    </>
  )
}

SearchBar.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.element).isRequired
}

// Props: Fields:
// {
//   id: 'key',
//   type: 'text', // text, select, number
//   label: 'Name', // string or HTML
//   minWidth: 200, // Optional
//   format: value => value //  Optional,
//   options: [] // Optional
//   searchable: 'wildcard' // ?
// }

export default SearchBar
