import React, { useContext } from 'react'
import {
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  ListItem,
  TextField,
  Typography,
  Accordion
} from '@mui/material'
import styled from '@emotion/styled'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { FiltersContext } from '../../../../../../context/FiltersContext'

const Container = styled('div')(() => ({
  width: '100%'
}))

const RangeContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '1rem'
}))

const LabelsCountFilter = () => {
  const { state, dispatch } = useContext(FiltersContext)

  const handleRange = () => {
    const min = parseInt(state.itemsCount.min)
    const max = parseInt(state.itemsCount.max)

    const payload = [
      { symbol: '>=', value: min > 0 ? min : 0 },
      { symbol: '<=', value: !isNaN(max) ? (max > 0 ? max : 0) : Infinity }
    ]

    dispatch({ type: 'setCount', payload })
  }

  const handleMinChange = (e) => {
    dispatch({
      type: 'setItemsCount',
      payload: { ...state.itemsCount, min: parseInt(e.target.value) }
    })
  }

  const handleMaxChange = (e) => {
    dispatch({
      type: 'setItemsCount',
      payload: { ...state.itemsCount, max: parseInt(e.target.value) }
    })
  }

  const isApplyDisabled =
    (state.itemsCount.max < state.itemsCount.min && state.itemsCount.max !== '') ||
    state.itemsCount.min < 0 ||
    state.itemsCount.max < 0

  const LabeledTextField = ({ id, label, value, onChange }) => (
    <FormControl variant="outlined">
      <TextField
        id={id}
        variant="outlined"
        label={label}
        placeholder=""
        type="number"
        onChange={onChange}
        value={value}
      />
    </FormControl>
  )

  return (
    <ListItem>
      <Accordion sx={{ width: '100%' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="count-content" id="count">
          <Typography>Labels Count</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Container>
            <RangeContainer>
              <LabeledTextField
                id="minLabelInput"
                label="Min"
                value={state.itemsCount.min}
                onChange={handleMinChange}
              />
              <Typography>To</Typography>
              <LabeledTextField
                id="maxLabelInput"
                label="Max"
                value={state.itemsCount.max}
                onChange={handleMaxChange}
              />
            </RangeContainer>
            <FormControl sx={{ width: '100%', margin: '0.5rem 0rem' }} variant="outlined">
              <Button
                name="labels"
                color="primary"
                variant="outlined"
                disabled={isApplyDisabled}
                onClick={handleRange}
              >
                Apply
              </Button>
            </FormControl>
          </Container>
        </AccordionDetails>
      </Accordion>
    </ListItem>
  )
}

export default LabelsCountFilter
