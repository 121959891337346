import { useEffect, useContext } from 'react'
import { useSelector } from 'react-redux'
import { DispatchContext } from '../../containers/labeler/context/DispatchContext'
import { LabelerContext } from '../../containers/labeler/context/LabelerContext'

import { useLocation } from 'react-router-dom'

// This only be call once in high level Component
// AVOID: Using in nested components
export default function useRole() {
  const reduxRole = useSelector((state) => state.project.role)
  const { state } = useContext(LabelerContext)
  const { dispatch } = useContext(DispatchContext)
  const location = useLocation()

  useEffect(() => {
    let labeler = reduxRole
    if (reduxRole === 'admin') {
      const urlParams = new URLSearchParams(location.search)
      const role = urlParams.get('role')
      labeler = role || 'viewer'
    }
    dispatch({ type: 'setRole', payload: labeler })
  }, [reduxRole])

  return { project: reduxRole, labeler: state ? state.labelerState.role : null }
}
