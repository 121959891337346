import React, { useContext } from 'react'
import LabelerRectList from './labeler/LabelerRectList'
import { LabelerContext } from '../../../../containers/labeler/context/LabelerContext'

const RectList = (
  ratio,
  lines,
  circles,
  deleteLine,
  deleteCircle,
  hideLine,
  hideCircle,
  selectItem,
  selectedTag,
  mouseEnterOnList,
  mouseLeaveOnList,
  handleClassChange
) => ({
  labeler: (
    <LabelerRectList
      ratio={ratio}
      lines={lines}
      circles={circles}
      deleteLine={deleteLine}
      deleteCircle={deleteCircle}
      hideLine={hideLine}
      hideCircle={hideCircle}
      selectItem={selectItem}
      selectedTag={selectedTag}
      mouseEnterOnList={mouseEnterOnList}
      mouseLeaveOnList={mouseLeaveOnList}
      handleClassChange={handleClassChange}
    />
  ),
  reviewer: (
    <LabelerRectList
      ratio={ratio}
      lines={lines}
      circles={circles}
      deleteLine={deleteLine}
      deleteCircle={deleteCircle}
      hideLine={hideLine}
      hideCircle={hideCircle}
      selectItem={selectItem}
      selectedTag={selectedTag}
      mouseEnterOnList={mouseEnterOnList}
      mouseLeaveOnList={mouseLeaveOnList}
      handleClassChange={handleClassChange}
    />
  ),
  viewer: (
    <LabelerRectList
      ratio={ratio}
      lines={lines}
      circles={circles}
      deleteLine={deleteLine}
      deleteCircle={deleteCircle}
      hideLine={hideLine}
      hideCircle={hideCircle}
      selectItem={selectItem}
      selectedTag={selectedTag}
      mouseEnterOnList={mouseEnterOnList}
      mouseLeaveOnList={mouseLeaveOnList}
      handleClassChange={handleClassChange}
    />
  )
})

export default function CompositeRectList(props) {
  const {
    state: {
      labelerState: { role }
    }
  } = useContext(LabelerContext)

  return (
    <>
      {
        RectList(
          props.ratio,
          props.lines,
          props.circles,
          props.deleteLine,
          props.deleteCircle,
          props.hideLine,
          props.hideCircle,
          props.selectItem,
          props.selectedTag,
          props.mouseEnterOnList,
          props.mouseLeaveOnList,
          props.handleClassChange
        )[role]
      }
    </>
  )
}
