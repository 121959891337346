import { useRef, useState } from 'react'

export function useTimerHook() {
  const hour = useRef(0)
  const minute = useRef(0)
  const second = useRef(0)
  const interval = useRef(null)
  const [timerState, setTimerState] = useState({ h: 0, m: 0, s: 0 })

  function returnData(input) {
    return input >= 10 ? input : `0${input}`
  }
  const startCounter = () =>
    (interval.current = setInterval(() => {
      timer()
    }, 1000))

  const stopCounter = () => {
    clearInterval(interval.current)
  }
  const setInitialTime = (millisenconds) => {
    const initialSencond = Math.trunc((millisenconds / 1000) % 60)
    const initialMinute = Math.trunc((millisenconds / (1000 * 60)) % 60)
    const initialHour = Math.trunc((millisenconds / (1000 * 60 * 60)) % 24)
    hour.current = initialHour
    minute.current = initialMinute
    second.current = initialSencond
  }
  const getTime = () => ({ hour: hour.current, minute: minute.current, second: second.current })

  function timer() {
    if (second.current < 59) {
      second.current = second.current + 1
    } else {
      second.current = 0
      minute.current = minute.current + 1
    }
    if (minute.current === 60) {
      minute.current = 0
      hour.current = hour.current + 1
    }
    setTimerState({
      h: returnData(hour.current),
      m: returnData(minute.current),
      s: returnData(second.current)
    })
  }

  return {
    timerState,
    controls: { stop: stopCounter, start: startCounter, setTime: setInitialTime, getTime }
  }
}
