import { handleActions } from 'redux-actions'

const defaultState = {
  mode: 'light',
  lenguage: 'EN',
  logo: '/assets/logo.png',
  logoHex: '/assets/monogram-logo.png'
}

const defaultDarkState = {
  mode: 'dark',
  lenguage: 'EN',
  logo: '/assets/logo-white.png',
  logoHex: '/assets/monogram-logo-white.png'
}

const reducer = handleActions(
  {
    UI: {
      SET_MODE: (state, { payload: { mode } }) => ({
        ...state,
        mode,
        logo: mode === 'light' ? defaultState.logo : defaultDarkState.logo,
        logoHex: mode === 'light' ? defaultState.logoHex : defaultDarkState.logoHex
      }),
      SET_LENGUAGE: (state, { payload: { lenguage } }) => ({ ...state, lenguage })
    }
  },
  defaultState
)

export default reducer
