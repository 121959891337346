import React, { useContext, useRef, useState, useEffect } from 'react'
import { ListItem, TextField } from '@mui/material'
import { DispatchContext } from '../../../context/DispatchContext'
import { LabelerContext } from '../../../context/LabelerContext'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import Menu from '@mui/material/Menu'

export default function BoundingBoxMenuList({ handleClose, category }) {
  const { state } = useContext(LabelerContext)
  const { dispatch } = useContext(DispatchContext)
  const { boundingBoxState, labelerState } = state
  const [pos, setPos] = useState({ x: 0, y: 0, open: false })
  const submenuRef = useRef(null)
  let submenuTimer

  const preventMenu = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }
  const handleAnnotationChange = (e) => {
    dispatch({ type: 'setBoundingText', payload: e.target.value })
  }

  const closeSubMenu = () => {
    setPos({ x: 0, y: 0, open: false })
  }

  const showSubmenu = () => {
    clearTimeout(submenuTimer)
    setPos({
      open: true,
      x:
        submenuRef.current.getBoundingClientRect().x +
        submenuRef.current.getBoundingClientRect().width,
      y: submenuRef.current.getBoundingClientRect().y
    })
  }

  const hideSubmenu = () => {
    submenuTimer = setTimeout(() => {
      setPos({ x: 0, y: 0, open: false })
    }, 300)
  }

  const handleSubmenuMouseEnter = () => {
    clearTimeout(submenuTimer)
  }

  const handleSubmenuMouseLeave = () => {
    hideSubmenu()
  }

  useEffect(() => {
    dispatch({ type: 'setSelectedBounding', payload: category.index })
  }, [category])

  const changeCategory = (index, category) => {
    dispatch({
      type: 'changeBoundingbox',
      payload: { index, name: category.name, color: category.color }
    })
  }

  return (
    <>
      <MenuList dense>
        <MenuItem>
          <ListItem>
            <TextField
              variant="standard"
              id="annotation"
              label="Text"
              onChange={handleAnnotationChange}
              value={
                boundingBoxState.selectedTag > -1 &&
                // eslint-disable-next-line no-prototype-builtins
                boundingBoxState.tags[boundingBoxState.selectedTag].hasOwnProperty('text')
                  ? boundingBoxState.tags[boundingBoxState.selectedTag].text
                  : ''
              }
            />
          </ListItem>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            handleClose()
            dispatch({ type: 'hideBounding' })
          }}
        >
          <ListItemText>Hide</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose()
            dispatch({ type: 'deleteboundingBox' })
          }}
        >
          <ListItemText>Delete</ListItemText>
        </MenuItem>
        <MenuItem ref={submenuRef} onMouseOver={showSubmenu} onMouseOut={hideSubmenu}>
          <ListItemText>Change</ListItemText>
          <Typography variant="body2" color="text.secondary">
            <ArrowRightIcon />
          </Typography>
        </MenuItem>
      </MenuList>

      <Menu
        onMouseEnter={handleSubmenuMouseEnter}
        onMouseLeave={handleSubmenuMouseLeave}
        onContextMenu={preventMenu}
        disableAutoFocusItem
        open={pos.open}
        anchorReference="anchorPosition"
        anchorPosition={{ top: pos.y, left: pos.x }}
        onClose={closeSubMenu}
      >
        <MenuList dense>
          {labelerState.categories
            .filter((cat) => cat.type === 'bounding_box')
            .map((cat, index) => {
              return (
                <MenuItem
                  onClick={() => {
                    closeSubMenu()
                    handleClose()
                    changeCategory(category.index, cat)
                  }}
                  key={index}
                >
                  <ListItemText>{cat.name}</ListItemText>
                </MenuItem>
              )
            })}
        </MenuList>
      </Menu>
    </>
  )
}
