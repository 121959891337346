import React, { useContext } from 'react'
import { Box, Grid, styled } from '@mui/material'
import LoadResultsFlag from './components/LoadResultsFlag'
import VisualizerCard from './components/visualizerCard/VisualizerCard'
import NoItemsFound from './components/NoItemsFound'
import PlaceholderImage from './components/PlaceholderImage'
import useImages from './hooks/useImages'
import useImagesClick from './components/visualizerCard/hooks/useImagesClick'
import { FiltersContext } from '../../context/FiltersContext'

const InfiniteScrollContainer = styled(Box)(({ theme }) => ({
  display: 'block',
  height: '100%',
  margin: '1rem',
  overflowY: 'scroll',
  textAlign: 'center',
  '&::-webkit-scrollbar': {
    width: '6px',
    borderRadius: '6px',
    display: 'none'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '6px',
    display: 'none'
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'rgba(0, 0, 0, 0.1',
    display: 'none'
  },
  '&::-webkit-scrollbar-button': {
    display: 'none'
  }
}))

const VisualizerGrid = () => {
  const { state } = useContext(FiltersContext)
  const { images } = state
  const {
    isLoading,
    isError,
    loadPrevImages,
    loadMoreImages,
    shouldShowLoadPrevResults,
    shouldShowLoadMoreResults
  } = useImages()

  const { imageSelected, handleSelectedImage, openLabelerImage } = useImagesClick()

  return (
    <InfiniteScrollContainer id="infinite-scroll-container">
      {images?.length > 0 && (
        <>
          {shouldShowLoadPrevResults && <LoadResultsFlag loadFunction={loadPrevImages} />}
          <Grid container spacing={1} justifyContent="center">
            {images
              .flatMap((page) => page.images)
              .map((image, index) => (
                <VisualizerCard
                  key={image?.id}
                  image={image}
                  imageSelected={imageSelected}
                  clickFunction={handleSelectedImage}
                  doubleClickFunction={openLabelerImage}
                  index={index}
                />
              ))}
          </Grid>
          {shouldShowLoadMoreResults && <LoadResultsFlag loadFunction={loadMoreImages} />}
        </>
      )}
      {!isLoading && !isError && images?.flatMap((page) => page.images).length === 0 && (
        <NoItemsFound />
      )}
      {!isLoading && isError && <p>Error</p>}
      {isLoading && Array.from({ length: 20 }, (_, i) => <PlaceholderImage key={i} />)}
    </InfiniteScrollContainer>
  )
}

export default VisualizerGrid
