import { useLayoutEffect, useContext, useRef } from 'react'
import { LabelerContext } from '../../../context/LabelerContext'
import { useLabelerMachine } from '../../../context/MachineContext'
export default function useZoom(panZoom, wheel, canvasList, callback = () => {}) {
  const { state: MachineState } = useLabelerMachine()
  const { state } = useContext(LabelerContext)
  const { labelerState } = state
  const isInitialMount = useRef(true)

  useLayoutEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      if (MachineState.value === 'readyForLabeling') zoom()
    }
  }, [panZoom, wheel])

  const zoom = () => {
    canvasList.forEach((canvas) => {
      const c = canvas.current
      const ctx = c.getContext('2d')
      ctx.setTransform(1, 0, 0, 1, 0, 0)
      ctx.clearRect(0, 0, c.width, c.height)
    })
    apply()
  }

  function apply() {
    canvasList.forEach((canvas) => {
      const c = canvas.current
      const ctx = c.getContext('2d')
      ctx.setTransform(panZoom.scale, 0, 0, panZoom.scale, panZoom.x, panZoom.y)
      ctx.imageSmoothingEnabled = labelerState.smooth
    })

    callback()
  }
}
