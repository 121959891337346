import React from 'react'
import { Navigate } from 'react-router-dom'
import Loader from '../components/generics/Loader'

function querystring(name, url = window.location.href) {
  name = name.replace(/[[]]/g, '\\$&')

  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)', 'i')
  const results = regex.exec(url)

  if (!results) {
    return null
  }
  if (!results[2]) {
    return ''
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export default function Home(props) {
  const redirect = querystring('redirect')

  return (
    <>
      <Loader />
      {props.isAuthenticated ? (
        <Navigate to={redirect === '' || redirect === null ? '/projects' : redirect} />
      ) : (
        <Navigate to={`/login?redirect=${redirect}`} />
      )}
    </>
  )
}
