import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'

export const DivContainer = styled('div')({
  backgroundImage: `url('${process.env.PUBLIC_URL}/assets/background_login.png')`,
  height: '100%',
  position: 'relative',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover'
})

export const GridHeader = styled(Grid)({
  textAlign: 'center',
  color: '#777',
  margin: '80px 0px 30px 0px'
})

export const GridPanel = styled(Grid)({
  margin: 8,
  backgroundColor: 'white',
  borderRadius: 15,
  boxShadow: '5px 5px 25px #3336',
  padding: '20px'
})

export const Img = styled('img')({
  margin: 'auto',
  maxWidth: '220px',
  maxHeight: '100%'
})
