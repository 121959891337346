import React from 'react'
import { Card, ProgressTitle, TitleContent } from '../StyledComponents'
import DefaultTable from '../../../../../../components/generics/tables/DefaultTable'
import getTableConfig from './utils/tableConfig'

const ImagesMetricsTable = ({ imgFilter, project, handleClick, theme }) => {
  const { keys, columns } = getTableConfig(imgFilter)

  return (
    <Card elevation={2}>
      <TitleContent>
        <ProgressTitle variant="h6">
          Images <b>x</b> {imgFilter === 'saved' ? 'Labeler' : 'Reviewer'}
        </ProgressTitle>
      </TitleContent>
      <DefaultTable
        handleClick={handleClick}
        clickeable={true}
        headColor={theme.palette.mode === 'dark' ? theme.palette.background.default : null}
        rowsPerPageOptions={[4, 8, 12]}
        rowsPerPage={8}
        keys={keys}
        columns={columns}
        data={
          imgFilter === 'saved'
            ? project.imagesStats.table.saved
            : imgFilter === 'reviewed'
            ? project.imagesStats.table.reviewed
            : project.imagesStats.table.saved
        }
      />
    </Card>
  )
}

export default ImagesMetricsTable
