import React, { useRef, useContext, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { connect } from 'react-redux'
import { LabelerContext } from '../../../context/LabelerContext'
import { DispatchContext } from '../../../context/DispatchContext'
import { isInsidePoly } from '../../../../../utils/labeler'
import useZoom from '../hooks/useZoom'
import useResizeCategoryCanvas from '../hooks/useResizeCategoryCanvas'
import { useCategoryEventListener } from '../hooks/useCategoryEventListener'
import { getAutoPolygon } from '../../../../../io/api/ml'
import { s3Get } from '../../../../../services/aws'
import { useEffectWR } from '../hooks/useEffectWR'
import { useClearCanvas } from '../hooks/useClearCanvas'
import { sandLabelStarted, sandLabelCreated, sandLabelEddited } from '../../../../../io/analytics'
import { useDrawTags } from '../hooks/useDrawTags'

function PolygonCanvas({ labeler, panZoom, mouse, project }) {
  const { state } = useContext(LabelerContext)
  const { dispatch } = useContext(DispatchContext)
  const { polygonState, labelerState } = state
  const poly = useRef(null)
  const editPoly = useRef(null)
  const suggestionPoly = useRef(null)
  const point = useRef(null)

  const [autoPoly, setAutoPoly] = useState(null)

  const [menu, setMenu] = useState({ open: false, pos: { x: 0, y: 0 } })

  const canvasIncompleteFigures = useRef(document.createElement('canvas', null, null))
  const canvasCompleteFigures = useRef(document.createElement('canvas', null, null))
  const canvasLineGuide = useRef(document.createElement('canvas', null, null))
  const GLOBAL_ALPHA = labelerState.canvasSettings[4].value

  useResizeCategoryCanvas(
    [canvasIncompleteFigures, canvasCompleteFigures, canvasLineGuide],
    'polygons'
  )
  useClearCanvas([canvasIncompleteFigures, canvasCompleteFigures, canvasLineGuide], 'polygons')
  useDrawTags(() => {
    drawPoints()
    drawCompleteShapes()
    drawAutoPolyPoints()
  }, 'polygons')
  useZoom(
    panZoom,
    mouse.wheel,
    [canvasIncompleteFigures, canvasCompleteFigures, canvasLineGuide],
    () => {
      drawPoints()
      drawCompleteShapes()
      drawAutoPolyPoints()
    }
  )

  useCategoryEventListener('mousedown', mouseDown, labeler.current, 'polygon') // DRAW
  useCategoryEventListener('mouseup', mouseUp, labeler.current, null)
  useCategoryEventListener('mousemove', mouseMove, labeler.current, null)
  useCategoryEventListener('mousedown', mouseRight, labeler.current, null) // MENU
  useCategoryEventListener('mousedown', mouseEditLeft, labeler.current, null) // EDIT
  useEffectWR([labelerState.mode, labelerState.selectedCategory], [deleteIncompletePolygons])
  useEffectWR([polygonState.redraw], [drawPoints, drawCompleteShapes])
  useEffectWR([GLOBAL_ALPHA], [drawPoints, drawCompleteShapes])
  useEffectWR([autoPoly], [drawAutoPolyPoints])

  useEffect(() => {
    poly.current = polygonState.poly
  }, [polygonState.poly])

  function mouseDown(e) {
    if (e.button === 0) {
      if (labelerState.mode === 'draw') {
        addPoint((mouse.x - panZoom.x) / panZoom.scale, (mouse.y - panZoom.y) / panZoom.scale)
        return
      }
      if (labelerState.mode === 'edit' && e.ctrlKey) {
        const p = hoverOnPoint(
          (mouse.x - panZoom.x) / panZoom.scale,
          (mouse.y - panZoom.y) / panZoom.scale
        )
        if (p !== null) {
          handleDelete(p)
        } else {
          addEditPoint((mouse.x - panZoom.x) / panZoom.scale, (mouse.y - panZoom.y) / panZoom.scale)
        }

        return
      }
      if (labelerState.mode === 'autoPoly') {
        addPointInAutoPoly(
          (mouse.x - panZoom.x) / panZoom.scale,
          (mouse.y - panZoom.y) / panZoom.scale
        )
      }
    }
  }
  function mouseEditLeft(e) {
    if (e.button === 0) {
      if (labelerState.mode === 'edit' && !e.ctrlKey) {
        clickOnElement((mouse.x - panZoom.x) / panZoom.scale, (mouse.y - panZoom.y) / panZoom.scale)
      }
    }
  }
  function mouseRight(e) {
    if (e.button === 2) {
      if (
        clickOnElement((mouse.x - panZoom.x) / panZoom.scale, (mouse.y - panZoom.y) / panZoom.scale)
      ) {
        if (point.current === null) {
          setMenu({ open: true, pos: { x: mouse.x + 48, y: mouse.y + 33 } })
        }
      }
    }
  }
  function mouseUp() {
    confirmEdit((mouse.x - panZoom.x) / panZoom.scale, (mouse.y - panZoom.y) / panZoom.scale)
  }
  function mouseMove(e) {
    drawLineGuide((mouse.x - panZoom.x) / panZoom.scale, (mouse.y - panZoom.y) / panZoom.scale)
    if (labelerState.mode === 'edit') {
      if (e.ctrlKey) {
        const p = hoverOnPoint(
          (mouse.x - panZoom.x) / panZoom.scale,
          (mouse.y - panZoom.y) / panZoom.scale
        )
        if (p === null) {
          addSuggestedItem(
            (mouse.x - panZoom.x) / panZoom.scale,
            (mouse.y - panZoom.y) / panZoom.scale
          )
        } else {
          deleteSuggestedPoint(p)
        }
        drawCompleteShapes(true)
      } else {
        removeSuggestedPolygon()
      }
      if (mouse.button) {
        sandLabelEddited({ type: 'Poly', id: project.id, name: project.name })
        update((mouse.x - panZoom.x) / panZoom.scale, (mouse.y - panZoom.y) / panZoom.scale, e)
      }
    }
  }

  const addSuggestedItem = (x, y) => {
    if (polygonState.selectedTag > -1) {
      let minimumDistance1 = Infinity
      let minimumDistance2 = Infinity
      let nearestPoint1, nearestPoint2
      const points = [...polygonState.tags[polygonState.selectedTag].pos]

      polygonState.tags[polygonState.selectedTag]?.pos.forEach((point, index) => {
        const distancia = calcularDistancia({ x, y }, point)
        if (distancia < minimumDistance1) {
          minimumDistance2 = minimumDistance1
          nearestPoint2 = nearestPoint1

          minimumDistance1 = distancia
          nearestPoint1 = index
        } else if (distancia < minimumDistance2) {
          minimumDistance2 = distancia
          nearestPoint2 = index
        }
      })

      let index = Math.abs(minimumDistance1 - minimumDistance2)
      if (index === 1) {
        index = nearestPoint2 + 1
      } else {
        index = nearestPoint1 + 1
      }
      points.splice(index, 0, { x, y })
      suggestionPoly.current = { ...polygonState.tags[polygonState.selectedTag], pos: points }

      const canvas = canvasIncompleteFigures.current
      const ctx = canvas.getContext('2d')
      ctx.setTransform(1, 0, 0, 1, 0, 0)
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.setTransform(panZoom.scale, 0, 0, panZoom.scale, panZoom.x, panZoom.y)
      ctx.beginPath()
      ctx.globalCompositeOperation = 'source-over'
      ctx.globalAlpha = 0.3
      ctx.fillStyle = '#fff'
      ctx.strokeStyle = '#000000'
      ctx.lineWidth = 2 / panZoom.scale
      polygonState.tags[polygonState.selectedTag].pos.forEach((pos) => {
        ctx.lineTo(pos.x, pos.y)
      })
      ctx.stroke()
      ctx.fill()
      ctx.beginPath()
      ctx.globalCompositeOperation = 'source-out'
      ctx.lineWidth = 1 / panZoom.scale
      suggestionPoly.current.pos.forEach((pos) => {
        ctx.lineTo(pos.x, pos.y)
      })
      ctx.stroke()
      ctx.fill()
      ctx.globalCompositeOperation = 'source-over'
      ctx.globalAlpha = 1
      polygonState.tags[polygonState.selectedTag].pos.forEach((pos) => {
        ctx.beginPath()
        ctx.arc(pos.x, pos.y, 5 / panZoom.scale, 0, 2 * Math.PI)
        ctx.fill()
        ctx.stroke()
      })
    }
  }

  const deleteSuggestedPoint = (p) => {
    const point = polygonState.tags[polygonState.selectedTag].pos[p]
    const canvas = canvasIncompleteFigures.current
    const ctx = canvas.getContext('2d')
    ctx.setTransform(1, 0, 0, 1, 0, 0)
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    ctx.setTransform(panZoom.scale, 0, 0, panZoom.scale, panZoom.x, panZoom.y)
    ctx.beginPath()
    ctx.globalAlpha = 0.3
    ctx.fillStyle = '#fff'
    ctx.strokeStyle = '#000000'
    ctx.lineWidth = 2 / panZoom.scale
    polygonState.tags[polygonState.selectedTag].pos.forEach((pos) => {
      ctx.lineTo(pos.x, pos.y)
    })
    ctx.stroke()
    ctx.fill()
    ctx.beginPath()
    ctx.globalAlpha = 1
    ctx.arc(point.x, point.y, 5 / panZoom.scale, 0, 2 * Math.PI)
    ctx.fillStyle = '#FF0000'
    ctx.fill()
  }

  const addEditPoint = (x, y) => {
    if (polygonState.selectedTag > -1) {
      let minimumDistance1 = Infinity
      let minimumDistance2 = Infinity
      let nearestPoint1, nearestPoint2
      const points = [...polygonState.tags[polygonState.selectedTag].pos]

      polygonState.tags[polygonState.selectedTag]?.pos.forEach((point, index) => {
        const distancia = calcularDistancia({ x, y }, point)
        if (distancia < minimumDistance1) {
          minimumDistance2 = minimumDistance1
          nearestPoint2 = nearestPoint1

          minimumDistance1 = distancia
          nearestPoint1 = index
        } else if (distancia < minimumDistance2) {
          minimumDistance2 = distancia
          nearestPoint2 = index
        }
      })

      let index = Math.abs(minimumDistance1 - minimumDistance2)
      if (index === 1) {
        index = nearestPoint2 + 1
      } else {
        index = nearestPoint1 + 1
      }
      points.splice(index, 0, { x, y })
      editPoly.current = { ...editPoly.current, pos: points }
      dispatch({
        type: 'updateAllPoints',
        payload: { polyindex: polygonState.selectedTag, pos: points }
      })
    }
  }

  function calcularDistancia(p1, p2) {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2))
  }

  const addPoint = (x, y) => {
    if (poly.current) {
      if (verifyCompletedShape({ x, y }, poly.current.pos[0])) {
        sandLabelCreated({ type: 'Poly', id: project.id, name: project.name })
        poly.current = { ...poly.current, pos: resizePoly(poly.current) }
        dispatch({ type: 'addPolygon', payload: poly.current })
        poly.current = null
        dispatch({ type: 'setPoly', payload: null })
        drawPoints()
      } else {
        poly.current = { ...poly.current, pos: [...poly.current.pos, { x, y }] }
        dispatch({ type: 'setPoly', payload: poly.current })
        dispatch({
          type: 'addPointInCache',
          payload: { index: poly.current.pos.length, pos: { x, y } }
        })
      }
    } else {
      sandLabelStarted({ type: 'Poly', id: project.id, name: project.name })
      const polygon = labelerState.selectedCategory
      poly.current = {
        classes: [],
        color: polygon.color,
        id: uuidv4(),
        name: polygon.name,
        pos: [{ x, y }],
        type: 'polygon',
        show: true
      }
      dispatch({ type: 'addPointInCache', payload: { index: 0 }, pos: { x, y } })
      dispatch({ type: 'setPoly', payload: poly.current })
    }
    dispatch({ type: 'setSaveTags' })
  }
  const resizePoly = (poly) => {
    return poly.pos.map((pos) => {
      const element = { ...pos }
      if (pos.x < 0 || pos.x > labelerState.image.props.w) {
        const a = pos.x < 0 ? 0 : labelerState.image.props.w
        element.x = a
      }
      if (pos.y < 0 || pos.y > labelerState.image.props.h) {
        const b = pos.y < 0 ? 0 : labelerState.image.props.h
        element.y = b
      }
      return element
    })
  }
  const resizePointPoly = (pos) => {
    const point = { ...pos }
    if (pos.x < 0 || pos.x > labelerState.image.props.w) {
      const a = pos.x < 0 ? 0 : labelerState.image.props.w
      point.x = a
    }
    if (pos.y < 0 || pos.y > labelerState.image.props.h) {
      const b = pos.y < 0 ? 0 : labelerState.image.props.h
      point.y = b
    }
    return point
  }
  const addPointInAutoPoly = (x, y) => {
    let pol
    if (autoPoly) {
      if (verifyCompletedShape({ x: Math.round(x), y: Math.round(y) }, autoPoly.pos[0])) {
        dispatch({ type: 'setLoadingAI', payload: true })
        pol = { ...autoPoly }
        getAutoPoly()
        setAutoPoly(null)
      } else {
        pol = { ...autoPoly, pos: [...autoPoly.pos, { x: Math.round(x), y: Math.round(y) }] }
        setAutoPoly(pol)
      }
    } else {
      pol = {
        pos: [{ x: Math.round(x), y: Math.round(y) }]
      }
      setAutoPoly(pol)
    }
    dispatch({ type: 'setSaveTags' })
  }
  const getAutoPoly = async () => {
    try {
      const selectedImage =
        labelerState.image.index >= 0 && labelerState.image.index < labelerState.image.images.length
          ? labelerState.image.images[labelerState.image.index]
          : null
      const s3url = await s3Get(selectedImage.image.url)
      const poly = await getAutoPolygon(s3url, autoPoly.pos, 1)
      const polyjson = await poly.json()

      const polygon = labelerState.selectedCategory
      const element = {
        id: uuidv4(),
        color: polygon.color,
        name: polygon.name,
        pos: [],
        type: 'polygon',
        show: true
      }
      for (let i = 0; i < polyjson.output.length; i++) {
        element.pos.push({
          x: polyjson.output[i][0],
          y: polyjson.output[i][1]
        })
      }
      dispatch({ type: 'addPolygon', payload: element })
      dispatch({ type: 'setLoadingAI', payload: false })
    } catch (error) {
      dispatch({ type: 'setLoadingAI', payload: false })
    }
  }

  const removeSuggestedPolygon = () => {
    if (polygonState.selectedTag > -1) {
      const canvas = canvasIncompleteFigures.current
      const ctx = canvas.getContext('2d')
      ctx.setTransform(1, 0, 0, 1, 0, 0)
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.setTransform(panZoom.scale, 0, 0, panZoom.scale, panZoom.x, panZoom.y)
      ctx.beginPath()
      drawCompleteShapes()
    }
  }

  function drawCompleteShapes(ctrlKey = false) {
    if (labelerState.image.isRendered) {
      const canvas = canvasCompleteFigures.current
      const ctx = canvas.getContext('2d')
      ctx.setTransform(1, 0, 0, 1, 0, 0)
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.setTransform(panZoom.scale, 0, 0, panZoom.scale, panZoom.x, panZoom.y)
      ctx.beginPath()
      polygonState.tags.forEach((polygon, index) => {
        if (index !== polygonState.selectedTag) {
          if (polygon.show || polygon.show === undefined) {
            ctx.beginPath()
            ctx.strokeStyle = polygon.hover ? '#000000' : polygon.color
            ctx.globalAlpha = GLOBAL_ALPHA
            ctx.fillStyle = polygon.hover ? '#ffffff' : polygon.color
            ctx.lineWidth = 1 / panZoom.scale
            polygon.pos.forEach((pos) => {
              ctx.lineTo(pos.x, pos.y)
            })
            ctx.lineTo(polygon.pos[0].x, polygon.pos[0].y)
            ctx.stroke()
            ctx.fill()
          }
        }
      })

      if (polygonState.selectedTag > -1 && !ctrlKey) {
        const canvas1 = canvasIncompleteFigures.current
        const ctx1 = canvas1.getContext('2d')
        ctx1.setTransform(1, 0, 0, 1, 0, 0)
        ctx1.clearRect(0, 0, canvas.width, canvas.height)
        ctx1.setTransform(panZoom.scale, 0, 0, panZoom.scale, panZoom.x, panZoom.y)
        ctx1.beginPath()
        ctx1.globalCompositeOperation = 'source-over'
        const polygon = polygonState.tags[polygonState.selectedTag]
        if (polygon.show || polygon.show === undefined) {
          ctx1.beginPath()
          ctx1.fillStyle = '#ffffff4d'
          ctx1.strokeStyle = '#000'
          ctx1.lineWidth = 1 / panZoom.scale
          polygon.pos.forEach((pos) => {
            ctx1.lineTo(pos.x, pos.y)
          })
          ctx1.lineTo(polygon.pos[0].x, polygon.pos[0].y)
          ctx1.stroke()
          ctx1.fill()
          ctx1.fillStyle = '#ffffff'
          polygon.pos.forEach((pos) => {
            ctx1.beginPath()
            ctx1.arc(pos.x, pos.y, 5 / panZoom.scale, 0, 2 * Math.PI)
            ctx1.fill()
            ctx1.stroke()
          })
        }
      }
    }
  }
  function drawPoints(x, y) {
    const canvas = canvasIncompleteFigures.current
    const ctx = canvas.getContext('2d')

    if (poly.current) {
      ctx.setTransform(1, 0, 0, 1, 0, 0)
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.setTransform(panZoom.scale, 0, 0, panZoom.scale, panZoom.x, panZoom.y)
      ctx.beginPath()

      ctx.lineWidth = 1 / panZoom.scale
      ctx.fillStyle = poly.current.color + '4D'
      ctx.strokeStyle = poly.current.color
      poly.current.pos.forEach((pos) => {
        ctx.lineTo(pos.x, pos.y)
      })
      ctx.lineTo(x, y)
      ctx.stroke()
      ctx.fill()
      ctx.beginPath()
      ctx.fillStyle = '#fff'
      ctx.strokeStyle = '#000'
      ctx.arc(poly.current.pos[0].x, poly.current.pos[0].y, 5 / panZoom.scale, 0, 2 * Math.PI)
      ctx.stroke()
      ctx.fill()
      if (polygonState.selectedTag > -1) {
        const polygon = polygonState.tags[polygonState.selectedTag]
        if (polygon.show || polygon.show === undefined) {
          ctx.beginPath()
          ctx.fillStyle = '#ffffff4d'
          ctx.strokeStyle = '#000'
          ctx.lineWidth = 1 / panZoom.scale
          polygon.pos.forEach((pos) => {
            ctx.lineTo(pos.x, pos.y)
          })
          ctx.lineTo(polygon.pos[0].x, polygon.pos[0].y)
          ctx.stroke()
          ctx.fill()
          ctx.fillStyle = '#ffffff'
          polygon.pos.forEach((pos) => {
            ctx.beginPath()
            ctx.arc(pos.x, pos.y, 5 / panZoom.scale, 0, 2 * Math.PI)
            ctx.fill()
            ctx.stroke()
          })
        }
      }
    } else {
      if (polygonState.selectedTag === -1) {
        ctx.setTransform(1, 0, 0, 1, 0, 0)
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        ctx.setTransform(panZoom.scale, 0, 0, panZoom.scale, panZoom.x, panZoom.y)
        ctx.beginPath()
      }
    }
  }
  function drawAutoPolyPoints(x, y) {
    if (autoPoly) {
      const canvas = canvasIncompleteFigures.current
      const ctx = canvas.getContext('2d')
      ctx.setTransform(1, 0, 0, 1, 0, 0)
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.setTransform(panZoom.scale, 0, 0, panZoom.scale, panZoom.x, panZoom.y)
      ctx.beginPath()
      ctx.lineWidth = 1 / panZoom.scale
      ctx.fillStyle = '#ffffff80'
      ctx.strokeStyle = '#000'
      autoPoly.pos.forEach((pos) => {
        ctx.lineTo(pos.x, pos.y)
      })
      ctx.lineTo(x, y)
      ctx.stroke()
      ctx.fill()
      ctx.beginPath()
      ctx.fillStyle = '#fff'
      ctx.strokeStyle = '#000'
      ctx.arc(autoPoly.pos[0].x, autoPoly.pos[0].y, 5 / panZoom.scale, 0, 2 * Math.PI)
      ctx.stroke()
      ctx.fill()
    }
  }
  const update = (x, y, e) => {
    if (point.current !== null) {
      const canvas = canvasIncompleteFigures.current
      const ctx = canvas.getContext('2d')

      ctx.setTransform(1, 0, 0, 1, 0, 0)
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.setTransform(panZoom.scale, 0, 0, panZoom.scale, panZoom.x, panZoom.y)
      ctx.beginPath()
      ctx.fillStyle = '#ffffff' + '80'
      ctx.strokeStyle = '#000000'
      ctx.lineWidth = 1 / panZoom.scale
      const p = { ...editPoly.current, pos: [...editPoly.current.pos] }

      editPoly.current.pos.forEach((pos, index) => {
        if (index !== point.current) {
          ctx.lineTo(pos.x, pos.y)
        } else {
          p.pos[index] = { x, y }
          ctx.lineTo(x, y)
        }
      })
      editPoly.current = p
      ctx.stroke()
      ctx.fill()
      e.stopImmediatePropagation()
    }
  }
  const confirmEdit = (x, y) => {
    if (point.current !== null) {
      const newpoint = resizePointPoly({ x, y })
      dispatch({
        type: 'updatePoly',
        payload: {
          polyindex: polygonState.selectedTag,
          posindex: point.current,
          pos: { x: newpoint.x, y: newpoint.y }
        }
      })
      editPoly.current = {
        ...editPoly.current,
        pos: editPoly.current.pos.map((pos, index) => {
          if (index === point.current) {
            return newpoint
          }
          return pos
        })
      }
      point.current = null
    }
  }
  const verifyCompletedShape = (point, firstPoint) => {
    if (
      Math.sqrt(Math.pow(firstPoint.x - point.x, 2) + Math.pow(firstPoint.y - point.y, 2)) <
      5 / panZoom.scale
    ) {
      return true
    }

    return false
  }
  const clickOnElement = (x, y) => {
    point.current = null
    if (polygonState.selectedTag > -1) {
      for (
        let index = polygonState.tags[polygonState.selectedTag].pos.length - 1;
        index >= 0;
        index--
      ) {
        if (
          Math.sqrt(
            Math.pow(polygonState.tags[polygonState.selectedTag].pos[index].x - x, 2) +
              Math.pow(polygonState.tags[polygonState.selectedTag].pos[index].y - y, 2)
          ) <
          5 / panZoom.scale
        ) {
          point.current = index
          return true
        }
      }
      for (let index = polygonState.tags.length - 1; index >= 0; index--) {
        if (polygonState.tags[index].show) {
          if (isInsidePoly(polygonState.tags[index].pos, { x, y })) {
            editPoly.current = polygonState.tags[index]
            dispatch({ type: 'selectPoly', payload: index })
            return true
          }
        }
      }
    } else {
      for (let index = polygonState.tags.length - 1; index >= 0; index--) {
        if (polygonState.tags[index].show) {
          if (isInsidePoly(polygonState.tags[index].pos, { x, y })) {
            editPoly.current = polygonState.tags[index]
            dispatch({ type: 'selectPoly', payload: index })
            return true
          }
        }
      }
    }
    editPoly.current = null
    dispatch({ type: 'selectPoly', payload: -1 })
    return null
  }

  const hoverOnPoint = (x, y) => {
    if (polygonState.selectedTag > -1) {
      for (
        let index = polygonState.tags[polygonState.selectedTag].pos.length - 1;
        index >= 0;
        index--
      ) {
        if (
          Math.sqrt(
            Math.pow(polygonState.tags[polygonState.selectedTag].pos[index].x - x, 2) +
              Math.pow(polygonState.tags[polygonState.selectedTag].pos[index].y - y, 2)
          ) <
          5 / panZoom.scale
        ) {
          return index
        }
      }
      return null
    }
    return null
  }
  const drawLineGuide = (x, y) => {
    if (poly.current) {
      drawPoints(x, y)
    }
    if (autoPoly) {
      drawAutoPolyPoints(x, y)
    }
  }
  function deleteIncompletePolygons() {
    dispatch({ type: 'setPoly', payload: null })
    editPoly.current = null
    const canvas = canvasIncompleteFigures.current
    const ctx = canvas.getContext('2d')
    ctx.setTransform(1, 0, 0, 1, 0, 0)
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    drawCompleteShapes()
  }

  const handleClose = () => {
    setMenu({ ...menu, open: false })
  }

  const handleDelete = (index) => {
    if (polygonState.selectedTag > -1) {
      if (polygonState.tags[polygonState.selectedTag].pos.length > 3) {
        const points = [...polygonState.tags[polygonState.selectedTag].pos]
        points.splice(index, 1)
        editPoly.current = { ...editPoly.current, pos: points }

        dispatch({
          type: 'updateAllPoints',
          payload: { polyindex: polygonState.selectedTag, pos: points }
        })
        handleClose()
      }
    }
  }

  return (
    <>
      <canvas className="layout" ref={canvasCompleteFigures} />
      <canvas className="layout" ref={canvasIncompleteFigures} />
      <canvas style={{ cursor: labelerState.cursor }} className="layout" ref={canvasLineGuide} />
    </>
  )
}

const mapStateToProps = (state) => ({
  project: state.project
})

export default connect(mapStateToProps, {})(PolygonCanvas)
