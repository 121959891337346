import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Grid, Paper, CircularProgress } from '@mui/material'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { verifyEmail } from '../../services/aws'
import '../../styles/Signup.css'
import { useSnackbar } from '../../components/snackbar/context/SnackbarContext'

function Verify() {
  const [isLoading, setIsLoading] = useState(false)
  const [verified, setVerified] = useState(false)

  const { showSnackbar } = useSnackbar()

  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)

    // TODO: Use Auth to check if email exist, or redirect
    const urlParams = new URLSearchParams(location.search)
    const code = urlParams.get('code')
    const email = urlParams.get('email')
    if (email && code) verifiedSignUp(email, code)
    else navigate('/login')
  }, [])

  const verifiedSignUp = async (email, code) => {
    if (email.length <= 0 || code.length <= 0) {
      showSnackbar({
        message: 'Missing data.',
        type: 'error'
      })
      return
    }

    setIsLoading(true)

    try {
      await verifyEmail(email, code)
        .then(() => {
          // at this time the user is logged in if no MFA required
          showSnackbar({
            message: 'Email Confirmed.',
            type: 'success'
          })
          setVerified(true)
          setIsLoading(false)
        })
        .catch(() => {
          showSnackbar({
            message: 'Missing option.',
            type: 'error'
          })
          setIsLoading(false)
        })
      setTimeout(() => {
        navigate('/login')
      }, 4096)
    } catch (error) {
      console.log('error confirming sign up', error)
    }
  }

  return (
    <div className="signup-bg">
      <Grid container className="signup-grid-container">
        <Grid item xs={12} spacing={2}>
          <Paper sx={{ margin: '80px 0px 30px 0px', textAlign: 'center', padding: 5 }}>
            {isLoading ? (
              <div style={{ width: '100%' }} className="signup-col">
                <CircularProgress />
              </div>
            ) : (
              <div style={{ width: '100%' }} className="signup-col">
                {verified ? (
                  <>
                    <h4>
                      <b>You are verified!</b>
                    </h4>
                    <p>
                      Shortly you&apos;ll be redirect to{' '}
                      <Link to="/login">
                        <span className="blue-text">Login</span>
                      </Link>
                    </p>
                  </>
                ) : (
                  <>
                    <h4>Something went wrong, please try again.</h4>
                    <p>
                      Get back to{' '}
                      <Link to="/login">
                        <span className="blue-text">Login</span>
                      </Link>
                    </p>
                  </>
                )}
              </div>
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state) => ({
  logo: state.ui.logo
})

export default connect(mapStateToProps, null)(Verify)
