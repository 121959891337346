import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import thunk from 'redux-thunk'

import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootReducers from './reducers'
import rootSagas from './sagas'

const store = () => {
  const persistConfig = {
    key: 'root',
    storage,
    version: 2, // New version 0, default or previous version -1
    whitelist: ['ui', 'project']
  }
  const persistedReducer = persistReducer(persistConfig, rootReducers())
  const sagaMiddleware = createSagaMiddleware()

  const saveTags = () => (next) => (action) => {
    if (action.type === 'TAGS/SAVE_TAGS_IN_DB') {
      if (
        action.payload.loadingTags === true ||
        action.payload.loadingImage === true ||
        action.payload.loadingTags === undefined ||
        action.payload.loadingImage === undefined ||
        action.payload.role === 'viewer'
      ) {
        const result = next({ type: 'DIALOG/SET_OPEN_SNACK', payload: { open: false } })
        return result
      }
    }
    const result = next(action)
    return result
  }

  const middleware = [saveTags, thunk, sagaMiddleware]

  const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(...middleware)))
  const persistor = persistStore(store)
  sagaMiddleware.run(rootSagas)

  return { store, persistor }
}

export default store
