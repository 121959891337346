import React, { useRef, useContext, useEffect } from 'react'
import * as ort from 'onnxruntime-web'
import { LabelerContext } from '../../../../context/LabelerContext'
// import { DispatchContext } from '../../../../containers/context/DispatchContext'
import { getPath } from '../../../../../../services/ai'
import Sam from './Sam'
// import { useSelector } from 'react-redux'

const { InferenceSession } = ort

export default function SamContainer({ labeler, panZoom, mouse, image, tensor: refTensor }) {
  // const projectState = useSelector((state) => state.project)

  const { state } = useContext(LabelerContext)
  // const { dispatch } = useContext(DispatchContext)
  const { labelerState } = state // segmentationState,

  const model = useRef(null)
  const tensor = useRef(null)
  const modelScale = useRef(null)

  useEffect(() => {
    if (labelerState.image.isRendered) {
      loadImage()
      getAll()
    }
  }, [labelerState.image.props])

  useEffect(() => {
    if (!labelerState.image.isRendered) {
      tensor.current = null
    }
  }, [labelerState.image.isRendered])

  useEffect(() => {
    if (refTensor) {
      tensor.current = refTensor
    }
  }, [refTensor])

  function loadImage() {
    try {
      const { height, width, samScale } = handleImageScale(labelerState.image.props)
      modelScale.current = {
        height,
        width,
        samScale
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getAll = async () => {
    try {
      const urlModel = await getPath('pruebas/onnx_models/sam_onnx_quantized_vit_h.onnx')
      initModel(urlModel)
    } catch (error) {
      console.log('ERR:', error)
    }
  }

  const initModel = async (URL) => {
    try {
      if (URL === undefined) return
      const _model = await InferenceSession.create(URL)
      model.current = _model
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleImageScale = (image) => {
    const LONG_SIDE_LENGTH = 1024
    const w = image.w
    const h = image.h
    const samScale = LONG_SIDE_LENGTH / Math.max(h, w)
    return { height: h, width: w, samScale }
  }

  return (
    <>
      {labelerState.mode === 'magic-v2' && (
        <Sam
          labeler={labeler}
          image={image}
          panZoom={panZoom}
          mouse={mouse}
          model={model.current}
          tensor={tensor.current}
          modelScale={modelScale.current}
        />
      )}
    </>
  )
}
