import React from 'react'
import useImageCanvas from './hooks/useImageCanvas'

const ImageCanvas = ({ image, cardImageRef, callDrawImage, width, height, open }) => {
  const { canvasImageRef, canvasRef, imgRef } = useImageCanvas({
    image,
    cardImageRef,
    callDrawImage,
    width,
    height,
    open
  })

  return (
    <>
      <canvas ref={canvasImageRef} style={{ position: 'absolute' }} />
      <canvas ref={canvasRef} style={{ position: 'absolute' }} />
      <img
        alt={image.name}
        ref={imgRef}
        style={{ display: 'none' }}
        src={image.src}
        loading="lazy"
      />
    </>
  )
}

export default ImageCanvas
