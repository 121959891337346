import React, { useContext, useState, useEffect } from 'react'
import { useLabelerMachine } from '../../../context/MachineContext'
import { DispatchContext } from '../../../context/DispatchContext'
export default function useOrchestrateLabelArea(canvasRef, containerRef, image) {
  const { state: MachineState, send } = useLabelerMachine()
  const { dispatch } = useContext(DispatchContext)
  const [dx, setDx] = useState(0)
  const [dy, setDy] = useState(0)
  const [scale, setScale] = useState(0)

  useEffect(() => {
    if (MachineState.value === 'prepareLabelArea') {
      const containerWidth = containerRef.current.clientWidth
      const containerHeight = containerRef.current.clientHeight - 40
      const canvas = canvasRef.current
      canvas.width = containerWidth
      canvas.height = containerHeight
      dispatch({
        type: 'setLabelerDimensions',
        payload: { width: canvas.width, height: canvas.height }
      })

      send({ type: 'RESOLVE', source: 'useOrchestrateLabelArea1' })
    }
  }, [MachineState.value])

  useEffect(() => {
    if (MachineState.value === 'clearImage') {
      setDx(0)
      setDy(0)
      setScale(0)
      const containerWidth = containerRef.current.clientWidth
      const containerHeight = containerRef.current.clientHeight - 40
      const canvas = canvasRef.current
      const ctx = canvas.getContext('2d')
      ctx.setTransform(1, 0, 0, 1, 0, 0)
      ctx.clearRect(0, 0, containerWidth, containerHeight)
      dispatch({ type: 'setLoadingTags', payload: true })
      dispatch({ type: 'setBoundingboxes', payload: [] })
      dispatch({ type: 'setLandmarks', payload: [] })
      dispatch({ type: 'setSegmentation', payload: { all: [], stack: [] } })
      dispatch({ type: 'setPolygons', payload: [] })
      dispatch({ type: 'setLines', payload: [] })
      dispatch({ type: 'setClassifications', payload: [] })
      send({ type: 'RESOLVE', source: 'useOrchestrateLabelArea2' })
    }
  }, [MachineState.value])

  useEffect(() => {
    if (MachineState.value === 'drawImage') {
      let scaleW = 1
      let scaleH = 1
      const containerWidth = containerRef.current.clientWidth
      const containerHeight = containerRef.current.clientHeight - 40
      const canvas = canvasRef.current
      const ctx = canvas.getContext('2d')
      const img = new Image()
      img.onload = function () {
        if (this.width > canvas.width || this.height > canvas.height) {
          scaleW = 100 / ((this.width * 100) / containerWidth)
          scaleH = 100 / ((this.height * 100) / containerHeight)
        }
        const scale = scaleH < scaleW ? scaleH : scaleW
        dispatch({
          type: 'setImageProps',
          payload: { w: this.width, h: this.height, optimalScale: scale }
        })

        let dx = (canvas.width - this.width) / 2
        let dy = (canvas.height - this.height) / 2
        dx = dx < 0 ? 0 : dx
        dy = dy < 0 ? 0 : dy
        ctx.setTransform(1, 0, 0, 1, 0, 0)
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        ctx.setTransform(scale, 0, 0, scale, dx, dy)
        ctx.drawImage(img, 0, 0)
        setDx(dx)
        setDy(dy)
        setScale(scale)
        send({ type: 'RESOLVE', source: 'useOrchestrateLabelArea3' })
      }
      img.src = image
    }
  }, [MachineState.value])

  return { dx, dy, scale }
}
