import { getTimeNow } from '../../../../../../../utils/util'

export const createRequestParams = (prop, value, user) => {
  const request = {
    [prop]: value
  }

  if ((prop === 'done' || prop === 'skiped') && value) {
    request.tagLabelerId = user.id
    request[prop === 'done' ? 'savedAt' : 'skippedAt'] = getTimeNow()
  }
  if ((prop === 'done' || prop === 'skiped') && !value) {
    request.tagLabelerId = 'none'
    request.tagReviewerId = 'none'
  }

  if (prop === 'accepted' && value) {
    request.tagReviewerId = user.id
    request.reviewedAt = getTimeNow()
  }

  if (prop === 'accepted' && !value) {
    request.tagReviewerId = 'none'
  }

  return request
}
