import { createActions } from 'redux-actions'

export const { project } = createActions({
  PROJECT: {
    GET_PROJECT: (id, role) => ({ id, role }),
    GET_CATEGORIES: (id, role) => ({ id, role }),
    GET_ROLE: (userId, teamId) => ({ userId, teamId }),
    GET_IMAGES: (projectId, nextToken) => ({ projectId, nextToken }),
    GET_IMAGE: (id) => ({ id }),
    GET_LABELS_STATS: (projectId, team) => ({ projectId, team }),
    GET_IMAGES_STATS: (projectId, team) => ({ projectId, team }),
    GET_LABELER_STATS: (projectId, userId) => ({ projectId, userId }),
    GET_REVIWER_STATS: (projectId, userId) => ({ projectId, userId }),
    UPDATE_PROJECT: (id, categories) => ({ id, categories }),
    UPDATE_NAME_PROJECT: (id, name) => ({ id, name }),

    SET_PROJECT: (project) => ({ project }),
    SET_CATEGORIES: (categories) => ({ categories }),
    SET_PROJECT_TEAM: (team) => ({ team }),
    SET_ROLE: (role) => ({ role }),
    SET_IMAGES: (images, image, index) => ({ images, image, index }),
    SET_IMAGE: (data, index) => ({ data, index }),
    SET_LABELS_STATS: (data) => ({ data }),
    SET_IMAGES_STATS: (data) => ({ data }),
    SET_LABELER_STATS: (data) => ({ data }),
    SET_REVIWER_STATS: (data) => ({ data }),
    CLEAN_PROJECT: (data) => ({ data })
  }
})
