export const updateTag = /* GraphQL */ `
  mutation UpdateTag($input: UpdateTagInput!) {
    updateTag(input: $input) {
      id
      done
      accepted
      skiped
      savedAt
      reviewedAt
      annotations {
        circles {
          id
          name
          color
          type
          id
          text
        }
        lines {
          id
          name
          color
          type
          text
        }
        points {
          id
          name
          color
          type
          text
        }
        polys {
          id
          name
          color
          type
          text
          classes {
            name
            options
            selected
          }
        }
        rects {
          id
          name
          color
          type
          pos
          parent
          text
          classes {
            name
            options
            selected
          }
        }
        classification {
          name
          options
          selected
        }
      }
      count
      time
      status
      labeler {
        id
        name
        email
        company
        termsAccepted
        status
        owner
      }
      reviewer {
        id
        name
        email
        owner
      }
      image {
        id
        key
        url
      }
      project {
        id
        name
        status
        team {
          id
          name
          status
        }
        user {
          id
          name
          email
        }
      }
    }
  }
`
