import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Input from '@mui/material/Input'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Cancel'

const EditableTextField = (props) => {
  const [editText, setEditText] = useState('')

  useEffect(() => {
    if (props.value) {
      setEditText(props.value)
    }
  }, [props.value])

  const actionsForm = (action) => {
    const editIconButton = document.getElementById('icon-button-edit-name')
    const saveIconButton = document.getElementById('icon-button-save-name')
    const cancelIconButton = document.getElementById('icon-button-cancel-name')
    const inputNameDataset = document.getElementById('input-edit-name-dataset')
    const displayNameDataset = document.getElementById('display-name-dataset')

    switch (action) {
      case 'edit':
        saveIconButton.style.display = 'flex'
        cancelIconButton.style.display = 'flex'
        editIconButton.style.display = 'none'
        inputNameDataset.style.display = 'block'
        displayNameDataset.style.display = 'none'

        if (props.onEditClicked) {
          props.onEditClicked(editText)
        }
        break
      case 'save':
        saveIconButton.style.display = 'none'
        cancelIconButton.style.display = 'none'
        editIconButton.style.display = 'flex'
        inputNameDataset.style.display = 'none'
        displayNameDataset.style.display = 'flex'

        if (props.onSaveClicked) {
          props.onSaveClicked(editText)
        }
        break
      case 'cancel':
        saveIconButton.style.display = 'none'
        cancelIconButton.style.display = 'none'
        editIconButton.style.display = 'flex'
        inputNameDataset.style.display = 'none'
        displayNameDataset.style.display = 'flex'
        setEditText(props.value)

        if (props.onCancelClicked) {
          props.onCancelClicked(editText)
        }
        break

      default:
        break
    }
  }

  return (
    <Box
      sx={{
        ...props.sx,
        textAlign: 'left',
        boxShadow: 'none',
        display: 'flex',
        height: '30px',
        alignItems: 'center'
      }}
    >
      <Typography id="display-name-dataset" variant="h5">
        {props.value}
      </Typography>
      <Input
        id="input-edit-name-dataset"
        inputProps={{
          sx: { display: 'none', fontSize: 18 }
        }}
        variant="standard"
        value={editText}
        defaultValue={props.defaultValue || ''}
        onChange={(e) => setEditText(e.target.value)}
      />
      <IconButton
        id="icon-button-edit-name"
        color="primary"
        aria-label="add to shopping cart"
        onClick={() => actionsForm('edit')}
      >
        <EditIcon />
      </IconButton>
      <IconButton
        id="icon-button-save-name"
        color="primary"
        aria-label="add to shopping cart"
        onClick={() => actionsForm('save')}
        style={{ display: 'none' }}
      >
        <SaveIcon />
      </IconButton>
      <IconButton
        id="icon-button-cancel-name"
        color="primary"
        aria-label="add to shopping cart"
        onClick={() => actionsForm('cancel')}
        style={{ display: 'none' }}
      >
        <CancelIcon />
      </IconButton>
    </Box>
  )
}

export default EditableTextField
