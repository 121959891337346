import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, Grid, Paper, Typography } from '@mui/material'
import { Download } from '@mui/icons-material'
import CreateCategory from '../../CreateCategory'
import DraggableCategoryList from '../../categoryDragAndDrop/DraggableCategoryList'
import { TYPES } from '../../../utils/constants'
import { createShape, createClassification } from '../../../utils/objects'
import DialogUploadCategories from './DialogUploadCategories'
import { sandCategoryAddedToProject, sandCategoryRemovedToProject } from '../../../io/analytics'
import { project as p } from '../../../io/project/actions'
import { styled } from '@mui/material/styles'

const ContainerText = styled('div')(() => ({
  display: 'flex',
  gap: '1rem',
  marginBottom: '1rem',
  alignItems: 'center',
  height: '65px',
  justifyContent: 'center',
  borderBottom: '1px solid #bdbdbd'
}))

const Text = styled(Typography)(() => ({
  textAlign: 'left'
}))

const CategoriesPaper = styled(Paper)(({ theme }) => ({
  overflow: 'auto',
  maxHeight: 300,
  minHeight: 220,
  padding: theme.spacing(1)
}))

const AddPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  padding: theme.spacing(1),
  height: '100%'
}))

function ProjectSettings({ project, updateCategories }) {
  const [editIndex, setEditIndex] = useState(-1)
  const handleDelete = (name) => {
    setEditIndex(-1)

    const newCategories = project.categories.filter((category) => {
      if (category.name === name) {
        sandCategoryRemovedToProject({
          name: category.name,
          type: category.type,
          projectId: project.id,
          projectName: project.name
        })
      }
      return category.name !== name && category.parent !== name
    })

    updateCategories(project.id, newCategories)
  }

  const addCategory = (category) => {
    const createdCat =
      category.type === TYPES.CLASSIFICATION
        ? createClassification(
            category.name,
            category.classes,
            category.parent === 'none' ? null : category.parent,
            category.classType,
            category.required
          )
        : createShape(
            category.name,
            category.color,
            category.type,
            category.parent === 'none' ? null : category.parent
          )
    let newCategories = []
    if (editIndex === -1) {
      newCategories = [...project.categories, createdCat]
    } else {
      newCategories = project.categories.map((cat, i) => (i === editIndex ? createdCat : cat))
      setEditIndex(-1)
    }

    updateCategories(project.id, newCategories)
    sandCategoryAddedToProject({
      name: createdCat.name,
      type: createdCat.type,
      projectId: project.id,
      projectName: project.name
    })
  }

  const uploadCategories = (categories) => {
    const newCategories = project.categories.concat(categories)
    updateCategories(project.id, newCategories)
  }

  const handleDownload = () => {
    const dataStr =
      'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(project.categories))
    const downloadAnchorNode = document.createElement('a')
    downloadAnchorNode.setAttribute('href', dataStr)
    downloadAnchorNode.setAttribute('download', `${project.id}-categories.json`)
    document.body.appendChild(downloadAnchorNode) // required for firefox
    downloadAnchorNode.click()
    downloadAnchorNode.remove()
  }

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <Text variant="h5">Categories</Text>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'right', padding: '0px 12px' }}>
          <DialogUploadCategories
            uploadCategories={uploadCategories}
            categories={project.categories}
          />
          <Button
            sx={{ margin: 1 }}
            color="secondary"
            size="small"
            variant="contained"
            onClick={handleDownload}
            endIcon={<Download />}
          >
            Export
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={0} sx={{ padding: '1rem', gap: '1rem' }}>
        <Grid item xs={12} sm={4}>
          <AddPaper elevation={3}>
            <ContainerText>
              <Text variant="h6">Add New Category</Text>
            </ContainerText>
            <CreateCategory
              addCategory={addCategory}
              cancelEdit={() => setEditIndex(-1)}
              categories={project.categories}
              editIndex={editIndex}
              loading={project.loading}
            />
          </AddPaper>
        </Grid>
        <Grid item xs>
          <CategoriesPaper elevation={3}>
            <ContainerText>
              <Text variant="h6">Current Categories</Text>
            </ContainerText>
            <DraggableCategoryList
              editIndex={editIndex}
              project={project}
              updateCategories={updateCategories}
              deleteCategory={handleDelete}
              setEditIndex={setEditIndex}
            />
          </CategoriesPaper>
        </Grid>
      </Grid>
    </>
  )
}

const mapStateToProps = (state) => ({
  project: state.project
})
const mapDispatchToProps = {
  updateCategories: p.updateProject
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSettings)
