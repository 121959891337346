import React, { useContext } from 'react'
import {
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  FormGroup,
  ListItem,
  Typography,
  FormControl,
  Accordion
} from '@mui/material'
import styled from '@emotion/styled'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { FiltersContext } from '../../../../../../context/FiltersContext'
import { uniqBy as _uniq } from 'lodash'
import { useSelector } from 'react-redux'
import { getUser } from '../../../../../../../../../../utils/util'

const ContainerFormControl = styled('div')(() => ({
  width: '100%'
}))

const ContainerLabel = styled('div')(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '160px'
}))

const LabelerFilter = () => {
  const { state, dispatch } = useContext(FiltersContext)
  const store = useSelector((state) => state)

  const handleChange = (labeler) => {
    const userIndex = state.labelers.findIndex((labelerItem) => labelerItem === labeler.user.id)
    if (userIndex !== -1) {
      const updatedLabelers = [...state.labelers]
      updatedLabelers.splice(userIndex, 1)
      dispatch({ type: 'setLabelers', payload: updatedLabelers })
    } else {
      const newLabelers = [...state.labelers, labeler.user.id]
      dispatch({ type: 'setLabelers', payload: newLabelers })
    }
  }

  const user = getUser()
  const teamMembers = _uniq(
    [{ user: { id: user.id, email: user.email } }, ...(store.project.team?.members?.items || [])],
    'user.id'
  )

  return (
    <ListItem>
      <Accordion sx={{ width: '100%' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="labeler-content"
          id="labeler"
        >
          <Typography>Labeler</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ContainerFormControl>
            <FormControl component="fieldset">
              <FormGroup>
                {teamMembers.map((labeler) => (
                  <FormControlLabel
                    key={labeler.user.id}
                    control={
                      <Checkbox
                        checked={state.labelers.some(
                          (labelerItem) => labelerItem === labeler.user.id
                        )}
                        onClick={() => handleChange(labeler)}
                        name={'labelers'}
                      />
                    }
                    label={<ContainerLabel>{labeler.user.email}</ContainerLabel>}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </ContainerFormControl>
        </AccordionDetails>
      </Accordion>
    </ListItem>
  )
}

export default LabelerFilter
