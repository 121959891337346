import { createSvgIcon } from '@mui/material/utils'

const Icon = createSvgIcon(
  <svg
    width="800px"
    height="800px"
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    // xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path fill="#2c0f6d" d="M16 16h-16l16-16z"></path>
  </svg>
)

const CornerIcon = (props) => {
  return <Icon {...props} />
}

export default CornerIcon
