import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import NewUserDialog from '../components/modal/NewUserDialog'

const AuthenticatedRoute = ({ isAuth, children, redirectTo }) => {
  const location = useLocation()

  return isAuth ? (
    <>
      {children}
      <NewUserDialog />
    </>
  ) : (
    <Navigate to={`${redirectTo}`} state={{ from: location }} replace />
  )
}

export default AuthenticatedRoute
