import React, { useState } from 'react'
import Message from './Message'
import { Avatar, Chip } from '@mui/material'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { stringAvatar } from '../../../../../utils/util'
import { styled } from '@mui/material/styles'
import useRole from '../../../../../utils/labeler/useRole'
const ContainerAvatar = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: 7
}))

const ContainerUsername = styled('span')(() => ({
  paddingLeft: 10,
  fontWeight: '500'
}))

const ContainerArrow = styled('span')(() => ({
  position: 'absolute',
  right: 20
}))

const ContainerComment = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start'
}))

const ContainerMessage = styled('div')(() => ({
  textAlign: 'left',
  wordWrap: 'break-word',
  paddingLeft: 30,
  paddingRight: 20
}))

const ReplyButton = styled('a')(() => ({
  fontSize: '12px',
  paddingLeft: 30,
  fontWeight: '500',
  marginTop: 5,
  cursor: 'pointer'
}))

const ClosedText = styled('p')(() => ({
  fontSize: '12px',
  paddingLeft: 30,
  fontWeight: '500',
  marginTop: 5
}))

const ContainerButtons = styled('div')(() => ({
  display: 'flex'
}))

export default function Comments({
  comments,
  open,
  handleAddMessage,
  handleOpen,
  handleCloseComment
}) {
  const [activeInput, setActiveInput] = useState(null)
  const role = useRole()
  const handleIndexInput = (index) => {
    setActiveInput(index)
  }

  return (
    <>
      {comments.map((comment, index) => {
        return (
          <>
            <ContainerAvatar onClick={() => handleOpen(index)}>
              <Avatar {...stringAvatar(comment.messages[0].username)} />
              <ContainerUsername>
                {comment.messages[0].username}{' '}
                {!open[index] && <Chip size="small" label={comment.messages.length} />}
              </ContainerUsername>
              <ContainerArrow style={{}}>
                {open[index] ? <ExpandLess /> : <ExpandMore />}
              </ContainerArrow>
            </ContainerAvatar>
            {open[index] && (
              <ContainerComment>
                <ContainerMessage>{comment.messages[0].message}</ContainerMessage>
                <ContainerButtons>
                  {!comment.closed ? (
                    <>
                      <ReplyButton onClick={() => handleIndexInput(index)}>Reply</ReplyButton>
                      {role.labeler === 'reviewer' && (
                        <ReplyButton onClick={() => handleCloseComment(index)}>Close</ReplyButton>
                      )}
                    </>
                  ) : (
                    <ClosedText>(Closed)</ClosedText>
                  )}
                </ContainerButtons>
              </ContainerComment>
            )}
            {!comment.closed && (
              <Message
                open={open[index]}
                handleAddMessage={handleAddMessage}
                handleCancel={() => setActiveInput(null)}
                comments={comments}
                comment={comment}
                index={index}
                activeInput={activeInput}
              />
            )}
          </>
        )
      })}
    </>
  )
}
