import React, { useContext, useState, useRef } from 'react'
import PanTool from '@mui/icons-material/PanTool'
import Add from '@mui/icons-material/Add'
import {
  Button,
  Card,
  Slider,
  Tooltip,
  CardContent,
  Typography,
  ClickAwayListener
} from '@mui/material'
import JoinFullIcon from '@mui/icons-material/JoinFull'
import ScoreIcon from '@mui/icons-material/Score'
import CropSquareIcon from '@mui/icons-material/CropSquare'
import { styled } from '@mui/material/styles'
import { LabelerContext } from '../../../containers/labeler/context/LabelerContext'
import { DispatchContext } from '../../../containers/labeler/context/DispatchContext'

const IconButton = styled(Button)(() => ({
  minWidth: 48,
  width: 48
}))

export default function BoundingBUttons() {
  const {
    state: {
      labelerState: { mode, selectedCategory },
      boundingBoxState
    }
  } = useContext(LabelerContext)
  const { dispatch } = useContext(DispatchContext)

  const [card, setCard] = useState({ open: false, index: 0, x: 0, y: 0 })

  const [settings, setSettings] = useState([
    { name: 'Score Threshold', value: 0.05, min: 0, max: 0.5, step: 0.01, type: 'score' },
    { name: 'Overlap Threshold', value: 0.7, min: 0, max: 1, step: 0.1, type: 'overlap' }
  ])
  const itemRef = useRef(null)

  const handleClick = (index) => {
    const item = itemRef.current.getBoundingClientRect()
    setCard({ ...card, open: !card.open, index: index - 1, x: item.left, y: item.top + 30 * index })
  }

  const changeValueSetting = (event, value) => {
    const actualSettings = [...settings]
    const item = { ...actualSettings[card.index] }
    item.value = value
    actualSettings[card.index] = item
    dispatch({
      type: 'setAICounters',
      payload: { score: actualSettings[0].value, overlap: actualSettings[1].value }
    })
    setSettings([...actualSettings])
  }

  const handleClickAway = () => {
    setCard({ open: false, index: 0, y: 0, x: 0 })
  }
  return (
    <>
      {card.open && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Card style={{ top: card.y, position: 'fixed', width: 150, left: '54px' }}>
            <CardContent>
              <Typography id="label">
                {settings[card.index].name}({settings[card.index].value})
              </Typography>
              <Slider
                id={settings[card.index].name}
                value={settings[card.index].value}
                aria-labelledby="label"
                onChange={changeValueSetting}
                min={settings[card.index].min}
                step={settings[card.index].step}
                max={settings[card.index].max}
                disabled={boundingBoxState.loadingAI}
              />
            </CardContent>
          </Card>
        </ClickAwayListener>
      )}

      <Tooltip title={'[Space] Draw'} aria-label={'[Space] Draw'}>
        <Button
          sx={
            mode === 'draw'
              ? { minWidth: 48, width: 48, background: 'rgba(120,120,120,0.3)' }
              : { minWidth: 48, width: 48 }
          }
          size="small"
          onClick={() => {
            dispatch({
              type: 'setLabelerMode',
              payload: { category: 'bounding_box', mode: 'draw' }
            })
          }}
        >
          <Add fontSize="small" />
        </Button>
      </Tooltip>
      <Tooltip ref={itemRef} title={'[Space] Edit'} aria-label={'[Space] Edit'}>
        <Button
          sx={
            mode === 'edit'
              ? { minWidth: 48, width: 48, background: 'rgba(120,120,120,0.3)' }
              : { minWidth: 48, width: 48 }
          }
          size="small"
          onClick={() => {
            dispatch({
              type: 'setLabelerMode',
              payload: { category: 'bounding_box', mode: 'edit' }
            })
          }}
        >
          <PanTool fontSize="small" />
        </Button>
      </Tooltip>

      <Tooltip title="Magic Bounding Box" aria-label="Magic Bounding Box">
        <IconButton
          size="small"
          onClick={() => dispatch({ type: 'setIACategory', payload: 'autoboundingboxes' })}
        >
          <CropSquareIcon fontSize="small" />
        </IconButton>
      </Tooltip>

      {selectedCategory.type === 'bounding_box' && selectedCategory.AI === 'autoboundingboxes' ? (
        <>
          <Tooltip title="Score Threshold" aria-label="Score Threshold">
            <IconButton size="small" onClick={() => handleClick(1)}>
              <ScoreIcon fontSize="small" />
            </IconButton>
          </Tooltip>

          <Tooltip title="Overlap Threshold" aria-label="Overlap Threshold">
            <IconButton size="small" onClick={() => handleClick(2)}>
              <JoinFullIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <></>
      )}
    </>
  )
}
