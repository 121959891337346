import React from 'react'
import { Collapse, Divider, IconButton, List } from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { styled } from '@mui/material/styles'
import LateralFilterItems from './components/lateralFilterItems/LateralFilterItems'
import LabelFillHandler from './components/labelFillHandler/LabelFillHandler'

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: 48,
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1)
}))

const Container = styled('div')({
  overflowX: 'hidden',
  height: '100%',
  paddingTop: 75,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  zIndex: 1
})

const RotatingArrowIcon = styled(KeyboardArrowLeftIcon)(({ collapse }) => ({
  transform: collapse ? 'rotate(180deg)' : 'rotate(0deg)'
}))

const CollapsePanel = styled(Collapse)(({ collapse, theme }) => ({
  overflow: 'hidden',
  width: '100%',
  maxHeight: 'calc(100% - 100px)',
  '&:hover': {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '2px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '5px',
      '&:hover': {
        backgroundColor: theme.palette.secondary.main
      }
    }
  },
  display: collapse ? 'block' : 'none'
}))

function LateralFilter({ collapse, setCollapse }) {
  return (
    <Container role="presentation">
      {/* Collapse Button */}
      <DrawerHeader>
        <IconButton onClick={() => setCollapse(!collapse)}>
          <RotatingArrowIcon collapse={collapse} />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <CollapsePanel collapse={collapse} in={collapse}>
        <LateralFilterItems />
      </CollapsePanel>
      <List
        sx={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          height: 'auto',
          backgroundColor: 'background.paper'
        }}
      >
        <LabelFillHandler text="Labels Fill" collapse={collapse} />
      </List>
    </Container>
  )
}

export default React.memo(LateralFilter)
