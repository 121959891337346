import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Grid, Button, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { formatNumber } from '../../../../../../utils/util'
import {
  GroupButton,
  ProgressTitle,
  TagCounter,
  TitleContent,
  Card as CardPaper
} from '../StyledComponents'
import {
  getTotalImages,
  getCompletedPercentage,
  getImagesDoneReviewedCount,
  getImagesSkippedReviewedCount
} from './utils/progressCardUtils'
import ImagesMetricsChart from './components/ImagesMetricsChart'
import FilterButtons from './components/FilterButtons'

const MetricsCard = ({ project, isPaid, imgFilter, setImgFilter }) => {
  const [totalImg, setTotalImg] = useState(0)
  const [barFilter, setBarFilter] = useState('week')
  const theme = useTheme()

  useEffect(() => {
    setTotalImg(getTotalImages(project.datasets))
  }, [project.datasets])

  const missingDone = totalImg - project.imagesSavedCount - project.imagesSkippedCount

  return (
    <CardPaper>
      <TitleContent>
        <ProgressTitle variant="h6">
          Images (
          <TagCounter>
            {imgFilter === 'reviewed'
              ? formatNumber(project.imagesSavedCount + project.imagesSkippedCount)
              : formatNumber(totalImg)}
          </TagCounter>
          )
        </ProgressTitle>
        {isPaid && <FilterButtons imgFilter={imgFilter} setImgFilter={setImgFilter} />}
      </TitleContent>
      <Grid container>
        <Grid item xs={3}>
          <Typography variant="h6" color="primary">
            {imgFilter === 'reviewed'
              ? formatNumber(getImagesDoneReviewedCount(imgFilter, project))
              : formatNumber(project.imagesSavedCount)}
          </Typography>
          <Typography>{imgFilter === 'reviewed' ? 'Reviewed' : 'Done'}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h6" color="primary">
            {imgFilter === 'reviewed'
              ? formatNumber(getImagesSkippedReviewedCount(imgFilter, project))
              : formatNumber(project.imagesSkippedCount)}
          </Typography>
          <Typography>{'Skipped'}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h6" color="primary">
            {imgFilter === 'reviewed'
              ? formatNumber(
                  project.imagesSavedCount +
                    project.imagesSkippedCount -
                    project.imagesReviewedCount
                )
              : formatNumber(missingDone > 0 ? missingDone : 0)}
          </Typography>
          <Typography>Missing</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h6" color="primary">
            {imgFilter === 'saved'
              ? totalImg > 0
                ? getCompletedPercentage(imgFilter, project, totalImg)
                : 0
              : project.imagesSavedCount > 0
              ? getCompletedPercentage(imgFilter, project, totalImg)
              : 0}
            %
          </Typography>
          <Typography>Completed</Typography>
        </Grid>
      </Grid>
      <ImagesMetricsChart
        imgFilter={imgFilter}
        barFilter={barFilter}
        project={project}
        theme={theme}
        totalImg={totalImg}
      />
      <div style={{ paddingTop: 20 }}>
        <GroupButton size="small" color="primary">
          <Button
            variant={barFilter === 'week' ? 'contained' : 'outlined'}
            onClick={() => setBarFilter('week')}
          >
            Week
          </Button>
          <Button
            variant={barFilter === 'month' ? 'contained' : 'outlined'}
            onClick={() => setBarFilter('month')}
          >
            Month
          </Button>
          <Button
            variant={barFilter === 'all' ? 'contained' : 'outlined'}
            onClick={() => setBarFilter('all')}
          >
            All
          </Button>
        </GroupButton>
      </div>
    </CardPaper>
  )
}

const mapStateToProps = (state) => ({
  project: state.project
})

export default connect(mapStateToProps, null)(MetricsCard)
