import { useEffect, useState, useContext } from 'react'
import { DispatchContext } from '../../../containers/labeler/context/DispatchContext'
import { LabelerContext } from '../../../containers/labeler/context/LabelerContext'
import { listImages, listReviewImages, getImage, getReviewImage } from '../../../services/graphql'
import { getImage as getSingleImg } from '../../../io/project/services'
import useGetNextImage from './useGetNextImage'
import useGetPrevImages from './useGetPrevImages'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from '../../../components/snackbar/context/SnackbarContext'
import { listViewerTagsIds } from '../../../io/graphql/tag/tags'
export default function useGetImages(props, projectReady, role) {
  const navigate = useNavigate()
  const [nextToken, setNextToken] = useState(null)
  const {
    state: {
      labelerState: {
        image: { navigation },
        // eslint-disable-next-line no-unused-vars
        role: roleContext
      }
    }
  } = useContext(LabelerContext)
  const { dispatch } = useContext(DispatchContext)

  const { showSnackbar } = useSnackbar()

  const formatImage = (image) => ({
    id: image.id,
    image: image.image,
    userId: image.labeler ? image.labeler.id : null,
    reviewer: image.tagReviewerId,
    name: image.key.split('/')[image.key.split('/').length - 1],
    date: image.createdAt,
    key: image.key,
    width: image.width,
    height: image.height,
    done: image.done,
    accepted: image.accepted,
    downloaded: false,
    skiped: image.skiped
  })

  useGetNextImage(props)
  useGetPrevImages(props, nextToken)

  useEffect(() => {
    resetImages()
  }, [role])

  const resetImages = async () => {
    if (navigation === 'init') {
      dispatch({ type: 'setImage', payload: -1 })
      dispatch({ type: 'setImages', payload: { images: [], currentImage: [] } })

      if (props.imageId) {
        if (props.imageId.toString().includes(props.projectId.toString())) {
          const singleImage = await getSingleImage(props.imageId)
          dispatch({
            type: 'setImages',
            payload: {
              images: [singleImage.data.getTag],
              currentImage: formatImage(singleImage.data.getTag)
            }
          })
        } else {
          showSnackbar({
            message: 'The project needs to be reloaded',
            type: 'info'
          })
          setTimeout(() => {
            navigate('/project?id=' + props.projectId)
          }, 5000)
        }
        return
      }
      getImages(props)
    }
  }

  const labelerGetImage = async (projectId, labelerId) => {
    let errorMessage = 'There are no more images.'
    try {
      const labResponse = await getImage(projectId, labelerId)
      if (Object.keys(labResponse.data).length === 0) {
        return { data: null, error: errorMessage }
      }
      const currentImage = formatImage(await getSingleImage(labResponse.data.id)).data.getTag
      dispatch({
        type: 'addNewImage',
        payload: { images: [labResponse.data], currentImage }
      })
      return { ...labResponse, error: labResponse.data === null ? errorMessage : '' }
    } catch (error) {
      errorMessage = 'Error getting new images.'
      return { data: null, error: errorMessage }
    }
  }

  const reviewerGetImage = async (projectId, labelerId) => {
    let errorMessage = 'There are no more images.'
    try {
      const revResponse = await getReviewImage(projectId, labelerId)
      if (Object.keys(revResponse.data).length === 0) {
        return { data: null, error: errorMessage }
      }
      dispatch({
        type: 'addNewImage',
        payload: { images: [revResponse.data], currentImage: revResponse.data }
      })

      return { ...revResponse, error: revResponse.data === null ? errorMessage : '' }
    } catch (error) {
      errorMessage = 'Error getting new images.'
      return { data: null, error: errorMessage }
    }
  }

  const getSingleImage = async (imageId) => {
    return await getSingleImg(imageId)
  }

  const getNewImage = async (projectId, labelerId) => {
    if (role !== 'none') {
      switch (role) {
        case 'labeler':
          return await labelerGetImage(projectId, labelerId)
        case 'reviewer':
          return await reviewerGetImage(projectId, labelerId)
        default:
          return {
            data: null,
            error: { data: null, message: `There are no images for ${role.labeler}.` }
          }
      }
    }
    return null
  }
  const getImagesByRole = async (projectId, labelerId, order, sortId, nextToken) => {
    switch (role) {
      case 'labeler':
        return await listImages(projectId, labelerId, order, nextToken)
      case 'reviewer':
        return await listReviewImages(projectId, labelerId, order, sortId, nextToken)
      case 'viewer':
        return await listViewerTagsIds(projectId, 20, order, nextToken)

      default:
        break
    }
    return { data: { items: [], next: null }, errors: null }
  }

  const getImages = async (props) => {
    try {
      let response
      const { data } = await getImagesByRole(
        props.projectId,
        props.labelerId,
        props.order,
        props.sortId,
        nextToken,
        props.state
      )
      response = { ...data }
      if (response && response.items && response.items.length === 0 && role !== 'none') {
        const nextImage = await getNewImage(props.projectId, props.labelerId)
        if (nextImage.error !== '') {
          showSnackbar({
            message: nextImage.error,
            type: 'info'
          })
          return
        }
        response = { items: [nextImage.data] }
      }

      const currentImage =
        response.items.length > 0
          ? formatImage((await getSingleImage(response.items[0].id)).data.getTag)
          : null
      dispatch({
        type: 'setImages',
        payload: { images: response.items, currentImage }
      })
      setNextToken(data.next)
      dispatch({ type: 'setLoadingImage', payload: false })
    } catch (error) {
      console.log(error)
    }
  }
  return null
}
