import { useReducer } from 'react'
import produce from 'immer'

const circleState = {
  tags: [],
  selectedTag: -1,
  redraw: 0
}

export const reducer = produce((state, action) => {
  switch (action.type) {
    case 'setLoadingTags':
      if (action.payload) {
        state.tags = []
        state.selectedTag = -1
        state.redraw = 0
      }
      break
    case 'addCircle':
      state.tags.push(action.payload)
      break
    case 'setCirclePos':
      state.tags[action.payload.index] = {
        ...state.tags[action.payload.index],
        pos: { x: action.payload.x, y: action.payload.y, r: action.payload.r }
      }
      state.redraw = state.redraw + 1
      break
  }
}, circleState)

export function CircleReducer() {
  const [state, dispatch] = useReducer(reducer, circleState)
  return { circleState: state, circleDispatch: dispatch }
}
