const getTableConfig = (imgFilter) => {
  let keys, columns

  if (imgFilter === 'reviewed') {
    keys = ['key', 'doc_count']
    columns = ['Labeler', 'Reviewed']
  } else {
    keys = ['key', 'done_count', 'skipped_count', 'doc_count']
    columns = ['Labeler', 'Done', 'Skipped', 'Img Count']
  }

  return { keys, columns }
}

export default getTableConfig
