import React, { useContext } from 'react'
import {
  Checkbox,
  Icon,
  IconButton,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Typography
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import styled from '@emotion/styled'
import { FiltersContext } from '../../../../../../../context/FiltersContext'

const CategoryClassificationContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))

const listItemStyle = {
  paddingLeft: 1,
  margin: 0,
  width: '100%'
}

const CategoryClassificationItem = ({
  classification,
  handleCategory,
  existCategory,
  handleChange,
  hiddenCategories
}) => {
  const { state } = useContext(FiltersContext)
  return (
    <>
      {/* Eye Button and Classification Name */}
      <ListItem sx={listItemStyle} key={classification.name}>
        <CategoryClassificationContainer>
          {classification.parent !== 'Segmentation' && (
            <IconButton onClick={() => handleCategory(classification)}>
              <Icon>
                {existCategory(hiddenCategories, classification) ? (
                  <VisibilityOff />
                ) : (
                  <Visibility />
                )}
              </Icon>
            </IconButton>
          )}
          <Typography>{classification.name}</Typography>
        </CategoryClassificationContainer>
      </ListItem>
      {/* Classification Option Selector */}
      <ListItem key={classification.name + '_select'} sx={listItemStyle}>
        <Select
          value={state.labelClassifications}
          onChange={handleChange}
          sx={{ width: '100%' }}
          size="small"
          name="labelClassifications"
          multiple
          renderValue={(selected) => {
            const selectedOptions = selected.filter((option) =>
              classification.options.includes(option)
            )
            return selectedOptions.join(', ')
          }}
        >
          {classification.options.map((option) => {
            return (
              <MenuItem key={option} value={option}>
                <Checkbox checked={state.labelClassifications.indexOf(option) > -1} />
                <ListItemText primary={option} />
              </MenuItem>
            )
          })}
        </Select>
      </ListItem>
    </>
  )
}

export default CategoryClassificationItem
