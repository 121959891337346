import React from 'react'
import BoundingBoxMenu from './types/boundingBox/Menu'
import PolygonMenu from './types/polygon/Menu'
import LandmarkMenu from './types/landmark/Menu'
import Menu from '@mui/material/Menu'

const MainMenu = (handleClose, category) => ({
  bounding_box: <BoundingBoxMenu handleClose={handleClose} category={category} />,
  polygon: <PolygonMenu handleClose={handleClose} category={category} />,
  point: <LandmarkMenu handleClose={handleClose} category={category} />
})

export default function MainCanvasMenu({ isOpen, pos, category, handleClose }) {
  const preventMenu = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <Menu
      onContextMenu={preventMenu}
      disableAutoFocusItem
      open={isOpen}
      anchorReference="anchorPosition"
      anchorPosition={{ top: pos ? pos.y : 0, left: pos ? pos.x : 0 }}
      onClose={handleClose}
    >
      {MainMenu(handleClose, category)[category?.type]}
    </Menu>
  )
}
