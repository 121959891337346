export function getPoly(posx, posy, tags = []) {
  for (let index = tags.length - 1; index >= 0; index--) {
    if (tags[index].show) {
      if (isInsidePoly(tags[index].pos, { x: posx, y: posy })) {
        return { index, poly: tags[index], type: 'polygon' }
      }
    }
  }

  return null
}

function isInsidePoly(points, test) {
  let i
  let j
  let c = false
  for (i = 0, j = points.length - 1; i < points.length; j = i++) {
    if (
      points[i].y > test.y !== points[j].y > test.y &&
      test.x <
        ((points[j].x - points[i].x) * (test.y - points[i].y)) / (points[j].y - points[i].y) +
          points[i].x
    ) {
      c = !c
    }
  }
  return c
}
