import { useEffect } from 'react'
import { useTimerHook } from '../../../utils/hooks/useTimer'
import { useEffectWR } from '../components/types/hooks/useEffectWR'
export function TimerController(timerState, role, machineState) {
  const labelerTimer = useTimerHook()
  const reviewerTimer = useTimerHook()

  useEffect(() => {
    if (machineState.value === 'readyForLabeling') {
      labelerTimer.controls.setTime(timerState.labelerInitialTime)
      reviewerTimer.controls.setTime(timerState.reviwerInitialTime)
      reviewerTimer.controls.stop()
      labelerTimer.controls.stop()

      if (role === 'labeler') {
        labelerTimer.controls.start()
        reviewerTimer.controls.stop()
      }
      if (role === 'reviewer') {
        reviewerTimer.controls.start()
        labelerTimer.controls.stop()
      }
    }
    if (machineState.value === 'clearImage') {
      reviewerTimer.controls.stop()
      labelerTimer.controls.stop()
    }
  }, [machineState.value])

  useEffectWR(
    [timerState.actionLabelerTime, timerState.actionReviewerTime],
    [
      () => {
        if (timerState.actionLabelerTime === 'run') {
          labelerTimer.controls.stop()
          labelerTimer.controls.start()
        }
        if (timerState.actionLabelerTime === 'stop') {
          labelerTimer.controls.stop()
        }

        if (timerState.actionReviewerTime === 'run') {
          reviewerTimer.controls.stop()
          reviewerTimer.controls.start()
        }
        if (timerState.actionReviewerTime === 'stop') {
          reviewerTimer.controls.stop()
        }
      }
    ]
  )

  return {
    labelerTime: labelerTimer.controls.getTime(),
    reviewerTime: reviewerTimer.controls.getTime(),
    labeler: labelerTimer.timerState,
    reviewer: reviewerTimer.timerState,
    none: { h: 0, m: 0, s: 0 }
  }
}
