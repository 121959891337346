import { put, takeLatest, all } from 'redux-saga/effects'

import { user } from './actions'
import {
  listMyProjets,
  listSharedProjects,
  listTeams,
  listMemberTeams,
  listDatasets,
  getMember
} from './services'
import { getUser } from '../../utils/util'

function* getMyProjects({ payload }) {
  const limit = 10

  try {
    let projects = []
    let nextToken = payload.nextToken || null
    let count = 0

    do {
      const response = yield listMyProjets(limit, nextToken, getUser().id, payload.filters)
      nextToken = response.data.searchProjects.nextToken

      if (count + response.data.searchProjects.items.length <= limit) {
        projects = [...projects, ...response.data.searchProjects.items]
        count += response.data.searchProjects.items.length
      } else {
        const remaining = limit - count
        projects = [...projects, ...response.data.searchProjects.items.slice(0, remaining)]
        count = limit
      }
    } while (count < limit && nextToken)

    yield put(
      user.setMyProjects({
        replace: payload.replace || false,
        items: projects,
        nextToken
      })
    )
  } catch (error) {
    console.error(error)
    const err = new TypeError('ERROR_GET_PROJECTS')
    yield put(user.setMyProjects(err))
  }
}

function* getSharedProjects({ payload }) {
  const limit = 10

  try {
    const projects = []
    let nextToken = payload.nextToken || null
    const userId = getUser().id

    do {
      const response = yield listSharedProjects(
        limit,
        nextToken,
        userId,
        getUser().owner,
        payload.filters
      )

      const pjs = response.data.searchProjects.items

      for (let i = 0; i < pjs.length; i++) {
        const r = yield getMember(userId, pjs[i].team.id)
        if (r.data.getMember) {
          const role = r.data.getMember.role
          pjs[i].role = role

          projects.push(pjs[i])
        }
      }

      nextToken = response.data.searchProjects.nextToken
    } while (projects.length < limit && !!nextToken)

    if (projects.length >= 0) {
      yield put(
        user.setSharedProjects({
          items: projects,
          replace: payload.replace || false,
          nextToken
        })
      )
    }
  } catch (error) {
    console.error(error)
    const err = new TypeError('ERROR_GET_PROJECTS')
    yield put(user.setSharedProjects(err))
  }
}

function* getMyTeams({ payload }) {
  const limit = 10

  try {
    let teams = []
    let nextToken = payload.nextToken || null

    do {
      const response = yield listTeams(limit, nextToken, getUser().id)

      nextToken = response.data.searchTeams.nextToken
      teams = [...teams, ...response.data.searchTeams.items]
    } while (teams.length < limit && !!nextToken)

    if (teams.length >= 0) {
      yield put(
        user.setMyTeams({
          items: teams,
          nextToken
        })
      )
    }
  } catch (error) {
    console.error(error)
    const err = new TypeError('ERROR_GET_TEAMS')
    yield put(user.setTeams(err))
  }
}

function* getMemberTeams({ payload }) {
  const limit = 10

  try {
    let teams = []
    let nextToken = payload.nextToken || null

    do {
      const response = yield listMemberTeams(limit, nextToken, getUser().id)

      nextToken = response.data.searchTeams.nextToken
      teams = [...teams, ...response.data.searchTeams.items]
    } while (teams.length < limit && !!nextToken)

    if (teams.length >= 0) {
      yield put(
        user.setMemberTeams({
          items: teams,
          nextToken
        })
      )
    }
  } catch (error) {
    console.error(error)
    const err = new TypeError('ERROR_GET_TEAMS')
    yield put(user.setTeams(err))
  }
}

function* getDatasets({ payload }) {
  const limit = 20

  try {
    let datasets = []
    let nextToken = payload.nextToken || null

    do {
      const response = yield listDatasets(limit, nextToken, getUser().id)
      nextToken = response.data.searchDatasets.nextToken
      datasets = [
        ...datasets,
        ...response.data.searchDatasets.items.filter((obj) => obj.status === 'created')
      ]
    } while (datasets.length < limit && !!nextToken)

    if (datasets.length || datasets.length === 0) {
      yield put(
        user.setDatasets({
          items: datasets,
          nextToken
        })
      )
    }
  } catch (error) {
    const err = new TypeError('ERROR_GET_DATASETS')
    yield put(user.setDatasets(err))
  }
}

function* ActionWatcher() {
  yield takeLatest(user.getMyTeams, getMyTeams)
  yield takeLatest(user.getMemberTeams, getMemberTeams)
  yield takeLatest(user.getMyProjects, getMyProjects)
  yield takeLatest(user.getSharedProjects, getSharedProjects)
  yield takeLatest(user.getDatasets, getDatasets)
}

export default function* rootSaga() {
  yield all([ActionWatcher()])
}
