export const drawRectangle = (
  ctxCanvas,
  x,
  y,
  width,
  height,
  imgWidth,
  imgHeight,
  classes,
  hiddenCategories,
  image,
  compareColor,
  borderColor,
  borderSize
) => {
  const correctedWidth = Math.max(width, 0)
  const correctedHeight = Math.max(height, 0)
  if (compareColor) {
    ctxCanvas.fillStyle = `${compareColor}5D`
  }

  if (borderColor) {
    ctxCanvas.strokeStyle = borderColor
  }

  if (borderSize) {
    ctxCanvas.lineWidth = 5
  } else {
    ctxCanvas.lineWidth = 2
  }

  ctxCanvas.rect(
    (x * imgWidth) / image.width,
    (y * imgHeight) / image.height,
    (correctedWidth * imgWidth) / image.width,
    (correctedHeight * imgHeight) / image.height
  )

  if (classes) {
    classes.forEach((cls) => {
      const hiddenIndex = hiddenCategories
        ? hiddenCategories.findIndex((cat) => cat.name === cls.name)
        : -1
      if (hiddenIndex === -1) {
        ctxCanvas.fillText(
          cls.selected,
          (x * imgWidth) / image.width,
          (y * imgHeight) / image.height - 5
        )
      }
    })
  }
}

export const drawPolygon = (ctxCanvas, pos, imgWidth, imgHeight, image) => {
  if (pos === null || pos.length === 0) {
    return
  }

  ctxCanvas.moveTo((pos[0].x * imgWidth) / image.width, (pos[0].y * imgHeight) / image.height)

  pos.slice(1).forEach(({ x, y }) => {
    ctxCanvas.lineTo((x * imgWidth) / image.width, (y * imgHeight) / image.height)
  })
}

export const drawPoint = (ctxCanvas, x, y, imgWidth, imgHeight, image) => {
  ctxCanvas.arc((x * imgWidth) / image.width, (y * imgHeight) / image.height, 10, 0, 2 * Math.PI)
}

export const drawAnnotationShape = (
  scaleX,
  scaleY,
  annotation,
  ctxCanvas,
  fill,
  image,
  img,
  hiddenCategories,
  compareColor,
  borderColor,
  borderSize
) => {
  if (typeof annotation === 'object' && annotation !== null) {
    const { type, color, pos, classes } = annotation

    if (Array.isArray(pos)) {
      const [x, y, width, height] = pos
      const imgWidth = img.width
      const imgHeight = img.height

      ctxCanvas.setTransform(scaleX, 0, 0, scaleY, 0, 0)
      ctxCanvas.strokeStyle = color
      ctxCanvas.fillStyle = fill ? `${color}4D` : 'rgba(0, 0, 0, 0)'
      ctxCanvas.beginPath()

      if (type === 'rect') {
        drawRectangle(
          ctxCanvas,
          x,
          y,
          width,
          height,
          imgWidth,
          imgHeight,
          classes,
          hiddenCategories,
          image,
          compareColor,
          borderColor,
          borderSize
        )
      } else if (type === 'poly') {
        drawPolygon(ctxCanvas, pos, imgWidth, imgHeight, image)
      } else if (type === 'point') {
        drawPoint(ctxCanvas, pos.x, pos.y, imgWidth, imgHeight, image)
      }

      ctxCanvas.globalAlpha = 1
      ctxCanvas.stroke()
      ctxCanvas.fill()
    }
  }
}

export const drawAnnotation = (
  annotation,
  type,
  filled,
  scaleX,
  scaleY,
  image,
  img,
  ctxCanvas,
  hiddenCategories,
  compareColor,
  borderColor,
  borderSize
) => {
  const index = hiddenCategories.findIndex((cat) => cat.name === annotation.name)
  if (index === -1) {
    drawAnnotationShape(
      scaleX,
      scaleY,
      { ...annotation, type },
      ctxCanvas,
      filled,
      image,
      img,
      hiddenCategories,
      compareColor,
      borderColor,
      borderSize
    )
  }
}
