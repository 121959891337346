import React from 'react'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}))

const ProjectInfo = ({ projectName, totalImagesInRequest, totalImagesDataset }) => {
  return (
    <Container>
      <Container>
        <Typography
          noWrap
          sx={{ fontWeight: 'bold', maxWidth: '100px' }}
          color="primary"
          variant="p"
        >
          {projectName}
        </Typography>
      </Container>
      <Typography sx={{ paddingLeft: '4px' }} variant="p">
        {`${totalImagesInRequest} / ${totalImagesDataset}`}{' '}
      </Typography>
    </Container>
  )
}

export default ProjectInfo
