import { styled } from '@mui/material/styles'
import { Paper, ButtonGroup, Typography } from '@mui/material'

export const GroupButton = styled(ButtonGroup)(() => ({
  display: 'block'
}))

export const Card = styled(Paper)(({ theme }) => ({
  margin: theme.spacing(1),
  padding: theme.spacing(1)
}))

export const ProgressTitle = styled(Typography)(() => ({
  textAlign: 'left'
}))

export const TitleContent = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '8px 0',
  alignItems: 'center'
}))

export const TagCounter = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 15
}))
