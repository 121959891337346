import Axios from 'axios'
import { getToken } from '../aws/auth'

// SAM: get Points from Mask
export const getPointsFromMask = async (data) => {
  const token = await getToken()

  return Axios.post(`${process.env.REACT_APP_API}ml/points`, data, {
    headers: {
      Authorization: token,
      'Content-Type': 'multipart/form-data'
    }
  })
}

// SAM: get Mask from a Box
export const getMaskFromBox = async (path, x, y, w, h, id, labelerState) => {
  const token = await getToken()

  return Axios.post(
    `${process.env.REACT_APP_CLEAN_API}ai/masks`,
    {
      path_obj: path,
      bbox_x1: Math.round(x),
      bbox_y1: Math.round(y),
      bbox_x2: Math.round(x + w),
      bbox_y2: Math.round(y + h),
      background_points: [],
      foreground_points: [],
      output_mask:
        'pruebas/outputs/' + id + labelerState.image.images[labelerState.image.index].name
    },
    {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json'
      }
    }
  )
}

// SAM: send extra point to enhance Mask
export const getMaskCorrections = async (
  path,
  x,
  y,
  w,
  h,
  id,
  labelerState,
  backgroundPoints,
  foregroundPoints
) => {
  const token = await getToken()

  return Axios.put(
    `${process.env.REACT_APP_CLEAN_API}ai/masks`,
    {
      path_obj: path,
      bbox_x1: Math.round(x),
      bbox_y1: Math.round(y),
      bbox_x2: Math.round(x + w),
      bbox_y2: Math.round(y + h),
      background_points: backgroundPoints,
      foreground_points: foregroundPoints,
      output_mask:
        'pruebas/outputs/' + id + labelerState.image.images[labelerState.image.index].name
    },
    {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json'
      }
    }
  )
}

export const getAutoPolygon = async (url, points, ratio) => {
  const token = await getToken()

  const ps = []

  for (let i = 0; i < points.length; i++) {
    ps.push([points[i].x / ratio, points[i].y / ratio])
  }

  return Axios.post(
    `${process.env.REACT_APP_API}autopolygon`,
    {
      image: url, // "https://1.bp.blogspot.com/-79DdxzZkDog/T76QV6v5IuI/AAAAAAAAAEY/6DzpGZzsmfA/s320/homerocatolico_456_336.jpg",
      points: ps, // [[120, 2], [0, 80], [70, 235], [270, 80], [95, 50], [200, 235], [70, 170]],
      polygonPoints: 50,
      model: 'dextr_gpu'
    },
    {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json'
      }
    }
  )
}

export const getComparisonIoU = async (tagId1, tagId2) => {
  const token = await getToken()

  return Axios.post(
    `${process.env.REACT_APP_API}ml/iou`,
    {
      ref_tag_id: tagId1,
      eval_tag_id: tagId2
    },
    {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json'
      }
    }
  )
}

export const getDinoTags = async (data) => {
  try {
    const token = await getToken()

    return await Axios.post(`${process.env.REACT_APP_API}ml/bounding-boxes`, data, {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json'
      }
    })
  } catch (error) {
    console.error('Error at getDinoTags', error.name, error.message)
    throw error
  }
}
