import React from 'react'
import { useTheme } from '@mui/material/styles'
import DefaultTable from '../../../../../../../components/generics/tables/DefaultTable'

const LabelsCounterTable = ({ labelFilter, catsPieData, totalTags }) => {
  const theme = useTheme()

  return (
    <DefaultTable
      clickeable={false}
      headColor={theme.palette.mode === 'dark' ? theme.palette.background.default : null}
      columns={['', 'Categories', 'Count', '%']}
      keys={[
        { name: 'color', type: 'colorDot' },
        'name',
        labelFilter === 'reviewed'
          ? 'countReviewed'
          : labelFilter === 'saved'
          ? 'countSaved'
          : 'count',
        {
          name:
            labelFilter === 'reviewed'
              ? 'countReviewed'
              : labelFilter === 'saved'
              ? 'countSaved'
              : 'count',
          type: 'percentage',
          total: totalTags
        }
      ]}
      rowsPerPageOptions={[4, 8, 12]}
      data={catsPieData}
    />
  )
}

export default LabelsCounterTable
