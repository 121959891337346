import { Tooltip } from '@mui/material'
import React from 'react'

const BarTooltip = (props) => {
  return (
    <Tooltip {...props} title={props.title} aria-label={props.title} placement="right">
      {props.children}
    </Tooltip>
  )
}

export default BarTooltip
