import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { sandEvent } from '../io/analytics'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardMedia from '@mui/material/CardMedia'
import ListItemText from '@mui/material/ListItemText'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DeleteIcon from '@mui/icons-material/Delete'
import InfoIcon from '@mui/icons-material/Info'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import ListItemIcon from '@mui/material/ListItemIcon'

function DatasetItem(props) {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Grid item xs={11} sm={4} md={3} lg={2} style={{ textAlign: 'justify' }}>
      <Card>
        <Link
          onClick={() => sandEvent('Open Dataset', props.dataset)}
          style={{ textDecoration: 'none' }}
          to={{
            pathname: '/dataset',
            search: `?id=${props.dataset.id}`,
            state: { dataset: props.dataset }
          }}
        >
          <CardMedia
            component="img"
            alt="Placeholder Logo"
            max-width="357"
            image={props.logoHex}
            title="Linked AI logo"
          />
        </Link>
        <div className="blue-line" />
        <ClickAwayListener onClickAway={handleClose}>
          <CardHeader
            style={{ paddingLeft: 10 }}
            avatar={<></>}
            action={
              <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} size="large">
                <MoreVertIcon />
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <Link
                    onClick={() => sandEvent('Open Dataset', props.dataset)}
                    style={{ textDecoration: 'none' }}
                    to={{
                      pathname: '/dataset',
                      search: `?id=${props.dataset.id}`,
                      state: { dataset: props.dataset }
                    }}
                  >
                    <MenuItem onClick={handleClose}>
                      <ListItemIcon>
                        <InfoIcon />
                      </ListItemIcon>
                      <ListItemText primary="Information" />
                    </MenuItem>
                  </Link>
                  <MenuItem
                    onClick={() => {
                      handleClose()
                      props.deleteDataset(props.index)
                    }}
                  >
                    <ListItemIcon>
                      <DeleteIcon />
                    </ListItemIcon>
                    <ListItemText primary="Delete" />
                  </MenuItem>
                </Menu>
              </IconButton>
            }
            title={props.title}
            subheader={props.subtitle}
            titleTypographyProps={{ noWrap: true }}
          />
        </ClickAwayListener>
      </Card>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  logoHex: state.ui.logoHex
})

export default connect(mapStateToProps, null)(DatasetItem)
