import React, { useContext } from 'react'
import {
  Checkbox,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Typography
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import styled from '@emotion/styled'
import { FiltersContext } from '../../../../../../../context/FiltersContext'

const ClassificationContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))

const ClassificationItem = ({ classification, onChange, hideCategory, hiddenCategories }) => {
  const { state } = useContext(FiltersContext)
  const existCategory = (categories, category) => {
    return categories?.some((item) => {
      return item.name === category.name
    })
  }

  return (
    <List sx={{ maxWidth: 180 }} key={classification.name}>
      <ListItem sx={{ p: 0 }}>
        <ClassificationContainer>
          <IconButton onClick={() => hideCategory(classification)}>
            <Icon>
              {existCategory(hiddenCategories, classification) ? <VisibilityOff /> : <Visibility />}
            </Icon>
          </IconButton>
          <Typography>{classification.name}</Typography>
        </ClassificationContainer>
      </ListItem>
      <ListItem sx={{ p: 0, m: 0 }}>
        <Select
          value={state.classifications[classification.name] || []}
          onChange={(event) => onChange(event.target.value, classification.name)}
          sx={{ width: '100%' }}
          name={classification.name}
          multiple
          size="small"
          renderValue={(selected) => selected.join(', ')}
        >
          <MenuItem key={'Empty'} value={'Empty'}>
            <Checkbox
              checked={
                state.classifications[classification.name]
                  ? state.classifications[classification.name]?.includes('Empty')
                  : false
              }
            />
            <ListItemText primary={'Empty'} />
          </MenuItem>
          {classification.options.map((item) => (
            <MenuItem key={item} value={item}>
              <Checkbox
                checked={
                  state.classifications[classification.name]
                    ? state.classifications[classification.name]?.includes(item)
                    : false
                }
              />
              <ListItemText primary={item} />
            </MenuItem>
          ))}
        </Select>
      </ListItem>
    </List>
  )
}

export default ClassificationItem
