import React from 'react'
import { connect } from 'react-redux'
import { styled } from '@mui/material/styles'

const Root = styled('div')(() => ({
  display: 'flex',
  flexGrow: 1,
  marginTop: '-5%',
  minHeight: '100vh',
  justifyContent: 'center',
  alignItems: 'center'
}))

function Loader(props) {
  return (
    <Root>
      <img
        className="App-logo"
        height="10%"
        width="10%"
        src={props.logoHex || 'assets/monogram-logo.png'}
        alt="logo"
      />
    </Root>
  )
}

const mapStateToProps = (state) => ({
  logoHex: state.ui.logoHex
})

export default connect(mapStateToProps, null)(Loader)
