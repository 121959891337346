import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as _ from 'lodash'
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  Avatar,
  ListItemText,
  Divider,
  Select,
  Radio,
  Grid,
  Button,
  FormLabel,
  Typography,
  RadioGroup,
  FormControl,
  LinearProgress,
  FormControlLabel,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material'
import SaveAlt from '@mui/icons-material/SaveAlt'
import LoaderButton from '../LoaderButton'
import { sandDataDownloadFromProject } from '../../io/analytics'
import { dateToTimeString } from '../../utils/util'
import { listRequest, getRequest as getRequestGraph, createRequest } from '../../services/graphql'
import { styled } from '@mui/material/styles'
import { getPath } from '../../services/ai'
import { downloadFormat, requestMaskGeneration } from '../../io/api/admin'
import { useSnackbar } from '../snackbar/context/SnackbarContext'

const LoaderBtn = styled(LoaderButton)(({ theme }) => ({
  margin: theme.spacing(0, 0, 2, 0),
  display: 'flex'
}))

const requestTypes = [
  { id: 'all', text: 'All images' },
  { id: 'labeled', text: 'Done Images' },
  { id: 'reviewed', text: 'Reviewed Images' }
]

const requestFormats = [
  { id: 'default', text: 'LinkedAi v1.0 (json)' },
  { id: 'yolo', text: 'Yolo v5.0 (zip)' },
  { id: 'coco', text: 'Coco v1.0 (zip)' },
  { id: 'voc', text: 'Pascal v2012 (zip)' }
]

function ProjectExport(props) {
  const [downloadType, setDownloadType] = useState('all')
  const [loading, setLoading] = useState(false)
  const [request, setRequest] = useState(null)
  const [requests, setRequests] = useState([])
  const [interval, setInter] = useState(null)
  const [availableMasks, setAvailableMasks] = useState(false)
  const [open, setOpen] = useState(false)
  const [format, setFormat] = useState('default')
  const { showSnackbar } = useSnackbar()

  const { project } = props

  useEffect(() => {
    if (project.id) getRequest(project.id)
  }, [project.id])

  useEffect(() => {
    let i = interval
    if (i) {
      clearInterval(i)
      setInter(null)
    }
    if (request && request.status === 'pending') {
      i = setInterval(() => {
        if (request) {
          getRequestGraph(request.id)
            .then((r) => {
              if (r.data && r.data.getRequestJson) {
                setRequests(mercheRequest(r.data.getRequestJson, requests))
                setRequest(r.data.getRequestJson)
              }
            })
            .catch((e) => console.log(e))
        } else {
          clearInterval(interval)
        }
      }, 15000)
      setInter(i)
    }

    return () => clearInterval(i)
  }, [request])

  useEffect(() => {
    setAvailableMasks(existShapes())
  }, [project.categories])

  const mercheRequest = (req, reqs) => {
    const newReq = _.reject(reqs, ['id', req.id])

    return [req, ...newReq]
  }

  const handleFormatChange = (event) => {
    setFormat(event.target.value)
  }

  const handleClose = (event) => {
    switch (event) {
      case 'Download':
        setOpen(false)
        generateSegmentationMasks()
        break
      case 'Continue':
        setOpen(false)
        sendRequest()
        break
      case 'Cancel':
        setOpen(false)
        break
      default:
        break
    }
  }

  const handleRadioChange = (event) => {
    setDownloadType(event.target.value)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (!availableMasks) {
      sendRequest()
    } else {
      setOpen(true)
    }
  }

  const generateSegmentationMasks = async () => {
    setAvailableMasks(false)

    showSnackbar({
      message: 'Done',
      type: 'info'
    })
    await requestMaskGeneration(project.id, null)
      .then((response) => {
        if (response.status === 200) {
          setAvailableMasks(false)
          return
        }
        showSnackbar({
          message: 'Generated',
          type: 'info'
        })
        setAvailableMasks(true)
      })
      .catch((error) => {
        setAvailableMasks(true)
        showSnackbar({
          message: 'Error',
          type: 'error'
        })
        console.log('Error', error)
      })
  }

  const getRequest = (id) => {
    setLoading(true)
    listRequest(id)
      .then((r) => {
        const requests = r.data.items
        if (requests.length > 0) {
          setRequests(requests)
          setRequest(requests[0])
        }
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        console.log(e)
      })
  }

  const sendRequest = () => {
    setLoading(true)
    sandDataDownloadFromProject({
      id: project.id,
      name: project.name,
      type: downloadType,
      format
    })

    let totalImg = 0
    project.datasets.forEach((d) => (totalImg += d.dataset ? d.dataset.count : 0))

    const tagsCount =
      downloadType === 'labeled'
        ? project.tagsSaveCount
        : downloadType === 'reviewed'
        ? project.tagsReviewCount
        : project.tagsCount
    const imagesCount =
      downloadType === 'labeled'
        ? project.imagesSavedCount
        : downloadType === 'reviewed'
        ? project.imagesReviewedCount
        : totalImg

    createRequest({
      type: downloadType,
      requestProjectId: project.id,
      tagsCount,
      imagesCount,
      format,
      status: 'pending'
    })
      .then((r) => {
        showSnackbar({
          message: 'Request created.',
          type: 'info'
        })
        setRequests(mercheRequest(r.data.createRequestJson, requests))
        setRequest(r.data.createRequestJson)
        setLoading(false)
      })
      .catch((e) => {
        showSnackbar({
          message: 'Request creation failed, please try again.',
          type: 'error'
        })
        setLoading(false)
        console.log(e)
      })
  }

  const getTypeText = (type) => {
    for (let i = 0; i < requestTypes.length; i++) {
      if (requestTypes[i].id === type) return requestTypes[i].text
    }
    return type
  }
  const getFormatText = (format) => {
    for (let i = 0; i < requestFormats.length; i++) {
      if (requestFormats[i].id === format) return requestFormats[i].text
    }
    return format || requestFormats[0].text
  }

  const downloadDatasetLabels = async (formatRequest) => {
    if (!request || !request.key) return
    sandDataDownloadFromProject({
      id: project.id,
      name: project.name,
      type: downloadType,
      formatRequest
    })

    setLoading(true)
    try {
      const path = await getPath(request.key, 'formatted-labels-linkedai')
      await downloadFormat(path, formatRequest, project, setLoading, showSnackbar)
      showSnackbar('Downloaded', 'success')
    } catch (error) {
      console.log(error)
      setLoading(false)
      showSnackbar('Error', 'error')
    }
  }

  const existShapes = () => {
    const categories = project.categories

    function existPolygon(shape) {
      return shape.type === 'polygon'
    }

    function existSegmentation(shape) {
      return shape.type === 'segmentation'
    }

    if (categories.find(existPolygon) || categories.find(existSegmentation)) {
      return true
    } else return false
  }

  return (
    <Grid container spacing={3} style={{ padding: '1rem' }}>
      <Grid item xs={12}>
        <Typography variant="h5" style={{ textAlign: 'left' }}>
          Export Labels
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit} style={{ textAlign: 'left' }}>
          <FormControl component="fieldset">
            {loading && <LinearProgress color="secondary" />}
            <FormLabel component="legend">Select the labels you want export:</FormLabel>
            <Select
              labelId="format-select"
              id="format-select"
              value={format}
              label="Format"
              onChange={handleFormatChange}
              disabled={loading || project.loading}
            >
              {requestFormats.map((format) => (
                <MenuItem value={format.id} key={format.id}>
                  {format.text}
                </MenuItem>
              ))}
            </Select>
            <RadioGroup name="download-type" value={downloadType} onChange={handleRadioChange}>
              {requestTypes.map((type) => (
                <FormControlLabel
                  value={type.id}
                  key={type.id}
                  control={<Radio />}
                  label={`${type.text}.`}
                />
              ))}
            </RadioGroup>
            <LoaderBtn
              type="submit"
              variant="outlined"
              color="primary"
              fullWidth={false}
              loadingText="Monkeys working"
              text="Request Labels"
              disabled={loading || project.loading}
              isLoading={request && request.status === 'pending'}
            />
          </FormControl>
        </form>

        <LoaderBtn
          variant="outlined"
          color="primary"
          fullWidth={false}
          loadingText="Monkeys working"
          text="Generate Segmentation Masks"
          disabled={!availableMasks}
          onClick={() => generateSegmentationMasks()}
        />

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Available Segmentation Masks'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              There are Segmentation Masks available, do you want to download them before
              continuing?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => handleClose('Continue')} color="primary">
              Continue without Download
            </Button>
            <Button
              variant="outlined"
              onClick={() => handleClose('Download')}
              color="secondary"
              autoFocus
            >
              Download
            </Button>
            <Button onClick={() => handleClose('Cancel')} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
      <Grid item xs={12}>
        <List sx={{ width: '100%', minWidth: 360, bgcolor: 'background.paper' }}>
          {requests.map((req) => {
            const mainText = getTypeText(req.type)
            return (
              <>
                <ListItem key={req.id} alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar alt={mainText} src="none.jpg" />
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${mainText} - ${getFormatText(req.format)}`}
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          <span>Imgs: </span>
                          {req.imagesCount},<span>Tags: </span>
                          {req.tagsCount}
                        </Typography>
                        {` - ${dateToTimeString(req.createdAt)}`}
                      </React.Fragment>
                    }
                  />
                  {request && req.id === request.id && (
                    <ListItemSecondaryAction>
                      <Button
                        style={{ margin: 'auto', marginTop: 10 }}
                        onClick={() => downloadDatasetLabels(req.format)}
                        color="secondary"
                        variant="contained"
                        disabled={
                          loading || project.loading || !request || request.status !== 'completed'
                        }
                      >
                        Download
                        <SaveAlt fontSize="small" />
                      </Button>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
                <Divider key={`div_${req.id}`} variant="inset" component="li" />
              </>
            )
          })}
        </List>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  project: state.project
})

export default connect(mapStateToProps, {})(ProjectExport)
