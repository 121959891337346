import React from 'react'
import PropTypes from 'prop-types'
import { IconButton, InputAdornment } from '@mui/material'
import { VisibilitySharp, VisibilityOffSharp } from '@mui/icons-material'

export const EyeAdornment = ({ handleShow, name, show }) => (
  <InputAdornment position="end">
    <IconButton onClick={() => handleShow(name)} edge="end" size="large">
      {show[name] ? <VisibilitySharp /> : <VisibilityOffSharp />}
    </IconButton>
  </InputAdornment>
)

EyeAdornment.propTypes = {
  handleShow: PropTypes.func,
  name: PropTypes.string,
  show: PropTypes.object
}
