import { API, graphqlOperation } from 'aws-amplify'

import * as mutations from './mutations'
import * as queries from './queries'

// TODO: Use default response
export const createUser = async (user) =>
  API.graphql(graphqlOperation(mutations.createUser, { input: user }))

export const getSingleUser = async (id) => API.graphql(graphqlOperation(queries.getUser, { id }))

export const getUserByEmail = async (email) =>
  API.graphql(graphqlOperation(queries.usersByEmail, { email }))

export const getIsPaidUser = async (userId) => {
  try {
    const { data } = await getSingleUser(userId)

    return { data: { isPaid: data.getUser.paid }, errors: null }
  } catch (e) {
    return { data: null, errors: [{ message: e }] }
  }
}
