export function updateImageProperty({ images, id, property }) {
  if (images === null || typeof images !== 'string') {
    console.error('Invalid input. Images must be a non-null string in JSON format.')
    return []
  }

  try {
    const newImages = JSON.parse(images)

    if (!Array.isArray(newImages)) {
      console.error('Invalid input. Parsed images must be an array.')
      return []
    }

    const updatedImages = newImages.map((page) => {
      if (!page || !page.images || !Array.isArray(page.images)) {
        console.error('Invalid input. Each page must have an "images" property that is an array.')
        return page
      }

      return {
        ...page,
        images: page.images.map((image) =>
          image?.id === id ? { ...image, [property]: !image[property] } : image
        )
      }
    })

    return updatedImages
  } catch (error) {
    console.error('Invalid JSON format in images.')
    return []
  }
}
