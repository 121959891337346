import { s3Upload, s3UploadConfig } from '../services/aws'

export const _getFilesSize = (files) => {
  let size = 0.0
  for (let i = 0; i < files.length; i++) {
    size += files[i].size
  }

  return size
}

export const uploadFile = async (file, folder) => await s3Upload(file, folder)
export const uploadConfig = async (file, folder) => await s3UploadConfig(file, folder)

export const removeFirst = (toUploadFiles) => {
  const array = [...toUploadFiles]
  array.splice(0, 1)
  return array
}

export const getPercentage = (uploadedFiles, toUploadFiles) => {
  const ufSize = _getFilesSize(uploadedFiles)
  const fSize = _getFilesSize(toUploadFiles)
  const totalSize = ufSize + fSize

  return totalSize > 0 ? ((ufSize / totalSize) * 1000).toFixed() / 10 : 0
}
