import React, { useState, useRef, useEffect, useContext, useLayoutEffect } from 'react'
import { Paper, CircularProgress, Box, Typography } from '@mui/material'
import { LabelerContext } from '../context/LabelerContext'
import BoundingboxCanvas from './types/boundingBox/BoundingboxCanvas'
import SegmentationCanvas from './types/segmentation/SegmentationCanvas'
import useZoom from './types/hooks/useZoom'
import LandmarkCanvas from './types/landmark/LandmarkCanvas'
import PolygonCanvas from './types/polygon/PolygonCanvas'
import '../../../styles/segmentation/basicLayout.css'
import { useLoadingImage } from './types/hooks/useLoadingImage'
import CircleCanvas from './types/circle/CircleCanvas'
import LineCanvas from './types/line/LineCanvas'
import { useLabelerMachine } from '../context/MachineContext'
import useOrchestrateLabelArea from './types/hooks/useOrchestrateLabelArea'
import { useEventListener } from './types/hooks/useEventListener'
import { getBoundingBox } from './types/boundingBox/algorithms'
import { getPoly } from './types/polygon/algorithms'
import { getLandmark } from './types/landmark/algorithms'
import MainCanvasMenu from './MainCanvasMenu'
import ClassificationCanvas from './types/classification/ClassificationCanvas'

export default function MainCanvas({ onSegmentation, tensor }) {
  const { state } = useContext(LabelerContext)
  const { state: MachineState } = useLabelerMachine()
  const labeler = useRef(null)
  const labelerLayers = useRef(null)
  const image = useRef(null)
  const canvasImageRef = useRef(null)
  const refFunction = useRef()
  const [mouse, setMouse] = useState({
    x: 0,
    y: 0,
    button: false,
    wheel: 0,
    lastX: 0,
    lastY: 0,
    drag: false,
    button2: false,
    lastButton: false
  })
  const [panZoom, setPanZoom] = useState({ x: 0, y: 0, scale: 1 })
  const [menu, setMenu] = useState({ open: false, pos: { x: 0, y: 0 }, type: null })
  const { labelerState, boundingBoxState, polygonState, landmarkState } = state

  useEffect(() => {
    refFunction.current = mouseDown
  }, [mouseDown])

  const progress = useLoadingImage()

  useEventListener('mousedown', onmouseDown, labeler.current)
  useEventListener('mouseup', onmouseUp, labeler.current)
  useEventListener('mousemove', omouseMove, labeler.current)

  useEffect(() => {
    const instance = labeler.current
    const eventListener = (event) => refFunction.current(event)
    instance.addEventListener('mousedown', eventListener)
    return () => {
      instance.removeEventListener('mousedown', eventListener)
    }
  }, [])

  function mouseDown(e) {
    if (labelerState.role === 'viewer' && e.button === 0) {
      e.stopImmediatePropagation()
    }
  }

  useLayoutEffect(() => {
    let scale = 1
    if (mouse.wheel !== 0) {
      scale = mouse.wheel < 0 ? 1 / 1.01 : 1.01
      setMouse({ ...mouse, wheel: mouse.wheel * 0.8 })
      if (Math.abs(mouse.wheel) < 1) {
        setMouse({ ...mouse, wheel: 0 })
      }
      let dx, dy
      if (panZoom.scale * scale > panZoom.scale) {
        dx = mouse.x - (mouse.x - panZoom.x) * scale
        dy = mouse.y - (mouse.y - panZoom.y) * scale
      } else {
        dx = mouse.x - (mouse.x - panZoom.x) * scale
        dy = mouse.y - (mouse.y - panZoom.y) * scale
      }
      scaleAt(dx, dy, scale)
    }
  }, [panZoom, mouse.wheel])

  useLayoutEffect(() => {
    const canvas = canvasImageRef.current
    const dx = panZoom.x + labelerState.tools.panZoom.x
    const dy = panZoom.y + labelerState.tools.panZoom.y
    const centerx = (canvas.width - image.current.width * panZoom.scale) / 2
    const realCenterx = centerx < 0 ? 0 : centerx
    const centery = (canvas.clientHeight - labelerState.image.props.h * panZoom.scale) / 2
    const realCentery = centery < 0 ? 0 : centery
    let transformDx, transformDy
    if (dx < realCenterx) {
      transformDx =
        dx + labelerState.image.props.w * panZoom.scale < 50
          ? -(labelerState.image.props.w * panZoom.scale - 50)
          : dx
    } else {
      transformDx = dx < canvas.clientWidth - 50 ? dx : canvas.clientWidth - 50
    }
    if (dy < realCentery) {
      transformDy =
        dy + labelerState.image.props.h * panZoom.scale < 50
          ? -(labelerState.image.props.h * panZoom.scale - 50)
          : dy
    } else {
      transformDy = dy < canvas.height - 50 ? dy : canvas.height - 50
    }
    setPanZoom({ ...panZoom, x: transformDx, y: transformDy })
  }, [labelerState.tools.panZoom])

  useLayoutEffect(() => {
    const dx = mouse.x - (mouse.x - panZoom.x) * Math.abs(labelerState.tools.scale)
    const dy = mouse.y - (mouse.y - panZoom.y) * Math.abs(labelerState.tools.scale)
    scaleAt(dx, dy, Math.abs(labelerState.tools.scale))
  }, [labelerState.tools.scale])

  const onmouseWheel = (e) => {
    if (e.target.className === 'layout') setMouse({ ...mouse, wheel: mouse.wheel + -e.deltaY })
  }

  function omouseMove(e) {
    const canvas = canvasImageRef.current
    const bounds = canvas.getBoundingClientRect()
    setMouse({
      ...mouse,
      x: e.pageX - bounds.left - window.scrollX,
      y: e.pageY - bounds.top - window.scrollY,
      lastX: mouse.x,
      lastY: mouse.y,
      lastButton: mouse.button
    })
    if (mouse.button2) {
      calculatePanzoom()
    }
  }

  function onmouseDown(e) {
    setMouse({
      ...mouse,
      button: e.button === 0 ? true : mouse.button,
      button2: e.button === 1 ? true : mouse.button2,
      lastButton: mouse.button
    })

    if (e.button === 2) {
      const b = getBoundingBox(
        (mouse.x - panZoom.x) / panZoom.scale,
        (mouse.y - panZoom.y) / panZoom.scale,
        boundingBoxState.tags,
        panZoom
      )
      const p = getPoly(
        (mouse.x - panZoom.x) / panZoom.scale,
        (mouse.y - panZoom.y) / panZoom.scale,
        polygonState.tags
      )
      const l = getLandmark(
        (mouse.x - panZoom.x) / panZoom.scale,
        (mouse.y - panZoom.y) / panZoom.scale,
        landmarkState.tags,
        panZoom
      )
      const elements = [b, p, l].filter((elemento) => elemento !== null)
      if (elements.length >= 1) {
        setMenu({ open: true, pos: { x: mouse.x + 48, y: mouse.y + 33 }, type: elements[0] })
        e.stopImmediatePropagation()
      }
    }
  }

  function onmouseUp(e) {
    setMouse({
      ...mouse,
      button: e.button === 0 ? false : mouse.button,
      button2: e.button === 1 ? false : mouse.button2
    })
  }

  const calculatePanzoom = () => {
    const canvas = labeler.current
    const dx = panZoom.x - (mouse.lastX - mouse.x)
    const dy = panZoom.y - (mouse.lastY - mouse.y)
    const centerx = (canvas.clientWidth - labelerState.image.props.w * panZoom.scale) / 2
    const realCenterx = centerx < 0 ? 0 : centerx
    const centery = (canvas.clientHeight - labelerState.image.props.h * panZoom.scale) / 2
    const realCentery = centery < 0 ? 0 : centery
    let transformDx, transformDy
    if (dx < realCenterx) {
      transformDx =
        dx + labelerState.image.props.w * panZoom.scale < 50
          ? -(labelerState.image.props.w * panZoom.scale - 50)
          : dx
    } else {
      transformDx = dx < canvas.clientWidth - 50 ? dx : canvas.clientWidth - 50
    }
    if (dy < realCentery) {
      transformDy =
        dy + labelerState.image.props.h * panZoom.scale < 50
          ? -(labelerState.image.props.h * panZoom.scale - 50)
          : dy
    } else {
      transformDy = dy < canvas.clientHeight - 50 ? dy : canvas.clientHeight - 50
    }

    setPanZoom({ ...panZoom, x: transformDx, y: transformDy })
  }

  function scaleAt(parX, parY, sc) {
    if (panZoom.scale * sc < labelerState.image.props.optimalScale / 2) {
      setPanZoom({ scale: labelerState.image.props.optimalScale / 2, x: panZoom.x, y: panZoom.y })
    } else {
      setPanZoom({ scale: panZoom.scale * sc, x: parX, y: parY })
    }
  }

  const { dx, dy, scale } = useOrchestrateLabelArea(
    canvasImageRef,
    labeler,
    labelerState.image.image
  )

  useEffect(() => {
    setPanZoom({ x: dx, y: dy, scale })
  }, [dx, dy, scale])

  useZoom(panZoom, mouse.wheel, [canvasImageRef], () => {
    const imageCanvas = canvasImageRef.current
    const ctx = imageCanvas.getContext('2d')
    ctx.drawImage(image.current, 0, 0)
  })

  useEffect(() => {
    if (MachineState.value === 'readyForLabeling') {
      const canvas = canvasImageRef.current
      const ctx = canvas.getContext('2d')
      ctx.filter = `brightness(${labelerState.canvasSettings[1].value}%) 
       contrast(${labelerState.canvasSettings[2].value}%) 
       saturate(${labelerState.canvasSettings[0].value}%)
       grayscale(${labelerState.canvasSettings[3].value}%)`
      ctx.drawImage(image.current, 0, 0)
    }
  }, [labelerState.canvasSettings, MachineState.value])

  useEffect(() => {
    const imageCanvas = canvasImageRef.current
    const dx = (imageCanvas.width - image.current.width) / 2
    const dy = (imageCanvas.height - image.current.height) / 2
    setPanZoom({
      x: dx < 0 ? 0 : dx,
      y: dy < 0 ? 0 : dy,
      scale: labelerState.image.props.optimalScale
    })
  }, [labelerState.tools.reset])

  useEffect(() => {
    if (labelerState.projectCategories) {
      for (const i in labelerState.projectCategories) {
        const cat = labelerState.projectCategories[i]
        if (cat.layer === 0) {
          if (onSegmentation) {
            onSegmentation()
            break
          }
        }
      }
    }
  }, [labelerState.projectCategories, labelerState.image.currentImage, labelerState.image.index])

  const preventMenu = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const typesOflabeling = () => {
    return {
      0: (
        <SegmentationCanvas
          labeler={labeler}
          panZoom={panZoom}
          mouse={mouse}
          canvasImageRef={canvasImageRef}
          image={image}
          tensor={tensor}
        />
      ),
      1: <PolygonCanvas labeler={labeler} panZoom={panZoom} mouse={mouse} />,
      2: <BoundingboxCanvas labeler={labeler} panZoom={panZoom} mouse={mouse} />,
      3: <CircleCanvas labeler={labeler} panZoom={panZoom} mouse={mouse} />,
      4: <LineCanvas labeler={labeler} panZoom={panZoom} mouse={mouse} />,
      5: <LandmarkCanvas labeler={labeler} panZoom={panZoom} mouse={mouse} />,
      6: <ClassificationCanvas />
    }
  }

  const handleClose = () => {
    setMenu({ ...menu, open: false })
  }

  return (
    <div
      id="labelercontainer"
      style={{ cursor: 'pointer' }}
      onWheel={onmouseWheel}
      onContextMenu={preventMenu}
      className="containerLayouts"
      ref={labeler}
    >
      <div className="containerLayouts" ref={labelerLayers}>
        <img
          alt=""
          src={labelerState.image.image}
          ref={image}
          style={{ display: 'none' }}
          crossOrigin="Anonymous"
        />
        <canvas className="layout" ref={canvasImageRef} width={1} height={1} />
        {labelerState.projectCategories.map(
          (cat) => typesOflabeling(labelerLayers, panZoom, mouse, canvasImageRef)[cat.layer]
        )}

        <Paper
          style={{
            visibility: labelerState.loadingAI ? 'visible' : 'hidden',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 3,
            width: labelerState.tools.width,
            height: labelerState.tools.height,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#333333cc'
          }}
        >
          <CircularProgress size={60} />
        </Paper>
        {progress < 100 && (
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Typography variant="body1" component="h1" color="text.secondary">{`${Math.round(
              progress
            )}%`}</Typography>
          </Box>
        )}
        <MainCanvasMenu
          isOpen={menu.open}
          handleClose={handleClose}
          pos={menu.pos}
          category={menu.type}
        />
      </div>
    </div>
  )
}
