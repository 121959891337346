import React from 'react'

import '../styles/NotFound.css'

const NotFound = () => (
  <div className="NotFound">
    <h3>Sorry, page not found!</h3>
    <img alt="" style={{ maxHeight: '200px' }} src="assets/harry_sleeping.png" />
  </div>
)

export default NotFound
