import React, { useState } from 'react'
import { getUser } from '../../../../../utils/util'
import { updateComment } from '../../../../../services/graphql'
import useRole from '../../../../../utils/labeler/useRole'
import { styled } from '@mui/material/styles'

import { Button, TextField, CircularProgress } from '@mui/material'

const StylesTextField = styled(TextField)(({ theme }) => ({
  color: theme.palette.text.primary,
  width: '92%'
}))

const Container = styled('div')(() => ({}))

const ContainerInput = styled('div')(() => ({
  width: '90%',
  textAlign: 'end'
}))

const ContainerButtons = styled('div')(() => ({
  display: 'flex',
  width: '90%',
  margin: '5px 0 0 10%',
  justifyContent: 'start',
  alignItems: 'center'
}))

export default function ReplyInput({ comments, index, handleAddMessage, handleCancel }) {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const role = useRole()

  const handleChangeMessage = (e) => {
    setMessage(e.target.value)
  }

  const createNewMesagge = async () => {
    try {
      const user = getUser()
      const msg = {
        message,
        messageUserId: user.id,
        role: role.labeler,
        username: user.name,
        createdAt: new Date().toISOString()
      }
      setLoading(true)
      const newMessages = [...comments[index].messages, msg]
      const item = { id: comments[index].id, messages: newMessages }
      await updateComment(item)
      handleAddMessage(msg, index)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  return (
    <Container>
      <ContainerInput>
        <span>
          <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
            <path
              d="M8 6h4.832C13.908 6 16 6.5 16 9c0 1.333-.333 2.167-1 2.5 1.333.333 2 1.333 2 3 0 .5 0 3.5-4 3.5H8a1 1 0 01-1-1V7a1 1 0 011-1zm1 10h3.5c1 0 2-.25 2-1.5s-1.104-1.5-2-1.5H9v3zm0-4.975h3c.504 0 2 0 2-1.525S12 8 12 8H9v3.025z"
              fill="currentColor"
            ></path>
          </svg>
        </span>
        <span>
          <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
            <path
              d="M10 6h6a1 1 0 010 2h-6a1 1 0 110-2zM8 16h6a1 1 0 010 2H8a1 1 0 010-2zm4-8h2l-2 8h-2l2-8z"
              fill="currentColor"
            ></path>
          </svg>
        </span>
        <StylesTextField
          id="outlined-multiline-flexible"
          multiline
          rows={4}
          onChange={handleChangeMessage}
          value={message}
        />
      </ContainerInput>
      <ContainerButtons>
        {loading ? (
          <CircularProgress size={'1.5rem'} />
        ) : (
          <>
            <Button
              size="small"
              sx={{ margin: '2px' }}
              onClick={createNewMesagge}
              variant="outlined"
            >
              Save
            </Button>
            <Button size="small" sx={{ margin: '2px' }} onClick={handleCancel}>
              Cancel
            </Button>
          </>
        )}
      </ContainerButtons>
    </Container>
  )
}
