import React from 'react'
import Check from '@mui/icons-material/Check'
import Cancel from '@mui/icons-material/Cancel'
import ReplayIcon from '@mui/icons-material/Replay'
import { Tooltip, Button } from '@mui/material'
import { styled } from '@mui/material/styles'

const ButtonComponent = styled(Button)(() => ({
  minWidth: 45,
  width: 45
}))

export default function AutoboundingBoxesButtons({ region, confirm, cancel, reload, isLoading }) {
  return (
    <>
      <Tooltip title="Confirm Mask">
        <ButtonComponent
          disabled={isLoading}
          onClick={confirm}
          variant="contained"
          color="primary"
          style={{
            position: 'absolute',
            top: region.toolsPosY - 40,
            left: region.toolsPosX
          }}
        >
          <Check />
        </ButtonComponent>
      </Tooltip>
      <Tooltip title="Cancel Mask">
        <ButtonComponent
          disabled={isLoading}
          onClick={cancel}
          variant="contained"
          color="primary"
          style={{
            position: 'absolute',
            top: region.toolsPosY - 40,
            left: region.toolsPosX + 60
          }}
        >
          <Cancel />
        </ButtonComponent>
      </Tooltip>
      <Tooltip title="Cancel Mask">
        <ButtonComponent
          disabled={isLoading}
          onClick={reload}
          variant="contained"
          color="primary"
          style={{
            position: 'absolute',
            top: region.toolsPosY - 40,
            left: region.toolsPosX + 120
          }}
        >
          <ReplayIcon />
        </ButtonComponent>
      </Tooltip>
    </>
  )
}
