import React, { useContext } from 'react'
import { TextField, IconButton, Paper, Divider } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { FiltersContext } from '../../../../../context/FiltersContext'

const Searcher = () => {
  const { state, dispatch } = useContext(FiltersContext)

  const handleSubmit = () => {
    dispatch({ type: 'setNames', payload: { name: state.name, operator: 'and' } })
  }

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <Paper
      elevation={0}
      variant={'outlined'}
      sx={{
        display: 'flex',
        flex: 8,
        alignItems: 'center',
        justifyContent: 'center',
        padding: 0.65,
        margin: 0.65
      }}
    >
      <TextField
        autoFocus
        size="small"
        value={state.name}
        sx={{ width: '100%', margin: '0.5rem 0.5rem', marginRight: '1rem' }}
        placeholder="Name"
        variant="standard"
        onChange={(e) => {
          dispatch({ type: 'setName', payload: e.target.value })
        }}
        onKeyUp={handleEnterKey}
      />
      <Divider orientation="vertical" flexItem />
      <IconButton
        sx={{ marginLeft: 1, marginRight: 0.5 }}
        size="small"
        type="button"
        aria-label="search"
        onClick={handleSubmit}
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  )
}

export default Searcher
