import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import UserSearch from '../components/UserSearch'
import { getTeam } from '../services/graphql'
import { getUser } from '../utils/util'
import { styled, List, ListItemButton, ListItemText } from '@mui/material'
import { user } from '../io/user/actions'

const StyledList = styled(List)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  margin: 0,
  padding: 0
}))

const ListItemButtonProject = styled(ListItemButton)(({ theme }) => ({
  display: 'block',
  borderBottom: `1px solid ${theme.palette.grey[300]}`
}))

const ContainerItemInfo = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: '50% 50%'
}))

const MainContainerTeam = styled('div')(() => ({
  margin: '7rem 10rem'
}))

const MainGridContainerTeam = styled(Grid)(() => ({
  margin: '1rem 0rem'
}))

const Title = styled(Typography)(() => ({
  marginBottom: '1rem'
}))

const Text = styled(Typography)(() => ({
  marginTop: '10px',
  textAlign: 'left'
}))

const CreateTeam = (props) => {
  const location = useLocation()
  const [team, setTeam] = useState({})

  useEffect(() => {
    const params = location.search.split('=')
    const id = params[params.length - 1]
    if (id) {
      getTeam(id)
        .then((r) => {
          const team = r.data.getTeam
          setTeam(team)
        })
        .catch((e) => console.log(e))
    }
  }, [])

  useEffect(() => {
    props.getProjects()
  }, [])

  const associatedProjects = props?.projects?.items?.filter(
    (project) => project?.team?.id === team?.id
  )

  function formatDate(originalDate) {
    const newDate = new Date(originalDate)
    const year = newDate.getFullYear()
    const month = (newDate.getMonth() + 1).toString().padStart(2, '0')
    const day = newDate.getDate().toString().padStart(2, '0')

    return `${year}-${month}-${day}`
  }

  return (
    <MainContainerTeam>
      <Paper elevation={0}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" href="/teams" to="/teams">
            Teams
          </Link>
          <Typography color="textPrimary">{team.name}</Typography>
        </Breadcrumbs>
      </Paper>
      <MainGridContainerTeam container spacing={2}>
        <Grid item xs={7}>
          <Title variant="h4">Your Team</Title>
          {team && (
            <form noValidate autoComplete="off">
              <Typography variant="h5">{team.name}</Typography>
              <Text variant="h5">Search for members</Text>
              <UserSearch
                {...props}
                team={team}
                notDelete={!team.user && team.user && getUser().id !== team.user.id}
              />
            </form>
          )}
        </Grid>
        <Grid item xs={4} sx={{ marginLeft: '4rem' }}>
          <Title variant="h4">Associated Projects</Title>
          <StyledList>
            {associatedProjects?.map((project, index) => (
              <ListItemButtonProject key={index}>
                <ContainerItemInfo>
                  <ListItemText primary={<Typography variant="body2">{project.name}</Typography>} />
                  <ListItemText
                    sx={{ textAlign: 'right' }}
                    primary={
                      <Typography variant="body2">{formatDate(project.createdAt)}</Typography>
                    }
                  />
                </ContainerItemInfo>
              </ListItemButtonProject>
            ))}
          </StyledList>
        </Grid>
      </MainGridContainerTeam>
    </MainContainerTeam>
  )
}

const mapStateToProps = (state) => ({
  projects: state.user.projects
})

const mapDispatchToProps = {
  getProjects: user.getMyProjects
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTeam)
