import React from 'react'
import {
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemSecondaryAction,
  Typography,
  Button,
  Divider,
  LinearProgress
} from '@mui/material'
import { styled } from '@mui/material/styles'

import { dateToString } from '../../../../../utils/util'

const LoadMore = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main
}))

function SharedProjectsList({ loading, loadMore, projects, nextToken, labelIt }) {
  return (
    <>
      {loading && <LinearProgress />}
      <List>
        {projects
          ? projects.map((project) => (
              <section key={project.id}>
                <ListItem style={{ textDecoration: 'none' }} component="a">
                  <ListItemText
                    primary={project.name}
                    primaryTypographyProps={{
                      color: 'primary',
                      component: 'a',
                      href:
                        project.role === 'viewer'
                          ? `/project?id=${project.id}`
                          : `/sharedproject?id=${project.id}`
                    }}
                    secondary={`${project.team ? project.team.name : 'Default'} - ${dateToString(
                      project.createdAt
                    )}`}
                  />
                  <ListItemSecondaryAction>
                    {project.role && project.role === 'viewer' ? (
                      <></>
                    ) : (
                      <Button
                        id="startlabeling"
                        size="small"
                        color={'secondary'}
                        variant="contained"
                        onClick={() => labelIt(project)}
                      >
                        {project.role === 'reviewer' ? 'Start Reviewing' : 'Start Labeling'}
                      </Button>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider component="li" />
              </section>
            ))
          : null}
        {(projects.length <= 0 || nextToken) && (
          <ListItemButton key="more" onClick={loadMore} disabled={loading}>
            <Typography>
              <LoadMore>Load more...</LoadMore>
            </Typography>
          </ListItemButton>
        )}
      </List>
    </>
  )
}

export default SharedProjectsList
