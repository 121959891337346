import React, { useState, useEffect, useCallback } from 'react'
import { Link, useLocation } from 'react-router-dom'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import {
  Grid,
  Paper,
  Chip,
  Button,
  IconButton,
  ImageList,
  DialogTitle,
  DialogActions,
  ImageListItem,
  Box,
  ImageListItemBar,
  Dialog,
  Typography,
  CircularProgress
} from '@mui/material'
import { styled } from '@mui/material/styles'
import HubIcon from '@mui/icons-material/HubOutlined'
import BackIcon from '@mui/icons-material/ArrowBackIos'
import AddIcon from '@mui/icons-material/Add'
import { uniqBy as _uniq } from 'lodash'

import { getDataset, updateDataset } from '../services/graphql'
import { getImages } from '../io/graphql/dataset/dataset'
import {
  sandDatasetNameEdited,
  sandImageFailed,
  sandImageUploaded,
  sandImagesAddedToDataset
} from '../io/analytics'
import { getUserToken, getUser, getAllowFiles, formatNumber } from '../utils/util'
import EditableTextField from '../components/generics/textfields/EditableTextField'
import SearchBar from '../components/generics/textfields/SearchBar'
import Upload from '../components/Upload'
import ImageDropzone from '../components/generics/ImageDropzone'

import { useEventListener } from '../utils/hooks/useEventListener'
import { getPercentage, removeFirst, uploadFile } from '../utils/datasets'
import useFreeTrial from '../pages/project/hooks/useFreeTrial'
import { useSnackbar } from '../components/snackbar/context/SnackbarContext'

const WS_URL = 'wss://az39jl2ycj.execute-api.us-east-1.amazonaws.com/production'

const RootContainer = styled('div')(() => ({
  flexGrow: 1,
  marginTop: 20,
  padding: '60px 2% 0px 2%',
  height: '97%',
  overflow: 'hidden'
}))

const ImagesContainer = styled(Paper)(() => ({
  height: '100%',
  padding: 3,
  textAlign: 'center',
  overflow: 'hidden',
  borderRadius: '8px'
}))

const BoxContainer = styled(Box)(({ theme }) => ({
  height: '90%',
  padding: '0px 8px',
  overflow: 'hidden',
  overflowY: 'scroll',
  boxShadow: 'none',
  '&::-webkit-scrollbar': {
    width: '0.5em',
    zIndex: -1
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(220, 220,220,.2)' : 'rgba(50,50,50,.1)',
    borderRadius: '35px'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(160,160,160,1)' : 'rgba(200, 200,200,1)',
    borderRadius: 25
  }
}))

const MyCircularProgress = styled(CircularProgress)(() => ({
  position: 'absolute',
  margin: '0px',
  width: '60px !important',
  height: '60px !important',
  top: '6px',
  left: '6px',
  zIndex: 10
}))

function SimpleDialog(props) {
  const { onClose, selectedValue, open, dataset, onUploaded } = props

  const handleClose = () => {
    onClose(selectedValue)
  }

  return (
    <Dialog fullWidth maxWidth="lg" onClose={handleClose} open={open}>
      <DialogTitle>Upload new data to the Dataset</DialogTitle>
      <Upload {...props} dataset={dataset} imageUploaded={onUploaded} createDisable />
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

const Dataset = (props) => {
  const { permissionsFreeTrial } = useFreeTrial()
  const location = useLocation()
  const user = getUser()

  const [dataset, setDataset] = useState({})
  const [datasetId, setDatasetId] = useState(undefined)
  const [openUpload, setOpenUpload] = useState(false)
  // Loading
  const [loading, setLoading] = useState(false)
  // Last Request State
  const [requestActive, setRequestActive] = useState(false)
  // SearchName
  const [searchName, setSearchName] = useState('')
  // Images
  const [images, setImages] = useState([])
  // Token for Next Request
  const [nextReq, setNextReq] = useState(null)
  // Socket URl
  const [socketUrl, setSocketUrl] = useState(WS_URL)
  // Dropzone
  const [dropzoneActive, setDropzoneActive] = useState(true)
  // toUploadFiles
  const [toUploadFiles, setToUploadFiles] = useState([])
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [retries, setRetries] = useState(0)

  const { showSnackbar } = useSnackbar()

  const { sendJsonMessage, lastMessage, readyState } = useWebSocket(
    socketUrl, // ?userId=c7303e03-015b-4eb7-88c8-94fc18149f04&datasetId=07eb20dd-6183-4e0c-a898-955f9c418b0d`
    {
      onOpen: () => {
        console.log('WebSocket connection established.')
      }
    }
  )

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated'
  }[readyState]

  const handleClickChangeSocketUrl = useCallback((url) => setSocketUrl(url), [])

  useEffect(() => {
    console.log('Message', lastMessage)
  }, [lastMessage])

  useEffect(() => {
    const params = location.search.split('=')
    const id = params[params.length - 1]
    setDatasetId(id)

    if (id) {
      getDatasetInfo(location.state && location.state.dataset ? location.state.dataset : {}, id)
    }
  }, [])

  useEffect(() => {
    if (datasetId) {
      handleClickChangeSocketUrl(`${WS_URL}?userId=${user.id}&datasetId=${datasetId}`)
      getNextImages('load')
    }
  }, [dataset])

  useEffect(() => {
    _uploadFiles()
  }, [uploadedFiles, toUploadFiles, retries])

  const createEmbedding = (e, ids) => {
    if (ReadyState.OPEN === readyState) {
      sendJsonMessage(
        {
          action: 'create',
          data: {
            imageIds: ids,
            modelName: 'samH',
            datasetId,
            userId: user.id
          }
        },
        []
      )
      showSnackbar({
        message: 'Creating Embedding',
        type: 'info'
      })
      setImages((prevState) => {
        const updatedImages = prevState.map((image) => {
          if (ids.includes(image.id)) {
            return {
              ...image,
              generateEmbeddings: { samH: true }
            }
          }
          return image
        })

        return updatedImages
      })
    } else {
      showSnackbar({
        message: `Error Starting Embedding creation, status: ${connectionStatus}`,
        type: 'error'
      })
    }
  }

  const createAllEmbeddings = () => {
    if (ReadyState.OPEN === readyState) {
      sendJsonMessage(
        {
          action: 'create',
          data: {
            imageIds: [],
            modelName: 'samH',
            datasetId,
            userId: user.id,
            status: 'locked'
          }
        },
        []
      )
      showSnackbar({
        message: `Creating Embeddings`,
        type: 'info'
      })
      setImages((prevState) => {
        const updatedImages = prevState.map((image) => {
          return {
            ...image,
            generateEmbeddings: { samH: true }
          }
        })

        return updatedImages
      })
    } else {
      showSnackbar({
        message: `Error Starting Embeddings creation, status: ${connectionStatus}`,
        type: 'error'
      })
    }
  }

  const getDatasetInfo = (dataset, id) => {
    if (id || (dataset && !dataset.demo)) {
      getDataset(id)
        .then((r) => {
          if (!r.err) {
            const _dataset = r.data.getDataset
            setDataset(_dataset)
          }
        })
        .catch((e) => console.log(e))
    }
  }

  const getNextImages = async (typeRequest, filter) => {
    if (requestActive !== true) {
      // Star images charge
      setLoading(true)
      setRequestActive(true)

      try {
        // Response getImages
        const { data, error } = await getImages(datasetId, nextReq, 100, filter)

        // Exist images
        if (!error) {
          setNextReq(data.nextToken)
          if (typeRequest === 'filter') {
            // Array with images and data
            const auxImages = await handleSetImages(data)
            // New images array
            setImages([...auxImages])
          } else {
            // Array with images and data
            const auxImages = await handleSetImages(data)
            const array = _uniq([...images, ...auxImages], 'id')
            // New images array
            setImages(array)
          }
        }
      } catch (error) {
        console.log(error)
      }
      // Finish images charge
      setLoading(false)
      setRequestActive(false)
    }
  }

  // Custom Hook to listen Scroll Events
  useEventListener('scroll', handlerScroll, window, true)

  // Function to calculate the position of the scroll and send more images request
  function handlerScroll(e) {
    if (images.length < 100 || e.target.scrollHeight === e.target.clientHeight) {
      return []
    } else if (
      Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight) <= 5.0 &&
      e.target.scrollHeight !== e.target.clientHeight
    ) {
      getNextImages('more', searchName)
    }
  }

  const handleNameEdited = (editText) => {
    updateDataset({ id: datasetId, name: editText })
      .then((r) => {
        if (r?.data?.updateDataset) setDataset({ ...dataset, name: editText })
      })
      .catch((e) => {
        console.log(e)
      })

    sandDatasetNameEdited({ id: datasetId, name: editText, oldName: dataset.name })
  }

  const handleSetImages = async (data) => {
    const idToken = await getUserToken()
    const auxImages = []
    data.items &&
      data.items.forEach((image) => {
        auxImages.push({
          src:
            'https://d364olzi1d53qw.cloudfront.net/' +
            dataset.id +
            '/891x501' +
            image.key.slice(image.key.lastIndexOf('/')) +
            '?token=' +
            idToken,
          loading: false,
          width: image.width,
          height: image.height,
          key: image.key,
          selected: false,
          id: image.id,
          name: image.key.slice(image.key.lastIndexOf('/') + 1),
          generateEmbeddings: image.generateEmbeddings || { samH: false },
          embeddings: image.embeddings || { samH: false }
        })
      })
    return auxImages
  }

  const handleUploaded = () => {
    getNextImages('load')
  }

  // const _getFilesSize = (files) => {
  //   let size = 0.0
  //   for (let i = 0; i < files.length; i++) {
  //     size += files[i].size
  //   }

  //   return size
  // }

  const onDrop = async (acceptedFiles) => {
    sandImagesAddedToDataset({
      id: datasetId,
      name: dataset.name,
      count: acceptedFiles.length
    })
    setDropzoneActive(false)

    const { allowedFiles, rejectedFiles } = getAllowFiles(acceptedFiles)
    if (rejectedFiles.length > 0) {
      showSnackbar({
        message: `You may only upload PNG, JPEG, JPG files`,
        type: 'warning'
      })
    }
    setToUploadFiles([...toUploadFiles, ...allowedFiles])
  }

  const handleFiltersChange = (filters) => {
    const name = filters.and.length > 0 ? filters.and[0].nameDataset.nameDataset : ''
    setSearchName(name)
    getNextImages('filter', name)
  }

  const _uploadFiles = () => {
    if (dataset && datasetId && toUploadFiles.length > 0) {
      const file = toUploadFiles[0]

      if (!_fileWasUpload(file.name)) {
        uploadFile(file, datasetId) // TODO: Track progress
          .then((attachment) => {
            setUploadedFiles([...uploadedFiles, file])
            setToUploadFiles(removeFirst(toUploadFiles))
            sandImageUploaded({ name: file, datasetId: dataset.name, datasetName: dataset.name })

            if (props.imageUploaded) {
              props.imageUploaded(attachment)
            }
          })
          .catch(() => {
            sandImageFailed({ datasetId, datasetName: dataset.name, file })
            showSnackbar({
              message: `Error uploading image:  ${file.name} Retry:${retries + 1}`,
              type: 'error'
            })
            setRetries(retries + 1)
          })
      } else {
        setToUploadFiles(removeFirst(toUploadFiles))
      }
    } else {
      setDropzoneActive(true)
    }
  }

  // const uploadFile = async (file, folder) => await s3Upload(file, folder)
  // const uploadConfig = async (file, folder) => await s3UploadConfig(file, folder)

  // const removeFirst = (toUploadFiles) => {
  //   const array = [...toUploadFiles]
  //   array.splice(0, 1)
  //   return array
  // }

  const _fileWasUpload = (fileName) => {
    for (let i = 0; i < uploadedFiles.length; i++) {
      if (uploadedFiles[i].name === fileName) {
        showSnackbar({
          message: `There is a duplicate image name: ${fileName}`,
          type: 'warning'
        })
        return true
      }
    }
    return false
  }

  // const getPercentage = () => {
  //   const ufSize = _getFilesSize(uploadedFiles)
  //   const fSize = _getFilesSize(toUploadFiles)
  //   const totalSize = ufSize + fSize

  //   return totalSize > 0 ? ((ufSize / totalSize) * 1000).toFixed() / 10 : 0
  // }

  const ImageProgress = () => (
    <div style={{ position: 'relative', width: 72, height: 72, margin: '10px auto' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%'
        }}
      >
        <Typography variant="h6">
          {toUploadFiles.length <= 0 && getPercentage(uploadedFiles, toUploadFiles) === 0
            ? ''
            : getPercentage(uploadedFiles, toUploadFiles) + '%'}
        </Typography>
      </div>
      {dropzoneActive ||
      (toUploadFiles.length <= 0 && getPercentage(uploadedFiles, toUploadFiles) === 0) ? null : (
        <MyCircularProgress color="secondary" />
      )}
      <MyCircularProgress
        variant="determinate"
        value={Number(getPercentage(uploadedFiles, toUploadFiles))}
      />
    </div>
  )

  return (
    <>
      <RootContainer>
        <Grid container spacing={1} sx={{ height: '100%', overflow: 'hidden' }}>
          <Grid item xs={2}>
            <Link color="inherit" href="/datasets" to="/datasets">
              <Button startIcon={<BackIcon />}>Datasets</Button>
            </Link>
            <div style={{ height: '24px' }} />
            <Button
              sx={{ margin: 1 }}
              variant="outlined"
              size="large"
              startIcon={<AddIcon />}
              onClick={() => setOpenUpload(true)}
            >
              Add Image
            </Button>
            {user.paid || permissionsFreeTrial ? (
              <Button sx={{ margin: 1 }} size="large" onClick={() => createAllEmbeddings()}>
                Create All Embeddings
              </Button>
            ) : null}
            <ImageProgress />
          </Grid>
          <Grid
            item
            xs={10}
            sx={{ height: '100%', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}
          >
            <Box sx={{ marginBottom: '10px', marginTop: 0 }}>
              <SearchBar
                fields={[{ id: 'nameDataset', type: 'text', label: 'Name', minWidth: 200 }]}
                filtersChange={handleFiltersChange}
                singleSearch
              />
            </Box>
            <ImageDropzone onDrop={onDrop} noClick={true}>
              {({ getRootProps, getInputProps }) => (
                <ImagesContainer {...getRootProps()}>
                  <input {...getInputProps()} />
                  <EditableTextField
                    sx={{ marginTop: 2, marginBottom: 1, marginLeft: 2 }}
                    value={dataset.name}
                    onSaveClicked={handleNameEdited}
                  />
                  <Typography sx={{ marginBottom: 2, marginLeft: 2 }} align="left" variant="body1">
                    Images <Chip label={formatNumber(dataset.count || 0)} size="small" />
                  </Typography>
                  <BoxContainer>
                    <ImageList sx={{ paddingBottom: 1 }} variant="masonry" cols={2} gap={8}>
                      {images.map((item) => (
                        <ImageListItem key={item.id}>
                          <img
                            style={{ minHeight: 100 }}
                            src={item.src}
                            srcSet={`${item.src} 2x`}
                            alt={item.name}
                            loading="lazy"
                          />
                          <ImageListItemBar
                            title={item.name}
                            actionIcon={
                              user.paid || permissionsFreeTrial ? (
                                <IconButton
                                  sx={{
                                    color: '#eaeaea',
                                    '&.Mui-disabled': {
                                      color: 'rgba(100, 100, 255, 0.84)'
                                    }
                                  }}
                                  disabled={item.embeddings.samH}
                                  aria-label={`has embedding`}
                                  onClick={(e) => createEmbedding(e, [item.id])}
                                >
                                  {item.generateEmbeddings.samH && !item.embeddings.samH ? (
                                    <CircularProgress color="secondary" size={20} />
                                  ) : (
                                    <HubIcon />
                                  )}
                                </IconButton>
                              ) : null
                            }
                          />
                        </ImageListItem>
                      ))}
                    </ImageList>
                    {(images.length >= 100) & loading ? (
                      <CircularProgress size={50} sx={{ padding: 1 }} />
                    ) : null}
                  </BoxContainer>
                </ImagesContainer>
              )}
            </ImageDropzone>
          </Grid>
        </Grid>
        <SimpleDialog
          dataset={dataset}
          open={openUpload}
          onUploaded={handleUploaded}
          onClose={() => setOpenUpload(false)}
        />
      </RootContainer>
    </>
  )
}

export default Dataset
