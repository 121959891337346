import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { createUser } from '../../io/graphql/user/user'
import { getUser, saveUser } from '../../utils/util'
import { Auth } from 'aws-amplify'
import DialogFreeTrial from '../../pages/dashboard/components/projects/components/DialogFreeTrial'

export default function FormDialog() {
  const [open, setOpen] = useState(false)
  const [openDialogFreeTrial, setOpenDialogFreeTrial] = useState(false)

  const handleOpenDialogFreeTrial = () => {
    setOpenDialogFreeTrial(true)
  }

  const handleCloseDialogFreeTrial = () => {
    setOpenDialogFreeTrial(false)
    window.location.reload()
  }
  const [userInformation, setUserInformation] = useState({ name: '', company: '', role: '' })

  useEffect(() => {
    const user = getUser()
    if (user === null) {
      setOpen(true)
    }
  }, [])

  const _handleChange = (event) => {
    setUserInformation({ ...userInformation, [event.target.name]: event.target.value })
  }

  const handleClose = () => {
    setOpen(false)
  }

  const saveNewUser = async (e) => {
    e.preventDefault()
    const user = await Auth.currentSession()
    createUser({
      email: user.getIdToken().payload.email, // TODO RECUPERAR DE COGNITO
      name: userInformation.name,
      status: 'created',
      termsAccepted: new Date(),
      company: userInformation.company === '' ? '-' : userInformation.company,
      owner: user.getIdToken().payload['cognito:username']
    })
      .then((res) => {
        saveUser(res.data.createUser)
        handleClose()
        handleOpenDialogFreeTrial()
      })
      .catch((err) => console.log('Error', err))
  }

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Let us know you!</DialogTitle>
        <DialogContent>
          <DialogContentText>We need some extra data to set your account ready!</DialogContentText>
          <form onSubmit={saveNewUser} id="myform">
            <TextField
              required
              autoFocus
              margin="dense"
              id="name"
              name="name"
              label="Name"
              type="name"
              fullWidth
              variant="standard"
              onChange={_handleChange}
              value={userInformation.name}
            />
            <TextField
              autoFocus
              margin="dense"
              id="company"
              name="company"
              label="Company Name"
              type="company"
              fullWidth
              variant="standard"
              onChange={_handleChange}
              value={userInformation.company}
            />
            <TextField
              autoFocus
              margin="dense"
              id="role"
              name="role"
              label="Company Role"
              type="role"
              fullWidth
              variant="standard"
              onChange={_handleChange}
              value={userInformation.role}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" type="submit" form="myform">
            Done
          </Button>
        </DialogActions>
      </Dialog>
      <DialogFreeTrial open={openDialogFreeTrial} handleClose={handleCloseDialogFreeTrial} />
    </>
  )
}
