import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { project as p } from '../../io/project/actions'
import { getUser, getMemberRole } from '../../utils/util'
import {
  List,
  Drawer,
  Divider,
  ListItemButton,
  ListItemText,
  LinearProgress,
  IconButton,
  Collapse
} from '@mui/material'
import ReplayIcon from '@mui/icons-material/Replay'
import { styled } from '@mui/material/styles'
import LaunchIcon from '@mui/icons-material/Launch'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import ProjectOverview from './components/overview/ProjectOverview'
import ProjectSettings from '../../components/Project/projectSettings/ProjectSettings'
import ProjectExport from '../../components/Project/ProjectExport'
import ProjectLabels from '../../components/Project/ProjectLabels'
import ProjectHeader from '../../containers/ProjectHeader'
import Visualizer from './components/visualizer/Visualizer'
import EditableTextField from '../../components/generics/textfields/EditableTextField'
import useFreeTrial from './hooks/useFreeTrial'

const ListComponent = styled('div')(() => ({
  paddingTop: 60,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-end',
  zIndex: 1
}))

const Content = styled('div')(() => ({
  flexGrow: 1,
  height: '100%',
  display: 'flex',
  justifyContent: 'flex start',
  flexDirection: 'column',
  padding: '1rem',
  margin: '0px'
}))

const ContainerTitle = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  textAlign: 'left',
  padding: `4rem 0rem 0rem 0rem`
}))

const TABS = {
  OVERVIEW: 'Overview',
  SETTINGS: 'Settings',
  LABELS: 'Label status',
  TEAM: 'Team',
  DATASET: 'Dataset',
  EXPORT: 'Export Options',
  VISUALIZER: 'Visualizer'
}

const Project = (props) => {
  const { permissionsFreeTrial } = useFreeTrial()

  const navigate = useNavigate()

  const [open, setOpen] = useState(true)
  const [tabs, setTabs] = useState([])
  const [tab, setTab] = useState(0)
  const [role, setRole] = useState('')
  const [idProject, setIdProject] = useState('')

  const containerProject = useRef(null)
  const { project } = props

  const HEADER = {
    Overview: <ProjectHeader id={project.id} />,
    Settings: <ProjectHeader id={project.id} />,
    'Label status': <ProjectHeader id={project.id} />,
    Team: <ProjectHeader id={project.id} />,
    'Export Options': <ProjectHeader id={project.id} />,
    Visualizer: <></>
  }

  useEffect(() => {
    props.cleanProject()
    setRole('')
    const queryParams = new URLSearchParams(window.location.search)
    const id = queryParams.get('id')
    setIdProject(id)
  }, [])

  useEffect(() => {
    if (idProject) {
      props.getProject(idProject)
    }
  }, [idProject])

  useEffect(() => {
    if (props.project?.team) {
      if (props.project.team.members) {
        const mRole = getMemberRole(props.project.team)
        if (
          mRole === 'admin' &&
          (!props.project?.user || getUser().id !== props.project?.user?.id)
        ) {
          navigate(`/projects`)
        }
        setRole(mRole)
      } else if (props.project.team?.name && props.project.team.name.includes('Riding solo')) {
        if (!props.project?.user || getUser().id !== props.project?.user?.id) navigate(`/projects`)
        setRole('admin')
      } else {
        navigate(`/projects`)
      }
    }
    setInfo()
  }, [props.project])

  const setInfo = () => {
    if (getUser()?.paid || permissionsFreeTrial) {
      if (role === 'viewer') {
        setTabs([TABS.OVERVIEW, TABS.LABELS, TABS.VISUALIZER, TABS.EXPORT])
      } else if (role === 'admin') {
        setTabs([
          TABS.OVERVIEW,
          TABS.SETTINGS,
          TABS.LABELS,
          TABS.VISUALIZER,
          TABS.DATASET,
          TABS.TEAM,
          TABS.EXPORT
        ])
      } else if (role === 'labeler' || role === 'reviewer') {
        navigate(`/sharedproject?id=${idProject}`)
      }
    } else {
      if (role === 'viewer') {
        setTabs([TABS.OVERVIEW, TABS.LABELS, TABS.EXPORT])
      } else if (role === 'admin') {
        setTabs([TABS.OVERVIEW, TABS.SETTINGS, TABS.DATASET, TABS.LABELS, TABS.TEAM, TABS.EXPORT])
      } else if (role === 'labeler' || role === 'reviewer') {
        navigate(`/sharedproject?id=${idProject}`)
      }
    }
  }

  const renderTab = () => {
    const t = tab >= tabs.length ? '' : tabs[tab]
    switch (t) {
      case TABS.OVERVIEW:
        return <ProjectOverview />
      case TABS.SETTINGS:
        return <ProjectSettings />
      case TABS.LABELS:
        return <ProjectLabels />
      case TABS.VISUALIZER:
        return <Visualizer collapse={!open} role={role} />
      case TABS.EXPORT:
        return <ProjectExport />
      default:
        return null
    }
  }

  const sideMenu = (
    <ListComponent role="presentation" style={{ width: open ? '127px' : '45px' }}>
      <IconButton
        onClick={() => {
          setOpen(!open)
        }}
        aria-expanded={open}
        aria-label="show more"
      >
        {open ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
      </IconButton>
      <Collapse in={open}>
        <List>
          <Divider />
          {tabs.map((text, index) => {
            let isDisable = props.project.loading
            let destination = null
            const datasetsLen = props?.project?.datasets.length
            const isExternal = text === 'Team' || text === 'Dataset'

            if (!isDisable && isExternal) {
              if (text === 'Team') isDisable = !props?.project?.team?.id
              if (text === 'Dataset')
                isDisable = !(datasetsLen > 0 && props?.project?.datasets[0].dataset.id)
            }
            if (isExternal) {
              if (text === 'Team') destination = `/team?id=${props?.project?.team?.id}`
              if (text === 'Dataset')
                destination = `/dataset?id=${
                  datasetsLen > 0 && props?.project?.datasets[0]?.dataset.id
                }`
            }

            return (
              <>
                <ListItemButton
                  key={text}
                  sx={{ width: '100%' }}
                  selected={tab === index}
                  component={isExternal ? Link : null}
                  to={destination}
                  target={isExternal ? '_blank' : null}
                  onClick={isExternal ? null : () => setTab(index)}
                  disabled={isDisable}
                >
                  <ListItemText primary={text} />
                  {isExternal && (
                    <IconButton size="small">
                      <LaunchIcon />
                    </IconButton>
                  )}
                </ListItemButton>
                <Divider key={`${text}_div`} />
              </>
            )
          })}
        </List>
      </Collapse>
    </ListComponent>
  )

  const saveNameProject = (editText) => {
    props.updateNameProject(props.project.id, editText)
  }

  return (
    <div
      ref={containerProject}
      style={{
        height: '100%',
        transition: 'margin-left .5s',
        padding: 0,
        marginLeft: open ? '127px' : '45px'
      }}
    >
      <Content>
        {tabs[tab] !== TABS.VISUALIZER && (
          <ContainerTitle
            style={{
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: open ? 'calc(100% - 127px)' : 'calc(100% - 45px)'
            }}
          >
            <EditableTextField
              sx={{ margin: '8px 1px' }}
              value={props.project.name}
              onSaveClicked={saveNameProject}
            />
            <IconButton
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => props.getProject(idProject)}
            >
              <ReplayIcon />
            </IconButton>
            {HEADER[tabs[tab]]}
          </ContainerTitle>
        )}
        {props.project.loading && <LinearProgress />}
        {tabs[tab] !== TABS.VISUALIZER && <Divider sx={{ marginBottom: '15px' }} />}
        <div style={{ paddingTop: '0px', height: '100%' }}>{renderTab()}</div>
      </Content>
      <Drawer variant="permanent" sx={{ position: 'absolute', zIndex: '4' }} open={open}>
        {sideMenu}
      </Drawer>
    </div>
  )
}

const mapStateToProps = (state) => ({
  project: state.project
})

const mapDispatchToProps = {
  getProject: p.getProject,
  cleanProject: p.cleanProject,
  updateNameProject: p.updateNameProject
}

export default connect(mapStateToProps, mapDispatchToProps)(Project)
