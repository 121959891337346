import React, { useContext } from 'react'

const MachineContext = React.createContext({})
const { Provider, Consumer } = MachineContext

export default function MachineProvider(props) {
  return <Provider value={props.value}>{props.children}</Provider>
}

const useLabelerMachine = () => {
  const context = useContext(MachineContext)
  if (context === undefined) {
    throw new Error('useLabelerMachine error')
  }
  return context
}

export { MachineContext, MachineProvider, Consumer as MachineConsumer, useLabelerMachine }
