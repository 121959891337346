// import { IntercomAPI } from 'react-intercom'

// Utils
/**
 * Send User to Analitycs.
 *
 * @param user The user to Identify.
 * @returns void.
 */
export async function sandUser(user) {
  if (!window.analytics || !checkEnviroment().send) return
  window.analytics.identify(user.userId, {
    name: user.name,
    email: user.email
  })
  // supportUser(user)
}

/**
 * Send a page view to Analitycs.
 *
 * @param path The path of the new page.
 * @returns void.
 */
export async function sandPage(path) {
  if (!window.analytics || !checkEnviroment().send) return
  window.analytics.page(path)
}

/**
 * Send a event to Analitycs.
 *
 * @param name The name of the event.
 * @param data The aditional props of the event.
 * @returns void.
 */
export async function sandEvent(name, data) {
  const check = checkEnviroment()
  if (!window.analytics || !check.send) return

  let d = {}
  if (data) d = { ...data }
  if (typeof data === 'string') d = { data }

  d.extra = check.extra
  d.path = window.location.pathname
  window.analytics.track(name, d)
}

function checkEnviroment() {
  switch (window.enviroment) {
    case 'prod':
      return { send: true, extra: null }
    case 'other':
      return { send: true, extra: window.location.hostname }
    case 'dev':
      return { send: false, extra: 'dev' }
    case 'local':
    case 'release-candidate':
    default:
      return { send: true, extra: 'other' }
  }
}

// -- Events --
// Project
export async function sandProjectCreationStart() {
  sandEvent('Project Creation Started', {})
}

export async function sandProjectNameSet({ name }) {
  sandEvent('Project Name Set', { project_name: name })
}

export async function sandProjectNameEdited({ id, name }) {
  sandEvent('Project Name Set', { project_id: id, project_name: name })
}

export async function sandProjectCreationCanceled({ name }) {
  sandEvent('Project Creation Canceled', { project_name: name })
}

export async function sandProjectCreated({ name, id, categories, datasetId, teamId }) {
  sandEvent('Project Created', {
    project_name: name,
    project_id: id,
    categories,
    dataset_id: datasetId,
    team_id: teamId
  })
}

// Data
export async function sandDataRequestFromProject({ name, id, type, format }) {
  sandEvent('Data Request from Project', {
    project_name: name,
    project_id: id,
    export_type: type,
    export_format: format
  })
}

export async function sandDataDownloadFromProject({ name, id, type, format }) {
  sandEvent('Data Download from Project', {
    project_name: name,
    project_id: id,
    export_type: type,
    export_format: format
  })
}

// Dataset
export async function sandDatasetCreationStart() {
  sandEvent('Dataset Creation Started', {})
}

export async function sandDatasetCreated({ id, name }) {
  sandEvent('Dataset Created', { dataset_id: id, dataset_name: name })
}

export async function sandDatasetNameEdited({ id, name, oldName }) {
  sandEvent('Dataset Name Edited', {
    dataset_id: id,
    dataset_name: name,
    dataset_old_name: oldName
  })
}

export async function sandDeleteDataset({ id, name }) {
  sandEvent('Dataset Deleted', { dataset_id: id, dataset_name: name })
}

// Category
export async function sandCategoryAddedToProject({ projectId, projectName, name, type }) {
  sandEvent('Category Added to Project', {
    category_name: name,
    category_type: type,
    project_id: projectId || null,
    project_name: projectName || null
  })
}

export async function sandCategoryRemovedToProject({ projectId, projectName, name, type }) {
  sandEvent('Category Removed from Project', {
    category_name: name,
    category_type: type,
    project_id: projectId || null,
    project_name: projectName || null
  })
}

export async function sandCategoryMoved({ projectId, projectName, name, type }) {
  sandEvent('Category Moved', {
    category_name: name,
    category_type: type,
    project_id: projectId || null,
    project_name: projectName || null
  })
}

// Team
export async function sandTeamCreationStarted() {
  sandEvent('Team Creation Started', {})
}

export async function sandTeamCreated({ id, name }) {
  sandEvent('Team Created', { team_id: id, team_name: name })
}

// Image
export async function sandImagesAddedToDataset({ id, name, count }) {
  sandEvent('Images Added to Dataset', {
    dataset_id: id || null,
    dataset_name: name || null,
    files_count: count
  })
}

export async function sandImageUploaded({ name, datasetId, datasetName }) {
  sandEvent('Image Uploaded', {
    dataset_id: datasetId,
    dataset_name: datasetName,
    files_name: name
  })
}

export async function sandImageFailed({ name, datasetId, datasetName }) {
  sandEvent('Image Failed', { dataset_id: datasetId, dataset_name: datasetName, files_name: name })
}

// Members
export async function sandMemberAddedToTeam({ id, name, memberId }) {
  sandEvent('Member Added to Team', { team_id: id, team_name: name, member_id: memberId })
}

export async function sandMemberInvitedToTeam({ id, name, memberEmail }) {
  sandEvent('Member Invited to Team', { team_id: id, team_name: name, member_email: memberEmail })
}

export async function sandMemberRemovedToTeam({ id, name, memberId }) {
  sandEvent('Member Removed to Team', { team_id: id, team_name: name, member_id: memberId })
}

// Labeler
export async function sandLabelerViewed({ id }) {
  sandEvent('Labeler Viewed', { project_id: id || null })
}

export async function sandRoleChanged({ id, name, role, oldRole }) {
  sandEvent('Role Changed', { project_id: id, project_name: name, role, old_role: oldRole })
}

// BBox, Poly, Segment, Ellipse, Line, Point
export async function sandLabelStarted({ id, name, type }) {
  sandEvent('Label Started', { project_id: id, project_name: name, type })
}

export async function sandLabelCreated({ id, name, type, count }) {
  sandEvent('Label Created', { project_id: id, project_name: name, type, points_count: count })
}

export async function sandLabelHidden({ id, name, type }) {
  sandEvent('Label Hidden', { project_id: id || null, project_name: name || null, type })
}

export async function sandLabelEddited({ id, name, type }) {
  sandEvent('Label Eddited', { project_id: id, project_name: name, type })
}

export async function sandLabelDeleted({ id, name, type }) {
  sandEvent('Label Deleted', { project_id: id || null, project_name: name || null, type })
}

// DEPRECATED

export async function downloadProjectLabels(data) {
  sandEvent('downloadProjectLabels', data)
}

// INTERCOM
// export async function supportUser (user) {
//   if (!checkEnviroment().send) return
//   IntercomAPI('boot', {
//     name: user.name,
//     email: user.email,
//     created_at: Date.now()
//   })
// }
