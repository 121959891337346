export const createMember = /* GraphQL */ `
  mutation CreateMember($input: CreateMemberInput!) {
    createMember(input: $input) {
      id
      role
      createdAt
      team {
        id
        name
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
        }
        status
        access
        members {
          nextToken
        }
        projects {
          nextToken
        }
        owner
      }
      user {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        projects {
          nextToken
        }
      }
    }
  }
`
export const deleteMember = /* GraphQL */ `
  mutation DeleteMember($input: DeleteMemberInput!) {
    deleteMember(input: $input) {
      id
      role
      createdAt
      team {
        id
        name
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
        }
        status
        access
        members {
          nextToken
        }
        projects {
          nextToken
        }
        owner
      }
      user {
        id
        name
        email
        company
        termsAccepted
        status
        owner
      }
    }
  }
`
export const updateTag = /* GraphQL */ `
  mutation UpdateTag($input: UpdateTagInput!) {
    updateTag(input: $input) {
      id
      height
      width
      key
      done
      accepted
      savedAt
      annotations {
        circles {
          id
          name
          color
          type
          id
          text
        }
        lines {
          id
          name
          color
          type
          text
        }
        points {
          id
          name
          color
          type
          text
        }
        polys {
          id
          name
          color
          type
          text
          classes {
            name
            options
            selected
          }
        }
        rects {
          id
          name
          color
          type
          pos
          parent
          text
          classes {
            name
            options
            selected
          }
        }
        classification {
          name
          options
          selected
        }
      }
      count
      time
      status
      createdAt
      labeler {
        id
        name
        email
        company
        termsAccepted
        status
        owner
      }
      reviewer {
        id
        name
        email
        owner
      }
      image {
        id
        key
        url
        status
        createdAt
        width
        height
      }
      project {
        id
        name
        categories {
          color
          name
          type
        }
        access
        status
        team {
          id
          name
          status
          owner
        }
        user {
          id
          name
          email
          company
          status
          owner
        }
        owner
      }
    }
  }
`
export const updateProject = /* GraphQL */ `
  mutation UpdateProject($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      id
      name
      categories {
        color
        name
        type
        parent
        options
        required
        multiSelect
        classType
      }
      categoriesCount {
        name
        count
        countSaved
        countReviewed
      }
      createdAt
      access
      status
      tagsCount
      datasetSort
      reviewType
      tagsSaveCount
      tagsReviewCount
      imagesSavedCount
      imagesReviewedCount
      team {
        id
        name
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          createdAt
        }
        members {
          items {
            id
            role
            createdAt
            memberTeamId
            user {
              id
              name
              email
              owner
            }
            team {
              id
              name
              user {
                name
                email
              }
            }
          }
        }
        status
        access
        owner
      }
      user {
        id
        name
        email
        company
      }
      datasets {
        items {
          dataset {
            id
            name
            count
          }
          id
        }
        nextToken
      }
      tags {
        items {
          id
          height
          width
          key
          done
          accepted
          count
          time
          status
          createdAt
          tagProjectId
          tagLabelerId
          tagReviewerId
        }
        nextToken
      }
      owner
    }
  }
`
