import React, { Component } from 'react'
import { Dialog, Button, TextField } from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'

class UploadDemoImageDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      uploadedImageURL: ''
    }
    this.setURL = this.setURL.bind(this)
  }

  setURL(e) {
    this.setState({ uploadedImageURL: e.target.value })
  }

  render() {
    return (
      <Dialog open>
        <DialogTitle>Try our Platform with your own Image</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {' '}
            You may tag a busy highway as a demo or upload your own image (don&apos;t worry, we
            won&apos;t save it).{' '}
          </DialogContentText>
          <TextField
            variant="standard"
            fullWidth
            label={'Paste the URL to your image'}
            value={this.state.uploadedImageURL}
            onChange={this.setURL}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" autoFocus onClick={this.props.closeUploadImgDialog}>
            Use Demo Image
          </Button>
          <Button
            color="primary"
            onClick={() => this.props.setUploadImg(this.state.uploadedImageURL)}
            disabled={this.state.uploadedImageURL.length === 0}
          >
            Use Uploaded image
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default UploadDemoImageDialog
