import React, { useRef, useContext, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { connect } from 'react-redux'
import useZoom from '../hooks/useZoom'
import useResizeCategoryCanvas from '../hooks/useResizeCategoryCanvas'
import { LabelerContext } from '../../../context/LabelerContext'
import { DispatchContext } from '../../../context/DispatchContext'
import { useCategoryEventListener } from '../hooks/useCategoryEventListener'
import { useEffectWR } from '../hooks/useEffectWR'
import { useClearCanvas } from '../hooks/useClearCanvas'
import { sandLabelStarted, sandLabelCreated, sandLabelEddited } from '../../../../../io/analytics'
import { useDrawTags } from '../hooks/useDrawTags'
import { useLabelerMachine } from '../../../context/MachineContext'
import AutoboundingBoxes from './AutoboundingBoxes'

const MINIMUM_BOUNDING_BOX_SIZE = 1
const EDIT_POINT_RADIUS = 5

function BoundingboxCanvas({ labeler, panZoom, mouse, project }) {
  const { state: MachineState } = useLabelerMachine()

  const { state } = useContext(LabelerContext)
  const { dispatch } = useContext(DispatchContext)
  const { boundingBoxState, labelerState } = state

  const canvasIncompleteFigures = useRef(null)
  const canvasCompleteFigures = useRef(null)
  const canvasLineGuide = useRef(null)

  const [rect, setRect] = useState(null)
  const editRect = useRef(null)
  const selectedPoint = useRef(null)
  const initialClick = useRef(null)
  const isDrag = useRef(null)
  const GLOBAL_ALPHA = labelerState.canvasSettings[4].value

  useClearCanvas([canvasIncompleteFigures, canvasCompleteFigures, canvasLineGuide], 'bounding_box')

  const { AutoboundingBoxesCanvas, CustomModal } = AutoboundingBoxes({ panZoom, mouse, labeler })

  useEffectWR([labelerState.selectedCategory, labelerState.mode], [deleteIncompleteBoundingBoxes])
  useResizeCategoryCanvas(
    [canvasIncompleteFigures, canvasCompleteFigures, canvasLineGuide],
    'bounding_box'
  )
  useDrawTags(() => {
    drawBounding(true)
  }, 'bounding_box')
  useZoom(
    panZoom,
    mouse.wheel,
    [canvasIncompleteFigures, canvasCompleteFigures, canvasLineGuide],
    drawAll
  )

  useCategoryEventListener('mousedown', mouseDown, labeler.current, 'bounding_box')
  useCategoryEventListener('mouseup', mouseUp, labeler.current, null)
  useCategoryEventListener('mousemove', mouseMove, labeler.current, null)
  useCategoryEventListener('mousedown', mouseRight, labeler.current, null)
  useCategoryEventListener('mousedown', mouseEditLeft, labeler.current, null) // EDIT

  useEffectWR([boundingBoxState.redraw], [drawAll])
  useEffectWR([GLOBAL_ALPHA], [drawAll])
  function drawAll() {
    if (MachineState.value === 'readyForLabeling') {
      drawBounding(true)
    }
  }
  function mouseDown(e) {
    if (labelerState.selectedCategory.AI !== 'none') {
      return
    }
    if (e.button === 0) {
      if (labelerState.mode === 'draw') {
        addBoundingbox((mouse.x - panZoom.x) / panZoom.scale, (mouse.y - panZoom.y) / panZoom.scale)
      }
    }
  }

  function mouseEditLeft(e) {
    if (e.button === 0) {
      if (labelerState.mode === 'edit') {
        clickRect((mouse.x - panZoom.x) / panZoom.scale, (mouse.y - panZoom.y) / panZoom.scale)
      }
    }
  }

  function clickRect(x, y) {
    const points = boundingBoxState.editPoints
    const { rect, index } = clickedOnABoundingbox(x, y)

    for (let pointIndex = 0; pointIndex < points.length; pointIndex++) {
      const point = points[pointIndex]
      const distance = Math.sqrt(Math.pow(x - point.x, 2) + Math.pow(y - point.y, 2))
      const cornerArch = EDIT_POINT_RADIUS / panZoom.scale
      if (distance <= cornerArch) {
        isDrag.current = true
        drawBounding(true)
        selectedPoint.current = pointIndex
        return true
      }
    }
    if (rect) {
      dispatch({ type: 'setSelectedBounding', payload: index })
      initialClick.current = { x, y }
      editRect.current = rect
      selectedPoint.current = null
      return true
    }
    editRect.current = null
    initialClick.current = null
    dispatch({ type: 'unselectBounding' })
    return false
  }

  function clickedOnABoundingbox(posx, posy) {
    for (let index = 0; index < boundingBoxState.tags.length; index++) {
      const rect = boundingBoxState.tags[index]
      if (
        boundingBoxState.tags[index].show &&
        rect.pos[0] - 3 / panZoom.scale < posx &&
        posx < rect.pos[0] + Math.abs(rect.pos[2]) + 3 / panZoom.scale &&
        rect.pos[1] - 3 / panZoom.scale < posy &&
        posy < rect.pos[1] + Math.abs(rect.pos[3]) + 3 / panZoom.scale
      )
        return { index, rect }
    }
    return { index: null, rect: null }
  }

  function mouseRight() {}

  function mouseUp(e) {
    if (labelerState.mode === 'edit') {
      if (e.button === 0) {
        confirmEdit((mouse.x - panZoom.x) / panZoom.scale, (mouse.y - panZoom.y) / panZoom.scale)
        isDrag.current = false
        sandLabelEddited({ type: 'BBox', id: project.id, name: project.name })
        dispatch({ type: 'setSaveTags' })
      }
    }
  }

  function mouseMove() {
    drawMoveRect((mouse.x - panZoom.x) / panZoom.scale, (mouse.y - panZoom.y) / panZoom.scale)
    if (labelerState.selectedCategory.type === 'bounding_box' && labelerState.mode === 'draw')
      drawLineGuide((mouse.x - panZoom.x) / panZoom.scale, (mouse.y - panZoom.y) / panZoom.scale)
    if (labelerState.mode === 'edit') {
      if (mouse.button) {
        updateBoundingbox(
          (mouse.x - panZoom.x) / panZoom.scale,
          (mouse.y - panZoom.y) / panZoom.scale
        )
      }
    }
  }

  const updateBoundingbox = (x, y) => {
    if (editRect.current) {
      const canvas = canvasIncompleteFigures.current
      const ctx = canvas.getContext('2d')
      ctx.beginPath()
      ctx.setTransform(1, 0, 0, 1, 0, 0)
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.setTransform(panZoom.scale, 0, 0, panZoom.scale, panZoom.x, panZoom.y)
      ctx.strokeStyle = '#000000'
      ctx.fillStyle = '#ffffff40'

      if (selectedPoint.current !== null) {
        const bounding = { ...resizeBoundingbox(selectedPoint.current, x, y) }
        bounding.pos = [
          bounding.pos[0],
          bounding.pos[1],
          bounding.pos[2] <= 0 ? MINIMUM_BOUNDING_BOX_SIZE / panZoom.scale : bounding.pos[2],
          bounding.pos[3] <= 0 ? MINIMUM_BOUNDING_BOX_SIZE / panZoom.scale : bounding.pos[3]
        ]
        ctx.fillRect(
          editRect.current.pos[0],
          editRect.current.pos[1],
          editRect.current.pos[2],
          editRect.current.pos[3]
        )
        editRect.current = { ...editRect.current, pos: [...bounding.pos] }
      } else {
        if (initialClick.current) {
          const a =
            x -
            (initialClick.current.x - boundingBoxState.tags[boundingBoxState.selectedTag].pos[0])
          const b =
            y -
            (initialClick.current.y - boundingBoxState.tags[boundingBoxState.selectedTag].pos[1])
          ctx.stroke()
          ctx.fillRect(a, b, editRect.current.pos[2], editRect.current.pos[3])
          const pos = [...editRect.current.pos]
          pos[0] = a
          pos[1] = b
          editRect.current = { ...editRect.current, pos: [...pos] }
        }
      }
    }
  }

  const compareSize = (bb1, bb2) => {
    const area1 = bb1[2] * bb1[3]
    const area2 = bb2[2] * bb2[3]
    if (area1 > area2) return true
    return false
  }

  const resizeBoundingbox = (point, x, y) => {
    let boundingbox
    let lx, ly, lw, lh
    switch (point) {
      case 0:
        lx = x
        ly = y
        lw = editRect.current.pos[2] + (editRect.current.pos[0] - x)
        lh = editRect.current.pos[3] + (editRect.current.pos[1] - y)

        boundingbox = {
          ...editRect.current,
          pos: [
            lx < 0 ? 0 : lx,
            ly < 0 ? 0 : ly,
            x < 0 ? lw - Math.abs(x) : lw,
            y < 0 ? lh - Math.abs(y) : lh
          ]
        }

        if (lw < MINIMUM_BOUNDING_BOX_SIZE || lh < MINIMUM_BOUNDING_BOX_SIZE)
          if (!compareSize(boundingbox.pos, editRect.current.pos)) {
            return editRect.current
          }

        return boundingbox
      case 1:
        lx = x
        ly = editRect.current.pos[1]
        lw = editRect.current.pos[2] + (editRect.current.pos[0] - x)
        lh = y - editRect.current.pos[1]

        boundingbox = {
          ...editRect.current,
          pos: [
            lx < 0 ? 0 : lx,
            ly,
            lx < 0 ? lw - Math.abs(x) : lw,

            y > labelerState.image.props.h
              ? labelerState.image.props.h - editRect.current.pos[1]
              : lh
          ]
        }

        if (lw < MINIMUM_BOUNDING_BOX_SIZE || lh < MINIMUM_BOUNDING_BOX_SIZE)
          if (!compareSize(boundingbox.pos, editRect.current.pos)) {
            return editRect.current
          }

        return boundingbox
      case 2:
        lx = editRect.current.pos[0]
        ly = y
        lw = x - editRect.current.pos[0]
        lh = editRect.current.pos[3] + (editRect.current.pos[1] - y)

        boundingbox = {
          ...editRect.current,
          pos: [
            lx,
            ly < 0 ? 0 : ly,
            x > labelerState.image.props.w
              ? labelerState.image.props.w - editRect.current.pos[0]
              : lw,
            y < 0 ? lh - Math.abs(y) : lh
          ]
        }

        if (lw < MINIMUM_BOUNDING_BOX_SIZE || lh < MINIMUM_BOUNDING_BOX_SIZE)
          if (!compareSize(boundingbox.pos, editRect.current.pos)) {
            return editRect.current
          }

        return boundingbox
      case 3:
        lx = editRect.current.pos[0]
        ly = editRect.current.pos[1]
        lw = x - editRect.current.pos[0]
        lh = y - editRect.current.pos[1]

        boundingbox = {
          ...editRect.current,
          pos: [
            lx,
            ly,
            x > labelerState.image.props.w
              ? labelerState.image.props.w - editRect.current.pos[0]
              : lw,
            y > labelerState.image.props.h
              ? labelerState.image.props.h - editRect.current.pos[1]
              : lh
          ]
        }

        if (lw < MINIMUM_BOUNDING_BOX_SIZE || lh < MINIMUM_BOUNDING_BOX_SIZE)
          if (!compareSize(boundingbox.pos, editRect.current.pos)) {
            return editRect.current
          }

        return boundingbox
      case 4:
        lx = x
        ly = editRect.current.pos[1]
        lw = editRect.current.pos[2] + (editRect.current.pos[0] - x)
        lh = editRect.current.pos[3]

        boundingbox = {
          ...editRect.current,
          pos: [lx < 0 ? 0 : lx, ly, x < 0 ? lw - Math.abs(x) : lw, lh]
        }

        if (lw < MINIMUM_BOUNDING_BOX_SIZE || lh < MINIMUM_BOUNDING_BOX_SIZE)
          if (!compareSize(boundingbox.pos, editRect.current.pos)) {
            return editRect.current
          }

        return boundingbox
      case 5:
        lx = editRect.current.pos[0]
        ly = editRect.current.pos[1]
        lw = x - editRect.current.pos[0]
        lh = editRect.current.pos[3]

        boundingbox = {
          ...editRect.current,
          pos: [
            lx,
            ly,
            x > labelerState.image.props.w
              ? labelerState.image.props.w - editRect.current.pos[0]
              : lw,
            lh
          ]
        }

        if (lw < MINIMUM_BOUNDING_BOX_SIZE || lh < MINIMUM_BOUNDING_BOX_SIZE)
          if (!compareSize(boundingbox.pos, editRect.current.pos)) {
            return editRect.current
          }
        return boundingbox
      case 6:
        lx = editRect.current.pos[0]
        ly = y
        lw = editRect.current.pos[2]
        lh = editRect.current.pos[3] + (editRect.current.pos[1] - y)

        boundingbox = {
          ...editRect.current,
          pos: [lx, ly < 0 ? 0 : ly, lw, y < 0 ? lh - Math.abs(y) : lh]
        }

        if (lw < MINIMUM_BOUNDING_BOX_SIZE || lh < MINIMUM_BOUNDING_BOX_SIZE)
          if (!compareSize(boundingbox.pos, editRect.current.pos)) {
            return editRect.current
          }

        return boundingbox
      case 7:
        lx = editRect.current.pos[0]
        ly = editRect.current.pos[1]
        lw = editRect.current.pos[2]
        lh = y - editRect.current.pos[1]

        boundingbox = {
          ...editRect.current,
          pos: [
            lx,
            ly,
            lw,
            y > labelerState.image.props.h
              ? labelerState.image.props.h - editRect.current.pos[1]
              : lh
          ]
        }

        if (lw < MINIMUM_BOUNDING_BOX_SIZE || lh < MINIMUM_BOUNDING_BOX_SIZE)
          if (!compareSize(boundingbox.pos, editRect.current.pos)) {
            return editRect.current
          }

        return boundingbox
    }
    return null
  }

  const addBoundingbox = (x, y) => {
    if (rect) {
      const boundingbox = labelerState.selectedCategory
      const x1 = rect.w < 0 ? (rect.x + rect.w < 0 ? 0 : rect.x + rect.w) : rect.x
      const y1 = rect.h < 0 ? (rect.y + rect.h < 0 ? 0 : rect.y + rect.h) : rect.y
      const w =
        rect.x + rect.w < 0 ? Math.abs(rect.w) - Math.abs(0 - (rect.x + rect.w)) : Math.abs(rect.w)
      const h =
        rect.y + rect.h < 0 ? Math.abs(rect.h) - Math.abs(0 - (rect.y + rect.h)) : Math.abs(rect.h)
      if (w > 0 && h > 0 && w > MINIMUM_BOUNDING_BOX_SIZE && h > MINIMUM_BOUNDING_BOX_SIZE) {
        sandLabelCreated({ type: 'BBox', id: project.id, name: project.name })
        const classes = findClasses()
        const newBoundingBox = {
          id: uuidv4(),
          classes,
          parent: rect.parent,
          text: null,
          name: boundingbox.name,
          color: boundingbox.color,
          type: 'bounding_box',
          pos: [x1, y1, w, h],
          hover: false,
          show: true
        }
        dispatch({ type: 'addBoundingbox', payload: newBoundingBox })
        drawBounding(false, newBoundingBox)
        dispatch({ type: 'setSaveTags' })
        setRect(null)
      }
    } else {
      sandLabelStarted({ type: 'BBox', id: project.id, name: project.name })

      const nx = x < 0 ? 0 : x > labelerState.image.props.w ? labelerState.image.props.w : x
      const ny = y < 0 ? 0 : y > labelerState.image.props.h ? labelerState.image.props.h : y
      if (labelerState.selectedCategory.parent === null) {
        setRect({
          x: nx,
          y: ny,
          w: 0,
          h: 0,
          color: labelerState.selectedCategory.color,
          parent: null
        })
      } else {
        if (boundingBoxState.lastTag > -1) {
          setRect({
            x: nx,
            y: ny,
            w: 0,
            h: 0,
            color: labelerState.selectedCategory.color,
            parent: boundingBoxState.tags[boundingBoxState.lastTag].id
          })
        }
      }
    }
  }
  function drawBounding(redraw = false, bounding, boundingboxes = boundingBoxState.tags) {
    const canvas = canvasCompleteFigures.current
    const ctx = canvas.getContext('2d')
    const canvas2 = canvasIncompleteFigures.current
    const ctx1 = canvas2.getContext('2d')
    if (redraw) {
      ctx.beginPath()
      ctx.globalCompositeOperation = 'source-over'
      ctx.setLineDash([])
      ctx1.setTransform(1, 0, 0, 1, 0, 0)
      ctx1.clearRect(0, 0, canvas.width, canvas.height)
      ctx.setTransform(1, 0, 0, 1, 0, 0)
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.setTransform(panZoom.scale, 0, 0, panZoom.scale, panZoom.x, panZoom.y)
      ctx.lineWidth = 0.8 / panZoom.scale
      boundingboxes.forEach((boundingBox) => {
        if (boundingBox.show || boundingBox.show === undefined) {
          ctx.strokeStyle = boundingBox.hover ? '#000000' : boundingBox.color
          ctx.globalAlpha = GLOBAL_ALPHA
          ctx.fillStyle = boundingBox.hover ? '#ffffff' : boundingBox.color
          ctx.beginPath()
          ctx.rect(boundingBox.pos[0], boundingBox.pos[1], boundingBox.pos[2], boundingBox.pos[3])
          ctx.stroke()
          ctx.fill()
        }
      })
      if (boundingBoxState.selectedTag > -1 && isDrag.current === false) {
        if (
          boundingboxes[boundingBoxState.selectedTag].show ||
          !Object.prototype.hasOwnProperty.call(boundingboxes[boundingBoxState.selectedTag], 'show')
        ) {
          ctx.strokeStyle = '#000'
          ctx.fillStyle = '#fff'
          ctx.beginPath()
          ctx.globalCompositeOperation = 'source-over'
          const cornerArch = EDIT_POINT_RADIUS / panZoom.scale

          ctx.arc(
            boundingboxes[boundingBoxState.selectedTag].pos[0],
            boundingboxes[boundingBoxState.selectedTag].pos[1],
            cornerArch,
            2 * Math.PI,
            0
          )
          ctx.lineTo(
            boundingboxes[boundingBoxState.selectedTag].pos[0],
            boundingboxes[boundingBoxState.selectedTag].pos[1]
          )
          ctx.stroke()
          ctx.fill()

          ctx.beginPath()
          ctx.arc(
            boundingboxes[boundingBoxState.selectedTag].pos[0],
            boundingboxes[boundingBoxState.selectedTag].pos[1] +
              boundingboxes[boundingBoxState.selectedTag].pos[3],
            cornerArch,
            2 * Math.PI,
            0
          )
          ctx.lineTo(
            boundingboxes[boundingBoxState.selectedTag].pos[0],
            boundingboxes[boundingBoxState.selectedTag].pos[1] +
              boundingboxes[boundingBoxState.selectedTag].pos[3]
          )
          ctx.stroke()
          ctx.fill()

          ctx.beginPath()
          ctx.arc(
            boundingboxes[boundingBoxState.selectedTag].pos[0] +
              boundingboxes[boundingBoxState.selectedTag].pos[2],
            boundingboxes[boundingBoxState.selectedTag].pos[1],
            cornerArch,
            2 * Math.PI,
            0
          )
          ctx.lineTo(
            boundingboxes[boundingBoxState.selectedTag].pos[0] +
              boundingboxes[boundingBoxState.selectedTag].pos[2],
            boundingboxes[boundingBoxState.selectedTag].pos[1]
          )
          ctx.stroke()
          ctx.fill()
          ctx.beginPath()
          ctx.arc(
            boundingboxes[boundingBoxState.selectedTag].pos[0] +
              boundingboxes[boundingBoxState.selectedTag].pos[2],
            boundingboxes[boundingBoxState.selectedTag].pos[1] +
              boundingboxes[boundingBoxState.selectedTag].pos[3],
            cornerArch,
            2 * Math.PI,
            0
          )

          ctx.lineTo(
            boundingboxes[boundingBoxState.selectedTag].pos[0] +
              boundingboxes[boundingBoxState.selectedTag].pos[2],
            boundingboxes[boundingBoxState.selectedTag].pos[1] +
              boundingboxes[boundingBoxState.selectedTag].pos[3]
          )
          ctx.stroke()
          ctx.fill()

          ctx.beginPath()
          ctx.arc(
            boundingboxes[boundingBoxState.selectedTag].pos[0],
            boundingboxes[boundingBoxState.selectedTag].pos[1] +
              boundingboxes[boundingBoxState.selectedTag].pos[3] / 2,
            cornerArch,
            2 * Math.PI,
            0
          )
          ctx.lineTo(
            boundingboxes[boundingBoxState.selectedTag].pos[0],
            boundingboxes[boundingBoxState.selectedTag].pos[1] +
              boundingboxes[boundingBoxState.selectedTag].pos[3] / 2
          )
          ctx.stroke()
          ctx.fill()
          ctx.beginPath()
          ctx.arc(
            boundingboxes[boundingBoxState.selectedTag].pos[0] +
              boundingboxes[boundingBoxState.selectedTag].pos[2],
            boundingboxes[boundingBoxState.selectedTag].pos[1] +
              boundingboxes[boundingBoxState.selectedTag].pos[3] / 2,
            cornerArch,
            2 * Math.PI,
            0
          )
          ctx.lineTo(
            boundingboxes[boundingBoxState.selectedTag].pos[0] +
              boundingboxes[boundingBoxState.selectedTag].pos[2],
            boundingboxes[boundingBoxState.selectedTag].pos[1] +
              boundingboxes[boundingBoxState.selectedTag].pos[3] / 2
          )
          ctx.stroke()
          ctx.fill()
          ctx.beginPath()
          ctx.arc(
            boundingboxes[boundingBoxState.selectedTag].pos[0] +
              boundingboxes[boundingBoxState.selectedTag].pos[2] / 2,
            boundingboxes[boundingBoxState.selectedTag].pos[1],
            cornerArch,
            2 * Math.PI,
            0
          )
          ctx.lineTo(
            boundingboxes[boundingBoxState.selectedTag].pos[0] +
              boundingboxes[boundingBoxState.selectedTag].pos[2] / 2,
            boundingboxes[boundingBoxState.selectedTag].pos[1]
          )
          ctx.stroke()
          ctx.fill()

          ctx.beginPath()
          ctx.arc(
            boundingboxes[boundingBoxState.selectedTag].pos[0] +
              boundingboxes[boundingBoxState.selectedTag].pos[2] / 2,
            boundingboxes[boundingBoxState.selectedTag].pos[1] +
              boundingboxes[boundingBoxState.selectedTag].pos[3],
            cornerArch,
            2 * Math.PI,
            0
          )
          ctx.lineTo(
            boundingboxes[boundingBoxState.selectedTag].pos[0] +
              boundingboxes[boundingBoxState.selectedTag].pos[2] / 2,
            boundingboxes[boundingBoxState.selectedTag].pos[1] +
              boundingboxes[boundingBoxState.selectedTag].pos[3]
          )
          ctx.stroke()
          ctx.fill()
          ctx.globalAlpha = 1
          ctx.beginPath()
        }
      }
    } else {
      ctx1.setTransform(1, 0, 0, 1, 0, 0)
      ctx1.clearRect(0, 0, canvas2.width, canvas2.height)
      ctx.beginPath()
      ctx.strokeStyle = bounding.color
      ctx.fillStyle = bounding.color + '4D'
      ctx.lineWidth = 0.8 / panZoom.scale
      ctx.rect(bounding.pos[0], bounding.pos[1], bounding.pos[2], bounding.pos[3])
      ctx.stroke()
      ctx.fill()
      ctx.beginPath()
    }
  }
  const drawMoveRect = (w, h) => {
    if (rect) {
      const canvas2 = canvasIncompleteFigures.current
      const ctx1 = canvas2.getContext('2d')
      ctx1.beginPath()
      ctx1.setTransform(1, 0, 0, 1, 0, 0)
      ctx1.clearRect(0, 0, canvas2.width, canvas2.height)
      ctx1.setTransform(panZoom.scale, 0, 0, panZoom.scale, panZoom.x, panZoom.y)
      ctx1.lineWidth = 0.8 / panZoom.scale
      ctx1.rect(rect.x, rect.y, w - rect.x, h - rect.y)
      ctx1.strokeStyle = rect.color
      ctx1.fillStyle = rect.color + '4D'
      ctx1.stroke()
      ctx1.fill()

      const varw = w > labelerState.image.props.w ? labelerState.image.props.w - rect.x : w - rect.x
      const varh = h > labelerState.image.props.h ? labelerState.image.props.h - rect.y : h - rect.y

      setRect({ ...rect, w: varw, h: varh })
    }
  }
  function drawLineGuide(x, y) {
    if (x > 0 && x < labelerState.image.props.w && y > 0 && y < labelerState.image.props.h) {
      const canvas = canvasLineGuide.current
      const ctx = canvas.getContext('2d')
      ctx.setTransform(1, 0, 0, 1, 0, 0)
      ctx.globalAlpha = 1
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.setTransform(panZoom.scale, 0, 0, panZoom.scale, panZoom.x, panZoom.y)
      ctx.beginPath()
      ctx.setLineDash([])
      ctx.moveTo(x, 0)
      ctx.lineTo(x, labelerState.image.props.h)
      ctx.lineWidth = 1 / panZoom.scale
      ctx.strokeStyle = '#000000'
      ctx.stroke()
      ctx.beginPath()

      ctx.setLineDash([3 / panZoom.scale, 3 / panZoom.scale])
      ctx.moveTo(x, 0)
      ctx.lineTo(x, labelerState.image.props.h)
      ctx.lineWidth = 1 / panZoom.scale
      ctx.strokeStyle = '#ffffff'
      ctx.stroke()
      ctx.beginPath()

      ctx.setLineDash([])
      ctx.moveTo(0, y)
      ctx.lineTo(labelerState.image.props.w, y)
      ctx.lineWidth = 1 / panZoom.scale
      ctx.strokeStyle = '#000000'
      ctx.stroke()
      ctx.beginPath()

      ctx.setLineDash([3 / panZoom.scale, 3 / panZoom.scale])
      ctx.moveTo(0, y)
      ctx.lineTo(labelerState.image.props.w, y)
      ctx.lineWidth = 1 / panZoom.scale
      ctx.strokeStyle = '#ffffff'
      ctx.stroke()
      if (boundingBoxState.lastTag > -1 && boundingBoxState.containedChildrens) {
        const tag = boundingBoxState.tags[boundingBoxState.lastTag]
        ctx.beginPath()
        ctx.fillStyle = '#bf9b21'
        ctx.arc(tag.pos[0], tag.pos[1], 6 / panZoom.scale, 0, 2 * Math.PI)
        ctx.fill()
      }
    }
  }
  function findClasses() {
    const clas = labelerState.categories.filter(
      (obj) => obj.parent === labelerState.selectedCategory.name && obj.type === 'classification'
    )
    return clas.map((obj) => ({
      name: obj.name,
      options: obj.options,
      selected: '',
      values: [],
      required: obj.required,
      multiSelect: obj.multiSelect
    }))
  }

  function confirmEdit() {
    if (editRect.current) {
      dispatch({
        type: 'setBoundingbox',
        payload: { index: boundingBoxState.selectedTag, boundingbox: editRect.current }
      })
      initialClick.current = null
    }
  }

  function deleteIncompleteBoundingBoxes() {
    const canvas = canvasIncompleteFigures.current
    const ctx = canvas.getContext('2d')
    const canvas1 = canvasLineGuide.current
    const ctx1 = canvas1.getContext('2d')

    ctx.setTransform(1, 0, 0, 1, 0, 0)
    ctx.globalAlpha = 1
    ctx.setTransform(1, 0, 0, 1, 0, 0)
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    ctx.setTransform(panZoom.scale, 0, 0, panZoom.scale, panZoom.x, panZoom.y)

    ctx1.setTransform(1, 0, 0, 1, 0, 0)
    ctx1.globalAlpha = 1
    ctx1.setTransform(1, 0, 0, 1, 0, 0)
    ctx1.clearRect(0, 0, canvas.width, canvas.height)
    ctx1.setTransform(panZoom.scale, 0, 0, panZoom.scale, panZoom.x, panZoom.y)
    setRect(null)
  }

  return (
    <>
      <canvas className="layout" ref={canvasCompleteFigures} />
      <canvas className="layout" ref={canvasIncompleteFigures} />
      <canvas style={{ cursor: labelerState.cursor }} className="layout" ref={canvasLineGuide} />
      {AutoboundingBoxesCanvas}
      {CustomModal}
    </>
  )
}

const mapStateToProps = (state) => ({
  project: state.project
})

export default connect(mapStateToProps, {})(BoundingboxCanvas)
