import React, { useState } from 'react'
import { Drawer } from '@mui/material'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { FiltersProvider } from './context/FiltersContext'
import { FiltersReducer } from './context/filtersReducer'
import TopFilter from './components/topFilter/TopFilter'
import VisualizerGrid from './components/visualizerGrid/VisualizerGrid'
import VisualizerToolbar from './components/visualizerToolbar/VisualizerToolbar'
import LateralFilter from './components/lateralFilter/LateralFilter'
import FilterChips from './components/filterChips/FilterChips'
import CompareLabelsModal from './components/compareLabelsModal/CompareLabelsModal'

const queryClient = new QueryClient()

function Visualizer({ role }) {
  const { filtersState, filtersDispatch } = FiltersReducer()

  const [collapse, setCollapse] = useState(true)

  return (
    <QueryClientProvider client={queryClient}>
      <FiltersProvider value={{ state: filtersState, dispatch: filtersDispatch }}>
        <div style={{ marginTop: '35px' }}>
          {/* TopFilter */}
          <TopFilter />
          <div
            style={{
              height: '80vh',
              marginRight: collapse ? '240px' : '40px',
              transition: 'margin-right 0.75s ease'
            }}
          >
            <FilterChips />
            <VisualizerGrid />
          </div>
          {/* LateralFilter */}
          <Drawer
            variant="permanent"
            sx={{
              flexShrink: 0,
              overflowX: 'hidden',
              '& .MuiDrawer-paper': {
                maxWidth: `${collapse ? '250px' : '65px'}`,
                paddingTop: '58px',
                boxSizing: 'border-box',
                transition: 'max-width 0.75s ease-in-out'
              }
            }}
            anchor="right"
          >
            <LateralFilter collapse={collapse} setCollapse={setCollapse} />
          </Drawer>
          {/* Toolbar */}
          <VisualizerToolbar
            sx={{
              top: 'auto',
              bottom: 0,
              width: `calc(100% - ${collapse ? '250px' : '65px'})`,
              position: 'sticky',
              backgroundColor: 'background.paper',
              transition: 'width 0.75s ease-in-out',
              overflow: 'hidden'
            }}
            role={role}
          />
        </div>
        {filtersState.selectedImage && <CompareLabelsModal />}
      </FiltersProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  )
}

export default Visualizer
