import { useEffect, useContext, useState } from 'react'
import { LabelerContext } from '../../containers/labeler/context/LabelerContext'

export default function useImage() {
  const [image, setImage] = useState(null)
  const {
    state: {
      labelerState: {
        image: { images, index: imageIndex, currentImage }
      }
    }
  } = useContext(LabelerContext)

  useEffect(() => {
    setImage(currentImage)
  }, [imageIndex, images, currentImage])

  return image ? { ...image, index: imageIndex } : null
}
