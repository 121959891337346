import React, { useRef, useContext, useState } from 'react'
import useZoom from '../hooks/useZoom'
import useResizeCategoryCanvas from '../hooks/useResizeCategoryCanvas'
import { LabelerContext } from '../../../context/LabelerContext'
import { DispatchContext } from '../../../context/DispatchContext'
import { v4 as uuidv4 } from 'uuid'
import { useCategoryEventListener } from '../hooks/useCategoryEventListener'
import { useEffectWR } from '../hooks/useEffectWR'
import { useClearCanvas } from '../hooks/useClearCanvas'
import { useDrawTags } from '../hooks/useDrawTags'

export default function LandmarkCanvas({ labeler, panZoom, mouse }) {
  const { state } = useContext(LabelerContext)
  const { dispatch } = useContext(DispatchContext)
  const { landmarkState, labelerState } = state

  const [editLandmark, setEditLandmark] = useState(null)
  const [template, setTemplate] = useState([])
  const canvasIncompleteFigures = useRef(document.createElement('canvas', null, null))
  const canvasCompleteFigures = useRef(document.createElement('canvas', null, null))
  const canvasLineGuide = useRef(document.createElement('canvas', null, null))

  useResizeCategoryCanvas(
    [canvasIncompleteFigures, canvasCompleteFigures, canvasLineGuide],
    'point'
  )
  useDrawTags(() => {
    drawPoints(true)
  }, 'point')
  useZoom(
    panZoom,
    mouse.wheel,
    [canvasIncompleteFigures, canvasCompleteFigures, canvasLineGuide],
    () => {
      drawPoints(true)
    }
  )

  useCategoryEventListener('mousedown', mouseDown, labeler.current, 'point')
  useCategoryEventListener('mouseup', mouseUp, labeler.current, 'point')
  useCategoryEventListener('mousemove', mouseMove, labeler.current, 'point')
  useCategoryEventListener('wheel', mouseWheel, labeler.current, 'point')
  useEffectWR([landmarkState.redraw], [() => drawPoints(true)])
  useEffectWR([landmarkState.person], [handleTemplate])
  useClearCanvas([canvasIncompleteFigures, canvasCompleteFigures, canvasLineGuide], 'point')

  function mouseDown(e) {
    if (e.button === 0) {
      if (labelerState.mode === 'draw') {
        addPointInLandmark(
          (mouse.x - panZoom.x) / panZoom.scale,
          (mouse.y - panZoom.y) / panZoom.scale
        )
        return
      }
      if (labelerState.mode === 'edit') {
        clickPoint((mouse.x - panZoom.x) / panZoom.scale, (mouse.y - panZoom.y) / panZoom.scale)
      }
      if (labelerState.mode === 'template') {
        confirmTemplate()
      }
    }
  }
  function mouseUp(e) {
    if (labelerState.mode === 'edit') {
      if (e.button === 0) {
        confirmEdit((mouse.x - panZoom.x) / panZoom.scale, (mouse.y - panZoom.y) / panZoom.scale)
      }
    }
  }
  function mouseMove() {
    drawLineGuide((mouse.x - panZoom.x) / panZoom.scale, (mouse.y - panZoom.y) / panZoom.scale)
    if (labelerState.mode === 'edit') {
      if (mouse.button) {
        moveLandmark((mouse.x - panZoom.x) / panZoom.scale, (mouse.y - panZoom.y) / panZoom.scale)
      }
    }
    if (labelerState.mode === 'template') {
      resizeTemplate((mouse.x - panZoom.x) / panZoom.scale, (mouse.y - panZoom.y) / panZoom.scale)
    }
  }
  function mouseWheel(e) {
    if (labelerState.mode === 'template') {
      e.stopPropagation()
      let ms = 0
      ms = ms + -e.deltaY
      let scale = 1
      if (ms !== 0) {
        scale = ms < 0 ? 1 / 1.01 : 1.01
        ms = ms * 0.8
        if (Math.abs(ms) < 1) {
          ms = 0
        }
      }
      scaleTemplatePoints(scale)
    }
  }
  const addPointInLandmark = (x, y) => {
    if (x > 0 && x < labelerState.image.props.w && y > 0 && y < labelerState.image.props.h) {
      const landmarkType = labelerState.selectedCategory
      const landmark = {
        id: uuidv4(),
        childs: [],
        name: landmarkType.name,
        color: landmarkType.color,
        type: 'point',
        pos: { x, y },
        show: true
      }
      dispatch({ type: 'addLandmark', payload: landmark })
      drawPoints(false, landmark)
      dispatch({ type: 'setSaveTags' })
    }
  }
  function drawPoints(redraw = false, landmarks = landmarkState.tags) {
    if (labelerState.image.isRendered) {
      const canvas = canvasCompleteFigures.current
      const ctx = canvas.getContext('2d')
      const canvas2 = canvasIncompleteFigures.current
      const ctx1 = canvas2.getContext('2d')
      if (redraw) {
        ctx.beginPath()
        ctx.globalCompositeOperation = 'source-over'
        ctx.setLineDash([])
        ctx1.setTransform(1, 0, 0, 1, 0, 0)
        ctx1.clearRect(0, 0, canvas.width, canvas.height)
        ctx.setTransform(1, 0, 0, 1, 0, 0)
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        ctx.setTransform(panZoom.scale, 0, 0, panZoom.scale, panZoom.x, panZoom.y)
        ctx.lineWidth = 0.8 / panZoom.scale
        landmarks.forEach((landmark) => {
          if (landmark.show !== false) {
            ctx.strokeStyle = landmark.hover ? '#fff' : landmark.color
            ctx.beginPath()
            ctx.arc(landmark.pos.x, landmark.pos.y, 5 / panZoom.scale, 0, 2 * Math.PI)
            ctx.stroke()
            ctx.beginPath()
            ctx.arc(landmark.pos.x, landmark.pos.y, 1 / panZoom.scale, 0, 2 * Math.PI)
            ctx.strokeStyle = landmark.hover ? '#ffffff80' : landmark.color + '80'
            ctx.stroke()
          }
        })
      } else {
        if (landmarks.show !== false) {
          ctx.beginPath()
          ctx.strokeStyle = landmarks.color
          ctx.lineWidth = 0.8 / panZoom.scale
          ctx.arc(landmarks.pos.x, landmarks.pos.y, 5 / panZoom.scale, 0, 2 * Math.PI)
          ctx.stroke()
          ctx.beginPath()
          ctx.strokeStyle = landmarks.color + '80'
          ctx.arc(landmarks.pos.x, landmarks.pos.y, 1 / panZoom.scale, 0, 2 * Math.PI)
          ctx.stroke()
        }
      }
    }
  }
  const clickPoint = (x, y, points = landmarkState.tags) => {
    dispatch({ type: 'unselectPoint', payload: {} })
    for (let index = 0; index < points.length; index++) {
      const point = points[index]
      if (
        Math.sqrt(Math.pow(point.pos.x - x, 2) + Math.pow(point.pos.y - y, 2)) <
        7 / panZoom.scale
      ) {
        setEditLandmark(point)
        dispatch({ type: 'selectPoint', payload: { index } })
        return
      }
    }

    return null
  }

  // eslint-disable-next-line no-unused-vars
  function drawLineGuide(x, y) {}

  const resizeTemplate = (x, y) => {
    let points = [...template]
    const lastPoint = Object.assign({}, points.pop())
    const lastPos = Object.assign({}, lastPoint.pos)
    points = points.map((point) => {
      const temp = Object.assign({}, point)
      const pos = { ...point.pos }
      const rx = lastPoint.pos.x - point.pos.x
      const ry = lastPoint.pos.y - point.pos.y
      pos.x = x - rx
      pos.y = y - ry
      temp.pos = { ...pos }
      return temp
    })
    lastPos.x = x
    lastPos.y = y
    lastPoint.pos = lastPos
    points.push(lastPoint)
    setTemplate(points)

    const canvas = canvasIncompleteFigures.current
    const ctx = canvas.getContext('2d')
    ctx.beginPath()
    ctx.setTransform(1, 0, 0, 1, 0, 0)
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    ctx.setTransform(panZoom.scale, 0, 0, panZoom.scale, panZoom.x, panZoom.y)

    template.forEach((landmark) => {
      ctx.strokeStyle = landmark.color
      ctx.beginPath()
      ctx.arc(landmark.pos.x, landmark.pos.y, 5 / panZoom.scale, 0, 2 * Math.PI)
      ctx.stroke()
      ctx.beginPath()
      ctx.strokeStyle = landmark.color + '80'
      ctx.arc(landmark.pos.x, landmark.pos.y, 1 / panZoom.scale, 0, 2 * Math.PI)
      ctx.stroke()
    })
  }
  const scaleTemplatePoints = (wheel) => {
    const points = [...template]
    points.forEach((point) => {
      const rx = point.pos.x / wheel
      const ry = point.pos.y / wheel
      point.pos.x = rx
      point.pos.y = ry
    })
    setTemplate(points)

    const canvas = canvasIncompleteFigures.current
    const ctx = canvas.getContext('2d')
    ctx.beginPath()
    ctx.setTransform(1, 0, 0, 1, 0, 0)
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    ctx.setTransform(panZoom.scale, 0, 0, panZoom.scale, panZoom.x, panZoom.y)

    template.forEach((landmark) => {
      ctx.strokeStyle = landmark.color
      ctx.beginPath()
      ctx.arc(landmark.pos.x, landmark.pos.y, 5 / panZoom.scale, 0, 2 * Math.PI)
      ctx.stroke()
      ctx.beginPath()
      ctx.strokeStyle = landmark.color + '80'
      ctx.arc(landmark.pos.x, landmark.pos.y, 1 / panZoom.scale, 0, 2 * Math.PI)
      ctx.stroke()
    })
  }
  function moveLandmark(x, y) {
    if (editLandmark) {
      if (x > 0 && x < labelerState.image.props.w && y > 0 && y < labelerState.image.props.h) {
        const canvas = canvasIncompleteFigures.current
        const ctx = canvas.getContext('2d')
        ctx.beginPath()
        ctx.setTransform(1, 0, 0, 1, 0, 0)
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        ctx.setTransform(panZoom.scale, 0, 0, panZoom.scale, panZoom.x, panZoom.y)
        ctx.strokeStyle = editLandmark.color
        ctx.lineWidth = 0.8 / panZoom.scale
        ctx.arc(x, y, 5 / panZoom.scale, 0, 2 * Math.PI)
        ctx.stroke()
        ctx.beginPath()
        ctx.strokeStyle = editLandmark.color + '80'
        ctx.arc(x, y, 1 / panZoom.scale, 0, 2 * Math.PI)
        ctx.stroke()
      }
    }
  }
  function confirmEdit(x, y) {
    if (editLandmark) {
      dispatch({
        type: 'setLandmark',
        payload: { index: landmarkState.selectedTag, pos: { x, y } }
      })
      dispatch({ type: 'setSaveTags' })
      setEditLandmark(null)
    }
  }
  function confirmTemplate() {
    dispatch({ type: 'confirmTemplate', payload: template })
    dispatch({ type: 'setSaveTags' })
  }
  function handleTemplate() {
    if (labelerState.mode === 'template') {
      setTemplate(landmarkState.person)
    }
  }

  return (
    <>
      <canvas className="layout" ref={canvasCompleteFigures} />
      <canvas className="layout" ref={canvasIncompleteFigures} />
      <canvas className="layout" ref={canvasLineGuide} style={{ cursor: labelerState.cursor }} />
    </>
  )
}
