import React, { useContext } from 'react'
import { Chip } from '@mui/material'
import { styled } from '@mui/material/styles' // Asegúrate de importar styled de @mui/material/styles
import { FiltersContext } from '../../../../../../../context/FiltersContext'

const chipStyles = {
  backgroundColor: 'rgba(43, 30, 97, .75)',
  color: '#fff',
  minHeight: '15px',
  maxHeight: '24px',
  margin: '2px',
  height: '10%'
}

const StyledChipsContainer = styled('div')(({ width, height, gridSize }) => ({
  display: 'flex',
  width: `${width * gridSize}px`,
  maxWidth: '600px',
  height: `${height * gridSize}px`,
  position: 'absolute',
  alignItems: 'flex-end',
  alignContent: 'flex-start',
  flexWrap: 'wrap-reverse',
  padding: '2px',
  overflow: 'hidden'
}))

const ClassificationChips = ({ width, height, image }) => {
  const { state } = useContext(FiltersContext)
  const { gridSize } = state

  if (!state.hideClassifications && image?.annotations?.classification) {
    return (
      <StyledChipsContainer width={width} height={height} gridSize={gridSize}>
        {image.annotations.classification.map((classification) => {
          const isHidden = state.hiddenCategories.some((cat) => cat.name === classification.name)
          return isHidden ? null : (
            <Chip
              key={classification.name}
              size="small"
              color="primary"
              label={classification.selected}
              sx={chipStyles}
            />
          )
        })}
      </StyledChipsContainer>
    )
  } else {
    return null
  }
}

export default ClassificationChips
