import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { Drawer, Button } from '@mui/material'
import Keyboard from '@mui/icons-material/Keyboard'
import BlurOn from '@mui/icons-material/BlurOn'
import { Link } from 'react-router-dom'
import { LabelerContext } from '../../containers/labeler/context/LabelerContext'
import { DispatchContext } from '../../containers/labeler/context/DispatchContext'
import SegmentationButtons from './segmentation/SegmentationButtons'
import DefaultButtons from './default/DefaultButtons'
import LandmarkButtons from './landmark/LandmarkButtons'
import BoundingButtons from './bounding/BoundingButtons'
import PolygonButtons from './polygon/PolygonButtons'
import BarTooltip from './BarTooltip'
const buttonsByCategory = ({ isMagicEnable }) => ({
  segmentation: <SegmentationButtons isMagicEnable={isMagicEnable} />,
  point: <LandmarkButtons />,
  bounding_box: <BoundingButtons />,
  polygon: <PolygonButtons />
})

export default function Toolbar({ isMagicEnable }) {
  const {
    state: { labelerState }
  } = useContext(LabelerContext)
  const { dispatch } = useContext(DispatchContext)
  const store = useSelector((state) => state.ui)
  return (
    <Drawer variant="permanent" style={{ position: 'relative', zIndex: 1000 }}>
      <Link
        style={{ width: '100%', padding: 'auto', marginTop: 5 }}
        href="/projects"
        to="/projects"
      >
        <img height={36} width={36} src={store.logoHex} alt="logo" />
      </Link>
      <span style={{ height: 40 }} />
      <DefaultButtons tools={labelerState.tools} />
      <span style={{ height: 80 }} />
      {labelerState.role !== 'viewer' &&
        buttonsByCategory({ isMagicEnable })[labelerState.selectedCategory.type]}
      <span style={{ height: 20 }} />
      <BarTooltip title="[H] Keyboard shortcuts">
        <Button
          size="small"
          sx={{ minWidth: 48, width: 48 }}
          onClick={() => dispatch({ type: 'changeIsHotkeysOpen' })}
        >
          <Keyboard fontSize="small" />
        </Button>
      </BarTooltip>
      {labelerState.selectedCategory.type !== 'segmentation' && (
        <BarTooltip title={'Enable/Disable image smoothing'}>
          <Button
            sx={{ minWidth: 48, width: 48 }}
            size="small"
            onClick={() => {
              dispatch({ type: 'setSmooth' })
            }}
          >
            <BlurOn fontSize="small" />
          </Button>
        </BarTooltip>
      )}
    </Drawer>
  )
}
