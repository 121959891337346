import React, { useContext } from 'react'
import { Paper, MenuItem, Select, Divider, IconButton } from '@mui/material'
import SortIcon from '@mui/icons-material/Sort'
import { FiltersContext } from '../../../../../context/FiltersContext'

const sorters = [
  {
    label: 'Name',
    id: 'key'
  },
  {
    label: 'Count',
    id: 'count'
  },
  {
    label: 'Timestamp',
    id: 'timestamp'
  }
]

const Sorter = () => {
  const { state, dispatch } = useContext(FiltersContext)

  const handleSort = () => {
    dispatch({
      type: 'setOrder',
      payload: { type: state.order.type, order: state.order.order === 'asc' ? 'desc' : 'asc' }
    })
  }

  const handleOrderChange = (event) => {
    dispatch({
      type: 'setOrder',
      payload: { type: event.target.value, order: state.order.order }
    })
  }

  const renderOrderLabel = () => {
    const selected = sorters.filter((s) => state.order.type === s.id)
    return selected.length > 0 && selected[0].label ? selected[0].label : 'None'
  }
  return (
    <Paper
      sx={{
        display: 'flex',
        justifyContent: 'right',
        padding: 0.5,
        margin: 0.5,
        flex: 1
      }}
      elevation={0}
      variant="outlined"
    >
      <Select
        id="orderBy"
        size="small"
        variant="standard"
        value={state.order.type}
        onChange={handleOrderChange}
        renderValue={renderOrderLabel}
        sx={{ width: '100%', margin: '0.5rem 0.5rem' }}
      >
        {sorters.map((sort) => (
          <MenuItem key={sort.id} value={sort.id}>
            {sort.label}
          </MenuItem>
        ))}
      </Select>
      <Divider orientation="vertical" flexItem sx={{ margin: 0.5 }} />
      <IconButton size="small" type="button" aria-label="sort" onClick={handleSort}>
        <SortIcon sx={state.order.order === 'desc' ? { transform: 'rotate(180deg)' } : {}} />
      </IconButton>
    </Paper>
  )
}

export default Sorter
