import { useState, useEffect } from 'react'
import { checkUserFreeTrial } from '../../../io/api/paidUser'

const useFreeTrial = () => {
  const [permissionsFreeTrial, setPermissionsFreeTrial] = useState(false)

  useEffect(() => {
    checkUserFreeTrial().then((result) => {
      const { status, data } = result
      if (status === 200 && data === 'success') {
        setPermissionsFreeTrial(true)
      }
    })
  }, [])

  return { permissionsFreeTrial }
}

export default useFreeTrial
