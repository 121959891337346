import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { ui } from '../io/ui/actions'
import { logout } from '../io/user/actions'
import { logout as logoutAws } from '../services/aws'
import { sandLabelerViewed } from '../io/analytics'
import '../styles/Navigationbar.css'
import {
  Box,
  Button,
  AppBar,
  Divider,
  Container,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Tooltip,
  Avatar,
  FormControlLabel,
  Switch
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { getUser } from '../utils/util'

const pages = [
  { label: 'Dashboard', path: '/projects', visibleOn: 'auth' },
  { label: 'Labeler', path: '/labeler', visibleOn: 'auth', onClick: sandLabelerViewed },
  { label: 'Docs', path: process.env.REACT_APP_DOCS, target: '_blank', dest: 'ext' },
  { label: 'Signup', path: '/signup', visibleOn: 'no-auth' },
  { label: 'Login', path: '/login', visibleOn: 'no-auth' }
]

function MainAppBar(props) {
  const { logo } = props
  const navigate = useNavigate()

  const [anchorElNav, setAnchorElNav] = React.useState(null)
  const [anchorElUser, setAnchorElUser] = React.useState(null)

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleAccountSelect = () => {
    navigate('/account/details')
  }

  const handleLogout = async () => {
    handleCloseUserMenu()
    logoutAws()
      .then(() => {
        props.logout()
        props.userHasAuthenticated(false)

        navigate('/login')
      })
      .catch((err) => {
        console.log('ERROR Login Out')
        console.log(err)
      })
  }

  const ConditionalWrapper = ({ condition, wrapper, defaultWrapper, children }) =>
    condition ? wrapper(children) : defaultWrapper(children)

  const user = getUser()
  const settings = [
    { label: 'Account', onClick: handleAccountSelect, type: 'text' },
    { label: 'Dark Mode', type: 'switch' },
    { type: 'divider', label: 'div0' },
    { label: 'Logout', onClick: handleLogout, type: 'text' }
  ]

  return (
    <AppBar position="fixed" color="default">
      <Container
        sx={{ paddingRight: { xs: 3, sm: '7%' }, paddingLeft: { xs: 2, sm: '8%' }, height: 50 }}
        maxWidth="xl"
      >
        <Toolbar variant="dense" disableGutters>
          <Box component="a" sx={{ display: { xs: 'none', sm: 'flex' }, mr: 2 }} href="/">
            <img style={{ maxHeight: 24 }} src={logo} alt="LinkedAI" />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', sm: 'none' }
              }}
            >
              {pages.map((page) => {
                if (props.isAuthenticated && page.visibleOn === 'no-auth') return null
                if (!props.isAuthenticated && page.visibleOn === 'auth') return null

                return (
                  <ConditionalWrapper
                    key={page.label}
                    condition={page.dest === 'ext'}
                    defaultWrapper={(children) => (
                      <Link
                        style={{ textDecoration: 'none' }}
                        onClick={page.onClick}
                        to={{ pathname: page.path }}
                      >
                        {children}
                      </Link>
                    )}
                    wrapper={(children) => (
                      <a
                        style={{ textDecoration: 'none' }}
                        href={page.path}
                        target={page.target || ''}
                      >
                        {children}
                      </a>
                    )}
                  >
                    <MenuItem key={page.label} onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">{page.label}</Typography>
                    </MenuItem>
                  </ConditionalWrapper>
                )
              })}
            </Menu>
          </Box>
          <Box
            component="a"
            sx={{ display: { xs: 'flex', sm: 'none' }, mr: 2, flexGrow: 1 }}
            href="/"
          >
            <img style={{ maxHeight: 24 }} src={logo} alt="LinkedAI" />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' }, justifyContent: 'right' }}>
            {pages.map((page) => {
              if (props.isAuthenticated && page.visibleOn === 'no-auth') return null
              if (!props.isAuthenticated && page.visibleOn === 'auth') return null
              return (
                <ConditionalWrapper
                  key={page.label}
                  condition={page.dest === 'ext'}
                  defaultWrapper={(children) => (
                    <Link style={{ textDecoration: 'none' }} to={{ pathname: page.path }}>
                      {children}
                    </Link>
                  )}
                  wrapper={(children) => (
                    <a
                      style={{ textDecoration: 'none' }}
                      href={page.path}
                      target={page.target || ''}
                    >
                      {children}
                    </a>
                  )}
                >
                  <Button
                    key={page.label}
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, display: 'block', margin: 0.5 }}
                  >
                    {page.label}
                  </Button>
                </ConditionalWrapper>
              )
            })}
          </Box>
          {props.isAuthenticated && (
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    sx={{ width: 36, height: 36, bgcolor: '#00c9c9' }}
                    alt={user?.name}
                    src="/2.jpg"
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => {
                  if (setting.type === 'divider') return <Divider key={setting.label} />
                  const isSwitch = setting.type === 'switch'
                  let element = <Typography textAlign="center">{setting.label}</Typography>
                  if (isSwitch) {
                    element = (
                      <FormControlLabel
                        key={setting.label}
                        style={{ margin: 0 }}
                        control={
                          <Switch
                            checked={props.uiMode === 'dark'}
                            onChange={() => {
                              props.uiMode === 'light'
                                ? props.setMode('dark')
                                : props.setMode('light')
                            }}
                            color="primary"
                            name="checkedA"
                          />
                        }
                        label={setting.label}
                        labelPlacement="start"
                      />
                    )
                  }

                  return (
                    <MenuItem key={setting.label} onClick={setting.onClick || null}>
                      {element}
                    </MenuItem>
                  )
                })}
                <Divider />
                <MenuItem disabled style={{ padding: '0rem 1rem' }}>
                  <p style={{ fontSize: '8pt', margin: '0px' }}>{`v.${props.version}`}</p>
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  )
}

const mapStateToProps = (state) => {
  return {
    uiMode: state.ui.mode,
    logo: state.ui.logo
  }
}

const mapDispatchToProps = {
  logout,
  setMode: ui.setMode
}

export default connect(mapStateToProps, mapDispatchToProps)(MainAppBar)
