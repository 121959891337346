import React, { useContext } from 'react'
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import FormatColorResetIcon from '@mui/icons-material/FormatColorReset'
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill'
import { FiltersContext } from '../../../../context/FiltersContext'

function LabelFillHandler({ text, collapse }) {
  const { state, dispatch } = useContext(FiltersContext)

  const handleFillClick = () => {
    dispatch({
      type: `setFilledCategories`
    })
  }

  return (
    <ListItem disablePadding sx={{ display: 'block' }}>
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: collapse ? 'initial' : 'center',
          px: 2.5
        }}
        onClick={handleFillClick}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: collapse ? 3 : 'auto',
            justifyContent: 'center'
          }}
        >
          {!state.filledCategories ? <FormatColorFillIcon /> : <FormatColorResetIcon />}
        </ListItemIcon>
        <ListItemText
          primary={text}
          sx={{ opacity: collapse ? 1 : 0, display: collapse ? 'block' : 'none' }}
        />
      </ListItemButton>
    </ListItem>
  )
}

export default LabelFillHandler
