export const drawSegmentation = (
  segmentationShapes,
  hiddenCategories,
  filledCategories,
  img,
  image,
  ctxCanvas2
) => {
  const scaleImgWidth = img.width / image.width
  const scaleImgHeight = img.height / image.height

  segmentationShapes.forEach((segmentation) => {
    const index = hiddenCategories.findIndex((cat) => cat.name === segmentation.name)
    if (index === -1) {
      ctxCanvas2.beginPath()

      segmentation.points.forEach((point) => {
        const x = point.x * scaleImgWidth
        const y = point.y * scaleImgHeight
        ctxCanvas2.lineTo(x, y)
      })

      ctxCanvas2.fillStyle = filledCategories ? segmentation.color : 'rgba(0, 0, 0, 0)'
      ctxCanvas2.strokeStyle = segmentation.color

      ctxCanvas2.fill()
      ctxCanvas2.stroke()

      ctxCanvas2.closePath()
    }
  })
}
