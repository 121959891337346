import { useReducer } from 'react'
import produce from 'immer'
import { updateImageProperty } from './utils/imageUtils'

export const initialState = {
  accepted: { value: 'all' },
  done: { value: 'all' },
  skiped: { value: 'all' },
  categories: [],
  classifications: {},
  labelClassifications: [],
  count: [],
  itemsCount: { min: '', max: '' },
  date: [],
  filter: [],
  getImages: 0,
  gridSize: 0.2,
  labelers: [],
  reviewers: [],
  loading: false,
  names: [],
  name: '',
  order: {
    type: 'key',
    order: 'asc'
  },
  countImages: 0,
  images: [],
  selectedImage: null,
  hiddenCategories: [],
  hideClassifications: false,
  filledCategories: true,
  totalImagesInRequest: 0,
  openCompareDialog: false,
  compareImage: null,
  dataComparison: {},
  errorItemHover: null
}

export const reducer = produce((state, action) => {
  switch (action.type) {
    case 'setNames':
      state.names = [action.payload]
      break
    case 'setName':
      state.name = action.payload
      break
    case 'setOrder':
      state.order = action.payload
      break
    case 'setGridSize':
      state.gridSize = parseFloat(`0.${action.payload}`)
      break
    case 'setDone':
      state.done = action.payload
      break
    case 'setAccepted':
      state.accepted = action.payload
      break
    case 'setSkiped':
      state.skiped = action.payload
      break
    case 'setCategories':
      state.categories = action.payload
      break
    case 'setClassifications':
      state.classifications = action.payload
      break
    case 'setLabelClassifications':
      state.labelClassifications = action.payload
      break
    case 'setLabelers':
      state.labelers = action.payload
      break
    case 'setReviewers':
      state.reviewers = action.payload
      break
    case 'setCount':
      state.count = action.payload
      break
    case 'setItemsCount':
      state.itemsCount = action.payload
      break
    case 'setLoading':
      state.loading = action.payload
      break
    case 'setCountImages':
      state.countImages = action.payload
      break
    case 'setImageProperty':
      state.images = updateImageProperty({
        images: JSON.stringify(state.images, null, 2),
        id: action.payload.image.id,
        property: action.payload.property
      })
      break
    case 'setImages':
      state.images = action.payload
      break
    case 'setSelectedImage':
      state.selectedImage = action.payload
      break
    case 'setHiddenCategories': {
      const index = state.hiddenCategories.findIndex((cat) => cat.name === action.payload.name)
      if (index !== -1) {
        state.hiddenCategories = state.hiddenCategories.filter(
          (cat) => cat.name !== action.payload.name
        )
      } else {
        state.hiddenCategories = [...state.hiddenCategories, action.payload]
      }
      break
    }
    case 'setHideClassifications':
      state.hideClassifications = !state.hideClassifications
      break
    case 'setFilledCategories':
      state.filledCategories = !state.filledCategories
      break
    case 'setTotalImagesInRequest':
      state.totalImagesInRequest = action.payload
      break
    case 'setOpenCompareDialog':
      state.openCompareDialog = action.payload
      break
    case 'setCompareImage':
      state.compareImage = action.payload
      break
    case 'setDataComparison':
      state.dataComparison = action.payload
      break
    case 'setErrorItemHover':
      state.errorItemHover = action.payload
      break
    case 'reset':
      if (action.payload) {
        if (action.payload.masterKey) {
          delete state[action.payload.masterKey][action.payload.key]
        } else {
          state[action.payload.key] = initialState[action.payload.key]
        }
      } else {
        return initialState
      }
      break
    default:
      throw new Error(`Unsupported action type: ${action.type}`)
  }
}, initialState)

export function FiltersReducer() {
  const [state, dispatch] = useReducer(reducer, initialState)
  return { filtersState: state, filtersDispatch: dispatch }
}
