import axios from 'axios'
import { getUser } from '../../utils/util'

export const checkUserFreeTrial = async () => {
  const user = getUser()
  const url = 'https://api.linkedai.co/v2/accounts/validate-status'

  try {
    return await axios.post(url, { id: user.id })
  } catch (error) {
    console.error('Error checking user status: ', error)
  }
}
