import React, { useMemo, useRef, useEffect, useCallback, useState } from 'react'
import { useMachine } from '@xstate/react'
import { useIdleTimer } from 'react-idle-timer'

import Labeler from '../components/Labeler'
import { LabelerProvider } from '../context/LabelerContext'
import { DispatchProvider } from '../context/DispatchContext'
import { useOnFocus } from '../../../utils/hooks/useOnFocus'
import { updateTag } from '../../../io/graphql/tag/tags'
import { useSelector } from 'react-redux'
import { useEventListener } from '../../../utils/hooks/useEventListener'
import NewLabeler from '../components/NewLabeler'
import { LabelerReducer } from './reducers/labeler'
import { SegmentationReducer } from './reducers/annotationsTypes/segmentation'
import { LandmarksReducer } from './reducers/annotationsTypes/landmarks'
import { BoundingBoxReducer } from './reducers/annotationsTypes/boundingbox'
import { PolygonsReducer } from './reducers/annotationsTypes/polygons'
import { ClassificationReducer } from './reducers/annotationsTypes/classification'
import { LineReducer } from './reducers/annotationsTypes/lines'
import { CircleReducer } from './reducers/annotationsTypes/circle'
import { TimerReducer } from './reducers/timer'
import { TimerController } from './TimerController'
import useGetTags from '../hooks/useGetTags'
import { getUser } from '../../../utils/util'
import { labelerMachine } from './labelerMachine'
import MachineProvider from '../context/MachineContext'
import { useSnackbar } from '../../../components/snackbar/context/SnackbarContext'

export default function LabelerController(props) {
  // HOOKS REDUX
  const store = useSelector((state) => state.project)
  const [state, send] = useMachine(labelerMachine)

  // REDUCERS
  const { labelerState, labelerDispatch } = LabelerReducer()
  const { timerState, timerDispatch } = TimerReducer()
  const { segmentationState, segmentationDispatch } = SegmentationReducer()
  const { landmarkState, landmarkDispatch } = LandmarksReducer()
  const { boundingBoxState, boundingBoxDispatch } = BoundingBoxReducer()
  const { polygonState, polygonDispatch } = PolygonsReducer()
  const { classificationState, classificationDispatch } = ClassificationReducer()
  const { lineState, lineDispatch } = LineReducer()
  const { circleState, circleDispatch } = CircleReducer()

  // TIMER HOOK
  const { labelerTime, reviewerTime } = TimerController(timerState, labelerState.role, state)

  const [numberOfAttempts, setNumberOfAttempts] = useState(0)

  const { showSnackbar } = useSnackbar()

  useEffect(() => {
    if (numberOfAttempts >= 4) {
      showSnackbar({
        message: 'Labels could not be saved',
        type: 'warning',
        block: true,
        button: true,
        buttonFunction: saveTags,
        textButton: 'Save Tags'
      })
    }
  }, [numberOfAttempts])

  useEffect(() => {
    if (state.context.hasError) {
      showSnackbar({
        message: 'Error, contact your administrator',
        type: 'error',
        block: true,
        button: true,
        buttonFunction: resetMachine,
        textButton: 'Bring image again '
      })
    }
  }, [state.context.hasError])

  const resetMachine = () => {
    send({ type: 'RESET', source: 'LABELERCONTROLLER' })
  }

  // COMBINE REDUCERS
  const combineDispatch =
    (...dispatches) =>
    (action) =>
      dispatches.forEach((dispatch) => dispatch(action))

  const combinedDispatch = useCallback(
    combineDispatch(
      polygonDispatch,
      segmentationDispatch,
      landmarkDispatch,
      boundingBoxDispatch,
      classificationDispatch,
      circleDispatch,
      lineDispatch,
      labelerDispatch,
      timerDispatch
    ),
    [
      polygonDispatch,
      landmarkDispatch,
      boundingBoxDispatch,
      segmentationDispatch,
      classificationDispatch,
      circleDispatch,
      lineDispatch,
      labelerDispatch,
      timerDispatch
    ]
  )

  const combinedState = useMemo(
    () => ({
      polygonState,
      segmentationState,
      landmarkState,
      boundingBoxState,
      classificationState,
      circleState,
      lineState,
      labelerState,
      timerState
    }),
    [
      polygonState,
      segmentationState,
      landmarkState,
      boundingBoxState,
      classificationState,
      circleState,
      lineState,
      labelerState,
      timerState
    ]
  )

  // HOOK GET TAGS
  useGetTags(
    store.categories,
    labelerState.image.currentImage,
    store.id,
    combinedDispatch,
    state,
    send
  )

  // REFS
  const childRef = useRef(null)

  // SAVE TAGS FUNCTION
  const saveTags = async (extras = {}) => {
    const selectedImage =
      labelerState.image.index >= 0 && labelerState.image.index < labelerState.image.images.length
        ? labelerState.image.images[labelerState.image.index]
        : null

    if (!selectedImage) return numberOfAttempts

    const labelertime = labelerTime
    const reviewertime = reviewerTime
    const { data: currentAttempt } = await updateTag(
      selectedImage.id,
      getUser().id,
      labelerState.role,
      state.context.loading,
      store.id,
      {
        segmentations: segmentationState.tags,
        points: landmarkState.tags,
        rects: boundingBoxState.tags,
        polygons: polygonState.tags,
        lines: lineState.tags,
        classification: classificationState.tags
      },
      labelertime,
      reviewertime,
      numberOfAttempts,
      labelerState.image.currentImage.key,
      { ...extras }
    )

    if (Number.isInteger(currentAttempt)) setNumberOfAttempts(currentAttempt)
    return currentAttempt
  }

  // ONINDLE FUNCTIONS
  const onIdle = () => {
    switch (labelerState.role) {
      case 'labeler':
        combinedDispatch({ type: 'setActionLabelerTime', payload: 'stop' })
        break
      case 'reviewer':
        combinedDispatch({ type: 'setActionReviewerTime', payload: 'stop' })
        break
    }
    saveTags()
  }

  const onActive = () => {
    switch (labelerState.role) {
      case 'labeler':
        combinedDispatch({ type: 'setActionLabelerTime', payload: 'run' })
        break
      case 'reviewer':
        combinedDispatch({ type: 'setActionReviewerTime', payload: 'run' })
        break
      default:
        break
    }
    saveTags()
  }

  const nextMode = (mode, type) => {
    switch (type) {
      case 'segmentation':
        switch (mode) {
          case 'draw':
            return 'erase'
          case 'erase':
            return 'paint'
          case 'paint':
            return 'draw'
          default:
            return 'draw'
        }
      default:
        switch (mode) {
          case 'draw':
            return 'edit'
          case 'edit':
            return 'draw'
          default: // 'template'
            return 'draw'
        }
    }
  }

  useEffect(() => {
    saveTags()
  }, [combinedState.labelerState.saveTags])

  useEventListener('keydown', keyHandler)

  function keyHandler(e) {
    if (e.target.type !== undefined || e.target.localName === 'input' || e.repeat) return
    if (!(e.ctrlKey || e.metaKey) && !e.shiftKey && !e.altKey) {
      normalHotKeys(e)
      return
    }
    if ((e.ctrlKey || e.metaKey) && !e.shiftKey && !e.altKey) {
      cmdHotKeys(e)
      return
    }
    if (!(e.ctrlKey || e.metaKey) && e.shiftKey && e.altKey) {
      shiftHotKeys(e)
      return
    }
    if (!(e.ctrlKey || e.metaKey) && !e.shiftKey && e.altKey) {
      altHotKeys(e)
      return
    }
    if ((e.ctrlKey || e.metaKey) && e.shiftKey) {
      cmdPlusShiftHotKeys(e)
    }
  }

  const normalHotKeys = (e) => {
    switch (e.which) {
      case 32: // Space KEY
        // eslint-disable-next-line no-case-declarations
        const mode = nextMode(
          combinedState.labelerState.mode,
          combinedState.labelerState.selectedCategory.type
        )
        combinedDispatch({
          type: 'setLabelerMode',
          payload: { mode, category: combinedState.labelerState.selectedCategory.type }
        })
        e.returnValue = false
        break
      case 72: // H KEY
        combinedDispatch({ type: 'changeIsHotkeysOpen' })
        break
      case 90: // Z KEY
        combinedDispatch({
          type: 'setCanvasSettings',
          payload: [
            { name: 'Saturation', value: 100 },
            { name: 'Brightness', value: 100 },
            { name: 'Contrast', value: 100 },
            { name: 'GrayScale', value: 0 }
          ]
        })
        break
      case 71: // G KEY
        combinedDispatch({
          type: 'setGrid',
          payload: !combinedState.labelerState.grid
        })
        break
      case 88: // X KEY
        combinedDispatch({ type: 'setOpenCard', payload: { open: true, index: 0 } })
        break
      case 67: // C KEY
        combinedDispatch({ type: 'setOpenCard', payload: { open: true, index: 1 } })
        break
      case 86: // V KEY
        combinedDispatch({ type: 'setOpenCard', payload: { open: true, index: 2 } })
        break
      case 66: // B KEY
        combinedDispatch({ type: 'setOpenCard', payload: { open: true, index: 3 } })
        break
      case 82: // R KEY
        combinedDispatch({ type: 'setReset' })
        break
      case 78: // N KEY
        combinedDispatch({
          type: 'setScale',
          payload: labelerState.tools.scale > 0 ? -0.9 : 0.9
        })
        break
      case 77: // M KEY
        combinedDispatch({
          type: 'setScale',
          payload: labelerState.tools.scale > 0 ? -1.1 : 1.1
        })
        break
      case 79: // O Key
        // childRef.current.openDownloadModal() TODO: Fix this method
        break
      case 73: // I key
        childRef.current.showAiDialog()
        break
      case 8:
        combinedDispatch({ type: 'deleteboundingBox' })
        combinedDispatch({ type: 'deletepolygon' })
        break
      // 1,2,3,4,5,6,7,8,9 KEYS
      case 49:
      case 50:
      case 51:
      case 52:
      case 53:
      case 54:
      case 55:
      case 56:
      case 57:
        childRef.current.changeCategory(parseInt(e.key) - 1)

        if (combinedState.labelerState.categories[parseInt(e.key) - 1].type === 'segmentation') {
          combinedDispatch({
            type: 'setSelectedSegmentation',
            payload: combinedState.labelerState.categories[parseInt(e.key) - 1]
          })
        }
        combinedDispatch({
          type: 'setSelectedCategory',
          payload: { cat: combinedState.labelerState.categories[parseInt(e.key) - 1] }
        })
        break
      case 37: // ArrowLeft KEY
        combinedDispatch({
          type: 'setPanzoom',
          payload: { ...combinedState.labelerState.tools.panZoom, x: 10, y: 0 }
        })
        break
      case 39: // ArrowRight KEY
        combinedDispatch({
          type: 'setPanzoom',
          payload: { ...combinedState.labelerState.tools.panZoom, x: -10, y: 0 }
        })
        break
      case 38: // ArrowUp KEY
        combinedDispatch({
          type: 'setPanzoom',
          payload: { ...combinedState.labelerState.tools.panZoom, x: 0, y: 10 }
        })
        break
      case 40: // ArrowDown KEY
        combinedDispatch({
          type: 'setPanzoom',
          payload: { ...combinedState.labelerState.tools.panZoom, x: 0, y: -10 }
        })
        break
      case 13: // Enter KEY
        // TODO: @daniel Finish Labels with enter
        break
      default:
        break
    }
  }

  const cmdHotKeys = (e) => {
    switch (e.which) {
      case 90:
        switch (labelerState.selectedCategory.type) {
          case 'segmentation':
            combinedDispatch({ type: 'ctrlZ', payload: null })
            break
          case 'polygon':
            polygonDispatch({ type: 'ctrlZ', payload: null })
            break
          default:
            break
        }
        break
      case 68:
        combinedDispatch({ type: 'shortcutDone', payload: null })
        e.preventDefault()
        break
      case 83:
        combinedDispatch({ type: 'shortcutSkip', payload: null })
        e.preventDefault()
        break

      default:
        break
    }
  }

  const altHotKeys = () => {
    // TODO: Add keys
  }

  const shiftHotKeys = () => {
    // TODO: Add keys
  }

  const cmdPlusShiftHotKeys = (e) => {
    switch (e.which) {
      case 90:
        switch (labelerState.selectedCategory.type) {
          case 'segmentation':
            combinedDispatch({ type: 'ctrlShiftZ', payload: null })
            break
          case 'polygon':
            polygonDispatch({ type: 'ctrlShiftZ', payload: null })
            break
          default:
            break
        }
        break
      case 68:
        combinedDispatch({ type: 'shortcutDoneAndNext', payload: null })
        e.preventDefault()
        break
      case 83:
        combinedDispatch({ type: 'shortcutSkipAndNext', payload: null })
        e.preventDefault()
        break

      default:
        break
    }
  }

  // INACTIVITY HOOKS
  useIdleTimer({
    timeout: 20000,
    onIdle,
    onActive,
    debounce: 0
  })

  useOnFocus({
    onActive,
    onIdle
  })

  return (
    <MachineProvider value={{ state, send }}>
      <DispatchProvider value={{ dispatch: combinedDispatch }}>
        <LabelerProvider value={{ state: combinedState }}>
          <NewLabeler {...props}>
            <Labeler
              ref={childRef}
              role={combinedState.labelerState.role}
              time={{ labelerTime, reviewerTime }}
              categories={combinedState.labelerState.categories}
              loadingTags={combinedState.labelerState.loadingTags}
              loadingImage={combinedState.labelerState.image.loading}
              saveTags={saveTags}
              {...props}
            />
          </NewLabeler>
        </LabelerProvider>
      </DispatchProvider>
    </MachineProvider>
  )
}
