import React from 'react'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

const StyledImage = styled('img')({
  width: 500
})

const NotFoundImages = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height={400}>
      <StyledImage alt="No data found" src="/assets/harry_sleeping.png" />
    </Box>
  )
}

export default NotFoundImages
