import React from 'react'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'

export default function AdminSelector({ handlerSetRole, role }) {
  return (
    <>
      <Divider />
      <div>
        Role:
        <Select
          variant="standard"
          onChange={(e) => handlerSetRole(e.target.value)}
          value={role}
          sx={{ padding: '0.3rem 0rem 0.3rem 0.5rem' }}
          disableUnderline
        >
          <MenuItem value={'reviewer'}>Reviewer</MenuItem>
          <MenuItem value={'labeler'}>Labeler</MenuItem>
          <MenuItem value={'viewer'}>Viewer</MenuItem>
        </Select>
      </div>
    </>
  )
}
