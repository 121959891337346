import { Auth } from 'aws-amplify'
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'

export const getToken = async () => {
  const session = await Auth.currentSession()
  const token = session.idToken.jwtToken

  return token
}

export const federatedSignIn = async () =>
  Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })
