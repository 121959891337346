import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Paper,
  Table,
  Select,
  Avatar,
  MenuItem,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  LinearProgress,
  TablePagination
} from '@mui/material'
import SearchBar from '../generics/textfields/SearchBar'
import Like from '@mui/icons-material/ThumbUp'
import { getAllImages } from '../../services/graphql'
import { dateToString } from '../../utils/util'

function ProjectLabels(props) {
  const [userType, setUserType] = useState('labeler')
  const getTeam = () => {
    let mTeam = []
    if (props.project.team && props.project.team.members) {
      mTeam = props.project.team.members.items.map((m) => {
        return { id: m.user.id, value: m.user.id, text: m.user.email }
      })
    }

    return mTeam
  }
  const fields = [
    // {
    //   id: "avatar",
    //   type: '?',
    //   label: "Avatar",
    //   minWidth: 50,
    //   format: value => value
    // },
    {
      id: 'key',
      type: 'text',
      label: 'Name',
      minWidth: 200,
      format: (value) => {
        const names = value.split('/')
        return names[names.length - 1]
      },
      searchable: 'wildcard'
    },
    {
      id: userType,
      type: 'select',
      label: (
        <Select
          variant="standard"
          disableUnderline
          labelId="user"
          id="user-name"
          value={userType}
          onChange={(e) => setUserType(e.target.value)}
        >
          <MenuItem value="labeler">Labeler</MenuItem>
          <MenuItem value="reviewer">Reviewer</MenuItem>
        </Select>
      ),
      options: getTeam(),
      labelSearch: userType === 'labeler' ? 'Labeler' : 'Reviewer',
      minWidth: 120,
      align: 'right',
      format: (value) => (value ? value.email : 'unknown'),
      searchable: 'eq'
    },
    {
      id: 'count',
      type: 'number',
      label: 'Labels Count',
      minWidth: 100,
      align: 'right',
      format: (value) => value,
      searchable: 'eq'
    },
    {
      id: 'done',
      type: 'boolean',
      label: 'Done',
      minWidth: 120,
      align: 'right',
      options: ['Pending', 'Done'],
      format: (value) => (value ? 'Done' : 'Pending'),
      searchable: 'eq'
    },
    {
      id: 'savedAt',
      type: 'date',
      label: 'Done At',
      minWidth: 120,
      align: 'right',
      format: (value) => (value !== null ? dateToString(value) : '-'),
      searchable: 'matchPhrasePrefix'
    },
    {
      id: 'accepted',
      type: 'boolean',
      label: 'Reviewed',
      minWidth: 120,
      align: 'right',
      options: ['Pending', 'Reviewed'],
      format: (value) => (value === true ? <Like color="primary" /> : '-'),
      searchable: 'eq'
    }
  ]

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [images, setImages] = useState([])
  const [loading, setLoading] = useState(false)
  const [next, setNext] = useState(null)
  const [search, setSearch] = useState({
    column: fields[0].id,
    query: fields[0],
    text: '',
    logicConnector: 'and'
  })
  const [filters, setFilters] = useState([])
  const { project } = props

  useEffect(() => {
    if (project.id) {
      getImgs()
    }
  }, [project.id])

  useEffect(() => {
    getImgs()
  }, [rowsPerPage])

  useEffect(() => {
    setSearch({
      ...search,
      text: ''
    })
    getImgs()
  }, [filters])

  const getImgs = (page) => {
    setLoading(true)

    getAllImages(project.id, rowsPerPage, 'asc', next, filters)
      .then((r) => {
        if (r.data && r.data.items) {
          setImages([...images, ...r.data.items])

          if (r.data.items.length < rowsPerPage) {
            setNext(null)
          } else {
            setNext(r.data.next)
          }

          if (Number.isInteger(page)) setPage(page)
        }
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        console.log(e)
      })
  }

  const handleChangePage = (event, newPage) => {
    const index = newPage * rowsPerPage
    if (images.length > index) {
      setPage(newPage)
    } else {
      getImgs(newPage)
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setNext(null)
    setImages([])
    setPage(0)

    setTimeout(() => {
      setRowsPerPage(event.target.value)
    }, 200)
  }

  const onFilterChange = (filters) => {
    setNext(null)
    setImages([])
    setPage(0)

    setTimeout(() => {
      setFilters(filters)
    }, 200)
  }

  const totalImages = () => {
    let totalImg = 0
    if (!next) {
      totalImg = images.length
    } else {
      project.datasets.forEach((d) => (totalImg += d.dataset ? d.dataset.count : -1))
      totalImg = -1
    }
    return totalImg
  }

  return (
    <div>
      <Typography variant="h5" sx={{ textAlign: 'left', margin: 1 }}>
        Image List
      </Typography>
      <SearchBar fields={fields} filtersChange={onFilterChange} />
      <Paper sx={{ margin: 10 }}>
        {loading && <LinearProgress color="secondary" />}
        <Table>
          <TableHead>
            <TableRow>
              {fields.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody style={{ minHeight: 400 }}>
            {images &&
              images.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                return (
                  <TableRow key={i} hover role="checkbox" tabIndex={-1}>
                    {fields.map((column) => {
                      const value = row[column.id]
                      if (column.id === 'avatar')
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {row.image && (
                              <Avatar>
                                <img
                                  style={{ maxHeight: '100%' }}
                                  src={row.image.thumbnail}
                                  alt={row.image.name}
                                />
                              </Avatar>
                            )}
                          </TableCell>
                        )
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id === 'key' ? (
                            <Link to={{ pathname: '/labeler', search: `?id=${row.id}` }}>
                              {column.format(value, props.team)}
                            </Link>
                          ) : (
                            column.format(value, props.team)
                          )}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={totalImages()}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'previous page'
          }}
          nextIconButtonProps={{
            'aria-label': 'next page'
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  )
}

const mapStateToProps = (state) => ({
  project: state.project
})

export default connect(mapStateToProps, {})(ProjectLabels)
