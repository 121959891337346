import React, { useContext } from 'react'
import { Toolbar, IconButton, Typography } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { FiltersContext } from '../../context/FiltersContext'
import StatusButtons from './components/StatusButtons'

const VisualizerToolbar = (props) => {
  const { state, dispatch } = useContext(FiltersContext)

  return (
    <Toolbar
      variant="dense"
      sx={{
        ...props.sx,
        display: state.selectedImage ? 'flex' : 'none',
        justifyContent: 'space-between',
        zIndex: 10
      }}
      position="fixed"
    >
      <IconButton
        sx={{ justifySelf: 'flex-start' }}
        size="small"
        type="button"
        onClick={() => dispatch({ type: 'setSelectedImage', payload: null })}
      >
        <CancelIcon />
      </IconButton>
      <Typography sx={{ maxWidth: '50%', justifySelf: 'center' }}>
        {state.selectedImage?.name}
      </Typography>

      {props.role !== 'viewer' ? <StatusButtons role={props.role} /> : null}
    </Toolbar>
  )
}

export default VisualizerToolbar
