import React from 'react'
import { TextField } from '@mui/material'

export default function RequestLabel() {
  return (
    <div>
      <TextField
        variant="standard"
        id="standard-password-input"
        label="Password"
        type="password"
        autoComplete="current-password"
      />
    </div>
  )
}
