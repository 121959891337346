class IFilter {
  checkCondition() {
    this._ABSTRACTMETHOD('checkCondition(elements, rules)')
  }

  _ABSTRACTMETHOD(name = 'unknown method') {
    console.warn('WARNING! Function "' + name + '" is not overridden in ' + this.constructor.name)
  }
}

export default IFilter
