import React, { useRef } from 'react'
import { Avatar, Chip } from '@mui/material'
import { DragIndicator } from '@mui/icons-material'
import { getIcon } from '../../utils/objects'
import { useDrag, useDrop } from 'react-dnd'

export default function DraggableCategoryItem({
  category,
  editIndex,
  handleDelete,
  index,
  setEditIndex,
  moveCardHandler
}) {
  const [{ isDragging }, drag] = useDrag({
    item: { name: 'category', index },
    type: 'category',
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  })

  const ref = useRef(null)
  const [, drop] = useDrop({
    accept: 'category',
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      moveCardHandler(dragIndex, hoverIndex)
      item.index = hoverIndex
    }
  })

  const opacity = isDragging ? 0.2 : 1
  drag(drop(ref))

  return (
    <div ref={ref} style={{ opacity }}>
      <Chip
        avatar={
          <div style={{ alignItems: 'center', display: 'flex', width: 'auto' }}>
            <DragIndicator />
            <Avatar
              style={{
                backgroundColor: `${category.color || '#000'}`,
                height: '24px',
                width: '24px'
              }}
              alt={category.type}
              src={getIcon(category.type)}
            />
          </div>
        }
        className="category-chip"
        color={editIndex === index ? 'primary' : 'default'}
        disabled={isDragging}
        label={category.parent ? `${category.name} (${category.parent})` : category.name}
        onClick={() => setEditIndex(index)}
        onDelete={() => handleDelete(index)}
        variant="outlined"
      />
    </div>
  )
}
