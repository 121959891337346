import {
  Backdrop,
  Button,
  ButtonGroup,
  CircularProgress,
  DialogActions,
  DialogTitle,
  TextField
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import {
  DialogMainContainer,
  DialogCloseIconButton,
  MainContentContainer,
  ActionsContainer,
  FormContainer,
  GridContainer,
  MainImageContainer,
  InfoContainer
} from './components/compareLabelsModalStyledComponents'
import useCompareLabels from './hooks/useCompareLabels'
import ErrorList from './components/ErrorsList'
import { useContext, useEffect, useRef, useState } from 'react'
import { FiltersContext } from '../../context/FiltersContext'
import { useResizeImageContainer } from '../../hooks/useResizeImageContainer'
import { drawAnnotation } from '../visualizerGrid/components/visualizerCard/components/cardImage/components/imageCanvas/utils/drawAnnotation'
import { useSelector } from 'react-redux'
import { getComparisonIoU, getDinoTags } from '../../../../../../io/api/ml'
import { getImage } from '../../../../../../io/project/services'

function CompareLabelsModal() {
  const { state, dispatch } = useContext(FiltersContext)
  const project = useSelector((state) => state.project)
  const {
    selectedImage,
    openCompareDialog: open,
    errorItemHover,
    compareImage,
    dataComparison
  } = state

  const [dinoText, setDinoText] = useState('')

  const { handleCompareIdChange, onCompareCall, compareId, resetCompareId } = useCompareLabels()

  const [dinoTags, setDinoTags] = useState([])
  const [imageData, setImageData] = useState({})
  const [action, setAction] = useState('compare')
  const [loading, setLoading] = useState(false)

  const formatData = (data) => {
    return data.map((item) => ({
      id: item.id,
      image: item.image,
      userId: item.labeler ? item.labeler.id : null,
      reviewer: item.tagReviewerId,
      name: item.key.split('/')[item.key.split('/').length - 1],
      date: item.createdAt,
      key: item.key,
      width: item.width,
      height: item.height,
      done: item.done,
      accepted: item.accepted,
      downloaded: false,
      skiped: item.skiped
    }))
  }

  useEffect(() => {
    const getSingleImage = async () => {
      const response = await getImage(selectedImage.id)
      setImageData(formatData([response.data.getTag]))
    }
    getSingleImage()
  }, [])

  const dinoTagsFunction = async () => {
    setLoading(true)

    try {
      const dinoTags = await getDinoTags({
        path_images: [decodeURIComponent(imageData?.[0]?.key)?.replace(/\+/g, ' ')],
        categoria: dinoText,
        box_threshold: 0.35,
        text_threshold: 0.25,
        project_id: project.id
      })

      const onCompareCall = async () => {
        if (selectedImage.id) {
          try {
            const formattedId = `${selectedImage.id}`
            const response = await getComparisonIoU(selectedImage.id, `g-${formattedId}`)
            dispatch({ type: 'setCompareImage', payload: selectedImage })
            dispatch({
              type: 'setDataComparison',
              payload: { ...response.data, ...selectedImage.id }
            })
          } catch (error) {
            console.error('Error getting IoU comparison:', error)
          }
        }
      }

      onCompareCall()

      const imageTagsData = dinoTags.data.body
      const imageTags = imageTagsData[decodeURIComponent(imageData?.[0]?.key)?.replace(/\+/g, ' ')]
      setDinoTags(imageTags)
      drawDinoTagsCanvas()
    } catch (error) {
      console.error('Error at dinoTagsFunction', error.name, error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    setAction('compare')
    setDinoText('')
    setDinoTags([])
    resetCompareId()
    dispatch({ type: 'setOpenCompareDialog', payload: false })
    dispatch({ type: 'setCompareImage', payload: null })
    dispatch({ type: 'setDataComparison', payload: null })
  }

  const imageRef = useRef(null)
  const canvasImageRef = useRef(null)
  const divImageRef = useRef(null)
  const canvasTagsRef = useRef(null)
  const canvasCompareTagsRef = useRef(null)
  const canvasDinoTagsRef = useRef(null)

  const ctxCanvasTags = canvasTagsRef.current?.getContext('2d')
  const ctxCanvasCompareTags = canvasCompareTagsRef.current?.getContext('2d')
  const ctxCanvasDinoTags = canvasDinoTagsRef.current?.getContext('2d')

  const drawImageCanvas = () => {
    if (!imageRef.current || !canvasImageRef.current || !divImageRef.current) return

    const ctxCanvasImage = canvasImageRef.current.getContext('2d')

    const scaleX = divImageRef.current.clientWidth / imageRef.current.width
    const scaleY = divImageRef.current.clientHeight / imageRef.current.height

    canvasImageRef.current.width = divImageRef.current.clientWidth
    canvasImageRef.current.height = divImageRef.current.clientHeight

    ctxCanvasImage.setTransform(scaleX, 0, 0, scaleY, 0, 0)
    ctxCanvasImage.drawImage(imageRef.current, 0, 0)
  }

  const drawTagsCanvas = () => {
    if (
      !ctxCanvasTags ||
      !canvasTagsRef.current ||
      !divImageRef.current ||
      !selectedImage.annotations.rects
    )
      return
    ctxCanvasTags.clearRect(0, 0, canvasTagsRef.current.width, canvasTagsRef.current.height)
    canvasTagsRef.current.width = divImageRef.current.clientWidth
    canvasTagsRef.current.height = divImageRef.current.clientHeight
    const scaleX = divImageRef.current.clientWidth / imageRef.current.width
    const scaleY = divImageRef.current.clientHeight / imageRef.current.height

    selectedImage.annotations.rects.forEach((annotation) => {
      const fill = annotation.id === errorItemHover?.refBoundingBoxId ? '#ffffff' : undefined
      const border = annotation.id === errorItemHover?.refBoundingBoxId ? '#000000' : undefined

      drawAnnotation(
        annotation,
        'rect',
        true,
        scaleX,
        scaleY,
        selectedImage,
        imageRef.current,
        ctxCanvasTags,
        [],
        fill,
        border
      )
    })
  }

  const drawCompareTagsCanvas = () => {
    if (
      !ctxCanvasCompareTags ||
      !canvasCompareTagsRef.current ||
      !divImageRef.current ||
      !compareImage?.annotations?.rects
    )
      return
    ctxCanvasCompareTags.clearRect(
      0,
      0,
      canvasCompareTagsRef.current.width,
      canvasCompareTagsRef.current.height
    )

    canvasCompareTagsRef.current.width = divImageRef.current.clientWidth
    canvasCompareTagsRef.current.height = divImageRef.current.clientHeight
    const scaleX = divImageRef.current.clientWidth / imageRef.current.width
    const scaleY = divImageRef.current.clientHeight / imageRef.current.height

    compareImage.annotations.rects.forEach((annotation) => {
      const borderSize = annotation.id === errorItemHover?.evalBoundingBoxId
      drawAnnotation(
        annotation,
        'rect',
        true,
        scaleX,
        scaleY,
        compareImage,
        imageRef.current,
        ctxCanvasCompareTags,
        [],
        '#007C00',
        '#007E39',
        borderSize
      )
    })
  }

  const drawDinoTagsCanvas = () => {
    if (!ctxCanvasDinoTags || !canvasDinoTagsRef.current || !divImageRef.current || !dinoTags)
      return
    ctxCanvasDinoTags.clearRect(
      0,
      0,
      canvasDinoTagsRef.current.width,
      canvasDinoTagsRef.current.height
    )
    canvasDinoTagsRef.current.width = divImageRef.current.clientWidth
    canvasDinoTagsRef.current.height = divImageRef.current.clientHeight
    const scaleX = divImageRef.current.clientWidth / imageRef.current.width
    const scaleY = divImageRef.current.clientHeight / imageRef.current.height

    const formattedDinoTags = dinoTags?.map((tag, index) => {
      return {
        color: '#000000',
        id: index,
        name: 'dino',
        pos: [tag[0], tag[1], tag[2], tag[3]],
        type: 'bounding_box'
      }
    })

    formattedDinoTags.forEach((annotation) => {
      drawAnnotation(
        annotation,
        'rect',
        true,
        scaleX,
        scaleY,
        selectedImage,
        imageRef.current,
        ctxCanvasDinoTags,
        []
      )
    })
  }

  const { width, height } = useResizeImageContainer(selectedImage.src, !open, 10, drawImageCanvas)

  useEffect(() => {
    drawImageCanvas()
  }, [width, height])

  useEffect(() => {
    drawTagsCanvas()
  }, [imageRef.current, errorItemHover])

  useEffect(() => {
    drawCompareTagsCanvas()
  }, [compareImage, errorItemHover])

  useEffect(() => {
    drawDinoTagsCanvas()
  }, [dinoTags, state])

  const divImageContainer = {
    width: width * 0.5,
    height: height * 0.5,
    maxWidth: '100%',
    maxHeight: '100%',
    display: 'flex',
    overflow: 'hidden',
    justifyContent: 'center'
  }

  const dialogContentContainer = {
    width,
    height: 'auto',
    display: 'flex',
    minWidth: '100%',
    overflow: 'hidden'
  }

  const handleDinoText = (e) => {
    setDinoText(e.target.value)
  }

  const resetFunction = () => {
    setDinoTags([])
    setDinoText('')
    dispatch({ type: 'setDataComparison', payload: null })
    resetCompareId()

    ctxCanvasCompareTags.clearRect(
      0,
      0,
      canvasCompareTagsRef.current.width * 100,
      canvasCompareTagsRef.current.height * 100
    )

    ctxCanvasDinoTags.clearRect(
      0,
      0,
      canvasDinoTagsRef.current.width * 100,
      canvasDinoTagsRef.current.height * 100
    )

    drawTagsCanvas()
  }

  return (
    <DialogMainContainer maxWidth={'xl'} onClose={handleClose} open={open}>
      <DialogTitle>Compare Labels</DialogTitle>
      <DialogCloseIconButton aria-label="close" onClick={handleClose}>
        <CloseIcon />
      </DialogCloseIconButton>
      <div style={dialogContentContainer}>
        {selectedImage && (
          <MainContentContainer>
            <GridContainer>
              <MainImageContainer>
                <div ref={divImageRef} style={divImageContainer}>
                  <canvas ref={canvasImageRef} style={{ position: 'absolute' }} />
                  <canvas ref={canvasTagsRef} style={{ position: 'absolute' }} />
                  <canvas ref={canvasCompareTagsRef} style={{ position: 'absolute' }} />
                  <canvas ref={canvasDinoTagsRef} style={{ position: 'absolute' }} />
                  <img
                    alt={selectedImage.name}
                    ref={imageRef}
                    style={{ display: 'none' }}
                    src={selectedImage.src}
                  />
                </div>
              </MainImageContainer>
              <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <InfoContainer>
                <ErrorList dataComparison={dataComparison} />
              </InfoContainer>
            </GridContainer>
          </MainContentContainer>
        )}
      </div>
      <DialogActions>
        <ActionsContainer>
          <ButtonGroup variant="outlined" aria-label="outlined primary button group">
            <Button
              variant={action === 'compare' ? 'contained' : 'outlined'}
              onClick={() => setAction('compare')}
            >
              Compare
            </Button>
            <Button
              variant={action === 'check' ? 'contained' : 'outlined'}
              onClick={() => setAction('check')}
            >
              Check
            </Button>
          </ButtonGroup>
          <FormContainer>
            <TextField
              label={action === 'compare' ? 'Compare with (Tag Id)' : 'Text'}
              value={action === 'compare' ? compareId : dinoText}
              onChange={action === 'compare' ? handleCompareIdChange : (e) => handleDinoText(e)}
            />
            <Button
              size="medium"
              variant="contained"
              onClick={action === 'compare' ? onCompareCall : dinoTagsFunction}
            >
              {action === 'compare' ? 'Compare' : 'Check'}
            </Button>
            <Button size="medium" variant="outlined" onClick={resetFunction}>
              {'reset'}
            </Button>
          </FormContainer>
        </ActionsContainer>
      </DialogActions>
    </DialogMainContainer>
  )
}

export default CompareLabelsModal
