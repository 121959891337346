import { useLayoutEffect, useContext } from 'react'

import { LabelerContext } from '../../../context/LabelerContext'
import { useLabelerMachine } from '../../../context/MachineContext'

export default function UseResizeCategoryCanvas(canvasList = [], parent = '') {
  const { state: MachineState, send } = useLabelerMachine()
  const { state } = useContext(LabelerContext)
  const { labelerState } = state

  useLayoutEffect(() => {
    if (MachineState.value === 'prepareLayer' || parent === '') {
      const tools = labelerState.tools
      canvasList.forEach((canvas) => {
        if (canvas.current !== null) {
          const c = canvas.current
          c.width = tools.width
          c.height = tools.height
        }
      })
      if (labelerState.projectCategories.at(-1).name === parent) {
        send({ type: 'RESOLVE', source: 'useResizeCategory' })
      }
    }
  }, [MachineState.value])
}
