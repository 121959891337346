import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import { styled } from '@mui/material/styles'
import { getUser, getFileSize, getAllowFiles } from '../utils/util'
import { createDataset } from '../services/graphql'
import LoaderButton from './LoaderButton'
import ImageDropzone from './generics/ImageDropzone'
import { useSnackbar } from './snackbar/context/SnackbarContext'
import {
  sandDatasetCreated,
  sandImagesAddedToDataset,
  sandImageUploaded,
  sandImageFailed
} from '../io/analytics'
import '../styles/Upload.css'
import { Typography } from '@mui/material'
import {
  _getFilesSize,
  getPercentage,
  removeFirst,
  uploadConfig,
  uploadFile
} from '../utils/datasets'

const Img = styled('img')({
  margin: 'auto',
  maxWidth: '220px',
  maxHeight: '100%'
})

const MyCircularProgress = styled(CircularProgress)(() => ({
  position: 'absolute',
  margin: '0px',
  width: '60px !important',
  height: '60px !important',
  top: '6px',
  left: '6px',
  zIndex: 10
}))

function Upload(props) {
  const [datasetCreated, setDatasetCreated] = useState(false)
  const [dataset, setDataset] = useState({ value: '', state: null })
  const [toUploadFiles, setToUploadFiles] = useState([])
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [dropzoneActive, setDropzoneActive] = useState(true)
  const [newDataset, setNewDataset] = useState(null) // ?
  const [retries, setRetries] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const { showSnackbar } = useSnackbar()
  const navigate = useNavigate()

  useEffect(() => {
    if (props.dataset) {
      setDatasetCreated(true)
      setDataset({
        value: props.dataset.name,
        state: null
      })
      setNewDataset(props.dataset)
    }
  }, [props.dataset])

  useEffect(() => {
    if (newDataset) {
      if (props.datasetCreated) {
        props.datasetCreated(newDataset)
      }
    }
  }, [newDataset])

  useEffect(() => {
    _uploadFiles()
  }, [uploadedFiles, toUploadFiles, retries])

  const _handleChange = (e) => {
    setDataset({ value: e.target.value, state: null })
  }

  const _onSubmit = async (event) => {
    event.preventDefault()
    if (dataset.value.length < 1) {
      showSnackbar({
        message: 'Please set a name before uploading',
        type: 'warning'
      })

      return
    }

    setIsLoading(true)

    const user = getUser()
    if (dataset.value.length > 1) {
      createDataset({
        datasetUserId: user.id,
        name: dataset.value,
        count: 0,
        status: 'created'
      })
        .then((res) => {
          const dataset = res.data.createDataset
          sandDatasetCreated({ id: dataset.id, name: dataset.name })

          delete dataset.count
          delete dataset.status
          delete dataset.description
          delete dataset.user.termsAccepted
          delete dataset.user.datasets
          delete dataset.user.company
          delete dataset.user.status
          delete dataset.user.owner
          delete dataset.user.email

          setDatasetCreated(true)
          setIsLoading(false)
          setNewDataset(dataset)
          showSnackbar({
            message: 'Dataset created, please upload your images (JPG or PNG).',
            type: 'success'
          })

          uploadConfig(JSON.stringify(dataset), dataset.id)
            .then((r) => console.log(r))
            .catch((e) => console.log(e))
        })
        .catch((error) => {
          console.log(error)
          setIsLoading(false)
          showSnackbar({
            message: 'Error creating dataset.',
            type: 'error'
          })
        })
    } else {
      setIsLoading(false)
      showSnackbar({
        message: 'Please set a NAME and try again',
        type: 'error'
      })
    }
  }

  // Second parameter can be _rejectedFiles
  const onDrop = async (acceptedFiles) => {
    sandImagesAddedToDataset({
      id: newDataset?.id,
      name: newDataset?.name,
      count: acceptedFiles.length
    })
    setDropzoneActive(false)

    const { allowedFiles, rejectedFiles } = getAllowFiles(acceptedFiles)
    if (rejectedFiles.length > 0) {
      showSnackbar({
        message: 'You may only upload PNG, JPEG, JPG files',
        type: 'warning'
      })
    }
    setToUploadFiles([...toUploadFiles, ...allowedFiles])
  }

  const _uploadFiles = () => {
    if (newDataset && newDataset.id && toUploadFiles.length > 0) {
      const file = toUploadFiles[0]

      if (!_fileWasUpload(file.name)) {
        uploadFile(file, newDataset.id)
          .then((attachment) => {
            setUploadedFiles([...uploadedFiles, file])
            setToUploadFiles(removeFirst(toUploadFiles))
            sandImageUploaded({ name: file, datasetId: dataset.name, datasetName: dataset.name })

            if (props.imageUploaded) {
              props.imageUploaded(attachment)
            }
          })
          .catch(() => {
            sandImageFailed({ datasetId: newDataset.id, datasetName: newDataset.name, file })
            showSnackbar({
              message: `Error uploading image:  ${file.name} Retry:${retries + 1}`,
              type: 'error'
            })

            setRetries(retries + 1)
          })
      } else {
        setToUploadFiles(removeFirst(toUploadFiles))
      }
    } else {
      setDropzoneActive(true)
    }
  }

  const _fileWasUpload = (fileName) => {
    for (let i = 0; i < uploadedFiles.length; i++) {
      if (uploadedFiles[i].name === fileName) {
        showSnackbar({
          message: `There is a duplicate image name: ${fileName}`,
          type: 'error'
        })
        return true
      }
    }
    return false
  }

  const NameForm = () => (
    <form onSubmit={_onSubmit}>
      <Grid container>
        <Grid item style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <p style={{ alignSelf: 'center', paddingTop: 7.5, fontWeight: 'bold', marginLeft: 18 }}>
            Dataset Name:
          </p>
        </Grid>
        <Grid item xs={5}>
          <TextField
            sx={{ marginLeft: 1, width: '100%' }}
            id="dataset"
            name="dataset"
            state={dataset.state}
            type="text"
            size="small"
            disabled={datasetCreated || isLoading || props.createDisable}
            placeholder="Enter dataset name"
            value={dataset.value}
            onChange={_handleChange}
            variant="outlined"
            autoFocus
          />
        </Grid>
        <Grid item xs={4} style={{ textAlign: 'center' }}>
          {!datasetCreated && !props.createDisable && (
            <LoaderButton
              style={{ marginTop: 2 }}
              isLoading={isLoading}
              type="submit"
              text="Ok"
              loadingText="Loading…"
            />
          )}
        </Grid>
      </Grid>
    </form>
  )

  const CreateProjectButton = () => {
    if (datasetCreated && uploadedFiles.length > 0 && props.buttonProject) {
      return (
        <>
          <Button variant="contained" color="secondary" onClick={() => navigate('/createProject')}>
            Create project
          </Button>
          <br />
        </>
      )
    }
    return null
  }

  const ImageProgress = () => (
    <div style={{ position: 'relative', width: 72, height: 72, margin: '10px auto' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%'
        }}
      >
        <Typography variant="h6">
          {toUploadFiles.length <= 0 && getPercentage(uploadedFiles, toUploadFiles) === 0
            ? 'None'
            : getPercentage(uploadedFiles, toUploadFiles) + '%'}
        </Typography>
      </div>
      {dropzoneActive ||
      (toUploadFiles.length <= 0 && getPercentage(uploadedFiles, toUploadFiles) === 0) ? null : (
        <MyCircularProgress color="secondary" />
      )}
      <MyCircularProgress
        variant="determinate"
        value={Number(getPercentage(uploadedFiles, toUploadFiles))}
      />
    </div>
  )

  const ImageList = () => (
    <Grid container style={{ overflow: 'auto', textAlign: 'center', maxHeight: '80vh' }}>
      <Grid item>
        <Typography variant="h6" style={{ margin: '10px' }}>
          {fSize === 0
            ? ufSize === 0
              ? 'Ready to upload your images'
              : 'Drop your files (JPG or PNG)'
            : 'Uploading...'}
        </Typography>
        <Typography variant="body2">{getFileSize(ufSize)} uploaded</Typography>
      </Grid>
      {uploadedFiles.reverse().map((file, index) => (
        <Grid
          item
          key={index}
          style={{
            width: '100%',
            margin: 0,
            textAlign: 'left',
            borderBottom: '1px solid #C4C4C4'
          }}
        >
          <Typography
            variant="body2"
            style={{ paddingTop: '5px', paddingLeft: '5px', margin: 0 }}
            noWrap
          >
            {file.name}
          </Typography>
          <Typography variant="caption" style={{ paddingLeft: '5px' }}>
            {getFileSize(file.size)}
          </Typography>
        </Grid>
      ))}
    </Grid>
  )

  const MyDropZone = () => (
    <div style={{ margin: '10px 5px' }}>
      <ImageDropzone onDrop={onDrop}>
        {({ getRootProps, getInputProps }) => (
          <section
            style={{
              borderStyle: 'dashed',
              borderColor: '#888',
              width: '100%',
              height: '400px',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <div {...getRootProps()}>
              <Img src="assets/upload_icon.png" />
              <br />
              <br />
              <input {...getInputProps()} />
              <Typography variant="body2">
                Drop <b>images</b> here.
                <br />
                No annotations, just images!
              </Typography>
            </div>
          </section>
        )}
      </ImageDropzone>
      {!props.createDisable && (
        <Typography align="center">
          Go see your new dataset <Link to="/datasets">here</Link>
        </Typography>
      )}
    </div>
  )

  const ufSize = _getFilesSize(uploadedFiles)
  const fSize = _getFilesSize(toUploadFiles)

  return (
    <div style={{ margin: '10px 10%' }}>
      <Paper sx={{ borderRadius: '12px', padding: '15px 3%', margin: '0px' }}>
        <Grid container style={{ padding: '1em 0px' }}>
          <Grid item xs={10} sm={9}>
            <NameForm />
          </Grid>
          <Grid
            item
            xs={2}
            sm={3}
            style={{
              textAlign: 'center',
              borderLeftStyle: 'solid',
              borderWidth: '1px',
              height: '100%',
              borderColor: '#3333'
            }}
          >
            <CreateProjectButton />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              height: '1px',
              width: '100%',
              backgroundColor: '#333',
              margin: '1rem 0rem'
            }}
          />
          <Grid item xs={8} sm={9}>
            {dataset.value?.length > 0 && datasetCreated ? <MyDropZone /> : null}
          </Grid>
          <Grid item xs={4} sm={3}>
            {dataset.value?.length > 0 && datasetCreated ? (
              <>
                <ImageProgress />
                <ImageList />
              </>
            ) : null}
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}

export default Upload
