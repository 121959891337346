/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": process.env.REACT_APP_AWS_REGION,
    "aws_appsync_graphqlEndpoint": process.env.REACT_APP_AWS_GRAPH_URL,
    "aws_appsync_region": process.env.REACT_APP_AWS_REGION,
    "aws_appsync_authenticationType": process.env.REACT_APP_AWS_AUTH_TYPE,
    "aws_appsync_apiKey": process.env.REACT_APP_AWS_API_KEY,
    "aws_cognito_identity_pool_id": process.env.REACT_APP_AWS_IDENTITY_POOL,
    "aws_cognito_region": process.env.REACT_APP_AWS_REGION,
    "aws_user_pools_id": process.env.REACT_APP_AWS_POOL,
    "aws_user_pools_web_client_id": process.env.REACT_APP_AWS_POOL_CLIENT,
    "oauth": {
        "domain": "linkedaip.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": process.env.REACT_APP_GOOGLE_REDIRECT,
        "redirectSignOut": process.env.REACT_APP_GOOGLE_REDIRECT,
        "responseType": "code"
    },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": process.env.REACT_APP_AWS_BUCKET,
    "aws_user_files_s3_bucket_region": process.env.REACT_APP_AWS_REGION
};


export default awsmobile;
