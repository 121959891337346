import { put, takeLatest, all } from 'redux-saga/effects'
import { project as p } from './actions'
import {
  getProject as gtp,
  getViewProject as gtvp,
  updateProject as upp,
  getMember,
  getTeam,
  listAllMembers,
  // eslint-disable-next-line no-unused-vars
  getTeamIdProject as gtip
} from '../../services/graphql'
import { getImage as gimg } from './services'
import { getLastWeek, getLastMonth, getUserInTeam2, getUser } from '../../utils/util'
import {
  getDataAllTags,
  getDataDoneDaily,
  getDataDoneReviewed,
  getDataImagesLabeler,
  getDataImagesReviewer,
  getDataImagesSkippedLabeler,
  getDataLabelerTags,
  getDataReviewed,
  getDataReviewedDaily,
  getDataReviewedTags,
  getDataSave,
  getDataSkipped,
  getDataSkippedDaily,
  getDataSkippedReviewed,
  getDataTagsLabeler,
  getDataTagsReviewer
} from '../api/metrics'

function* getProject({ payload }) {
  try {
    const response = yield gtp(payload.id)
    const _project = response.data.getProject

    let team = _project.team
    if (_project.team) {
      team = yield getTeam(_project.team.id)
        .then((response) => response)
        .catch((err) => err)
      const members = yield listAllMembers(team.data.getTeam.id)
        .then((response) => response.members)
        .catch((error) => {
          console.log('error', error)
          return []
        })

      team = {
        ...team,
        data: { ...team.data, getTeam: { ...team.data.getTeam, members: { items: members } } }
      }
    }

    if (_project) {
      const dataDoneReviewed = yield getDataDoneReviewed({ projectId: payload.id })
        .then((response) => response)
        .catch((error) => error)

      const dataSkippedReviewed = yield getDataSkippedReviewed({ projectId: payload.id })
        .then((response) => response)
        .catch((error) => error)

      yield put(
        p.setProject({
          ..._project,
          team: team && team.data ? team.data.getTeam : team,
          imagesSkippedReviewedCount:
            dataSkippedReviewed.data.aggregations.skipped_and_reviewed_count.doc_count,
          imagesDoneReviewedCount:
            dataDoneReviewed.data.aggregations.done_and_reviewed_count.doc_count
        })
      )

      // TODO: Don't call this for sharedProjects
      yield put(p.getImagesStats(payload.id, team))
      yield put(p.getLabelsStats(payload.id, team))
    }
  } catch (error) {
    console.log('error', error)
    const err = new TypeError('ERROR_GET_PROJECT')
    yield put(p.setProject(err))
  }
}

function* getCategories({ payload }) {
  try {
    const response =
      payload.role && payload.role === 'viewer' ? yield gtvp(payload.id) : yield gtp(payload.id)
    const _project = response.data.getProject
    if (_project)
      yield put(
        p.setCategories({
          categories: _project.categories,
          categoriesCount: _project.categoriesCount
        })
      )
  } catch (error) {
    console.log('error', error)
    const err = new TypeError('ERROR_GET_PROJECT')
    yield put(p.setProject(err))
  }
}

function* updateProject({ payload }) {
  try {
    const response = yield upp({ id: payload.id, categories: payload.categories })
    const _project = response.data.updateProject
    if (_project) {
      yield put(p.setProject(_project))
    }
  } catch (error) {
    console.log('error', error)
    const err = new TypeError('ERROR_UPDATE_PROJECT')
    yield put(p.setProject(err))
  }
}

function* updateNameProject({ payload }) {
  try {
    const response = yield upp({ id: payload.id, name: payload.name })
    const _project = response.data.updateProject
    if (_project) {
      yield put(p.setProject(_project))
    }
  } catch (error) {
    console.log('error', error)
    const err = new TypeError('ERROR_UPDATE_PROJECT')
    yield put(p.setProject(err))
  }
}

// eslint-disable-next-line require-yield
function* getImages({ payload }) {
  console.log('PAYLOAD', payload)
}

function* getImage({ payload }) {
  try {
    const r = yield gimg(payload.id)

    const img = r.data.getTag
    yield put(p.setImages([img], img, 0))
  } catch (error) {
    console.log('error', error)
    const err = new TypeError('ERROR_GET_IMAGES')
    yield put(p.setImage(err))
  }
}

function* getRole({ payload }) {
  try {
    const r = yield getMember(payload.userId, payload.teamId)
    const role = r.data.getMember.role
    yield put(p.setRole(role))
  } catch (error) {
    console.log('error', error)
    const err = new TypeError('ERROR_GET_PROJECT')
    yield put(p.setRole(err))
  }
}

function* getImagesStats({ payload }) {
  const dataSave = yield getDataSave(payload)
    .then((response) => response)
    .catch((error) => error)

  const dataReviewed = yield getDataReviewed(payload)
    .then((response) => response)
    .catch((error) => error)

  const dataSkipped = yield getDataSkipped(payload)
    .then((response) => response)
    .catch((error) => error)

  const dataDoneDaily = yield getDataDoneDaily(payload)
    .then((response) => response)
    .catch((error) => error)

  const dataReviewedDaily = yield getDataReviewedDaily(payload)
    .then((response) => response)
    .catch((error) => error)

  const dataSkippedDaily = yield getDataSkippedDaily(payload)
    .then((response) => response)
    .catch((error) => error)

  const week1 = getLastWeek(dataReviewedDaily.data.aggregations['2'].buckets)
  const week2 = getLastWeek(dataDoneDaily.data.aggregations['2'].buckets)
  const week3 = getLastWeek(dataSkippedDaily.data.aggregations['2'].buckets)

  const month1 = getLastMonth(dataReviewedDaily.data.aggregations['2'].buckets)
  const month2 = getLastMonth(dataDoneDaily.data.aggregations['2'].buckets)
  const month3 = getLastMonth(dataSkippedDaily.data.aggregations['2'].buckets)

  const all1 = dataReviewedDaily.data.aggregations['2'].buckets
  const all2 = dataDoneDaily.data.aggregations['2'].buckets
  const all3 = dataSkippedDaily.data.aggregations['2'].buckets

  const saved = dataSave.data.aggregations['2'].buckets.map((item) => {
    return {
      doc_count: item.doc_count,
      key: payload.team ? getUserInTeam2(payload.team.data.getTeam, item.key) : getUser().email,
      id: item.key,
      done_count: item.done_count.value,
      skipped_count: item.skiped_count.value
    }
  })

  const reviewed = dataReviewed.data.aggregations['2'].buckets.map((item) => {
    return {
      doc_count: item.doc_count,
      key: payload.team ? getUserInTeam2(payload.team.data.getTeam, item.key) : getUser().email,
      id: item.key
    }
  })

  const skipped = dataSkipped.data.aggregations['2'].buckets.map((item) => {
    return {
      doc_count: item.doc_count,
      key: payload.team ? getUserInTeam2(payload.team.data.getTeam, item.key) : getUser().email,
      id: item.key
    }
  })

  switch (dataReviewedDaily.status) {
    case 200:
      // eslint-disable-next-line no-case-declarations
      const data = {
        barSaved: { week: week2, month: month2, all: all2 },
        barReviewed: { week: week1, month: month1, all: all1 },
        barSkipped: { week: week3, month: month3, all: all3 },
        saved,
        reviewed,
        skipped
      }
      yield put(p.setImagesStats(data))
      break

    default:
      break
  }
}
function* getLabelsStats({ payload }) {
  try {
    const dataAll = yield getDataAllTags(payload)
      .then((response) => response)
      .catch((error) => error)

    const dataReviewed = yield getDataReviewedTags(payload)

    const dataLabeler = yield getDataLabelerTags(payload)

    const all = dataAll.data.aggregations['2'].buckets.map((item) => {
      return {
        doc_count: item['1'].value,
        key: payload.team ? getUserInTeam2(payload.team.data.getTeam, item.key) : getUser().email,
        id: item.key
      }
    })
    const reviewed = dataReviewed.data.aggregations['2'].buckets.map((item) => {
      return {
        doc_count: item['1'].value,
        key: payload.team ? getUserInTeam2(payload.team.data.getTeam, item.key) : getUser().email,
        id: item.key
      }
    })
    const labeler = dataLabeler.data.aggregations['2'].buckets.map((item) => {
      return {
        doc_count: item['1'].value,
        key: payload.team ? getUserInTeam2(payload.team.data.getTeam, item.key) : getUser().email,
        id: item.key
      }
    })

    switch (dataReviewed.status) {
      case 200:
        // eslint-disable-next-line no-case-declarations
        const data = { labeler, reviewed, all }
        yield put(p.setLabelsStats(data))
        break

      default:
        break
    }
  } catch (error) {
    console.log('error', error)
  }
}
function* getLabelerMetrics({ payload }) {
  try {
    const images = yield getDataImagesLabeler(payload)

    const tags = yield getDataTagsLabeler(payload)

    const editedTags = tags.data.aggregations['2'].buckets.map((item) => {
      return { ...item, doc_count: item['1'].value }
    })

    const imagesSkipped = yield getDataImagesSkippedLabeler(payload)

    if (images.status === 200 && tags.status === 200) {
      const week1 = getLastWeek(images.data.aggregations['2'].buckets)
      const week2 = getLastWeek(editedTags)
      const week3 = getLastWeek(imagesSkipped.data.aggregations['2'].buckets)

      const month1 = getLastMonth(images.data.aggregations['2'].buckets)
      const month2 = getLastMonth(editedTags)
      const month3 = getLastMonth(imagesSkipped.data.aggregations['2'].buckets)

      const all1 = images.data.aggregations['2'].buckets
      const all2 = editedTags
      const all3 = imagesSkipped.data.aggregations['2'].buckets

      const data = {
        images: { week: week1, month: month1, all: all1 },
        labels: { week: week2, month: month2, all: all2 },
        skipped: { week: week3, month: month3, all: all3 }
      }
      yield put(p.setLabelerStats(data))
    }
  } catch (error) {
    console.log('error', error)
  }
}
function* getReviwerMetrics({ payload }) {
  try {
    const images = yield getDataImagesReviewer(payload)

    const tags = yield getDataTagsReviewer(payload)

    const editedTags = tags.data.aggregations['2'].buckets.map((item) => {
      return { ...item, doc_count: item['1'].value }
    })

    if (images.status === 200 && tags.status === 200) {
      const week1 = getLastWeek(images.data.aggregations['2'].buckets)
      const week2 = getLastWeek(editedTags)
      const month1 = getLastMonth(images.data.aggregations['2'].buckets)
      const month2 = getLastMonth(editedTags)
      const all1 = images.data.aggregations['2'].buckets
      const all2 = editedTags

      const data = {
        images: { week: week1, month: month1, all: all1 },
        labels: { week: week2, month: month2, all: all2 }
      }
      yield put(p.setReviwerStats(data))
    }
  } catch (error) {
    console.log('error', error)
  }
}

function* ActionWatcher() {
  yield takeLatest(p.getProject, getProject)
  yield takeLatest(p.getCategories, getCategories)
  yield takeLatest(p.updateProject, updateProject)
  yield takeLatest(p.updateNameProject, updateNameProject)
  yield takeLatest(p.getImages, getImages)
  yield takeLatest(p.getImage, getImage)
  yield takeLatest(p.getRole, getRole)
  yield takeLatest(p.getImagesStats, getImagesStats)
  yield takeLatest(p.getLabelsStats, getLabelsStats)
  yield takeLatest(p.getLabelerStats, getLabelerMetrics)
  yield takeLatest(p.getReviwerStats, getReviwerMetrics)
}

export default function* rootSaga() {
  yield all([ActionWatcher()])
}
