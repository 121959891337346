import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button } from '@mui/material'
import { Card, ProgressTitle, TitleContent, TagCounter, GroupButton } from '../StyledComponents.jsx'
import LabelsCounterTable from './components/LabelsCounterTable.jsx'
import PieChart from '../../../../../../components/charts/Pie.jsx'
import { formatPieChartData, getTotalTags } from './utils/labelCounterCardUtils.js'

const LabelsMetricsCard = ({ project, isPaid, labelFilter, setLabelFilter }) => {
  const [catsPieData, setCatsPieData] = useState([])

  useEffect(() => {
    const totalTags = getTotalTags(labelFilter, project)
    const formattedData = formatPieChartData(project, labelFilter, totalTags)
    setCatsPieData(formattedData)
  }, [project, labelFilter])

  const totalTags = getTotalTags(labelFilter, project)

  return (
    <Card elevation={2}>
      <TitleContent>
        <ProgressTitle variant="h6">
          Labels (<TagCounter>{totalTags.toLocaleString()}</TagCounter>)
        </ProgressTitle>
        <GroupButton size="small" color="primary">
          <Button
            variant={labelFilter === 'all' ? 'contained' : 'outlined'}
            onClick={() => setLabelFilter('all')}
          >
            All
          </Button>
          <Button
            variant={labelFilter === 'saved' ? 'contained' : 'outlined'}
            onClick={() => setLabelFilter('saved')}
          >
            Done
          </Button>
          {isPaid && (
            <Button
              variant={labelFilter === 'reviewed' ? 'contained' : 'outlined'}
              onClick={() => setLabelFilter('reviewed')}
            >
              Reviewed
            </Button>
          )}
        </GroupButton>
      </TitleContent>
      <LabelsCounterTable
        labelFilter={labelFilter}
        catsPieData={catsPieData}
        totalTags={totalTags}
      />
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: '60%', position: 'relative' }}>
          {!project.loading && <PieChart data={catsPieData} />}
        </div>
      </div>
    </Card>
  )
}

const mapStateToProps = (state) => ({
  project: state.project
})

export default connect(mapStateToProps, null)(LabelsMetricsCard)
