import { useState } from 'react'
import { useEventListener } from './useEventListener'

export function useOnFocus({ onActive, onIdle }) {
  const [windowFocus, setWindowFocus] = useState(false)
  useEventListener('visibilitychange', handleFocus)

  function handleFocus(event) {
    if (event.target.visibilityState === 'visible') {
      setWindowFocus(true)
      onActive()
    } else {
      setWindowFocus(false)
      onIdle()
    }
  }
  return windowFocus
}
