import { useState } from 'react'
import { Fab, Slide, IconButton } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { styled } from '@mui/material/styles'
import DiscordIcon from './icons/DiscordIcon'
import CornerIcon from './icons/CornerIco'

const HelpButton = () => {
  const [hide, setHide] = useState(false)

  const Container = styled('div')(() => ({
    position: 'absolute',
    bottom: hide ? 0 : 20,
    right: hide ? 0 : 10,
    overflow: 'hidden'
  }))

  return (
    <Container>
      {hide ? (
        <Slide in={hide} direction="up">
          <div style={{ marginBottom: '-6px' }}>
            <CornerIcon
              onClick={() => setHide(false)}
              fontSize="large"
              sx={{ margin: 0, padding: 0 }}
            />
          </div>
        </Slide>
      ) : (
        <Slide in={!hide} direction="left">
          <div
            style={{
              display: 'flex'
            }}
          >
            <Fab
              sx={{
                boxShadow: 'none',
                backgroundColor: '#2c0f6d',
                color: '#fff',
                textDecoration: 'none',
                '&:hover': { backgroundColor: '#451a98', color: '#fff' },
                '&:focus': {
                  backgroundColor: '#451a98',
                  color: '#fff',
                  textDecoration: 'none',
                  outline: '#2c0f6d solid 1px'
                }
              }}
              variant="extended"
              size="small"
              target="_blank"
              href={'https://discord.gg/memBXABpt5'}
            >
              <DiscordIcon sx={{ mr: 1 }} color="default" />
              Need Help ?
            </Fab>
            <IconButton
              aria-label="close-help"
              sx={{ padding: '2px' }}
              onClick={() => setHide(true)}
            >
              <CancelIcon />
            </IconButton>
          </div>
        </Slide>
      )}
    </Container>
  )
}

export default HelpButton
