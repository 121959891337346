/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from 'react'
import { DispatchContext } from '../../../containers/labeler/context/DispatchContext'
import { LabelerContext } from '../../../containers/labeler/context/LabelerContext'
import { listImages, listReviewImages, getAllImages } from '../../../services/graphql'
import useRole from '../../labeler/useRole'
import { useLabelerMachine } from '../../../containers/labeler/context/MachineContext'
import { useSnackbar } from '../../../components/snackbar/context/SnackbarContext'
import { getImage as getSingleImg } from '../../../io/project/services'

export default function useGetPrevImages(props, token) {
  const role = useRole(props._role)
  const [nextToken, setNextToken] = useState(null)
  const { state: MachineState, send } = useLabelerMachine()
  const {
    state: {
      labelerState: {
        image: { index, images, navigation, loading },
        role: roleContext
      }
    }
  } = useContext(LabelerContext)
  const { dispatch } = useContext(DispatchContext)

  const { showSnackbar } = useSnackbar()

  useEffect(() => {
    setNextToken(token)
  }, [token])

  useEffect(() => {
    if (navigation === 'prev') {
      send({ type: 'RESET', source: 'useGetPrevImage' })
      dispatch({ type: 'setRenderedImage', payload: false })
      getPrevImage()
    }
  }, [navigation])

  const formatImages = (images) => {
    return images.map((item) => ({
      id: item.id
    }))
  }

  const formatImage = (image) => ({
    id: image.id,
    image: image.image,
    userId: image.labeler ? image.labeler.id : null,
    reviewer: image.tagReviewerId,
    name: image.key.split('/')[image.key.split('/').length - 1],
    date: image.createdAt,
    key: image.key,
    width: image.width,
    height: image.height,
    done: image.done,
    accepted: image.accepted,
    downloaded: false,
    skiped: image.skiped
  })
  const getSingleImage = async (imageId) => {
    return await getSingleImg(imageId)
  }

  const getImagesByRole = async (projectId, labelerId, order, sortId, nextToken) => {
    try {
      switch (role.labeler) {
        case 'labeler':
          return await listImages(projectId, labelerId, order, nextToken)
        case 'reviewer':
          return await listReviewImages(projectId, labelerId, order, sortId, nextToken)
        case 'viewer':
          return await getAllImages(projectId, 20, order, nextToken)
        default:
          break
      }

      return { data: { items: [], next: null }, errors: null }
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const getPrevImage = async () => {
    dispatch({ type: 'setLoadingImage', payload: true })
    if (index >= images.length - 1) {
      if (nextToken) {
        const { data, errors } = await getImagesByRole(
          props.projectId,
          props.labelerId,
          props.order,
          props.sortId,
          nextToken,
          props.state
        )
        const newData = [...images, ...formatImages(data.items)]
        const currentImage = await getSingleImage(newData[index + 1].id)
        dispatch({ type: 'setImages', payload: { images: newData, currentImage } })
        dispatch({ type: 'setNavigation', payload: 'stateless' })

        if (newData.length > images.length) {
          dispatch({ type: 'setImage', payload: index + 1 })
          setNextToken(data.next)
          return
        }
        if (data.items.length === 0) {
          showSnackbar({
            message: 'There are no more images.',
            type: 'info'
          })
          dispatch({ type: 'setRenderedImage', payload: true })
          dispatch({ type: 'setNavigation', payload: 'return' })
        }

        dispatch({ type: 'setLoadingImage', payload: false })
        return
      }
      dispatch({ type: 'setLoadingImage', payload: false })
      showSnackbar({
        message: 'There are no more images.',
        type: 'info'
      })
      dispatch({ type: 'setRenderedImage', payload: true })
      dispatch({ type: 'setNavigation', payload: 'return' })
      return
    }
    const currentImage = await getSingleImage(images[index + 1].id)
    dispatch({
      type: 'setImages',
      payload: { images, currentImage: formatImage(currentImage.data.getTag) }
    })
    dispatch({ type: 'setLoadingImage', payload: false })
    dispatch({ type: 'setImage', payload: index + 1 })
    dispatch({ type: 'setNavigation', payload: 'stateless' })
  }

  return null
}
