import { useReducer } from 'react'
import produce from 'immer'

const classificationState = {
  tags: []
}

export const reducer = produce((state, action) => {
  switch (action.type) {
    case 'setLoadingTags':
      if (action.payload) {
        state.tags = []
      }
      break
    case 'setClassifications':
      state.tags = action.payload
      break
    case 'setClassificationSimpleValue':
      state.tags[action.payload.index].selected = action.payload.value
      break
    case 'setClassificationMultipleValue':
      state.tags[action.payload.index].values = action.payload.value
      break
  }
}, classificationState)

export function ClassificationReducer() {
  const [state, dispatch] = useReducer(reducer, classificationState)
  return { classificationState: state, classificationDispatch: dispatch }
}
