import { API, graphqlOperation } from 'aws-amplify'
import { imagesByDataset } from './queries'

// Query images and map in a constant format
export const getImages = async (id, nextToken, limit, filters) => {
  const response = await listtImages(limit || 10, nextToken, id, filters)
    .then((r) => {
      return {
        data: {
          items: r.data.imagesByDataset.items,
          nextToken: r.data.imagesByDataset.nextToken
        },
        error: false
      }
    })
    .catch((err) => {
      console.log(err)
      return { data: null, error: true }
    })

  return response
}

// List Images from Graph
export const listtImages = (limit, nextToken, id, filters) =>
  API.graphql(
    graphqlOperation(imagesByDataset, {
      limit,
      filter: { key: { contains: filters } },
      nextToken,
      imageDatasetId: id,
      sort: { field: 'createdAt', direction: 'desc' }
    })
  )
