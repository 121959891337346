import { useEffect, useRef, useContext } from 'react'
import { LabelerContext } from '../../../context/LabelerContext'
import { useLabelerMachine } from '../../../context/MachineContext'
export function useCategoryEventListener(eventName, handler, element = window, cat) {
  const { state: MachineState } = useLabelerMachine()
  const { state } = useContext(LabelerContext)
  const { labelerState } = state

  const savedHandler = useRef()

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    if (MachineState.value !== 'readyForLabeling') return
    if (cat === null || cat === labelerState.selectedCategory.type) {
      const isSupported = element && element.addEventListener
      if (!isSupported) return
      const eventListener = (event) => savedHandler.current(event)
      element.addEventListener(eventName, eventListener)
      return () => {
        element.removeEventListener(eventName, eventListener)
      }
    }
  }, [eventName, element, labelerState.selectedCategory, MachineState.value])
}
