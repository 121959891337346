/* eslint-disable camelcase */
import { useReducer } from 'react'
import produce from 'immer'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'

const segmentationState = {
  tags: { all: [], stack: [] },
  cache: [],
  cache_return: [],
  projectSegmentations: { all: [], stack: [] },
  selectedSegmentation: null,
  selectedSegmentationIndex: 0,
  invertColorGuideLine: false,
  globalAlpha: 0.5,
  paint: false,
  redraw: 0,
  loadingAI: false
}

const deleteIncompleteElement = (all, stack, selected) => {
  const lastShape = stack[stack.length - 1]
  if (lastShape && !lastShape.completed) {
    stack.pop()
    all[selected.name].shapes.pop()
  }
}

const handleControlZOperation = (operation, stack, all, cache_return) => {
  if (operation) {
    switch (operation.type) {
      case 'addMagic':
        cache_return.push({
          operation,
          stack: [...stack],
          all: JSON.parse(JSON.stringify(all))
        })
        all = all.splice(0, all.length, ...operation.all)
        stack.splice(0, stack.length, ...operation.stack)
        break
      case 'paint':
        cache_return.push({
          operation,
          stack: [...stack],
          all: JSON.parse(JSON.stringify(all))
        })
        all = all.splice(0, all.length, ...operation.all)
        stack.splice(0, stack.length, ...operation.stack)
        break
      case 'delete':
        cache_return.push({
          operation,
          stack: [...stack],
          all: JSON.parse(JSON.stringify(all))
        })
        all[operation.index].shapes = [...operation.shapes]
        stack.splice(0, stack.length, ...operation.stack)
        break
      case 'addShape':
        cache_return.push({
          operation,
          stack: [...stack],
          all: JSON.parse(JSON.stringify(all))
        })
        stack.splice(operation.index, 1)
        all[operation.indexAll].shapes.pop()
        break
      case 'addPoint':
        cache_return.push({
          operation,
          stack: [...stack],
          all: JSON.parse(JSON.stringify(all))
        })
        if (operation.indexPoint) {
          if (operation.indexPoint.a !== 0) {
            stack[operation.indexStack].points.splice(
              operation.indexPoint.a,
              operation.indexPoint.b
            )
          } else {
            stack[operation.indexStack].points.splice(1, operation.indexPoint.b)
          }
        } else {
          stack.splice(stack.length - 1, 1)
        }
        break

      default:
        break
    }
  }
}

export const reducer = produce((state, action) => {
  switch (action.type) {
    case 'setLoadingTags':
      if (action.payload) {
        state.tags = state.projectSegmentations
        state.memorizedTags = { all: [], stack: [] }
        state.cache = [] // {type:'addPoint'}, {type:'addShape'}
      }
      break
    case 'setGlobalAlpha':
      state.globalAlpha = action.payload
      state.redraw = state.redraw + 1
      break
    case 'defaultSegmentation':
      state.selectedSegmentation = action.payload
      state.selectedSegmentationIndex = action.payload.name
      break
    case 'setProjectSegmentations':
      state.projectSegmentations = action.payload
      state.tags = action.payload
      break
    case 'setSegmentation':
      state.memorizedTags = { all: [], stack: [] }
      state.tags = action.payload ? action.payload : state.projectSegmentations
      state.redraw = state.redraw + 1
      break
    case 'setSegmentationMode':
      deleteIncompleteElement(state.tags.all, state.tags.stack, state.selectedSegmentation)
      state.mode = action.payload
      break
    case 'setSelectedSegmentation':
      deleteIncompleteElement(state.tags.all, state.tags.stack, state.selectedSegmentation)
      state.selectedSegmentation = action.payload
      state.selectedSegmentationIndex = action.payload.name
      break
    case 'setSegmentationType':
      if (action.payload.obj.id) {
        state.cache = []
        state.cache.push({
          type: 'paint',
          all: JSON.parse(JSON.stringify(state.tags.all)),
          stack: JSON.parse(JSON.stringify(state.tags.stack))
        })
        const index = state.tags.all[action.payload.type].shapes.findIndex(
          (shape) => shape.id === action.payload.obj.id
        )
        const shape = state.tags.all[action.payload.type].shapes.splice(index, 1)
        state.tags.all[state.selectedSegmentationIndex].shapes.push(shape)
        state.tags.stack[action.payload.index].type = state.selectedSegmentationIndex

        state.redraw = state.redraw + 1
      }
      break
    case 'hideSegmentation':
      state.tags.stack.forEach((element, index) => {
        if (element.type === action.payload.index) {
          state.tags.stack[index].hide = action.payload.hide
        }
      })
      state.tags.all[action.payload.index].hide = action.payload.hide
      state.redraw = state.redraw + 1
      break
    case 'hideAll':
      state.tags.stack = state.tags.stack.map((segmentation) => ({
        ...segmentation,
        hide: !action.payload
      }))
      for (const segmentation in state.tags.all) {
        state.tags.all[segmentation].hide = !action.payload
      }
      state.redraw = state.redraw + 1
      break
    case 'hideSegmentationCategory':
      state.tags.stack = state.tags.stack.map((segmentation) => {
        if (segmentation.type === action.payload.name) {
          return { ...segmentation, hide: !segmentation.hide }
        }
        return segmentation
      })

      state.tags.all[action.payload.name].hide = !state.tags.all[action.payload.name].hide

      state.redraw = state.redraw + 1
      break
    case 'enterSegmentation':
      state.tags.stack.forEach((element, index) => {
        if (element.type === action.payload) {
          state.tags.stack[index].hover = true
        }
      })
      state.tags.all[action.payload].hover = true
      state.redraw = state.redraw + 1
      break
    case 'leaveSegmentation':
      state.tags.stack.forEach((element, index) => {
        if (element.type === action.payload) {
          state.tags.stack[index].hover = false
        }
      })
      state.tags.all[action.payload].hover = false
      state.redraw = state.redraw + 1
      break
    case 'addSegmentationStack':
      state.cache_return = []
      state.tags.stack.push(action.payload)
      state.cache.push({
        type: 'addPoint',
        indexStack: state.tags.stack.length - 1,
        indexPoint: null
      })
      break
    case 'addMultipleStack':
      state.cache.push({
        type: 'addMagic',
        stack: JSON.parse(JSON.stringify(state.tags.stack)),
        all: JSON.parse(JSON.stringify(state.tags.all))
      })
      state.tags.stack = [...state.tags.stack, ...action.payload]
      break
    case 'addShape':
      state.cache_return = []
      state.tags.all[action.payload.index].shapes.push(action.payload.id)
      state.cache[state.cache.length - 1] = {
        ...state.cache[state.cache.length - 1],
        indexAll: action.payload.index
      }
      break
    case 'addCompleteShape':
      state.cache_return = []
      state.cache = state.cache.filter((operation) => operation.type === 'addShape')
      state.cache.push({ type: 'addShape', index: state.tags.stack.length - 1 })
      state.tags.stack[state.tags.stack.length - 1].completed = true
      break
    case 'addPoints':
      state.cache_return = []
      // eslint-disable-next-line no-case-declarations
      const a = state.tags.stack[state.tags.stack.length - 1].points.length - 1
      state.tags.stack[state.tags.stack.length - 1].points.push(...action.payload.points)
      state.cache.push({
        type: 'addPoint',
        indexStack: state.tags.stack.length - 1,
        indexPoint: { a, b: state.tags.stack[state.tags.stack.length - 1].points.length - 1 }
      })
      break
    case 'deleteSegmentationShapes':
      state.cache_return = []
      state.cache = []
      state.cache.push({
        type: 'delete',
        index: action.payload.index,
        shapes: state.tags.all[action.payload.index].shapes,
        stack: JSON.parse(JSON.stringify(state.tags.stack))
      })
      state.tags.all[action.payload.index].shapes = []
      state.tags.stack = [
        ...state.tags.stack.filter((item) => {
          return item.type !== action.payload.index
        })
      ]
      state.redraw = state.redraw + 1
      break
    case 'redraw':
      state.redraw = state.redraw + 1
      break
    case 'ctrlZ':
      // eslint-disable-next-line no-case-declarations
      const operation = state.cache.pop()
      handleControlZOperation(operation, state.tags.stack, state.tags.all, state.cache_return)
      state.redraw = state.redraw + 1
      break
    case 'ctrlShiftZ':
      // eslint-disable-next-line no-case-declarations
      const operationreturn = state.cache_return.pop()
      if (operationreturn) {
        state.cache.push(operationreturn.operation)
        state.tags.stack = [...operationreturn.stack]
        state.tags.all = [...operationreturn.all]
        state.redraw = state.redraw + 1
      }
      break
    case 'deleteIncompleteSegmentation':
      if (state.tags.stack.at(-1).completed === false) state.tags.stack.pop()
      break
    case 'setSegmentationAiLoading':
      state.loadingAI = action.payload
      break
    default:
      break
  }
}, segmentationState)

export function SegmentationReducer() {
  const [state, dispatch] = useReducer(reducer, segmentationState)
  return { segmentationState: state, segmentationDispatch: dispatch }
}
