import React, { useEffect } from 'react'
import {
  Box,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TableContainer,
  TablePagination
} from '@mui/material'

function DefaultTable(props) {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  useEffect(() => {
    if (props.rowsPerPage > 0 && rowsPerPage !== props.rowsPerPage) {
      setRowsPerPage(props.rowsPerPage)
    }
  }, [props.rowsPerPage])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const getObject = (element, k) => {
    switch (k.type) {
      case 'colorDot':
        return (
          <Box
            sx={{
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              backgroundColor: element[k.name]
            }}
          />
        )
      case 'percentage':
        return `${k.total ? Math.round((100 * element[k.name]) / k.total) : 0}%`
      default:
        return element[k.name || k]
    }
  }

  return (
    <TableContainer className={props.className} component={Paper}>
      <Table>
        <TableHead style={props.headColor ? { background: props.headColor } : {}}>
          <TableRow>
            {props.columns.map((col) => {
              return <TableCell key={col}>{col}</TableCell>
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.clickeable
            ? props.data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((element) => (
                  <TableRow key={element.key}>
                    {props.keys.map((k) => (
                      <TableCell component="th" scope="row" key={k}>
                        <a onClick={() => props.handleClick(element.id, element.key)}>
                          {getObject(element, k)}
                        </a>
                      </TableCell>
                    ))}
                  </TableRow>
                ))
            : props.data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((element) => (
                  <TableRow key={element.key}>
                    {props.keys.map((k) => (
                      <TableCell component="th" scope="row" key={k}>
                        {getObject(element, k)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={props.rowsPerPageOptions}
        component="div"
        count={props.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  )
}

export default DefaultTable
