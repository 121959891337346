export const createTagState = /* GraphQL */ `
  mutation CreateTagState($input: CreateTagStateInput!, $condition: ModelTagStateConditionInput) {
    createTagState(input: $input, condition: $condition) {
      id
      createdAt
      stage
      action
      toBlameId
      toBlame {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      role
      updatedAt
    }
  }
`
