import { useReducer } from 'react'
import produce from 'immer'

const labelerState = {
  tools: {
    openCard: false,
    index: 0,
    reset: -1,
    scale: 1,
    panZoom: { x: 0, y: 0 },
    width: 1,
    height: 1
  },
  image: {
    loading: true,
    images: [],
    props: { w: 1, h: 1, optimalScale: 1 },
    index: -1,
    isRendered: false,
    progress: 0,
    navigation: 'init',
    currentImage: null
  },
  categories: [],
  canvasSettings: [
    { name: 'Saturation', value: 100 },
    { name: 'Brightness', value: 100 },
    { name: 'Contrast', value: 100 },
    { name: 'GrayScale', value: 0 },
    { name: 'GlobalAlpha', value: 0.5 }
  ],
  saveTags: 1,
  grid: false,
  role: 'none',
  isFeedbackPanelOpen: false,
  loadingTags: true,
  selectedCategory: { type: 'none', AI: 'none' },
  mode: 'draw', // seg : ['draw', 'erase', 'paint'] others: ['draw', 'edit']  'template' 'autoPoly'
  cursor: 'crosshair',
  isHotkeysOpen: false,
  projectCategories: [],
  smooth: true,
  redraw: 1,
  loadingAI: false,
  isHiddenCat: [],
  shortcuts: { done: 1, skip: 1, doneAndNext: 1, skipAndNext: 1 }
}

const changeCursor = (mode, type) => {
  switch (type) {
    case 'segmentation':
      switch (mode) {
        case 'erase':
          return "url('assets/eraser.cur'), auto"
        case 'paint':
          return "url('assets/paint.cur'), auto"
        default:
          return 'crosshair'
      }
    case 'polygon':
    case 'bounding_box':
      switch (mode) {
        case 'draw':
          return 'crosshair'
        case 'edit':
          return 'default'
        default:
          return 'crosshair'
      }
    default:
      switch (mode) {
        case 'draw':
          return "url('assets/aim-pre.cur'), auto"
        case 'edit':
          return 'default'
        case 'template':
          return 'none'
        default:
          return 'crosshair'
      }
  }
}

const changeModeByCategory = (prevCat, cat, prevMode) => {
  if (prevCat.type === cat.type) return prevMode
  switch (prevCat.type) {
    case 'segmentation':
      if (
        cat.type !== 'segmentation' &&
        (prevMode === 'erase' || prevMode === 'paint' || prevMode === 'magic-v2')
      )
        return 'draw'
      break
    default:
      if (prevMode === 'template') return 'draw'
      if (cat === 'segmentation' && prevMode === 'edit') return 'draw'
      break
  }
  return prevMode
}

const selectLayer = {
  segmentation: 0,
  polygons: 1,
  bounding_box: 2,
  point: 5,
  ellipse: 3,
  line: 4,
  classification: 6
}

export const reducer = produce((state, action) => {
  switch (action.type) {
    case 'setIsHiddenCat':
      state.isHiddenCat = action.payload.categories.reduce((acc, item) => {
        acc[item.name] = { ...item, isHidden: false }
        return acc
      }, {})
      break
    case 'setHideCat':
      state.isHiddenCat[action.payload.name].isHidden =
        !state.isHiddenCat[action.payload.name].isHidden
      break
    case 'hideAll':
      for (const cat in state.isHiddenCat) {
        state.isHiddenCat[cat].isHidden = !action.payload
      }

      break
    case 'confirmTemplate':
      state.mode = 'draw'
      state.cursor = changeCursor('draw', state.selectedCategory.type)
      state.redraw = state.redraw * -1
      break
    case 'setLabelerMode':
      state.redraw = state.redraw * -1
      state.mode = action.payload.mode
      state.cursor = changeCursor(action.payload.mode, action.payload.category)
      break
    case 'ctrlShiftZ':
    case 'setBoundingText':
    case 'setPolygonText':
    case 'addAiTags':
    case 'setClassifications':
    case 'ctrlZ':
    case 'setGlobalAlpha':
    case 'leaveSegmentation':
    case 'enterSegmentation':
    case 'hideSegmentation':
    case 'setSegmentationType':
    case 'hideTemplatePoint':
    case 'deleteSegmentationShapes':
    case 'deletePoint':
    case 'hidePoint':
    case 'deleteboundingBox':
    case 'updatePoly':
    case 'deletepolygon':
    case 'deleteLine':
      state.redraw = state.redraw * -1
      state.saveTags = state.saveTags * -1
      break
    case 'setClassificationMultipleValue':
    case 'setClassificationSimpleValue':
    case 'classChange':
    case 'deleteSelectedPoint':
      state.saveTags = state.saveTags * -1
      break
    case 'setProjectCategories':
      // eslint-disable-next-line no-case-declarations
      const newcats = action.payload
        .map((cat) => {
          const catlayer = { name: cat, layer: selectLayer[cat] }
          return catlayer
        })
        .sort((a, b) => a.layer - b.layer)
      state.projectCategories = newcats
      break
    case 'setSelectedCategory':
      state.mode = changeModeByCategory(state.selectedCategory, action.payload.cat, state.mode)
      state.smooth = action.payload.cat.type !== 'segmentation'
      state.selectedCategory = { ...state.selectedCategory, ...action.payload.cat }
      state.cursor = changeCursor(state.mode, state.selectedCategory.type)
      state.redraw = state.redraw * -1
      break
    case 'setLabelerDimensions':
      state.tools.width = action.payload.width
      state.tools.height = action.payload.height
      break
    case 'setCategories':
      // eslint-disable-next-line no-case-declarations
      const cats = []
      action.payload.forEach((item) => {
        if (!item.parent) {
          cats.push(item)
        } else {
          cats.forEach((element, index) => {
            if (item.parent === element.name) {
              cats.splice(index + 1, 0, item)
            }
          })
        }
      })
      state.categories = cats
      break
    case 'setReset':
      state.tools.reset = state.tools.reset * -1
      break
    case 'setPanzoom':
      state.tools.panZoom = { x: action.payload.x, y: action.payload.y }
      break
    case 'setScale':
      state.tools.scale = action.payload
      break
    case 'setOpenCard':
      state.tools.openCard = action.payload.open
      state.tools.index = action.payload.index
      break
    case 'setImage':
      state.image.index = action.payload
      break
    case 'setActualImage':
      state.image.image = action.payload
      break
    case 'setImageDone':
      state.image.currentImage.done = action.payload
      break
    case 'setImageAccepted':
      state.image.currentImage.accepted = action.payload
      break
    case 'setImageSkipped':
      state.image.currentImage.skiped = action.payload
      break
    case 'setLoadingImage':
      state.image.loading = action.payload
      state.image.progress = action.payload ? 0 : state.image.progress
      break
    case 'setRenderedImage':
      state.image.isRendered = action.payload
      break
    case 'setImageProps':
      state.image.props = action.payload
      break
    case 'setLoadingTags':
      state.loadingTags = action.payload
      break
    case 'setImages':
      state.image.images = action.payload.images
      state.image.currentImage = action.payload.currentImage
      break
    case 'addNewImage':
      state.image.images = [...action.payload.images, ...state.image.images]
      state.image.currentImage = action.payload.currentImage
      break
    case 'setSaveTags':
      state.saveTags = state.saveTags * -1
      break
    case 'setGrid':
      state.grid = action.payload
      break
    case 'setCanvas':
      state.mode = changeModeByCategory(
        state.selectedCategory,
        action.payload.defaultSelectedCategory,
        state.mode
      )
      state.selectedCategory = {
        ...state.selectedCategory,
        ...action.payload.defaultSelectedCategory
      }
      state.cursor = changeCursor(state.mode, state.selectedCategory.type)
      state.smooth = action.payload.defaultSelectedCategory.type !== 'segmentation'
      break
    case 'setCanvasSettings':
      state.canvasSettings = action.payload
      break
    case 'resetCanvasSettings':
      state.canvasSettings = [
        { name: 'Saturation', value: 100 },
        { name: 'Brightness', value: 100 },
        { name: 'Contrast', value: 100 },
        { name: 'GrayScale', value: 0 },
        { name: 'GlobalAlpha', value: 0.5 }
      ]
      break
    case 'setRole':
      if (action.payload === 'viewer') {
        state.mode = null
      } else {
        state.mode = 'draw'
      }
      state.cursor = changeCursor('draw', state.selectedCategory.type)
      state.role = action.payload
      break
    case 'changeIsHotkeysOpen':
      state.isHotkeysOpen = !state.isHotkeysOpen
      break
    case 'setCursor':
      state.cursor = action.payload.cursor
      break
    case 'setSmooth':
      state.smooth = !state.smooth
      state.redraw = state.redraw * -1
      break
    case 'changeSmoothing':
      state.smooth = action.payload
      state.redraw = state.redraw * -1
      break
    case 'setLoadingAI':
      state.loadingAI = action.payload
      break
    case 'setNavigation':
      state.image.navigation = action.payload
      break
    case 'setIsFeedbackPanelOpen':
      state.isFeedbackPanelOpen = action.payload
      break
    case 'setIACategory':
      state.selectedCategory.AI = action.payload
      break
    case 'shortcutDone':
      state.shortcuts.done = state.shortcuts.done * -1
      break
    case 'shortcutSkip':
      state.shortcuts.skip = state.shortcuts.skip * -1
      break
    case 'shortcutDoneAndNext':
      state.shortcuts.doneAndNext = state.shortcuts.doneAndNext * -1
      break
    case 'shortcutSkipAndNext':
      state.shortcuts.skipAndNext = state.shortcuts.skipAndNext * -1
      break
  }
}, labelerState)

export function LabelerReducer() {
  const [state, dispatch] = useReducer(reducer, labelerState)
  return { labelerState: state, labelerDispatch: dispatch }
}
