import { combineReducers } from 'redux'
import ui from '../io/ui/reducer'
import user from '../io/user/reducer'
import labeler from '../io/labeler/reducer'
import project from '../io/project/reducer'
import dialogs from '../io/dialogs/reducer'
import modals from '../services/modals/modalsReducer'
import { deleteUser } from '../utils/util'

const appReducer = () =>
  combineReducers({
    ui,
    user,
    project,
    labeler,
    dialogs,
    // TODO: Remove this
    modals
  })

const reducer = () => {
  return (state, action) => {
    if (action.type === 'LOGOUT') {
      deleteUser()
      state = undefined
    }
    return appReducer()(state, action)
  }
}

export default reducer
