import React, { useEffect, useState } from 'react'
import { uniq as _uniq } from 'lodash'
import { Link } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
  ListItemSecondaryAction,
  IconButton
} from '@mui/material'
import Chip from '@mui/material/Chip'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import ClearIcon from '@mui/icons-material/Clear'
import ListIcon from '@mui/icons-material/List'
import { inviteToTeamByEmail } from '../services/aws'
import {
  sandMemberAddedToTeam,
  sandMemberInvitedToTeam,
  sandMemberRemovedToTeam
} from '../io/analytics'
import { createMember, deleteMember, updateTeam } from '../services/graphql'
import { getUserByEmail } from '../io/graphql/user/user'
import { getUser, uppercaseFirst } from '../utils/util'
import '../styles/createproject.css'
import useFreeTrial from '../pages/project/hooks/useFreeTrial'
import { useSnackbar } from './snackbar/context/SnackbarContext'

const roles = [
  { name: 'Labeler', id: 'labeler' },
  { name: 'Reviewer', id: 'reviewer' },
  { name: 'Viewer', id: 'viewer' }
]

const UserSearch = (props) => {
  const { permissionsFreeTrial } = useFreeTrial()
  const [email, setEmail] = useState('')
  const [addedMembers, setAddedMembers] = useState([])
  const [searching, setSearching] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [inviteDialog, setInviteDialog] = useState(false)
  const [receiverMail, setReceiverMail] = useState('')
  const [receiverName, setReceiverName] = useState('')
  const [role, setRole] = useState({ name: 'Labeler', id: 'labeler' })
  const [loading, setLoading] = useState(false)
  const [access, setAccess] = useState([])

  const { showSnackbar } = useSnackbar()

  useEffect(() => {
    if (props.team && props.team.id) {
      setAddedMembers(props.team.members.items)
      setAccess(props.team.access)
    }
  }, [props.team])

  useEffect(() => {
    if (notFound) {
      setInterval(() => {
        setNotFound(false)
      }, 8000)
    }
  }, [notFound])

  // Handlers ---------------------------------------------------
  const handleSendEmailModalClose = () => {
    setInviteDialog(false)
  }
  const handleReceiverMailChange = ({ target }) => {
    setReceiverMail(target.value)
  }
  const handleReceiverNameChange = ({ target }) => {
    setReceiverName(target.value)
  }

  const handleDelete = (index) => {
    if (index >= addedMembers.length) return
    if (loading || searching) {
      showSnackbar({
        message: 'Our monkeys are busy, please try again.',
        type: 'error'
      })
      return
    }
    const username = addedMembers[index].user.owner
    setLoading(true)
    deleteMember({
      id: addedMembers[index].id
    })
      .then(() => {
        sandMemberRemovedToTeam({
          id: props.team.id,
          name: props.team.name,
          memberId: addedMembers[index].id
        })
        setAddedMembers(
          (prevAddedMembers) =>
            prevAddedMembers.filter((category, i) => {
              return i !== index
            }),
          () => {
            const _access = _uniq([...access])
            const ix = _access.indexOf(username)
            if (ix > -1) {
              _access.splice(ix, 1)
            }
            updateTeam({ id: props.team.id, access: _access })
              .then(() => {
                setAccess(_access)
                setLoading(false)
              })
              .catch((e) => {
                console.log(e)
                this.setState({ loading: false })
              })
          }
        )
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
      })
  }

  const handleUserChange = (e) => {
    setEmail(e.target.value)
    setReceiverMail(e.target.value)
  }

  const handleInviteToTeam = () => {
    const sender = getUser()
    sandMemberInvitedToTeam({ id: props.team.id, name: props.team.name, memberEmail: receiverMail })
    inviteToTeamByEmail({
      receiverName,
      receiverMail,
      senderName: sender.name,
      teamName: props.team.name !== '' ? props.team.name : 'A team'
    })

    setInviteDialog(false)
  }

  const handleUserSearch = (e) => {
    if (e.keyCode && e.keyCode !== 13) {
      return
    }
    if (!props.team) return

    if (email.length > 5 && email.includes('@') && email.includes('.')) {
      setSearching(true)
      getUserByEmail(email, 3).then((response) => {
        if (response.data.itemsByEmail.items.length > 0) {
          const user = response.data.itemsByEmail.items[0]

          if (user.id === getUser().id) {
            showSnackbar({
              message: 'You are not allowed to change your role.',
              type: 'error'
            })
            setEmail('')
            setSearching(false)
            return
          }

          const member = {
            memberTeamId: props.team.id,
            memberUserId: user.id,
            role: role.id
          }

          createMember(member)
            .then((res) => {
              sandMemberAddedToTeam({ id: props.team.id, name: props.team.name, memberId: user.id })
              const _access = _uniq([...access, user.owner])
              updateTeam({ id: props.team.id, access: _access })
                .then(() => setAccess(_access))
                .catch((e) => console.log(e))
              const member = res.data.createMember
              delete member.team
              setAddedMembers((prevAddedMembers) => [...prevAddedMembers, member])
              setSearching(false)
              setEmail('')
            })
            .catch((e) => {
              console.log(e)
              if (userExist(user.id)) {
                showSnackbar({
                  message: `This member already exist: ${user.email}`,
                  type: 'error'
                })
              } else {
                showSnackbar({
                  message: `Error adding member: ${user.email}, please try again.`,
                  type: 'error'
                })
              }
              setSearching(false)
            })
        } else {
          setNotFound(true)
          setSearching(false)
        }
      })
    }
  }

  const userExist = (id) => {
    for (let i = 0; i < addedMembers.length; i++) {
      if (id === addedMembers[i].user.id) {
        return true
      }
    }
    return false
  }
  // ------------------------------------------------------------

  // Renders ----------------------------------------------------
  const renderTeamMembers = () => {
    return (
      <Grid item xs={12}>
        <Paper
          style={{ overflow: 'auto', maxHeight: 300, padding: '20px', minHeight: 100 }}
          elevation={1}
        >
          {addedMembers ? (
            <>
              <Typography style={{ textAlign: 'left' }} variant="body1">
                {`Your team has ${
                  addedMembers.length === 1 ? '1 member' : addedMembers.length + ' members'
                }`}
              </Typography>
              <List>
                {addedMembers.map((member, index) => {
                  return (
                    <div key={index}>
                      <ListItem>
                        <ListItemText
                          primary={member.user.name}
                          secondary={!props.notDelete ? member.user.email : ''}
                        />
                        <ListItemSecondaryAction>
                          {props.times && !props.notDelete && (
                            <Link
                              key={member.id}
                              style={{ textDecoration: 'none' }}
                              to={{ pathname: '/times', state: { user: member } }}
                            >
                              <IconButton size="large">
                                <ListIcon />
                              </IconButton>
                            </Link>
                          )}
                          <Chip
                            label={uppercaseFirst(member.role)}
                            color={member.role === 'admin' ? 'primary' : 'secondary'}
                          />
                          {!props.notDelete && member.role !== 'admin' && (
                            <IconButton
                              disabled={searching || props.loading}
                              onClick={() => handleDelete(index)}
                              size="large"
                            >
                              <ClearIcon />
                            </IconButton>
                          )}
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                    </div>
                  )
                })}
                <ListItemButton
                  key="more"
                  onClick={props.loadMoreItems}
                  disabled={searching || props.loading}
                >
                  <Typography color="primary">
                    <em>Reload</em>
                  </Typography>
                </ListItemButton>
              </List>
            </>
          ) : (
            <p>Add a Team Member</p>
          )}
        </Paper>
      </Grid>
    )
  }

  const renderInviteDialog = () => {
    return inviteDialog ? (
      <Dialog style={{ zIndex: 3 }} open>
        <DialogTitle>Email to send</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1">Please fill the fields to send the invitation</Typography>
          <TextField
            variant="standard"
            name="receiverMail"
            label="Receiver Mail"
            value={receiverMail}
            onChange={handleReceiverMailChange}
            margin="none"
            autoFocus
            fullWidth
          />
          <br />
          <TextField
            variant="standard"
            name="receiverName"
            label="Receiver Name"
            value={receiverName}
            onChange={handleReceiverNameChange}
            margin="none"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={() => handleSendEmailModalClose()}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={() => handleInviteToTeam()}>
            Invite
          </Button>
        </DialogActions>
      </Dialog>
    ) : null
  }

  return (
    <div>
      <Grid container style={{ margin: 0, padding: 0 }}>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            padding: '0 10px'
          }}
        >
          {!props.notDelete && (
            <Grid container>
              <Grid item xs={6}>
                <TextField
                  variant="standard"
                  name="email"
                  id="outlined-name"
                  label="Email"
                  value={email}
                  onChange={handleUserChange}
                  onKeyDown={handleUserSearch}
                  margin="none"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl variant="standard" style={{ marginTop: 10 }}>
                  <InputLabel htmlFor="age-simple">Role</InputLabel>
                  <Select
                    variant="standard"
                    style={{ minWidth: 150, marginTop: '20px' }}
                    label="Role"
                    value={role}
                    renderValue={(value) => (value.name ? value.name : value)}
                    onChange={(e) => setRole(e.target.value)}
                    disabled={searching || props.loading}
                    inputProps={{
                      name: 'role',
                      id: 'role-select'
                    }}
                  >
                    {roles.map((role) => (
                      <MenuItem key={role.id} value={role}>
                        <em>{role.name}</em>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                {searching ? (
                  <CircularProgress />
                ) : (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleUserSearch}
                    disabled={
                      searching || props.loading || (!getUser()?.paid && !permissionsFreeTrial)
                    }
                  >
                    Add member
                  </Button>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
        {notFound ? (
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => setInviteDialog(true)}
          >
            Not found, click here to invite
          </Button>
        ) : null}
        {renderTeamMembers()}
      </Grid>
      {renderInviteDialog()}
    </div>
  )
}

export default UserSearch
