import Axios from 'axios'
import { getToken } from '../aws/auth'

// Generate Mask from Polys or Segmentations
export const requestMaskGeneration = async (projectId, formData) => {
  const token = await getToken()

  return Axios.post(`${process.env.REACT_APP_API}admin-review?project_id=${projectId}`, formData, {
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    }
  })
}

// Seng Email of Registration
export const sendRegisterEmail = async (name, email, company, position, products, message) => {
  return Axios.post(
    `${process.env.REACT_APP_API4}`,
    {
      name,
      email,
      company,
      position,
      products,
      message
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
}

export const downloadFormat = async (r, format, project, setLoading, showSnackbar) => {
  return Axios.get(r, { responseType: 'blob' })
    .then((response) => {
      if (response.status !== 200) {
        showSnackbar({
          message: 'Error downloading',
          type: 'error'
        })
      }
      const blob = new Blob([response.data])

      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = format === 'default' ? `${project.name}.json` : `${project.name}.zip`
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
      setLoading(false)
    })
    .catch((error) => {
      setLoading(false)
      showSnackbar({
        message: error,
        type: 'error'
      })
    })
}

export const registerToken = async (userId) => {
  const token = await getToken()

  return Axios.post(
    `${process.env.REACT_APP_API}accounts/save-cognito-id`,
    { userId },
    {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json'
      }
    }
  )
}
