import { createActions } from 'redux-actions'

export const { logout, user } = createActions({
  LOGOUT: () => ({}),
  USER: {
    GET_MY_PROJECTS: (nextToken, filters, replace) => ({ nextToken, filters, replace }),
    SET_MY_PROJECTS: (projects) => ({ projects }),

    GET_SHARED_PROJECTS: (nextToken, filters, replace) => ({ nextToken, filters, replace }),
    SET_SHARED_PROJECTS: (sharedProjects) => ({ sharedProjects }),

    GET_MY_TEAMS: (nextToken) => ({ nextToken }),
    SET_MY_TEAMS: (teams) => ({ teams }),

    GET_MEMBER_TEAMS: (nextToken) => ({ nextToken }),
    SET_MEMBER_TEAMS: (teams) => ({ teams }),

    GET_DATASETS: (nextToken) => ({ nextToken }),
    SET_DATASETS: (datasets) => ({ datasets }),
    ADD_DATASET: (dataset) => ({ dataset })
  }
})
