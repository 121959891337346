import React, { useContext, useRef, useState } from 'react'
import { DispatchContext } from '../../../context/DispatchContext'
import { LabelerContext } from '../../../context/LabelerContext'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import Menu from '@mui/material/Menu'

export default function LandmarkMenuList({ handleClose, category }) {
  const { state } = useContext(LabelerContext)
  const { dispatch } = useContext(DispatchContext)
  const { labelerState } = state
  const [pos, setPos] = useState({ x: 0, y: 0, open: false })
  const submenuRef = useRef(null)
  let submenuTimer

  const preventMenu = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const closeSubMenu = () => {
    setPos({ x: 0, y: 0, open: false })
  }

  const showSubmenu = () => {
    clearTimeout(submenuTimer)
    setPos({
      open: true,
      x:
        submenuRef.current.getBoundingClientRect().x +
        submenuRef.current.getBoundingClientRect().width,
      y: submenuRef.current.getBoundingClientRect().y
    })
  }

  const hideSubmenu = () => {
    submenuTimer = setTimeout(() => {
      setPos({ x: 0, y: 0, open: false })
    }, 300)
  }

  const handleSubmenuMouseEnter = () => {
    clearTimeout(submenuTimer)
  }

  const handleSubmenuMouseLeave = () => {
    hideSubmenu()
  }

  const changeCategory = (index, category) => {
    dispatch({
      type: 'changePoint',
      payload: { index, name: category.name, color: category.color }
    })
  }

  return (
    <>
      <MenuList dense>
        <Divider />
        <MenuItem
          onClick={() => {
            handleClose()
            dispatch({ type: 'hidePoint', payload: { index: category.index } })
          }}
        >
          <ListItemText>Hide</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose()
            dispatch({ type: 'deletePoint', payload: { index: category.index } })
          }}
        >
          <ListItemText>Delete</ListItemText>
        </MenuItem>
        <MenuItem ref={submenuRef} onMouseOver={showSubmenu} onMouseOut={hideSubmenu}>
          <ListItemText>Change</ListItemText>
          <Typography variant="body2" color="text.secondary">
            <ArrowRightIcon />
          </Typography>
        </MenuItem>
      </MenuList>
      <Menu
        onMouseEnter={handleSubmenuMouseEnter}
        onMouseLeave={handleSubmenuMouseLeave}
        onContextMenu={preventMenu}
        disableAutoFocusItem
        open={pos.open}
        anchorReference="anchorPosition"
        anchorPosition={{ top: pos.y, left: pos.x }}
        onClose={closeSubMenu}
      >
        <MenuList dense>
          {labelerState.categories
            .filter((cat) => cat.type === 'point')
            .map((cat, index) => {
              return (
                <MenuItem
                  key={index}
                  onClick={() => {
                    closeSubMenu()
                    handleClose()
                    changeCategory(category.index, cat)
                  }}
                >
                  <ListItemText>{cat.name}</ListItemText>
                </MenuItem>
              )
            })}
        </MenuList>
      </Menu>
    </>
  )
}
