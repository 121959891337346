import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { Hub } from 'aws-amplify'
import Routes from './Routes'
import { isLogged } from './services/aws'
import AppBar from './components/MainAppBar'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles'
import { useLocation, useNavigate } from 'react-router-dom'

import { dialog } from './io/dialogs/actions'
import './styles/App.css'
import './styles/main.css'
import { useEventListener } from './utils/hooks/useEventListener'
import { sandPage } from './io/analytics'
import { getUserByEmail } from './io/graphql/user/user'
import { saveUser } from './utils/util'
import { registerToken } from './io/api/admin'
import { SnackbarProvider, useSnackbar } from './components/snackbar/context/SnackbarContext'
import ReusableSnackbar from './components/snackbar/ReusableSnackbar'
import Loader from './components/generics/Loader'

const VERSION = `${process.env.REACT_APP_VERSION}${process.env.REACT_APP_VERSION_SUB}`

const SnackbarComponent = () => {
  const { showSnackbar } = useSnackbar()

  useEventListener('online', online)
  useEventListener('offline', offline)

  function online() {
    showSnackbar({
      message: 'ONLINE',
      type: 'success',
      duration: 1000
    })
  }

  function offline() {
    showSnackbar({
      message: 'OFFLINE',
      type: 'error',
      block: true
    })
  }
}

const usePageView = () => {
  const location = useLocation()

  useEffect(() => {
    sandPage(location.pathname)
  }, [location])
}

function App(props) {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false)
  const [isAuthenticating, setIsAuthenticating] = React.useState(true)

  const navigate = useNavigate()
  const location = useLocation()
  usePageView()

  useEffect(() => {
    Sentry.init({
      dsn: 'https://2abc823716654e5c822daf6b1ad638c2@sentry.io/1369851',
      environment: process.env.NODE_ENV || 'unknown',
      integrations: [new BrowserTracing()],
      // To set a uniform sample rate
      tracesSampleRate: 0.2
    })

    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          getUserFromDB(data)
          break
        case 'signOut':
          break
        case 'customOAuthState':
          break
      }
    })

    isLogged()
      .then(() => {
        userHasAuthenticated(true)
      })
      .catch((e) => {
        if (e !== 'No current user') {
          console.log(e)
        }
      })
      .finally(() => {
        setIsAuthenticating(false)
      })

    return unsubscribe
  }, [])

  const createCustomTheme = (mode) => ({
    props: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true
        }
      }
    },
    breakpoints: {
      values: {
        xs: 0,
        s: 400,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536
      }
    },
    palette: {
      mode,
      text: {
        primary: mode === 'dark' ? '#fcfcfc' : '#333333',
        nav: mode === 'dark' ? '#fcfcfc' : '#2c0f6d',
        icons: mode === 'dark' ? '#fcfcfc' : '#757575',
        arrows: mode === 'dark' ? '#00c9c9' : '#333333'
      },
      primary: { main: mode === 'dark' ? '#00c9c9' : '#2c0f6d' },
      secondary: { main: '#00c9c9', contrastText: '#eee' },
      background: {
        default: mode === 'dark' ? '#3c3c3c' : '#f9f9f9',
        paper: mode === 'dark' ? '#5a5a5a' : '#f9f9f9',
        navbar: mode === 'dark' ? '#2c0f6d' : '#ffffff',
        labeler: mode === 'dark' ? '#0a0a0a' : '#f9f9f9',
        graphs: '#2c0f6d'
      },
      neutral: {
        main: '#666',
        contrastText: '#ccc'
      }
    },
    typography: { useNextVariants: true },
    components: {
      MuiBreadcrumbs: {
        styleOverrides: {
          root: {
            background: mode === 'dark' ? '#3c3c3c' : '#f9f9f9'
          }
        }
      },
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            background: mode === 'dark' ? '#3c3c3c' : '#f9f9f9'
          },
          a: {
            color: mode === 'dark' ? '#00c9c9' : '#2c0f6d'
          },
          'a:hover': {
            color: mode === 'dark' ? 'rgb(00, 210, 230)' : 'rgb(70, 20, 130)'
          },
          '.blue-text': {
            color: mode === 'dark' ? '#00c9c9' : '#2c0f6d'
          },
          '.blue-line': {
            backgroundColor: mode === 'dark' ? 'rgb(00, 201, 201)' : 'rgb(43, 30, 97)',
            width: '100%',
            height: 2
          }
        }
      }
    }
  })

  const getUserFromDB = async (data) => {
    const attributes = data?.attributes
      ? data.attributes
      : data?.signInUserSession?.idToken?.payload

    if (attributes) {
      await getUserByEmail(attributes.email)
        .then(async (res) => {
          const user = res.data.itemsByEmail.items[0]
          saveUser(user)
          userHasAuthenticated(true)

          await registerToken(user.id)
            .then(() => console.log('Token Verify'))
            .catch((e) => console.log('Error Verifing Token', e))

          if (location.state?.from) {
            navigate(location.state.from)
          } else {
            navigate('/projects')
          }
        })
        .catch((error) => {
          Sentry.captureMessage({ error, email: attributes.email })
        })
    }
  }

  const userHasAuthenticated = (authenticated) => {
    setIsAuthenticated(authenticated)
  }

  const childProps = {
    isAuthenticated,
    userHasAuthenticated,
    version: VERSION
  }
  const theme = createTheme(createCustomTheme(props.ui.mode))

  return (
    <Sentry.ErrorBoundary
      beforeCapture={(scope) => {
        const user = JSON.parse(window.localStorage.getItem('user'))
        scope.setTag('user', user)
      }}
    >
      {!isAuthenticating ? (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider>
              <div
                className="App"
                style={{
                  height: '100%',
                  textAlign: 'center',
                  position: 'relative',
                  overflow: 'auto'
                }}
              >
                {((location.pathname !== '/labeler' && location.pathname !== '/') ||
                  (location.pathname === '/labeler' && !isAuthenticated)) && (
                  <AppBar
                    version={VERSION}
                    userHasAuthenticated={userHasAuthenticated}
                    isAuthenticated={isAuthenticated}
                  />
                )}
                <Routes childProps={childProps} />
                <SnackbarComponent />
              </div>
              <ReusableSnackbar />
            </SnackbarProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      ) : (
        <Loader />
      )}
    </Sentry.ErrorBoundary>
  )
}

const mapStateToProps = (state) => ({
  ui: state.ui,
  openSnack: state.dialogs.openSnack
})

const mapDispatchToProps = {
  setOpenSnack: dialog.setOpenSnack
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
