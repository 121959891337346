import npyjs from 'npyjs'
import * as ort from 'onnxruntime-web'

export const loadEmbedding = async (URL) => {
  try {
    const _tensor = await loadNpyTensor(URL, 'float32')
    return _tensor
  } catch (error) {
    console.log('TENSOR ERROR', error)
    return null
  }
}

const loadNpyTensor = async (tensorFile, dType) => {
  // eslint-disable-next-line new-cap
  const npLoader = new npyjs()
  const npArray = await npLoader.load(tensorFile)
  const _tensor = new ort.Tensor(dType, npArray.data, npArray.shape)
  return _tensor
}
