import { useReducer } from 'react'
import produce from 'immer'
import { person } from './templates/person'

const landmarksState = {
  tags: [],
  person: [],
  selectedTag: -1,
  redraw: 0
}

export const reducer = produce((state, action) => {
  switch (action.type) {
    case 'setLoadingTags':
      if (action.payload) {
        state.tags = []
        state.person = []
        state.selectedTag = -1
        state.redraw = 0
      }
      break
    case 'confirmTemplate':
      state.tags = action.payload
      state.person = []
      state.redraw = state.redraw + 1
      break
    case 'setLabelerMode':
      state.person = []
      if (action.payload.mode === 'template') {
        state.person = person
      }
      state.redraw = state.redraw * -1
      break
    case 'setPerson':
      state.person = action.payload
      break
    case 'setLandmarks':
      state.tags = action.payload
      state.redraw = state.redraw + 1
      break
    case 'setLandmark':
      state.tags[action.payload.index].pos.x = action.payload.pos.x
      state.tags[action.payload.index].pos.y = action.payload.pos.y
      state.redraw = state.redraw + 1
      break
    case 'addLandmark':
      state.tags.push(action.payload)
      break
    case 'hidePoint':
      state.tags[action.payload.index] = {
        ...state.tags[action.payload.index],
        show: !state.tags[action.payload.index].show
      }
      state.redraw = state.redraw + 1
      break
    case 'hideAll':
      state.tags = state.tags.map((point) => ({ ...point, show: action.payload }))
      state.redraw = state.redraw + 1
      break
    case 'hideLandmarkCategory':
      state.tags = state.tags.map((point) => {
        if (point.name === action.payload.name) {
          return { ...point, show: !point.show }
        }
        return point
      })
      state.redraw = state.redraw + 1
      break
    case 'hideTemplatePoint':
      state.person[action.payload.index] = {
        ...state.person[action.payload.index],
        show: !state.person[action.payload.index].show
      }
      break
    case 'unhidePoint':
      state.tags[action.payload] = { ...state.tags[action.payload.index], show: false }
      break
    case 'deletePoint':
      state.tags = state.tags.filter((_, index) => index !== action.payload.index)
      state.redraw = state.redraw + 1
      break
    case 'hoverPoint':
      state.tags[action.payload.index] = {
        ...state.tags[action.payload.index],
        hover: action.payload.hover
      }
      state.redraw = state.redraw + 1
      break
    case 'selectPoint':
      if (state.selectedTag > -1)
        state.tags[state.selectedTag] = { ...state.tags[state.selectedTag], hover: false }
      state.tags[action.payload.index] = { ...state.tags[action.payload.index], hover: true }
      state.selectedTag = action.payload.index
      state.redraw = state.redraw + 1
      break
    case 'unselectPoint':
      if (state.selectedTag > -1) {
        state.tags[state.selectedTag] = { ...state.tags[state.selectedTag], hover: false }
        state.selectedTag = -1
        state.redraw = state.redraw + 1
      }
      break
    case 'deleteSelectedPoint':
      state.tags = state.tags.filter((_, index) => index !== state.selectedTag)
      state.selectedTag = -1
      state.redraw = state.redraw + 1
      break
    case 'changePoint':
      state.selectedTag = -1
      state.tags[action.payload.index].name = action.payload.name
      state.tags[action.payload.index].color = action.payload.color
      state.tags[action.payload.index].hover = false
      state.redraw = state.redraw + 1
      break
  }
}, landmarksState)

export function LandmarksReducer() {
  const [state, dispatch] = useReducer(reducer, landmarksState)
  return { landmarkState: state, landmarkDispatch: dispatch }
}
