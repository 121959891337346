import React, { useState } from 'react'
import {
  Button,
  Input,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material'
import { Upload } from '@mui/icons-material'
import { useSnackbar } from '../../snackbar/context/SnackbarContext'

const DialogUploadCategories = ({ uploadCategories, categories }) => {
  const [open, setOpen] = React.useState(false)
  const [file, setFile] = useState(null)
  const { showSnackbar } = useSnackbar()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setFile(null)
  }

  const handleChange = (event) => {
    setFile(event.target.files[0])
  }

  const handleUpload = () => {
    if (!file) {
      showSnackbar({
        message: 'Missing File.',
        type: 'error'
      })
      return
    }

    const reader = new FileReader()
    reader.onload = (event) => {
      const data = JSON.parse(event.target.result)
      function compareCategories(cat1, cat2) {
        return cat1.name === cat2.name
      }
      let newCategories = data
      if (categories.length) {
        newCategories = data.filter(
          (cat2) => !categories.some((cat1) => compareCategories(cat1, cat2))
        )
      }

      uploadCategories(newCategories)
    }
    reader.readAsText(file)
    handleClose()
    setFile(null)
  }

  return (
    <>
      <Button
        sx={{ margin: 1 }}
        onClick={handleClickOpen}
        color="primary"
        size="small"
        variant="contained"
        endIcon={<Upload />}
      >
        Import
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Upload Categories</DialogTitle>
        <DialogContent>
          <Typography>Upload the categories as a json.</Typography>
          <Input
            type="file"
            onChange={handleChange}
            style={{ marginRight: '1rem' }}
            inputProps={{ accept: 'application/json' }}
            placeholder="ff"
          />
          <Button color="primary" variant="contained" endIcon={<Upload />} onClick={handleUpload}>
            Import
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DialogUploadCategories
