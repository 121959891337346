import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import { sandEvent } from '../../io/analytics'
import { getUser } from '../../utils/util'
import Teams from './components/teams/Teams'
import Projects from './components/projects/Projects'
import ModalWelcome from './components/ModalWelcome'
import Datasets from '../../containers/Datasets'
import NotFound from '../../components/NotFound'

const UserContainer = styled('div')(() => ({
  marginBottom: '60px',
  marginTop: '80px',
  marginRight: '10%',
  marginLeft: '10%',
  display: 'flex',
  flexWrap: 'nowrap',
  flexGrow: 1
}))

function Dashboard(props) {
  const [tab, setTab] = useState(props.tab ? props.tab : 0)
  const [welcomeModal, setWelcomeModal] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    if (props.modals.stateModalWelcome) {
      setWelcomeModal(true)
    }
  }, [])

  useEffect(() => {
    setTab(props.tab)
  }, [props.tab])

  const handleTabChange = (event, value) => {
    setTab(value)
  }

  const closeModal = (close) => {
    setWelcomeModal(!close)
  }

  const user = getUser()
  return (
    <UserContainer>
      <ModalWelcome {...props} open={welcomeModal} closeModal={closeModal} />
      <Grid container>
        <Grid item xs={12}>
          <Tabs
            sx={{
              width: '100%',
              borderBottom: '1px solid #e8e8e8'
            }}
            value={tab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            scrollButtons="auto"
          >
            <Tab
              style={{ fontSize: '14px' }}
              label="Projects"
              onClick={() => {
                sandEvent('Tab Projects')
                navigate('/projects')
              }}
            />
            <Tab
              style={{ fontSize: '14px' }}
              label="Datasets"
              onClick={() => {
                sandEvent('Tab Datasets')
                navigate('/datasets')
              }}
            />
            {user && user.paid && (
              <Tab
                style={{ fontSize: '14px' }}
                label="Teams"
                onClick={() => {
                  sandEvent('Tab Teams')
                  navigate('/teams')
                }}
              />
            )}
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          {/* {this.props.project.id} */}
          {tab === 0 && <Projects {...props} />}
          {tab === 1 && <Datasets {...props} />}
          {tab === 2 && (user.paid ? <Teams {...props} /> : <NotFound />)}
        </Grid>
      </Grid>
    </UserContainer>
  )
}

const mapStateToProps = (state) => ({
  project: state.project,
  modals: state.modals
})

export default connect(mapStateToProps, {})(Dashboard)
