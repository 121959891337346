import { API, graphqlOperation } from 'aws-amplify'
import * as queries from '../graphql/queries'
import * as qweries from '../graphql/qweries'
import * as mutations from '../graphql/mutations'
import * as mwtations from '../graphql/mwtations'
import * as subscriptions from '../graphql/subscriptions'
import { project } from '../io/project/actions'
import { getAssignLabeler, getAssignReviewer } from '../io/api/tags'
import { listTagsIds, listReviewerTagsIds } from '../io/graphql/tag/tags'

// TODO: Move this methods to io/

// DATASET
export const createDataset = async (dataset) =>
  API.graphql(graphqlOperation(mutations.createDataset, { input: dataset }))

export const getDataset = async (id) => API.graphql(graphqlOperation(qweries.getDataset, { id }))

export const subsCreateDataset = () => API.graphql(graphqlOperation(subscriptions.onCreateDataset))

export const imagesByDataset = (imageDatasetId, nextToken) => {
  return API.graphql(
    graphqlOperation(queries.imagesByDataset, {
      imageDatasetId,
      limit: 10,
      nextToken
    })
  )
}

export const updateDataset = async (data) =>
  API.graphql(graphqlOperation(mutations.updateDataset, { input: data }))

// IMAGE
export const subsCreateImage = API.graphql(graphqlOperation(subscriptions.onCreateImage))

// TEAM
export const createTeam = async (team) =>
  API.graphql(graphqlOperation(mutations.createTeam, { input: team }))

export const getTeam = async (id) => API.graphql(graphqlOperation(qweries.getTeam, { id }))

export const updateTeam = async (team) =>
  API.graphql(graphqlOperation(mutations.updateTeam, { input: team }))

export const getMember = async (memberUserId, memberTeamId) => {
  const id = memberUserId + memberTeamId
  return API.graphql(graphqlOperation(queries.getMember, { id }))
}

export const listAllMembers = async (teamId) => {
  let members = []
  let nextToken = null

  do {
    const r = await API.graphql(
      graphqlOperation(qweries.searchMembers, {
        filter: { memberTeamId: { eq: teamId } },
        nextToken
      })
    )
    members = members.concat(r.data.searchMembers.items)
    nextToken = r.data.searchMembers.nextToken
  } while (nextToken)

  return { members, nextToken }
}

export const createMember = async (member) => {
  member.id = member.memberUserId + member.memberTeamId
  return API.graphql(graphqlOperation(mwtations.createMember, { input: member }))
}

export const deleteMember = async (member) => {
  return API.graphql(graphqlOperation(mwtations.deleteMember, { input: { id: member.id } }))
}

export const createProject = (_project) => async (dispatch) => {
  // MOVE To SAGA
  for (let i = 0; i < _project.categories.length; i++) {
    const cat = _project.categories[i]
    _project.categories[i] = {
      name: cat.name,
      color: cat.color,
      type: cat.type,
      parent: cat.parent || null,
      options: cat.options || [],
      classType: cat.classType || 'single',
      required: cat.required || false
    }
  }

  _project = {
    ..._project,
    tagsCount: 0,
    tagsSaveCount: 0,
    tagsReviewCount: 0,
    imagesSavedCount: 0,
    imagesReviewedCount: 0,
    categoriesCount: []
  }

  const res = await API.graphql(graphqlOperation(mutations.createProject, { input: _project }))
    .then((response) => {
      if (response.error) return { error: response.error }
      dispatch(project.setProject(response.data.createProject))
      return { error: null, data: response.data.createProject }
    })
    .catch((er) => {
      console.log('Error create project', er)
      return { error: er }
    })
  return res
}

export const createProjectData = async (data) =>
  API.graphql(graphqlOperation(mutations.createProjectData, { input: data }))

export const updateProject = async (data) =>
  API.graphql(graphqlOperation(mwtations.updateProject, { input: data }))

export const listImages = async (id, labeler, order, nextToken) => {
  let images = []
  let errors = null
  do {
    const r = await listTagsIds(id, labeler, order, nextToken)
    if (r.data) {
      images = r.data.items
      nextToken = r.data.nextToken
    }
    errors = r.errors
  } while (images.length <= 0 && nextToken)

  return { data: { items: images, next: nextToken }, errors }
}

export const listReviewImages = async (id, reviewer, order, sortId, nextToken) => {
  let images = []
  let errors = null
  do {
    const r = await listReviewerTagsIds(id, reviewer, order, nextToken)
    images = r.data.items
    nextToken = r.data.nextToken
    errors = r.errors
  } while (images.length <= 0 && nextToken)

  return { data: { items: images, next: nextToken }, errors }
}

export const getProject = async (id) => API.graphql(graphqlOperation(qweries.getProject, { id }))

export const getViewProject = async (id) =>
  API.graphql(graphqlOperation(qweries.getViewProject, { id }))

export const getAllImages = async (
  id,
  limit,
  sortDirection, // asc desc
  nextToken,
  filters = { and: [], or: [] }
) => {
  const r = await API.graphql(
    graphqlOperation(qweries.searchTags, {
      filter:
        filters.and.length === 0 && filters.or.length === 0
          ? {
              tagProjectId: { eq: id }
            }
          : filters.and.length === 0
          ? {
              tagProjectId: { eq: id },
              or: filters.or
            }
          : filters.or.length === 0
          ? {
              tagProjectId: { eq: id },
              and: filters.and
            }
          : {
              tagProjectId: { eq: id },
              and: filters.and,
              or: filters.or
            },
      limit,
      sort: { field: 'createdAt', direction: sortDirection },
      nextToken
    })
  )

  return {
    data: { items: r.data ? r.data.searchTags.items : [], next: r.data.searchTags.nextToken },
    errors: r.errors
  }
}

export const getImage = async (id, labeler) => {
  const image = await getSingleImage(id, labeler)
  return { data: image.error ? null : image, error: image.error }
}

export const getReviewImage = async (id, reviewer) => {
  const image = await getSingleReviewImage(id, reviewer)
  return { data: image.error ? null : image, error: image.error }
}

const getSingleImage = async (id, labeler) => {
  if (!id) return { error: 'No id' }
  try {
    const assign = await getAssignLabeler(id, labeler)

    return {
      ...assign.data
    }
  } catch (e) {
    return {
      error: e,
      data: null
    }
  }
}

const getSingleReviewImage = async (id, reviewer) => {
  if (!id) {
    return { error: 'No id' }
  }
  try {
    const assign = await getAssignReviewer(id, reviewer)

    return {
      ...assign.data
    }
  } catch (e) {
    return {
      error: e,
      data: null
    }
  }
}

export const tagsByProject = async (tagProjectId, nextToken) => {
  const res = await API.graphql(
    graphqlOperation(queries.tagsByProject, {
      tagProjectId,
      limit: 10,
      nextToken
    })
  )
  return res
}
// TAGS

export const getTags = async (id) => API.graphql(graphqlOperation(qweries.getTag, { id }))

export const listTags = (nextToken) => async () => {
  return await API.graphql(graphqlOperation(queries.searchTags, { nextToken }))
}

export const createRequest = async (request) =>
  API.graphql(graphqlOperation(mutations.createRequestJson, { input: request }))

export const listRequest = async (requestProjectId, nextToken) => {
  let requests = []
  do {
    const r = await listRqst(requestProjectId, nextToken)
    requests = r.data.reqByProject.items
    nextToken = r.data.reqByProject.nextToken
  } while (requests.length <= 0 && nextToken)

  return { data: { items: requests }, next: nextToken }
}

const listRqst = async (requestProjectId, nextToken) =>
  API.graphql(
    graphqlOperation(queries.reqByProject, {
      requestProjectId,
      limit: 10,
      nextToken,
      // filter: { status: { ne: 'deleted' } },
      sortDirection: 'DESC'
    })
  )

export const getRequest = async (id) =>
  API.graphql(graphqlOperation(queries.getRequestJson, { id }))

// SAVER

export const listSaveImages = async (id, nextToken) => {
  let images = []
  do {
    const r = await getSaves(id, nextToken)
    images = [...images, ...r.data.tagsByProject.items]
    nextToken = r.data.tagsByProject.nextToken
  } while (nextToken)

  return { data: { items: images }, next: nextToken }
}

const getSaves = async (
  id,
  nextToken // ASC DESC
) =>
  API.graphql(
    graphqlOperation(queries.tagsByProject, {
      tagProjectId: id,
      filter: { accepted: { eq: true } },
      limit: 5000,
      nextToken
    })
  )

export const saveComment = async (comment) =>
  API.graphql(graphqlOperation(mutations.createComment, { input: comment }))

export const updateComment = async (comment) =>
  API.graphql(graphqlOperation(mutations.updateComment, { input: comment }))

export const getComments = async (commentTagId) =>
  API.graphql(graphqlOperation(queries.commentsByCommentTagIdAndCreatedAt, { commentTagId }))
