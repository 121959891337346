import React from 'react'
import '../styles/LoaderButton.css'
import Button from '@mui/material/Button'
import RefreshIcon from '@mui/icons-material/Refresh'

const LoaderButton = ({
  isLoading,
  text,
  loadingText,
  className = '',
  disabled = false,
  variant = 'contained',
  ...props
}) => (
  <Button
    {...props}
    className={`LoaderButton ${className}`}
    disabled={disabled || isLoading}
    endIcon={isLoading && <RefreshIcon className="spinning" />}
    // size={size}
    variant={variant}
  >
    {!isLoading ? text : loadingText}
  </Button>
)

export default LoaderButton
