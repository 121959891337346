import React, { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import TopFilterItems from './components/topFilterItems/TopFilterItems'
import { styled } from '@mui/material/styles'
import { FiltersContext } from '../../context/FiltersContext'
import ProjectTitle from './components/projectInfo/ProjectInfo'
import { LinearProgress } from '@mui/material'

const Container = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  color: '#aaa',
  paddingRight: 0.2,
  margin: 0.5,
  [theme.breakpoints.down('sm')]: {
    margin: 0,
    display: 'block'
  }
}))

const TopFilterContainer = styled('div')(({ theme }) => ({
  top: 50,
  flex: 'auto',
  position: 'sticky',
  background: theme.palette.background.default,
  display: 'flex',
  textAlign: 'left',
  zIndex: 2,
  flexDirection: 'column',
  width: '100%',
  padding: 1,
  margin: 1
}))

function TopFilter() {
  const { state } = useContext(FiltersContext)
  const store = useSelector((state) => state)

  const totalImagesInRequest = useMemo(
    () => state.totalImagesInRequest || 0,
    [state.totalImagesInRequest]
  )

  const totalImagesDataset = useMemo(() => {
    let total = 0
    store.project.datasets.forEach((item) => {
      total += item.dataset?.count || 0
    })
    return total
  }, [store.project.datasets])

  return (
    <TopFilterContainer>
      <Container>
        <ProjectTitle
          projectName={store.project.name}
          totalImagesInRequest={totalImagesInRequest}
          totalImagesDataset={totalImagesDataset}
        />
        <TopFilterItems />
      </Container>
      <div
        style={{
          height: '15px',
          opacity: state.loading ? 1 : 0,
          transition: 'opacity 1s ease'
        }}
      >
        {state.loading && <LinearProgress />}
      </div>
    </TopFilterContainer>
  )
}

export default TopFilter
