import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Grid,
  Chip,
  Dialog,
  Button,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  LinearProgress,
  DialogContentText
} from '@mui/material'
import { styled } from '@mui/material/styles'

import { sandDatasetCreationStart, sandDeleteDataset } from '../io/analytics'
import { updateDataset } from '../services/graphql'
import { user as u } from '../io/user/actions'
import DatasetItem from '../components/DatasetItem'
import '../styles/Datasets.css'
import { formatNumber } from '../utils/util'

const LoadMore = styled('em')(({ theme }) => ({
  color: theme.palette.primary.main
}))

function Datasets(props) {
  const [open, setOpen] = useState(false)
  const [selectedDataset, setSelectedDataset] = useState(-1)

  useEffect(() => {
    props.getDatasets()
  }, [])

  const handleClickOpen = (index) => {
    setOpen(true)
    setSelectedDataset(index)
  }

  const listUndeletedDatasets = () => {
    props.getDatasets()
  }

  const loadMore = () => {
    const { datasets } = props
    if (datasets.nextToken) {
      props.getDatasets(datasets.nextToken)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleDelete = () => {
    const { datasets } = props
    const dataset = datasets.items[selectedDataset]
    setOpen(false)

    updateDataset({
      id: dataset.id,
      status: 'deleted'
    }).then(() => {
      setOpen(false)
      listUndeletedDatasets()
    })
    sandDeleteDataset({ name: dataset.name, id: dataset.id })
  }

  const renderDeleteDatasetModal = () => {
    const datasets = props.datasets
    return (
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Delete Dataset</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the &apos;{datasets.items[selectedDataset].name}&apos;
            dataset?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const { datasets } = props

  return (
    <div className="datasets-container">
      {/* Datasets */}
      {datasets.loading && <LinearProgress />}
      {datasets && datasets.items.length > 0 ? (
        <div>
          <Grid container style={{ padding: '1em' }} spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Button
                sx={{ margin: 2, color: '#fff !important' }}
                onClick={sandDatasetCreationStart}
                component={Link}
                to="/upload"
                color="secondary"
                variant="contained"
              >
                Create dataset
              </Button>
              <div className="blue-line" />
            </Grid>
            {datasets.items.map((dataset, index) => (
              <DatasetItem
                key={index}
                dataset={dataset}
                subtitle={
                  <>
                    {new Date(dataset.createdAt).toLocaleDateString()}{' '}
                    <Chip label={formatNumber(dataset.count || 0)} size="small" />{' '}
                  </>
                }
                title={dataset.name}
                index={index}
                deleteDataset={handleClickOpen}
              />
            ))}
          </Grid>
          {datasets.nextToken && (
            <Button size="small" onClick={loadMore} color="primary">
              <LoadMore>Load more...</LoadMore>
            </Button>
          )}
        </div>
      ) : (
        <>
          <Button
            style={{ margin: 10, color: '#fff !important' }}
            component={Link}
            to={'/upload'}
            onClick={sandDatasetCreationStart}
            color="secondary"
            variant="contained"
          >
            Create your first dataset
          </Button>
          <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: '5%' }}>
            <img src="assets/upload_icon.png" alt="upload your images here, in png or jpg" />
            <Typography gutterBottom variant="h5" component="h2">
              Upload Data
            </Typography>
            <Typography component="p">
              You can upload images with{' '}
              <Link to="/upload" onClick={sandDatasetCreationStart}>
                drag-n-drop
              </Link>
              ,
            </Typography>
          </Grid>
        </>
      )}
      {open ? renderDeleteDatasetModal() : null}
    </div>
  )
}
const mapStateToProps = (state) => ({
  datasets: state.user.datasets
})

const mapDispatchToProps = {
  getDatasets: u.getDatasets
}

export default connect(mapStateToProps, mapDispatchToProps)(Datasets)
