export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      email
      company
      termsAccepted
      status
      datasets {
        items {
          id
          name
          description
          count
          status
          createdAt
          owner
        }
        nextToken
      }
      teams {
        items {
          id
          role
          team {
            id
            name
            user {
              name
              email
            }
          }
          createdAt
        }
        nextToken
      }
    }
  }
`

export const getMember = /* GraphQL */ `
  query GetMember($id: ID!) {
    getMember(id: $id) {
      id
      role
      createdAt
      team {
        id
        name
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          createdAt
        }
        status
        access
        owner
      }
      user {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        createdAt
      }
    }
  }
`

export const listMembers = /* GraphQL */ `
  query ListMembers($filter: ModelMemberFilterInput, $limit: Int, $nextToken: String) {
    listMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        role
        createdAt
        team {
          id
          name
        }
        user {
          id
          name
          email
          company
        }
      }
      nextToken
    }
  }
`

export const searchMembers = /* GraphQL */ `
  query SearchMembers(
    $filter: SearchableMemberFilterInput
    $sort: [SearchableMemberSortInput]
    $limit: Int
    $nextToken: String
  ) {
    searchMembers(filter: $filter, sort: $sort, limit: $limit, nextToken: $nextToken) {
      items {
        id
        role
        createdAt
        team {
          id
          name
        }
        user {
          id
          name
          email
          company
        }
        memberTeamId
      }
      nextToken
    }
  }
`

export const getDataset = /* GraphQL */ `
  query GetDataset($id: ID!) {
    getDataset(id: $id) {
      id
      name
      description
      count
      status
      createdAt
      user {
        id
        name
        email
        company
      }
      images {
        items {
          id
          key
          url
          createdAt
          width
          height
          thumbnail {
            key
            width
            height
          }
        }
        nextToken
      }
    }
  }
`
export const itemsByEmail = /* GraphQL */ `
  query ItemsByEmail(
    $email: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByEmail(
      email: $email
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        company
        owner
        paid
        createdAt
      }
      nextToken
    }
  }
`

export const searchProjects = /* GraphQL */ `
  query SearchProjects(
    $filter: SearchableProjectFilterInput
    $sort: [SearchableProjectSortInput]
    $limit: Int
    $nextToken: String
  ) {
    searchProjects(filter: $filter, sort: $sort, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        categories {
          color
          name
          type
          parent
          options
          multiSelect
          classType
          required
        }
        createdAt
        datasets {
          items {
            dataset {
              id
              name
              count
            }
          }
          nextToken
        }
        status
        datasetSort
        reviewType
        tagsCount
        tagsSaveCount
        tagsReviewCount
        imagesSavedCount
        imagesReviewedCount
        team {
          id
          name
          status
        }
        user {
          id
          name
          email
        }
      }
      nextToken
    }
  }
`
export const listTeams = /* GraphQL */ `
  query ListTeams($filter: ModelTeamFilterInput, $limit: Int, $nextToken: String) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        user {
          id
          name
          email
          company
          status
        }
        status
        access
        members {
          items {
            id
          }
          nextToken
        }
        owner
      }
      nextToken
    }
  }
`
export const searchTeams = /* GraphQL */ `
  query SearchTeams(
    $filter: SearchableTeamFilterInput
    $sort: [SearchableTeamSortInput]
    $limit: Int
    $nextToken: String
  ) {
    searchTeams(filter: $filter, sort: $sort, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          createdAt
        }
        status
        access
        members {
          items {
            id
          }
          nextToken
        }
        projects {
          nextToken
        }
        owner
      }
      nextToken
    }
  }
`
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      name
      access
      user {
        id
        name
        email
      }
      status
      members {
        items {
          id
          role
          createdAt
          user {
            id
            name
            email
            owner
          }
        }
        nextToken
      }
      owner
    }
  }
`
export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      id
      height
      width
      key
      done
      accepted
      skiped
      annotations {
        circles {
          id
          name
          color
          type
          text
          pos {
            center {
              x
              y
            }
            left
            top
          }
        }
        lines {
          id
          name
          color
          type
          text
          pos {
            x
            y
          }
          classes {
            name
            options
            selected
            values
            required
            multiSelect
          }
        }
        points {
          id
          name
          color
          type
          text
          pos {
            x
            y
          }
          classes {
            name
            options
            selected
            values
            required
            multiSelect
          }
        }
        polys {
          id
          name
          color
          type
          text
          pos {
            x
            y
          }
          classes {
            name
            options
            selected
            values
            required
            multiSelect
            classType
          }
        }
        rects {
          id
          name
          color
          type
          pos
          parent
          text
          classes {
            name
            options
            selected
            values
            required
            multiSelect
            classType
          }
        }
        segmentations {
          all {
            name
            color
            type
            shapes {
              points {
                x
                y
              }
              completed
              deleted
            }
          }
          stack {
            id
            completed
            deleted
            points {
              x
              y
            }
            type
          }
        }
        classification {
          name
          options
          selected
          values
          multiSelect
          classType
        }
      }

      count
      time
      labelerTime
      reviewerTime
      status
      createdAt
      labeler {
        id
        name
        email
        status
      }
      reviewer {
        id
        name
        email
        status
      }
      image {
        id
        key
        url
        status
        width
        height
        embeddings {
          samH
        }
        generateEmbeddings {
          samH
        }
      }
      project {
        id
        name
        categories {
          color
          name
          type
        }
        createdAt
        status
        team {
          id
          name
          status
          owner
        }
        owner
      }
      tagProjectId
      tagLabelerId
      tagReviewerId
    }
  }
`
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      name
      categories {
        color
        name
        type
        parent
        options
        required
        multiSelect
        classType
      }
      createdAt
      datasetSort
      reviewType
      status
      team {
        id
        name
        user {
          id
          name
          email
          company
        }
      }
      user {
        id
        name
        email
        company
      }
      datasets {
        items {
          dataset {
            id
            name
            count
          }
          id
        }
        nextToken
      }
      owner
      tagsCount
      tagsSaveCount
      tagsReviewCount
      imagesSavedCount
      imagesReviewedCount
      imagesSkippedCount
      categoriesCount {
        name
        count
        countSaved
        countReviewed
      }
    }
  }
`

export const getViewProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      name
      categories {
        color
        name
        type
        parent
        options
        required
        multiSelect
        classType
      }
      createdAt
      status
      team {
        id
        name
        user {
          id
          name
          email
          company
        }
      }
      datasets {
        items {
          dataset {
            id
            name
            count
          }
        }
        nextToken
      }
      user {
        id
        name
        email
        company
      }
      owner
      tagsCount
      tagsSaveCount
      tagsReviewCount
      imagesSavedCount
      imagesReviewedCount
      imagesSkippedCount
      categoriesCount {
        name
        count
        countSaved
        countReviewed
      }
    }
  }
`
export const searchTags = /* GraphQL */ `
  query SearchTags(
    $filter: SearchableTagFilterInput
    $sort: [SearchableTagSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableTagAggregationInput]
  ) {
    searchTags(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        height
        width
        key
        done
        skiped
        accepted
        categoriesCount {
          name
          count
        }
        count
        status
        createdAt
        assignedAt
        savedAt
        reviewedAt
        labeler {
          id
          name
          email
        }
        reviewer {
          id
          name
          email
        }
      }
      nextToken
    }
  }
`
export const imagesByDataset = /* GraphQL */ `
  query ImagesByDataset(
    $imageDatasetId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    imagesByDataset(
      imageDatasetId: $imageDatasetId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        key
        url
        status
        createdAt
        imageDatasetId
        width
        height
        updatedAt 
        embeddings {
          samH
        }
        generateEmbeddings {
          samH
        }
      }
      nextToken
    }
  }
`
