import React from 'react'
import Check from '@mui/icons-material/Check'
import Cancel from '@mui/icons-material/Cancel'
import { Button, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'

const ConfirmButton = styled(Button)(({ region }) => ({
  minWidth: 45,
  width: 45,
  position: 'absolute',
  top: region.toolsPosY - 40,
  left: region.toolsPosX,
  zIndex: 7
}))

const CancelButton = styled(ConfirmButton)(({ region }) => ({
  left: region.toolsPosX + 60
}))

export default function SamButtons({ region, cancel, confirmMask, disabled }) {
  return (
    <>
      <Tooltip title="Confirm Mask">
        <ConfirmButton
          disabled={disabled}
          onClick={confirmMask}
          variant="contained"
          color="primary"
          region={region}
        >
          <Check />
        </ConfirmButton>
      </Tooltip>
      <Tooltip title="Cancel Mask">
        <CancelButton
          disabled={disabled}
          onClick={cancel}
          variant="contained"
          color="primary"
          region={region}
        >
          <Cancel />
        </CancelButton>
      </Tooltip>
    </>
  )
}
