class FilterChain {
  constructor() {
    this.handlers = []
  }

  /**
   *
   * @param {AbstractFilterHandler} filter
   */
  addHandler(handler) {
    this.handlers.push(handler)
  }

  verifyRules() {
    let result = true
    this.handlers.forEach((handler) => {
      const checkCondition = handler.checkCondition()
      if (!checkCondition) {
        result = false
      }
    })
    return result
  }
}

export default FilterChain
