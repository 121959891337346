import React, { useContext } from 'react'
import PanTool from '@mui/icons-material/PanTool'
import Add from '@mui/icons-material/Add'
import Accessibility from '@mui/icons-material/Accessibility'
import { Button } from '@mui/material'
import { LabelerContext } from '../../../containers/labeler/context/LabelerContext'
import { DispatchContext } from '../../../containers/labeler/context/DispatchContext'
import BarTooltip from '../BarTooltip'

export default function LabelButtons() {
  const {
    state: {
      labelerState: { mode }
    }
  } = useContext(LabelerContext)
  const { dispatch } = useContext(DispatchContext)

  return (
    <>
      <BarTooltip title={'[Space] Draw'}>
        <Button
          sx={
            mode === 'draw'
              ? { minWidth: 48, width: 48, background: 'rgba(120,120,120,0.3)' }
              : { minWidth: 48, width: 48 }
          }
          size="small"
          onClick={() => {
            dispatch({ type: 'setLabelerMode', payload: { category: 'point', mode: 'draw' } })
          }}
        >
          <Add fontSize="small" />
        </Button>
      </BarTooltip>
      <BarTooltip title={'[Space] Edit'}>
        <Button
          sx={
            mode === 'edit'
              ? { minWidth: 48, width: 48, background: 'rgba(120,120,120,0.3)' }
              : { minWidth: 48, width: 48 }
          }
          size="small"
          onClick={() => {
            dispatch({ type: 'setLabelerMode', payload: { category: 'point', mode: 'edit' } })
          }}
        >
          <PanTool fontSize="small" />
        </Button>
      </BarTooltip>
      <BarTooltip title={'[Space] Edit'}>
        <Button
          sx={
            mode === 'template'
              ? { minWidth: 48, width: 48, background: 'rgba(120,120,120,0.3)' }
              : { minWidth: 48, width: 48 }
          }
          size="small"
          onClick={() => {
            dispatch({ type: 'setLabelerMode', payload: { category: 'point', mode: 'template' } })
          }}
        >
          <Accessibility fontSize="small" />
        </Button>
      </BarTooltip>
    </>
  )
}
