import * as React from 'react'
import TextField from '@mui/material/TextField'

const FieldGroup = ({ id, label, style, ...props }) => {
  return (
    <>
      <p>
        <b>{label}</b>
      </p>
      <TextField
        id={id}
        label={label}
        variant="outlined"
        {...props}
        style={{ width: '100%', marginBottom: style?.marginBottom || '1rem', ...style }}
      />
    </>
  )
}

export default FieldGroup
