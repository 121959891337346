export const getSegments = (arr, start, end) => {
  if (start < 0 || start >= arr.length || end < 0 || end >= arr.length || start > end) {
    return []
  }

  const segments = arr.slice(start, end + 1)

  return segments
}

export const searchPage = (pages, property, valor) => {
  return pages.some((page) => page[property] === valor)
}

export const shouldShowLoadPrevResults = (currentPage, AUX_INDEX, pagesLoaded, MAX_PAGES) => {
  return (
    currentPage > AUX_INDEX ||
    (currentPage <= AUX_INDEX && currentPage !== 0 && pagesLoaded > MAX_PAGES)
  )
}

export const shouldShowLoadMoreResults = (
  totalDataLoaded,
  totalImagesInRequest,
  currentPage,
  SIZE_REQUEST,
  pagesLoaded
) => {
  return (
    totalDataLoaded < totalImagesInRequest ||
    !(currentPage + SIZE_REQUEST === pagesLoaded * SIZE_REQUEST)
  )
}
