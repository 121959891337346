import React, { useRef, useContext, useState } from 'react'
import useZoom from '../hooks/useZoom'
import useResizeCategoryCanvas from '../hooks/useResizeCategoryCanvas'
import { LabelerContext } from '../../../context/LabelerContext'
import { DispatchContext } from '../../../context/DispatchContext'
import { v4 as uuidv4 } from 'uuid'
import { useCategoryEventListener } from '../hooks/useCategoryEventListener'
import { useEffectWR } from '../hooks/useEffectWR'
import { useClearCanvas } from '../hooks/useClearCanvas'
import { useDrawTags } from '../hooks/useDrawTags'

export default function CircleCanvas({ labeler, panZoom, mouse }) {
  const { state } = useContext(LabelerContext)
  const { dispatch } = useContext(DispatchContext)
  const { circleState, labelerState } = state

  const [circle, setCircle] = useState(null)
  const [editCircle, setEditCircle] = useState(null)

  const canvasIncompleteFigures = useRef(document.createElement('canvas', null, null))
  const canvasCompleteFigures = useRef(document.createElement('canvas', null, null))
  const canvasLineGuide = useRef(document.createElement('canvas', null, null))

  useResizeCategoryCanvas(
    [canvasIncompleteFigures, canvasCompleteFigures, canvasLineGuide],
    'ellipse'
  )
  useDrawTags(() => {
    drawAllCircles()
  }, 'ellipse')
  useZoom(
    panZoom,
    mouse.wheel,
    [canvasIncompleteFigures, canvasCompleteFigures, canvasLineGuide],
    () => {
      drawAllCircles()
    }
  )

  useCategoryEventListener('mousedown', mouseDown, labeler.current, 'ellipse')
  useCategoryEventListener('mouseup', mouseUp, labeler.current, 'ellipse')
  useCategoryEventListener('mousemove', mouseMove, labeler.current, 'ellipse')
  useCategoryEventListener('wheel', mouseWheel, labeler.current, 'ellipse')
  useEffectWR([circleState.redraw], [() => drawAllCircles()])

  useClearCanvas([canvasIncompleteFigures, canvasCompleteFigures, canvasLineGuide], 'ellipse')
  useEffectWR(
    [editCircle],
    [
      function () {
        drawAllCircles()
      }
    ]
  )

  function mouseDown(e) {
    if (e.button === 0) {
      if (labelerState.mode === 'draw') {
        if (circle !== null) {
          const ellipseType = labelerState.selectedCategory
          const ellipse = {
            id: uuidv4(),
            childs: [],
            name: ellipseType.name,
            color: ellipseType.color,
            type: 'ellipse',
            pos: { x: circle.x, y: circle.y, r: circle.r },
            show: true
          }
          dispatch({ type: 'addCircle', payload: ellipse })
          drawAllCircles(false, circle)
          setCircle(null)
        } else {
          drawEllipe((mouse.x - panZoom.x) / panZoom.scale, (mouse.y - panZoom.y) / panZoom.scale)
        }

        return
      }
      if (labelerState.mode === 'edit') {
        const ellipse = handleSelectCircle(
          (mouse.x - panZoom.x) / panZoom.scale,
          (mouse.y - panZoom.y) / panZoom.scale
        )
        if (ellipse !== null) {
          setEditCircle(ellipse)
        }
      }
    }
  }
  function mouseUp(e) {
    if (labelerState.mode === 'edit') {
      if (e.button === 0) {
        if (editCircle !== null) {
          confirmEdit(
            (mouse.x - panZoom.x) / panZoom.scale,
            (mouse.y - panZoom.y) / panZoom.scale,
            circleState.tags[editCircle.index].pos.r
          )
        }
      }
    }
  }
  function mouseMove() {
    drawLineGuide((mouse.x - panZoom.x) / panZoom.scale, (mouse.y - panZoom.y) / panZoom.scale)
    if (labelerState.mode === 'edit') {
      if (mouse.button) {
        if (editCircle !== null) {
          moveCircle((mouse.x - panZoom.x) / panZoom.scale, (mouse.y - panZoom.y) / panZoom.scale)
        }
      }
    }
    if (labelerState.mode === 'draw') {
      if (circle !== null) {
        resizeEllipse((mouse.x - panZoom.x) / panZoom.scale, (mouse.y - panZoom.y) / panZoom.scale)
      }
    }
  }
  function mouseWheel(e) {
    if (labelerState.mode === 'template') {
      e.stopPropagation()
      let ms = 0
      ms = ms + -e.deltaY
      let scale = 1
      if (ms !== 0) {
        // eslint-disable-next-line no-unused-vars
        scale = ms < 0 ? 1 / 1.01 : 1.01
        ms = ms * 0.8
        if (Math.abs(ms) < 1) {
          ms = 0
        }
      }
    }
  }

  const confirmEdit = (x, y, r) => {
    dispatch({ type: 'setCirclePos', payload: { index: editCircle.index, x, y, r } })
    const canvas = canvasIncompleteFigures.current
    const ctx = canvas.getContext('2d')
    ctx.setTransform(1, 0, 0, 1, 0, 0)
    ctx.clearRect(0, 0, canvas.width, canvas.height)
  }

  const moveCircle = (x, y) => {
    const e = circleState.tags[editCircle.index].pos
    const canvas = canvasIncompleteFigures.current
    const ctx = canvas.getContext('2d')
    ctx.setTransform(1, 0, 0, 1, 0, 0)
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    ctx.beginPath()
    ctx.setTransform(panZoom.scale, 0, 0, panZoom.scale, panZoom.x, panZoom.y)

    ctx.ellipse(x, y, e.r, e.r, 0, 0, 2 * Math.PI)
    ctx.fillStyle = '#ffffff4D'
    ctx.strokeStyle = '#000'
    ctx.stroke()
    ctx.fill()
  }

  const drawEllipe = (x, y) => {
    const ellipseType = labelerState.selectedCategory
    const canvas = canvasIncompleteFigures.current
    const ctx = canvas.getContext('2d')
    ctx.setTransform(1, 0, 0, 1, 0, 0)
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    ctx.beginPath()
    ctx.setTransform(panZoom.scale, 0, 0, panZoom.scale, panZoom.x, panZoom.y)
    ctx.ellipse(x, y, 5, 5, 0, 0, 2 * Math.PI)
    ctx.fillStyle = ellipseType.color + '60'
    ctx.strokeStyle = ellipseType.color
    ctx.stroke()
    ctx.fill()
    setCircle({ x, y, r: 5, color: ellipseType.color })
  }

  const resizeEllipse = (x, y) => {
    const ellipseType = labelerState.selectedCategory
    const canvas = canvasIncompleteFigures.current
    const ctx = canvas.getContext('2d')
    ctx.setTransform(1, 0, 0, 1, 0, 0)
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    ctx.beginPath()
    ctx.setTransform(panZoom.scale, 0, 0, panZoom.scale, panZoom.x, panZoom.y)
    const rx = Math.abs(circle.x - x)
    const ry = Math.abs(circle.y - y)
    const r = rx > ry ? rx : ry
    ctx.ellipse(circle.x, circle.y, r, r, 0, 0, 2 * Math.PI)
    ctx.fillStyle = ellipseType.color + '60'
    ctx.strokeStyle = ellipseType.color
    ctx.stroke()
    ctx.fill()
    setCircle({ ...circle, r })
  }
  const drawAllCircles = (redraw = true, tags = circleState.tags) => {
    const canvas = canvasCompleteFigures.current
    const ctx = canvas.getContext('2d')
    ctx.beginPath()
    if (redraw) {
      ctx.setTransform(1, 0, 0, 1, 0, 0)
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.setTransform(panZoom.scale, 0, 0, panZoom.scale, panZoom.x, panZoom.y)

      tags.forEach((ellipse, index) => {
        ctx.beginPath()
        ctx.ellipse(ellipse.pos.x, ellipse.pos.y, ellipse.pos.r, ellipse.pos.r, 0, 0, 2 * Math.PI)
        ctx.fillStyle = editCircle?.index === index ? '#ffffff4D' : ellipse.color + '60'
        ctx.strokeStyle = editCircle?.index === index ? '#000' : ellipse.color
        ctx.stroke()
        ctx.fill()
      })
      if (editCircle !== null) {
        ctx.beginPath()
        ctx.fillStyle = '#ffffff4D'
        ctx.strokeStyle = '#000'

        ctx.moveTo(editCircle.a.x, editCircle.a.y)
        ctx.arc(editCircle.a.x, editCircle.a.y, 8, 0, 2 * Math.PI)

        ctx.moveTo(editCircle.b.x, editCircle.b.y)
        ctx.arc(editCircle.b.x, editCircle.b.y, 8, 0, 2 * Math.PI)

        ctx.moveTo(editCircle.c.x, editCircle.c.y)
        ctx.arc(editCircle.c.x, editCircle.c.y, 8, 0, 2 * Math.PI)

        ctx.moveTo(editCircle.d.x, editCircle.d.y)
        ctx.arc(editCircle.d.x, editCircle.d.y, 8, 0, 2 * Math.PI)
        ctx.stroke()
        ctx.fill()
      }

      return
    }
    const canvas1 = canvasIncompleteFigures.current
    const ctx1 = canvas1.getContext('2d')
    ctx1.setTransform(1, 0, 0, 1, 0, 0)
    ctx1.clearRect(0, 0, canvas.width, canvas.height)

    ctx.ellipse(tags.x, tags.y, tags.r, tags.r, 0, 0, 2 * Math.PI)
    ctx.fillStyle = tags.color + '60'
    ctx.strokeStyle = tags.color
    ctx.stroke()
    ctx.fill()
  }

  const handleSelectCircle = (x, y) => {
    let ind = null
    circleState.tags.forEach((ellipse, index) => {
      const dx = (x - ellipse.pos.x) ** 2 / ellipse.pos.r ** 2
      const dy = (y - ellipse.pos.y) ** 2 / ellipse.pos.r ** 2
      if (dx + dy <= 1) {
        ind = {
          index,
          a: { x: ellipse.pos.x - ellipse.pos.r, y: ellipse.pos.y },
          b: { x: ellipse.pos.x, y: ellipse.pos.y - ellipse.pos.r },
          c: { x: ellipse.pos.x + ellipse.pos.r, y: ellipse.pos.y },
          d: { x: ellipse.pos.x, y: ellipse.pos.y + ellipse.pos.r }
        }
      }
    })
    return ind
  }
  const drawLineGuide = () => {}

  return (
    <>
      <canvas className="layout" ref={canvasCompleteFigures} />
      <canvas className="layout" ref={canvasIncompleteFigures} />
      <canvas className="layout" ref={canvasLineGuide} style={{ cursor: labelerState.cursor }} />
    </>
  )
}
