import React from 'react'
import { styled } from '@mui/material/styles'
import { GoogleIcon } from './GoogleIcon'

const ButtonComponent = styled('div')(() => ({
  display: 'inline-block',
  background: 'white',
  color: '#444',
  width: '190px',
  borderRadius: '5px',
  border: 'thin solid #888',
  boxShadow: '1px 1px 1px grey',
  whiteSpace: 'nowrap',
  '&:hover': {
    cursor: 'pointer'
  }
}))

const ButtonTextComponent = styled('span')(() => ({
  display: 'inline-block',
  verticalAlign: 'middle',
  paddingLeft: '8px',
  paddingRight: '8px',
  fontSize: '14px',
  fontWeight: 'bold',
  /* Use the Roboto font that is loaded in the <head> */
  fontFamily: 'Roboto, sans-serif'
}))

const GoogleSignInButton = (props) => {
  return (
    <ButtonComponent {...props} className="customGPlusSignIn">
      <div style={{ display: 'flex', padding: 0, margin: 0, alignItems: 'center' }}>
        <GoogleIcon />
        <ButtonTextComponent>Login with Google</ButtonTextComponent>
      </div>
    </ButtonComponent>
  )
}

export default GoogleSignInButton
