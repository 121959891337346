import React, { useRef, useEffect } from 'react'
import { Chart } from 'chart.js'

export default function Pie(props) {
  const chartRef = useRef(null)
  useEffect(() => {
    chartRef.current = new Chart(chartRef.current, {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: props.data.map((d) => d.value),
            backgroundColor: props.data.map((d) => d.color)
          }
        ]
      },
      options: {
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          arc: {
            borderWidth: 0
          }
        },
        tooltips: {
          mode: 'label',
          callbacks: {
            label: function (tooltipItem, data) {
              const indice = tooltipItem.index
              return data.labels[indice] + ': ' + data.datasets[0].data[indice] + '%'
            }
          }
        }
      }
    })
  }, [])
  useEffect(() => {
    chartRef.current.data.datasets[0].data = []
    chartRef.current.data.datasets[0].data = props.data.map((d) => d.percentage)
    chartRef.current.data.datasets[0].backgroundColor = props.data.map((d) => d.color)
    chartRef.current.update()
  }, [props.data])

  return (
    <div style={{ width: '100%', height: '300px' }}>
      <canvas className={props.className} ref={chartRef} />
    </div>
  )
}
