import React from 'react'
import Dropzone from 'react-dropzone'

const ImageDropzone = ({ children, onDrop, noClick = false }) => {
  return (
    <Dropzone
      accept={{
        'image/*': ['.png', '.bmp', '.jpeg', '.jpg'],
        'application/json': ['.json']
      }}
      onDrop={onDrop}
      noClick={noClick}
    >
      {children}
    </Dropzone>
  )
}

export default ImageDropzone
