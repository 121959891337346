import React, { useContext } from 'react'
import { IconButton, Paper, Slider, Divider } from '@mui/material'
import ScaleIcon from '@mui/icons-material/PhotoSizeSelectLarge'
import { FiltersContext } from '../../../../../context/FiltersContext'

const Resizer = () => {
  const { state, dispatch } = useContext(FiltersContext)
  return (
    <Paper
      elevation={0}
      variant="outlined"
      sx={{
        flex: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 0.5,
        margin: 0.5
      }}
    >
      <Slider
        size="small"
        defaultValue={1}
        value={state.gridSize * 10}
        step={1}
        min={1}
        max={9}
        onChange={(e, value) => dispatch({ type: 'setGridSize', payload: value })}
        sx={{ margin: '0.5rem 1rem' }}
      />
      <Divider size="small" orientation="vertical" flexItem sx={{ margin: 0.5 }} />
      <IconButton type="button" aria-label="search" disabled>
        <ScaleIcon />
      </IconButton>
    </Paper>
  )
}

export default Resizer
