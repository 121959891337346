import { useState, useContext, useEffect } from 'react'
import { useLabelerMachine } from '../../../context/MachineContext'
import { LabelerContext } from '../../../context/LabelerContext'
import { DispatchContext } from '../../../context/DispatchContext'
import { s3Get } from '../../../../../services/aws'

export function useLoadingImage() {
  const [progress, setProgress] = useState(0)
  const { state: MachineState, send } = useLabelerMachine()
  const { state } = useContext(LabelerContext)
  const [selectedImage, setSelectedImage] = useState(null)
  const { labelerState } = state
  const { dispatch } = useContext(DispatchContext)

  useEffect(() => {
    if (state.labelerState.image.navigation === 'return') {
      getImage()
    }
  }, [state.labelerState.image.navigation])

  useEffect(() => {
    if (MachineState.value === 'getImage') {
      setSelectedImage(labelerState.image.currentImage)
    }
  }, [labelerState.image.currentImage])

  useEffect(() => {
    if (selectedImage !== null) getImage()
  }, [selectedImage])

  const getImage = async () => {
    try {
      dispatch({ type: 'resetCanvasSettings' })
      const _s3get = await s3Get(selectedImage.key)
      dispatch({ type: 'setActualImage', payload: _s3get })
      progressImage(_s3get)
    } catch (error) {
      console.log(error)
    }
  }

  function progressImage(image) {
    if (image === undefined) return
    if (MachineState.value === 'getImage') {
      const request = new XMLHttpRequest()
      request.open('GET', image, true)
      request.overrideMimeType('text/plain; charset=x-user-defined')
      request.send(null)

      request.onprogress = onProgress
      request.onload = onComplete
      request.onerror = onError
    }
  }

  function onProgress(event) {
    if (!event.lengthComputable) {
      return
    }
    const loaded = event.loaded
    const total = event.total
    const progress = (loaded / total).toFixed(2)

    setProgress(parseInt(progress * 100))
  }

  function onComplete() {
    if (MachineState.value === 'getImage') {
      send({ type: 'RESOLVE', source: 'useLoadingImage' })
    }
    dispatch({ type: 'setRenderedImage', payload: true })
  }

  function onError() {
    console.log('Error getting image')
    setProgress('Error!')
  }

  return progress
}
