import React from 'react'
import { Grid, List, Paper, Typography, Divider, ListItem } from '@mui/material'
import BoltIcon from '@mui/icons-material/Bolt'
import { getUser } from '../utils/util'

export default function UserAccount() {
  const user = getUser()

  return (
    <div
      style={{
        marginBottom: 60,
        marginTop: 70,
        marginRight: '10%',
        marginLeft: '10%',
        display: 'flex',
        flexWrap: 'nowrap',
        flexGrow: 1
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h3" style={{ margin: '1rem' }}>
            Your Data
          </Typography>
          <Paper elevation={2}>
            <List>
              <ListItem key="Name">
                <Typography>
                  <b>Name: </b>
                  {user.name}
                </Typography>
              </ListItem>
              <ListItem key="Email">
                <Typography>
                  <b>Email: </b>
                  {user.email}
                </Typography>
              </ListItem>
              <Divider />
              <ListItem key="membership">
                <Typography>{user.paid ? 'Pro' : 'Free'}</Typography>
                {user.paid ? <BoltIcon color="secondary" /> : null}
              </ListItem>
            </List>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}
