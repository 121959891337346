import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { Grid, Fab, Typography, LinearProgress } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import { user as u } from '../../../../io/user/actions'
import ProjectsList from './components/ProjectsList'
import { sandProjectCreationStart, sandLabelerViewed } from '../../../../io/analytics'
import { project as p } from '../../../../io/project/actions'
import SearchBar from '../../../../components/generics/textfields/SearchBar'
import { dialog } from '../../../../io/dialogs/actions'
import SharedProjectsList from './components/SharedProjectsList'

function Projects(props) {
  const fields = [
    {
      id: 'name',
      type: 'text',
      label: 'Name',
      minWidth: 200,
      format: (value) => {
        const names = value.split('/')
        return names[names.length - 1]
      },
      searchable: 'wildcard'
    }
  ]

  useEffect(() => {
    props.getProjects()
    props.getSharedProjects()
  }, [props.filters])

  // props.getSharedProjects(null, props.filters || null, true)

  const navigate = useNavigate()

  const loadMore = () => {
    props.getProjects(props.projects.nextToken)
  }

  const loadMoreShared = () => {
    props.getSharedProjects(props.sharedProjects.nextToken)
  }

  const labelIt = (project, role) => {
    props.setProject(project)
    sandLabelerViewed({ id: project.id })
    setTimeout(() => {
      navigate(role ? `/labeler?role=${role}` : '/labeler')
    }, 200)
  }

  const filterChange = (filters) => {
    props.getProjects(null, filters, true)
    props.getSharedProjects(null, filters, true)
  }

  const { projects, sharedProjects } = props

  const _projects = projects.items
  const _sharedProjects = sharedProjects.items

  return (
    <Grid container>
      <Grid
        item
        xs={6}
        sx={{
          display: 'flex',
          padding: '10px',
          alignItems: 'center',
          justifyItems: 'center'
        }}
      >
        <Link to="/createproject" onClick={sandProjectCreationStart}>
          <Fab size="medium" color="primary" aria-label="Add">
            <AddIcon />
          </Fab>
        </Link>
        <Link to="/createproject" onClick={sandProjectCreationStart}>
          <Typography style={{ marginLeft: '8px' }} variant="h6">
            {' '}
            Create new project
          </Typography>
        </Link>
      </Grid>
      <Grid item xs={6}>
        <SearchBar
          sx={{ marginTop: '12px' }}
          fields={fields}
          filtersChange={filterChange}
          justify="right"
          singleSearch
        />
      </Grid>
      <Grid item xs={12}>
        {projects.loading && <LinearProgress />}
        <ProjectsList
          projects={_projects}
          nextToken={projects.nextToken}
          loading={projects.loading}
          loadMore={loadMore}
          labelIt={labelIt}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">Shared with you</Typography>
        <SharedProjectsList
          projects={_sharedProjects}
          loadMore={loadMoreShared}
          loading={sharedProjects.loading}
          nextToken={sharedProjects.nextToken}
          labelIt={labelIt}
        />
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  projects: state.user.projects,
  sharedProjects: state.user.sharedProjects
})

const mapDispatchToProps = {
  getProjects: u.getMyProjects,
  getSharedProjects: u.getSharedProjects,
  setProject: p.setProject,
  setDialogStatus: dialog.setStatusLabelerMetricsDialog,
  setLabelerEmail: dialog.setLabelerEmail,
  setReviwerEmail: dialog.setReviwerEmail
}

export default connect(mapStateToProps, mapDispatchToProps)(Projects)
