import React, { useContext, useState } from 'react'
import {
  Chip,
  List,
  Button,
  Select,
  Divider,
  MenuItem,
  ListItem,
  TextField,
  IconButton,
  Typography,
  InputLabel,
  FormControl,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  ListItemSecondaryAction
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import ColorPicker from './generics/textfields/ColorPicker'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { styled } from '@mui/material/styles'
import { TYPES_ATTRS } from '../utils/constants'
import { randomColor } from '../utils/util'
import { LabelerContext } from '../containers/labeler/context/LabelerContext'
import { DispatchContext } from '../containers/labeler/context/DispatchContext'
import ColoredDot from './generics/ColoredDot'
import { useSelector } from 'react-redux'
import { useLabelerMachine } from '../containers/labeler/context/MachineContext'

const RootContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.subpaper,
  width: 'auto'
}))

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: 6
}))

const FormControlSelect = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1),
  width: '90%'
}))

const ContainerTextField = styled('div')(() => ({
  width: '100%',
  paddingRight: '10%'
}))

export default function CategoriesManager(props) {
  const { state: MachineState } = useLabelerMachine()
  const { dispatch } = useContext(DispatchContext)
  const { state } = useContext(LabelerContext)
  const project = useSelector((state) => state.project)
  const [selectedTab, setSelectedTab] = useState(0)
  const [newCategory, setNewCategory] = useState({ newType: '', newName: '' })
  const [newColor, setNewColor] = useState(randomColor(30))

  const { labelerState, classificationState } = state

  const handleChange = (event, value) => {
    event.preventDefault()
    setSelectedTab(value)
    props.changeCurrentCategoryTab(value)
  }

  const handleListItemClick = (event, index, cat) => {
    dispatch({ type: 'setSelectedCategory', payload: { cat } })
    if (cat.type === 'segmentation') {
      dispatch({
        type: 'setSelectedSegmentation',
        payload: cat
      })
    }
    props.changeCategory(index)
  }

  const handleInputChange = (event, name) => {
    event.preventDefault()
    setNewCategory({ ...newCategory, [name]: event.target.value })
    event.stopPropagation()
    event.returnValue = false
  }

  const handleColorChange = (event, name, value) => {
    if (!value) return
    setNewColor(value)
  }

  const handleAddClick = () => {
    if (!newCategory.newName) {
      return
    }
    props.addCategory(newCategory.newName, newColor, newCategory.newType)
    setNewCategory({ newName: '', newType: '' })
    setNewColor(randomColor(30))
  }

  const orderCategories = (categories) => {
    const cats = []
    for (let i = 0; i < categories.length; i++) {
      const cat = { ...categories[i] }
      if (!cat.parent) {
        cat.id = i
        cat.count = 0
        cats.push(cat)
      }
    }

    for (let i = 0; i < categories.length; i++) {
      const cat = { ...categories[i] }
      if (cat.parent) {
        cat.id = i
        for (let j = 0; j < cats.length; j++) {
          if (cat.parent === cats[j].name) {
            cats[j].count++
            cat.kid = true
            cats.splice(j + 1, 0, cat)
          }
        }
      }
    }
    return cats
  }

  const handleCategoryValue = (e, index, multiple = false, classType) => {
    if (multiple) {
      dispatch({
        type: 'setClassificationMultipleValue',
        payload: { index, value: e.target.value }
      })
      return
    }
    let value
    switch (classType) {
      case 'int':
        value = e.target.value.replace(/[^0-9]/g, '')
        break
      case 'float':
        value = e.target.value.replace(/[^0-9.]/g, '')
        break
      default:
        value = e.target.value
        break
    }
    dispatch({ type: 'setClassificationSimpleValue', payload: { index, value } })
  }

  const handleHideCat = (cat) => {
    switch (cat.type) {
      case 'bounding_box':
        dispatch({ type: 'hideBoundingCategory', payload: { name: cat.name } })
        break
      case 'point':
        dispatch({ type: 'hideLandmarkCategory', payload: { name: cat.name } })
        break
      case 'segmentation':
        dispatch({ type: 'hideSegmentationCategory', payload: { name: cat.name } })
        break
      case 'polygon':
        dispatch({ type: 'hidePolygonCategory', payload: { name: cat.name } })
        break
      default:
        break
    }
    dispatch({ type: 'setHideCat', payload: { name: cat.name } })
  }

  const renderCategoryItem = (cat, index, kid) => {
    return (
      <ListItemButton
        key={index}
        style={!kid ? {} : { paddingLeft: 20 }}
        button
        selected={labelerState.selectedCategory.name === cat.name}
        onClick={(event) => handleListItemClick(event, index, cat)}
        onMouseEnter={() => {
          props.mouseEnterOnCategory(cat.name)
        }}
        onMouseLeave={() => {
          props.mouseLeaveOnCategory(cat.name)
        }}
      >
        <ListItemIcon sx={{ minWidth: 0, marginRight: 1 }}>
          <ColoredDot color={cat.color} size={12} />
        </ListItemIcon>
        <ListItemText
          sx={{ textAlign: 'left' }}
          primary={<Typography noWrap>{cat.name}</Typography>}
        />
        <Button
          size="small"
          onClick={(e) => {
            handleHideCat(cat, index)
            e.preventDefault()
            e.stopPropagation()
            e.nativeEvent.stopImmediatePropagation()
          }}
        >
          {labelerState.isHiddenCat[cat.name]?.isHidden ? (
            <VisibilityOffIcon fontSize="small" />
          ) : (
            <VisibilityIcon fontSize="small" />
          )}
        </Button>
        {project && project.id ? null : (
          <ListItemIcon sx={{ minWidth: 0 }}>
            <IconButton
              sx={{ margin: 0, padding: 0 }}
              onClick={() => props.deleteCategory(index)}
              aria-label="Delete"
              size="large"
            >
              <DeleteIcon sx={{ width: 24, height: 24, padding: 2 }} />
            </IconButton>
          </ListItemIcon>
        )}
      </ListItemButton>
    )
  }

  const renderClassificationItem = (cat, index) => {
    const isMultiple = cat.multiSelect || cat.classType === 'multiple'

    return (
      <ListItem key={index} sx={{ margin: 0, paddingRight: 0 }}>
        <ListItemText
          primary={<Typography noWrap>{`${cat.name} ${cat.required ? '*' : ''} `}</Typography>}
          secondary={
            <>
              {cat.classType !== 'string' &&
              cat.classType !== 'int' &&
              cat.classType !== 'float' ? (
                <FormControlSelect variant="standard" style={{ margin: 0, padding: 0 }}>
                  <InputLabel id="select-label">Select</InputLabel>
                  <Select
                    fullWidth
                    variant="standard"
                    disabled={labelerState.role === 'viewer' || MachineState.context.loading}
                    id="select"
                    multiple={isMultiple}
                    value={cat.selected || (isMultiple ? cat.values : '')}
                    renderValue={(selected) =>
                      isMultiple ? (
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </div>
                      ) : (
                        selected
                      )
                    }
                    onChange={(e) => handleCategoryValue(e, index, isMultiple, cat.classType)}
                  >
                    {cat.options.map((opt) => (
                      <MenuItem value={opt} key={opt}>
                        {opt}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControlSelect>
              ) : (
                <ContainerTextField>
                  <TextField
                    fullWidth
                    variant="standard"
                    placeholder="Text"
                    disabled={labelerState.role === 'viewer' || MachineState.context.loading}
                    value={cat.selected || ''}
                    onChange={(e) => handleCategoryValue(e, index, isMultiple, cat.classType)}
                    required={cat.required}
                    error={cat.required && cat.selected === ''}
                  />
                </ContainerTextField>
              )}
            </>
          }
          secondaryTypographyProps={{ component: 'div' }}
        />
        <ListItemSecondaryAction></ListItemSecondaryAction>
      </ListItem>
    )
  }

  const renderTab0 = () => {
    if (selectedTab === 0 || (project && project.id)) {
      const cats = orderCategories(props.categories).filter((obj) => obj.type !== 'classification')
      const clas = classificationState.tags

      return (
        <>
          <div style={{ height: 'auto', overflow: 'auto', maxHeight: 200 }}>
            <List>{cats.map((cat) => renderCategoryItem(cat, cat.id, cat.kid))}</List>
          </div>
          {clas.length > 0 && (
            <>
              <Divider />
              <div style={{ overflowY: 'scroll', overflow: 'auto', maxHeight: 200, padding: 0 }}>
                <List>{clas.map((cat, index) => renderClassificationItem(cat, index))}</List>
              </div>
            </>
          )}
        </>
      )
    }
  }

  const renderTab1 = () => {
    if (selectedTab === 1 && (!project || !project.id)) {
      return (
        <div style={{ padding: '0 10px' }}>
          <form autoComplete="off">
            <ContainerTextField>
              <TextField
                variant="standard"
                id="outlined-name"
                label="Name"
                value={newCategory.newName}
                onChange={(event) => {
                  event.returnValue = false
                  handleInputChange(event, 'newName')
                }}
                margin="normal"
                fullWidth
              />
            </ContainerTextField>
            <br />
            <br />
            <ColorPicker
              color={newColor}
              onChange={(color) => handleColorChange(null, 'newColor', color)}
            />
            <br />
            <br />
            <FormControl variant="standard" fullWidth>
              <InputLabel htmlFor="type-select">Type</InputLabel>
              <Select
                variant="standard"
                style={{ minWidth: 150 }}
                value={newCategory.newType}
                onChange={(event) => handleInputChange(event, 'newType')}
                inputProps={{
                  name: 'type',
                  id: 'type-select'
                }}
              >
                {TYPES_ATTRS.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    <em>{type.name}</em>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <Button
              variant="contained"
              color="secondary"
              className="btn-add"
              onClick={handleAddClick}
              disabled={newColor === '' || newCategory.newName === '' || newCategory.newType === ''}
            >
              Add
            </Button>
          </form>
        </div>
      )
    }
  }
  return (
    <RootContainer>
      <Title>Categories</Title>
      {project && project.id ? null : (
        <div style={{ display: 'flex' }}>
          <Button
            sx={{ width: '50%' }}
            size="small"
            color={selectedTab === 0 ? 'primary' : 'neutral'}
            onClick={(e) => handleChange(e, 0)}
          >
            Current
          </Button>
          <Button
            sx={{ width: '50%' }}
            size="small"
            color={selectedTab === 1 ? 'primary' : 'neutral'}
            onClick={(e) => handleChange(e, 1)}
          >
            Add
          </Button>
        </div>
      )}
      <Divider />
      {renderTab0()}
      {renderTab1()}
    </RootContainer>
  )
}
