import React, { useContext } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import SkipIcon from '@mui/icons-material/DoNotDisturbOn'
import UnSkipIcon from '@mui/icons-material/DoNotDisturbOff'
import ReviewIcon from '@mui/icons-material/ThumbUp'
import UnReviewIcon from '@mui/icons-material/ThumbDown'
import DoneIcon from '@mui/icons-material/Done'
import UnDoneIcon from '@mui/icons-material/RemoveDone'
import CompareIcon from '@mui/icons-material/Compare'

import { updateTagStatus } from '../../../../../../../io/graphql/tag/tags'
import { getUser } from '../../../../../../../utils/util'
import { FiltersContext } from '../../../context/FiltersContext'
import { createRequestParams } from '../utils/statusButtons'

const StatusButtons = ({ role }) => {
  const { state, dispatch } = useContext(FiltersContext)
  const { selectedImage } = state
  const user = getUser()

  const IconConfig = [
    {
      prop: 'compare',
      title: 'Compare Image',
      disabled: !selectedImage,
      icon: () => <CompareIcon />
    },
    {
      prop: 'accepted',
      title: 'Review Image',
      disabled: selectedImage?.accepted ? false : !selectedImage?.done && !selectedImage?.skiped,
      icon: () => (selectedImage?.accepted ? <UnReviewIcon /> : <ReviewIcon />)
    },
    {
      prop: 'skiped',
      title: 'Skip Image',
      disabled: selectedImage?.skiped
        ? selectedImage?.accepted
        : selectedImage?.accepted || selectedImage?.done,
      icon: () => (selectedImage?.skiped ? <UnSkipIcon /> : <SkipIcon />)
    },
    {
      prop: 'done',
      title: 'Done Image',
      disabled: selectedImage?.done
        ? selectedImage?.accepted
        : selectedImage?.accepted || selectedImage?.skiped,
      icon: () => (selectedImage?.done ? <UnDoneIcon /> : <DoneIcon />)
    }
  ]

  const handleAction = (prop) => () => {
    switch (prop) {
      case 'compare':
        handleClickCompare()
        break
      default:
        handleImageState(prop)
    }
  }

  const handleClickCompare = () => {
    dispatch({ type: 'setOpenCompareDialog', payload: true })
  }

  const handleImageState = (prop) => () => {
    const params = createRequestParams(prop, !selectedImage[prop], user)

    updateTagStatus(selectedImage?.id, user.id, role, params)
      .then(({ data, errors }) => {
        if (errors || errors?.length > 0) {
          throw new Error(errors)
        }

        if (data) {
          dispatch({
            type: 'setSelectedImage',
            payload: { ...selectedImage, [prop]: data[prop] }
          })

          dispatch({
            type: 'setImageProperty',
            payload: { image: selectedImage, property: prop, images: state.images }
          })
        }
      })
      .catch((e) => {
        throw new Error(e)
      })
  }

  return (
    <div style={{ display: 'flex', justifySelf: 'flex-end' }}>
      {IconConfig.map((item) => (
        <Tooltip title={item.title} key={item.prop}>
          <IconButton
            size="small"
            type="button"
            color={selectedImage ? 'primary' : 'disabled'}
            disabled={!selectedImage || item.disabled}
            style={{ display: 'flex', height: '100%', alignSelf: 'center' }}
            onClick={handleAction(item.prop)}
          >
            {item.icon(selectedImage)}
          </IconButton>
        </Tooltip>
      ))}
    </div>
  )
}

export default StatusButtons
