export const getTotalImages = (datasets) => {
  let total = 0
  datasets.forEach((d) => {
    if (d.dataset && typeof d.dataset.count === 'number') {
      total += d.dataset.count
    }
  })
  return total
}

export const getImagesDoneReviewedCount = (imgFilter, project) => {
  return imgFilter === 'reviewed' ? project.imagesDoneReviewedCount : project.imagesSavedCount
}

export const getImagesSkippedReviewedCount = (imgFilter, project) => {
  return imgFilter === 'reviewed' ? project.imagesSkippedReviewedCount : project.imagesSkippedCount
}

export const getCompletedPercentage = (imgFilter, project, totalImg) => {
  if (imgFilter === 'saved') {
    return totalImg > 0 ? ((100 * project.imagesSavedCount) / totalImg).toFixed(2) : '0.00'
  } else {
    const reviewedCount = imgFilter === 'reviewed' ? project.imagesReviewedCount : 0
    return project.imagesSavedCount > 0
      ? ((100 * reviewedCount) / project.imagesSavedCount).toFixed(2)
      : '0.00'
  }
}
