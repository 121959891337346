import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { CircularProgress } from '@mui/material'

const LoadResultsFlag = ({ loadFunction }) => {
  const { ref, inView } = useInView()

  useEffect(() => {
    if (inView) {
      loadFunction()
    }
  }, [inView])

  return <CircularProgress ref={ref} sx={{ margin: '1rem' }} />
}

export default LoadResultsFlag
