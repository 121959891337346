import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Grid from '@mui/material/Grid'
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'

import SignupForm from '../../components/forms/SignupForm'
import { federatedSignIn } from '../../io/aws/auth'
import GoogleSignInButton from '../../pages/signin/login/components/GoogleSignInButton'
import '../../styles/Signup.css'

const Img = styled('img')({
  margin: 'auto',
  maxWidth: '220px',
  maxHeight: '100%'
})

function Signup(props) {
  const [signedUp, setSignedUp] = useState(false)
  const actualUrl = window.location.href
  useEffect(() => {
    window.scrollTo(0, 0)
    if (actualUrl.includes('/signup'))
      document.getElementsByClassName('App')[0].style.overflow = 'hidden'
  }, [])

  const showDone = () => {
    setSignedUp(true)
  }

  return (
    <div className="signup-bg">
      <Grid container className="signup-grid-container">
        {!signedUp ? (
          <>
            <Grid item xs={12} className="signup-logo" spacing={5}>
              <h4>SIGNUP TO</h4>
              <Img src={props.logo} />
            </Grid>
            <Grid item xs={12} className="signup-form">
              <h3 className="you-rock">Ready to Rock!</h3>
              <SignupForm {...props} submit="Sign Up" onDone={showDone} message="Signup Success." />
              <GoogleSignInButton sx={{ marginTop: 2 }} onClick={federatedSignIn} />
              <p className="signup-text">
                Already rocking with us?{' '}
                <Link to="/login">
                  <span className="blue-text">Login</span>
                </Link>
              </p>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} className="signup-logo" spacing={5}>
              <Img src={props.logo} />
            </Grid>
            <Grid item xs={12} className="signup-form" spacing={5}>
              <h4>Please Check your email and click the verification link!</h4>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  )
}

const mapStateToProps = (state) => ({
  logo: state.ui.logo
})

export default connect(mapStateToProps, null)(Signup)
