import React, { useContext, useRef } from 'react'
import { useResizeImageContainer } from '../../../../../../hooks/useResizeImageContainer'
import { FiltersContext } from '../../../../../../context/FiltersContext'
import ClassificationChips from './components/ClassificationChips'
import ImageCanvas from './components/imageCanvas/ImageCanvas'

const CardImage = ({ image }) => {
  const cardImageRef = useRef(null)
  const { state } = useContext(FiltersContext)
  const { gridSize } = state
  const { src, loading } = image

  const callDrawImage = (drawImageCanvas) => drawImageCanvas

  const { width, height } = useResizeImageContainer(src, loading, gridSize, callDrawImage)

  const containerStyle = {
    width: width * gridSize,
    height: height * gridSize,
    display: 'flex',
    minWidth: '100%'
  }

  return (
    <div ref={cardImageRef} style={containerStyle} data-testid="card-image-component">
      <ImageCanvas
        image={image}
        cardImageRef={cardImageRef}
        callDrawImage={callDrawImage}
        width={width}
        height={height}
      />
      <ClassificationChips width={width} height={height} gridSize={gridSize} image={image} />
    </div>
  )
}

export default CardImage
