export const flattenObjectValues = (obj) => {
  const filteredArray = Object.entries(obj).map(([key, value]) => [
    key,
    value != null ? value.toString() : null
  ])

  const newObject = Object.fromEntries(filteredArray)

  const finalObject = Object.keys(newObject).map((key) => {
    return { key, label: `CL: ${key}`, value: newObject[key], masterKey: 'classifications' }
  })

  return finalObject
}

export const isValidData = (value) => {
  if (typeof value === 'string') {
    return value.trim() !== ''
  }

  if (Array.isArray(value)) {
    return value.length > 0
  }

  return value !== null && value !== undefined
}

export const getLabelerEmails = (labelers, teamMembers) => {
  return labelers
    .filter((labelerId) => teamMembers.some((member) => member.user.id === labelerId))
    .map((labelerId) => {
      const matchingMember = teamMembers.find((member) => member.user.id === labelerId)
      return matchingMember ? matchingMember.user.email : null
    })
    .filter((email) => email !== null)
    .join(', ')
}

export const getReviewerEmails = (reviewers, teamMembers) => {
  return reviewers
    .filter((reviewerId) => teamMembers.some((member) => member.user.id === reviewerId))
    .map((reviewerId) => {
      const matchingMember = teamMembers.find((member) => member.user.id === reviewerId)
      return matchingMember ? matchingMember.user.email : null
    })
    .filter((email) => email !== null)
    .join(', ')
}

export const getLabelsCount = (count) => {
  if (count.length === 1 && count[0]?.value !== undefined) {
    return count[0]?.value + ' - ' + count[0]?.value
  } else if (count.length === 2 && count[0]?.value !== undefined && count[1]?.value !== undefined) {
    return count[0]?.value + ' - ' + count[1]?.value
  } else {
    return null
  }
}
