import React, { useState, useEffect } from 'react'
// eslint-disable-next-line no-unused-vars
import * as _ from 'lodash'
import {
  List,
  Paper,
  Button,
  Select,
  Checkbox,
  MenuItem,
  ListItem,
  InputBase,
  TextField,
  IconButton,
  InputLabel,
  FormControl,
  FormControlLabel,
  ListItemSecondaryAction,
  ListItemText
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { styled } from '@mui/material/styles'
import { randomColor } from '../utils/util'
import { TYPES_ATTRS, TYPES } from '../utils/constants'
import ColorPicker from './generics/textfields/ColorPicker'
import { useSnackbar } from './snackbar/context/SnackbarContext'

const PaperArea = styled(Paper)(() => ({
  padding: 0,
  marginLeft: 4,
  display: 'flex',
  alignItems: 'center'
}))

const ListOptionsPanel = styled(List)(() => ({
  minHeight: 80,
  maxHeight: 120,
  overflow: 'auto'
}))

const InputOption = styled(InputBase)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  padding: 0,
  flex: 1
}))

export default function CreateCategory(props) {
  const { categories } = props
  const defalutColor = randomColor(10)

  const [option, setOption] = useState('')
  const [category, setCategory] = useState({
    name: '',
    color: defalutColor,
    type: '',
    parent: 'none',
    classes: [],
    classType: 'single',
    required: false
  })

  const { showSnackbar } = useSnackbar()

  useEffect(() => {
    if (props.editIndex >= 0) {
      const cat = props.categories[props.editIndex]

      setCategory({
        name: cat.name,
        type: cat.type,
        color: cat.color,
        parent: cat.parent || 'none',
        classes: cat.options || [],
        classType: cat.classType || 'single',
        required: cat.required || false
      })
    }
  }, [props.categories, props.editIndex])

  const handleChange = (e) => {
    setCategory({ ...category, [e.target.name]: e.target.value })
  }

  const handleAddClass = (e) => {
    e.preventDefault()

    if (!option) {
      showSnackbar({
        message: 'Missing option.',
        type: 'error'
      })
      return
    }

    setCategory({ ...category, classes: [...category.classes, option] })
    setOption('')
  }

  const handleRemoveClass = (index) => {
    setCategory({
      ...category,
      classes: category.classes.filter((_, i) => {
        return i !== index
      })
    })
  }

  const handleAddClick = () => {
    if (!category.name) {
      showSnackbar({
        message: 'Missing category name.',
        type: 'error'
      })

      return
    }

    if (!category.color) {
      showSnackbar({
        message: 'Missing category color.',
        type: 'error'
      })

      return
    }

    if (!category.type) {
      showSnackbar({
        message: 'Missing category type.',
        type: 'error'
      })
      return
    }

    if (
      category.type === TYPES.CLASSIFICATION &&
      category.classType !== 'string' &&
      category.classType !== 'boolean' &&
      category.classType !== 'int' &&
      category.classType !== 'float' &&
      category.classes.length === 0
    ) {
      showSnackbar({
        message: 'Missing Classification options.',
        type: 'error'
      })
      return
    }

    if (props.editIndex === -1 && categories) {
      for (let index = 0; index < categories.length; index++) {
        const element = categories[index]

        if (element.name === category.name) {
          showSnackbar({
            message: 'Already exist a category with this name.',
            type: 'warning'
          })

          return
        }
        if (category.type !== TYPES.CLASSIFICATION && element.color === category.color) {
          showSnackbar({
            message: 'Already exist a category with this color.',
            type: 'warning'
          })
          return
        }
      }
    }

    if (props.addCategory) props.addCategory(category)

    setCategory({
      ...category,
      name: '',
      color: defalutColor,
      parent: 'none',
      classes: [],
      classType: 'single',
      required: false
    })
  }

  const cancelEdit = () => {
    props.cancelEdit()
    setCategory({
      ...category,
      name: '',
      color: defalutColor,
      parent: 'none',
      classes: [],
      classType: 'single',
      required: false
    })
  }

  const renderCategory = () => (
    <ColorPicker
      color={category.color}
      onChange={(color) => handleChange({ target: { value: color, name: 'color' } })}
    />
  )

  const renderClassification = () => (
    <>
      <FormControl variant="standard" fullWidth>
        <InputLabel id="class-type">Type of classification</InputLabel>
        <Select
          variant="standard"
          labelId="class-type-select-label"
          id="class-type-select"
          value={category.classType}
          label="class-type"
          name="classType"
          onChange={handleChange}
        >
          <MenuItem value={'single'}>
            <em sx={{ display: 'flex' }}>Single Select</em>
          </MenuItem>
          <MenuItem value={'multiple'}>
            <em sx={{ display: 'flex' }}>Multiple Select</em>
          </MenuItem>
          <MenuItem value={'string'}>
            <em sx={{ display: 'flex' }}>Text</em>
          </MenuItem>
          <MenuItem value={'boolean'}>
            <em sx={{ display: 'flex' }}>Boolean</em>
          </MenuItem>
          <MenuItem value={'int'}>
            <em sx={{ display: 'flex' }}>Integer</em>
          </MenuItem>
          <MenuItem value={'float'}>
            <em sx={{ display: 'flex' }}>Float</em>
          </MenuItem>
        </Select>
      </FormControl>
      <FormControlLabel
        control={
          <Checkbox
            sx={{ padding: 1 }}
            checked={category.required}
            onChange={(e) =>
              handleChange({ target: { value: e.target.checked, name: e.target.name } })
            }
            inputProps={{ name: 'required', 'aria-label': 'Required' }}
          />
        }
        label="Required"
      />
      {category.classType !== 'string' &&
        category.classType !== 'boolean' &&
        category.classType !== 'int' &&
        category.classType !== 'float' && (
          <>
            <PaperArea component="form" onSubmit={handleAddClass}>
              <InputOption
                placeholder="Add option"
                value={option}
                onChange={(e) => {
                  setOption(e.target.value)
                }}
                inputProps={{ 'aria-label': 'add class' }}
                margin="none"
              />
              <IconButton type="submit" sx={{ padding: 1 }} aria-label="add" size="large">
                <AddIcon />
              </IconButton>
            </PaperArea>
            <Paper sx={{ margin: '4px 2px' }}>
              <ListOptionsPanel>
                {category.classes.map((c, i) => (
                  <ListItem key={i}>
                    <ListItemText primary={c} />
                    <ListItemSecondaryAction onClick={() => handleRemoveClass(i)}>
                      <IconButton edge="end" aria-label="delete" size="large">
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </ListOptionsPanel>
            </Paper>
          </>
        )}
    </>
  )

  return (
    <>
      <FormControl variant="standard">
        <InputLabel>Type</InputLabel>
        <Select
          variant="standard"
          sx={{ minWidth: 150 }}
          label="Type"
          value={category.type}
          onChange={handleChange}
          inputProps={{
            name: 'type',
            id: 'type-select'
          }}
        >
          {TYPES_ATTRS.map((type) => (
            <MenuItem key={type.id} value={type.id}>
              <em>{type.name}</em>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {category.type && (
        <TextField
          variant="standard"
          sx={{ marginTop: '20px' }}
          name="name"
          id="outlined-name"
          label="Name"
          value={category.name}
          onChange={handleChange}
          margin="normal"
        />
      )}
      {category.type ? (
        category.type === TYPES.CLASSIFICATION ? (
          renderClassification()
        ) : (
          renderCategory()
        )
      ) : (
        <></>
      )}
      {categories && category.type && (
        <FormControl variant="standard" sx={{ marginTop: 2 }}>
          <InputLabel htmlFor="parent-simple">Parent</InputLabel>
          <Select
            variant="standard"
            sx={{ minWidth: 150, marginTop: '20px' }}
            label="Parent"
            value={category.parent}
            onChange={handleChange}
            inputProps={{
              name: 'parent',
              id: 'parent-select'
            }}
          >
            <MenuItem key={'none'} value={'none'}>
              <em>None</em>
            </MenuItem>
            {categories.map((cat) =>
              cat.parent || cat.type === 'classification' ? null : (
                <MenuItem key={cat.name} value={cat.name}>
                  <em>
                    {cat.name} - {cat.type}
                  </em>
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      )}
      <div className="btn-add">
        <Button
          sx={{ margin: 1 }}
          variant="contained"
          color="secondary"
          onClick={handleAddClick}
          disabled={!category.type || props.loading}
        >
          {props.editIndex >= 0 ? 'Update' : 'Add'}
        </Button>
        {props.editIndex >= 0 && (
          <Button
            sx={{ margin: 1 }}
            variant="contained"
            onClick={cancelEdit}
            disabled={props.loading}
          >
            Cancel
          </Button>
        )}
      </div>
    </>
  )
}
