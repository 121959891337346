import { Storage, Auth, API } from 'aws-amplify'

import { formatS3Key } from '../utils/util'

export async function s3Upload(file, folder) {
  const filename = `${folder}/${file.name}`
  const stored = await Storage.vault
    .put(filename, file, {
      level: 'protected',
      contentType: file.type
    })
    .catch((error) => {
      console.log(error)
    })

  // const url = await Storage.vault.get(filename)
  // console.log(url) // "BUCKET DIR

  return stored.key
}

export async function s3UploadConfig(file, folder) {
  const filename = `${folder}/config.json`

  const stored = await Storage.vault
    .put(filename, file, {
      level: 'protected',
      contentType: 'application/json'
    })
    .catch((error) => {
      console.log(error)
    })

  return stored.key
}

export async function s3UploadJson(file, name, folder, key) {
  try {
    const filename = `${folder}/${name}.json`
    await Storage.vault.put(filename, file, {
      level: 'public',
      contentType: 'application/json'
    })
    return true
  } catch (error) {
    if (error.name === 'ExpiredToken') {
      try {
        await s3Get(key)
        const filename = `${folder}/${name}.json`
        await Storage.vault.put(filename, file, {
          level: 'public',
          contentType: 'application/json'
        })
        return true
      } catch (error) {
        console.log(error)
        return false
      }
    }
    return false
  }
}

export async function s3List(folder) {
  const filename = `datasets/${folder}/`
  const data = await Storage.vault.list(filename)

  return data
}

export async function s3Get(path) {
  path = formatS3Key(path)
  const other = path.split('/')
  const img = await Storage.vault.get(`${other[other.length - 2]}/${other[other.length - 1]}`, {
    identityId: `${other[other.length - 3]}`,
    level: 'protected'
  })

  return img
}

export async function s3GetThumbnail(path) {
  const image = await Storage.get(path, { level: 'public' })

  return image
}

export async function demoList() {
  const filename = `RoadSigns/`
  const data = await Storage.list(filename)

  return data
}

export const verifyEmail = async (email, code) => {
  const user = await Auth.confirmSignUp(email, code)

  return { ok: true, user }
}

export const login = async (email, password) => {
  const user = await Auth.signIn(email, password)
  // console.log("USER AUTH", user)
  return { ok: true, user }
}

export const isLogged = async () => {
  await Auth.currentSession()

  return { ok: true }
}

export const signup = async (username, password) => {
  const user = await Auth.signUp({ username, password })

  return { ok: true, user }
}

export const logout = async () => {
  await Auth.signOut()

  return { ok: true }
}

export const confirmSignup = async (email, password, confirmationCode) => {
  await Auth.confirmSignUp(email, confirmationCode)
  await Auth.signIn(email, password)

  return { ok: true }
}

export const deleteDataset = async (dataset) =>
  API.post('linkedai-api', '/datasets/delete', {
    body: dataset
  })

export const updateImage = async (imageId, datasetId, width, height) =>
  API.put('linkedai-api', `/images`, { body: { imageId, datasetId, width, height } })

export const assignImage = async (userId, datasetId, projectId, prefix) =>
  API.post('linkedai-api', `/images/assign`, { body: { userId, projectId, datasetId, prefix } })

export const reviewImage = async (
  userId,
  datasetId,
  projectId,
  imageId,
  count,
  reviewer,
  accepted
) =>
  API.post('linkedai-api', `/images/review`, {
    body: { userId, projectId, datasetId, imageId, count, reviewer, accepted }
  })

export const getImages = async (userId, datasetId, projectId, prefix) =>
  API.post('linkedai-api', `/images/list/${datasetId}/user`, {
    body: { userId, projectId, prefix }
  })

export const listProjectImages = async (projectId) =>
  API.get('linkedai-api', `/images/list/${projectId}/project/all`)

export const listReviewImages = async (projectId) =>
  API.get('linkedai-api', `/images/list/${projectId}/project`)

export const listSaveImages = async (projectId) =>
  API.get('linkedai-api', `/images/list/${projectId}/project/reviews`)

export const findUsersByEmail = async (search) =>
  API.post('linkedai-api', '/users/find', {
    body: search
  })

export const handleRequest = (res) => {
  if (!res.ok) throw res
  return res.json()
}

// Dispatchers
// export const listDatasets = (userId) => async dispatch => {
//   const res = await API.get('linkedai-api', '/datasets/' + userId + '/list')
//     .then(response => {
//       if (response.length || response.length === 0) {
//         const datasets = response
//         datasets.sort((elA, elB) => {
//           if (elA.name > elB.name) return 1
//           if (elB.name > elA.name) return -1
//           return 0
//         })

//         dispatch(user.setUserDatasets(datasets))
//         return { error: null, data: response }
//       }
//       return { error: response }
//     })
//     .catch(er => {
//       console.log('Error datasets', er)
//       return { error: er }
//     })
//   return res
// }

// export const listProjects = (userId) => async dispatch => {
//   const res = await API.get('linkedai-api', '/projects/' + userId + '/list')
//     .then(response => {
//       if (response.length || response.length === 0) {
//         const projects = response
//         projects.sort((elA, elB) => {
//           if (elA.name > elB.name) return 1
//           if (elB.name > elA.name) return -1
//           return 0
//         })

//         dispatch(user.setUserProjects(projects))
//         return { error: null, data: response }
//       }
//       return { error: response }
//     })
//     .catch(er => {
//       console.log('Error datasets', er)
//       return { error: er }
//     })
//   return res
// }

// export const listShareProjects = (teams) => async dispatch => {
//   const res = await API.post('linkedai-api', '/projects/list/team', { body: { teams } })
//     .then(response => {
//       if (response.length || response.length === 0) {
//         const projects = response
//         projects.sort((elA, elB) => {
//           if (elA.name > elB.name) return 1
//           if (elB.name > elA.name) return -1
//           return 0
//         })

//         dispatch(user.setUserShareProjects(projects))
//         return { error: null, data: response }
//       }
//       return { error: response }
//     })
//     .catch(er => {
//       console.log('Error datasets', er)
//       return { error: er }
//     })
//   return res
// }

// export const listTeams = (user) => async dispatch => {
//   const res = await API.post('linkedai-api', '/teams/list', { body: { id: user } })
//     .then(response => {
//       dispatch(user.setUserTeams(response))
//       return response
//     })
//     .catch(er => {
//       console.log('Error datasets', er)
//       return { error: er }
//     })

//   return res
// }

export const createProject = (project) => async (dispatch) => {
  const res = await API.post('linkedai-api', '/projects', {
    body: project
  })
    .then((response) => {
      if (!response.status) return { error: response.error }
      if (response.data.sts && response.data.sts === 'active') {
        dispatch(project.setProject(response.data))
        return { error: null, data: response.data }
      }
      return { error: response.data }
    })
    .catch((er) => {
      console.log('Error datasets', er)
      return { error: er }
    })
  return res
}

export const updateProject = (project) => async (dispatch) => {
  const res = await API.put('linkedai-api', '/projects/' + project.projectId + '/update', {
    body: project
  })
    .then((response) => {
      if (response.status) {
        dispatch(project.setProject(project))
        return { error: null, data: response.data }
      }
      return { error: response }
    })
    .catch((er) => {
      console.log('Error datasets', er)
      return { error: er }
    })
  return res
}

// Tags
export const saveTags = async (tag) => API.post('linkedai-api', '/tags', { body: tag })

// export const updateCountTags = async (tag) =>
//   API.post('linkedai-api', '/tags', { body: tag })

export const getTags = async (image) => API.post('linkedai-api', '/tags/get', { body: image })

export const getProjectLabels = (projectId) => API.get('linkedai-api', `/tags/list/${projectId}`)

export const getProjectTags = (projectId) =>
  API.get('linkedai-api', `/tags/list/${projectId}/project`)

// Teams
export const createTeam = async (team) =>
  API.post('linkedai-api', '/teams', {
    body: team
  })

export const updateTeam = async (team) =>
  API.put('linkedai-api', '/teams', {
    body: team
  })
export const inviteToTeamByEmail = async (invitation) =>
  API.post('linkedai-api', '/teams/email', {
    body: invitation
  })

// Times

export const addTime = async (time) =>
  API.post('linkedai-api', '/times', {
    body: time
  })

export const listTimes = async (userId) => API.get('linkedai-api', `/times/list/${userId}`)

export const saveTSTags = async (datasetId, imageId) =>
  API.put('linkedai-api', `/images/save`, { body: { imageId, datasetId } })
