import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import {
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormGroup,
  Icon,
  IconButton,
  ListItem,
  Typography,
  Accordion
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { FiltersContext } from '../../../../../../context/FiltersContext'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import styled from '@emotion/styled'
import ClassificationItem from './components/ClassificationItem'

const ContainerFormControl = styled('div')(() => ({
  width: '100%'
}))

const ClassificationsGeneralContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  margin: 0,
  padding: 0
}))

const ClassificationsFilter = () => {
  const { state, dispatch } = useContext(FiltersContext)

  const store = useSelector((state) => state)

  const handleChangeClassifications = (value, name) => {
    const classificationSelected = value

    const previousSelectedClasses = state.classifications || {}

    const updatedSelectedClasses =
      Array.isArray(classificationSelected) && classificationSelected.length === 0
        ? {}
        : {
            ...previousSelectedClasses,
            [name]: classificationSelected
          }

    dispatch({ type: 'setClassifications', payload: updatedSelectedClasses })
  }

  const handleHideCategory = (category) => {
    dispatch({ type: 'setHiddenCategories', payload: category })
  }

  const handleHideClassifications = (e) => {
    e.stopPropagation()
    dispatch({ type: 'setHideClassifications', payload: null })
  }

  return (
    <ListItem>
      <Accordion sx={{ width: '100%' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="categories-content"
          id="classifications"
        >
          <ClassificationsGeneralContainer>
            <Typography>Classifications</Typography>
            <IconButton sx={{ marginTop: -0.5 }} onClick={handleHideClassifications}>
              <Icon>{state.hideClassifications ? <VisibilityOff /> : <Visibility />}</Icon>
            </IconButton>
          </ClassificationsGeneralContainer>
        </AccordionSummary>
        <AccordionDetails>
          <ContainerFormControl>
            <FormControl component="fieldset" sx={{ width: '100%' }}>
              <FormGroup>
                {store.project.categories
                  .filter(
                    (category) => category.type === 'classification' && category.parent === null
                  )
                  .map((classification) => (
                    <ClassificationItem
                      classification={classification}
                      onChange={handleChangeClassifications}
                      hideCategory={handleHideCategory}
                      hiddenCategories={state.hiddenCategories}
                      key={classification.name}
                    />
                  ))}
              </FormGroup>
            </FormControl>
          </ContainerFormControl>
        </AccordionDetails>
      </Accordion>
    </ListItem>
  )
}

export default ClassificationsFilter
