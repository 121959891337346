/* eslint-disable no-unused-vars */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-empty-pattern */
import { handleActions } from 'redux-actions'

export const defaultState = {
  id: null,
  name: null,
  createdAt: null,
  categories: [],
  status: null,
  team: null,
  user: {
    id: null
  },
  datasets: [],
  tagsCount: 0,
  tagsSaveCount: 0,
  tagsReviewCount: 0,
  imagesSavedCount: 0,
  imagesReviewedCount: 0,
  imagesSkippedCount: 0,
  imagesDoneReviewedCount: 0,
  imagesSkippedReviewedCount: 0,
  categoriesCount: [],
  datasetSort: null,
  reviewType: null,
  // EXTRAS
  loading: false,
  images: null,
  image: {
    data: null,
    index: 0
  },
  role: 'none',
  // stats
  imagesStats: {
    barSaved: { week: [], month: [], all: [] },
    barReviewed: { week: [], month: [], all: [] },
    table: { saved: [], reviewed: [], skipped: [] },
    loading: false
  },
  labelesStats: { table: { all: [], reviewed: [], labeler: [] }, loading: false },
  labelerStats: {
    loading: true,
    barImages: { week: [], month: [], all: [] },
    barLabels: { week: [], month: [], all: [] },
    barSkipped: { week: [], month: [], all: [] }
  },
  reviwerStats: {
    loading: true,
    barImages: { week: [], month: [], all: [] },
    barLabels: { week: [], month: [], all: [] }
  }
}

const reducer = handleActions(
  {
    PROJECT: {
      GET_PROJECT: (state, { payload: {} }) => ({
        ...state,
        role: 'none',
        loading: true,
        imagesStats: defaultState.imagesStats,
        labelesStats: defaultState.labelesStats
      }),
      SET_PROJECT: (state, { payload: { project } }) => {
        return {
          ...state,
          id: project.id,
          name: project.name,
          createdAt: project.createdAt,
          categories: project.categories,
          status: project.status,
          datasetSort: project.datasetSort,
          reviewType: project.reviewType,
          team: project.team || { name: 'Riding solo 🦸' },
          datasets:
            project.datasets && project.datasets.hasOwnProperty('items')
              ? project.datasets.items
              : defaultState.datasets,
          tagsCount: project.tagsCount || defaultState.tagsCount,
          tagsSaveCount: project.tagsSaveCount || defaultState.tagsSaveCount,
          tagsReviewCount: project.tagsReviewCount || defaultState.tagsReviewCount,
          imagesSavedCount: project.imagesSavedCount || defaultState.imagesSavedCount,
          imagesReviewedCount: project.imagesReviewedCount || defaultState.imagesReviewedCount,
          imagesSkippedCount: project.imagesSkippedCount || defaultState.imagesSkippedCount,
          imagesSkippedReviewedCount:
            project.imagesSkippedReviewedCount || defaultState.imagesSkippedReviewedCount,
          imagesDoneReviewedCount:
            project.imagesDoneReviewedCount || defaultState.imagesDoneReviewedCount,
          categoriesCount: project.categoriesCount || defaultState.categoriesCount,
          user: project.user || defaultState.user,
          loading: defaultState.loading,
          images: defaultState.images,
          image: defaultState.image,
          role: !project.team ? 'admin' : defaultState.role
        }
      },
      GET_CATEGORIES: (state, { payload: {} }) => ({ ...state, loading: true }),
      SET_CATEGORIES: (state, { payload: { categories } }) => {
        return {
          ...state,
          categories: categories.categories,
          categoriesCount: categories.categoriesCount
        }
      },
      SET_PROJECT_TEAM: (state, { payload: { team } }) => ({ ...state, team }),
      UPDATE_PROJECT: (state, { payload: {} }) => ({ ...state, loading: true }),
      UPDATE_NAME_PROJECT: (state, { payload: {} }) => ({ ...state, loading: true }),
      SET_ROLE: {
        next(state, { payload: { role } }) {
          return { ...state, role, loading: false }
        },
        throw(state, action) {
          return { ...state, loading: false }
        }
      },
      SET_IMAGE: (state, { payload: { data, index } }) => {
        return { ...state, image: { data, index } }
      },
      SET_IMAGES: {
        next(state, { payload: { images, image, index } }) {
          return { ...state, image: { image, index }, images, loading: false }
        },
        throw(state, action) {
          return { ...state, loading: false }
        }
      },
      GET_LABELS_STATS: (state, { payload: { projectId } }) => {
        return { ...state, labelesStats: { ...state.labelesStats, loading: true } }
      },
      GET_IMAGES_STATS: (state, { payload: { projectId } }) => {
        return { ...state, imagesStats: { ...state.imagesStats, loading: true } }
      },
      GET_LABELER_STATS: (state, { payload: {} }) => {
        return {
          ...state,
          labelerStats: {
            barImages: { week: [], month: [], all: [] },
            barLabels: { week: [], month: [], all: [] },
            barSkipped: { week: [], month: [], all: [] },
            loading: true
          }
        }
      },
      SET_LABELER_STATS: (state, { payload: { data } }) => {
        return {
          ...state,
          labelerStats: {
            loading: false,
            barImages: { week: data.images.week, month: data.images.month, all: data.images.all },
            barLabels: { week: data.labels.week, month: data.labels.month, all: data.labels.all },
            barSkipped: {
              week: data.skipped.week,
              month: data.skipped.month,
              all: data.skipped.all
            }
          }
        }
      },
      GET_REVIWER_STATS: (state, { payload: {} }) => {
        return {
          ...state,
          reviwerStats: {
            barImages: { week: [], month: [], all: [] },
            barLabels: { week: [], month: [], all: [] },
            barSkipped: { week: [], month: [], all: [] },
            loading: true
          }
        }
      },
      SET_REVIWER_STATS: (state, { payload: { data } }) => {
        return {
          ...state,
          reviwerStats: {
            loading: false,
            barImages: { week: data.images.week, month: data.images.month, all: data.images.all },
            barLabels: { week: data.labels.week, month: data.labels.month, all: data.labels.all }
          }
        }
      },
      SET_LABELS_STATS: (state, { payload: { data } }) => {
        return {
          ...state,
          labelesStats: {
            table: { labeler: data.labeler, reviewed: data.reviewed, all: data.all },
            loading: false
          }
        }
      },
      SET_IMAGES_STATS: (state, { payload: { data } }) => {
        return {
          ...state,
          imagesStats: {
            barSaved: data.barSaved,
            barReviewed: data.barReviewed,
            barSkipped: data.barSkipped,
            table: {
              saved: data.saved,
              reviewed: data.reviewed,
              skipped: data.skipped
            },
            loading: false
          }
        }
      },
      CLEAN_PROJECT: (state, { payload: { data } }) => {
        return defaultState
      }
    }
  },
  defaultState
)

export default reducer
