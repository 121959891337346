import React, { useState, useEffect } from 'react'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider, useDrop } from 'react-dnd'

import DraggableCategoryItem from './DraggableCategoryItem'
import { useDeepEffect } from '../../utils/hooks/useDeepEffect'
import { sandCategoryMoved } from '../../io/analytics'

const containerStyle = {
  width: '100%',
  maxWidth: '100%',
  display: 'flex',
  flexWrap: 'wrap'
}

export default function DraggableCategoryList(props) {
  const [categories, setCategories] = useState([])

  useDeepEffect(() => {
    setCategories([...props.project.categories])
  }, [props.project.categories])

  useEffect(() => {
    if (categories.length > 0) {
      props.updateCategories(props.project.id, categories)
    }
  }, [categories])

  const handleDelete = (index) => {
    const categoriesCopy = JSON.parse(JSON.stringify(categories))
    categoriesCopy.splice(index, 1)
    setCategories([...categoriesCopy])
    props.deleteCategory()
  }

  const moveCardHandler = (dragIndex, dropIndex) => {
    if (dragIndex !== dropIndex) {
      const dragItem = categories[dragIndex]
      if (dragItem) {
        const categoriesCopy = [...categories]

        categoriesCopy.splice(dragIndex, 1)
        categoriesCopy.splice(dropIndex, 0, dragItem)

        setCategories(categoriesCopy)
        sandCategoryMoved({
          name: dragItem.name,
          type: dragItem.type,
          projectId: props.project.id,
          projectName: props.project.name
        })
      }
    }
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Zone
        moveCardHandler={moveCardHandler}
        handleDelete={handleDelete}
        categories={categories}
        {...props}
      />
    </DndProvider>
  )
}

const Zone = ({ editIndex, handleDelete, project, setEditIndex, categories, moveCardHandler }) => {
  // eslint-disable-next-line no-unused-vars
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'category',
    drop: () => ({ name: 'category' }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  })

  return (
    <div ref={drop} style={containerStyle}>
      {project?.categories?.length > 0 ? (
        categories.map((category, index) => (
          <DraggableCategoryItem
            moveCardHandler={moveCardHandler}
            category={category}
            editIndex={editIndex}
            handleDelete={handleDelete}
            index={index}
            key={category.name}
            project={project}
            setEditIndex={setEditIndex}
          />
        ))
      ) : (
        <p>Add your first category!</p>
      )}
    </div>
  )
}
