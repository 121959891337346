/* eslint-disable no-unused-vars */
import { useEffect, useContext } from 'react'
import { DispatchContext } from '../../../containers/labeler/context/DispatchContext'
import { LabelerContext } from '../../../containers/labeler/context/LabelerContext'
import { getImage, getReviewImage } from '../../../services/graphql'
import useRole from '../../labeler/useRole'
import { useLabelerMachine } from '../../../containers/labeler/context/MachineContext'
import { useSnackbar } from '../../../components/snackbar/context/SnackbarContext'
import { getImage as getSingleImg } from '../../../io/project/services'

export default function useGetNextImage(props) {
  const role = useRole(props._role)
  const { state: MachineState, send } = useLabelerMachine()
  const {
    state: {
      labelerState: {
        image: { index, images, navigation },
        role: roleContext
      }
    }
  } = useContext(LabelerContext)
  const { dispatch } = useContext(DispatchContext)

  const { showSnackbar } = useSnackbar()

  useEffect(() => {
    if (navigation === 'next') {
      send({ type: 'RESET', source: 'useGetNextImage' })

      dispatch({ type: 'setRenderedImage', payload: false })
      nextImage()
    }
  }, [navigation])

  const formatImages = (images) => {
    return images.map((item) => ({
      id: item.id
    }))
  }

  const formatImage = (image) => ({
    id: image.id,
    image: image.image,
    userId: image.labeler ? image.labeler.id : null,
    reviewer: image.tagReviewerId,
    name: image.key.split('/')[image.key.split('/').length - 1],
    date: image.createdAt,
    key: image.key,
    width: image.width,
    height: image.height,
    done: image.done,
    accepted: image.accepted,
    downloaded: false,
    skiped: image.skiped
  })
  const getSingleImage = async (imageId) => {
    return await getSingleImg(imageId)
  }
  const labelerGetImage = async (projectId, labelerId) => {
    let errorMessage = 'There are no more images.'
    try {
      const labResponse = await getImage(projectId, labelerId)

      if (Object.keys(labResponse.data).length === 0) {
        return { data: null, error: errorMessage }
      }
      const currentImage = await getSingleImage(labResponse.data.id)
      dispatch({
        type: 'addNewImage',
        payload: {
          images: formatImages([labResponse.data]),
          currentImage: formatImage(currentImage.data.getTag)
        }
      })
      return { ...labResponse, error: labResponse.data === null ? errorMessage : '' }
    } catch (error) {
      errorMessage = 'Error getting new images.'
      return { data: null, error: errorMessage }
    }
  }

  const reviewerGetImage = async (projectId, labelerId) => {
    let errorMessage = 'There are no more images.'
    try {
      const revResponse = await getReviewImage(projectId, labelerId)
      if (Object.keys(revResponse.data).length === 0) {
        return { data: null, error: errorMessage }
      }
      const currentImage = await getSingleImage(revResponse.data.id)
      dispatch({
        type: 'addNewImage',
        payload: {
          images: formatImages([revResponse.data]),
          currentImage: formatImage(currentImage.data.getTag)
        }
      })
      return { ...revResponse, error: revResponse.data === null ? errorMessage : '' }
    } catch (error) {
      errorMessage = 'Error getting new images.'
      return { data: null, error: errorMessage }
    }
  }

  const getNewImage = async (projectId, labelerId) => {
    switch (role.labeler) {
      case 'labeler':
        // eslint-disable-next-line no-case-declarations
        const labResponse = await labelerGetImage(projectId, labelerId)
        return labResponse
      case 'reviewer':
        // eslint-disable-next-line no-case-declarations
        const revResponse = reviewerGetImage(projectId, labelerId)
        return revResponse
      default:
        return { data: null, error: `There are no images for ${role.labeler}.` }
    }
  }

  const nextImage = async () => {
    dispatch({ type: 'setLoadingImage', payload: true })
    if (index <= 0) {
      const nextResponse = await getNewImage(
        props.projectId,
        props.labelerId,
        props.sortId,
        props.reviewType
      )
      if (nextResponse.data === null) {
        dispatch({ type: 'setNavigation', payload: 'return' })
        dispatch({ type: 'setRenderedImage', payload: true })
        showSnackbar({
          message: nextResponse.error,
          type: 'info'
        })
        return
      }
    } else {
      const currentId = index > 0 ? images[index - 1].id : images[index].id
      const currentImage = formatImage((await getSingleImage(currentId)).data.getTag)
      dispatch({ type: 'setImages', payload: { images, currentImage } })
    }
    dispatch({ type: 'setLoadingImage', payload: false })
    dispatch({ type: 'setImage', payload: index - 1 })
    dispatch({ type: 'setNavigation', payload: 'stateless' })
  }
  return null
}
