import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import '../styles/createproject.css'
import {
  Grid,
  Step,
  Chip,
  Paper,
  AppBar,
  Dialog,
  Avatar,
  Button,
  Select,
  Stepper,
  Toolbar,
  Collapse,
  MenuItem,
  TextField,
  StepLabel,
  IconButton,
  Typography,
  InputLabel,
  FormControl,
  StepContent,
  Breadcrumbs,
  DialogContent,
  FormHelperText,
  CircularProgress
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ExpandLess from '@mui/icons-material/ExpandLess'

import Upload from '../components/Upload'
import CreateTeam from '../containers/CreateTeam'
import CreateCategory from '../components/CreateCategory'

import { createProject, createProjectData } from '../services/graphql'
import { getUser } from '../utils/util'

import { project as p } from '../io/project/actions'
import { user as u } from '../io/user/actions'

import { connect } from 'react-redux'
import { createShape, createClassification, getIcon } from '../utils/objects'
import { TYPES } from '../utils/constants'
import {
  sandProjectNameSet,
  sandCategoryAddedToProject,
  sandCategoryRemovedToProject,
  sandProjectCreationCanceled,
  sandProjectCreated,
  sandTeamCreationStarted,
  sandDatasetCreationStart
} from '../io/analytics'
import { styled } from '@mui/material/styles'
import useFreeTrial from '../pages/project/hooks/useFreeTrial'
import { useSnackbar } from '../components/snackbar/context/SnackbarContext'
import DialogUploadCategories from '../components/Project/projectSettings/DialogUploadCategories'
const LoadMore = styled('em')(({ theme }) => ({
  color: theme.palette.primary.main
}))

const STEPS = {
  NAME: 'Name the project',
  DATASET: 'Select Dataset',
  TEAM: 'Select Team',
  CATEGORIES: 'Add Categories'
}

function CreateProject(props) {
  const [dataset, setDataset] = useState({ value: '', state: null })
  const [team, setTeam] = useState({ value: 'Riding solo 🦸', state: null })
  const [name, setName] = useState({ value: '', state: null })
  const [categories, setCategories] = useState([])
  const [datasetSort, setDatasetSort] = useState('id')
  const [projectLoaded, setProjectLoaded] = useState(true)
  const [showDataset, setShowDataset] = useState(false)
  const [showAdvanceDataset, setShowAdvanceDataset] = useState(false)
  const [showTeam, setShowTeam] = useState(false)
  const [steps, setSteps] = useState([])
  const [activeStep, setActiveStep] = useState(0)
  const [loading, setLoading] = useState(false)
  const [editIndex, setEditIndex] = useState(-1)

  const { showSnackbar } = useSnackbar()

  const queryParams = new URLSearchParams(window.location.search)
  const id = queryParams.get('id')

  const { permissionsFreeTrial } = useFreeTrial()
  const navigate = useNavigate()

  useEffect(() => {
    getProject()
    setProjectLoaded(false)
    props.listDatasets()
    props.listTeams()

    setSteps(getSteps())
  }, [])

  useEffect(() => {
    if (team === undefined) {
      setTeam({ value: props.team })
    }
  }, [props.team])

  useEffect(() => {
    if (dataset === undefined) {
      setDataset({ value: props.dataset })
    }
  }, [props.dataset])

  useEffect(() => {
    if (!projectLoaded && props.project && props.project.id === id) {
      setName({ ...name, value: `Copy_` + props.project.name })
      setDataset({ ...dataset, value: props.project.datasets[0].dataset })
      setTeam({ ...team, value: props.project.team })
      setCategories(props.project.categories)

      setProjectLoaded(true)
    }
  }, [props.project])

  const getProject = () => {
    if (!id) return
    props.getProject(id)
  }

  const getSteps = () => {
    if (getUser().paid) {
      return [STEPS.NAME, STEPS.DATASET, STEPS.TEAM, STEPS.CATEGORIES]
    } else {
      return [STEPS.NAME, STEPS.DATASET, STEPS.CATEGORIES]
    }
  }

  const handleDelete = (name) => {
    setEditIndex(-1)
    setCategories(
      categories.filter((category) => {
        const isCat = category.name !== name

        if (!isCat) {
          sandCategoryRemovedToProject({ name: category.name, type: category.type })
        }

        return isCat
      })
    )
  }

  const handleChange = (e) => {
    if (e.target.value === 'more') {
      if (e.target.name === 'dataset') {
        getMoreDatasets()
      } else if (e.target.name === 'team') {
        getMoreTeams()
      }
      return false
    }

    switch (e.target.name) {
      case 'name':
        setName({ value: e.target.value, state: null })
        break
      case 'dataset':
        setDataset({ value: e.target.value, state: null })
        break
      case 'team':
        setTeam({ value: e.target.value, state: null })
        break
      case 'order-dataset':
        setDatasetSort(e.target.value)
        break
      default:
        console.log('Option not found')
    }
  }

  const handleAddClick = (category) => {
    const createdCat =
      category.type === TYPES.CLASSIFICATION
        ? createClassification(
            category.name,
            category.classes,
            category.parent === 'none' ? null : category.parent,
            category.classType,
            category.required
          )
        : createShape(
            category.name,
            category.color,
            category.type,
            category.parent === 'none' ? null : category.parent
          )

    if (editIndex === -1) {
      setCategories([...categories, createdCat])
    } else {
      setCategories(categories.map((cat, i) => (i === editIndex ? createdCat : cat)))
      setEditIndex(-1)
    }
    sandCategoryAddedToProject({ name: createdCat.name, type: createdCat.type })
  }

  const onSubmit = () => {
    const user = getUser()

    if (!name.value) {
      showSnackbar({
        message: `Please provide a name to the dataset.`,
        type: 'error'
      })
      return
    }

    if (!categories || categories.length <= 0) {
      showSnackbar({
        message: `Please add at least  one category.`,
        type: 'error'
      })
      return
    }

    if (!team.value) {
      showSnackbar({
        message: `Please select a team.`,
        type: 'error'
      })
      return
    }

    if (!dataset.value) {
      showSnackbar({
        message: `Please select a dataset.`,
        type: 'error'
      })
      return
    }

    setLoading(true)

    const project = {
      name: name.value,
      datasetSort,
      categories,
      projectTeamId: team.value.id ? team.value.id : 'none',
      projectUserId: user.id,
      access: team.value.access ? team.value.access : [],
      status: 'created'
    }

    props
      .createProject(project)
      .then((r) => {
        if (!r.error) {
          createProjectData({
            projectDataProjectId: r.data.id,
            projectDataDatasetId: dataset.value.id
          })
            .then((r) => {
              const data = r.data.createProjectData
              const dataset = data.dataset
              delete dataset.projects

              const project = data.project
              project.datasets = [dataset]

              showSnackbar({
                message: `Project ` + project.name + ` created.`,
                type: 'success'
              })

              setTimeout(() => {
                setLoading(false)
                sandProjectCreated({
                  id: project.id,
                  name: project.name,
                  categories: project.categories,
                  datasetId: dataset.id,
                  teamId: project.projectTeamId
                })

                navigate(`/project?id=${project.id}`)
              }, 3000)
            })
            .catch((e) => {
              console.log(e)
              setLoading(false)
              showSnackbar({
                message: `Error creating project.`,
                type: 'error'
              })
            })

          return
        }
        showSnackbar({
          message: `Project not created. ` + r.error,
          type: 'error'
        })
      })
      .catch((error) => {
        console.log('Error', error)
        setActiveStep(activeStep - 1)
        showSnackbar({
          message: 'Error creating project.',
          type: 'error'
        })
      })
  }

  const onCloseDataset = () => {
    setShowDataset(false)
  }

  const saveDataset = (dataset) => {
    props.addNewDataset(dataset)
    setDataset({ value: dataset, state: null })
  }

  const getMoreDatasets = () => {
    props.listDatasets(props.datasets.nextToken)
  }

  const getMoreTeams = () => {
    props.listTeams(props.teams.nextToken)
  }

  const onCloseTeam = () => {
    setShowTeam(false)
  }

  const saveTeam = (team) => {
    props.listTeams(null)
    setTeam({ value: team, state: null })
  }

  const renderDatasetDialog = () => {
    return (
      <Dialog
        fullScreen
        onClose={onCloseDataset}
        aria-labelledby="customized-dialog-title"
        open={showDataset}
      >
        <AppBar style={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onCloseDataset}
              aria-label="close"
              size="large"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" style={{ marginLeft: 10, flex: 1, color: 'white' }}>
              Create New Dataset
            </Typography>
            {dataset.value && (
              <Button size="large" variant="contained" color="secondary" onClick={onCloseDataset}>
                Done
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <Upload {...props} datasetCreated={saveDataset} />
        </DialogContent>
      </Dialog>
    )
  }
  const uploadCategories = (categories) => {
    setCategories([...categories])
  }
  const renderTeamDialog = () => {
    return (
      <Dialog
        fullScreen
        onClose={onCloseTeam}
        aria-labelledby="customized-dialog-title"
        open={showTeam}
      >
        <AppBar style={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onCloseTeam}
              aria-label="close"
              size="large"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              variant="h6"
              style={{ marginLeft: 10, flex: 1, color: 'white' }}
              disabled={!getUser()?.paid || !permissionsFreeTrial}
            >
              Create New Team
            </Typography>
            {typeof team.value !== 'string' && (
              <Button size="large" variant="contained" color="secondary" onClick={onCloseTeam}>
                Done
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <CreateTeam {...props} teamCreated={saveTeam} />
        </DialogContent>
      </Dialog>
    )
  }

  const renderCategories = () => {
    return (
      <Grid container style={{ margin: 0, padding: 0 }}>
        <Grid
          item
          xs={4}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            padding: '0 10px'
          }}
        >
          <CreateCategory
            addCategory={handleAddClick}
            cancelEdit={() => setEditIndex(-1)}
            categories={categories}
            editIndex={editIndex}
            loading={false}
          />
        </Grid>
        <Grid item xs={8}>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <DialogUploadCategories
              style={{ alignSelf: 'rigth' }}
              uploadCategories={uploadCategories}
              categories={[]}
            />
          </div>
          <Paper
            style={{ overflow: 'auto', maxHeight: 300, padding: '20px', minHeight: 220 }}
            elevation={1}
          >
            {categories.length > 0 ? (
              categories.map((cat, index) => {
                const parent = (
                  <Chip
                    key={index}
                    className="category-chip"
                    avatar={
                      <Avatar
                        style={{ backgroundColor: `${cat.color}` }}
                        alt={cat.type}
                        src={getIcon(cat.type)}
                      />
                    }
                    label={cat.parent ? `${cat.name} (${cat.parent})` : cat.name}
                    color={editIndex === index ? 'primary' : 'default'}
                    onDelete={() => handleDelete(cat.name)}
                    onClick={() => setEditIndex(index)}
                    variant="outlined"
                  />
                )

                return parent
              })
            ) : (
              <p>Your categories</p>
            )}
          </Paper>
        </Grid>
      </Grid>
    )
  }

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      onSubmit()
    }

    const step = activeStep >= steps.length ? '' : steps[activeStep]
    if (step === STEPS.NAME) {
      sandProjectNameSet({ name: name.value })
    }

    setActiveStep(activeStep + 1)
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1)
  }

  const loadMoreItems = (event) => {
    if (event.target.clientHeight + event.target.scrollTop >= event.target.scrollHeight) {
      getMoreDatasets()
    }
  }

  const disableNext = () => {
    switch (activeStep) {
      case 0:
        return !name.value
      case 1:
        return !dataset.value
      case 2:
        return !team.value
      case 3:
        return categories.length <= 0
      default:
        return false
    }
  }

  const drawStep = () => {
    const { datasets } = props
    const step = activeStep >= steps.length ? '' : steps[activeStep]

    switch (step) {
      case STEPS.NAME:
        return (
          <TextField
            variant="standard"
            name="name"
            label="Project Name"
            className=""
            value={name.value}
            onChange={handleChange}
            margin="normal"
          />
        )
      case STEPS.DATASET:
        // eslint-disable-next-line no-case-declarations
        const dsets = []
        for (let index = 0; index < datasets.items.length; index++) {
          const element = datasets.items[index]
          dsets.push(
            <MenuItem value={element} key={index} name={element.name}>
              {`(${element.count}) ${element.name}`}
            </MenuItem>
          )
        }
        return (
          <>
            {!props.hideNewDataset && (
              <Button
                variant="contained"
                size="small"
                color="secondary"
                style={{ margin: 10 }}
                onClick={() => {
                  setShowDataset(true)
                  sandDatasetCreationStart()
                }}
              >
                Create Dataset
              </Button>
            )}
            <FormControl variant="standard" className="" fullWidth>
              <InputLabel htmlFor="dataset-helper">Dataset</InputLabel>
              <Select
                variant="standard"
                MenuProps={{
                  onScroll: loadMoreItems
                }}
                value={dataset.value}
                onOpen={() => {}}
                onChange={handleChange}
                renderValue={(value) => (value.name ? value.name : value)}
                inputProps={{
                  name: 'dataset',
                  id: 'dataset-select'
                }}
              >
                {dsets}
                {/* <MenuItem value={'upload'} >Create dataset</MenuItem> */}
                <MenuItem key="more" value="more">
                  <LoadMore>Load more...</LoadMore>
                </MenuItem>
              </Select>
              <FormHelperText>
                Select one of your datasets already created, also you can create a new one .
              </FormHelperText>
            </FormControl>
            <Typography
              variant="subtitle2"
              style={{
                marginTop: 8,
                marginBottom: 4,
                padding: 2,
                display: 'flex',
                justifyContent: 'left'
              }}
            >
              Advance options{' '}
              <span onClick={() => setShowAdvanceDataset((current) => !current)}>
                {showAdvanceDataset ? <ExpandLess /> : <ExpandMore />}
              </span>
            </Typography>
            <Collapse
              style={{ margin: '0 12px 24px 12px' }}
              in={showAdvanceDataset}
              timeout="auto"
              unmountOnExit
            >
              <FormControl variant="standard" style={{ minWidth: 120 }}>
                <InputLabel id="order-dataset">Order Dataset By</InputLabel>
                <Select
                  variant="standard"
                  labelId="order-dataset-select"
                  id="order-dataset-select"
                  value={datasetSort}
                  label="Order"
                  onChange={handleChange}
                  inputProps={{
                    name: 'order-dataset',
                    id: 'order-dataset-select'
                  }}
                >
                  <MenuItem value={'id'}>id</MenuItem>
                  <MenuItem value={'key'}>name</MenuItem>
                  <MenuItem value={'createdAt'}>Date Created</MenuItem>
                  <MenuItem value={'timestamp'}>timestamp</MenuItem>
                </Select>
              </FormControl>
            </Collapse>
          </>
        )
      case STEPS.TEAM:
        return (
          <>
            {!props.hideNewTeam && (
              <Button
                variant="contained"
                size="small"
                color="secondary"
                style={{ margin: 10 }}
                onClick={() => {
                  setShowTeam(true)
                  sandTeamCreationStarted()
                }}
                disabled={!getUser()?.paid && !permissionsFreeTrial}
              >
                Create Team
              </Button>
            )}
            <FormControl variant="standard" className="" fullWidth>
              <InputLabel htmlFor="team-helper">Team</InputLabel>
              <Select
                variant="standard"
                value={team.value}
                onChange={handleChange}
                renderValue={(value) => (value.name ? value.name : value)}
                inputProps={{ name: 'team', id: 'team-select' }}
              >
                <MenuItem value={'Riding solo 🦸'} key={'default'} name={'Riding solo 🦸'}>
                  Riding solo 🦸
                </MenuItem>
                {props.teams.items.map((team) => {
                  if (team.owner === getUser().owner) {
                    return (
                      <MenuItem value={team} key={team.id} name={team.name}>
                        {team.name}
                      </MenuItem>
                    )
                  }
                  return null
                })}
                <MenuItem key="more" value="more">
                  <LoadMore>Load more...</LoadMore>
                </MenuItem>
              </Select>
              <FormHelperText>
                Select one team already created or select &apos;Riding solo 🦸&apos; if you are
                working alone.
              </FormHelperText>
            </FormControl>
          </>
        )
      case STEPS.CATEGORIES:
        return renderCategories()
      default:
        return null
    }
  }

  return (
    <div style={{ marginTop: 80 }}>
      {!props.hideBreadcrumbs && (
        <Paper elevation={0} style={{ marginLeft: '10%' }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              color="inherit"
              href="/projects"
              to="/projects"
              onClick={sandProjectCreationCanceled}
            >
              Projects
            </Link>
            <Typography color="textPrimary">Create Project</Typography>
          </Breadcrumbs>
        </Paper>
      )}
      <Grid container className="create-grid">
        <Grid item xs={8} className="project-item">
          <Typography style={{ color: '#aaa' }} variant="h4">
            {' '}
            Create Project
          </Typography>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((label) => (
              <Step key={label} style={{ textAlign: 'left' }}>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                  {drawStep()}
                  <div style={{ marginBottom: 10 }}>
                    <div>
                      {activeStep > 0 && (
                        <Button disabled={activeStep === 0} onClick={handleBack}>
                          Back
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        disabled={disableNext()}
                      >
                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                      </Button>
                    </div>
                  </div>
                </StepContent>
              </Step>
            ))}
          </Stepper>
          {loading && <CircularProgress />}
        </Grid>
      </Grid>
      {renderDatasetDialog()}
      {renderTeamDialog()}
    </div>
  )
}

const mapStateToProps = (state) => ({
  teams: state.user.teams,
  datasets: state.user.datasets,
  modals: state.modals,
  project: state.project
})

const mapDispatchToProps = {
  createProject,
  listDatasets: u.getDatasets,
  addNewDataset: u.addDataset,
  listTeams: u.getMyTeams,
  getProject: p.getProject
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateProject)
