import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import {
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormGroup,
  ListItem,
  Typography,
  Accordion
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { FiltersContext } from '../../../../../../context/FiltersContext'
import styled from '@emotion/styled'
import CategoryItem from './components/CategoryItem'

const AccordionComponent = styled(Accordion)(() => ({
  width: '100%'
}))

const ContainerFormControl = styled('div')(() => ({
  width: '100%'
}))

const CategoriesFilter = () => {
  const { state, dispatch } = useContext(FiltersContext)
  const store = useSelector((state) => state)

  const handleChange = (event) => {
    const { name, value } = event.target

    if (name === 'categories') {
      handleCategoriesChange(value)
    } else if (name === 'labelClassifications') {
      handleLabelClassificationsChange(value)
    }
  }

  const handleCategoriesChange = (value) => {
    const newCategories = state.categories.includes(value)
      ? state.categories.filter((category) => category !== value)
      : [...state.categories, value]

    dispatch({ type: 'setCategories', payload: newCategories })
  }

  const handleLabelClassificationsChange = (value) => {
    const selectedValue = value
    dispatch({ type: 'setLabelClassifications', payload: selectedValue })
  }

  const handleCategory = (category) => {
    dispatch({ type: 'setHiddenCategories', payload: category })
  }

  const existCategory = (categories, category) => {
    return categories.some((item) => JSON.stringify(item) === JSON.stringify(category))
  }

  const renderCategoryList = () =>
    store.project.categories
      .filter((category) => category.type !== 'classification')
      .map((category) => (
        <CategoryItem
          key={category.name}
          category={category}
          handleCategory={handleCategory}
          existCategory={existCategory}
          handleChange={handleChange}
          store={store}
          hiddenCategories={state.hiddenCategories}
        />
      ))

  return (
    <ListItem>
      <AccordionComponent>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="categories-content"
          id="categories"
        >
          <Typography>Categories</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ContainerFormControl>
            <FormControl component="fieldset" style={{ width: '100%' }}>
              <FormGroup>{renderCategoryList()}</FormGroup>
            </FormControl>
          </ContainerFormControl>
        </AccordionDetails>
      </AccordionComponent>
    </ListItem>
  )
}

export default CategoriesFilter
