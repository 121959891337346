import React, { useEffect, useContext } from 'react'
import { LabelerContext } from '../../../context/LabelerContext'
import { useLabelerMachine } from '../../../context/MachineContext'
export function useClearCanvas(canvasList = [], parent = '', callback = () => {}) {
  const { state: MachineState, send } = useLabelerMachine()
  const { state } = useContext(LabelerContext)
  const { labelerState } = state

  useEffect(() => {
    if (MachineState.value === 'clearTags' || parent === '') {
      canvasList.forEach((canvas) => {
        if (canvas.current !== null) {
          const context = canvas.current.getContext('2d')
          context.beginPath()
          context.clearRect(0, 0, labelerState.image.props.w, labelerState.image.props.h)
          callback()
        }
      })
      if (labelerState.projectCategories.at(-1).name === parent) {
        send({ type: 'RESOLVE', source: 'useClearCanvas' })
      }
    }
  }, [MachineState.value])
}
