import React from 'react'
import { Link } from 'react-router-dom'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Upload from '../components/Upload'

const CreateDataset = (props) => {
  return (
    <div style={{ marginTop: '5em' }}>
      <Paper elevation={0} style={{ marginLeft: '10%' }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" href="/datasets" to="/datasets">
            Datasets
          </Link>
          <Typography color="textPrimary">Create Dataset</Typography>
        </Breadcrumbs>
      </Paper>
      {false && (
        <Link to="/datasets">
          <Button style={{ marginBottom: 10 }} variant="contained" color="primary">
            My Datasets
          </Button>
        </Link>
      )}
      <Upload {...props} buttonProject />
    </div>
  )
}

export default CreateDataset
