import React, { useState } from 'react'
import { connect } from 'react-redux'
import '../styles/createproject.css'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import UserSearch from '../components/UserSearch'
import { sandTeamCreated, sandMemberAddedToTeam } from '../io/analytics'
import { createTeam, createMember } from '../services/graphql'
import { getUser } from '../utils/util'
import { useSnackbar } from '../components/snackbar/context/SnackbarContext'

const CreateTeam = (props) => {
  const [teamName, setTeamName] = useState('')
  const [team, setTeam] = useState(null)

  const { showSnackbar } = useSnackbar()

  const handleNameChange = (e) => {
    setTeamName(e.target.value)
  }

  const onSubmit = () => {
    const user = getUser()

    if (!teamName) {
      showSnackbar({
        message: 'Set team name.',
        type: 'error'
      })
      return
    }

    createTeam({
      teamUserId: user.id,
      name: teamName,
      status: 'created',
      access: []
    }).then((res) => {
      showSnackbar({
        message: `Team ${teamName} created.`,
        type: 'success'
      })
      const team = res.data.createTeam

      sandTeamCreated({ id: team.id, name: team.name })

      if (props.teamCreated) {
        props.teamCreated(team)
      }

      createMember({
        memberTeamId: team.id,
        memberUserId: user.id,
        role: 'admin'
      })
        .then((res) => {
          sandMemberAddedToTeam({ id: team.id, name: team.name, memberId: user.id })
          console.log(res)
        })
        .catch((e) => console.log(e))
      setTeam(team)
    })
  }

  return (
    <div className="create-container">
      <Grid container className="create-grid">
        <Grid item xs={8} className="project-item">
          <Typography variant="h4">Your Team</Typography>
          <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
            <TextField
              variant="standard"
              name="username"
              id="outlined-name"
              label="Team Name"
              value={teamName}
              onChange={handleNameChange}
              margin="dense"
              autoFocus
            />
            <Button
              variant="contained"
              color="primary"
              className="btn-add"
              onClick={onSubmit}
              disabled={!teamName.length > 0 || !!team}
            >
              Create Team
            </Button>
            {team && (
              <>
                <Typography style={{ marginTop: '10px', textAlign: 'left' }} variant="h5">
                  Search for members
                </Typography>
                <UserSearch {...props} team={team} />
              </>
            )}
          </form>
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state) => ({
  project: state.project
})

export default connect(mapStateToProps, {})(CreateTeam)
