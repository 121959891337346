import React, { useContext } from 'react'
import { Button, Divider } from '@mui/material'
import { styled } from '@mui/material/styles'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import { useLabelerMachine } from '../../../../../containers/labeler/context/MachineContext'
import { LabelerContext } from '../../../../../containers/labeler/context/LabelerContext'
import { DispatchContext } from '../../../../../containers/labeler/context/DispatchContext'
import Badge from '@mui/material/Badge'
import { useEffectWR } from '../../../../../containers/labeler/components/types/hooks/useEffectWR'
const Btn = styled(Button)(() => ({
  width: '50%'
}))

const SkipBtn = styled(Btn)(({ theme }) => ({
  color: theme.palette.error.light
}))

const CommentsButton = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  margin: 0
}))

const ContainerButtons = styled('div')(() => ({
  display: 'flex'
}))

const StyledBadge = styled(Badge)(({ theme }) => ({
  marginTop: 8,
  marginRight: 8,
  cursor: 'pointer',
  zIndex: 0,
  '& .MuiBadge-badge': {
    right: 0,
    top: 0,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px 10ox 3px'
  }
}))

export default function LabelerImgHandler({
  setImageSkip,
  setImageDone,
  done,
  skipped,
  openFeedbackToolbar,
  numberOfComments
}) {
  const { state: MachineState } = useLabelerMachine()
  const { state } = useContext(LabelerContext)
  const { dispatch } = useContext(DispatchContext)
  useEffectWR([state.labelerState.shortcuts.done], [setImageDone])
  useEffectWR([state.labelerState.shortcuts.skip], [setImageSkip])
  useEffectWR(
    [state.labelerState.shortcuts.doneAndNext],
    [
      function () {
        setImageDone(nextImage)
      }
    ]
  )
  useEffectWR(
    [state.labelerState.shortcuts.skipAndNext],
    [
      function () {
        setImageSkip(nextImage)
      }
    ]
  )

  const nextImage = () => {
    dispatch({ type: 'setNavigation', payload: 'next' })
  }

  const handlerImageDone = () => {
    setImageDone()
  }

  const handlerSkipImage = () => {
    setImageSkip()
  }

  return (
    <ContainerButtons>
      <Btn
        disabled={MachineState.value !== 'readyForLabeling'}
        color="primary"
        onClick={handlerImageDone}
        variant={done ? 'contained' : 'text'}
        sx={done ? { backgroundColor: 'success.light', color: 'white' } : {}}
      >
        Done
      </Btn>
      <SkipBtn
        disabled={MachineState.value !== 'readyForLabeling'}
        color="secondary"
        onClick={handlerSkipImage}
        variant={skipped ? 'contained' : 'text'}
        sx={
          skipped
            ? {
                backgroundColor: 'error.light',
                color: 'white'
              }
            : {}
        }
      >
        Skip
      </SkipBtn>
      <CommentsButton onClick={openFeedbackToolbar}>
        <StyledBadge badgeContent={numberOfComments} color="primary">
          <NotificationsNoneIcon />
        </StyledBadge>
      </CommentsButton>
      <Divider />
    </ContainerButtons>
  )
}
