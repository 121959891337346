import { createActions } from 'redux-actions'

export const { dialog } = createActions({
  DIALOG: {
    SET_STATUS: (value) => ({ value }),
    SET_TITLE: (title) => ({ title }),
    SET_TEXT: (text) => ({ text }),
    SET_CATEGORIES: (categories) => ({ categories }),
    SET_ALL_PROPERTIES: (status, index, title, text, okText, categories = []) => ({
      isOpen: status,
      index,
      title,
      text,
      okText,
      categories
    }),
    SET_STATUS_LABELER_METRICS_DIALOG: (value) => ({ value }),
    SET_STATUS_REVIWER_METRICS_DIALOG: (value) => ({ value }),
    SET_LABELER_EMAIL: (email, id) => ({ email, id }),
    SET_REVIWER_EMAIL: (email, id) => ({ email, id }),
    SET_OPEN_SNACK: (open) => ({ open })
  }
})
