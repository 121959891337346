import React, { useContext } from 'react'
import {
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  FormGroup,
  ListItem,
  Typography,
  FormControl,
  Accordion
} from '@mui/material'
import styled from '@emotion/styled'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { FiltersContext } from '../../../../../../context/FiltersContext'
import { uniqBy as _uniq } from 'lodash'
import { useSelector } from 'react-redux'
import { getUser } from '../../../../../../../../../../utils/util'

const ContainerFormControl = styled('div')(() => ({
  width: '100%'
}))

const ContainerLabel = styled('div')(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '160px'
}))

const ReviewerFilter = () => {
  const { state, dispatch } = useContext(FiltersContext)
  const store = useSelector((state) => state)

  const handleChange = (reviewer) => {
    const userIndex = state.reviewers.findIndex((reviewerItem) => reviewerItem === reviewer.user.id)
    if (userIndex !== -1) {
      const updatedReviewers = [...state.reviewers]
      updatedReviewers.splice(userIndex, 1)
      dispatch({ type: 'setReviewers', payload: updatedReviewers })
    } else {
      const newReviewers = [...state.reviewers, reviewer.user.id]
      dispatch({ type: 'setReviewers', payload: newReviewers })
    }
  }

  const user = getUser()
  const teamMembers = _uniq(
    [{ user: { id: user.id, email: user.email } }, ...(store.project.team?.members?.items || [])],
    'user.id'
  )

  return (
    <ListItem>
      <Accordion sx={{ width: '100%' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="reviewer-content"
          id="reviewer"
        >
          <Typography>Reviewer</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ContainerFormControl>
            <FormControl component="fieldset">
              <FormGroup>
                {teamMembers.map((reviewer) => (
                  <FormControlLabel
                    key={reviewer.user.id}
                    control={
                      <Checkbox
                        checked={state.reviewers.some(
                          (reviewerItem) => reviewerItem === reviewer.user.id
                        )}
                        onClick={() => handleChange(reviewer)}
                        name={'reviewers'}
                      />
                    }
                    label={<ContainerLabel>{reviewer.user.email}</ContainerLabel>}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </ContainerFormControl>
        </AccordionDetails>
      </Accordion>
    </ListItem>
  )
}

export default ReviewerFilter
