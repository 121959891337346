/* eslint-disable no-empty */
import { EMPTY, TYPES } from './constants'
import { sandEvent } from '../io/analytics'
import { v4 as uuidv4 } from 'uuid'

export function resizeRect(posx, posy, clickedCase, editRect, grabPoint) {
  let initialX = editRect.pos[0]
  let initialY = editRect.pos[1]
  let width = editRect.pos[2]
  let height = editRect.pos[3]
  let mouseType = 'default'
  switch (clickedCase) {
    case 7:
      width = posx - initialX < 1 ? editRect.pos[2] : posx - initialX
      height = posy - initialY < 1 ? editRect.pos[3] : posy - initialY
      mouseType = 'nwse-resize'
      break
    case 6:
      height = posy - initialY < 1 ? editRect.pos[3] : posy - initialY
      mouseType = 'ns-resize'
      break
    case 5:
      if (width + initialX - posx > 1) {
        width += initialX - posx
        initialX = posx
        height = posy - initialY < 1 ? editRect.pos[3] : posy - initialY
      }
      mouseType = 'nesw-resize'
      break
    case 4:
      width = posx - initialX < 1 ? editRect.pos[2] : posx - initialX
      mouseType = 'ew-resize'
      break
    case 3:
      if (width + initialX - posx > 1) {
        width += initialX - posx
        initialX = posx
      }
      mouseType = 'ew-resize'
      break
    case 2:
      if (height + initialY - posy > 1) {
        height += initialY - posy
        width = posx - initialX < 1 ? editRect.pos[2] : posx - initialX
        initialY = posy
      }
      mouseType = 'nesw-resize'
      break
    case 1:
      if (height + initialY - posy > 1) {
        height += initialY - posy
        initialY = posy
      }
      mouseType = 'ns-resize'
      break
    case 10:
      if (height + initialY - posy > 1 && width + initialX - posx > 1) {
        height += initialY - posy
        width += initialX - posx
        initialX = posx
        initialY = posy
      }
      mouseType = 'nwse-resize'
      break
    case -1:
      initialX += (posx - initialX) - grabPoint[0]
      initialY += (posy - initialY) - grabPoint[1]
      mouseType = 'grabbing'
      break
    default:
      break
  }
  return { initialX, initialY, width, height, mouseType }
}

export function caseSelect(posx, posy, editRect, scale) {
  let clickedCase = null
  let grabPoint = null
  const initialX = editRect.pos[0]
  const initialY = editRect.pos[1]
  const width = editRect.pos[2]
  const height = editRect.pos[3]
  const iniXP2 = initialX + (11 / (scale / 2))
  const iniXM2 = initialX - (11 / (scale / 2))
  const iniYP2 = initialY + (11 / (scale / 2))
  const iniYM2 = initialY - (11 / (scale / 2))
  if ((posx > (iniXM2 + width) && posx < (iniXP2 + width)) &&
    (posy > (iniYM2 + height) && posy < (iniYP2 + height))) {
    clickedCase = 7
  }
  if ((posx > (iniXM2 + width / 2) && posx < (iniXP2 + width / 2)) &&
    (posy > (iniYM2 + height) && posy < (iniYP2 + height))) {
    clickedCase = 6
  }
  if ((posx > (iniXM2) && posx < (iniXP2)) &&
    (posy > (iniYM2 + height) && posy < (iniYP2 + height))) {
    clickedCase = 5
  }
  if ((posx > (iniXM2 + width) && posx < (iniXP2 + width)) &&
    (posy > (iniYM2 + height / 2) && posy < (iniYP2 + height / 2))) {
    clickedCase = 4
  }
  if ((posx > (iniXM2) && posx < (iniXP2)) &&
    (posy > (iniYM2 + height / 2) && posy < (iniYP2 + height / 2))) {
    clickedCase = 3
  }
  if ((posx > (iniXM2 + width) && posx < (iniXP2 + width)) &&
    (posy > (iniYM2) && posy < (iniYP2))) {
    clickedCase = 2
  }
  if ((posx > (iniXM2 + width / 2) && posx < (iniXP2 + width / 2)) &&
    (posy > (iniYM2) && posy < (iniYP2))) {
    clickedCase = 1
  }
  if ((posx > (iniXM2) && posx < (iniXP2)) &&
    (posy > (iniYM2) && posy < (iniYP2))) {
    clickedCase = 10
  }
  if ((posx > (initialX + 5) && posx < (initialX + width - 5)) &&
    (posy > (initialY + 5) && posy < (initialY + height - 5))) {
    clickedCase = -1
    grabPoint = [posx - initialX, posy - initialY]
  }
  return { clickedCase, grabPoint }
}

export function generateColor() {
  return '#' + Math.random().toString(16).substr(-6)
}

export function selectionPointLocation(rect, index) {
  switch (index) {
    case 0:
      return [rect.pos[0], rect.pos[1], 4 / this.state.scale > 1 ? 4 / this.state.scale : 1, 0, 2 * Math.PI]
    case 1:
      return [rect.pos[0], rect.pos[1] + rect.pos[3], 4 / this.state.scale > 1 ? 4 / this.state.scale : 1, 0, 2 * Math.PI]
    case 2:
      return [rect.pos[0] + rect.pos[2], rect.pos[1], 4 / this.state.scale > 1 ? 4 / this.state.scale : 1, 0, 2 * Math.PI]
    case 3:
      return [rect.pos[0] + rect.pos[2], rect.pos[1] + rect.pos[3], 4 / this.state.scale > 1 ? 4 / this.state.scale : 1, 0, 2 * Math.PI]
    case 4:
      return [rect.pos[0] + (rect.pos[2]) / 2, rect.pos[1], 4 / this.state.scale > 1 ? 4 / this.state.scale : 1, 0, 2 * Math.PI]
    case 5:
      return [rect.pos[0] + (rect.pos[2]) / 2, rect.pos[1] + rect.pos[3], 4 / this.state.scale > 1 ? 4 / this.state.scale : 1, 0, 2 * Math.PI]
    case 6:
      return [rect.pos[0] + rect.pos[2], rect.pos[1] + (rect.pos[3]) / 2, 4 / this.state.scale > 1 ? 4 / this.state.scale : 1, 0, 2 * Math.PI]
    case 7:
      return [rect.pos[0], rect.pos[1] + (rect.pos[3]) / 2, 4 / this.state.scale > 1 ? 4 / this.state.scale : 1, 0, 2 * Math.PI]
    default:
      break
  }
}

export function selectRectForEdit(addedRects, posx, posy) {
  for (let index = 0; index < addedRects.length; index++) {
    const rect = addedRects[index]
    if (!rect.show) continue
    if (
      (rect.pos[0] < posx && posx < (rect.pos[0] + rect.pos[2])) &&
      (rect.pos[1] < posy && posy < (rect.pos[1] + rect.pos[3]))
    ) {
      return {
        element: { ...rect },
        index
      }
    }
  }
}

export function selectPointForEdit(addedPoints, posx, posy) {
  for (let index = 0; index < addedPoints.length; index++) {
    const point = addedPoints[index]
    if (!point.show) continue
    if (
      (point.pos.x - 11 < posx && posx < point.pos.x + 11) &&
      (point.pos.y - 11 < posy && posy < point.pos.y + 11)
    ) {
      return {
        element: { ...point },
        index
      }
    }
  }
  return null
}

export function selectClickCase(posx, posy) {
  /* 0 1 2
     3   4
     5 6 7
  */
  const caseAndGrab = (caseSelect(posx, posy, this.state.edit.element, this.state.scale))
  const { clickedCase, grabPoint } = caseAndGrab
  if (clickedCase === null) {
    this.setState({ edit: EMPTY.labeler.edit }, this.updateCanvasFromState)
  } else {
    this.setState(prevState => ({
      edit: {
        ...prevState.edit,
        clickedCase,
        grabPoint
      }
    }), this.updateCanvasFromState)
  }
}

export function isInsidePoly(points, test) {
  let i; let j; let c = false
  for (i = 0, j = points.length - 1; i < points.length; j = i++) {
    if (((points[i].y > test.y) !== (points[j].y > test.y)) &&
      (test.x < (points[j].x - points[i].x) * (test.y - points[i].y) / (points[j].y - points[i].y) + points[i].x)) {
      c = !c
    }
  }
  return c
}

// TODO: Remove this
// function isNearPoint(points, point) {
// let near = false
// for(let k = 0; k < points.length; k++) {
//   if(Math.abs(points[k].x - point.x) <= 4 && Math.abs(points[k].y - point.y) <= 4) {
//     near = !near
//     break
//   }
// }

// return near
// }

export function selectPolyForEdit(addedPolygons, posx, posy) {
  const test = { x: posx, y: posy }
  for (let index = 0; index < addedPolygons.length; index++) {
    const poly = addedPolygons[index]
    if (!poly.show) continue
    let minx = null
    let miny = null
    let maxx = null
    let maxy = null
    poly.pos.forEach((pair, index) => {
      if (index === 0) {
        minx = pair.x
        miny = pair.y
        maxx = pair.x
        maxy = pair.y
      }
      if (pair.x < minx) { minx = pair.x }
      if (pair.y < miny) { miny = pair.y }
      if (pair.x > maxx) { maxx = pair.x }
      if (pair.y > maxy) { maxy = pair.y }
    })
    if ((posx < minx || posx > maxx) || (posy < miny || posy > maxy)) continue
    if (isInsidePoly(poly.pos, test)) {
      return { element: poly, index }
    }
  }
}

export function selectPolyClickCase(posx, posy) {
  let cclickedCase = null
  const grabPoint = null

  let factor = 4 - Math.log(this.state.scale)
  factor = factor < 1.5 ? 1.5 : factor // (11 / (this.state.scale / 2))

  this.state.edit.element.pos.forEach((point, index) => {
    const iniXP2 = point.x + factor
    const iniXM2 = point.x - factor
    const iniYP2 = point.y + factor
    const iniYM2 = point.y - factor
    if ((posx > (iniXM2) && posx < (iniXP2)) &&
      (posy > (iniYM2) && posy < (iniYP2))) {
      cclickedCase = index
      sandEvent('polygon/selectEditPoint', { poly: this.state.edit.element, cclickedCase })
    }
  })
  if (cclickedCase === null) {
    const test = { x: posx, y: posy }
    if (isInsidePoly(this.state.edit.element.pos, test)) {
      // TODO: Enable this to move the entire poligon
      // grabPoint = {
      //   posx: posx,
      //   posy: posy
      // }
      sandEvent('polygon/grabPolygon', { poly: this.state.edit.element, grabPoint })
    } else {
      sandEvent('polygon/deselectEditPoint', this.state.edit.element)
      this.setState({ edit: EMPTY.labeler.edit },
        this.updateCanvasFromState)
    }
  }
  this.setState(prevState => ({
    edit: {
      ...prevState.edit,
      clickedCase: cclickedCase,
      grabPoint
    }
  }), this.updateCanvasFromState)
}

export function getRatioRectangles(rects, ratio) {
  const newRects = []
  if (rects && rects !== null) {
    if (rects.poly) {
      if (rects.rects.length > 0) {
        rects.rects.forEach(rect => {
          const element = { name: rect.name, color: rect.color, pos: [], type: rect.type ? rect.type : TYPES.RECT, show: true, parent: rect.parent, id: rect.id, text: rect.text, classes: rect.classes }

          rect.pos.forEach((pos, index) => {
            element.pos[index] = pos * ratio
          })
          newRects.push(element)
        })
      }
    } else {
      if (rects.length > 0) {
        rects.forEach(rect => {
          const element = { name: rect.name, color: rect.color, pos: [], type: rect.type ? rect.type : TYPES.RECT, show: true, parent: rect.parent, id: rect.id, text: rect.text, classes: rect.classes }
          rect.pos.forEach((pos, index) => {
            element.pos[index] = pos * ratio
          })
          newRects.push(element)
        })
      }
    }

    return newRects
  }
}

export function getRealRectangles(rects, ratio) {
  const newRects = []
  if (rects && rects !== null && rects.length > 0) {
    rects.forEach(rect => {
      const element = { name: rect.name, color: rect.color, pos: [], type: rect.type, parent: rect.parent, id: rect.id, text: rect.text, classes: rect.classes }

      rect.pos.forEach((pos, index) => {
        element.pos[index] = pos / ratio
      })
      newRects.push(element)
    })
  }
  return newRects
}

export function getRatioPolys(polys, ratio) {
  if (polys) {
    const newPolys = []
    polys.forEach(poly => {
      const element = { name: poly.name, color: poly.color, pos: [], type: poly.type ? poly.type : TYPES.POLY, show: true, id: poly.id, text: poly.text, classes: poly.classes }

      poly.pos.forEach((pos, index) => {
        element.pos[index] = {
          x: pos.x * ratio,
          y: pos.y * ratio
        }
      })
      newPolys.push(element)
    })

    return newPolys
  }
}

export function getRealPolys(polys, ratio) {
  const newPolys = []
  polys.forEach(poly => {
    const element = { name: poly.name, color: poly.color, pos: [], type: poly.type, id: poly.id, text: poly.text, classes: poly.classes }

    poly.pos.forEach((pos, index) => {
      element.pos[index] = {
        x: pos.x / ratio,
        y: pos.y / ratio
      }
    })
    newPolys.push(element)
  })

  return newPolys
}

export function getRatioPoints(points, ratio) {
  if (points) {
    const newPoints = []
    points.forEach(point => {
      const element = {
        name: point.name,
        pos: {},
        color: point.color,
        type: point.type ? point.type : TYPES.POINT,
        show: true,
        id: point.id,
        text: point.text,
        classes: point.classes
      }

      element.pos.x = point.pos.x * ratio
      element.pos.y = point.pos.y * ratio
      newPoints.push(element)
    })

    return newPoints
  }
}

export function getRealPoints(points, ratio) {
  const newPoints = []
  points.forEach(point => {
    const element = {
      name: point.name,
      color: point.color,
      pos: {},
      type: point.type,
      id: point.id,
      text: point.text,
      classes: point.classes
    }
    element.pos.x = Math.round(point.pos.x / ratio)
    element.pos.y = Math.round(point.pos.y / ratio)
    newPoints.push(element)
  })

  return newPoints
}

export function getRatioLines(lines, ratio) {
  if (lines) {
    const newPoints = []
    lines.forEach(line => {
      const element = {
        name: line.name,
        color: line.color,
        pos: [],
        type: line.type ? line.type : TYPES.LINE,
        show: true,
        id: line.id,
        text: line.text,
        classes: line.classes
      }
      line.pos.forEach((pos, index) => {
        element.pos[index] = {
          x: Math.round(pos.x * ratio),
          y: Math.round(pos.y * ratio)
        }
      })
      newPoints.push(element)
    })

    return newPoints
  }
}

export function getRealLines(lines, ratio) {
  const newPoints = []
  lines.forEach(line => {
    const element = {
      name: line.name,
      color: line.color,
      pos: [],
      type: line.type,
      id: line.id,
      text: line.text,
      classes: line.classes
    }

    line.pos.forEach((pos, index) => {
      element.pos[index] = {
        x: pos.x / ratio,
        y: pos.y / ratio
      }
    })
    newPoints.push(element)
  })

  return newPoints
}
export function getRatioCircles(circles, ratio) {
  if (circles) {
    const newPoints = []
    circles.forEach(circle => {
      const element = {
        name: circle.name,
        color: circle.color,
        pos: [],
        type: circle.type ? circle.type : TYPES.CIRCLE,
        show: true,
        id: circle.id,
        text: circle.text
      }
      element.pos = {
        center: { x: circle.pos.center.x * ratio, y: circle.pos.center.y * ratio },
        left: circle.pos.left * ratio,
        top: circle.pos.top * ratio,
        other: 0
      }
      newPoints.push(element)
    })

    return newPoints
  }
}

export function getRealCircles(circles, ratio) {
  const newPoints = []
  circles.forEach(circle => {
    const element = {
      name: circle.name,
      color: circle.color,
      pos: [],
      type: circle.type,
      id: circle.id,
      text: circle.text
    }
    element.pos = {
      center: { x: circle.pos.center.x / ratio, y: circle.pos.center.y / ratio },
      left: circle.pos.left / ratio,
      top: circle.pos.top / ratio,
      other: 0
    }
    newPoints.push(element)
  })

  return newPoints
}

export function getClickedShape(state, posx, posy) {
  if (state.addedPoints.length > 0) {
    const point = selectPointForEdit(state.addedPoints, posx, posy)
    if (point) return point
  }
  if (state.addedLines.length > 0) {
    const line = selectLineForEdit(state.addedLines, posx, posy)
    if (line) return line
  }
  if (state.addedRects.length > 0) {
    const rect = selectRectForEdit(state.addedRects, posx, posy)
    if (rect) return rect
  }
  if (state.addedCircles.length > 0) {
    const ellip = selectEllipseForEdit(state.addedCircles, posx, posy)
    if (ellip) return ellip
  }
  if (state.addedPolygons.length > 0) {
    const poly = selectPolyForEdit(state.addedPolygons, posx, posy)
    if (poly) return poly
  }
  return null
}

export function selectLineForEdit(addedLines, posx, posy) {
  const test = { x: posx, y: posy }
  function calcIsInsideThickLineSegment(line1, line2, pnt, lineThickness = 11) {
    const L2 = (((line2.x - line1.x) * (line2.x - line1.x)) + ((line2.y - line1.y) * (line2.y - line1.y)))
    if (L2 === 0) return false
    const r = (((pnt.x - line1.x) * (line2.x - line1.x)) + ((pnt.y - line1.y) * (line2.y - line1.y))) / L2

    // Assume line thickness is circular
    if (r < 0) {
      // Outside line1
      return (Math.sqrt(((line1.x - pnt.x) * (line1.x - pnt.x)) + ((line1.y - pnt.y) * (line1.y - pnt.y))) <= lineThickness)
    } else if ((r >= 0) && (r <= 1)) {
      // On the line segment
      const s = (((line1.y - pnt.y) * (line2.x - line1.x)) - ((line1.x - pnt.x) * (line2.y - line1.y))) / L2
      return (Math.abs(s) * Math.sqrt(L2) <= lineThickness)
    } else {
      // Outside line2
      return (Math.sqrt(((line2.x - pnt.x) * (line2.x - pnt.x)) + ((line2.y - pnt.y) * (line2.y - pnt.y))) <= lineThickness)
    }
  }

  for (let index = 0; index < addedLines.length; index++) {
    const line = addedLines[index]
    if (!line.show) continue
    for (let jndex = 1; jndex < line.pos.length; jndex++) {
      const element2 = line.pos[jndex]
      const element1 = line.pos[jndex - 1]
      if (calcIsInsideThickLineSegment(element1, element2, test)) return { element: line, index }
    }
  }
}

export function selectLinelickCase(posx, posy) {
  let cclickedCase = null
  this.state.edit.element.pos.forEach((point, index) => {
    const iniXP2 = point.x + (11 / (this.state.scale / 2))
    const iniXM2 = point.x - (11 / (this.state.scale / 2))
    const iniYP2 = point.y + (11 / (this.state.scale / 2))
    const iniYM2 = point.y - (11 / (this.state.scale / 2))
    if ((posx > (iniXM2) && posx < (iniXP2)) &&
      (posy > (iniYM2) && posy < (iniYP2))) {
      cclickedCase = index
      sandEvent('line/selectEditPoint', { line: this.state.edit.element, cclickedCase })
    }
  })
  if (cclickedCase === null) {
    this.setState({ edit: EMPTY.labeler.edit })
  } else {
    this.setState(prevState => ({
      edit: {
        ...prevState.edit,
        clickedCase: cclickedCase
      }
    }), this.updateCanvasFromState)
  }
}

export function selectEllipseForEdit(addedEllipses, posx, posy) {
  for (let index = 0; index < addedEllipses.length; index++) {
    const ellipse = addedEllipses[index]
    if (!ellipse.show) continue
    const hor = (posx - ellipse.pos.center.x)
    const ver = (posy - ellipse.pos.center.y)
    const isInside = (Math.pow(hor, 2) / Math.pow(ellipse.pos.left, 2)) + (Math.pow(ver, 2) / Math.pow(ellipse.pos.top, 2)) <= 1
    if (isInside) return { element: { ...ellipse }, index }
  }
}

export function selectEllipseClickCase(posx, posy) {
  let cclickedCase = null
  const { element: ellipse } = this.state.edit

  if (
    (ellipse.pos.center.x - 11 < posx &&
      posx < ellipse.pos.center.x + 11) &&
    (ellipse.pos.center.y - ellipse.pos.top - 11 < posy &&
      posy < ellipse.pos.center.y - ellipse.pos.top + 11)
  ) cclickedCase = 'top'
  if (
    (ellipse.pos.center.x - 11 < posx &&
      posx < ellipse.pos.center.x + 11) &&
    (ellipse.pos.center.y + ellipse.pos.top - 11 < posy &&
      posy < ellipse.pos.center.y + ellipse.pos.top + 11)
  ) cclickedCase = 'bottom'
  if (
    (ellipse.pos.center.x + ellipse.pos.left - 11 < posx &&
      posx < ellipse.pos.center.x + ellipse.pos.left + 11) &&
    (ellipse.pos.center.y - 11 < posy &&
      posy < ellipse.pos.center.y + 11)
  ) cclickedCase = 'right'
  if (
    (ellipse.pos.center.x - ellipse.pos.left - 11 < posx &&
      posx < ellipse.pos.center.x - ellipse.pos.left + 11) &&
    (ellipse.pos.center.y - 11 < posy &&
      posy < ellipse.pos.center.y + 11)
  ) cclickedCase = 'left'
  if (cclickedCase === null) {
    const rotation = ellipse.pos.other
    const cos = Math.cos(rotation)

    const sin = Math.sin(rotation)
    const dx = (posx - ellipse.pos.center.x)

    const dy = (posy - ellipse.pos.center.y)
    const tdx = cos * dx + sin * dy

    const tdy = sin * dx - cos * dy
    const isInside = (tdx * tdx) / (ellipse.pos.top * ellipse.pos.top) + (tdy * tdy) / (ellipse.pos.left * ellipse.pos.left) <= 1
    if (isInside) {
      this.setState(prevState => ({
        edit: {
          ...prevState.edit,
          grabPoint: { x: posx, y: posy }
        }
      }), () => {
        sandEvent('circle/grab', {})
        this.updateCanvasFromState()
      })
    } else this.setState({ edit: EMPTY.labeler.edit })
  } else {
    this.setState(prevState => ({
      edit: {
        ...prevState.edit,
        clickedCase: cclickedCase
      }
    }), () => {
      sandEvent('circle/selectEditPoint', {})
      this.updateCanvasFromState()
    })
  }
}

export function checkCircleClick(posx, posy, callback) {
  if (!this.state.circleCenter.x && !this.state.circleCenter.y) {
    this.setState({ circleCenter: { x: posx, y: posy } })
    this.ctx.strokeStyle = this.state.categories[this.state.currentCategory].color
    this.ctx.fillStyle = this.state.categories[this.state.currentCategory].color
    this.ctx.beginPath()
    this.ctx.arc(posx, posy, 4 / this.state.scale > 1 ? 4 / this.state.scale : 1, 0, 2 * Math.PI)
    this.ctx.globalAlpha = 0.4
    this.ctx.fill()
    this.ctx.globalAlpha = 0.7
    this.ctx.stroke()
  } else {
    const a = Math.abs(posx - this.state.circleCenter.x)
    const b = Math.abs(posy - this.state.circleCenter.y)
    const distance = Math.sqrt(a * a + b * b)
    this.ctx.beginPath()
    this.ctx.arc(this.state.circleCenter.x, this.state.circleCenter.y,
      distance,
      0, 2 * Math.PI)
    this.ctx.globalAlpha = 0.2
    this.ctx.fill()
    this.setState({
      circleCenter: {},
      addedCircles: [
        ...this.state.addedCircles,
        {
          id: uuidv4(),
          pos: {
            center: { x: this.state.circleCenter.x, y: this.state.circleCenter.y },
            left: distance,
            top: distance,
            other: 0
          },
          type: this.state.categories[this.state.currentCategory].type,
          color: this.state.categories[this.state.currentCategory].color,
          name: this.state.categories[this.state.currentCategory].name,
          show: true,
          parent: null
        }
      ]
    }, callback)
  }
}

// Retro compatibility of tags
export const getTagsFromImage = (image) => {
  let rects = []
  let polys = []
  let points = []
  let lines = []
  let circles = []

  if (image.tags) {
    if (image.tags.rects && image.tags.rects !== undefined && image.tags.rects.length > 0) {
      rects = image.tags.rects
    } else { rects = image.tags }
    if (image.tags.polys !== undefined && image.tags.polys && image.tags.polys.length > 0) {
      polys = image.tags.polys
    } else { }
    if (image.tags.points !== undefined && image.tags.points && image.tags.points.length > 0) {
      points = image.tags.points
    } else { }
    if (image.tags.lines !== undefined && image.tags.lines && image.tags.lines.length > 0) {
      lines = image.tags.lines
    } else { }
    if (image.tags.circles !== undefined && image.tags.circles && image.tags.circles.length > 0) {
      circles = image.tags.circles
    } else { }
  }

  return {
    rects,
    polys,
    points,
    lines,
    circles
  }
}

export function getRatioClassification(classification) {
  if (!classification) return {}

  const cls = {}
  for (let i = 0; i < classification.length; i++) {
    const c = classification[i]
    if (c.multiSelect) {
      cls[c.name] = c.values
    } else {
      cls[c.name] = c.selected
    }
  }

  return cls
}

export function getSegmentations(data, categories) {
  if (data !== null) {
    if (data.length === 0) {
      const arr = categories.all.map((category) => {
        return { name: category.name, color: category.color, shapes: [], type: category.type, hide: false, hover: false }
      })
      this.context.dispatch({ type: 'setSegmentation', payload: { all: arr, stack: [] } })

    } else {
      this.context.dispatch({ type: 'setSegmentation', payload: data })
    }

  } else {
    const arr = []
    for (let index = 0; index < categories.length; index++) {
      const category = categories[index]
      arr.push({ name: category.name, color: category.color, shapes: [], type: category.type, hide: false, hover: false })

      this.context.dispatch({ type: 'setSegmentation', payload: { all: arr, stack: [] } })
    }
  }
  this.context.dispatch({ type: 'setLoadingTags', payload: false })
}
