import React, { createContext, useContext, useState } from 'react'

const SnackbarContext = createContext()

export const SnackbarProvider = ({ children }) => {
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: '',
    type: 'info',
    isButton: false,
    buttonBlocked: false,
    buttonFunction: null,
    buttonText: '',
    duration: 0
  })

  const showSnackbar = ({
    message,
    type = 'info',
    isButton = 'false',
    buttonBlocked = 'false',
    buttonFunction = null,
    buttonText = '',
    duration = 6000
  }) => {
    setSnackbarState({
      open: true,
      message,
      type,
      isButton,
      buttonBlocked,
      buttonFunction,
      buttonText,
      duration
    })
  }

  const hideSnackbar = () => {
    setSnackbarState((prevState) => ({
      open: false,
      type: prevState.type,
      message: '',
      isButton: false,
      buttonBlocked: false,
      buttonFunction: null,
      buttonText: '',
      duration: 0
    }))
  }

  return (
    <SnackbarContext.Provider value={{ snackbarState, showSnackbar, hideSnackbar }}>
      {children}
    </SnackbarContext.Provider>
  )
}

export const useSnackbar = () => {
  const context = useContext(SnackbarContext)
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider')
  }
  return context
}
