import React, { Component } from 'react'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'

class DownloadAsModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleDownloadModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Download your tags as CSV or JSON'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please select the type of file to download. Currently, CSV is only available for 2D
            Boxes.
          </DialogContentText>
          <DialogActions>
            <Button
              onClick={this.props.handleSelectCSV}
              color="primary"
              variant="contained"
              disabled={this.props.disabledCSV}
            >
              CSV
            </Button>
            <Button
              onClick={this.props.handleSelectJSON}
              color="primary"
              autoFocus
              variant="contained"
            >
              JSON
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    )
  }
}

export default DownloadAsModal
