import React, { useState, useRef, useCallback, useEffect } from 'react'
import Snackbar from '@mui/material/Snackbar'
import SnackbarContent from '@mui/material/SnackbarContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { createPortal } from 'react-dom'
import Button from '@mui/material/Button'
const types = {
  success: '#81c784',
  error: '#e57373',
  warning: '#ffb74d',
  info: '#64b5f6'
}

const customsnack =
  (
    isOpened,
    Portal,
    clickOutsideToHide,
    message,
    type,
    block,
    isButton,
    handlerButton,
    textButton = 'Save Tags'
  ) =>
  // eslint-disable-next-line no-unused-vars
  ({ children }) => {
    if (!isOpened) return null
    const type_ = types[type]

    return createPortal(
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isOpened}
        autoHideDuration={6000}
        onClose={() => !block && clickOutsideToHide()}
      >
        <SnackbarContent
          style={{ backgroundColor: type_ }}
          aria-describedby="client-snackbar"
          action={
            isButton
              ? [
                  // eslint-disable-next-line react/jsx-key
                  <Button variant="contained" cozzzlor="primary" onClick={handlerButton}>
                    {textButton}
                  </Button>,
                  <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={() => !block && clickOutsideToHide()}
                  >
                    <CloseIcon />
                  </IconButton>
                ]
              : [
                  <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={() => !block && clickOutsideToHide()}
                  >
                    <CloseIcon />
                  </IconButton>
                ]
          }
          message={<span id="message-id">{message}</span>}
        ></SnackbarContent>
      </Snackbar>,
      Portal
    )
  }

const useSnackbar = () => {
  const [isShow, setIsShow] = useState(false)
  const snackbar = useRef(null)
  const [type, setType] = useState(null)
  const [message, setMessage] = useState(null)
  const [block, setBlock] = useState(false)
  const [button, setButton] = useState(false)
  const [handler, setHandler] = useState(() => {})
  const [textButton, setTextButton] = useState('')
  const [duration, setDuration] = useState('')

  useEffect(() => {
    if (document.getElementById('principal-snackbar') === null) {
      const container = document.createElement('div')
      container.setAttribute('id', 'principal-snackbar')
      document.body.appendChild(container)
    }
    snackbar.current = document.getElementById('principal-snackbar')
  }, [])

  const open = ({
    message,
    type,
    block = false,
    button = false,
    buttonFunction,
    textButton = 'Confirm',
    duration = 6000
  }) => {
    setBlock(block)
    setIsShow(true)
    setType(type)
    setMessage(message)
    setButton(button)
    setHandler(() => buttonFunction)
    setTextButton(textButton)
    setDuration(duration)
  }
  const close = () => {
    setIsShow(false)
  }

  const Modal = useCallback(
    customsnack(
      isShow,
      snackbar.current,
      close,
      message,
      type,
      block,
      button,
      handler,
      textButton,
      duration
    ),
    [isShow, message, type]
  )

  return { Snackbar: Modal, isShow, openSnackbar: open, closeSnackbar: close }
}

export default useSnackbar
