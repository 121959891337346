import React from 'react'

const DispatchContext = React.createContext({})
const { Provider, Consumer } = DispatchContext

export default function DispatchProvider(props) {
  return <Provider value={props.value}>{props.children}</Provider>
}

export { DispatchContext, DispatchProvider, Consumer as DispatchConsumer }
