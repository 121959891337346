export const imagesByDataset = /* GraphQL */ `
  query ImagesByDataset(
    $imageDatasetId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    imagesByDataset(
      imageDatasetId: $imageDatasetId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        key
        url
        status
        createdAt
        imageDatasetId
        width
        height
        updatedAt
        embeddings {
          samH
        }
        generateEmbeddings {
          samH
        }
      }
      nextToken
    }
  }
`
