import React from 'react'
import { Card, ProgressTitle, TitleContent } from './StyledComponents'
import DefaultTable from '../../../../../components/generics/tables/DefaultTable'

const LabelsMetricsTrable = ({ labelFilter, project, theme }) => {
  return (
    <Card elevation={2}>
      <TitleContent>
        <ProgressTitle variant="h6">
          Tags per {labelFilter === 'saved' ? 'labeler' : 'reviewer'}
        </ProgressTitle>
      </TitleContent>
      <DefaultTable
        clickeable={false}
        headColor={theme.palette.mode === 'dark' ? theme.palette.background.default : null}
        rowsPerPageOptions={[4, 8, 12]}
        keys={['key', 'doc_count']}
        columns={[`${labelFilter === 'reviewed' ? 'Reviewer' : 'Labeler'}`, 'Labels Count']}
        data={
          labelFilter === 'reviewed'
            ? project.labelesStats.table.reviewed
            : labelFilter === 'saved'
            ? project.labelesStats.table.labeler
            : project.labelesStats.table.all
        }
      />
    </Card>
  )
}

export default LabelsMetricsTrable
