import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { styled } from '@mui/material/styles'
import { Link, useNavigate } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import Grid from '@mui/material/Grid'

import LoaderButton from '../../components/LoaderButton'
import FieldGroup from '../../components/forms/FieldGroup'
import { DivContainer, GridHeader, GridPanel, Img } from './components/StyledComponents'
import { useSnackbar } from '../../components/snackbar/context/SnackbarContext'

const PText = styled('div')({
  fontWeight: 'bold',
  padding: 15,
  textAlign: 'center'
})

function ForgotPass(props) {
  const [email, setEmail] = useState({ state: null, value: '' })
  const [password, setPassword] = useState({ state: null, value: '' })
  const [otherPassword, setOtherPassword] = useState({ state: null, value: '' })
  const [code, setCode] = useState({ state: null, value: '' })
  const [withCode, setWithCode] = useState(false)
  const form = useRef(null)

  const { showSnackbar } = useSnackbar()

  const actualUrl = window.location.href

  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
    if (actualUrl.includes('/forgotpass'))
      document.getElementsByClassName('App')[0].style.overflow = 'hidden'
  }, [])

  const newPass = (email, pass, code) => {
    Auth.forgotPasswordSubmit(email, code, pass)
      .then(() => {
        // at this time the user is logged in if no MFA required
        showSnackbar({
          message: 'Password changed.',
          type: 'success'
        })
        navigate('/projects')
      })
      .catch(() => {
        setCode(...code, { state: 'error' })
        showSnackbar({
          message: 'The CODE is not correct.',
          type: 'error'
        })
      })
  }

  const onSubmitNewPass = (e) => {
    e.preventDefault()

    if (password.value !== otherPassword.value) {
      showSnackbar({
        message: `Passwords don't match.`,
        type: 'error'
      })
      setOtherPassword(...otherPassword, { state: 'error' })
      return
    }

    newPass(email.value, password.value, code.value)
  }

  const handleChange = (e) => {
    switch (e.target.name) {
      case 'code':
        setCode({ value: e.target.value, state: null })
        break
      case 'password':
        setPassword({ value: e.target.value, state: null })
        break
      case 'otherPassword':
        setOtherPassword({ value: e.target.value, state: null })
        break
      case 'email':
        setEmail({ value: e.target.value, state: null })
        break
      default:
        break
    }
  }

  const setWCode = () => {
    // eslint-disable-next-line no-use-before-define
    const email = email.value
    if (!email || email.length <= 0) {
      setEmail(...email, { state: 'error' })
      showSnackbar({
        message: `Please set your email`,
        type: 'error'
      })
      return
    }
    setWithCode(true)
  }

  const onSubmit = (e) => {
    e.preventDefault()

    Auth.forgotPassword(email.value)
      .then(() => {
        setWithCode(true)
        showSnackbar({
          message: `Check your email to find the verification Code.`,
          type: 'info'
        })
      })
      .catch(() => {
        showSnackbar({
          message: `Error: email not found.`,
          type: 'error'
        })
      })
  }

  return (
    <DivContainer>
      <Grid container spacing={2}>
        <Grid item xs={1} md={2} />
        <GridHeader item lg={3} md={4} xs={9}>
          <h4>Reset Password</h4>
          <Img style={{ maxWidth: '220px' }} src={props.logo} />
        </GridHeader>
        <Grid item lg={6} md={5} xs={2} />
        <Grid item md={2} xs={1} />
        <GridPanel item lg={3} md={4} xs={9}>
          {withCode ? (
            <>
              <form
                id={props.submit + '_form'}
                style={{ marginBottom: '1rem' }}
                method="POST"
                onSubmit={onSubmitNewPass}
                ref={form}
              >
                <FieldGroup
                  id="code"
                  label="Code"
                  name="code"
                  state={code.state}
                  type="number"
                  placeholder="Enter Code"
                  value={code.value}
                  onChange={handleChange}
                />
                <FieldGroup
                  id="password"
                  label="New Password"
                  name="password"
                  state={password.state}
                  type="password"
                  placeholder="Enter New Password"
                  value={password.value}
                  onChange={handleChange}
                />
                <FieldGroup
                  id="otherPassword"
                  label="Verify New Password"
                  name="otherPassword"
                  state={otherPassword.state}
                  type="password"
                  placeholder="Enter New Password Again"
                  value={otherPassword.value}
                  onChange={handleChange}
                />
                <LoaderButton
                  fullWidth
                  color="primary"
                  size="large"
                  type="submit"
                  text="Change Password"
                  loadingText="Logging in..."
                />
              </form>
              <PText>
                Don&apos;t have an account?{' '}
                <Link to="/signup">
                  <span className="blue-text">Sign up</span>
                </Link>
              </PText>
            </>
          ) : (
            <>
              <form
                id={props.submit + '_form'}
                method="POST"
                onSubmit={onSubmit}
                ref={form}
                style={{ marginBottom: '1rem' }}
              >
                <FieldGroup
                  id="email"
                  label="Email"
                  name="email"
                  state={email.state}
                  type="email"
                  placeholder="Enter email"
                  value={email.value}
                  onChange={handleChange}
                />
                <p style={{ textAlign: 'left', marginBottom: '1rem' }}>
                  We will send you an email with a code to recover your password.
                </p>
                <LoaderButton
                  fullWidth
                  color="primary"
                  size="large"
                  type="submit"
                  text="Send"
                  loadingText="Logging in..."
                />
              </form>
              <a onClick={setWCode}>
                <span className="blue-text">Already have a code? </span>
              </a>
              <pText>
                Don&apos;t have an account?{' '}
                <Link to="/signup">
                  <span className="blue-text">Sign up</span>
                </Link>
              </pText>
            </>
          )}
        </GridPanel>
      </Grid>
    </DivContainer>
  )
}

const mapStateToProps = (state) => ({
  logo: state.ui.logo
})

export default connect(mapStateToProps, null)(ForgotPass)
