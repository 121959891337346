import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import * as Sentry from '@sentry/browser'
import LoaderButton from '../../../../components/LoaderButton'
import { EyeAdornment } from '../../../../components/forms/EyeAdornment'
import { FormControl, FormLabel, OutlinedInput } from '@mui/material'
import { login } from '../../../../services/aws'
import { useSnackbar } from '../../../../components/snackbar/context/SnackbarContext'

function LoginForm(props) {
  const [isLoading, setIsLoading] = useState(false)
  const [loginData, setLoginData] = useState({
    email: { value: '', state: null },
    password: { value: '', state: null }
  })
  const [showPassword, setShowPassword] = useState({
    password: false
  })
  const { showSnackbar } = useSnackbar()

  useEffect(() => {
    if (props.location && props.location.state && props.location.state.email)
      setLoginData({ ...loginData, email: props.location.state.email, state: null })
  }, [])

  const handleChange = (event) => {
    const { name, value } = event.target
    setLoginData({ ...loginData, [name]: { value, state: null } })
  }

  const onSubmit = (event) => {
    event.preventDefault()
    setIsLoading(true)
    let error = false
    if (!loginData.password.value) {
      setLoginData({ ...loginData, password: { ...loginData.password, state: 'error' } })
      error = true
    }
    if (!loginData.email.value) {
      setLoginData({ ...loginData, email: { ...loginData.email, state: 'error' } })
      error = true
    }
    if (error) {
      setIsLoading(false)
      return
    }
    login(loginData.email.value, loginData.password.value).catch((error) => {
      Sentry.captureMessage({ error, email: loginData.email.value })
      const { message } = error
      setIsLoading(false)
      showSnackbar({
        message: `Error logging in (${message})`,
        type: 'error'
      })
    })
  }

  const handleShowPassword = () => {
    setShowPassword({ password: !showPassword.password })
  }
  return (
    <form id={props.submit + '_form'} method="POST" onSubmit={(event) => onSubmit(event)}>
      <FormControl variant="standard" fullWidth size="small">
        <FormLabel style={{ fontWeight: 600 }}>Email</FormLabel>
        <OutlinedInput
          error={loginData.email.state === 'error'}
          margin="dense"
          name="email"
          id="outlined-basic"
          onChange={(event) => handleChange(event)}
          type="email"
          value={loginData.email.value}
          variant="outlined"
        />
      </FormControl>
      <FormControl fullWidth margin="normal" size="small" variant="outlined">
        <FormLabel style={{ fontWeight: 600 }}>Password</FormLabel>
        <OutlinedInput
          endAdornment={
            <EyeAdornment handleShow={handleShowPassword} name="password" show={showPassword} />
          }
          error={loginData.password.state === 'error'}
          margin="dense"
          name="password"
          id="outlined-adornment-password"
          onChange={(event) => handleChange(event)}
          type={showPassword.password ? 'text' : 'password'}
          value={loginData.password.value}
        />
      </FormControl>
      <LoaderButton
        fullWidth
        color="primary"
        size="large"
        isLoading={isLoading}
        type="submit"
        text={props.submit}
        loadingText="Logging in..."
      />
      <p style={{ textAlign: 'right', textDecoration: 'none', marginTop: 6 }}>
        <Link to="/forgotpass">
          <span className="blue-text">Forgot Password?</span>
        </Link>
      </p>
    </form>
  )
}

LoginForm.propTypes = {
  props: PropTypes.object
}

export default LoginForm
