import { handleActions } from 'redux-actions'

const defaultState = {
  stateModalListProjects: false,
  stateModalWelcome: false
}

const reducer = handleActions(
  {
    MODALS: {
      SET_MODAL_LIST_PROJECTS: (state, { payload: { status } }) => {
        return { ...state, stateModalListProjects: status }
      },
      SET_MODAL_WELCOME: (state, { payload: { status } }) => {
        return { ...state, stateModalWelcome: status }
      }
    }
  },
  defaultState
)

export default reducer
