import { useReducer } from 'react'
import produce from 'immer'

const lineState = {
  tags: [],
  selectedTag: -1,
  redraw: 0
}

export const reducer = produce((state, action) => {
  switch (action.type) {
    case 'setLoadingTags':
      if (action.payload) {
        state.tags = []
        state.selectedTag = -1
        state.redraw = 0
      }
      break
    case 'setLines':
      state.tags = action.payload
      state.redraw = state.redraw + 1
      break
    case 'addLine':
      state.tags.push(action.payload)
      state.redraw = state.redraw + 1
      break
    case 'setLine':
      state.tags[action.payload.index] = action.payload.line
      state.redraw = state.redraw + 1
      break
    case 'hideLine':
      state.tags[action.payload.index] = {
        ...state.tags[action.payload.index],
        show: !state.tags[action.payload.index].show
      }
      state.redraw = state.redraw + 1
      break
    case 'hideAll':
      state.tags = state.tags.map((line) => ({ ...line, show: action.payload }))
      state.redraw = state.redraw + 1
      break
    case 'hoverLine':
      state.tags[action.payload.index] = {
        ...state.tags[action.payload.index],
        hover: action.payload.hover
      }
      state.redraw = state.redraw + 1
      break
    case 'deleteLine':
      state.tags = state.tags.filter((_, index) => index !== action.payload.index)
      state.redraw = state.redraw + 1
      break
  }
}, lineState)

export function LineReducer() {
  const [state, dispatch] = useReducer(reducer, lineState)
  return { lineState: state, lineDispatch: dispatch }
}
