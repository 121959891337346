import { API, graphqlOperation } from 'aws-amplify'

import * as qweries from '../../graphql/qweries'

export const listTags = (limit, nextToken, id) =>
  API.graphql(
    graphqlOperation(qweries, {
      limit,
      nextToken,
      filter: { access: { wildcard: '*' }, projectUserId: { ne: id } },
      sort: { field: 'createdAt', direction: 'desc' }
    })
  )

export const getImage = (id) =>
  API.graphql(
    graphqlOperation(qweries.getTag, {
      id
    })
  )
