/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput) {
    onCreateUser(filter: $filter) {
      id
      name
      email
      company
      termsAccepted
      status
      owner
      paid
      createdAt
      datasets {
        items {
          id
          name
          description
          count
          status
          createdAt
          updatedAt
          datasetUserId
        }
        nextToken
      }
      teams {
        items {
          id
          role
          createdAt
          updatedAt
          memberTeamId
          memberUserId
        }
        nextToken
      }
      projects {
        items {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
    onUpdateUser(filter: $filter) {
      id
      name
      email
      company
      termsAccepted
      status
      owner
      paid
      createdAt
      datasets {
        items {
          id
          name
          description
          count
          status
          createdAt
          updatedAt
          datasetUserId
        }
        nextToken
      }
      teams {
        items {
          id
          role
          createdAt
          updatedAt
          memberTeamId
          memberUserId
        }
        nextToken
      }
      projects {
        items {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
    onDeleteUser(filter: $filter) {
      id
      name
      email
      company
      termsAccepted
      status
      owner
      paid
      createdAt
      datasets {
        items {
          id
          name
          description
          count
          status
          createdAt
          updatedAt
          datasetUserId
        }
        nextToken
      }
      teams {
        items {
          id
          role
          createdAt
          updatedAt
          memberTeamId
          memberUserId
        }
        nextToken
      }
      projects {
        items {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const onCreateDataset = /* GraphQL */ `
  subscription OnCreateDataset($filter: ModelSubscriptionDatasetFilterInput) {
    onCreateDataset(filter: $filter) {
      id
      name
      description
      count
      status
      createdAt
      updatedAt
      datasetUserId
      user {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      images {
        items {
          id
          key
          url
          status
          createdAt
          imageDatasetId
          width
          height
          updatedAt
        }
        nextToken
      }
      projects {
        items {
          id
          projectDataProjectId
          projectDataDatasetId
          updatedAt
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateDataset = /* GraphQL */ `
  subscription OnUpdateDataset($filter: ModelSubscriptionDatasetFilterInput) {
    onUpdateDataset(filter: $filter) {
      id
      name
      description
      count
      status
      createdAt
      updatedAt
      datasetUserId
      user {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      images {
        items {
          id
          key
          url
          status
          createdAt
          imageDatasetId
          width
          height
          updatedAt
        }
        nextToken
      }
      projects {
        items {
          id
          projectDataProjectId
          projectDataDatasetId
          updatedAt
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteDataset = /* GraphQL */ `
  subscription OnDeleteDataset($filter: ModelSubscriptionDatasetFilterInput) {
    onDeleteDataset(filter: $filter) {
      id
      name
      description
      count
      status
      createdAt
      updatedAt
      datasetUserId
      user {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      images {
        items {
          id
          key
          url
          status
          createdAt
          imageDatasetId
          width
          height
          updatedAt
        }
        nextToken
      }
      projects {
        items {
          id
          projectDataProjectId
          projectDataDatasetId
          updatedAt
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateImage = /* GraphQL */ `
  subscription OnCreateImage($filter: ModelSubscriptionImageFilterInput) {
    onCreateImage(filter: $filter) {
      id
      key
      url
      status
      createdAt
      imageDatasetId
      dataset {
        id
        name
        description
        count
        status
        createdAt
        updatedAt
        datasetUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        images {
          nextToken
        }
        projects {
          nextToken
        }
      }
      width
      height
      thumbnail {
        key
        width
        height
      }
      generateEmbeddings {
        samH
        samB
        samL
      }
      embeddings {
        samH
        samB
        samL
      }
      updatedAt
    }
  }
`;
export const onUpdateImage = /* GraphQL */ `
  subscription OnUpdateImage($filter: ModelSubscriptionImageFilterInput) {
    onUpdateImage(filter: $filter) {
      id
      key
      url
      status
      createdAt
      imageDatasetId
      dataset {
        id
        name
        description
        count
        status
        createdAt
        updatedAt
        datasetUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        images {
          nextToken
        }
        projects {
          nextToken
        }
      }
      width
      height
      thumbnail {
        key
        width
        height
      }
      generateEmbeddings {
        samH
        samB
        samL
      }
      embeddings {
        samH
        samB
        samL
      }
      updatedAt
    }
  }
`;
export const onDeleteImage = /* GraphQL */ `
  subscription OnDeleteImage($filter: ModelSubscriptionImageFilterInput) {
    onDeleteImage(filter: $filter) {
      id
      key
      url
      status
      createdAt
      imageDatasetId
      dataset {
        id
        name
        description
        count
        status
        createdAt
        updatedAt
        datasetUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        images {
          nextToken
        }
        projects {
          nextToken
        }
      }
      width
      height
      thumbnail {
        key
        width
        height
      }
      generateEmbeddings {
        samH
        samB
        samL
      }
      embeddings {
        samH
        samB
        samL
      }
      updatedAt
    }
  }
`;
export const onCreateTeam = /* GraphQL */ `
  subscription OnCreateTeam(
    $filter: ModelSubscriptionTeamFilterInput
    $owner: String
  ) {
    onCreateTeam(filter: $filter, owner: $owner) {
      id
      name
      createdAt
      updatedAt
      teamUserId
      user {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      status
      owner
      access
      members {
        items {
          id
          role
          createdAt
          updatedAt
          memberTeamId
          memberUserId
        }
        nextToken
      }
      projects {
        items {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        nextToken
      }
    }
  }
`;
export const onUpdateTeam = /* GraphQL */ `
  subscription OnUpdateTeam(
    $filter: ModelSubscriptionTeamFilterInput
    $owner: String
  ) {
    onUpdateTeam(filter: $filter, owner: $owner) {
      id
      name
      createdAt
      updatedAt
      teamUserId
      user {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      status
      owner
      access
      members {
        items {
          id
          role
          createdAt
          updatedAt
          memberTeamId
          memberUserId
        }
        nextToken
      }
      projects {
        items {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        nextToken
      }
    }
  }
`;
export const onDeleteTeam = /* GraphQL */ `
  subscription OnDeleteTeam(
    $filter: ModelSubscriptionTeamFilterInput
    $owner: String
  ) {
    onDeleteTeam(filter: $filter, owner: $owner) {
      id
      name
      createdAt
      updatedAt
      teamUserId
      user {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      status
      owner
      access
      members {
        items {
          id
          role
          createdAt
          updatedAt
          memberTeamId
          memberUserId
        }
        nextToken
      }
      projects {
        items {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        nextToken
      }
    }
  }
`;
export const onCreateMember = /* GraphQL */ `
  subscription OnCreateMember($filter: ModelSubscriptionMemberFilterInput) {
    onCreateMember(filter: $filter) {
      id
      role
      createdAt
      updatedAt
      memberTeamId
      team {
        id
        name
        createdAt
        updatedAt
        teamUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        status
        owner
        access
        members {
          nextToken
        }
        projects {
          nextToken
        }
      }
      memberUserId
      user {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
    }
  }
`;
export const onUpdateMember = /* GraphQL */ `
  subscription OnUpdateMember($filter: ModelSubscriptionMemberFilterInput) {
    onUpdateMember(filter: $filter) {
      id
      role
      createdAt
      updatedAt
      memberTeamId
      team {
        id
        name
        createdAt
        updatedAt
        teamUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        status
        owner
        access
        members {
          nextToken
        }
        projects {
          nextToken
        }
      }
      memberUserId
      user {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
    }
  }
`;
export const onDeleteMember = /* GraphQL */ `
  subscription OnDeleteMember($filter: ModelSubscriptionMemberFilterInput) {
    onDeleteMember(filter: $filter) {
      id
      role
      createdAt
      updatedAt
      memberTeamId
      team {
        id
        name
        createdAt
        updatedAt
        teamUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        status
        owner
        access
        members {
          nextToken
        }
        projects {
          nextToken
        }
      }
      memberUserId
      user {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
    }
  }
`;
export const onCreateProject = /* GraphQL */ `
  subscription OnCreateProject(
    $filter: ModelSubscriptionProjectFilterInput
    $owner: String
  ) {
    onCreateProject(filter: $filter, owner: $owner) {
      id
      name
      categories {
        color
        name
        type
        parent
        options
        required
        classType
        multiSelect
      }
      categoriesCount {
        name
        count
        countSaved
        countReviewed
      }
      createdAt
      updatedAt
      access
      owner
      status
      tagsCount
      datasetSort
      reviewType
      tagsSaveCount
      tagsReviewCount
      imagesSavedCount
      imagesReviewedCount
      projectTeamId
      team {
        id
        name
        createdAt
        updatedAt
        teamUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        status
        owner
        access
        members {
          nextToken
        }
        projects {
          nextToken
        }
      }
      projectUserId
      user {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      tags {
        items {
          id
          height
          width
          key
          done
          skiped
          accepted
          count
          time
          labelerTime
          reviewerTime
          timestamp
          status
          createdAt
          updatedAt
          assignedAt
          reviewAssignedAt
          savedAt
          reviewedAt
          skippedAt
          tagLabelerId
          tagReviewerId
          tagImageId
          tagProjectId
        }
        nextToken
      }
      datasets {
        items {
          id
          projectDataProjectId
          projectDataDatasetId
          updatedAt
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateProject = /* GraphQL */ `
  subscription OnUpdateProject(
    $filter: ModelSubscriptionProjectFilterInput
    $owner: String
  ) {
    onUpdateProject(filter: $filter, owner: $owner) {
      id
      name
      categories {
        color
        name
        type
        parent
        options
        required
        classType
        multiSelect
      }
      categoriesCount {
        name
        count
        countSaved
        countReviewed
      }
      createdAt
      updatedAt
      access
      owner
      status
      tagsCount
      datasetSort
      reviewType
      tagsSaveCount
      tagsReviewCount
      imagesSavedCount
      imagesReviewedCount
      projectTeamId
      team {
        id
        name
        createdAt
        updatedAt
        teamUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        status
        owner
        access
        members {
          nextToken
        }
        projects {
          nextToken
        }
      }
      projectUserId
      user {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      tags {
        items {
          id
          height
          width
          key
          done
          skiped
          accepted
          count
          time
          labelerTime
          reviewerTime
          timestamp
          status
          createdAt
          updatedAt
          assignedAt
          reviewAssignedAt
          savedAt
          reviewedAt
          skippedAt
          tagLabelerId
          tagReviewerId
          tagImageId
          tagProjectId
        }
        nextToken
      }
      datasets {
        items {
          id
          projectDataProjectId
          projectDataDatasetId
          updatedAt
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteProject = /* GraphQL */ `
  subscription OnDeleteProject(
    $filter: ModelSubscriptionProjectFilterInput
    $owner: String
  ) {
    onDeleteProject(filter: $filter, owner: $owner) {
      id
      name
      categories {
        color
        name
        type
        parent
        options
        required
        classType
        multiSelect
      }
      categoriesCount {
        name
        count
        countSaved
        countReviewed
      }
      createdAt
      updatedAt
      access
      owner
      status
      tagsCount
      datasetSort
      reviewType
      tagsSaveCount
      tagsReviewCount
      imagesSavedCount
      imagesReviewedCount
      projectTeamId
      team {
        id
        name
        createdAt
        updatedAt
        teamUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        status
        owner
        access
        members {
          nextToken
        }
        projects {
          nextToken
        }
      }
      projectUserId
      user {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      tags {
        items {
          id
          height
          width
          key
          done
          skiped
          accepted
          count
          time
          labelerTime
          reviewerTime
          timestamp
          status
          createdAt
          updatedAt
          assignedAt
          reviewAssignedAt
          savedAt
          reviewedAt
          skippedAt
          tagLabelerId
          tagReviewerId
          tagImageId
          tagProjectId
        }
        nextToken
      }
      datasets {
        items {
          id
          projectDataProjectId
          projectDataDatasetId
          updatedAt
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateProjectData = /* GraphQL */ `
  subscription OnCreateProjectData(
    $filter: ModelSubscriptionProjectDataFilterInput
  ) {
    onCreateProjectData(filter: $filter) {
      id
      projectDataProjectId
      project {
        id
        name
        categories {
          color
          name
          type
          parent
          options
          required
          classType
          multiSelect
        }
        categoriesCount {
          name
          count
          countSaved
          countReviewed
        }
        createdAt
        updatedAt
        access
        owner
        status
        tagsCount
        datasetSort
        reviewType
        tagsSaveCount
        tagsReviewCount
        imagesSavedCount
        imagesReviewedCount
        projectTeamId
        team {
          id
          name
          createdAt
          updatedAt
          teamUserId
          status
          owner
          access
        }
        projectUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        datasets {
          nextToken
        }
      }
      projectDataDatasetId
      dataset {
        id
        name
        description
        count
        status
        createdAt
        updatedAt
        datasetUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        images {
          nextToken
        }
        projects {
          nextToken
        }
      }
      updatedAt
      createdAt
    }
  }
`;
export const onUpdateProjectData = /* GraphQL */ `
  subscription OnUpdateProjectData(
    $filter: ModelSubscriptionProjectDataFilterInput
  ) {
    onUpdateProjectData(filter: $filter) {
      id
      projectDataProjectId
      project {
        id
        name
        categories {
          color
          name
          type
          parent
          options
          required
          classType
          multiSelect
        }
        categoriesCount {
          name
          count
          countSaved
          countReviewed
        }
        createdAt
        updatedAt
        access
        owner
        status
        tagsCount
        datasetSort
        reviewType
        tagsSaveCount
        tagsReviewCount
        imagesSavedCount
        imagesReviewedCount
        projectTeamId
        team {
          id
          name
          createdAt
          updatedAt
          teamUserId
          status
          owner
          access
        }
        projectUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        datasets {
          nextToken
        }
      }
      projectDataDatasetId
      dataset {
        id
        name
        description
        count
        status
        createdAt
        updatedAt
        datasetUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        images {
          nextToken
        }
        projects {
          nextToken
        }
      }
      updatedAt
      createdAt
    }
  }
`;
export const onDeleteProjectData = /* GraphQL */ `
  subscription OnDeleteProjectData(
    $filter: ModelSubscriptionProjectDataFilterInput
  ) {
    onDeleteProjectData(filter: $filter) {
      id
      projectDataProjectId
      project {
        id
        name
        categories {
          color
          name
          type
          parent
          options
          required
          classType
          multiSelect
        }
        categoriesCount {
          name
          count
          countSaved
          countReviewed
        }
        createdAt
        updatedAt
        access
        owner
        status
        tagsCount
        datasetSort
        reviewType
        tagsSaveCount
        tagsReviewCount
        imagesSavedCount
        imagesReviewedCount
        projectTeamId
        team {
          id
          name
          createdAt
          updatedAt
          teamUserId
          status
          owner
          access
        }
        projectUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        datasets {
          nextToken
        }
      }
      projectDataDatasetId
      dataset {
        id
        name
        description
        count
        status
        createdAt
        updatedAt
        datasetUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        images {
          nextToken
        }
        projects {
          nextToken
        }
      }
      updatedAt
      createdAt
    }
  }
`;
export const onCreateTag = /* GraphQL */ `
  subscription OnCreateTag($filter: ModelSubscriptionTagFilterInput) {
    onCreateTag(filter: $filter) {
      id
      height
      width
      key
      done
      skiped
      accepted
      states {
        items {
          id
          createdAt
          stage
          action
          tagSateTagId
          toBlameId
          role
          updatedAt
        }
        nextToken
      }
      annotations {
        circles {
          id
          childs
          name
          color
          type
          text
        }
        lines {
          id
          childs
          name
          color
          type
          text
        }
        points {
          id
          childs
          name
          color
          type
          text
        }
        polys {
          id
          childs
          name
          color
          type
          text
        }
        rects {
          id
          childs
          name
          color
          type
          pos
          parent
          text
        }
        classification {
          name
          options
          selected
          values
          required
          classType
          multiSelect
        }
      }
      categoriesCount {
        name
        count
      }
      count
      time
      labelerTime
      reviewerTime
      timestamp
      status
      createdAt
      updatedAt
      assignedAt
      reviewAssignedAt
      savedAt
      reviewedAt
      skippedAt
      tagLabelerId
      labeler {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      tagReviewerId
      reviewer {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      tagImageId
      image {
        id
        key
        url
        status
        createdAt
        imageDatasetId
        dataset {
          id
          name
          description
          count
          status
          createdAt
          updatedAt
          datasetUserId
        }
        width
        height
        thumbnail {
          key
          width
          height
        }
        generateEmbeddings {
          samH
          samB
          samL
        }
        embeddings {
          samH
          samB
          samL
        }
        updatedAt
      }
      tagProjectId
      project {
        id
        name
        categories {
          color
          name
          type
          parent
          options
          required
          classType
          multiSelect
        }
        categoriesCount {
          name
          count
          countSaved
          countReviewed
        }
        createdAt
        updatedAt
        access
        owner
        status
        tagsCount
        datasetSort
        reviewType
        tagsSaveCount
        tagsReviewCount
        imagesSavedCount
        imagesReviewedCount
        projectTeamId
        team {
          id
          name
          createdAt
          updatedAt
          teamUserId
          status
          owner
          access
        }
        projectUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        datasets {
          nextToken
        }
      }
      comments {
        items {
          id
          createdAt
          closed
          recordType
          Type
          commentTagId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateTag = /* GraphQL */ `
  subscription OnUpdateTag($filter: ModelSubscriptionTagFilterInput) {
    onUpdateTag(filter: $filter) {
      id
      height
      width
      key
      done
      skiped
      accepted
      states {
        items {
          id
          createdAt
          stage
          action
          tagSateTagId
          toBlameId
          role
          updatedAt
        }
        nextToken
      }
      annotations {
        circles {
          id
          childs
          name
          color
          type
          text
        }
        lines {
          id
          childs
          name
          color
          type
          text
        }
        points {
          id
          childs
          name
          color
          type
          text
        }
        polys {
          id
          childs
          name
          color
          type
          text
        }
        rects {
          id
          childs
          name
          color
          type
          pos
          parent
          text
        }
        classification {
          name
          options
          selected
          values
          required
          classType
          multiSelect
        }
      }
      categoriesCount {
        name
        count
      }
      count
      time
      labelerTime
      reviewerTime
      timestamp
      status
      createdAt
      updatedAt
      assignedAt
      reviewAssignedAt
      savedAt
      reviewedAt
      skippedAt
      tagLabelerId
      labeler {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      tagReviewerId
      reviewer {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      tagImageId
      image {
        id
        key
        url
        status
        createdAt
        imageDatasetId
        dataset {
          id
          name
          description
          count
          status
          createdAt
          updatedAt
          datasetUserId
        }
        width
        height
        thumbnail {
          key
          width
          height
        }
        generateEmbeddings {
          samH
          samB
          samL
        }
        embeddings {
          samH
          samB
          samL
        }
        updatedAt
      }
      tagProjectId
      project {
        id
        name
        categories {
          color
          name
          type
          parent
          options
          required
          classType
          multiSelect
        }
        categoriesCount {
          name
          count
          countSaved
          countReviewed
        }
        createdAt
        updatedAt
        access
        owner
        status
        tagsCount
        datasetSort
        reviewType
        tagsSaveCount
        tagsReviewCount
        imagesSavedCount
        imagesReviewedCount
        projectTeamId
        team {
          id
          name
          createdAt
          updatedAt
          teamUserId
          status
          owner
          access
        }
        projectUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        datasets {
          nextToken
        }
      }
      comments {
        items {
          id
          createdAt
          closed
          recordType
          Type
          commentTagId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteTag = /* GraphQL */ `
  subscription OnDeleteTag($filter: ModelSubscriptionTagFilterInput) {
    onDeleteTag(filter: $filter) {
      id
      height
      width
      key
      done
      skiped
      accepted
      states {
        items {
          id
          createdAt
          stage
          action
          tagSateTagId
          toBlameId
          role
          updatedAt
        }
        nextToken
      }
      annotations {
        circles {
          id
          childs
          name
          color
          type
          text
        }
        lines {
          id
          childs
          name
          color
          type
          text
        }
        points {
          id
          childs
          name
          color
          type
          text
        }
        polys {
          id
          childs
          name
          color
          type
          text
        }
        rects {
          id
          childs
          name
          color
          type
          pos
          parent
          text
        }
        classification {
          name
          options
          selected
          values
          required
          classType
          multiSelect
        }
      }
      categoriesCount {
        name
        count
      }
      count
      time
      labelerTime
      reviewerTime
      timestamp
      status
      createdAt
      updatedAt
      assignedAt
      reviewAssignedAt
      savedAt
      reviewedAt
      skippedAt
      tagLabelerId
      labeler {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      tagReviewerId
      reviewer {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      tagImageId
      image {
        id
        key
        url
        status
        createdAt
        imageDatasetId
        dataset {
          id
          name
          description
          count
          status
          createdAt
          updatedAt
          datasetUserId
        }
        width
        height
        thumbnail {
          key
          width
          height
        }
        generateEmbeddings {
          samH
          samB
          samL
        }
        embeddings {
          samH
          samB
          samL
        }
        updatedAt
      }
      tagProjectId
      project {
        id
        name
        categories {
          color
          name
          type
          parent
          options
          required
          classType
          multiSelect
        }
        categoriesCount {
          name
          count
          countSaved
          countReviewed
        }
        createdAt
        updatedAt
        access
        owner
        status
        tagsCount
        datasetSort
        reviewType
        tagsSaveCount
        tagsReviewCount
        imagesSavedCount
        imagesReviewedCount
        projectTeamId
        team {
          id
          name
          createdAt
          updatedAt
          teamUserId
          status
          owner
          access
        }
        projectUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        datasets {
          nextToken
        }
      }
      comments {
        items {
          id
          createdAt
          closed
          recordType
          Type
          commentTagId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateRequestJson = /* GraphQL */ `
  subscription OnCreateRequestJson(
    $filter: ModelSubscriptionRequestJsonFilterInput
    $owner: String
  ) {
    onCreateRequestJson(filter: $filter, owner: $owner) {
      id
      type
      owner
      createdAt
      format
      tagsCount
      imagesCount
      status
      key
      requestProjectId
      project {
        id
        name
        categories {
          color
          name
          type
          parent
          options
          required
          classType
          multiSelect
        }
        categoriesCount {
          name
          count
          countSaved
          countReviewed
        }
        createdAt
        updatedAt
        access
        owner
        status
        tagsCount
        datasetSort
        reviewType
        tagsSaveCount
        tagsReviewCount
        imagesSavedCount
        imagesReviewedCount
        projectTeamId
        team {
          id
          name
          createdAt
          updatedAt
          teamUserId
          status
          owner
          access
        }
        projectUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        datasets {
          nextToken
        }
      }
      updatedAt
    }
  }
`;
export const onUpdateRequestJson = /* GraphQL */ `
  subscription OnUpdateRequestJson(
    $filter: ModelSubscriptionRequestJsonFilterInput
    $owner: String
  ) {
    onUpdateRequestJson(filter: $filter, owner: $owner) {
      id
      type
      owner
      createdAt
      format
      tagsCount
      imagesCount
      status
      key
      requestProjectId
      project {
        id
        name
        categories {
          color
          name
          type
          parent
          options
          required
          classType
          multiSelect
        }
        categoriesCount {
          name
          count
          countSaved
          countReviewed
        }
        createdAt
        updatedAt
        access
        owner
        status
        tagsCount
        datasetSort
        reviewType
        tagsSaveCount
        tagsReviewCount
        imagesSavedCount
        imagesReviewedCount
        projectTeamId
        team {
          id
          name
          createdAt
          updatedAt
          teamUserId
          status
          owner
          access
        }
        projectUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        datasets {
          nextToken
        }
      }
      updatedAt
    }
  }
`;
export const onDeleteRequestJson = /* GraphQL */ `
  subscription OnDeleteRequestJson(
    $filter: ModelSubscriptionRequestJsonFilterInput
    $owner: String
  ) {
    onDeleteRequestJson(filter: $filter, owner: $owner) {
      id
      type
      owner
      createdAt
      format
      tagsCount
      imagesCount
      status
      key
      requestProjectId
      project {
        id
        name
        categories {
          color
          name
          type
          parent
          options
          required
          classType
          multiSelect
        }
        categoriesCount {
          name
          count
          countSaved
          countReviewed
        }
        createdAt
        updatedAt
        access
        owner
        status
        tagsCount
        datasetSort
        reviewType
        tagsSaveCount
        tagsReviewCount
        imagesSavedCount
        imagesReviewedCount
        projectTeamId
        team {
          id
          name
          createdAt
          updatedAt
          teamUserId
          status
          owner
          access
        }
        projectUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        datasets {
          nextToken
        }
      }
      updatedAt
    }
  }
`;
export const onCreateComment = /* GraphQL */ `
  subscription OnCreateComment($filter: ModelSubscriptionCommentFilterInput) {
    onCreateComment(filter: $filter) {
      id
      createdAt
      messages {
        message
        messageUserId
        username
        role
        createdAt
      }
      closed
      recordType
      Type
      commentTagId
      tag {
        id
        height
        width
        key
        done
        skiped
        accepted
        states {
          nextToken
        }
        categoriesCount {
          name
          count
        }
        count
        time
        labelerTime
        reviewerTime
        timestamp
        status
        createdAt
        updatedAt
        assignedAt
        reviewAssignedAt
        savedAt
        reviewedAt
        skippedAt
        tagLabelerId
        labeler {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagReviewerId
        reviewer {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagImageId
        image {
          id
          key
          url
          status
          createdAt
          imageDatasetId
          width
          height
          updatedAt
        }
        tagProjectId
        project {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        comments {
          nextToken
        }
      }
      updatedAt
    }
  }
`;
export const onUpdateComment = /* GraphQL */ `
  subscription OnUpdateComment($filter: ModelSubscriptionCommentFilterInput) {
    onUpdateComment(filter: $filter) {
      id
      createdAt
      messages {
        message
        messageUserId
        username
        role
        createdAt
      }
      closed
      recordType
      Type
      commentTagId
      tag {
        id
        height
        width
        key
        done
        skiped
        accepted
        states {
          nextToken
        }
        categoriesCount {
          name
          count
        }
        count
        time
        labelerTime
        reviewerTime
        timestamp
        status
        createdAt
        updatedAt
        assignedAt
        reviewAssignedAt
        savedAt
        reviewedAt
        skippedAt
        tagLabelerId
        labeler {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagReviewerId
        reviewer {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagImageId
        image {
          id
          key
          url
          status
          createdAt
          imageDatasetId
          width
          height
          updatedAt
        }
        tagProjectId
        project {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        comments {
          nextToken
        }
      }
      updatedAt
    }
  }
`;
export const onDeleteComment = /* GraphQL */ `
  subscription OnDeleteComment($filter: ModelSubscriptionCommentFilterInput) {
    onDeleteComment(filter: $filter) {
      id
      createdAt
      messages {
        message
        messageUserId
        username
        role
        createdAt
      }
      closed
      recordType
      Type
      commentTagId
      tag {
        id
        height
        width
        key
        done
        skiped
        accepted
        states {
          nextToken
        }
        categoriesCount {
          name
          count
        }
        count
        time
        labelerTime
        reviewerTime
        timestamp
        status
        createdAt
        updatedAt
        assignedAt
        reviewAssignedAt
        savedAt
        reviewedAt
        skippedAt
        tagLabelerId
        labeler {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagReviewerId
        reviewer {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagImageId
        image {
          id
          key
          url
          status
          createdAt
          imageDatasetId
          width
          height
          updatedAt
        }
        tagProjectId
        project {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        comments {
          nextToken
        }
      }
      updatedAt
    }
  }
`;
export const onCreateTagState = /* GraphQL */ `
  subscription OnCreateTagState($filter: ModelSubscriptionTagStateFilterInput) {
    onCreateTagState(filter: $filter) {
      id
      createdAt
      stage
      action
      tagSateTagId
      tag {
        id
        height
        width
        key
        done
        skiped
        accepted
        states {
          nextToken
        }
        categoriesCount {
          name
          count
        }
        count
        time
        labelerTime
        reviewerTime
        timestamp
        status
        createdAt
        updatedAt
        assignedAt
        reviewAssignedAt
        savedAt
        reviewedAt
        skippedAt
        tagLabelerId
        labeler {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagReviewerId
        reviewer {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagImageId
        image {
          id
          key
          url
          status
          createdAt
          imageDatasetId
          width
          height
          updatedAt
        }
        tagProjectId
        project {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        comments {
          nextToken
        }
      }
      toBlameId
      toBlame {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      role
      updatedAt
    }
  }
`;
export const onUpdateTagState = /* GraphQL */ `
  subscription OnUpdateTagState($filter: ModelSubscriptionTagStateFilterInput) {
    onUpdateTagState(filter: $filter) {
      id
      createdAt
      stage
      action
      tagSateTagId
      tag {
        id
        height
        width
        key
        done
        skiped
        accepted
        states {
          nextToken
        }
        categoriesCount {
          name
          count
        }
        count
        time
        labelerTime
        reviewerTime
        timestamp
        status
        createdAt
        updatedAt
        assignedAt
        reviewAssignedAt
        savedAt
        reviewedAt
        skippedAt
        tagLabelerId
        labeler {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagReviewerId
        reviewer {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagImageId
        image {
          id
          key
          url
          status
          createdAt
          imageDatasetId
          width
          height
          updatedAt
        }
        tagProjectId
        project {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        comments {
          nextToken
        }
      }
      toBlameId
      toBlame {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      role
      updatedAt
    }
  }
`;
export const onDeleteTagState = /* GraphQL */ `
  subscription OnDeleteTagState($filter: ModelSubscriptionTagStateFilterInput) {
    onDeleteTagState(filter: $filter) {
      id
      createdAt
      stage
      action
      tagSateTagId
      tag {
        id
        height
        width
        key
        done
        skiped
        accepted
        states {
          nextToken
        }
        categoriesCount {
          name
          count
        }
        count
        time
        labelerTime
        reviewerTime
        timestamp
        status
        createdAt
        updatedAt
        assignedAt
        reviewAssignedAt
        savedAt
        reviewedAt
        skippedAt
        tagLabelerId
        labeler {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagReviewerId
        reviewer {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagImageId
        image {
          id
          key
          url
          status
          createdAt
          imageDatasetId
          width
          height
          updatedAt
        }
        tagProjectId
        project {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        comments {
          nextToken
        }
      }
      toBlameId
      toBlame {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      role
      updatedAt
    }
  }
`;
