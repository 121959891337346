import React, { useEffect, useContext } from 'react'
import { LabelerContext } from '../../../context/LabelerContext'
import { useLabelerMachine } from '../../../context/MachineContext'
export function useDrawTags(drawFunction = function () {}, parent) {
  const { state: MachineState, send } = useLabelerMachine()
  const { state } = useContext(LabelerContext)
  const { labelerState } = state

  useEffect(() => {
    if (MachineState.value === 'drawTags') {
      drawFunction()
      if (labelerState.projectCategories.at(-1).name === parent) {
        send({ type: 'RESOLVE', source: 'useDrawTags' })
      }
    }
  }, [MachineState.value])
}
