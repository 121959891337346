import React, { useState, useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import Toolbar from '../../../components/toolbar/Toolbar'
import { getUser } from '../../../utils/util'
import { project as projectActions } from '../../../io/project/actions'
import { LabelerContext } from '../context/LabelerContext'
import { DispatchContext } from '../context/DispatchContext'
import useGetImages from '../../../utils/hooks/labeler/useGetImages'
import useRole from '../../../utils/labeler/useRole'
import { loadEmbedding } from './types/segmentation/sam/utils'
import { getPath } from '../../../services/ai'

function NewLabeler(props) {
  const user = getUser()
  // Url params
  const queryParams = new URLSearchParams(window.location.search)
  const imageId = queryParams.get('id')
  const _role = useRole(props._role)

  // Context State
  const {
    state: { labelerState, landmarkState, segmentationState, boundingBoxState }
  } = useContext(LabelerContext)
  const { dispatch } = useContext(DispatchContext)
  const selectedImage =
    labelerState.image.index >= 0 && labelerState.image.index < labelerState.image.images.length
      ? labelerState.image.images[labelerState.image.index]
      : null
  // Local state
  const [tagState] = useState('init') // 'init', 'next', 'prev', 'stop'
  const [projectReady, setProjectReady] = useState(false)
  const [projectExist, setProjectExist] = useState(false)
  // SAM State
  const [tensor, setTensor] = useState(null)

  useEffect(() => {
    if (props.project.team) {
      props.getRole(user.id, props.project.team.id)
    }
  }, [props.project.team])

  useEffect(() => {
    if (projectExist) {
      setProjectReady(!props.project.loading)
    }
  }, [props.project.loading])

  useEffect(() => {
    if (props.project.id) {
      setProjectExist(true)
      props.getCategories(props.project.id, 'admin')
    }
  }, [])

  useEffect(() => {
    const { image } = labelerState
    if (image.images.length > 0 && image.index < 0) {
      dispatch({ type: 'setImage', payload: 0 })
    }
  }, [labelerState.image.images])

  useGetImages(
    {
      projectId: props.project.id,
      sortId: props.project.datasetSort,
      reviewType: props.project.reviewType,
      labelerId: user?.id,
      order: 'desc',
      imageId,
      state: tagState
    },
    projectReady,
    _role.labeler
  )

  const getEmbeddings = async () => {
    try {
      if (labelerState.image.index > -1) {
        setTensor(null)
        const key = labelerState.image.currentImage.key.split('/')
        const urlEmbedding = await getPath(
          `${key[0]}/${key[1]}/${key[2]}/${key[3].split('.').slice(0, -1).join('.')}_vit_h.npy`,
          'embeddings-linkedai'
        )
        const _tensor = await loadEmbedding(urlEmbedding)
        setTensor(_tensor === null ? null : _tensor)
      }
    } catch (error) {
      console.log('Tensor error:', error)
    }
  }

  return (
    <div style={{ height: '100%', overflow: 'hidden', position: 'relative' }}>
      <Toolbar isMagicEnable={!!tensor} />
      {React.cloneElement(props.children, {
        clickMode: labelerState.mode,
        isHotkeysOpen: labelerState.isHotkeysOpen,
        filters: labelerState.canvasSettings,
        images: labelerState.image.images, // TODO: Remove when Labeler stop using it
        imageIsLoading: labelerState.image.loading,
        isImageRendered: labelerState.image.isRendered,
        image: selectedImage,
        points: landmarkState.tags,
        segmentations: segmentationState.tags,
        loadingAutoBoundingboxes: boundingBoxState.loadingAI,
        loadingSam: segmentationState.loadingAI,
        tensor,
        getEmbeddings,
        handleNext: () => {
          dispatch({ type: 'setNavigation', payload: 'next' })
        },
        handleBack: () => {
          dispatch({ type: 'setNavigation', payload: 'prev' })
        }
      })}
    </div>
  )
}

const mapDispatchToProps = {
  getRole: projectActions.getRole,
  getCategories: projectActions.getCategories
}

const mapStateToProps = (state) => ({
  project: state.project
})

export default connect(mapStateToProps, mapDispatchToProps)(NewLabeler)
