import { useReducer } from 'react'
import produce from 'immer'

import { sandLabelHidden } from '../../../../../io/analytics'

const polygonsState = {
  tags: [],
  selectedTag: -1,
  cache: [],
  cache_return: [],
  poly: null,
  redraw: 0
}

const handleControlShiftZOperation = (operation, state) => {
  if (operation) {
    switch (operation.type) {
      case 'addPolygon':
        state.cache.push(operation)
        state.tags = [...operation.tags]
        break
      case 'addPoint':
        state.cache.push(operation)
        state.poly = operation.poly
        break
      case 'deletePolygon':
        state.cache.push(operation)
        state.cache_return = []
        state.tags = []
        break
      default:
        break
    }
  }
}

const handleControlZOperation = (operation, state) => {
  if (operation) {
    switch (operation.type) {
      case 'addPolygon':
        state.cache_return.push({ ...operation, tags: [...state.tags], poly: state.poly })
        state.tags.splice(operation.index, 1)
        break
      case 'addPoint':
        state.cache_return.push({
          ...operation,
          tags: [...state.tags],
          poly: JSON.parse(JSON.stringify(state.poly))
        })
        if (state.poly) {
          if (state.poly.pos.length > 1) {
            state.poly.pos.splice(operation.index, 1)
          } else {
            state.poly = undefined
          }
        }
        break
      case 'deletePolygon':
        state.cache_return.push({
          ...operation,
          tags: JSON.parse(JSON.stringify(operation.tags)),
          poly: state.poly
        })
        state.tags = [...operation.tags]
        break
      default:
        break
    }
  }
}

export const reducer = produce((state, action) => {
  switch (action.type) {
    case 'setLoadingTags':
      if (action.payload === true) {
        state.tags = []
        state.selectedTag = -1
        state.redraw = 0
        state.cache = []
        state.cache_return = []
      }
      break
    case 'setPoly':
      state.poly = action.payload
      break
    case 'addPointInCache':
      state.cache.push({ type: 'addPoint', index: action.payload.index, pos: action.payload.pos })
      break
    case 'setPolygons':
      state.tags = action.payload
      state.redraw = state.redraw + 1
      break
    case 'addPolygon':
      state.tags.push(action.payload)
      state.redraw = state.redraw + 1
      state.cache = state.cache.filter((item) => item.type !== 'addPoint')
      state.cache.push({ type: 'addPolygon', index: state.tags.length - 1 })
      break
    case 'selectPoly':
      state.selectedTag = action.payload
      state.redraw = state.redraw + 1
      break
    case 'updatePoly':
      state.tags[action.payload.polyindex].pos[action.payload.posindex] = action.payload.pos
      state.cache = []
      state.cache_return = []
      state.redraw = state.redraw + 1
      break
    case 'updateAllPoints':
      state.tags[action.payload.polyindex].pos = action.payload.pos
      state.cache = []
      state.cache_return = []
      state.redraw = state.redraw + 1
      break
    case 'hidePolygon':
      sandLabelHidden({ type: 'Poly' })
      if (action.payload) {
        state.tags[action.payload.index] = {
          ...state.tags[action.payload.index],
          show: action.payload.show
        }
        state.selectedTag = state.tags[action.payload.index].show ? state.selectedTag : -1
      } else {
        state.tags[state.selectedTag] = { ...state.tags[state.selectedTag], show: false }
        state.selectedTag = state.tags[state.selectedTag].show ? state.selectedTag : -1
      }
      state.redraw = state.redraw + 1
      break
    case 'hideAll':
      sandLabelHidden({ type: 'Poly' })
      state.tags = state.tags.map((poly) => ({ ...poly, show: action.payload }))
      state.redraw = state.redraw + 1
      break
    case 'hidePolygonCategory':
      sandLabelHidden({ type: 'Poly' })
      state.tags = state.tags.map((poly) => {
        if (poly.name === action.payload.name) {
          return { ...poly, show: !poly.show }
        }
        return poly
      })
      state.redraw = state.redraw + 1
      break
    case 'deletepolygon':
      sandLabelHidden({ type: 'Poly' })
      state.cache_return = []
      state.cache = []
      // eslint-disable-next-line no-case-declarations
      const i = action.payload !== undefined ? action.payload.index : state.selectedTag
      state.lastTag = -1
      state.cache.push({ type: 'deletePolygon', tags: JSON.parse(JSON.stringify(state.tags)) })
      state.tags = state.tags.filter((_, index) => index !== i)

      state.selectedTag = -1
      state.redraw = state.redraw + 1

      break
    case 'hoverPolygon':
      state.tags[action.payload.index] = {
        ...state.tags[action.payload.index],
        hover: action.payload.hover
      }
      state.selectedTag = action.payload.index === state.selectedTag ? -1 : state.selectedTag
      state.redraw = state.redraw + 1
      break
    case 'setPolygonText':
      state.tags[state.selectedTag] = { ...state.tags[state.selectedTag], text: action.payload }
      break
    case 'ctrlZ':
      // eslint-disable-next-line no-case-declarations
      const act = state.cache.pop()
      handleControlZOperation(act, state)
      state.redraw = state.redraw + 1
      break
    case 'ctrlShiftZ':
      // eslint-disable-next-line no-case-declarations
      const operationreturn = state.cache_return.pop()
      handleControlShiftZOperation(operationreturn, state)
      state.redraw = state.redraw + 1
      break
    case 'changePolygon':
      state.selectedTag = -1
      state.tags[action.payload.index].name = action.payload.name
      state.tags[action.payload.index].color = action.payload.color
      state.tags[action.payload.index].hover = false
      state.redraw = state.redraw + 1
      break
    default:
      break
  }
}, polygonsState)

export function PolygonsReducer() {
  const [state, dispatch] = useReducer(reducer, polygonsState)
  return { polygonState: state, polygonDispatch: dispatch }
}
