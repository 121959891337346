import { useLayoutEffect } from 'react'

export default function useResizeAICanvas(tools, canvasList = [], extraDependency = null) {
  useLayoutEffect(() => {
    canvasList.forEach((canvas) => {
      if (canvas.current !== null) {
        const c = canvas.current
        c.width = tools.width
        c.height = tools.height
      }
    })
  }, [tools.width, tools.height, extraDependency])
}
