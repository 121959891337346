import Axios from 'axios'
import { Storage } from 'aws-amplify'

export async function s3GetJson(path) {
  const url = await Storage.get(path, { level: 'public' })

  if (!url) return null

  try {
    const data = await Axios.get(url, {})

    return data.data
  } catch (err) {
    console.log(err)
    return null
  }
}
