import React, { useRef } from 'react'
import { styled } from '@mui/material/styles'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'
import { randomColor } from '../../../utils/util'

const ColorPickerInput = styled('input')(() => ({
  visibility: 'hidden',
  width: 0,
  padding: 0,
  margin: 0,
  position: 'absolute',
  left: 0
}))

export default function ColorPicker(props) {
  const defalutColor = randomColor(10)
  const colorPicker = useRef(null)

  const handleColor = (e) => {
    props.onChange(e.target.value)
  }

  const handleNewColor = (e) => {
    e.stopPropagation()
    props.onChange(randomColor(20))
  }
  const openColorPicker = () => {
    colorPicker.current.click()
  }
  return (
    <div style={{ width: '100%', position: 'relative' }}>
      <TextField
        variant="standard"
        sx={{ width: '100%' }}
        label="Color"
        onClick={openColorPicker}
        margin="normal"
        value={props.color}
        InputProps={{
          style: { color: props.color },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="refresh-color"
                onClick={handleNewColor}
                edge="end"
                size="small"
              >
                <RefreshIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <ColorPickerInput
        value={props.color || defalutColor}
        onChange={handleColor}
        ref={colorPicker}
        id="input-color"
        type="color"
      />
    </div>
  )
}
