import { useContext, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { FiltersContext } from '../../../../../../../../../context/FiltersContext'
import { useGetSegmentation } from './useGetSegmentation'
import { drawSegmentation } from '../utils/drawSegmentation'
import { drawAnnotation } from '../utils/drawAnnotation'

const useImageCanvas = ({ image, cardImageRef, callDrawImage, width, height }) => {
  const { state } = useContext(FiltersContext)

  const { hiddenCategories, filledCategories, gridSize } = state

  const project = useSelector((state) => state.project)

  const { all, stack } = useGetSegmentation({ project, image })

  const segmentationShapes = stack.map((seg) => ({
    ...seg,
    color: all[seg.type].color,
    name: all[seg.type].name
  }))

  const imgRef = useRef(null)
  const canvasImageRef = useRef(null)
  const canvasRef = useRef(null)
  const canvas2Ref = useRef(document.createElement('canvas', null, null))
  const canvas3Ref = useRef(document.createElement('canvas', null, null))
  const container = cardImageRef.current
  const img = imgRef.current
  const canvasImage = canvasImageRef.current
  const canvas = canvasRef.current
  const canvas2 = canvas2Ref.current
  const canvas3 = canvas3Ref.current

  const ctxCanvasImage = canvasImage?.getContext('2d')
  const ctxCanvas = canvas?.getContext('2d')
  const ctxCanvas2 = canvas2?.getContext('2d')
  const ctxCanvas3 = canvas3?.getContext('2d')

  useEffect(() => {
    callDrawImage(drawImageCanvas())
  }, [all, stack, hiddenCategories, filledCategories, gridSize, width, height])

  const drawImageCanvas = () => {
    const newImage = new Image()
    newImage.src = image.src

    if (!canvas || !img) return

    ctxCanvasImage.clearRect(0, 0, canvasImage.width, canvasImage.height)
    ctxCanvas.clearRect(0, 0, canvas.width, canvas.height)
    canvasImage.width = container.clientWidth
    canvasImage.height = container.clientHeight
    canvas.width = container.clientWidth
    canvas.height = container.clientHeight
    canvas2.width = container.clientWidth
    canvas2.height = container.clientHeight
    canvas3.width = container.clientWidth
    canvas3.height = container.clientHeight
    const scaleX = container.clientWidth / img.width
    const scaleY = container.clientHeight / img.height

    newImage.onload = () => {
      drawImage(scaleX, scaleY)
      drawCanvas(scaleX, scaleY)
    }
  }

  const drawImage = (scaleX, scaleY) => {
    ctxCanvasImage.setTransform(scaleX, 0, 0, scaleY, 0, 0)
    ctxCanvasImage.drawImage(img, 0, 0)
  }

  const drawCanvas = (scaleX, scaleY) => {
    drawSegmentations(scaleX, scaleY)
    if (image.annotations) {
      drawAnnotations(scaleX, scaleY)
    }
  }

  const drawSegmentations = (scaleX, scaleY) => {
    ctxCanvas.clearRect(0, 0, canvas.width, canvas.height)
    ctxCanvas.globalAlpha = 0.5
    ctxCanvas.setTransform(1, 0, 0, 1, 0, 0)
    ctxCanvas2.clearRect(0, 0, canvas.width, canvas.height)
    ctxCanvas2.setTransform(scaleX, 0, 0, scaleY, 0, 0)
    drawSegmentation(segmentationShapes, hiddenCategories, filledCategories, img, image, ctxCanvas2)
    ctxCanvas3.globalCompositeOperation = 'source-over'
    ctxCanvas3.clearRect(0, 0, canvas.width, canvas.height)
    ctxCanvas3.fillRect(0, 0, img.width, img.height)
    ctxCanvas3.globalCompositeOperation = 'source-in'
    ctxCanvas3.drawImage(canvas2, 0, 0)
    ctxCanvas3.closePath()
    ctxCanvas.drawImage(canvas3, 0, 0)
    ctxCanvas.closePath()
  }

  const drawAnnotations = (scaleX, scaleY) => {
    const annotationTypes = ['rects', 'polys', 'points']
    annotationTypes.forEach((type) => {
      if (image.annotations[type]) {
        const annotationType = type.slice(0, -1)
        const isFilled = filledCategories
        image.annotations[type].forEach((annotation) => {
          drawAnnotation(
            annotation,
            annotationType,
            isFilled,
            scaleX,
            scaleY,
            image,
            img,
            ctxCanvas,
            hiddenCategories
          )
        })
      }
    })
  }

  return {
    canvasImageRef,
    canvasRef,
    imgRef,
    drawImage
  }
}

export default useImageCanvas
