import React, { useEffect, useRef } from 'react'

export function useEffectWR(args = [], arrayOfFunctions = []) {
  const isInitialMount = useRef(true)

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      for (let i = 0; i < arrayOfFunctions.length; i++) {
        arrayOfFunctions[i]()
      }
    }
  }, [...args])
}
