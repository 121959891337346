import React, { useContext, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Button, TextField, CircularProgress } from '@mui/material'

import { getUser } from '../../../../../utils/util'
import { saveComment } from '../../../../../services/graphql'
import { LabelerContext } from '../../../context/LabelerContext'

const StylesTextField = styled(TextField)(({ theme }) => ({
  color: theme.palette.text.primary,
  width: '90%'
}))

const Container = styled('div')(() => ({
  width: '100%',
  height: '25%'
}))

const ContainerInput = styled('div')(() => ({
  height: '70%',
  width: '90%',
  textAlign: 'end'
}))

const ContainerButtons = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-around',
  alignItems: 'center',
  marginTop: 10
}))

export default function MessageInput({ handleAddComment, setRejectImg, role }) {
  const { state } = useContext(LabelerContext)
  const { labelerState } = state
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')

  const handleChangeMessage = (e) => {
    setMessage(e.target.value)
  }

  const isEmpty = (str) => {
    return !str || !str.trim().length
  }
  const createNewMesagge = async () => {
    try {
      if (!isEmpty(message)) {
        setLoading(true)
        const user = getUser()
        const msg = {
          message,
          messageUserId: user.id,
          username: user.name,
          role: role.labeler,
          createdAt: new Date().toISOString()
        }
        const comment = {
          messages: [msg],
          closed: false,
          recordType: 'comment',
          Type: 'global',
          commentTagId: labelerState.image.images[labelerState.image.index].id
        }
        await saveComment(comment)
        handleAddComment(comment)
        setLoading(false)
        setMessage('')
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  return (
    <Container>
      <ContainerInput>
        <span>
          <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
            <path
              d="M8 6h4.832C13.908 6 16 6.5 16 9c0 1.333-.333 2.167-1 2.5 1.333.333 2 1.333 2 3 0 .5 0 3.5-4 3.5H8a1 1 0 01-1-1V7a1 1 0 011-1zm1 10h3.5c1 0 2-.25 2-1.5s-1.104-1.5-2-1.5H9v3zm0-4.975h3c.504 0 2 0 2-1.525S12 8 12 8H9v3.025z"
              fill="currentColor"
            ></path>
          </svg>
        </span>
        <span>
          <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
            <path
              d="M10 6h6a1 1 0 010 2h-6a1 1 0 110-2zM8 16h6a1 1 0 010 2H8a1 1 0 010-2zm4-8h2l-2 8h-2l2-8z"
              fill="currentColor"
            ></path>
          </svg>
        </span>
        <StylesTextField
          id="outlined-multiline-flexible"
          multiline
          rows={3}
          onChange={handleChangeMessage}
          value={message}
        />
      </ContainerInput>
      <ContainerButtons>
        {loading ? (
          <CircularProgress size={'1.5rem'} />
        ) : (
          <>
            <Button sx={{ padding: 1 }} onClick={createNewMesagge} variant="outlined">
              Save comment
            </Button>
            <Button sx={{ padding: 1 }} onClick={() => setRejectImg(false)} variant="outlined">
              Reject Image
            </Button>
          </>
        )}
      </ContainerButtons>
    </Container>
  )
}
