import React, { useContext } from 'react'
import { Chip, List, ListItemButton, ListItemText, Typography, styled } from '@mui/material'
import { FiltersContext } from '../../../context/FiltersContext'
import ColoredDot from '../../../../../../../components/generics/ColoredDot'

const StyledList = styled(List)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  margin: 0,
  padding: 0
}))

const ListItemButtonError = styled(ListItemButton)(({ theme }) => ({
  display: 'block',
  borderBottom: `1px solid ${theme.palette.grey[300]}`
}))

const ListItemTextError = styled(ListItemText)(() => ({
  textAlign: 'left'
}))

const ContainerItemInfo = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: '10% 40% 50%'
}))

const ErrorList = ({ dataComparison }) => {
  const { state, dispatch } = useContext(FiltersContext)

  const { selectedImage, compareImage } = state

  const rectsSelectedImage = selectedImage?.annotations?.rects
  const rectsCompareImage = compareImage?.annotations?.rects

  const combinedData = dataComparison?.errors?.map((error) => {
    const matchingRectSelectedImage = rectsSelectedImage?.find(
      (rect) => rect.id === error.refBoundingBoxId
    )
    const matchingRectCompareImage = rectsCompareImage?.find(
      (rect) => rect.id === error.evalBoundingBoxId
    )

    return {
      ...error,
      rectsSelectedImage: matchingRectSelectedImage,
      rectsCompareImage: matchingRectCompareImage
    }
  })

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Error List
      </Typography>
      <StyledList>
        {combinedData?.map((error, index) => (
          <ListItemButtonError
            key={index}
            onMouseEnter={() => {
              dispatch({ type: 'setErrorItemHover', payload: error })
            }}
            onMouseLeave={() => {
              dispatch({ type: 'setErrorItemHover', payload: null })
            }}
          >
            <ContainerItemInfo>
              <ColoredDot color={error?.rectsSelectedImage?.color} />
              <ListItemTextError
                primary={
                  <Typography variant="body2">{error.refBoundingBoxId.slice(-3)}</Typography>
                }
              />
              <Chip
                label={error.iou ? `iou: ${error.iou.toFixed(7)}` : 'No Match Found'}
                variant="outlined"
                sx={{
                  position: error.iou && error.evalBoundingBoxId && 'relative',
                  top: error.iou && error.evalBoundingBoxId && '50%'
                }}
              />
            </ContainerItemInfo>
            {error.evalBoundingBoxId && (
              <ContainerItemInfo>
                <ColoredDot color={'#007C00'} />
                <ListItemTextError
                  primary={
                    <Typography variant="body2">{error.evalBoundingBoxId.slice(-3)}</Typography>
                  }
                />
              </ContainerItemInfo>
            )}
          </ListItemButtonError>
        ))}
      </StyledList>
    </>
  )
}

export default ErrorList
