import React from 'react'
import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useLabelerMachine } from '../../../../../containers/labeler/context/MachineContext'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import Badge from '@mui/material/Badge'

const Btn = styled(Button)(({ theme }) => ({
  width: '50%',
  color: theme.palette.mode === 'dark' ? '#f6a5c0' : theme.palette.secondary.main
}))

const ContainerButtons = styled('div')(() => ({
  display: 'flex'
}))

const CommentsButton = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  margin: 0
}))

const StyledBadge = styled(Badge)(({ theme }) => ({
  margin: 10,
  cursor: 'pointer',
  '& .MuiBadge-badge': {
    right: 0,
    top: 0,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px 10ox 3px'
  }
}))

export default function ReviewerImgHandler({
  setImageReview,
  accepted,
  done,
  openFeedbackToolbar,
  numberOfComments
}) {
  const { state: MachineState } = useLabelerMachine()

  const handleReject = () => {
    openFeedbackToolbar()
  }
  return (
    <ContainerButtons>
      <Button
        disabled={MachineState.value !== 'readyForLabeling'}
        sx={{ width: '50%' }}
        onClick={() => setImageReview(true)}
        color="primary"
        variant={accepted ? 'contained' : 'text'}
        style={
          accepted
            ? {
                background: '#81c784',
                color: 'white'
              }
            : {}
        }
      >
        Reviewed
      </Button>
      <Btn
        style={
          !accepted && !done
            ? {
                background: '#81c784',
                color: 'white'
              }
            : {}
        }
        onClick={handleReject}
        color="primary"
        disabled={MachineState.value !== 'readyForLabeling'}
      >
        Reject
      </Btn>
      <CommentsButton onClick={handleReject}>
        <StyledBadge badgeContent={numberOfComments} color="primary">
          <NotificationsNoneIcon />
        </StyledBadge>
      </CommentsButton>
    </ContainerButtons>
  )
}
