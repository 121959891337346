/* eslint-disable no-empty-pattern */
import { handleActions } from 'redux-actions'
import * as _ from 'lodash'

const defaultState = {
  id: null,
  loading: false,
  projects: {
    loading: false,
    items: [],
    nextToken: null
  },
  sharedProjects: {
    loading: false,
    items: [],
    nextToken: null
  },
  teams: {
    loading: false,
    items: [],
    nextToken: null
  },
  memberTeams: {
    loading: false,
    items: [],
    nextToken: null
  },
  datasets: {
    loading: false,
    items: [],
    nextToken: null
  }
}

const reducer = handleActions(
  {
    USER: {
      // MY PROJECTS ---------------------------------------------------------------------------------------------
      GET_MY_PROJECTS: (state, { payload: {} }) => ({
        ...state,
        projects: { ...state.projects, loading: true }
      }),
      SET_MY_PROJECTS: {
        next(state, { payload: { projects } }) {
          return {
            ...state,
            projects: {
              items:
                state.projects.nextToken && !projects.replace
                  ? _.uniqBy([...state.projects.items, ...projects.items], 'id')
                  : [...projects.items],
              nextToken: projects.nextToken,
              loading: false
            }
          }
        },
        throw(state) {
          return { ...state, projects: { ...state.projects, loading: false } }
        }
      },
      // SHARED PROJECTS -----------------------------------------------------------------------------------------
      GET_SHARED_PROJECTS: (state, { payload: {} }) => ({
        ...state,
        sharedProjects: { ...state.sharedProjects, loading: true }
      }),
      SET_SHARED_PROJECTS: {
        next(state, { payload: { sharedProjects } }) {
          return {
            ...state,
            sharedProjects: {
              items:
                state.sharedProjects.nextToken && !sharedProjects.replace
                  ? _.uniqBy([...state.sharedProjects.items, ...sharedProjects.items], 'id')
                  : [...sharedProjects.items],
              nextToken: sharedProjects.nextToken,
              loading: false
            }
          }
        },
        throw(state) {
          return { ...state, sharedProjects: { ...state.sharedProjects, loading: false } }
        }
      },
      // MY TEAMS ------------------------------------------------------------------------------------------------
      GET_MY_TEAMS: (state, { payload: {} }) => ({
        ...state,
        teams: { ...state.teams, loading: true }
      }),
      SET_MY_TEAMS: {
        next(state, { payload: { teams } }) {
          return {
            ...state,
            teams: {
              items: state.teams.nextToken
                ? _.uniqBy([...state.teams.items, ...teams.items], 'id')
                : [...teams.items],
              nextToken: teams.nextToken,
              loading: false
            }
          }
        },
        throw(state) {
          return { ...state, teams: { ...state.teams, loading: false } }
        }
      },
      // MEMBER TEAMS --------------------------------------------------------------------------------------------
      GET_MEMBER_TEAMS: (state, { payload: {} }) => ({
        ...state,
        memberTeams: { ...state.memberTeams, loading: true }
      }),
      SET_MEMBER_TEAMS: {
        next(state, { payload: { teams } }) {
          return {
            ...state,
            memberTeams: {
              items: state.memberTeams.nextToken
                ? _.uniqBy([...state.memberTeams.items, ...teams.items], 'id')
                : [...teams.items],
              nextToken: teams.nextToken,
              loading: false
            }
          }
        },
        throw(state) {
          return { ...state, memberTeams: { ...state.memberTeams, loading: false } }
        }
      },
      // MY DATASETS --------------------------------------------------------------------------------------------
      GET_DATASETS: (state, { payload: {} }) => ({
        ...state,
        datasets: { ...state.datasets, loading: true }
      }),
      SET_DATASETS: {
        next(state, { payload: { datasets } }) {
          return {
            ...state,
            datasets: {
              items: state.datasets.nextToken
                ? _.uniqBy([...state.datasets.items, ...datasets.items], 'id')
                : [...datasets.items],
              nextToken: datasets.nextToken,
              loading: false
            }
          }
        },
        throw(state) {
          return { ...state, datasets: { ...state.datasets, loading: false } }
        }
      },
      ADD_DATASET: (state, { payload: { dataset } }) => ({
        ...state,
        datasets: { ...state.datasets, items: _.uniqBy([dataset, ...state.datasets.items], 'id') }
      })
    }
  },
  defaultState
)

export default reducer
