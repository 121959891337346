import * as _ from 'lodash'

export const formatPieChartData = (project, labelFilter, totalTags) => {
  let indexColor = 0
  const data =
    project.categoriesCount && Array.isArray(project.categoriesCount)
      ? project.categoriesCount.filter((cat) => !cat.name.startsWith('_'))
      : []

  return data.map((cat) => {
    const cate = _.find(project.categories, ['name', cat.name])
    if (!cate) indexColor += 1
    return {
      label: cat.name,
      color: cate ? cate.color : grayColors[indexColor % grayColors.length],
      percentage:
        totalTags > 0
          ? labelFilter === 'reviewed'
            ? Math.round((100 * cat.countReviewed) / totalTags)
            : labelFilter === 'saved'
            ? Math.round((100 * cat.countSaved) / totalTags)
            : Math.round((100 * cat.count) / totalTags)
          : 0,
      name: cat.name,
      count: cat.count,
      countSaved: cat.countSaved,
      countReviewed: cat.countReviewed
    }
  })
}

export const getTotalTags = (labelFilter, project) => {
  switch (labelFilter) {
    case 'saved':
      return project.tagsSaveCount
    case 'reviewed':
      return project.tagsReviewCount
    case 'all':
    default:
      return project.tagsCount
  }
}

export const grayColors = ['#999', '#777', '#555', '#333', '#111']
