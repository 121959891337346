import React from 'react'

const LabelerContext = React.createContext({})
const { Provider, Consumer } = LabelerContext

export default function LabelerProvider(props) {
  return <Provider value={props.value}>{props.children}</Provider>
}

export { LabelerContext, LabelerProvider, Consumer as LabelerConsumer }
