import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import Toolbar from '@mui/material/Toolbar'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

import { getUser } from '../../../utils/util'
import Upload from '../../../components/Upload'
import CreateTeam from '../../../containers/CreateTeam'
import CreateProject from '../../../containers/CreateProject'
import { modals } from '../../../services/modals/modalsActions'

function ModalWelcome(props) {
  const [step, setStep] = useState(0)
  const [team, setTeam] = useState(null)
  const [dataset, setDataset] = useState(null)
  const [isUploaded, setIsUploaded] = useState(false)

  const { open } = props

  useEffect(() => {
    const user = getUser()
    if (!user || !user.paid) {
      setStep(1)
    }
  }, [])

  function handleClose() {
    props.setModalWelcome(false)
    props.closeModal(true)
  }

  const teamCreated = (team) => {
    setTeam(team)
  }

  const datasetCreated = (dataset) => {
    setDataset(dataset)
  }

  const renderContent = () => {
    switch (step) {
      case 0:
        return (
          <>
            <Typography style={{ color: '#aaa' }} variant="h4">
              Create a Team
            </Typography>
            <Typography variant="subtitle1">
              Start creating a team to work with. You can find registered users or invite new
              co-workers.
            </Typography>
            <CreateTeam teamCreated={teamCreated} />
          </>
        )
      case 1:
        return (
          <>
            <Typography style={{ color: '#aaa' }} variant="h4">
              Create a Dataset
            </Typography>
            <Typography variant="subtitle1">
              Start uploading the images you want to label with your team. Set the name of the
              dataset and then upload images droping it all.
            </Typography>
            <Upload datasetCreated={datasetCreated} imageUploaded={() => setIsUploaded(true)} />
          </>
        )
      case 2:
        return (
          <>
            <Typography style={{ color: '#aaa' }} variant="h4">
              Create a Project
            </Typography>
            <Typography variant="subtitle1">
              Now with your team and your dataset, you can create a project. Set a name to your
              project and add categories to label the data.
            </Typography>
            <CreateProject
              {...props}
              dataset={dataset}
              team={team}
              hideBreadcrumbs
              hideNewDataset
              hideNewTeam={!!team}
            />
          </>
        )
      default:
        handleClose()
        return null
    }
  }

  return (
    <Dialog
      fullScreen
      style={{ zIndex: 2 }}
      aria-labelledby="customized-dialog-title"
      open={open}
      onClose={handleClose}
    >
      <AppBar style={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h5" style={{ marginLeft: 10, flex: 1, color: 'white' }}>
            Welcome to LinkedAI!
          </Typography>
          {((!!team && step === 0) || (!!dataset && step === 1 && isUploaded)) && (
            <Button color="inherit" onClick={() => setStep(step + 1)}>
              Next
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <DialogContent dividers>{renderContent()}</DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-start' }}>
        {step === 0 && !team && (
          <Button onClick={() => setStep(step + 1)} color="secondary">
            Skip
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

ModalWelcome.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapDispatchToProps = {
  setModalWelcome: modals.setModalWelcome
}

export default connect(null, mapDispatchToProps)(ModalWelcome)
