import { useState } from 'react'

const useImagesClick = () => {
  const [imageSelected, setImageSelected] = useState({})

  const handleSelectedImage = (image) => {
    setImageSelected(image)
  }

  const openLabelerImage = (id) => {
    const newWindow = window.open(`/labeler?id=${id}`, '_blank')
    newWindow.focus()
  }
  return { imageSelected, handleSelectedImage, openLabelerImage }
}

export default useImagesClick
