import React from 'react'

const FiltersContext = React.createContext({})
const { Provider, Consumer } = FiltersContext

export default function FiltersProvider(props) {
  return <Provider value={props.value}>{props.children}</Provider>
}

export { FiltersContext, FiltersProvider, Consumer as FiltersConsumer }
