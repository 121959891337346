import React, { useEffect, useContext, useState, useRef } from 'react'
import { LabelerContext } from '../../../context/LabelerContext'
import { DispatchContext } from '../../../context/DispatchContext'
import useSnackbar from '../../../../../utils/hooks/useSnackbar'
import useZoom from '../hooks/useZoom'
import { useEffectWR } from '../hooks/useEffectWR'
import { Auth } from 'aws-amplify'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'
import Input from '@mui/material/Input'
import AutoboundingBoxesButtons from './AutoboundingBoxesButtons'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import useResizeAICanvas from '../hooks/useResizeAICanvas'
import CircularProgress from '@mui/material/CircularProgress'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: 'flex',
  flexDirection: 'column'
}

export default function AutoboundingBoxes({ panZoom, mouse }) {
  const { state } = useContext(LabelerContext)
  const { dispatch } = useContext(DispatchContext)
  const [isActived, setIsActived] = useState(false)
  const [projectCategory, setProjectCategory] = useState(null)
  const [modal, setModal] = useState({ open: false })
  const [category, setCategory] = useState('')
  const boundingRef = useRef([])
  const [region, setRegion] = useState({
    toolsPosX: 0,
    toolsPosY: 0,
    isOpened: false
  })

  const autoBoundingBoxCanvas = useRef(document.createElement('canvas', null, null))
  const { Snackbar, openSnackbar } = useSnackbar()
  const store = useSelector((state) => state)

  useEffectWR(
    [state.boundingBoxState.AICounters],
    [
      function () {
        getBoundingBoxes()
      }
    ]
  )

  useResizeAICanvas(state.labelerState.tools, [autoBoundingBoxCanvas], isActived)
  useEffect(() => {
    if (state.labelerState.selectedCategory.AI === 'autoboundingboxes') {
      setModal({ ...modal, open: true })
      setIsActived(true)
    }
  }, [state.labelerState.selectedCategory.AI])

  useZoom(panZoom, mouse.wheel, [autoBoundingBoxCanvas], () => {
    drawBoundingBoxes()
  })

  function mouseDown() {}

  const drawBoundingBoxes = (boundingboxes = boundingRef.current) => {
    const canvas = autoBoundingBoxCanvas.current
    const ctx = canvas.getContext('2d')

    ctx.beginPath()
    ctx.globalCompositeOperation = 'source-over'
    ctx.setLineDash([])

    ctx.setTransform(1, 0, 0, 1, 0, 0)
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    ctx.setTransform(panZoom.scale, 0, 0, panZoom.scale, panZoom.x, panZoom.y)
    ctx.lineWidth = 0.8 / panZoom.scale
    boundingboxes.forEach((boundingBox) => {
      ctx.strokeStyle = boundingBox.color
      ctx.fillStyle = boundingBox.color + '4D'
      ctx.beginPath()
      ctx.rect(boundingBox.pos[0], boundingBox.pos[1], boundingBox.pos[2], boundingBox.pos[3])
      ctx.stroke()
      ctx.fill()
    })
  }

  const getBoundingBoxes = async () => {
    try {
      dispatch({ type: 'setBoundingBoxAiLoading', payload: true })
      const path = decodeURIComponent(
        state.labelerState.image.images[state.labelerState.image.index]?.key
      )
      const session = await Auth.currentSession()
      const token = session.idToken.jwtToken

      const myHeaders = new Headers()
      myHeaders.append('Content-Type', 'application/json')
      myHeaders.append('Authorization', token)

      const raw = JSON.stringify({
        path_images: [path],
        categoria: category,
        score_threshold: state.boundingBoxState.AICounters.score,
        overlap_threshold: state.boundingBoxState.AICounters.overlap
      })

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      }

      let boundingboxes = await fetch('https://api.linkedai.co/v2/ml', requestOptions)
        .then((response) => response.text())
        .then((result) => result)

      boundingboxes = JSON.parse(boundingboxes)
      boundingboxes = Object.values(boundingboxes)
      let count = 0

      boundingboxes = boundingboxes[0].map((boundingbox) => {
        const x = boundingbox[0] < 0 ? 0 : boundingbox[0]
        const y = boundingbox[1] < 0 ? 0 : boundingbox[1]
        const w =
          boundingbox[2] > state.labelerState.image.props.w - boundingbox[0]
            ? state.labelerState.image.props.w - boundingbox[0]
            : boundingbox[2]
        const h =
          boundingbox[3] > state.labelerState.image.props.h - boundingbox[1]
            ? state.labelerState.image.props.h - boundingbox[1]
            : boundingbox[3]

        count++
        return {
          id: uuidv4(),
          pos: [x, y, w, h],
          classes: [],
          parent: null,
          text: null,
          name: projectCategory.name,
          color: projectCategory.color,
          type: 'bounding_box',
          hover: false,
          show: true
        }
      })

      if (count === 0) {
        dispatch({ type: 'setBoundingBoxAiLoading', payload: false })
        openSnackbar({
          message: 'No results, try again with other prompt',
          type: 'warning',
          duration: 2000
        })
        return
      }

      drawBoundingBoxes(boundingboxes)
      boundingRef.current = boundingboxes
      setModal({ ...modal, open: false })
      openSnackbar({
        message: 'Bounding boxes successfully generated',
        type: 'success',
        duration: 1000
      })

      setRegion({
        toolsPosX:
          autoBoundingBoxCanvas.current.width / 3 +
          autoBoundingBoxCanvas.current.width / 3 / 2 -
          50,
        toolsPosY: 100,
        isOpened: true
      })
      dispatch({ type: 'setBoundingBoxAiLoading', payload: false })
    } catch (error) {
      console.log(error)
      setModal({ ...modal, open: false })
      openSnackbar({
        message: 'Error while generating bounding boxes',
        type: 'error',
        duration: 2000
      })
      dispatch({ type: 'setIACategory', payload: 'none' })
      dispatch({ type: 'setBoundingBoxAiLoading', payload: false })
    }
  }
  const handleChange = (e) => {
    setProjectCategory(e.target.value)
  }
  const confirmBoundingboxes = () => {
    dispatch({ type: 'addMultipleBoxes', payload: boundingRef.current })
    cancelBoundingboxes()
  }

  const cancelBoundingboxes = () => {
    boundingRef.current = []
    setRegion({ ...region, isOpened: false })
    dispatch({ type: 'setIACategory', payload: 'none' })
    drawBoundingBoxes([])
  }

  const reload = () => {
    setModal({ ...modal, open: true })
    setIsActived(true)
  }

  return {
    AutoboundingBoxesCanvas: isActived ? (
      <>
        <canvas className="layout" onMouseDown={mouseDown} ref={autoBoundingBoxCanvas}></canvas>
        <Modal
          open={modal.open}
          onClose={() => {
            setModal({ ...modal, open: false })
            dispatch({ type: 'setIACategory', payload: 'none' })
          }}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={{ ...style, width: 400 }}>
            <h3 id="parent-modal-title">Category to search</h3>
            <Input
              onChange={(e) => {
                setCategory(e.target.value)
              }}
              style={{ marginRight: '1rem', marginBottom: '2rem' }}
            />

            <InputLabel id="demo-simple-select-label">Project Category</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={projectCategory}
              label="Project Category"
              onChange={handleChange}
              style={{ marginBottom: '2rem' }}
            >
              {store.project.categories
                .filter((category) => category.type === 'bounding_box')
                .map((category, index) => {
                  return (
                    <MenuItem key={index} value={category}>
                      {category.name}
                    </MenuItem>
                  )
                })}
            </Select>

            {state.boundingBoxState.loadingAI ? (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress />
              </Box>
            ) : (
              <Button
                disabled={category === '' || projectCategory === null}
                onClick={getBoundingBoxes}
              >
                Generate
              </Button>
            )}
          </Box>
        </Modal>
        {region.isOpened && (
          <AutoboundingBoxesButtons
            confirm={confirmBoundingboxes}
            cancel={cancelBoundingboxes}
            reload={reload}
            region={region}
            isLoading={state.boundingBoxState.loadingAI}
          />
        )}
        <Snackbar />
      </>
    ) : (
      <></>
    )
  }
}
