import React from 'react'
import useResizeCategoryCanvas from '../hooks/useResizeCategoryCanvas'
import { useClearCanvas } from '../hooks/useClearCanvas'
import { useDrawTags } from '../hooks/useDrawTags'

export default function ClassificationCanvas() {
  useClearCanvas([], 'classification')
  useResizeCategoryCanvas([], 'classification')
  useDrawTags(() => {}, 'classification')
  return <></>
}
