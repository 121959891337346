export function getLandmark(posx, posy, tags, panZoom) {
  for (let index = 0; index < tags.length; index++) {
    const point = tags[index]
    if (
      Math.sqrt(Math.pow(point.pos.x - posx, 2) + Math.pow(point.pos.y - posy, 2)) <
      7 / panZoom.scale
    ) {
      return { index, landmark: point, type: 'point' }
    }
  }
  return null
}
