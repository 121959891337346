import { useContext, useState } from 'react'
import { getComparisonIoU } from '../../../../../../../io/api/ml'
import { FiltersContext } from '../../../context/FiltersContext'

const useCompareLabels = () => {
  const { state, dispatch } = useContext(FiltersContext)
  const { selectedImage, images } = state

  const [compareId, setCompareId] = useState('')

  function findById(array, id) {
    return array.find((image) => image.id === id)
  }

  const compareImage = findById(
    images.flatMap((page) => page.images),
    compareId
  )

  const handleCompareIdChange = (event) => {
    setCompareId(event.target.value)
  }

  const resetCompareId = () => {
    setCompareId('')
  }

  const onCompareCall = async () => {
    if (compareId) {
      try {
        const response = await getComparisonIoU(selectedImage.id, compareId)
        dispatch({ type: 'setCompareImage', payload: compareImage })
        dispatch({ type: 'setDataComparison', payload: { ...response.data, compareId } })
      } catch (error) {
        console.error('Error getting IoU comparison:', error)
      }
    }
  }

  return {
    handleCompareIdChange,
    onCompareCall,
    compareId,
    resetCompareId
  }
}

export default useCompareLabels
