import React from 'react'
import { Collapse, Avatar } from '@mui/material'
import ReplyInput from './ReplyInput'
import { stringAvatar } from '../../../../../utils/util'
import { styled } from '@mui/material/styles'

const Container = styled('div')(() => ({
  display: 'flex',
  paddingLeft: 40,
  width: '100%',
  overflow: 'hidden',
  paddingRight: 2,
  flexDirection: 'column',
  marginTop: 7
}))

const ContainerAvatar = styled('div')(() => ({
  display: 'flex',
  overflow: 'hidden'
}))

const TitleAvatar = styled('span')(() => ({
  paddingLeft: 10,
  fontWeight: '500'
}))
const ContainerMessage = styled('div')(() => ({
  textAlign: 'justify',
  paddingLeft: 30,
  wordWrap: 'break-word',
  paddingRight: 10
}))

export default function Message({
  comments,
  comment,
  open,
  index,
  activeInput,
  handleAddMessage,
  handleCancel
}) {
  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      {comment.messages.slice(1).map((message, ind) => {
        return (
          <Container key={ind}>
            <ContainerAvatar>
              <Avatar {...stringAvatar(message.username)} />
              <TitleAvatar>{message.username}</TitleAvatar>
            </ContainerAvatar>
            <ContainerMessage>{message.message}</ContainerMessage>
          </Container>
        )
      })}
      {index === activeInput && (
        <ReplyInput
          comments={comments}
          index={index}
          handleAddMessage={handleAddMessage}
          handleCancel={handleCancel}
        />
      )}
    </Collapse>
  )
}
