import Axios from 'axios'
import { Auth } from 'aws-amplify'

import { getUserToken } from '../../utils/util'

export const getDataPoints = async (typeRequest, projectId, order, conditions, startIndex) => {
  const idToken = await getUserToken()
  const response = await Axios.post(
    `${process.env.REACT_APP_API}projects/` + projectId + '/tags',
    {
      sort: {
        [order.type]: {
          order: order.order
        }
      },
      from: typeRequest === 'filter' ? 0 : startIndex,
      conditions
    },
    {
      headers: {
        Authorization: idToken
      }
    }
  )
  return response
}

export const getAssignLabeler = async (id, labeler) => {
  const session = await Auth.currentSession()
  const token = session.idToken.jwtToken
  return Axios.post(
    `${process.env.REACT_APP_API}tags/assign`,
    {
      tagProjectId: id,
      tagLabelerId: labeler
    },
    { headers: { Authorization: token } }
  )
}

export const getAssignReviewer = async (id, reviewer) => {
  const session = await Auth.currentSession()
  const token = session.idToken.jwtToken
  return Axios.post(
    `${process.env.REACT_APP_API}tags/assign`,
    {
      tagProjectId: id,
      tagReviewerId: reviewer
    },
    { headers: { Authorization: token } }
  )
}
