import React, { useState } from 'react'
import DialogActions from '@mui/material/DialogActions'
import { connect } from 'react-redux'
import { dialog } from '../../../../io/dialogs/actions'
import BarChart from '../../../charts/Bar'
import LineChart from '../../../charts/Line'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  ButtonGroup,
  DialogContentText
} from '@mui/material'
import { useTheme, styled } from '@mui/material/styles'

const ContainerFilters = styled('div')(() => ({
  paddingTop: 20
}))

const DialogText = styled('div')(() => ({
  display: 'flex'
}))

const userType = {
  saved: { label: 'Labeler', email: 'labelerEmail', id: 'labelerId', stats: 'labelerStats' },
  reviewed: { label: 'Reviewer', email: 'reviwerEmail', id: 'reviwerId', stats: 'reviwerStats' },
  skipped: { label: 'Skipped', email: 'labelerEmail', id: 'labelerId', stats: 'labelerStats' }
}

function UserMetricsDialog(props) {
  const theme = useTheme()
  const [barFilter, setBarFilter] = useState('week')
  const handleClose = () => {
    props.setDialogStatus(false)
  }
  const { imgFilter } = props
  const { label, email, id, stats } = userType[imgFilter]

  return (
    <Dialog
      fullWidth={true}
      maxWidth={false}
      open={props.dialogs.isOpenLabelerMetricsDialog}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">{label} Metrics</DialogTitle>
      <DialogContent>
        <DialogText>
          <DialogContentText style={{ paddingRight: 5 }}>{label}:</DialogContentText>
          <DialogContentText>{props.dialogs[email]}</DialogContentText>
        </DialogText>
        <DialogText>
          <DialogContentText style={{ paddingRight: 5 }}>ID:</DialogContentText>
          <DialogContentText>{props.dialogs[id]}</DialogContentText>
        </DialogText>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <ButtonGroup sx={{ paddingTop: 2, paddingBottom: 2 }} size="small" color="primary">
            <Button
              name="week"
              variant={barFilter === 'week' ? 'contained' : null}
              onClick={() => setBarFilter('week')}
            >
              Week
            </Button>
            <Button
              name="month"
              variant={barFilter === 'month' ? 'contained' : null}
              onClick={() => setBarFilter('month')}
            >
              Month
            </Button>
            <Button
              name="all"
              variant={barFilter === 'all' ? 'contained' : null}
              onClick={() => setBarFilter('all')}
            >
              All
            </Button>
          </ButtonGroup>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Paper elevation={2}>
                <ContainerFilters>
                  {barFilter !== 'all' ? (
                    <BarChart
                      datasets={[
                        {
                          data: props[stats].barImages,
                          label: 'Images',
                          color: theme.palette.secondary.main
                        },
                        ...(label !== 'Reviewer'
                          ? [
                              {
                                data: props[stats].barSkipped,
                                label: 'Skipped Images',
                                color: '#c5c5c5'
                              }
                            ]
                          : [])
                      ]}
                      textColor={theme.palette.mode === 'dark' ? theme.palette.text.primary : null}
                      gridColor={theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : null}
                      filter={barFilter}
                    />
                  ) : (
                    <LineChart
                      datasets={[
                        {
                          data: props[stats].barImages,
                          label: 'Images',
                          color: theme.palette.secondary.main
                        },
                        ...(label !== 'Reviewer'
                          ? [
                              {
                                data: props[stats].barSkipped,
                                label: 'Skipped Images',
                                color: '#c5c5c5'
                              }
                            ]
                          : [])
                      ]}
                      textColor={theme.palette.mode === 'dark' ? theme.palette.text.primary : null}
                      gridColor={theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : null}
                      filter={barFilter}
                    />
                  )}
                </ContainerFilters>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper elevation={2}>
                <ContainerFilters>
                  {barFilter !== 'all' ? (
                    <BarChart
                      datasets={[
                        {
                          data: props[stats].barLabels,
                          label: 'Labels Count',
                          color: theme.palette.background.graphs
                        }
                      ]}
                      textColor={theme.palette.mode === 'dark' ? theme.palette.text.primary : null}
                      gridColor={theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : null}
                      filter={barFilter}
                    />
                  ) : (
                    <LineChart
                      datasets={[
                        {
                          data: props[stats].barLabels,
                          label: 'Labels Count',
                          color: theme.palette.background.graphs
                        }
                      ]}
                      textColor={theme.palette.mode === 'dark' ? theme.palette.text.primary : null}
                      gridColor={theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : null}
                      filter={barFilter}
                    />
                  )}
                </ContainerFilters>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
const mapStateToProps = (state) => ({
  dialogs: state.dialogs,
  labelerStats: state.project.labelerStats,
  reviwerStats: state.project.reviwerStats
})
const mapDispatchToProps = {
  setDialogStatus: dialog.setStatusLabelerMetricsDialog
}

export default connect(mapStateToProps, mapDispatchToProps)(UserMetricsDialog)
