import React from 'react'
import { Route, Routes as Switch } from 'react-router-dom'
import Home from './containers/Home'
import Login from './pages/signin/login/Login'
import ForgotPass from './pages/signin/ForgotPass'
import Signup from './containers/auth/Signup'
import Verify from './containers/auth/Verify'
import CreateDataset from './containers/CreateDataset'
import LabelerController from './containers/labeler/controller/LabelerController'
import Dataset from './containers/Dataset'
import Project from './pages/project/Project'
import Dashboard from './pages/dashboard/Dashboard'
import CreateProject from './containers/CreateProject'
import SharedProject from './pages/sharedProject/SharedProject'
import RequestLabel from './containers/RequestLabel'
import UserAccount from './containers/UserAccount'
import Team from './containers/Team'
import Times from './containers/Times'
import NotFound from './components/NotFound'
import RequireAuth from './components/AuthenticatedRoute'
import RequireNoAuth from './components/UnauthenticatedRoute'

const AuthElement = ({ children, cProps, needAuth = true }) => {
  const Required = (cProps, children) => ({
    auth: (
      <RequireAuth isAuth={cProps.isAuthenticated} redirectTo="/login">
        {children}
      </RequireAuth>
    ),
    noAuth: (
      <RequireNoAuth isAuth={cProps.isAuthenticated} redirectTo="/">
        {children}
      </RequireNoAuth>
    )
  })

  return Required(cProps, children)[needAuth ? 'auth' : 'noAuth']
}

const Routes = ({ childProps }) => (
  <Switch>
    <Route path="/" element={<Home {...childProps} />} />
    <Route path="/labeler" element={<LabelerController {...childProps} />} />
    {/* Authenticated Routes */}
    <Route
      path="/account/details"
      element={
        <AuthElement cProps={childProps}>
          <UserAccount {...childProps} />
        </AuthElement>
      }
    />
    <Route
      path="/team"
      element={
        <AuthElement cProps={childProps}>
          <Team {...childProps} />
        </AuthElement>
      }
    />
    <Route
      path="/times"
      element={
        <AuthElement cProps={childProps}>
          <Times {...childProps} />
        </AuthElement>
      }
    />
    <Route
      path="/dataset"
      element={
        <AuthElement cProps={childProps}>
          <Dataset {...childProps} />
        </AuthElement>
      }
    />
    <Route
      path="/project"
      element={
        <AuthElement cProps={childProps}>
          <Project {...childProps} />
        </AuthElement>
      }
    />
    <Route
      path="/upload"
      element={
        <AuthElement cProps={childProps}>
          <CreateDataset {...childProps} />
        </AuthElement>
      }
    />
    <Route
      path="/request"
      element={
        <AuthElement cProps={childProps}>
          <RequestLabel {...childProps} />
        </AuthElement>
      }
    />
    <Route
      path="/createproject"
      element={
        <AuthElement cProps={childProps}>
          <CreateProject {...childProps} />
        </AuthElement>
      }
    />
    <Route
      path="/sharedproject"
      element={
        <AuthElement cProps={childProps}>
          <SharedProject {...childProps} />
        </AuthElement>
      }
    />
    <Route
      path="/projects"
      element={
        <AuthElement cProps={childProps}>
          <Dashboard {...childProps} tab={0} />
        </AuthElement>
      }
    />
    <Route
      path="/datasets"
      element={
        <AuthElement cProps={childProps}>
          <Dashboard {...childProps} tab={1} />
        </AuthElement>
      }
    />
    <Route
      path="/teams"
      element={
        <AuthElement cProps={childProps}>
          <Dashboard {...childProps} tab={2} />
        </AuthElement>
      }
    />
    <Route
      path="/labeler/:id"
      element={
        <AuthElement cProps={childProps}>
          <LabelerController {...childProps} />
        </AuthElement>
      }
    />
    {/* Unauthenticated Routes */}
    <Route
      path="/login"
      element={
        <AuthElement cProps={childProps} needAuth={false}>
          <Login {...childProps} />
        </AuthElement>
      }
    />
    <Route
      path="/signup"
      element={
        <AuthElement cProps={childProps} needAuth={false}>
          <Signup {...childProps} />
        </AuthElement>
      }
    />
    <Route
      path="/verify"
      element={
        <AuthElement cProps={childProps} needAuth={false}>
          <Verify {...childProps} />
        </AuthElement>
      }
    />
    <Route
      path="/forgotpass"
      element={
        <AuthElement cProps={childProps} needAuth={false}>
          <ForgotPass {...childProps} />
        </AuthElement>
      }
    />
    {/* Finally, catch all unmatched routes */}
    <Route path="*" element={<NotFound />} />
  </Switch>
)

export default Routes
