import React, { useContext, useState } from 'react'
import { LabelerContext } from '../../../../../containers/labeler/context/LabelerContext'
import { DispatchContext } from '../../../../../containers/labeler/context/DispatchContext'
import {
  Chip,
  List,
  Button,
  Select,
  ListItem,
  Divider,
  Typography,
  MenuItem,
  TextField,
  ListItemIcon,
  ListItemText,
  FormControl,
  InputLabel
} from '@mui/material'
import { TYPES } from '../../../../../utils/constants'
import * as _ from 'lodash'
import DeleteIcon from '@mui/icons-material/Delete'
import OrderIcon from '@mui/icons-material/SwapVert'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { styled } from '@mui/material/styles'

const RootComponent = styled('div')(() => ({
  padding: '0px 6px',
  flex: '1',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column'
}))

const TitleComponent = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: 6
}))

const ListItemComponent = styled(ListItem)(() => ({
  display: 'flex',
  flexWrap: 'nowrap',
  padding: 0,
  justifyContent: 'space-around'
}))

const ListItemTextComponent = styled(ListItemText)(() => ({
  textAlign: 'left',
  paddingLeft: 10,
  paddingRight: 10
}))

const DeleteIconComponent = styled(DeleteIcon)(({ theme }) => ({
  width: 24,
  height: 24,
  padding: 2,
  color: theme.palette.text.icons
}))

const VisibilityOffIconComponent = styled(VisibilityOffIcon)(({ theme }) => ({
  width: 24,
  height: 24,
  padding: 2,
  color: theme.palette.text.icons
}))

const VisibilityIconComponent = styled(VisibilityIcon)(({ theme }) => ({
  width: 24,
  height: 24,
  padding: 2,
  color: theme.palette.text.icons
}))

function LabelerRectList(props) {
  const { state } = useContext(LabelerContext)
  const { dispatch } = useContext(DispatchContext)

  const [ordered, setOrdered] = useState(false)

  const handleItemSelect = (c, element, key) => (e) => {
    dispatch({
      type: 'classChange',
      payload: {
        e,
        className: c.name,
        key,
        element: element.r || element.p || element.t || element.l || element.c,
        multiSelect: c.multiSelect
      }
    })
  }

  const getTagslist = (ordered, list) => {
    return ordered ? _.sortBy(list, ['name']) : list
  }

  const getListOfItems = (ordered) => {
    const rects = getTagslist(ordered, state.boundingBoxState.tags).map((rect, i) => {
      const secondary = `
        start:(${Math.floor((rect.pos[0] / props.ratio) * 10) / 10}, ${
          Math.floor((rect.pos[1] / props.ratio) * 10) / 10
        })
        w:${Math.floor((rect.pos[2] / props.ratio) * 10) / 10}, h:${
          Math.floor((rect.pos[3] / props.ratio) * 10) / 10
        }
      `

      return {
        key: i,
        backgroundColor: rect.color,
        title: (rect.id ? `${rect.id.slice(-3)}.` : '') + rect.name,
        subtitle: secondary,
        funcDelete: () => dispatch({ type: 'deleteboundingBox', payload: { index: i } }),
        element: { r: rect, p: null, t: null, l: null, c: null },
        show: rect.show,
        funcHide: () =>
          dispatch({
            type: 'hideBounding',
            payload: { index: i, show: rect.show === undefined ? false : !rect.show }
          }),
        funcSelect:
          rect.parent === null ? () => dispatch({ type: 'selectLastTag', payload: i }) : null,
        seleted: state.boundingBoxState.lastTag === i,
        parentId: rect.parent ? rect.parent.slice(-3) : '',
        text: rect.text || '',
        classes: rect.classes,
        funcEnter: () => dispatch({ type: 'hoverBounding', payload: { index: i, hover: true } }),
        funcLeave: () => dispatch({ type: 'hoverBounding', payload: { index: i, hover: false } })
      }
    })

    const polys = getTagslist(ordered, state.polygonState.tags).map((poly, i) => {
      return {
        key: i,
        backgroundColor: poly.color,
        title: (poly.id ? `${poly.id.slice(-3)}.` : '') + poly.name,
        subtitle: `vertices:${poly.pos.length}`,
        funcDelete: () => dispatch({ type: 'deletepolygon', payload: { index: i } }),
        element: { r: null, p: poly, t: null, l: null, c: null },
        show: poly.show,
        funcHide: () =>
          dispatch({
            type: 'hidePolygon',
            payload: { index: i, show: poly.show === undefined ? false : !poly.show }
          }),
        funcSelect: () => props.selectItem(i, TYPES.POLY), // TODO: Fix with context
        seleted: props.selectedTag.type === TYPES.POLY && props.selectedTag.index === i, // Fix with context
        parentId: poly.parent ? poly.parent.slice(-3) : '',
        text: poly.text || '',
        classes: poly.classes,
        funcEnter: () => dispatch({ type: 'hoverPolygon', payload: { index: i, hover: true } }),
        funcLeave: () => dispatch({ type: 'hoverPolygon', payload: { index: i, hover: false } })
      }
    })

    const landmarks = getTagslist(ordered, state.landmarkState.tags).map((lmark, i) => {
      return {
        key: i,
        backgroundColor: lmark.color,
        title: (lmark.id ? `${lmark.id.slice(-3)}.` : '') + lmark.name,
        subtitle: `position:(${Math.round(lmark.pos.x)}, ${Math.round(lmark.pos.y)})`,
        funcDelete: () => dispatch({ type: 'deletePoint', payload: { index: i } }),
        element: { r: null, p: null, t: lmark, l: null, c: null },
        show: lmark.show,
        funcHide: () => dispatch({ type: 'hidePoint', payload: { index: i } }),
        funcSelect: () => props.selectItem(i, TYPES.POINT), // TODO: Fix with context
        seleted: state.landmarkState.selectedTag === i, // Fix with context
        parentId: lmark.parent ? lmark.parent.slice(-3) : '',
        text: lmark.text || '',
        classes: [],
        funcEnter: () => dispatch({ type: 'hoverPoint', payload: { index: i, hover: true } }),
        funcLeave: () => dispatch({ type: 'hoverPoint', payload: { index: i, hover: false } })
      }
    })

    const lines = getTagslist(ordered, state.lineState.tags).map((line, i) => {
      // TODO: Migrate to new Labeler
      return {
        key: i,
        backgroundColor: line.color,
        title:
          (line.id ? `${line.id.slice(-3)}.` : '') +
          line.name +
          (line.parent ? `(${line.parent.slice(-3)})` : ''),
        subtitle: `length:${line.pos.length}`,
        funcDelete: () => dispatch({ type: 'deleteLine', payload: { index: i } }),
        element: { r: null, p: null, t: null, l: line, c: null },
        show: line.show,
        funcHide: () => dispatch({ type: 'hideLine', payload: { index: i } }),
        funcSelect: () => props.selectItem(i, TYPES.LINE),
        seleted: props.selectedTag.type === TYPES.LINE && props.selectedTag.index === i,
        parentId: line.parent ? line.parent.slice(-3) : '',
        text: line.text || '',
        classes: line.classes,
        funcEnter: () => dispatch({ type: 'hoverLine', payload: { index: i, hover: true } }),
        funcLeave: () => dispatch({ type: 'hoverLine', payload: { index: i, hover: false } })
      }
    })

    const circles = getTagslist(ordered, props.circles).map((circle, i) => {
      // TODO: Migrate to new Labeler
      return {
        key: i,
        backgroundColor: circle.color,
        title:
          (circle.id ? `${circle.id.slice(-3)}.` : '') +
          circle.name +
          (circle.parent ? `(${circle.parent.slice(-3)})` : ''),
        subtitle: `center:${circle.pos.center.x}, ${circle.pos.center.y}`,
        funcDelete: () => props.deleteCircle(circle),
        element: { r: null, p: null, t: null, l: null, c: circle },
        show: circle.show,
        funcHide: () => props.hideCircle(circle),
        funcSelect: () => props.selectItem(i, TYPES.CIRCLE),
        seleted: props.selectedTag.type === TYPES.CIRCLE && props.selectedTag.index === i,
        parentId: circle.parent ? circle.parent.slice(-3) : '',
        text: circle.text || '',
        classes: []
      }
    })

    return [...rects, ...polys, ...landmarks, ...lines, ...circles] //, ...rects2, ...polys2, ...points
  }

  const renderItem = (
    key,
    backgroundColor,
    title,
    subtitle,
    fDelete,
    element,
    show,
    fHide,
    fSelect,
    selected,
    parentId,
    text,
    classess,
    fEnter = null,
    fLeave = null
  ) => {
    return (
      <>
        <ListItemComponent
          onMouseEnter={() => {
            fEnter
              ? fEnter()
              : props.mouseEnterOnList(element.r, element.p, element.t, element.l, element.c)
          }}
          onMouseLeave={() => {
            fLeave
              ? fLeave()
              : props.mouseLeaveOnList(element.r, element.p, element.t, element.l, element.c)
          }}
          key={key}
          style={selected ? { background: '#3331' } : {}}
        >
          <span key="_1" style={{ backgroundColor }} className="dot" onClick={fSelect} />
          <ListItemTextComponent
            onClick={fSelect}
            primary={
              <Typography noWrap>
                {title}
                {parentId ? ` (${parentId})` : ''}
              </Typography>
            }
            secondary={
              <>
                {subtitle}
                <Typography noWrap variant="subtitle2">
                  {text}
                </Typography>
                {classess && classess.length > 0
                  ? classess.map((c) =>
                      c.classType !== 'string' ? (
                        <FormControl variant="standard" key={c.name} style={{ width: '100%' }}>
                          <InputLabel>{c.name}</InputLabel>
                          <Select
                            variant="standard"
                            disabled={state.labelerState.role === 'viewer'}
                            style={{ width: '100%' }}
                            value={
                              c.multiSelect || c.classType === 'multiple' ? c.values : c.selected
                            }
                            multiple={c.multiSelect || c.classType === 'multiple'}
                            renderValue={(selected) =>
                              c.multiSelect || c.classType === 'multiple' ? (
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                  {selected.map((value) => (
                                    <Chip
                                      key={value}
                                      label={value}
                                      sx={{ display: 'flex', flexWrap: 'wrap' }}
                                    />
                                  ))}
                                </div>
                              ) : (
                                selected
                              )
                            }
                            onChange={handleItemSelect(c, element, key)}
                          >
                            {c.options &&
                              c.options.map((opt) => (
                                <MenuItem key={opt} value={opt}>
                                  <em>{opt}</em>
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      ) : (
                        <TextField
                          variant="standard"
                          key={c.name}
                          label={c.name}
                          value={c.selected || ''}
                          onChange={(e) =>
                            props.handleClassChange(
                              e,
                              c.name,
                              key,
                              element.r || element.p || element.t || element.l || element.c,
                              false,
                              c.classType
                            )
                          }
                        />
                      )
                    )
                  : null}
              </>
            }
            secondaryTypographyProps={{ component: 'div' }}
          />
          <ListItemIcon sx={{ minWidth: 0 }}>
            {state.labelerState.role !== 'viewer' && <DeleteIconComponent onClick={fDelete} />}
          </ListItemIcon>
          <ListItemIcon sx={{ minWidth: 0 }}>
            {show === false ? (
              <VisibilityOffIconComponent
                onClick={(e) => {
                  fHide(e)
                }}
              />
            ) : (
              <VisibilityIconComponent
                onClick={(e) => {
                  fHide(e)
                }}
              />
            )}
          </ListItemIcon>
        </ListItemComponent>
        <Divider key={key + '_'} />
      </>
    )
  }

  const renderSegmentationItem = (key, title, color, hide, fDelete, fHide, fEnter, fLeave) => {
    return (
      <>
        <ListItemComponent key={key} onMouseEnter={fEnter} onMouseLeave={fLeave}>
          <span style={{ backgroundColor: color }} className="dot" />
          <ListItemTextComponent primary={<Typography noWrap>{title}</Typography>} />
          <ListItemIcon sx={{ minWidth: 0 }}>
            {state.labelerState.role !== 'viewer' && <DeleteIconComponent onClick={fDelete} />}
          </ListItemIcon>
          <ListItemIcon style={{ paddingLeft: '10px' }} sx={{ minWidth: 0 }}>
            {hide ? (
              <VisibilityOffIconComponent onClick={() => fHide(true)} />
            ) : (
              <VisibilityIconComponent onClick={() => fHide(false)} />
            )}
          </ListItemIcon>
        </ListItemComponent>
        <Divider key={key + '_'} />
      </>
    )
  }

  return (
    <RootComponent>
      <TitleComponent>
        Labels{' '}
        {state.segmentationState.tags.stack.filter((item) => !item.deleted).length +
          state.boundingBoxState.tags.length +
          state.polygonState.tags.length}
        <Button
          sx={{ position: 'absolute', minWidth: 32, right: 0 }}
          size="small"
          onClick={() => {
            setOrdered(!ordered)
          }}
        >
          <OrderIcon fontSize="small" />
        </Button>
      </TitleComponent>
      <div style={{ height: '0px', flex: '1 1 auto', overflowY: 'auto', paddingRight: 10 }}>
        <List dense>
          {getListOfItems(ordered).map((ele) => {
            return renderItem(
              ele.key,
              ele.backgroundColor,
              ele.title,
              ele.subtitle,
              ele.funcDelete,
              ele.element,
              ele.show,
              ele.funcHide,
              ele.funcSelect,
              ele.selected,
              ele.parentId,
              ele.text,
              ele.classes,
              ele.funcEnter,
              ele.funcLeave
            )
          })}

          {Object.entries(state.segmentationState.tags.all).map((item, i) => {
            if (item[1].shapes.length > 0 && item[0] !== '_delete') {
              return renderSegmentationItem(
                i,
                item[0],
                item[1].color,
                item[1].hide,
                () => {
                  dispatch({
                    type: 'deleteSegmentationShapes',
                    payload: { index: item[0], segmentation: state.segmentationState.tags.all }
                  })
                },
                () => {
                  dispatch({
                    type: 'hideSegmentation',
                    payload: { index: item[0], hide: !item[1].hide }
                  })
                },
                () => {
                  dispatch({ type: 'enterSegmentation', payload: item[0] })
                },
                () => {
                  dispatch({ type: 'leaveSegmentation', payload: item[0] })
                }
              )
            }
            return null
          })}
        </List>
      </div>
    </RootComponent>
  )
}

export default LabelerRectList
