import React from 'react'
import '../styles/createproject.css'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'

import { listTimes } from '../services/aws'

const columns = [
  { id: 'date', label: 'Date', minWidth: 200 },
  {
    id: 'count',
    label: 'Tags',
    minWidth: 100,
    align: 'right',
    format: (value) => (value < 0 ? 0 : value.toLocaleString())
  },
  {
    id: 'images',
    label: 'Images',
    minWidth: 120,
    align: 'right',
    format: (value) => value.toLocaleString()
  },
  {
    id: 'createdAt',
    label: 'Start Time',
    minWidth: 120,
    align: 'right',
    format: (value) => new Date(value).toLocaleTimeString()
  },
  {
    id: 'lastModify',
    label: 'End Time',
    minWidth: 120,
    align: 'right',
    format: (value) => new Date(value).toLocaleTimeString()
  }
]

class Times extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      user: {},
      times: [],
      page: 0,
      rowsPerPage: 10
    }

    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
  }

  componentDidMount() {
    const { state } = this.props.location

    if (state && state.user) {
      this.setState({ user: state.user })
      listTimes(state.user.id)
        .then((r) => {
          if (r && r.length > 0) {
            this.setState({ times: r })
          }
        })
        .catch((e) => console.log(e))
    }
  }
  // ------------------------------------------------------------

  handleChangePage(event, newPage) {
    this.setState({ page: newPage })
  }

  handleChangeRowsPerPage(event) {
    this.setState({ rowsPerPage: event.target.value, page: 0 })
  }

  render() {
    const { page, rowsPerPage } = this.state
    return (
      <div className="create-container">
        <Grid container className="create-grid">
          <Grid item xs={12} className="project-item">
            <Typography variant="h4">User Times: {this.state.user.name}</Typography>
            <Paper>
              <div style={{ maxHeight: 407, overflow: 'auto' }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.times
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={row.timeId}>
                            {columns.map((column) => {
                              const value = row[column.id]
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {column.format && typeof value === 'number'
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              )
                            })}
                          </TableRow>
                        )
                      })}
                  </TableBody>
                </Table>
              </div>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={this.state.times.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  'aria-label': 'previous page'
                }}
                nextIconButtonProps={{
                  'aria-label': 'next page'
                }}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default Times
