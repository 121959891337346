import { useEffect, useState } from 'react'
import { s3GetJson } from '../../../../../../../../../../../../../io/aws/s3'

const createCategory = (category) => ({
  color: category.color,
  shapes: [],
  type: category.type,
  hide: false,
  hover: false,
  name: category.name
})

const updateShapes = (target, source) => {
  target.shapes = source.shapes
}

const updateOrAddProperty = (obj, property, value) => {
  obj[property] = obj[property] || createCategory(value)
  updateShapes(obj[property], value)
}

const processAllSegmentation = (segmentationData, segmentation) => {
  for (const property in segmentation.all) {
    if (Object.prototype.hasOwnProperty.call(segmentation.all, property)) {
      updateOrAddProperty(segmentationData.all, property, segmentation.all[property])
    }
  }
}

const processSegmentation = (segmentationData, segmentation, segmentationCategories) => {
  if (segmentation && segmentation.all) {
    if (typeof segmentation.all === 'object') {
      processAllSegmentation(segmentationData, segmentation)
    } else {
      segmentation.all.forEach((s) => updateOrAddProperty(segmentationCategories, s.name, s))
    }
  }
}

const useGetSegmentation = ({ image, project }) => {
  const [segmentationData, setSegmentationData] = useState({ all: [], stack: [] })

  useEffect(() => {
    getSegmentation()
  }, [project, image])

  const allCategories = project.categories.filter((category) => category.type === 'segmentation')
  const segmentationCategories = Object.fromEntries(
    allCategories.map((category) => [category.name, createCategory(category)])
  )

  const getSegmentation = async () => {
    try {
      const segmentation = await s3GetJson(`${project.id}/${image.id}.json`)
      processSegmentation(segmentationData, segmentation, segmentationCategories)

      setSegmentationData({ all: segmentation.all, stack: segmentation.stack })
    } catch (error) {}
  }

  return segmentationData
}

export {
  createCategory,
  updateShapes,
  updateOrAddProperty,
  processAllSegmentation,
  processSegmentation,
  useGetSegmentation
}
