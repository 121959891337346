import { formatS3Key } from '../utils/util'

const AWS = require('aws-sdk')
const credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: 'us-east-1:dbb30fc2-ef41-4bf5-b3f1-5b6f54b6615d'
})

AWS.config.region = 'us-east-1'
AWS.config.credentials = credentials

export async function getImageTags(key, callback, demo) {
  const rekognition = new AWS.Rekognition()

  const params = {
    Image: {
      S3Object: {
        Bucket: process.env.REACT_APP_AWS_BUCKET,
        Name: demo ? 'public/cars.jpg' : formatS3Key(key)
      }
    },
    MaxLabels: 120,
    MinConfidence: 80
  }

  await rekognition.detectLabels(params, callback)
}

export async function getPath(key, bucket = process.env.REACT_APP_AWS_BUCKET) {
  const s3 = new AWS.S3()

  try {
    const params = {
      Bucket: bucket,
      Key: decodeURIComponent(key)
    }

    const signedUrl = await s3.getSignedUrlPromise('getObject', params)
    return signedUrl
  } catch (error) {
    console.error('Error:', error)
    throw error
  }
}
