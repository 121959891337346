/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const searchDatasets = /* GraphQL */ `
  query SearchDatasets(
    $filter: SearchableDatasetFilterInput
    $sort: [SearchableDatasetSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableDatasetAggregationInput]
  ) {
    searchDatasets(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        description
        count
        status
        createdAt
        updatedAt
        datasetUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        images {
          nextToken
        }
        projects {
          nextToken
        }
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchTeams = /* GraphQL */ `
  query SearchTeams(
    $filter: SearchableTeamFilterInput
    $sort: [SearchableTeamSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableTeamAggregationInput]
  ) {
    searchTeams(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        createdAt
        updatedAt
        teamUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        status
        owner
        access
        members {
          nextToken
        }
        projects {
          nextToken
        }
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchMembers = /* GraphQL */ `
  query SearchMembers(
    $filter: SearchableMemberFilterInput
    $sort: [SearchableMemberSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableMemberAggregationInput]
  ) {
    searchMembers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        role
        createdAt
        updatedAt
        memberTeamId
        team {
          id
          name
          createdAt
          updatedAt
          teamUserId
          status
          owner
          access
        }
        memberUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchProjects = /* GraphQL */ `
  query SearchProjects(
    $filter: SearchableProjectFilterInput
    $sort: [SearchableProjectSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableProjectAggregationInput]
  ) {
    searchProjects(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        categories {
          color
          name
          type
          parent
          options
          required
          classType
          multiSelect
        }
        categoriesCount {
          name
          count
          countSaved
          countReviewed
        }
        createdAt
        updatedAt
        access
        owner
        status
        tagsCount
        datasetSort
        reviewType
        tagsSaveCount
        tagsReviewCount
        imagesSavedCount
        imagesReviewedCount
        projectTeamId
        team {
          id
          name
          createdAt
          updatedAt
          teamUserId
          status
          owner
          access
        }
        projectUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        datasets {
          nextToken
        }
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchRequestJsons = /* GraphQL */ `
  query SearchRequestJsons(
    $filter: SearchableRequestJsonFilterInput
    $sort: [SearchableRequestJsonSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableRequestJsonAggregationInput]
  ) {
    searchRequestJsons(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        type
        owner
        createdAt
        format
        tagsCount
        imagesCount
        status
        key
        requestProjectId
        project {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      email
      company
      termsAccepted
      status
      owner
      paid
      createdAt
      datasets {
        items {
          id
          name
          description
          count
          status
          createdAt
          updatedAt
          datasetUserId
        }
        nextToken
      }
      teams {
        items {
          id
          role
          createdAt
          updatedAt
          memberTeamId
          memberUserId
        }
        nextToken
      }
      projects {
        items {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDataset = /* GraphQL */ `
  query GetDataset($id: ID!) {
    getDataset(id: $id) {
      id
      name
      description
      count
      status
      createdAt
      updatedAt
      datasetUserId
      user {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      images {
        items {
          id
          key
          url
          status
          createdAt
          imageDatasetId
          width
          height
          updatedAt
        }
        nextToken
      }
      projects {
        items {
          id
          projectDataProjectId
          projectDataDatasetId
          updatedAt
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const listDatasets = /* GraphQL */ `
  query ListDatasets(
    $filter: ModelDatasetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDatasets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        count
        status
        createdAt
        updatedAt
        datasetUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        images {
          nextToken
        }
        projects {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getImage = /* GraphQL */ `
  query GetImage($id: ID!) {
    getImage(id: $id) {
      id
      key
      url
      status
      createdAt
      imageDatasetId
      dataset {
        id
        name
        description
        count
        status
        createdAt
        updatedAt
        datasetUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        images {
          nextToken
        }
        projects {
          nextToken
        }
      }
      width
      height
      thumbnail {
        key
        width
        height
      }
      generateEmbeddings {
        samH
        samB
        samL
      }
      embeddings {
        samH
        samB
        samL
      }
      updatedAt
    }
  }
`;
export const listImages = /* GraphQL */ `
  query ListImages(
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        key
        url
        status
        createdAt
        imageDatasetId
        dataset {
          id
          name
          description
          count
          status
          createdAt
          updatedAt
          datasetUserId
        }
        width
        height
        thumbnail {
          key
          width
          height
        }
        generateEmbeddings {
          samH
          samB
          samL
        }
        embeddings {
          samH
          samB
          samL
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      name
      createdAt
      updatedAt
      teamUserId
      user {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      status
      owner
      access
      members {
        items {
          id
          role
          createdAt
          updatedAt
          memberTeamId
          memberUserId
        }
        nextToken
      }
      projects {
        items {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        nextToken
      }
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        teamUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        status
        owner
        access
        members {
          nextToken
        }
        projects {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getMember = /* GraphQL */ `
  query GetMember($id: ID!) {
    getMember(id: $id) {
      id
      role
      createdAt
      updatedAt
      memberTeamId
      team {
        id
        name
        createdAt
        updatedAt
        teamUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        status
        owner
        access
        members {
          nextToken
        }
        projects {
          nextToken
        }
      }
      memberUserId
      user {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
    }
  }
`;
export const listMembers = /* GraphQL */ `
  query ListMembers(
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        role
        createdAt
        updatedAt
        memberTeamId
        team {
          id
          name
          createdAt
          updatedAt
          teamUserId
          status
          owner
          access
        }
        memberUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      name
      categories {
        color
        name
        type
        parent
        options
        required
        classType
        multiSelect
      }
      categoriesCount {
        name
        count
        countSaved
        countReviewed
      }
      createdAt
      updatedAt
      access
      owner
      status
      tagsCount
      datasetSort
      reviewType
      tagsSaveCount
      tagsReviewCount
      imagesSavedCount
      imagesReviewedCount
      projectTeamId
      team {
        id
        name
        createdAt
        updatedAt
        teamUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        status
        owner
        access
        members {
          nextToken
        }
        projects {
          nextToken
        }
      }
      projectUserId
      user {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      tags {
        items {
          id
          height
          width
          key
          done
          skiped
          accepted
          count
          time
          labelerTime
          reviewerTime
          timestamp
          status
          createdAt
          updatedAt
          assignedAt
          reviewAssignedAt
          savedAt
          reviewedAt
          skippedAt
          tagLabelerId
          tagReviewerId
          tagImageId
          tagProjectId
        }
        nextToken
      }
      datasets {
        items {
          id
          projectDataProjectId
          projectDataDatasetId
          updatedAt
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        categories {
          color
          name
          type
          parent
          options
          required
          classType
          multiSelect
        }
        categoriesCount {
          name
          count
          countSaved
          countReviewed
        }
        createdAt
        updatedAt
        access
        owner
        status
        tagsCount
        datasetSort
        reviewType
        tagsSaveCount
        tagsReviewCount
        imagesSavedCount
        imagesReviewedCount
        projectTeamId
        team {
          id
          name
          createdAt
          updatedAt
          teamUserId
          status
          owner
          access
        }
        projectUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        datasets {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getRequestJson = /* GraphQL */ `
  query GetRequestJson($id: ID!) {
    getRequestJson(id: $id) {
      id
      type
      owner
      createdAt
      format
      tagsCount
      imagesCount
      status
      key
      requestProjectId
      project {
        id
        name
        categories {
          color
          name
          type
          parent
          options
          required
          classType
          multiSelect
        }
        categoriesCount {
          name
          count
          countSaved
          countReviewed
        }
        createdAt
        updatedAt
        access
        owner
        status
        tagsCount
        datasetSort
        reviewType
        tagsSaveCount
        tagsReviewCount
        imagesSavedCount
        imagesReviewedCount
        projectTeamId
        team {
          id
          name
          createdAt
          updatedAt
          teamUserId
          status
          owner
          access
        }
        projectUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        datasets {
          nextToken
        }
      }
      updatedAt
    }
  }
`;
export const listRequestJsons = /* GraphQL */ `
  query ListRequestJsons(
    $filter: ModelRequestJsonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequestJsons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        owner
        createdAt
        format
        tagsCount
        imagesCount
        status
        key
        requestProjectId
        project {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const itemsByEmail = /* GraphQL */ `
  query ItemsByEmail(
    $email: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByEmail(
      email: $email
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const datasetsByDatasetUserIdAndCreatedAt = /* GraphQL */ `
  query DatasetsByDatasetUserIdAndCreatedAt(
    $datasetUserId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDatasetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    datasetsByDatasetUserIdAndCreatedAt(
      datasetUserId: $datasetUserId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        count
        status
        createdAt
        updatedAt
        datasetUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        images {
          nextToken
        }
        projects {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const imagesByImageDatasetIdAndCreatedAt = /* GraphQL */ `
  query ImagesByImageDatasetIdAndCreatedAt(
    $imageDatasetId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    imagesByImageDatasetIdAndCreatedAt(
      imageDatasetId: $imageDatasetId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        key
        url
        status
        createdAt
        imageDatasetId
        dataset {
          id
          name
          description
          count
          status
          createdAt
          updatedAt
          datasetUserId
        }
        width
        height
        thumbnail {
          key
          width
          height
        }
        generateEmbeddings {
          samH
          samB
          samL
        }
        embeddings {
          samH
          samB
          samL
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const imagesByDataset = /* GraphQL */ `
  query ImagesByDataset(
    $imageDatasetId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    imagesByDataset(
      imageDatasetId: $imageDatasetId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        key
        url
        status
        createdAt
        imageDatasetId
        dataset {
          id
          name
          description
          count
          status
          createdAt
          updatedAt
          datasetUserId
        }
        width
        height
        thumbnail {
          key
          width
          height
        }
        generateEmbeddings {
          samH
          samB
          samL
        }
        embeddings {
          samH
          samB
          samL
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const teamByTeamUserId = /* GraphQL */ `
  query TeamByTeamUserId(
    $teamUserId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamByTeamUserId(
      teamUserId: $teamUserId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdAt
        updatedAt
        teamUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        status
        owner
        access
        members {
          nextToken
        }
        projects {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const membersByMemberTeamIdAndCreatedAt = /* GraphQL */ `
  query MembersByMemberTeamIdAndCreatedAt(
    $memberTeamId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    membersByMemberTeamIdAndCreatedAt(
      memberTeamId: $memberTeamId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        role
        createdAt
        updatedAt
        memberTeamId
        team {
          id
          name
          createdAt
          updatedAt
          teamUserId
          status
          owner
          access
        }
        memberUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const membersByMemberUserIdAndCreatedAt = /* GraphQL */ `
  query MembersByMemberUserIdAndCreatedAt(
    $memberUserId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    membersByMemberUserIdAndCreatedAt(
      memberUserId: $memberUserId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        role
        createdAt
        updatedAt
        memberTeamId
        team {
          id
          name
          createdAt
          updatedAt
          teamUserId
          status
          owner
          access
        }
        memberUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const projectsByProjectTeamIdAndCreatedAt = /* GraphQL */ `
  query ProjectsByProjectTeamIdAndCreatedAt(
    $projectTeamId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectsByProjectTeamIdAndCreatedAt(
      projectTeamId: $projectTeamId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        categories {
          color
          name
          type
          parent
          options
          required
          classType
          multiSelect
        }
        categoriesCount {
          name
          count
          countSaved
          countReviewed
        }
        createdAt
        updatedAt
        access
        owner
        status
        tagsCount
        datasetSort
        reviewType
        tagsSaveCount
        tagsReviewCount
        imagesSavedCount
        imagesReviewedCount
        projectTeamId
        team {
          id
          name
          createdAt
          updatedAt
          teamUserId
          status
          owner
          access
        }
        projectUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        datasets {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const projectsByProjectUserIdAndCreatedAt = /* GraphQL */ `
  query ProjectsByProjectUserIdAndCreatedAt(
    $projectUserId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectsByProjectUserIdAndCreatedAt(
      projectUserId: $projectUserId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        categories {
          color
          name
          type
          parent
          options
          required
          classType
          multiSelect
        }
        categoriesCount {
          name
          count
          countSaved
          countReviewed
        }
        createdAt
        updatedAt
        access
        owner
        status
        tagsCount
        datasetSort
        reviewType
        tagsSaveCount
        tagsReviewCount
        imagesSavedCount
        imagesReviewedCount
        projectTeamId
        team {
          id
          name
          createdAt
          updatedAt
          teamUserId
          status
          owner
          access
        }
        projectUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        datasets {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const projectDataByProjectDataProjectIdAndCreatedAt = /* GraphQL */ `
  query ProjectDataByProjectDataProjectIdAndCreatedAt(
    $projectDataProjectId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectDataByProjectDataProjectIdAndCreatedAt(
      projectDataProjectId: $projectDataProjectId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectDataProjectId
        project {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        projectDataDatasetId
        dataset {
          id
          name
          description
          count
          status
          createdAt
          updatedAt
          datasetUserId
        }
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const projectDataByProjectDataDatasetIdAndCreatedAt = /* GraphQL */ `
  query ProjectDataByProjectDataDatasetIdAndCreatedAt(
    $projectDataDatasetId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectDataByProjectDataDatasetIdAndCreatedAt(
      projectDataDatasetId: $projectDataDatasetId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectDataProjectId
        project {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        projectDataDatasetId
        dataset {
          id
          name
          description
          count
          status
          createdAt
          updatedAt
          datasetUserId
        }
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const reqByProject = /* GraphQL */ `
  query ReqByProject(
    $requestProjectId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRequestJsonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reqByProject(
      requestProjectId: $requestProjectId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        owner
        createdAt
        format
        tagsCount
        imagesCount
        status
        key
        requestProjectId
        project {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      id
      height
      width
      key
      done
      skiped
      accepted
      states {
        items {
          id
          createdAt
          stage
          action
          tagSateTagId
          toBlameId
          role
          updatedAt
        }
        nextToken
      }
      annotations {
        circles {
          id
          childs
          name
          color
          type
          text
        }
        lines {
          id
          childs
          name
          color
          type
          text
        }
        points {
          id
          childs
          name
          color
          type
          text
        }
        polys {
          id
          childs
          name
          color
          type
          text
        }
        rects {
          id
          childs
          name
          color
          type
          pos
          parent
          text
        }
        classification {
          name
          options
          selected
          values
          required
          classType
          multiSelect
        }
      }
      categoriesCount {
        name
        count
      }
      count
      time
      labelerTime
      reviewerTime
      timestamp
      status
      createdAt
      updatedAt
      assignedAt
      reviewAssignedAt
      savedAt
      reviewedAt
      skippedAt
      tagLabelerId
      labeler {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      tagReviewerId
      reviewer {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      tagImageId
      image {
        id
        key
        url
        status
        createdAt
        imageDatasetId
        dataset {
          id
          name
          description
          count
          status
          createdAt
          updatedAt
          datasetUserId
        }
        width
        height
        thumbnail {
          key
          width
          height
        }
        generateEmbeddings {
          samH
          samB
          samL
        }
        embeddings {
          samH
          samB
          samL
        }
        updatedAt
      }
      tagProjectId
      project {
        id
        name
        categories {
          color
          name
          type
          parent
          options
          required
          classType
          multiSelect
        }
        categoriesCount {
          name
          count
          countSaved
          countReviewed
        }
        createdAt
        updatedAt
        access
        owner
        status
        tagsCount
        datasetSort
        reviewType
        tagsSaveCount
        tagsReviewCount
        imagesSavedCount
        imagesReviewedCount
        projectTeamId
        team {
          id
          name
          createdAt
          updatedAt
          teamUserId
          status
          owner
          access
        }
        projectUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        datasets {
          nextToken
        }
      }
      comments {
        items {
          id
          createdAt
          closed
          recordType
          Type
          commentTagId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listTags = /* GraphQL */ `
  query ListTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        height
        width
        key
        done
        skiped
        accepted
        states {
          nextToken
        }
        categoriesCount {
          name
          count
        }
        count
        time
        labelerTime
        reviewerTime
        timestamp
        status
        createdAt
        updatedAt
        assignedAt
        reviewAssignedAt
        savedAt
        reviewedAt
        skippedAt
        tagLabelerId
        labeler {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagReviewerId
        reviewer {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagImageId
        image {
          id
          key
          url
          status
          createdAt
          imageDatasetId
          width
          height
          updatedAt
        }
        tagProjectId
        project {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        comments {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const tagsByLabeler = /* GraphQL */ `
  query TagsByLabeler(
    $tagLabelerId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tagsByLabeler(
      tagLabelerId: $tagLabelerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        height
        width
        key
        done
        skiped
        accepted
        states {
          nextToken
        }
        categoriesCount {
          name
          count
        }
        count
        time
        labelerTime
        reviewerTime
        timestamp
        status
        createdAt
        updatedAt
        assignedAt
        reviewAssignedAt
        savedAt
        reviewedAt
        skippedAt
        tagLabelerId
        labeler {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagReviewerId
        reviewer {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagImageId
        image {
          id
          key
          url
          status
          createdAt
          imageDatasetId
          width
          height
          updatedAt
        }
        tagProjectId
        project {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        comments {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const tagsByReviwer = /* GraphQL */ `
  query TagsByReviwer(
    $tagReviewerId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tagsByReviwer(
      tagReviewerId: $tagReviewerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        height
        width
        key
        done
        skiped
        accepted
        states {
          nextToken
        }
        categoriesCount {
          name
          count
        }
        count
        time
        labelerTime
        reviewerTime
        timestamp
        status
        createdAt
        updatedAt
        assignedAt
        reviewAssignedAt
        savedAt
        reviewedAt
        skippedAt
        tagLabelerId
        labeler {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagReviewerId
        reviewer {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagImageId
        image {
          id
          key
          url
          status
          createdAt
          imageDatasetId
          width
          height
          updatedAt
        }
        tagProjectId
        project {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        comments {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const tagsByTagImageId = /* GraphQL */ `
  query TagsByTagImageId(
    $tagImageId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tagsByTagImageId(
      tagImageId: $tagImageId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        height
        width
        key
        done
        skiped
        accepted
        states {
          nextToken
        }
        categoriesCount {
          name
          count
        }
        count
        time
        labelerTime
        reviewerTime
        timestamp
        status
        createdAt
        updatedAt
        assignedAt
        reviewAssignedAt
        savedAt
        reviewedAt
        skippedAt
        tagLabelerId
        labeler {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagReviewerId
        reviewer {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagImageId
        image {
          id
          key
          url
          status
          createdAt
          imageDatasetId
          width
          height
          updatedAt
        }
        tagProjectId
        project {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        comments {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const tagsByTagProjectIdAndCreatedAt = /* GraphQL */ `
  query TagsByTagProjectIdAndCreatedAt(
    $tagProjectId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tagsByTagProjectIdAndCreatedAt(
      tagProjectId: $tagProjectId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        height
        width
        key
        done
        skiped
        accepted
        states {
          nextToken
        }
        categoriesCount {
          name
          count
        }
        count
        time
        labelerTime
        reviewerTime
        timestamp
        status
        createdAt
        updatedAt
        assignedAt
        reviewAssignedAt
        savedAt
        reviewedAt
        skippedAt
        tagLabelerId
        labeler {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagReviewerId
        reviewer {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagImageId
        image {
          id
          key
          url
          status
          createdAt
          imageDatasetId
          width
          height
          updatedAt
        }
        tagProjectId
        project {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        comments {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const tagsByProject = /* GraphQL */ `
  query TagsByProject(
    $tagProjectId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tagsByProject(
      tagProjectId: $tagProjectId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        height
        width
        key
        done
        skiped
        accepted
        states {
          nextToken
        }
        categoriesCount {
          name
          count
        }
        count
        time
        labelerTime
        reviewerTime
        timestamp
        status
        createdAt
        updatedAt
        assignedAt
        reviewAssignedAt
        savedAt
        reviewedAt
        skippedAt
        tagLabelerId
        labeler {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagReviewerId
        reviewer {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagImageId
        image {
          id
          key
          url
          status
          createdAt
          imageDatasetId
          width
          height
          updatedAt
        }
        tagProjectId
        project {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        comments {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const tagsByProjectReviewedAt = /* GraphQL */ `
  query TagsByProjectReviewedAt(
    $tagProjectId: ID!
    $reviewedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tagsByProjectReviewedAt(
      tagProjectId: $tagProjectId
      reviewedAt: $reviewedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        height
        width
        key
        done
        skiped
        accepted
        states {
          nextToken
        }
        categoriesCount {
          name
          count
        }
        count
        time
        labelerTime
        reviewerTime
        timestamp
        status
        createdAt
        updatedAt
        assignedAt
        reviewAssignedAt
        savedAt
        reviewedAt
        skippedAt
        tagLabelerId
        labeler {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagReviewerId
        reviewer {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagImageId
        image {
          id
          key
          url
          status
          createdAt
          imageDatasetId
          width
          height
          updatedAt
        }
        tagProjectId
        project {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        comments {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const tagsByProjectSavedAt = /* GraphQL */ `
  query TagsByProjectSavedAt(
    $tagProjectId: ID!
    $savedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tagsByProjectSavedAt(
      tagProjectId: $tagProjectId
      savedAt: $savedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        height
        width
        key
        done
        skiped
        accepted
        states {
          nextToken
        }
        categoriesCount {
          name
          count
        }
        count
        time
        labelerTime
        reviewerTime
        timestamp
        status
        createdAt
        updatedAt
        assignedAt
        reviewAssignedAt
        savedAt
        reviewedAt
        skippedAt
        tagLabelerId
        labeler {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagReviewerId
        reviewer {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagImageId
        image {
          id
          key
          url
          status
          createdAt
          imageDatasetId
          width
          height
          updatedAt
        }
        tagProjectId
        project {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        comments {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchTags = /* GraphQL */ `
  query SearchTags(
    $filter: SearchableTagFilterInput
    $sort: [SearchableTagSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableTagAggregationInput]
  ) {
    searchTags(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        height
        width
        key
        done
        skiped
        accepted
        states {
          nextToken
        }
        categoriesCount {
          name
          count
        }
        count
        time
        labelerTime
        reviewerTime
        timestamp
        status
        createdAt
        updatedAt
        assignedAt
        reviewAssignedAt
        savedAt
        reviewedAt
        skippedAt
        tagLabelerId
        labeler {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagReviewerId
        reviewer {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagImageId
        image {
          id
          key
          url
          status
          createdAt
          imageDatasetId
          width
          height
          updatedAt
        }
        tagProjectId
        project {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        comments {
          nextToken
        }
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      createdAt
      messages {
        message
        messageUserId
        username
        role
        createdAt
      }
      closed
      recordType
      Type
      commentTagId
      tag {
        id
        height
        width
        key
        done
        skiped
        accepted
        states {
          nextToken
        }
        categoriesCount {
          name
          count
        }
        count
        time
        labelerTime
        reviewerTime
        timestamp
        status
        createdAt
        updatedAt
        assignedAt
        reviewAssignedAt
        savedAt
        reviewedAt
        skippedAt
        tagLabelerId
        labeler {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagReviewerId
        reviewer {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagImageId
        image {
          id
          key
          url
          status
          createdAt
          imageDatasetId
          width
          height
          updatedAt
        }
        tagProjectId
        project {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        comments {
          nextToken
        }
      }
      updatedAt
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        messages {
          message
          messageUserId
          username
          role
          createdAt
        }
        closed
        recordType
        Type
        commentTagId
        tag {
          id
          height
          width
          key
          done
          skiped
          accepted
          count
          time
          labelerTime
          reviewerTime
          timestamp
          status
          createdAt
          updatedAt
          assignedAt
          reviewAssignedAt
          savedAt
          reviewedAt
          skippedAt
          tagLabelerId
          tagReviewerId
          tagImageId
          tagProjectId
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const commentsByCommentTagIdAndCreatedAt = /* GraphQL */ `
  query CommentsByCommentTagIdAndCreatedAt(
    $commentTagId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentsByCommentTagIdAndCreatedAt(
      commentTagId: $commentTagId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        messages {
          message
          messageUserId
          username
          role
          createdAt
        }
        closed
        recordType
        Type
        commentTagId
        tag {
          id
          height
          width
          key
          done
          skiped
          accepted
          count
          time
          labelerTime
          reviewerTime
          timestamp
          status
          createdAt
          updatedAt
          assignedAt
          reviewAssignedAt
          savedAt
          reviewedAt
          skippedAt
          tagLabelerId
          tagReviewerId
          tagImageId
          tagProjectId
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTagState = /* GraphQL */ `
  query GetTagState($id: ID!) {
    getTagState(id: $id) {
      id
      createdAt
      stage
      action
      tagSateTagId
      tag {
        id
        height
        width
        key
        done
        skiped
        accepted
        states {
          nextToken
        }
        categoriesCount {
          name
          count
        }
        count
        time
        labelerTime
        reviewerTime
        timestamp
        status
        createdAt
        updatedAt
        assignedAt
        reviewAssignedAt
        savedAt
        reviewedAt
        skippedAt
        tagLabelerId
        labeler {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagReviewerId
        reviewer {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagImageId
        image {
          id
          key
          url
          status
          createdAt
          imageDatasetId
          width
          height
          updatedAt
        }
        tagProjectId
        project {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        comments {
          nextToken
        }
      }
      toBlameId
      toBlame {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      role
      updatedAt
    }
  }
`;
export const listTagStates = /* GraphQL */ `
  query ListTagStates(
    $filter: ModelTagStateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTagStates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        stage
        action
        tagSateTagId
        tag {
          id
          height
          width
          key
          done
          skiped
          accepted
          count
          time
          labelerTime
          reviewerTime
          timestamp
          status
          createdAt
          updatedAt
          assignedAt
          reviewAssignedAt
          savedAt
          reviewedAt
          skippedAt
          tagLabelerId
          tagReviewerId
          tagImageId
          tagProjectId
        }
        toBlameId
        toBlame {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        role
        updatedAt
      }
      nextToken
    }
  }
`;
export const tagStatesByTagSateTagIdAndCreatedAt = /* GraphQL */ `
  query TagStatesByTagSateTagIdAndCreatedAt(
    $tagSateTagId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTagStateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tagStatesByTagSateTagIdAndCreatedAt(
      tagSateTagId: $tagSateTagId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        stage
        action
        tagSateTagId
        tag {
          id
          height
          width
          key
          done
          skiped
          accepted
          count
          time
          labelerTime
          reviewerTime
          timestamp
          status
          createdAt
          updatedAt
          assignedAt
          reviewAssignedAt
          savedAt
          reviewedAt
          skippedAt
          tagLabelerId
          tagReviewerId
          tagImageId
          tagProjectId
        }
        toBlameId
        toBlame {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        role
        updatedAt
      }
      nextToken
    }
  }
`;
