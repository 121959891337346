import { useState, useEffect, useContext } from 'react'
import Axios from 'axios'
import { useSelector } from 'react-redux'

import { getUserToken } from '../../../../../../../utils/util'
import { useInfiniteQuery } from '@tanstack/react-query'
import { FiltersContext } from '../../../context/FiltersContext'
import {
  getSegments,
  searchPage,
  shouldShowLoadMoreResults,
  shouldShowLoadPrevResults
} from '../utils/utilsVisualizerGrid'

const SIZE_REQUEST = 50
const AUX_INDEX = 200
const MAX_PAGES = 5

const useImages = () => {
  const [currentPage, setCurrentPage] = useState(0)

  const { state, dispatch } = useContext(FiltersContext)
  const project = useSelector((state) => state.project)

  const {
    order,
    labelers,
    reviewers,
    categories,
    names,
    accepted,
    done,
    skiped,
    count,
    classifications,
    labelClassifications,
    images
  } = state

  const projectId = project.id
  const datasetId = project.datasets[0].dataset.id

  // Build conditions
  const updatedClassifications = { ...classifications }

  const classificationsData = project.categories.filter(
    (category) => category.type === 'classification' && category.parent === null
  )

  const processedClassifications = classificationsData.map((classification) => {
    const { name, options } = classification
    const classificationOptions = ['Empty', ...options]
    return { [name]: classificationOptions }
  })

  const conditions = {
    and: {
      key: names.map((name) => name.name),
      labelerIds: labelers,
      reviewerIds: reviewers,
      count: count.map((label) => ({ symbol: label.symbol, value: label.value })),
      accepted: accepted.value === 'all' ? {} : { value: accepted.value },
      done: done.value === 'all' ? {} : { value: done.value },
      skiped: skiped.value === 'all' ? [] : { value: skiped.value }
    },
    or: {
      classifications: updatedClassifications,
      children: labelClassifications,
      categories: categories.map((category) => category)
    },
    not: { classifications: {} }
  }

  const hasEmptyClassification = Object.keys(updatedClassifications).some((key) =>
    updatedClassifications[key].includes('Empty')
  )

  if (hasEmptyClassification) {
    Object.keys(updatedClassifications).forEach((key) => {
      if (updatedClassifications[key].includes('Empty')) {
        const selectedClassifications = updatedClassifications[key].filter(
          (item) => item !== 'Empty'
        )
        const allClassifications = processedClassifications.find(
          (item) => Object.keys(item)[0] === key
        )[key]
        const unselectedClassifications = allClassifications.filter(
          (item) => !selectedClassifications.includes(item) && item !== 'Empty'
        )
        conditions.not.classifications[key] = unselectedClassifications
        conditions.or.classifications[key] = updatedClassifications[key].filter(
          (item) => item !== 'Empty'
        )
      }
    })
  }

  // Fetch and build data
  const handleSetImages = (data, idToken, dataset, total, pageParam) => {
    dispatch({ type: 'setTotalImagesInRequest', payload: total })
    return data.map((image) => ({
      src: `https://d364olzi1d53qw.cloudfront.net/${dataset}/891x501${image._source.key.slice(
        image._source.key.lastIndexOf('/')
      )}?token=${idToken}`,
      loading: false,
      accepted: image._source.accepted,
      done: image._source.done,
      annotations: image._source.annotations,
      width: image._source.width,
      height: image._source.height,
      selected: false,
      id: image._id,
      name: image._source.fileName,
      skiped: image._source.skiped,
      index: pageParam + image.index
    }))
  }

  const fetchImages = async ({ pageParam = currentPage }) => {
    const idToken = await getUserToken()
    return await Axios.post(
      'https://api.linkedai.co/v2/projects/' + projectId + '/tags',
      {
        sort: {
          [order.type]: {
            order: order.order
          }
        },
        from: pageParam,
        size: SIZE_REQUEST,
        conditions
      },
      {
        headers: {
          Authorization: idToken
        }
      }
    )
      .then((res) => {
        if (res.status !== 200) throw new Error('Request Error')
        return res
      })
      .then(async (res) => {
        const paginationData = JSON.parse(res.config.data)
        const currentCursor = paginationData.from
        const nextCursor = currentCursor + SIZE_REQUEST
        const prevCursor = currentCursor - SIZE_REQUEST
        const imagesData = res.data.hits.hits
        const idTokenPromise = await getUserToken()
        const images = handleSetImages(
          imagesData,
          idTokenPromise,
          datasetId,
          res.data.hits.total.value,
          pageParam
        )
        return {
          images,
          nextCursor,
          prevCursor,
          currentCursor
        }
      })
  }

  const { isLoading, isError, data, fetchNextPage, remove } = useInfiniteQuery(
    ['images'],
    fetchImages,
    {
      getNextPageParam: () => images[images.length - 1]?.nextCursor,
      refetchOnWindowFocus: false
    }
  )

  useEffect(() => {
    if (data) {
      if (currentPage <= AUX_INDEX) {
        dispatch({ type: 'setImages', payload: data?.pages })
      } else {
        const start = (currentPage - AUX_INDEX) / SIZE_REQUEST
        const end = currentPage / SIZE_REQUEST
        const segments = getSegments(data.pages, start, end)
        dispatch({ type: 'setImages', payload: segments })
      }
    }
  }, [data, dispatch])

  useEffect(() => {
    remove()
    setCurrentPage(0)
    dispatch({ type: 'setImages', payload: [] })
    fetchImages(0)
  }, [
    labelers,
    reviewers,
    categories,
    names,
    accepted,
    done,
    skiped,
    count,
    classifications,
    labelClassifications,
    order
  ])

  const loadMoreImages = () => {
    const lastResult = images[images.length - 1]

    setCurrentPage(lastResult.nextCursor)

    const pageIsLoaded = searchPage(data.pages, 'nextCursor', lastResult.nextCursor + SIZE_REQUEST)

    if (pageIsLoaded) {
      const start = (lastResult.nextCursor - AUX_INDEX) / SIZE_REQUEST
      const end = lastResult.nextCursor / SIZE_REQUEST
      const segments = getSegments(data.pages, start, end)
      dispatch({ type: 'setImages', payload: segments })
    } else {
      fetchNextPage()
    }
  }

  const loadPrevImages = async () => {
    setCurrentPage(images[0].prevCursor)
    const start = images[0].prevCursor / SIZE_REQUEST
    const end = (images[0].prevCursor + AUX_INDEX) / SIZE_REQUEST
    const segments = getSegments(data.pages, start, end)
    dispatch({ type: 'setImages', payload: segments })
    document.getElementById('infinite-scroll-container').scrollTop = 100
  }

  const totalImagesInRequest = state.totalImagesInRequest
  const totalDataLoaded = data?.pages.flatMap((page) => page.images).length
  const pagesLoaded = data?.pages.length

  return {
    isLoading,
    isError,
    loadMoreImages,
    loadPrevImages,
    shouldShowLoadPrevResults: shouldShowLoadPrevResults(
      currentPage,
      AUX_INDEX,
      pagesLoaded,
      MAX_PAGES
    ),
    shouldShowLoadMoreResults: shouldShowLoadMoreResults(
      totalDataLoaded,
      totalImagesInRequest,
      currentPage,
      SIZE_REQUEST,
      pagesLoaded
    ),
    currentPage
  }
}

export default useImages
