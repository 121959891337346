import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useTheme } from '@mui/material/styles'
import { Grid, Typography } from '@mui/material'
import { getUser } from '../../../../utils/util'
import { dialog } from '../../../../io/dialogs/actions'
import { project as actionsProject } from '../../../../io/project/actions'
import ImagesMetricsCard from './components/imagesMetricsCard/ImagesMetricsCard'
import LabelsMetricsCard from './components/labelsMetricsCard/LabelsMetricsCard'
import UserMetricsDialog from '../../../../components/generics/dialogs/labelerMetrics/UserMetricsDialog'
import ImagesMetricsTable from './components/imagesMetricsTable/ImagesMetricsTable'
import LabelsMetricsTrable from './components/LabelsMetricsTrable'

const ProjectOverview = ({
  project,
  getLabelerStats,
  getReviwerStats,
  setLabelerEmail,
  setReviwerEmail,
  setDialogStatus
}) => {
  const theme = useTheme()

  const [labelFilter, setLabelType] = useState('saved')
  const [imgFilter, setImgFilter] = useState('saved')

  const isPaid = getUser() ? getUser().paid : false

  const handleClick = (id, email) => {
    if (imgFilter === 'saved') {
      getLabelerStats(project.id, id)
      setLabelerEmail(email, id)
    } else {
      getReviwerStats(project.id, id)
      setReviwerEmail(email, id)
    }
    setDialogStatus(true)
  }

  const handleImageFilter = (newStatus) => {
    setImgFilter(newStatus)
  }

  const handleLabelFilter = (newStatus) => {
    setLabelType(newStatus)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ textAlign: 'left' }}>
          Progress
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <ImagesMetricsCard isPaid={isPaid} imgFilter={imgFilter} setImgFilter={handleImageFilter} />
        {isPaid && (
          <ImagesMetricsTable
            imgFilter={imgFilter}
            project={project}
            handleClick={handleClick}
            theme={theme}
          />
        )}
      </Grid>
      <Grid item xs>
        <LabelsMetricsCard
          isPaid={isPaid}
          labelFilter={labelFilter}
          setLabelFilter={handleLabelFilter}
        />
        {isPaid && (
          <LabelsMetricsTrable labelFilter={labelFilter} project={project} theme={theme} />
        )}
      </Grid>
      <UserMetricsDialog imgFilter={imgFilter} />
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  project: state.project
})

const mapDispatchToProps = {
  setDialogStatus: dialog.setStatusLabelerMetricsDialog,
  getLabelerStats: actionsProject.getLabelerStats,
  getReviwerStats: actionsProject.getReviwerStats,
  setLabelerEmail: dialog.setLabelerEmail,
  setReviwerEmail: dialog.setReviwerEmail
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectOverview)
