import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as Sentry from '@sentry/browser'
import { signup } from '../../services/aws'
import { sendRegisterEmail } from '../../io/api/admin'
import { modals } from '../../services/modals/modalsActions'
import { FormControl, FormLabel, OutlinedInput } from '@mui/material'
import LoaderButton from '../LoaderButton'
import { EyeAdornment } from './EyeAdornment'
import { useSnackbar } from '../snackbar/context/SnackbarContext'

function SignupForm(props) {
  const [errorPass, setErrorPass] = useState(false)
  const [error, setError] = useState(null)
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: ''
  })
  const [isLoading, setIsLoading] = useState(false)
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirm: false
  })
  const handleShowPassword = (name) => {
    setShowPassword({ ...showPassword, [name]: !showPassword[name] })
  }

  const { showSnackbar } = useSnackbar()

  const validatedEmail = (email) => {
    if (!email.includes('@')) return false
    const domain = email.split('@')[1]
    if (!(domain.length > 0 && domain.includes('.'))) return false
    if (domain.split('.')[1].length < 2) return false
    return true
  }
  const validateForm = () => {
    const vEmail = validatedEmail(formData.email)
    const validated =
      formData.email.length > 0 &&
      formData.password.length > 0 &&
      formData.password === formData.confirmPassword
    if (!vEmail) {
      showSnackbar({
        message: 'Invalid email.',
        type: 'error'
      })
    } else if (formData.password !== formData.confirmPassword) {
      showSnackbar({
        message: "Passwords don't match.",
        type: 'error'
      })
      setErrorPass(true)
    } else if (!validated) {
      showSnackbar({
        message: 'Missing fields.',
        type: 'error'
      })
    }
    return validated && vEmail
  }
  const handleChange = (event) => {
    const { name, value } = event.target
    if (name !== 'email') {
      setErrorPass(false)
    }
    setFormData({ ...formData, [name]: value })
  }
  const signupUser = async (event) => {
    setIsLoading(true)
    event.preventDefault()
    if (validateForm()) {
      setError(null)
      const { email, password } = formData
      // Send data
      // supportUser({ name, email}) // TODO: Move this to Additional info
      sendRegisterEmail('PRE REGISTER', email, '', '', 'This user try to register').catch(
        (error) => {
          Sentry.captureMessage(error)
          console.log('Error', error)
        }
      )
      signup(email, password)
        .then((res) => {
          if (res.ok) {
            props.onDone(email)
          } else {
            Sentry.captureMessage('Auth sign up: ' + res)
            setError('Problem with sign up. Contact support')
            setIsLoading(false)
          }
        })
        .catch((err) => {
          Sentry.captureMessage({ error: err, email: formData.email })
          const { message } = err
          if (message) {
            if (message.toLowerCase().includes('password')) {
              setErrorPass(true)
            }
            setError(message)
          } else {
            setError('Problem with sign up. Contact support')
          }
          setIsLoading(false)
        })
    } else {
      setIsLoading(false)
    }
  }
  return (
    <div className="Signup">
      <form onSubmit={signupUser}>
        <FormControl variant="standard" fullWidth size="small">
          <FormLabel style={{ fontWeight: 600 }}>Email</FormLabel>
          <OutlinedInput
            margin="dense"
            name="email"
            id="outlined-basic"
            onChange={(event) => handleChange(event)}
            type="email"
            value={formData.email}
            variant="outlined"
          />
        </FormControl>
        <FormControl fullWidth margin="normal" size="small" variant="outlined">
          <FormLabel style={{ fontWeight: 600 }}>Password</FormLabel>
          <OutlinedInput
            endAdornment={
              <EyeAdornment handleShow={handleShowPassword} name="password" show={showPassword} />
            }
            error={errorPass}
            margin="dense"
            name="password"
            id="outlined-adornment-password"
            onChange={(event) => handleChange(event)}
            type={showPassword.password ? 'text' : 'password'}
            value={formData.password}
          />
          <span className="pass-class-before">
            Must include:{' '}
            <span className="pass-class-after">Uppercase, lowercase, number and symbol.</span>
          </span>
        </FormControl>
        <FormControl fullWidth margin="normal" size="small" variant="outlined">
          <FormLabel style={{ fontWeight: 600 }}>Confirm Password</FormLabel>
          <OutlinedInput
            endAdornment={
              <EyeAdornment handleShow={handleShowPassword} name="confirm" show={showPassword} />
            }
            error={errorPass}
            margin="dense"
            name="confirmPassword"
            id="outlined-adornment-password"
            onChange={(event) => handleChange(event)}
            size="small"
            type={showPassword.confirm ? 'text' : 'password'}
            value={formData.confirmPassword}
          />
        </FormControl>
        {error && <p style={{ color: '#a94442', fontWeight: 'bold' }}>{error}</p>}
        <p style={{ fontSize: 10 }}>
          By signing up you accept our
          <a
            href="https://docs.google.com/document/d/1bydEAk2n748BdgtTEpLr-JoNdmHfMOjglqcapoPsMdg/edit?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            Terms and Conditions
          </a>
          ,{' '}
          <a
            href="https://docs.google.com/document/d/1zd27z7j2_qlmTLUSExyt8EBZH1uUJkvDQoT2rsFLyBM/edit?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            Privacy Policy
          </a>
          , and{' '}
          <a
            href="https://docs.google.com/document/d/1AhiRxRxnBFryyPXHjQjXIjUxft9FHp8oIZ4HMx2aQLA/edit?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            Copyright Dispute Policy
          </a>
        </p>
        <LoaderButton
          fullWidth
          color="primary"
          size="large"
          type="submit"
          isLoading={isLoading}
          text="Signup"
          loadingText="Signing up..."
        />
      </form>
    </div>
  )
}
SignupForm.propTypes = {
  props: PropTypes.object
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {
  setModalWelcome: modals.setModalWelcome
}

export default connect(mapStateToProps, mapDispatchToProps)(SignupForm)
