import React from 'react'
import Divider from '@mui/material/Divider'
import { styled } from '@mui/material/styles'
// import CompositeAITools from '../../../../components/Labeler/rightArea/AITools/CompositeAITools'
import CategoriesManager from '../../../../components/CategoriesManager'
import RectList from '../../../../components/Labeler/rightArea/rectList/CompositeRectList'
import ImgHandler from '../../../../components/Labeler/rightArea/imgHandler/CompositeImgHandler'

const Root = styled('div')(({ theme }) => ({
  background: theme.palette.background.paper,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  color: theme.palette.text.primary
}))

function RightArea(props) {
  return (
    <Root ref={props.reference}>
      <ImgHandler
        changeLoading={props.changeLoading}
        selectImage={props.selectImage}
        saveTags={props.saveTags}
      />
      <Divider />
      {/* HIDE TEMPORALY
      <CompositeAITools
        loading={props.loadingAI}
        showAiDialog={props.showAiDialog}
        drawPoints={props.drawPoints}
      /> */}
      <CategoriesManager
        changeCategory={props.changeCategory}
        addCategory={props.addCategory}
        deleteCategory={props.deleteCategory}
        editCategory={props.editCategory}
        categories={props.categories}
        mouseEnterOnCategory={props.mouseEnterOnCategory}
        mouseLeaveOnCategory={props.mouseLeaveOnCategory}
        currentCategoryTabCreate={props.currentCategoryTabCreate}
        changeCurrentCategoryTab={props.changeCurrentCategoryTab}
      />
      <Divider />
      <RectList
        lines={props.lines}
        circles={props.circles}
        mouseEnterOnList={props.mouseEnterOnList}
        mouseLeaveOnList={props.mouseLeaveOnList}
        deleteLine={props.deleteLine}
        deleteCircle={props.deleteCircle}
        hideLine={props.hideLine}
        hideCircle={props.hideCircle}
        ratio={props.ratio}
        selectItem={props.selectItem}
        selectedTag={props.selectedTag}
        handleClassChange={props.handleClassChange}
      />
    </Root>
  )
}

export default RightArea
