import React from 'react'
import { Button } from '@mui/material'
import { GroupButton } from '../../StyledComponents'

const FilterButtons = ({ imgFilter, setImgFilter }) => (
  <GroupButton size="small" color="primary">
    <Button
      variant={imgFilter === 'saved' ? 'contained' : 'outlined'}
      onClick={() => setImgFilter('saved')}
    >
      Done
    </Button>
    <Button
      variant={imgFilter === 'reviewed' ? 'contained' : 'outlined'}
      onClick={() => setImgFilter('reviewed')}
    >
      Reviewed
    </Button>
  </GroupButton>
)

export default FilterButtons
