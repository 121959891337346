import React from 'react'
import { Grid } from '@mui/material'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { federatedSignIn } from '../../../io/aws/auth'
import LoginForm from './components/LoginForm'
import GoogleSignInButton from './components/GoogleSignInButton'
import { DivContainer, GridHeader, GridPanel, Img } from '../components/StyledComponents'

const Login = (props) => {
  return (
    <DivContainer>
      <Grid container spacing={2}>
        <GridHeader item xs={12}>
          <h4 style={{ color: '#7a8697' }}>LOGIN TO</h4>
          <Img src={props.logo} />
        </GridHeader>
        <Grid item xs={2} />
        <GridPanel item md={3} xs={9}>
          <h3
            sx={{
              textAlign: 'center',
              fontSize: '20px',
              paddingBottom: '15px',
              marginTop: 0
            }}
          >
            You Rock!
          </h3>
          <LoginForm {...props} submit="Login" message="Login Success." />
          <GoogleSignInButton onClick={federatedSignIn} />
          <p
            style={{
              fontWeight: 'bold',
              padding: '15px',
              textAlign: 'center'
            }}
          >
            Don&apos;t have an account?{' '}
            <Link to="/signup">
              <span className="blue-text">Sign up</span>
            </Link>
          </p>
        </GridPanel>
        <Grid item md={1} xs={2} display={{ xs: 'block', md: 'none' }} />
        <GridPanel item md={5} xs={9}>
          <div
            style={{
              display: 'flex',
              height: '100%',
              margin: '0 auto',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            {/* <Img src={props.logo} /> */}
            <img
              style={{ maxWidth: '100%' }}
              src={`${process.env.PUBLIC_URL}/assets/login_image.gif`}
            />
          </div>
        </GridPanel>
      </Grid>
    </DivContainer>
  )
}

const mapStateToProps = (state) => ({
  logo: state.ui.logo
})

export default connect(mapStateToProps, null)(Login)
