export const TYPES = {
  RECT: 'bounding_box',
  POLY: 'polygon',
  CIRCLE: 'ellipse',
  LINE: 'line',
  POINT: 'point',
  SEGMENTATION: 'segmentation',
  CLASSIFICATION: 'classification'
}

export const TYPES_ATTRS = [
  { id: TYPES.RECT, name: 'Bounding Box' },
  { id: TYPES.POLY, name: 'Polygon' },
  // { id: TYPES.CIRCLE, name : 'Ellipse' }, TODO: Temporaly Unavailable
  { id: TYPES.LINE, name: 'Line' },
  { id: TYPES.POINT, name: 'Point' },
  { id: TYPES.SEGMENTATION, name: 'Segmentation' },
  { id: TYPES.CLASSIFICATION, name: 'Classification' }
]

// TODO: Empty states
export const EMPTY = {
  labeler: {
    edit: {
      index: -1,
      element: null,
      clickedCase: null,
      grabPoint: null
    }
  }
}
