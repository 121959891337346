import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  Grid,
  Paper,
  Button,
  ButtonGroup,
  Typography,
  Breadcrumbs,
  DialogContentText
} from '@mui/material'
import { useTheme } from '@mui/material/styles'

import BarChart from '../../components/charts/Bar'
import LineChart from '../../components/charts/Line'
import TagsTable from './components/TagsTable'
import { getUser, getMemberRole } from '../../utils/util'
import { project } from '../../io/project/actions'
import { sandLabelerViewed } from '../../io/analytics'

const userType = {
  saved: { label: 'Labeler', email: 'labelerEmail', id: 'labelerId', stats: 'labelerStats' },
  reviewed: { label: 'Reviewer', email: 'reviwerEmail', id: 'reviwerId', stats: 'reviwerStats' }
}

function SharedProject(props) {
  const navigate = useNavigate()

  const theme = useTheme()

  const [user, setUser] = useState({})
  const [barFilter, setBarFilter] = useState('week')
  const [imgsType, setImgsType] = useState('saved')
  const [statusCondition, setStatusCondition] = useState(false)
  const [role, setRole] = useState('')
  const [idProject, setIdProject] = useState('')

  const { label, stats } = userType[imgsType]

  useEffect(() => {
    props.cleanProject()
    setRole('')
    setUser(getUser())
    const queryParams = new URLSearchParams(window.location.search)
    const id = queryParams.get('id')
    setIdProject(id)
  }, [])

  useEffect(() => {
    if (idProject) {
      props.getProject(idProject)
    }
  }, [idProject])

  useEffect(() => {
    if (props.project.team) {
      setRole(getMemberRole(props.project.team))
    }
    setInfo()
  }, [props.project])

  useEffect(() => {
    if (!imgsType || !props.project.id || !user.id) return

    if (imgsType === 'saved') {
      props.getLabelerStats(props.project.id, user.id)
    } else {
      props.getReviwerStats(props.project.id, user.id)
    }
  }, [imgsType, props.project.id, user])

  const setInfo = () => {
    if (role === 'admin' || role === 'viewer') {
      navigate(`/project?id=${idProject}`)
    }
  }

  const labelIt = (project, role) => {
    props.setProject(project)
    sandLabelerViewed({ id: project.id })
    setTimeout(() => {
      navigate(role ? `/labeler?role=${role}` : '/labeler')
    }, 200)
  }

  return (
    <div style={{ marginTop: '5rem' }}>
      <Paper elevation={0} style={{ marginLeft: '10%' }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" href="/projects" to="/projects">
            Projects
          </Link>
          <Typography color="textPrimary">Shared Project</Typography>
        </Breadcrumbs>
      </Paper>
      <Grid container sx={{ padding: '2rem 4rem 0rem 4rem' }}>
        <Grid item xs={12}>
          <Typography variant="h4">{props.project.name}</Typography>
        </Grid>

        <Grid item xs={12} sx={{ marginTop: '1rem', display: 'flex', justifyContent: 'center' }}>
          <div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <DialogContentText>{label}:&nbsp;</DialogContentText>
              <DialogContentText>{user.email}</DialogContentText>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <DialogContentText>{'ID'}:&nbsp;</DialogContentText>
              <DialogContentText>{user.id}</DialogContentText>
            </div>
          </div>
        </Grid>

        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'left' }}>
          <Typography variant="h6">
            {role === 'labeler'
              ? `Images Done: ${(props.project.labelerStats?.barImages.all || []).reduce(
                  (count, item) => {
                    return count + (item.doc_count || 0)
                  },
                  0
                )}`
              : `Images Reviewed: ${(props.project.reviewerStats?.barImages.all || []).reduce(
                  (count, item) => {
                    return count + (item.doc_count || 0)
                  },
                  0
                )}`}
          </Typography>
        </Grid>

        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'right' }}>
          <Button
            id="startlabeling"
            size="small"
            color="secondary"
            variant="contained"
            onClick={() => labelIt(props.project)}
          >
            {role === 'reviewer' ? 'Start Reviewing' : 'Start Labeling'}
          </Button>
        </Grid>

        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'left' }}>
          <ButtonGroup sx={{ paddingTop: 2, paddingBottom: 2 }} size="small" color="primary">
            <Button
              variant={statusCondition === false ? 'contained' : 'outlined'}
              onClick={() => setStatusCondition(false)}
            >
              Pending
            </Button>
            <Button
              variant={statusCondition === true ? 'contained' : 'outlined'}
              onClick={() => setStatusCondition(true)}
            >
              {role === 'labeler' ? 'Done' : 'Accepted'}
            </Button>
            <Button
              variant={statusCondition === 'all' ? 'contained' : 'outlined'}
              onClick={() => setStatusCondition('all')}
            >
              All
            </Button>
          </ButtonGroup>
        </Grid>

        <Grid item xs={12} sx={{ marginTop: '1rem' }}>
          <TagsTable
            project={props.project}
            projectId={idProject}
            role={role}
            statusCondition={statusCondition}
          />
        </Grid>

        <Grid item xs={12}>
          <ButtonGroup sx={{ paddingTop: 2, paddingBottom: 2 }} size="small" color="primary">
            <Button
              variant={imgsType === 'saved' ? 'contained' : 'outlined'}
              onClick={() => setImgsType('saved')}
            >
              Done
            </Button>
            <Button
              variant={imgsType === 'reviewed' ? 'contained' : 'outlined'}
              onClick={() => setImgsType('reviewed')}
            >
              Reviewed
            </Button>
          </ButtonGroup>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              margin: '0rem 1rem'
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Paper elevation={2}>
                  <div style={{ paddingTop: 20 }}>
                    {barFilter !== 'all' ? (
                      <BarChart
                        datasets={[
                          {
                            data: props[stats].barImages,
                            label: 'Images',
                            color: theme.palette.secondary.main
                          }
                        ]}
                        textColor={
                          theme.palette.mode === 'dark' ? theme.palette.text.primary : null
                        }
                        gridColor={
                          theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : null
                        }
                        filter={barFilter}
                      />
                    ) : (
                      <LineChart
                        datasets={[
                          {
                            data: props[stats].barImages,
                            label: 'Images',
                            color: theme.palette.secondary.main
                          }
                        ]}
                        textColor={
                          theme.palette.mode === 'dark' ? theme.palette.text.primary : null
                        }
                        gridColor={
                          theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : null
                        }
                        filter={barFilter}
                      />
                    )}
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper elevation={2}>
                  <div style={{ paddingTop: 20 }}>
                    {barFilter !== 'all' ? (
                      <BarChart
                        datasets={[
                          {
                            data: props[stats].barLabels,
                            label: 'Labels',
                            color: theme.palette.background.graphs
                          }
                        ]}
                        textColor={
                          theme.palette.mode === 'dark' ? theme.palette.text.primary : null
                        }
                        gridColor={
                          theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : null
                        }
                        filter={barFilter}
                      />
                    ) : (
                      <LineChart
                        datasets={[
                          {
                            data: props[stats].barLabels,
                            label: 'Labels',
                            color: theme.palette.background.graphs
                          }
                        ]}
                        textColor={
                          theme.palette.mode === 'dark' ? theme.palette.text.primary : null
                        }
                        gridColor={
                          theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : null
                        }
                        filter={barFilter}
                      />
                    )}
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </div>
          <ButtonGroup sx={{ paddingTop: 2, paddingBottom: 2 }} size="small" color="primary">
            <Button
              name="week"
              variant={barFilter === 'week' ? 'contained' : 'outlined'}
              onClick={() => setBarFilter('week')}
            >
              Week
            </Button>
            <Button
              name="month"
              variant={barFilter === 'month' ? 'contained' : 'outlined'}
              onClick={() => setBarFilter('month')}
            >
              Month
            </Button>
            <Button
              name="all"
              variant={barFilter === 'all' ? 'contained' : 'outlined'}
              onClick={() => setBarFilter('all')}
            >
              All
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state) => ({
  labelerStats: state.project.labelerStats,
  reviwerStats: state.project.reviwerStats,
  project: state.project
})

const mapDispatchToProps = {
  getProject: project.getProject,
  getLabelerStats: project.getLabelerStats,
  getReviwerStats: project.getReviwerStats,
  cleanProject: project.cleanProject,
  setProject: project.setProject
}

export default connect(mapStateToProps, mapDispatchToProps)(SharedProject)
