/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      email
      company
      termsAccepted
      status
      owner
      paid
      createdAt
      datasets {
        items {
          id
          name
          description
          count
          status
          createdAt
          updatedAt
          datasetUserId
        }
        nextToken
      }
      teams {
        items {
          id
          role
          createdAt
          updatedAt
          memberTeamId
          memberUserId
        }
        nextToken
      }
      projects {
        items {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      email
      company
      termsAccepted
      status
      owner
      paid
      createdAt
      datasets {
        items {
          id
          name
          description
          count
          status
          createdAt
          updatedAt
          datasetUserId
        }
        nextToken
      }
      teams {
        items {
          id
          role
          createdAt
          updatedAt
          memberTeamId
          memberUserId
        }
        nextToken
      }
      projects {
        items {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      email
      company
      termsAccepted
      status
      owner
      paid
      createdAt
      datasets {
        items {
          id
          name
          description
          count
          status
          createdAt
          updatedAt
          datasetUserId
        }
        nextToken
      }
      teams {
        items {
          id
          role
          createdAt
          updatedAt
          memberTeamId
          memberUserId
        }
        nextToken
      }
      projects {
        items {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const createDataset = /* GraphQL */ `
  mutation CreateDataset(
    $input: CreateDatasetInput!
    $condition: ModelDatasetConditionInput
  ) {
    createDataset(input: $input, condition: $condition) {
      id
      name
      description
      count
      status
      createdAt
      updatedAt
      datasetUserId
      user {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      images {
        items {
          id
          key
          url
          status
          createdAt
          imageDatasetId
          width
          height
          updatedAt
        }
        nextToken
      }
      projects {
        items {
          id
          projectDataProjectId
          projectDataDatasetId
          updatedAt
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const updateDataset = /* GraphQL */ `
  mutation UpdateDataset(
    $input: UpdateDatasetInput!
    $condition: ModelDatasetConditionInput
  ) {
    updateDataset(input: $input, condition: $condition) {
      id
      name
      description
      count
      status
      createdAt
      updatedAt
      datasetUserId
      user {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      images {
        items {
          id
          key
          url
          status
          createdAt
          imageDatasetId
          width
          height
          updatedAt
        }
        nextToken
      }
      projects {
        items {
          id
          projectDataProjectId
          projectDataDatasetId
          updatedAt
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const deleteDataset = /* GraphQL */ `
  mutation DeleteDataset(
    $input: DeleteDatasetInput!
    $condition: ModelDatasetConditionInput
  ) {
    deleteDataset(input: $input, condition: $condition) {
      id
      name
      description
      count
      status
      createdAt
      updatedAt
      datasetUserId
      user {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      images {
        items {
          id
          key
          url
          status
          createdAt
          imageDatasetId
          width
          height
          updatedAt
        }
        nextToken
      }
      projects {
        items {
          id
          projectDataProjectId
          projectDataDatasetId
          updatedAt
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const createImage = /* GraphQL */ `
  mutation CreateImage(
    $input: CreateImageInput!
    $condition: ModelImageConditionInput
  ) {
    createImage(input: $input, condition: $condition) {
      id
      key
      url
      status
      createdAt
      imageDatasetId
      dataset {
        id
        name
        description
        count
        status
        createdAt
        updatedAt
        datasetUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        images {
          nextToken
        }
        projects {
          nextToken
        }
      }
      width
      height
      thumbnail {
        key
        width
        height
      }
      generateEmbeddings {
        samH
        samB
        samL
      }
      embeddings {
        samH
        samB
        samL
      }
      updatedAt
    }
  }
`;
export const updateImage = /* GraphQL */ `
  mutation UpdateImage(
    $input: UpdateImageInput!
    $condition: ModelImageConditionInput
  ) {
    updateImage(input: $input, condition: $condition) {
      id
      key
      url
      status
      createdAt
      imageDatasetId
      dataset {
        id
        name
        description
        count
        status
        createdAt
        updatedAt
        datasetUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        images {
          nextToken
        }
        projects {
          nextToken
        }
      }
      width
      height
      thumbnail {
        key
        width
        height
      }
      generateEmbeddings {
        samH
        samB
        samL
      }
      embeddings {
        samH
        samB
        samL
      }
      updatedAt
    }
  }
`;
export const deleteImage = /* GraphQL */ `
  mutation DeleteImage(
    $input: DeleteImageInput!
    $condition: ModelImageConditionInput
  ) {
    deleteImage(input: $input, condition: $condition) {
      id
      key
      url
      status
      createdAt
      imageDatasetId
      dataset {
        id
        name
        description
        count
        status
        createdAt
        updatedAt
        datasetUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        images {
          nextToken
        }
        projects {
          nextToken
        }
      }
      width
      height
      thumbnail {
        key
        width
        height
      }
      generateEmbeddings {
        samH
        samB
        samL
      }
      embeddings {
        samH
        samB
        samL
      }
      updatedAt
    }
  }
`;
export const createTeam = /* GraphQL */ `
  mutation CreateTeam(
    $input: CreateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    createTeam(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      teamUserId
      user {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      status
      owner
      access
      members {
        items {
          id
          role
          createdAt
          updatedAt
          memberTeamId
          memberUserId
        }
        nextToken
      }
      projects {
        items {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        nextToken
      }
    }
  }
`;
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam(
    $input: UpdateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    updateTeam(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      teamUserId
      user {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      status
      owner
      access
      members {
        items {
          id
          role
          createdAt
          updatedAt
          memberTeamId
          memberUserId
        }
        nextToken
      }
      projects {
        items {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        nextToken
      }
    }
  }
`;
export const deleteTeam = /* GraphQL */ `
  mutation DeleteTeam(
    $input: DeleteTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    deleteTeam(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      teamUserId
      user {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      status
      owner
      access
      members {
        items {
          id
          role
          createdAt
          updatedAt
          memberTeamId
          memberUserId
        }
        nextToken
      }
      projects {
        items {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        nextToken
      }
    }
  }
`;
export const createMember = /* GraphQL */ `
  mutation CreateMember(
    $input: CreateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    createMember(input: $input, condition: $condition) {
      id
      role
      createdAt
      updatedAt
      memberTeamId
      team {
        id
        name
        createdAt
        updatedAt
        teamUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        status
        owner
        access
        members {
          nextToken
        }
        projects {
          nextToken
        }
      }
      memberUserId
      user {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
    }
  }
`;
export const updateMember = /* GraphQL */ `
  mutation UpdateMember(
    $input: UpdateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    updateMember(input: $input, condition: $condition) {
      id
      role
      createdAt
      updatedAt
      memberTeamId
      team {
        id
        name
        createdAt
        updatedAt
        teamUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        status
        owner
        access
        members {
          nextToken
        }
        projects {
          nextToken
        }
      }
      memberUserId
      user {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
    }
  }
`;
export const deleteMember = /* GraphQL */ `
  mutation DeleteMember(
    $input: DeleteMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    deleteMember(input: $input, condition: $condition) {
      id
      role
      createdAt
      updatedAt
      memberTeamId
      team {
        id
        name
        createdAt
        updatedAt
        teamUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        status
        owner
        access
        members {
          nextToken
        }
        projects {
          nextToken
        }
      }
      memberUserId
      user {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      name
      categories {
        color
        name
        type
        parent
        options
        required
        classType
        multiSelect
      }
      categoriesCount {
        name
        count
        countSaved
        countReviewed
      }
      createdAt
      updatedAt
      access
      owner
      status
      tagsCount
      datasetSort
      reviewType
      tagsSaveCount
      tagsReviewCount
      imagesSavedCount
      imagesReviewedCount
      projectTeamId
      team {
        id
        name
        createdAt
        updatedAt
        teamUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        status
        owner
        access
        members {
          nextToken
        }
        projects {
          nextToken
        }
      }
      projectUserId
      user {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      tags {
        items {
          id
          height
          width
          key
          done
          skiped
          accepted
          count
          time
          labelerTime
          reviewerTime
          timestamp
          status
          createdAt
          updatedAt
          assignedAt
          reviewAssignedAt
          savedAt
          reviewedAt
          skippedAt
          tagLabelerId
          tagReviewerId
          tagImageId
          tagProjectId
        }
        nextToken
      }
      datasets {
        items {
          id
          projectDataProjectId
          projectDataDatasetId
          updatedAt
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      name
      categories {
        color
        name
        type
        parent
        options
        required
        classType
        multiSelect
      }
      categoriesCount {
        name
        count
        countSaved
        countReviewed
      }
      createdAt
      updatedAt
      access
      owner
      status
      tagsCount
      datasetSort
      reviewType
      tagsSaveCount
      tagsReviewCount
      imagesSavedCount
      imagesReviewedCount
      projectTeamId
      team {
        id
        name
        createdAt
        updatedAt
        teamUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        status
        owner
        access
        members {
          nextToken
        }
        projects {
          nextToken
        }
      }
      projectUserId
      user {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      tags {
        items {
          id
          height
          width
          key
          done
          skiped
          accepted
          count
          time
          labelerTime
          reviewerTime
          timestamp
          status
          createdAt
          updatedAt
          assignedAt
          reviewAssignedAt
          savedAt
          reviewedAt
          skippedAt
          tagLabelerId
          tagReviewerId
          tagImageId
          tagProjectId
        }
        nextToken
      }
      datasets {
        items {
          id
          projectDataProjectId
          projectDataDatasetId
          updatedAt
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      name
      categories {
        color
        name
        type
        parent
        options
        required
        classType
        multiSelect
      }
      categoriesCount {
        name
        count
        countSaved
        countReviewed
      }
      createdAt
      updatedAt
      access
      owner
      status
      tagsCount
      datasetSort
      reviewType
      tagsSaveCount
      tagsReviewCount
      imagesSavedCount
      imagesReviewedCount
      projectTeamId
      team {
        id
        name
        createdAt
        updatedAt
        teamUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        status
        owner
        access
        members {
          nextToken
        }
        projects {
          nextToken
        }
      }
      projectUserId
      user {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      tags {
        items {
          id
          height
          width
          key
          done
          skiped
          accepted
          count
          time
          labelerTime
          reviewerTime
          timestamp
          status
          createdAt
          updatedAt
          assignedAt
          reviewAssignedAt
          savedAt
          reviewedAt
          skippedAt
          tagLabelerId
          tagReviewerId
          tagImageId
          tagProjectId
        }
        nextToken
      }
      datasets {
        items {
          id
          projectDataProjectId
          projectDataDatasetId
          updatedAt
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const createProjectData = /* GraphQL */ `
  mutation CreateProjectData(
    $input: CreateProjectDataInput!
    $condition: ModelProjectDataConditionInput
  ) {
    createProjectData(input: $input, condition: $condition) {
      id
      projectDataProjectId
      project {
        id
        name
        categories {
          color
          name
          type
          parent
          options
          required
          classType
          multiSelect
        }
        categoriesCount {
          name
          count
          countSaved
          countReviewed
        }
        createdAt
        updatedAt
        access
        owner
        status
        tagsCount
        datasetSort
        reviewType
        tagsSaveCount
        tagsReviewCount
        imagesSavedCount
        imagesReviewedCount
        projectTeamId
        team {
          id
          name
          createdAt
          updatedAt
          teamUserId
          status
          owner
          access
        }
        projectUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        datasets {
          nextToken
        }
      }
      projectDataDatasetId
      dataset {
        id
        name
        description
        count
        status
        createdAt
        updatedAt
        datasetUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        images {
          nextToken
        }
        projects {
          nextToken
        }
      }
      updatedAt
      createdAt
    }
  }
`;
export const updateProjectData = /* GraphQL */ `
  mutation UpdateProjectData(
    $input: UpdateProjectDataInput!
    $condition: ModelProjectDataConditionInput
  ) {
    updateProjectData(input: $input, condition: $condition) {
      id
      projectDataProjectId
      project {
        id
        name
        categories {
          color
          name
          type
          parent
          options
          required
          classType
          multiSelect
        }
        categoriesCount {
          name
          count
          countSaved
          countReviewed
        }
        createdAt
        updatedAt
        access
        owner
        status
        tagsCount
        datasetSort
        reviewType
        tagsSaveCount
        tagsReviewCount
        imagesSavedCount
        imagesReviewedCount
        projectTeamId
        team {
          id
          name
          createdAt
          updatedAt
          teamUserId
          status
          owner
          access
        }
        projectUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        datasets {
          nextToken
        }
      }
      projectDataDatasetId
      dataset {
        id
        name
        description
        count
        status
        createdAt
        updatedAt
        datasetUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        images {
          nextToken
        }
        projects {
          nextToken
        }
      }
      updatedAt
      createdAt
    }
  }
`;
export const deleteProjectData = /* GraphQL */ `
  mutation DeleteProjectData(
    $input: DeleteProjectDataInput!
    $condition: ModelProjectDataConditionInput
  ) {
    deleteProjectData(input: $input, condition: $condition) {
      id
      projectDataProjectId
      project {
        id
        name
        categories {
          color
          name
          type
          parent
          options
          required
          classType
          multiSelect
        }
        categoriesCount {
          name
          count
          countSaved
          countReviewed
        }
        createdAt
        updatedAt
        access
        owner
        status
        tagsCount
        datasetSort
        reviewType
        tagsSaveCount
        tagsReviewCount
        imagesSavedCount
        imagesReviewedCount
        projectTeamId
        team {
          id
          name
          createdAt
          updatedAt
          teamUserId
          status
          owner
          access
        }
        projectUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        datasets {
          nextToken
        }
      }
      projectDataDatasetId
      dataset {
        id
        name
        description
        count
        status
        createdAt
        updatedAt
        datasetUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        images {
          nextToken
        }
        projects {
          nextToken
        }
      }
      updatedAt
      createdAt
    }
  }
`;
export const deleteTag = /* GraphQL */ `
  mutation DeleteTag(
    $input: DeleteTagInput!
    $condition: ModelTagConditionInput
  ) {
    deleteTag(input: $input, condition: $condition) {
      id
      height
      width
      key
      done
      skiped
      accepted
      states {
        items {
          id
          createdAt
          stage
          action
          tagSateTagId
          toBlameId
          role
          updatedAt
        }
        nextToken
      }
      annotations {
        circles {
          id
          childs
          name
          color
          type
          text
        }
        lines {
          id
          childs
          name
          color
          type
          text
        }
        points {
          id
          childs
          name
          color
          type
          text
        }
        polys {
          id
          childs
          name
          color
          type
          text
        }
        rects {
          id
          childs
          name
          color
          type
          pos
          parent
          text
        }
        classification {
          name
          options
          selected
          values
          required
          classType
          multiSelect
        }
      }
      categoriesCount {
        name
        count
      }
      count
      time
      labelerTime
      reviewerTime
      timestamp
      status
      createdAt
      updatedAt
      assignedAt
      reviewAssignedAt
      savedAt
      reviewedAt
      skippedAt
      tagLabelerId
      labeler {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      tagReviewerId
      reviewer {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      tagImageId
      image {
        id
        key
        url
        status
        createdAt
        imageDatasetId
        dataset {
          id
          name
          description
          count
          status
          createdAt
          updatedAt
          datasetUserId
        }
        width
        height
        thumbnail {
          key
          width
          height
        }
        generateEmbeddings {
          samH
          samB
          samL
        }
        embeddings {
          samH
          samB
          samL
        }
        updatedAt
      }
      tagProjectId
      project {
        id
        name
        categories {
          color
          name
          type
          parent
          options
          required
          classType
          multiSelect
        }
        categoriesCount {
          name
          count
          countSaved
          countReviewed
        }
        createdAt
        updatedAt
        access
        owner
        status
        tagsCount
        datasetSort
        reviewType
        tagsSaveCount
        tagsReviewCount
        imagesSavedCount
        imagesReviewedCount
        projectTeamId
        team {
          id
          name
          createdAt
          updatedAt
          teamUserId
          status
          owner
          access
        }
        projectUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        datasets {
          nextToken
        }
      }
      comments {
        items {
          id
          createdAt
          closed
          recordType
          Type
          commentTagId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      createdAt
      messages {
        message
        messageUserId
        username
        role
        createdAt
      }
      closed
      recordType
      Type
      commentTagId
      tag {
        id
        height
        width
        key
        done
        skiped
        accepted
        states {
          nextToken
        }
        categoriesCount {
          name
          count
        }
        count
        time
        labelerTime
        reviewerTime
        timestamp
        status
        createdAt
        updatedAt
        assignedAt
        reviewAssignedAt
        savedAt
        reviewedAt
        skippedAt
        tagLabelerId
        labeler {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagReviewerId
        reviewer {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagImageId
        image {
          id
          key
          url
          status
          createdAt
          imageDatasetId
          width
          height
          updatedAt
        }
        tagProjectId
        project {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        comments {
          nextToken
        }
      }
      updatedAt
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      createdAt
      messages {
        message
        messageUserId
        username
        role
        createdAt
      }
      closed
      recordType
      Type
      commentTagId
      tag {
        id
        height
        width
        key
        done
        skiped
        accepted
        states {
          nextToken
        }
        categoriesCount {
          name
          count
        }
        count
        time
        labelerTime
        reviewerTime
        timestamp
        status
        createdAt
        updatedAt
        assignedAt
        reviewAssignedAt
        savedAt
        reviewedAt
        skippedAt
        tagLabelerId
        labeler {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagReviewerId
        reviewer {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagImageId
        image {
          id
          key
          url
          status
          createdAt
          imageDatasetId
          width
          height
          updatedAt
        }
        tagProjectId
        project {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        comments {
          nextToken
        }
      }
      updatedAt
    }
  }
`;
export const createTagState = /* GraphQL */ `
  mutation CreateTagState(
    $input: CreateTagStateInput!
    $condition: ModelTagStateConditionInput
  ) {
    createTagState(input: $input, condition: $condition) {
      id
      createdAt
      stage
      action
      tagSateTagId
      tag {
        id
        height
        width
        key
        done
        skiped
        accepted
        states {
          nextToken
        }
        categoriesCount {
          name
          count
        }
        count
        time
        labelerTime
        reviewerTime
        timestamp
        status
        createdAt
        updatedAt
        assignedAt
        reviewAssignedAt
        savedAt
        reviewedAt
        skippedAt
        tagLabelerId
        labeler {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagReviewerId
        reviewer {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagImageId
        image {
          id
          key
          url
          status
          createdAt
          imageDatasetId
          width
          height
          updatedAt
        }
        tagProjectId
        project {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        comments {
          nextToken
        }
      }
      toBlameId
      toBlame {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      role
      updatedAt
    }
  }
`;
export const deleteTagState = /* GraphQL */ `
  mutation DeleteTagState(
    $input: DeleteTagStateInput!
    $condition: ModelTagStateConditionInput
  ) {
    deleteTagState(input: $input, condition: $condition) {
      id
      createdAt
      stage
      action
      tagSateTagId
      tag {
        id
        height
        width
        key
        done
        skiped
        accepted
        states {
          nextToken
        }
        categoriesCount {
          name
          count
        }
        count
        time
        labelerTime
        reviewerTime
        timestamp
        status
        createdAt
        updatedAt
        assignedAt
        reviewAssignedAt
        savedAt
        reviewedAt
        skippedAt
        tagLabelerId
        labeler {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagReviewerId
        reviewer {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagImageId
        image {
          id
          key
          url
          status
          createdAt
          imageDatasetId
          width
          height
          updatedAt
        }
        tagProjectId
        project {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        comments {
          nextToken
        }
      }
      toBlameId
      toBlame {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      role
      updatedAt
    }
  }
`;
export const createRequestJson = /* GraphQL */ `
  mutation CreateRequestJson(
    $input: CreateRequestJsonInput!
    $condition: ModelRequestJsonConditionInput
  ) {
    createRequestJson(input: $input, condition: $condition) {
      id
      type
      owner
      createdAt
      format
      tagsCount
      imagesCount
      status
      key
      requestProjectId
      project {
        id
        name
        categories {
          color
          name
          type
          parent
          options
          required
          classType
          multiSelect
        }
        categoriesCount {
          name
          count
          countSaved
          countReviewed
        }
        createdAt
        updatedAt
        access
        owner
        status
        tagsCount
        datasetSort
        reviewType
        tagsSaveCount
        tagsReviewCount
        imagesSavedCount
        imagesReviewedCount
        projectTeamId
        team {
          id
          name
          createdAt
          updatedAt
          teamUserId
          status
          owner
          access
        }
        projectUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        datasets {
          nextToken
        }
      }
      updatedAt
    }
  }
`;
export const updateRequestJson = /* GraphQL */ `
  mutation UpdateRequestJson(
    $input: UpdateRequestJsonInput!
    $condition: ModelRequestJsonConditionInput
  ) {
    updateRequestJson(input: $input, condition: $condition) {
      id
      type
      owner
      createdAt
      format
      tagsCount
      imagesCount
      status
      key
      requestProjectId
      project {
        id
        name
        categories {
          color
          name
          type
          parent
          options
          required
          classType
          multiSelect
        }
        categoriesCount {
          name
          count
          countSaved
          countReviewed
        }
        createdAt
        updatedAt
        access
        owner
        status
        tagsCount
        datasetSort
        reviewType
        tagsSaveCount
        tagsReviewCount
        imagesSavedCount
        imagesReviewedCount
        projectTeamId
        team {
          id
          name
          createdAt
          updatedAt
          teamUserId
          status
          owner
          access
        }
        projectUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        datasets {
          nextToken
        }
      }
      updatedAt
    }
  }
`;
export const deleteRequestJson = /* GraphQL */ `
  mutation DeleteRequestJson(
    $input: DeleteRequestJsonInput!
    $condition: ModelRequestJsonConditionInput
  ) {
    deleteRequestJson(input: $input, condition: $condition) {
      id
      type
      owner
      createdAt
      format
      tagsCount
      imagesCount
      status
      key
      requestProjectId
      project {
        id
        name
        categories {
          color
          name
          type
          parent
          options
          required
          classType
          multiSelect
        }
        categoriesCount {
          name
          count
          countSaved
          countReviewed
        }
        createdAt
        updatedAt
        access
        owner
        status
        tagsCount
        datasetSort
        reviewType
        tagsSaveCount
        tagsReviewCount
        imagesSavedCount
        imagesReviewedCount
        projectTeamId
        team {
          id
          name
          createdAt
          updatedAt
          teamUserId
          status
          owner
          access
        }
        projectUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        datasets {
          nextToken
        }
      }
      updatedAt
    }
  }
`;
export const createTag = /* GraphQL */ `
  mutation CreateTag(
    $input: CreateTagInput!
    $condition: ModelTagConditionInput
  ) {
    createTag(input: $input, condition: $condition) {
      id
      height
      width
      key
      done
      skiped
      accepted
      states {
        items {
          id
          createdAt
          stage
          action
          tagSateTagId
          toBlameId
          role
          updatedAt
        }
        nextToken
      }
      annotations {
        circles {
          id
          childs
          name
          color
          type
          text
        }
        lines {
          id
          childs
          name
          color
          type
          text
        }
        points {
          id
          childs
          name
          color
          type
          text
        }
        polys {
          id
          childs
          name
          color
          type
          text
        }
        rects {
          id
          childs
          name
          color
          type
          pos
          parent
          text
        }
        classification {
          name
          options
          selected
          values
          required
          classType
          multiSelect
        }
      }
      categoriesCount {
        name
        count
      }
      count
      time
      labelerTime
      reviewerTime
      timestamp
      status
      createdAt
      updatedAt
      assignedAt
      reviewAssignedAt
      savedAt
      reviewedAt
      skippedAt
      tagLabelerId
      labeler {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      tagReviewerId
      reviewer {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      tagImageId
      image {
        id
        key
        url
        status
        createdAt
        imageDatasetId
        dataset {
          id
          name
          description
          count
          status
          createdAt
          updatedAt
          datasetUserId
        }
        width
        height
        thumbnail {
          key
          width
          height
        }
        generateEmbeddings {
          samH
          samB
          samL
        }
        embeddings {
          samH
          samB
          samL
        }
        updatedAt
      }
      tagProjectId
      project {
        id
        name
        categories {
          color
          name
          type
          parent
          options
          required
          classType
          multiSelect
        }
        categoriesCount {
          name
          count
          countSaved
          countReviewed
        }
        createdAt
        updatedAt
        access
        owner
        status
        tagsCount
        datasetSort
        reviewType
        tagsSaveCount
        tagsReviewCount
        imagesSavedCount
        imagesReviewedCount
        projectTeamId
        team {
          id
          name
          createdAt
          updatedAt
          teamUserId
          status
          owner
          access
        }
        projectUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        datasets {
          nextToken
        }
      }
      comments {
        items {
          id
          createdAt
          closed
          recordType
          Type
          commentTagId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateTag = /* GraphQL */ `
  mutation UpdateTag(
    $input: UpdateTagInput!
    $condition: ModelTagConditionInput
  ) {
    updateTag(input: $input, condition: $condition) {
      id
      height
      width
      key
      done
      skiped
      accepted
      states {
        items {
          id
          createdAt
          stage
          action
          tagSateTagId
          toBlameId
          role
          updatedAt
        }
        nextToken
      }
      annotations {
        circles {
          id
          childs
          name
          color
          type
          text
        }
        lines {
          id
          childs
          name
          color
          type
          text
        }
        points {
          id
          childs
          name
          color
          type
          text
        }
        polys {
          id
          childs
          name
          color
          type
          text
        }
        rects {
          id
          childs
          name
          color
          type
          pos
          parent
          text
        }
        classification {
          name
          options
          selected
          values
          required
          classType
          multiSelect
        }
      }
      categoriesCount {
        name
        count
      }
      count
      time
      labelerTime
      reviewerTime
      timestamp
      status
      createdAt
      updatedAt
      assignedAt
      reviewAssignedAt
      savedAt
      reviewedAt
      skippedAt
      tagLabelerId
      labeler {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      tagReviewerId
      reviewer {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      tagImageId
      image {
        id
        key
        url
        status
        createdAt
        imageDatasetId
        dataset {
          id
          name
          description
          count
          status
          createdAt
          updatedAt
          datasetUserId
        }
        width
        height
        thumbnail {
          key
          width
          height
        }
        generateEmbeddings {
          samH
          samB
          samL
        }
        embeddings {
          samH
          samB
          samL
        }
        updatedAt
      }
      tagProjectId
      project {
        id
        name
        categories {
          color
          name
          type
          parent
          options
          required
          classType
          multiSelect
        }
        categoriesCount {
          name
          count
          countSaved
          countReviewed
        }
        createdAt
        updatedAt
        access
        owner
        status
        tagsCount
        datasetSort
        reviewType
        tagsSaveCount
        tagsReviewCount
        imagesSavedCount
        imagesReviewedCount
        projectTeamId
        team {
          id
          name
          createdAt
          updatedAt
          teamUserId
          status
          owner
          access
        }
        projectUserId
        user {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        datasets {
          nextToken
        }
      }
      comments {
        items {
          id
          createdAt
          closed
          recordType
          Type
          commentTagId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      createdAt
      messages {
        message
        messageUserId
        username
        role
        createdAt
      }
      closed
      recordType
      Type
      commentTagId
      tag {
        id
        height
        width
        key
        done
        skiped
        accepted
        states {
          nextToken
        }
        categoriesCount {
          name
          count
        }
        count
        time
        labelerTime
        reviewerTime
        timestamp
        status
        createdAt
        updatedAt
        assignedAt
        reviewAssignedAt
        savedAt
        reviewedAt
        skippedAt
        tagLabelerId
        labeler {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagReviewerId
        reviewer {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagImageId
        image {
          id
          key
          url
          status
          createdAt
          imageDatasetId
          width
          height
          updatedAt
        }
        tagProjectId
        project {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        comments {
          nextToken
        }
      }
      updatedAt
    }
  }
`;
export const updateTagState = /* GraphQL */ `
  mutation UpdateTagState(
    $input: UpdateTagStateInput!
    $condition: ModelTagStateConditionInput
  ) {
    updateTagState(input: $input, condition: $condition) {
      id
      createdAt
      stage
      action
      tagSateTagId
      tag {
        id
        height
        width
        key
        done
        skiped
        accepted
        states {
          nextToken
        }
        categoriesCount {
          name
          count
        }
        count
        time
        labelerTime
        reviewerTime
        timestamp
        status
        createdAt
        updatedAt
        assignedAt
        reviewAssignedAt
        savedAt
        reviewedAt
        skippedAt
        tagLabelerId
        labeler {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagReviewerId
        reviewer {
          id
          name
          email
          company
          termsAccepted
          status
          owner
          paid
          createdAt
          updatedAt
        }
        tagImageId
        image {
          id
          key
          url
          status
          createdAt
          imageDatasetId
          width
          height
          updatedAt
        }
        tagProjectId
        project {
          id
          name
          createdAt
          updatedAt
          access
          owner
          status
          tagsCount
          datasetSort
          reviewType
          tagsSaveCount
          tagsReviewCount
          imagesSavedCount
          imagesReviewedCount
          projectTeamId
          projectUserId
        }
        comments {
          nextToken
        }
      }
      toBlameId
      toBlame {
        id
        name
        email
        company
        termsAccepted
        status
        owner
        paid
        createdAt
        datasets {
          nextToken
        }
        teams {
          nextToken
        }
        projects {
          nextToken
        }
        updatedAt
      }
      role
      updatedAt
    }
  }
`;
