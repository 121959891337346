import React, { useContext } from 'react'
import { Box, Button, Typography } from '@mui/material'
import NotFoundImages from './NotFoundImages'
import { FiltersContext } from '../../../context/FiltersContext'

const NoItemsFound = () => {
  const { dispatch } = useContext(FiltersContext)

  return (
    <Box>
      <NotFoundImages />
      <Typography>No items found for this search</Typography>
      <Button
        variant="text"
        onClick={() => {
          dispatch({ type: 'reset' })
        }}
      >
        Remove Filters
      </Button>
    </Box>
  )
}

export default NoItemsFound
