import React, { Suspense } from 'react'
import { Amplify } from 'aws-amplify'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { createRoot } from 'react-dom' // Import createRoot
import './styles/index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import initStore from './store/store'
import config from './aws-exports'
import HelpButton from './components/HelpButton'

export const { store, persistor } = initStore()

Amplify.configure(config)

// Wrap your App component with Suspense
const Root = () => (
  <Provider store={store}>
    <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
      <BrowserRouter>
        <Suspense fallback={<div>Loading...</div>}>
          <App modals={store.getState().modals} />
          <HelpButton />
        </Suspense>
      </BrowserRouter>
    </PersistGate>
  </Provider>
)

// Use createRoot().render() instead of ReactDOM.render()
createRoot(document.getElementById('root')).render(<Root />)

serviceWorker.unregister()
