import Axios from 'axios'

// Metrics Images

export const getDataSave = (payload) => {
  return Axios.get(
    `${process.env.REACT_APP_API}metrics/images?project_id=${payload.projectId}&aggregation=done_and_skipped`,
    {}
  )
}

export const getDataReviewed = (payload) => {
  return Axios.get(
    `${process.env.REACT_APP_API}metrics/images?project_id=${payload.projectId}&aggregation=reviewed`,
    {}
  )
}

export const getDataSkipped = (payload) => {
  return Axios.get(
    `${process.env.REACT_APP_API}metrics/images?project_id=${payload.projectId}&aggregation=skipped`,
    {}
  )
}

export const getDataDoneReviewed = (payload) => {
  return Axios.get(
    `${process.env.REACT_APP_API}metrics/images?project_id=${payload.projectId}&aggregation=done_and_reviewed`,
    {}
  )
}

export const getDataSkippedReviewed = (payload) => {
  return Axios.get(
    `${process.env.REACT_APP_API}metrics/images?project_id=${payload.projectId}&aggregation=skipped_and_reviewed`,
    {}
  )
}

export const getDataDoneDaily = (payload) => {
  return Axios.get(
    `${process.env.REACT_APP_API}metrics/images?project_id=${payload.projectId}&aggregation=done_by_day`,
    {}
  )
}

export const getDataReviewedDaily = (payload) => {
  return Axios.get(
    `${process.env.REACT_APP_API}metrics/images?project_id=${payload.projectId}&aggregation=reviewed_by_day`,
    {}
  )
}

export const getDataSkippedDaily = (payload) => {
  return Axios.get(
    `${process.env.REACT_APP_API}metrics/images?project_id=${payload.projectId}&aggregation=skipped_by_day`,
    {}
  )
}

// Metrics Tags

export const getDataAllTags = (payload) => {
  return Axios.get(
    `${process.env.REACT_APP_API}metrics/tags?project_id=${payload.projectId}&aggregation=labeler`,
    {}
  )
}

export const getDataReviewedTags = (payload) => {
  return Axios.get(
    `${process.env.REACT_APP_API}metrics/tags?project_id=${payload.projectId}&aggregation=reviewer`,
    {}
  )
}

export const getDataLabelerTags = (payload) => {
  return Axios.get(
    `${process.env.REACT_APP_API}metrics/tags?project_id=${payload.projectId}&aggregation=done_by_labeler`
  )
}

// Metrics Labeler

export const getDataImagesLabeler = (payload) => {
  return Axios.get(
    `${process.env.REACT_APP_API}metrics/images?project_id=${payload.projectId}&aggregation=done_by_day&labeler_id=${payload.userId}`
  )
}

export const getDataTagsLabeler = (payload) => {
  return Axios.get(
    `${process.env.REACT_APP_API}metrics/tags?project_id=${payload.projectId}&aggregation=labeler_and_day&labeler_id=${payload.userId}`
  )
}

export const getDataImagesSkippedLabeler = (payload) => {
  return Axios.get(
    `${process.env.REACT_APP_API}metrics/images?project_id=${payload.projectId}&aggregation=skipped_by_day&labeler_id=${payload.userId}`
  )
}

// Metrics Reviewer

export const getDataImagesReviewer = (payload) => {
  return Axios.get(
    `${process.env.REACT_APP_API}metrics/images?project_id=${payload.projectId}&aggregation=reviewed_by_day&labeler_id=${payload.userId}`
  )
}

export const getDataTagsReviewer = (payload) => {
  return Axios.get(
    `${process.env.REACT_APP_API}metrics/tags?project_id=${payload.projectId}&aggregation=reviewer_and_day&labeler_id=${payload.userId}`
  )
}
