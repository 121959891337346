import React from 'react'
import { connect } from 'react-redux'
import { dialog } from '../../../../io/dialogs/actions'
import {
  Button,
  Dialog,
  Checkbox,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  DialogContentText
} from '@mui/material'

function DialogComponent(props) {
  return (
    <Dialog
      open={props.dialogs.isOpen}
      onClose={() => props.setDialogStatus(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.dialogs.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.dialogs.text}
          <br />
          {props.dialogs.categories.map((cat) => (
            <div style={{ margin: 10 }} key={cat.name}>
              <FormControlLabel
                control={
                  <Checkbox onChange={(e) => props.handleCategory(e, cat)} value={cat.name} />
                }
                label={cat.name}
              />
            </div>
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.clickCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={props.okDialog} color="primary" autoFocus>
          {props.dialogs.okText || 'OK'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
const mapStateToProps = (state) => ({
  dialogs: state.dialogs
})
const mapDispatchToProps = {
  setDialogStatus: dialog.setStatus
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogComponent)
