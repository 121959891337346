import React, { useContext, useState, useEffect } from 'react'
import { Button } from '@mui/material'
import CreateIcon from '@mui/icons-material/Create'
import AutoPolyIcon from '@mui/icons-material/Brush'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'

import CahngeCatIcon from '../../../icons/PaintBucket'
import EraserIcon from '../../../icons/Eraser'
import { LabelerContext } from '../../../containers/labeler/context/LabelerContext'
import { DispatchContext } from '../../../containers/labeler/context/DispatchContext'
import { getUser } from '../../../utils/util'
import BarTooltip from '../BarTooltip'
import useFreeTrial from '../../../pages/project/hooks/useFreeTrial'
import { useSelector } from 'react-redux'
import { getIsPaidUser } from '../../../io/graphql/user/user'
export default function SegmentationButtons(props) {
  const { permissionsFreeTrial } = useFreeTrial()
  const {
    state: {
      labelerState: { mode, image }
    }
  } = useContext(LabelerContext)
  const { dispatch } = useContext(DispatchContext)
  const project = useSelector((state) => state.project)

  const [isPaid, setIsPaid] = useState(false)

  const handlerMode = (e) => {
    dispatch({
      type: 'setLabelerMode',
      payload: { category: 'segmentation', mode: e.currentTarget.name }
    })
  }

  useEffect(() => {
    validateUserPaid()
  }, [image.index])

  const validateUserPaid = async () => {
    try {
      let _isPaid = getUser() ? getUser().paid : false
      if (!_isPaid) {
        _isPaid = (await getIsPaidUser(project.user.id)).data.isPaid
      }
      setIsPaid(_isPaid)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <BarTooltip title="Add segmentation">
        <Button
          sx={
            mode === 'draw'
              ? { minWidth: 48, width: 48, background: 'rgba(120,120,120,0.3)' }
              : { minWidth: 48, width: 48 }
          }
          size="small"
          name="draw"
          onClick={handlerMode}
        >
          <CreateIcon fontSize="small" />
        </Button>
      </BarTooltip>
      <BarTooltip title="Delete segmentation">
        <Button
          sx={
            mode === 'erase'
              ? { minWidth: 48, width: 48, background: 'rgba(120,120,120,0.3)' }
              : { minWidth: 48, width: 48 }
          }
          size="small"
          name="erase"
          onClick={handlerMode}
        >
          <EraserIcon fontSize="small" />
        </Button>
      </BarTooltip>
      <BarTooltip title="Paint">
        <Button
          sx={
            mode === 'paint'
              ? { minWidth: 48, width: 48, background: 'rgba(120,120,120,0.3)' }
              : { minWidth: 48, width: 48 }
          }
          size="small"
          name="paint"
          onClick={handlerMode}
        >
          <CahngeCatIcon fontSize="small" />
        </Button>
      </BarTooltip>
      {isPaid && (
        <>
          <BarTooltip title="AutoPoly">
            <Button
              sx={
                mode === 'magig'
                  ? { minWidth: 48, width: 48, background: 'rgba(120,120,120,0.3)', fontSize: 8 }
                  : { minWidth: 48, width: 48, fontSize: 8 }
              }
              size="small"
              name="magic"
              labelStyle={{ fontSize: '63px' }}
              onClick={handlerMode}
            >
              <AutoPolyIcon fontSize="small" />
              {/* <span>BETA</span> */}
            </Button>
          </BarTooltip>

          <BarTooltip title="Magic">
            <Button
              sx={
                mode === 'magic-v2'
                  ? { minWidth: 48, width: 48, background: 'rgba(120,120,120,0.3)', fontSize: 8 }
                  : { minWidth: 48, width: 48, fontSize: 8 }
              }
              size="small"
              name="magic-v2"
              disabled={!props.isMagicEnable || (!isPaid && !permissionsFreeTrial)}
              labelStyle={{ fontSize: '63px' }}
              onClick={handlerMode}
            >
              <AutoFixHighIcon fontSize="small" />
              {/* <span>BETA</span> */}
            </Button>
          </BarTooltip>
        </>
      )}
    </>
  )
}
