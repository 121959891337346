import React, { useContext, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Divider, IconButton } from '@mui/material'
import ArrowForward from '@mui/icons-material/ArrowForward'
import { getComments, updateComment } from '../../../../services/graphql'
import { LabelerContext } from '../../context/LabelerContext'
import { DispatchContext } from '../../context/DispatchContext'
import MessageInput from './comments/MessageInput'
import Comments from './comments/Comments'
import useRole from '../../../../utils/labeler/useRole'
import { useSelector } from 'react-redux'
import FilterChain from '../../filters/FilterChain'
import ClassificationHandler from '../../filters/ClassificationHandler'
import useImage from '../../../../utils/labeler/useImage'
import { getTimeNow, getUser } from '../../../../utils/util'
import { useSnackbar } from '../../../../components/snackbar/context/SnackbarContext'

const RootComments = styled('div')(() => ({
  width: '100%',
  height: '70%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}))

const ContainerComments = styled('div')(({ theme }) => ({
  width: '95%',
  height: '95%',
  overflow: 'auto',
  border: '1px solid #d1d1d1',
  borderRadius: 5,
  paddingLeft: 10,
  '&::-webkit-scrollbar': {
    width: 5
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.background.subpaper
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 4
  }
}))

const HeaderDiv = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center'
}))

let chain = null

export default function ReviewerRightArea(props) {
  const { state } = useContext(LabelerContext)
  const { labelerState } = state
  const { dispatch } = useContext(DispatchContext)
  const [comments, setComments] = useState([])
  const [open, setOpen] = React.useState([])
  const projectState = useSelector((state) => state.project)
  const image = useImage()
  const role = useRole()
  const { showSnackbar } = useSnackbar()

  useEffect(() => {
    chain = null
    chain = new FilterChain()
    chain.addHandler(
      new ClassificationHandler(
        projectState.categories.filter((category) => category.type === 'classification'),
        state.classificationState.tags
      )
    )
    return () => {
      chain = null
    }
  }, [state.classificationState.tags])

  useEffect(() => {
    setComments([])
    setOpen([])
  }, [labelerState.image.index, labelerState.isFeedbackPanelOpen])

  useEffect(() => {
    getMessages()
  }, [labelerState.image.index, labelerState.isFeedbackPanelOpen])

  const getMessages = async () => {
    try {
      const messages = await getComments(labelerState.image.images[labelerState.image.index].id)
      if (messages.data.commentsByCommentTagIdAndCreatedAt) {
        setComments(messages.data.commentsByCommentTagIdAndCreatedAt.items)
        setOpen(new Array(messages.data.commentsByCommentTagIdAndCreatedAt.items.length).fill(true))
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleAddComment = (comment) => {
    const localComments = [...comments, comment]
    setComments(localComments)
  }

  const handleAddMessage = (message, index) => {
    const localComments = [...comments]
    const localMessages = [...localComments[index].messages, message]

    localComments[index] = { ...comments[index], messages: localMessages }
    setComments(localComments)
  }

  const handleCloseComment = async (index) => {
    const localComments = [...comments]
    const comment = { ...localComments[index] }
    comment.closed = true
    localComments[index] = { ...comment }
    await updateComment({ id: comment.id, closed: comment.closed })
    setComments(localComments)
  }
  const handleOpen = (index) => {
    const localOpen = [...open]
    localOpen[index] = !localOpen[index]
    setOpen([...localOpen])
  }
  const setRejectImg = (accepted) => {
    if (comments.length === 0) {
      showSnackbar({
        message: `It is necessary to add at least one comment`,
        type: 'error'
      })
      return
    }
    if (!projectState.id || !image) return
    if (chain.verifyRules()) {
      props
        .saveTags({
          accepted,
          reviewedAt: getTimeNow(),
          tagReviewerId: getUser().id,
          done: false,
          skiped: false
        })
        .then(() => {
          dispatch({ type: 'setImageDone', payload: accepted })
          dispatch({ type: 'setImageAccepted', payload: accepted })
          showSnackbar({
            message: `Image updated as ${accepted ? 'reviewed' : 'rejected'}.`,
            type: 'success'
          })
        })
        .catch((e) => {
          console.log(e)
          showSnackbar({
            message: `Error updateing image status, please try again.`,
            type: 'error'
          })
        })
    } else {
      showSnackbar({
        message: `There are required classifications that have not been completed.`,
        type: 'error'
      })
    }
  }

  return (
    <>
      <HeaderDiv>
        <IconButton onClick={() => dispatch({ type: 'setIsFeedbackPanelOpen', payload: false })}>
          <ArrowForward />
        </IconButton>
      </HeaderDiv>
      <Divider />
      <RootComments>
        <ContainerComments>
          <Comments
            open={open}
            comments={comments}
            handleOpen={handleOpen}
            handleAddMessage={handleAddMessage}
            handleCloseComment={handleCloseComment}
          ></Comments>
        </ContainerComments>
      </RootComments>
      <Divider />
      {role.labeler === 'reviewer' ? (
        <MessageInput role={role} handleAddComment={handleAddComment} setRejectImg={setRejectImg} />
      ) : (
        <></>
      )}
    </>
  )
}
