import { TYPES } from './constants'

function Shape(name, color, type, parent) {
  return parent
    ? {
        name,
        color,
        type,
        parent
      }
    : {
        name,
        color,
        type
      }
}

export function Segmentation(name, color, parent) {
  return Shape(name, color, TYPES.SEGMENTATION, parent)
}
function Category(name, options, type, parent, classType, required) {
  return parent
    ? {
        name,
        color: '#000',
        options,
        type,
        parent,
        classType,
        required
      }
    : {
        name,
        color: '#000',
        options,
        type,
        classType,
        required
      }
}

export function Rect(name, color, parent) {
  return Shape(name, color, TYPES.RECT, parent)
}

export function Poly(name, color, parent) {
  return Shape(name, color, TYPES.POLY, parent)
}

export function Line(name, color, parent) {
  return Shape(name, color, TYPES.LINE, parent)
}

export function Point(name, color, parent) {
  return Shape(name, color, TYPES.POINT, parent)
}

export function Circle(name, color, parent) {
  return Shape(name, color, TYPES.CIRCLE, parent)
}

export function Classification(name, options, parent, classType, required) {
  return Category(name, options, TYPES.CLASSIFICATION, parent, classType, required)
}

export function createShape(name, color, shape, parent) {
  switch (shape) {
    case TYPES.RECT:
      return new Rect(name, color, parent)
    case TYPES.POLY:
      return new Poly(name, color, parent)
    case TYPES.POINT:
      return new Point(name, color, parent)
    case TYPES.LINE:
      return new Line(name, color, parent)
    case TYPES.CIRCLE:
      return new Circle(name, color, parent)
    case TYPES.SEGMENTATION:
      return new Segmentation(name, color, parent)
    default:
      return null
  }
}

export function createClassification(name, options, parent, classType, required) {
  return new Classification(name, options, parent, classType, required)
}

export function getIcon(shape) {
  switch (shape) {
    case TYPES.RECT:
      return 'assets/bounding-box.png'
    case TYPES.POLY:
      return 'assets/polygon.png'
    case TYPES.POINT:
      return 'assets/point.png'
    case TYPES.LINE:
      return 'assets/line.png'
    case TYPES.CIRCLE:
      return 'assets/ellipse.png'
    default:
      return 'assets/point.png'
  }
}
