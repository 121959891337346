import IFilter from './IFilter'

class ClassificationHandler extends IFilter {
  constructor(classificationCategories, classificationTags) {
    super()
    this.classificationCategories = classificationCategories
    this.classificationTags = classificationTags
  }

  checkCondition() {
    return this.checkRequired(this.classificationCategories, this.classificationTags)
  }

  checkRequired(classificationCategories, tags) {
    let result = true
    classificationCategories.forEach((classification) => {
      if (classification.required) {
        const classificationTag = tags.find((tag) => tag.name === classification.name)
        if (classificationTag) {
          if (classificationTag.selected === '') {
            result = false
          }
        }
      }
    })
    return result
  }
}

export default ClassificationHandler
