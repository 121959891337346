import { useReducer } from 'react'
import produce from 'immer'

const initialTimerState = {
  time: 0,
  initialTime: 0,
  actionLabelerTime: 'run',
  actionReviewerTime: 'run',
  labelerInitialTime: 0,
  reviwerInitialTime: 0,
  loading: true
}

export const reducer = produce((state, action) => {
  switch (action.type) {
    case 'setInitialTime':
      state.labelerInitialTime = action.payload.labelerTimer ? action.payload.labelerTimer : 0
      state.reviwerInitialTime = action.payload.reviewerTimer ? action.payload.reviewerTimer : 0
      state.loading = false
      break
    case 'setActionLabelerTime':
      state.actionLabelerTime = action.payload
      break
    case 'setActionReviewerTime':
      state.actionReviewerTime = action.payload
      break
    case 'setLoadingTags':
      state.loading = action.payload
      break
  }
}, initialTimerState)

export function TimerReducer() {
  const [state, dispatch] = useReducer(reducer, initialTimerState)
  return { timerState: state, timerDispatch: dispatch }
}
