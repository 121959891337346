import React, { useEffect, useRef, useState } from 'react'

export function useResizeImageContainer(url, loading, gridSize, callback) {
  const image = useRef(document.createElement('img', null, null))
  const [props, setProps] = useState({ width: 1000, height: 1000 })

  useEffect(() => {
    if (!loading && url) {
      image.current.onload = function () {
        setProps({ width: this.width, height: this.height })
        callback(this.width, this.height)
      }
      image.current.src = url
    }
  }, [url, loading, gridSize])
  return { width: props.width, height: props.height }
}
