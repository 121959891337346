export const person = [
  {
    id: 'c1941f27-f9e1-4f2b-a3dc-469c0bde13a8',
    name: 'neck',
    color: '#FF0004',
    type: 'point',
    text: null,
    pos: {
      x: 319.6696496877162,
      y: 280.2649959756457
    }
  },
  {
    id: '85e4e112-ccde-4f8c-aca0-fcd62bfd686c',
    name: 'spinal_cord_1',
    color: '#3AFF00',
    type: 'point',
    text: null,
    pos: {
      x: 318.59407975774917,
      y: 316.2965886295403
    }
  },
  {
    id: 'b54de11e-d988-42e0-92d5-60cbc5e822aa',
    name: 'spinal_cord_2',
    color: '#53FFE6',
    type: 'point',
    text: null,
    pos: {
      x: 318.32518727525746,
      y: 348.8325790110421
    }
  },
  {
    id: '23c75ea1-84d8-41e9-a765-e953cf776ec7',
    name: 'spinal_cord_3',
    color: '#DFFF00',
    type: 'point',
    text: null,
    pos: {
      x: 318.0562947927657,
      y: 381.9063543575275
    }
  },
  {
    id: 'fbac8463-32cd-4449-81b6-6f0da3dc6c81',
    name: 'spinal_cord_4',
    color: '#9CF903',
    type: 'point',
    text: null,
    pos: {
      x: 316.20764920386495,
      y: 417.3705722601641
    }
  },
  {
    id: '63590615-ae1d-4587-ac85-a6a1591a20c5',
    name: 'spinal_cord_5',
    color: '#0062FF',
    type: 'point',
    text: null,
    pos: {
      x: 317.6159546126,
      y: 460.5586047947065
    }
  },
  {
    id: 'd9b3a775-d30c-4d02-946d-75cafc266873',
    name: 'nose',
    color: '#00FFFC',
    type: 'point',
    text: null,
    pos: {
      x: 319.1318647227327,
      y: 223.2597896873946
    }
  },
  {
    id: '2e306cbc-aa91-428b-a3ca-0c9e9ee22a92',
    name: 'chin',
    color: '#FFDB00',
    type: 'point',
    text: null,
    pos: {
      x: 318.59407975774917,
      y: 249.88014545407813
    }
  },
  {
    id: '54d5c588-af90-48b1-a734-9749450dc1d8',
    name: 'left_eye',
    color: '#FF0004',
    type: 'point',
    text: null,
    pos: {
      x: 331.23202643486144,
      y: 211.4285204577576
    }
  },
  {
    id: '901fd6ae-8ad5-4e9e-9373-99868c5e4422',
    name: 'right_eye',
    color: '#8A0FFF',
    type: 'point',
    text: null,
    pos: {
      x: 305.68724059814514,
      y: 212.23519790523278
    }
  },
  {
    id: 'c473c086-d109-4b83-a5ab-cf634f7db96f',
    name: 'left_ear',
    color: '#0026FF',
    type: 'point',
    text: null,
    pos: {
      x: 345.75222048941606,
      y: 220.03307989749365
    }
  },
  {
    id: '6ebcc156-e8e1-4c79-beee-894c2a3c1eab',
    name: 'right_ear',
    color: '#3FFF00',
    type: 'point',
    text: null,
    pos: {
      x: 292.2426164735576,
      y: 220.03307989749365
    }
  },
  {
    id: 'd353888c-2368-4ef7-aef4-ad2c22527c99',
    name: 'left_wrist',
    color: '#D000FF',
    type: 'point',
    text: null,
    pos: {
      x: 424.508008285065,
      y: 486.5941168737962
    }
  },
  {
    id: 'a967b466-d9b3-485e-aae9-bf8b1d971fc0',
    name: 'right_wrist',
    color: '#2c0f6d',
    type: 'point',
    text: null,
    pos: {
      x: 229.81617069285562,
      y: 485.06219566494076
    }
  },
  {
    id: '8109900c-b521-4f5e-b21c-15d6e8e452b3',
    name: 'left_pinky',
    color: '#0090FF',
    type: 'point',
    text: null,
    pos: {
      x: 418.3273468660758,
      y: 525.308387354832
    }
  },
  {
    id: 'ca53023d-e751-4ca3-a543-d2cd0fc1ca1d',
    name: 'right_pinky',
    color: '#12FF00',
    type: 'point',
    text: null,
    pos: {
      x: 230.08656637090152,
      y: 528.1162841167708
    }
  },
  {
    id: '3e66f242-8ecc-4938-a5bf-0f0e54fa98c5',
    name: 'left_index',
    color: '#FFC000',
    type: 'point',
    text: null,
    pos: {
      x: 433.9598960261364,
      y: 525.308387354832
    }
  },
  {
    id: 'ce406b52-ce6b-4703-a0b6-ce7cd727db4a',
    name: 'right_index',
    color: '#0069FF',
    type: 'point',
    text: null,
    pos: {
      x: 214.94318772934608,
      y: 533.489741054097
    }
  },
  {
    id: '20bb0437-3eaf-4f85-b5dc-05cfdaab4cbc',
    name: 'left_thumb',
    color: '#FF03B0',
    type: 'point',
    text: null,
    pos: {
      x: 438.578603732518,
      y: 519.6238240239009
    }
  },
  {
    id: 'bd5e55df-fc86-47e7-9709-af8b4f102e46',
    name: 'right_thumb',
    color: '#FF6D01',
    type: 'point',
    text: null,
    pos: {
      x: 211.03521904765435,
      y: 524.208315435079
    }
  },
  {
    id: '53f88605-0f91-4df5-8aec-f3aef9b1899a',
    name: 'left_middle',
    color: '#FB7C00',
    type: 'point',
    text: null,
    pos: {
      x: 430.05175873612126,
      y: 529.2165246448471
    }
  },
  {
    id: 'b3dca7a7-057d-49d6-ba63-0e57a2e60bb6',
    name: 'right_middle',
    color: '#FF00C5',
    type: 'point',
    text: null,
    pos: {
      x: 219.82814858146074,
      y: 535.9322214801542
    }
  },
  {
    id: '0656e3d0-500d-4d24-b732-626466b76a9f',
    name: 'right_ring',
    color: '#0AADFF',
    type: 'point',
    text: null,
    pos: {
      x: 225.69010160399833,
      y: 533.9782371393084
    }
  },
  {
    id: '42515709-9ad0-46f0-89da-37bbcb4c815b',
    name: 'left_ring',
    color: '#FFF900',
    type: 'point',
    text: null,
    pos: {
      x: 425.0777658215565,
      y: 528.8612394366639
    }
  },
  {
    id: '0656e3d0-500d-4d24-b732-626466b76a9f',
    name: 'left_shoulder',
    color: '#A6FF00',
    type: 'point',
    text: null,
    pos: {
      x: 384.02438271960506,
      y: 292.42320164829
    }
  },
  {
    id: '42515709-9ad0-46f0-89da-37bbcb4c815b',
    name: 'right_shoulder',
    color: '#F000FF',
    type: 'point',
    text: null,
    pos: {
      x: 257.17195146469766,
      y: 293.0832341768344
    }
  },
  {
    id: '27c527c2-402e-43a6-8ae9-f5b87e5df70c',
    name: 'left_elbow',
    color: '#E7FF00',
    type: 'point',
    text: null,
    pos: {
      x: 398.06222405005775,
      y: 387.3190090421503
    }
  },
  {
    id: '9d3a4b86-fa81-4d6e-a03e-743ba077ec1e',
    name: 'right_elbow',
    color: '#00FFF3',
    type: 'point',
    text: null,
    pos: {
      x: 246.42503759004546,
      y: 389.31696296349327
    }
  },
  {
    id: '8fb3b60c-3110-4e68-9033-3023565a5655',
    name: 'left_hip',
    color: '#F1FF00',
    type: 'point',
    text: null,
    pos: {
      x: 350.0069790135065,
      y: 448.8227263885807
    }
  },
  {
    id: 'ee2d41e8-b692-43d4-94f0-979e5be3f956',
    name: 'right_hip',
    color: '#BE00FF',
    type: 'point',
    text: null,
    pos: {
      x: 286.6332356204285,
      y: 449.76159666107094
    }
  },
  {
    id: '1c695925-fed2-4204-9059-b802a633b024',
    name: 'left_knee',
    color: '#00FF37',
    type: 'point',
    text: null,
    pos: {
      x: 362.75520951846016,
      y: 600.5373164790034
    }
  },
  {
    id: '0ee7d03d-d72c-4e5f-929e-d316500762be',
    name: 'right_knee',
    color: '#AE45FF',
    type: 'point',
    text: null,
    pos: {
      x: 261.7653095184601,
      y: 603.5976164790035
    }
  },
  {
    id: '9b6b7f61-7167-42ce-8475-74ac5167811c',
    name: 'left_ankle',
    color: '#FF00FB',
    type: 'point',
    text: null,
    pos: {
      x: 370.77713655354415,
      y: 738.475662504228
    }
  },
  {
    id: '8070ac32-8752-40f9-ae79-f0df46d8bf9e',
    name: 'left_heel',
    color: '#C2FF05',
    type: 'point',
    text: null,
    pos: {
      x: 371.4355554721407,
      y: 747.6935273645811
    }
  },
  {
    id: 'fdfce2a4-f829-438a-a8df-d2589702cd85',
    name: 'left_bigtoe',
    color: '#E100FF',
    type: 'point',
    text: null,
    pos: {
      x: 381.3118392510905,
      y: 770.0797705968671
    }
  },
  {
    id: '9da1d430-7769-4b2b-9116-e31f2f9bddeb',
    name: 'right_anklee',
    color: '#5FB9FF',
    type: 'point',
    text: null,
    pos: {
      x: 258.84592039211435,
      y: 738.475662504228
    }
  },
  {
    id: '93d5ac14-b5cf-4a58-841d-b3a01155217c',
    name: 'right_heel',
    color: '#4EFFCF',
    type: 'point',
    text: null,
    pos: {
      x: 258.84592039211435,
      y: 749.6687841203709
    }
  },
  {
    id: 'b6a9db9c-869a-4dc6-9030-68b44f4fb56d',
    name: 'right_bigtoe',
    color: '#FF5B82',
    type: 'point',
    text: null,
    pos: {
      x: 240.41019067140832,
      y: 771.3966084340602
    }
  },
  {
    id: 'bc260195-0f33-47b8-b825-09179872c7f3',
    name: 'top_head',
    color: '#5000FF',
    type: 'point',
    text: null,
    pos: {
      x: 317.24961734529046,
      y: 182.11923986615668
    }
  }
]
