import React from 'react'
import Card from '@mui/material/Card'
import { CircularProgress } from '@mui/material'

export default function LoadingDropdown() {
  return (
    <Card sx={{ padding: 2 }}>
      <CircularProgress />
    </Card>
  )
}
