import React, { useContext } from 'react'
import LabelerRightArea from './LabelerRightArea'
import ReviewerCommentsRightArea from './ReviewerCommentsRightArea'
import { LabelerContext } from '../../context/LabelerContext'
import { Slide, Box } from '@mui/material'
import { styled } from '@mui/material/styles'

const Container = styled(Box)(() => ({
  display: 'flex',
  flexFlow: 'column',
  backgroundColor: 'white',
  transformOrigin: `right`,
  height: '100%',
  borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
  top: 0
}))

const Root = styled('div')(({ theme, width }) => ({
  background: theme.palette.background.paper,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  color: theme.palette.text.primary,
  position: 'absolute',
  width
}))
export default function ContainerRightArea(props) {
  const containerRef = React.useRef(null)
  const labelerRightAreaRef = React.useRef(null)
  const { state } = useContext(LabelerContext)
  const { labelerState } = state
  return (
    <Container ref={containerRef}>
      <LabelerRightArea reference={labelerRightAreaRef} {...props}></LabelerRightArea>
      <Slide
        direction="left"
        in={labelerState.isFeedbackPanelOpen}
        container={containerRef.current}
      >
        <Root width={labelerRightAreaRef.current?.getBoundingClientRect().width}>
          <ReviewerCommentsRightArea {...props}></ReviewerCommentsRightArea>
        </Root>
      </Slide>
    </Container>
  )
}
