import React, { useState, useEffect, useContext } from 'react'
import LabelerImgHandler from './labeler/LabelerImgHandler'
import ReviewerImgHandler from './reviewer/ReviewerImgHandler'
import ViewerImgHandler from './viewer/ViewerImgHandler'
import AdminSelector from './AdminSelector'
import Divider from '@mui/material/Divider'
import { styled } from '@mui/material/styles'

import { useSelector } from 'react-redux'
import Timer from '../../../../containers/labeler/components/Timer'
import { getUser, getTimeNow } from '../../../../utils/util'
import useImage from '../../../../utils/labeler/useImage'
import { DispatchContext } from '../../../../containers/labeler/context/DispatchContext'
import { LabelerContext } from '../../../../containers/labeler/context/LabelerContext'
import ClassificationHandler from '../../../../containers/labeler/filters/ClassificationHandler'
import FilterChain from '../../../../containers/labeler/filters/FilterChain'
import { useLabelerMachine } from '../../../../containers/labeler/context/MachineContext'
import { getComments } from '../../../../services/graphql'
import { useSnackbar } from '../../../snackbar/context/SnackbarContext'

const Container = styled('div')(({ theme }) => ({
  background: theme.palette.background.subpaper
}))

const ImgHandler = (
  skipped,
  setImgSkip,
  done,
  setImgDone,
  accepted,
  setImgReview,
  handleNext,
  openFeedbackToolbar,
  setRejectImg,
  numberOfComments
) => ({
  labeler: (
    <LabelerImgHandler
      done={done}
      setImageDone={setImgDone}
      skipped={skipped}
      setImageSkip={setImgSkip}
      handleNext={handleNext}
      openFeedbackToolbar={openFeedbackToolbar}
      numberOfComments={numberOfComments}
    />
  ), // saveDoneImage
  reviewer: (
    <ReviewerImgHandler
      done={done}
      accepted={accepted}
      setImageReview={setImgReview}
      handleNext={handleNext}
      openFeedbackToolbar={openFeedbackToolbar}
      setRejectImg={setRejectImg}
      numberOfComments={numberOfComments}
    />
  ), // setImagesAsReview
  viewer: <ViewerImgHandler />
})

let chain = null

export default function CompositeImgHandler(props) {
  const projectState = useSelector((state) => state.project)
  const { send } = useLabelerMachine()
  const { dispatch } = useContext(DispatchContext)
  const { state } = useContext(LabelerContext)
  const {
    labelerState: { role }
  } = state
  const image = useImage()
  const [numberOfComments, setNumberOfComments] = useState(null)

  const { showSnackbar } = useSnackbar()

  useEffect(() => {
    chain = null
    chain = new FilterChain()
    chain.addHandler(
      new ClassificationHandler(
        projectState.categories.filter((category) => category.type === 'classification'),
        state.classificationState.tags
      )
    )
    return () => {
      chain = null
    }
  }, [state.classificationState.tags])

  useEffect(() => {
    getMessages()
  }, [image?.id])

  const handleNext = () => {}

  const handlerSetRole = (role) => {
    dispatch({ type: 'setRole', payload: role })
    dispatch({ type: 'setImages', payload: { images: [] } })
    dispatch({ type: 'setImage', payload: -1 })
    dispatch({ type: 'setNavigation', payload: 'init' })
    send({ type: 'RESET', source: 'CompositeImgHandler' })
  }
  const setImgSkip = async (callbackFunction = () => {}) => {
    if (!projectState.id || !image) return
    try {
      props.saveTags({
        done: false,
        skiped: true,
        skippedAt: getTimeNow()
      })
      dispatch({ type: 'setImageDone', payload: false })
      dispatch({ type: 'setImageSkipped', payload: true })
      showSnackbar({
        message: 'Image updated as skip',
        type: 'success'
      })
      callbackFunction()
    } catch (error) {
      showSnackbar({
        message: 'Error updating image status, please try again.',
        type: 'error'
      })
    }
  }
  const setImgDone = async (callbackFunction = () => {}) => {
    if (!projectState.id || !image) return
    if (chain.verifyRules()) {
      try {
        await props.saveTags({
          done: true,
          skiped: false,
          savedAt: getTimeNow()
        })

        dispatch({ type: 'setImageDone', payload: true })
        dispatch({ type: 'setImageSkipped', payload: false })
        showSnackbar({
          message: 'Image updated as saved.',
          type: 'success'
        })
        callbackFunction()
      } catch (error) {
        showSnackbar({
          message: 'Error updating image status, please try again.' + error,
          type: 'error'
        })
      }
    } else {
      showSnackbar({
        message: 'There are required classifications that have not been completed.',
        type: 'success'
      })
    }
  }
  const setImgReview = (accepted) => {
    if (!projectState.id || !image) return
    if (chain.verifyRules()) {
      props
        .saveTags({
          accepted,
          reviewedAt: getTimeNow(),
          tagReviewerId: getUser().id,
          ...(accepted ? { done: true, skiped: false } : {})
        })
        .then(() => {
          dispatch({ type: 'setImageAccepted', payload: accepted })
          showSnackbar({
            message: `Image updated as ${accepted ? 'reviewed' : 'rejected'}.`,
            type: 'success'
          })
        })
        .catch((e) => {
          console.log(e)
          showSnackbar({
            message: 'Error updating image status, please try again.',
            type: 'error'
          })
        })
    } else {
      showSnackbar({
        message: 'There are required classifications that have not been completed.',
        type: 'error'
      })
    }
  }
  const setRejectImg = (accepted) => {
    if (!projectState.id || !image) return
    if (chain.verifyRules()) {
      props
        .saveTags({
          accepted,
          reviewedAt: getTimeNow(),
          tagReviewerId: getUser().id,
          done: false,
          skiped: false
        })
        .then(() => {
          dispatch({ type: 'setImageDone', payload: accepted })
          dispatch({ type: 'setImageAccepted', payload: accepted })
          showSnackbar({
            message: `Image updated as ${accepted ? 'reviewed' : 'rejected'}.`,
            type: 'success'
          })
        })
        .catch((e) => {
          console.log(e)
          showSnackbar({
            message: 'Error updating image status, please try again.',
            type: 'error'
          })
        })
    } else {
      showSnackbar({
        message: 'There are required classifications that have not been completed.',
        type: 'error'
      })
    }
  }

  const openFeedbackToolbar = () => {
    dispatch({ type: 'setIsFeedbackPanelOpen', payload: true })
  }

  const getMessages = async () => {
    try {
      if (image) {
        const messages = await getComments(image.id)
        if (messages.data.commentsByCommentTagIdAndCreatedAt) {
          const comments = messages.data.commentsByCommentTagIdAndCreatedAt.items.filter(
            (item) => !item.closed
          )
          setNumberOfComments(comments.length)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <Container>
      {
        ImgHandler(
          image?.skiped,
          setImgSkip,
          image?.done,
          setImgDone,
          image?.accepted,
          setImgReview,
          handleNext,
          openFeedbackToolbar,
          setRejectImg,
          numberOfComments
        )[role]
      }
      {projectState.role === 'admin' && (
        <AdminSelector handlerSetRole={handlerSetRole} role={role} />
      )}
      <Divider />
      <div>
        <Timer />
      </div>
    </Container>
  )
}
