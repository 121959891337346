import { API, graphqlOperation } from 'aws-amplify'
import * as qweries from '../../graphql/qweries'
import * as queries from '../../graphql/queries'

export const listMyProjets = (limit, nextToken, id, filters = { or: [], and: [] }) =>
  API.graphql(
    graphqlOperation(qweries.searchProjects, {
      limit,
      nextToken,
      filter:
        filters.and.length === 0 && filters.or.length === 0
          ? {
              projectUserId: { eq: id }
            }
          : filters.or.length === 0
          ? {
              projectUserId: { eq: id },
              and: filters.and
            }
          : filters.and.length === 0
          ? {
              projectUserId: { eq: id },
              or: filters.or
            }
          : {
              projectUserId: { eq: id },
              and: filters.and,
              or: filters.or
            },
      sort: { field: 'createdAt', direction: 'desc' }
    })
  )

export const listSharedProjects = (limit, nextToken, id, owner, filters = { or: [], and: [] }) =>
  API.graphql(
    graphqlOperation(qweries.searchProjects, {
      limit,
      nextToken,
      filter:
        filters.and.length === 0 && filters.or.length === 0
          ? {
              access: { match: owner },
              projectUserId: { ne: id }
            }
          : filters.or.length === 0
          ? {
              access: { match: owner },
              projectUserId: { ne: id },
              and: filters.and
            }
          : filters.and.length === 0
          ? {
              access: { match: owner },
              projectUserId: { ne: id },
              or: filters.or
            }
          : {
              access: { match: owner },
              projectUserId: { ne: id },
              and: filters.and,
              or: filters.or
            },
      sort: { field: 'createdAt', direction: 'desc' }
    })
  )

export const listTeams = (limit, nextToken, id) =>
  API.graphql(
    graphqlOperation(qweries.searchTeams, {
      limit,
      nextToken,
      filter: { teamUserId: { eq: id } },
      sort: { field: 'createdAt', direction: 'desc' }
    })
  )

export const listMemberTeams = (limit, nextToken, id) =>
  API.graphql(
    graphqlOperation(qweries.searchTeams, {
      limit,
      nextToken,
      filter: { access: { wildcard: '*' }, teamUserId: { ne: id } },
      sort: { field: 'createdAt', direction: 'desc' }
    })
  )

export const listDatasets = (limit, nextToken, id) =>
  API.graphql(
    graphqlOperation(queries.searchDatasets, {
      limit,
      nextToken,
      filter: { datasetUserId: { eq: id } },
      sort: { field: 'createdAt', direction: 'desc' }
    })
  )

export const getMember = async (memberUserId, memberTeamId) => {
  const id = memberUserId + memberTeamId
  return API.graphql(graphqlOperation(queries.getMember, { id }))
}
